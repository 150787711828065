import React from 'react'

const HolderDistributionChart = ({ holdersData }) => {
    return <div className=" border border-[var(--cu-black-50)] bg-[var(--cu-black-500)]  rounded-xl">
        <div className="flex border-b border-b-gray-700 p-[1rem]">
            <p>Holder Distribution</p>
        </div>
        {holdersData?.data?.length ? holdersData?.data?.map((holder, indx) => {
            let holderPercentage = ((holder?.value / holdersData?.total) * 100);
            holderPercentage = Math.floor(holderPercentage * 10) / 10;
            holderPercentage = holderPercentage.toFixed(1);
            return <div key={indx} className="w-full flex justify-between flex-col">
                <div className="flex flex-col items-start gap-2 p-[1rem]">
                    <div className="w-full flex justify-between">
                        <p>{holder?.header ?? '-'}</p>
                        <p className="flex gap-2">
                            <strong>{holder?.value ?? '-'}</strong>
                            <span className="text-[var(--cu-grey-500)]">{`(${holderPercentage}%)`}</span></p>
                    </div>
                    <div className="w-full rounded-full h-2.5 bg-gray-700">
                        <div className="bg-[var(--cu-black-10)] h-2.5 rounded-full" style={{ width: `${holderPercentage}%` }}></div>
                    </div>
                </div>
            </div>
        }) :<div className="py-[1rem] h-[15dvh] flex justify-center items-center">{`No data available`}</div>}

    </div>
}
export default HolderDistributionChart