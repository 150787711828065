import CustomBtn from "App/Components/Ui/CustomBtn"
import Panel from "App/Components/Ui/Panel"
import FilterCheckBox from "./Components/FilterCheckbox"
import FrameColors from "./Components/FrameColors"
import styles from "./FrameFilter.module.scss"
import { useCallback, useEffect, useRef, useState } from "react"
import SearchPanel from "./Components/SearchPanel"
import { useStore } from "App/hooks-store/store"
import NftZoomed from "App/Components/NftZoomed"
import './FrameFilter.scss';
const FrameFilter = props => {
    const [searchStatus, setSearchStatus] = useState(false)
    const [nftSelected, setNftSelected] = useState(null)
    const [assetList, setAssetList] = useState([])
    const [assetLoading, setAssetLoading] = useState(false)
    const [isCollection, setIsCollection] = useState(true)
    const [searchValue, setSearchValue] = useState("")
    const [tempNft, setTempNft] = useState(null)
    const store = useStore()[0];
    const { collections, artBank, artBankLoadingStatus, collectionLoadingStatus } = store;
    const overflowfilter = useRef();
    const dispatch = useStore(false)[1];
    const [filter, setFilter] = useState({
        Age: [],
        Style: [],
        Material: [],
        Shape: [],
        "Add-ons": [],
        Complexity: []
    })
    const searchFramesHandler = e => {
        setSearchValue(e.target.value)
        setSearchStatus(true)
    }

    useEffect(() => {
        if(isCollection) {
            setAssetList(collections);
        } else { 
            setAssetList(artBank);
        }
    }, [isCollection, artBank, collections])

    useEffect(() => {
        setTempNft(props?.appliedNft)
    }, [props?.appliedNft])

    useEffect(() => {
        const loading = isCollection ? collectionLoadingStatus : artBankLoadingStatus;
        setAssetLoading(loading !== 'finished' && loading !== '')
    }, [isCollection, artBankLoadingStatus, collectionLoadingStatus])

    useEffect(() => {
        filterResetHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCollection])
    

    const filterOptionHandler = (title, option) => {
        if (option.checked === true)
            setFilter(prevState => {
                let temp = prevState
                temp[title].push(option.option)
                return temp
            })
        else if (option.checked === false)
            setFilter(prevState => {
                let temp = prevState
                temp[title].splice(temp[title].indexOf(option.option), 1)
                return temp
            })
    }
    const filterResetHandler = () => {
        dispatch('setActiveIcons', { filter: false });
        setSearchStatus(false)
        setSearchValue('')
        props.onSelectNft(null)
        setNftSelected(null)
        setTempNft(null)

        setFilter({
            Age: [],
            Style: [],
            Material: [],
            Shape: [],
            "Add-ons": [],
            Complexity: []
        })
        props.onResetColor()
        props.onApplyFilter({
            Age: [],
            Style: [],
            Material: [],
            Shape: [],
            "Add-ons": [],
            Complexity: []
        }, true)

    }
    const applyFilterHandler = useCallback(() => {
        dispatch('setActiveIcons', { filter: false });
        if (searchStatus) {
            setSearchStatus(false)
            props.onSelectNft(null)
            setTimeout(() => {
                props.onSelectNft(nftSelected)
            }, 500);
        }
        else
            props.onApplyFilter(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, nftSelected, searchStatus, props])
    useEffect(() => {
        applyFilterHandler()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sort])
    const nftSelectHandler = e => {
        setNftSelected(e)
        setSearchStatus(true)
        setTempNft(e);
    }
    useEffect(() => {
        const screenSize = window.innerWidth;
        const adjustHeight = () => {
            overflowfilter.current.style.maxHeight = `${window.innerHeight - 366}px`
        }
        if (screenSize < 768) {
            window.addEventListener("resize", adjustHeight)
            adjustHeight();
        }
        return () => {
            window.removeEventListener("resize", adjustHeight)
        }
    }, [])

    return <div className={`${styles["frame-filter"]} ${props.className} ${props?.showFilter}`}>
        <Panel transparent className="pb-2.5 h-full relative bg-[#14171F] border-[#30333A] border border-1">
        <div className="flex flex-col w-full overflow-clip max-height-ios frame-filter-wrapper">
                <div className={`${styles["searching"]} pb-2`}>
                    {!tempNft && <p >{props.loading ? "Image Preview" : "Image Preview"}</p>}
                    {!!tempNft &&
                        <NftZoomed className={`${styles["selected-nft-image"]}`} noScrolls={true} nft={tempNft.img} />
                    }
                </div>
                <div className={`${styles["filter-cnr-inner"]} overflow-y-auto`} ref={overflowfilter}>
                    <div className="flex flex-col gap-1">
                        {Object.values(props.filterCategories).map((cat, index) => 
                            <div key={index}>
                                {cat?.isColor ? 
                                    <FrameColors onColorSelect={props.onColorSelect} selectedColor={props.selectedColor} filter={filter} colors={props.colors} /> 
                                    : 
                                    (cat?.isAsset ? 
                                        <SearchPanel searchFramesHandler={searchFramesHandler} 
                                            loading={assetLoading} selectedNft={nftSelected} 
                                            appliedNft={tempNft} searchValue={searchValue} 
                                            nfts={assetList} onSelectNft={nftSelectHandler}
                                            searchStatus={searchStatus} setSearchStatus={setSearchStatus}
                                            setIsCollection={setIsCollection} isCollection={isCollection}
                                        />
                                        :
                                        <FilterCheckBox key={index} filter={filter} onCheckOption={filterOptionHandler} title={cat.name} options={cat.list} />
                                    )
                                }
                            </div>
                        )}
                    </div>
                </div>
                <div className={`${styles["btn-box"]}`}>
                    <CustomBtn onClick={filterResetHandler} className={`${styles["reset"]}`}>Reset</CustomBtn>
                    <CustomBtn onClick={applyFilterHandler} className={`${styles["apply"]}`}>Apply</CustomBtn>
                </div>
            </div>
        </Panel>
    </div>
}

export default FrameFilter