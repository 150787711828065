/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "../../CategoryCollections.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "App/hooks-store/store";
import CollectionCard from "App/Components/CollectionCard";
import { categorySort } from "App/Helper/utilities";
import useDiscoverApiHelper from "App/Pages/Discover/Components/API/apiHelper";
import useHttp from "App/hooks/use-http";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";

const ExploreCategoryCollections = () => {
  const [collectionListOriginal, setCollectionListOriginal] = useState([]);
  const [page, setPage] = useState(1);
  const { category } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(null);
  const [{ activeMenuIcons }, dispatch] = useStore();
  const { triggerAPI } = useHttp();
  const { getDiscoverCollection, getCategoryCollection } =
    useDiscoverApiHelper();
  const targetRef = useRef(null);
  const navigate = useNavigate();
  const timeOut = useRef(null);
  const [categoryList, setCategoryList] = useState([]);
  const getCategoryResponse = useCallback(
    (res) => {
      const cateList = categorySort([...res?.data?.list]);
      setCategoryList(cateList);
    },
    [dispatch]
  );
  const getCategoryList = useCallback(() => {
    triggerAPI(
      {
        url: `category/get`,
        data: {
          page: 1,
          items: 1000,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getCategoryResponse
    );
  }, [dispatch, getCategoryResponse, triggerAPI]);

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    dispatch("setTopMenuBarOptions", { showMenuIcons: true });
    dispatch("enableTopIcons", {
      filter: false,
      search: true,
      sorting: false,
      lgView: false,
      xlgView: false,
      smView: false,
      listView: false,
      dropDown: false,
      favourite: false,
      filterSort: false,
      sortFilter:true
    });
    dispatch("setActiveIcons", { search: "", sort: undefined });
    return () => {
      dispatch("resetAll");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCategoryCollectionList = useCallback(() => {
    const dataParam = {
      search: searchValue ?? "",
      id: category,
    };
    setLoading(() => true);
    dispatch("enableTopIcons", {
      filter: false,
      search: true,
      sorting: false,
      lgView: false,
      xlgView: false,
      smView: false,
      listView: false,
      dropDown: false,
      favourite: false,
      filterSort: false,
      sortFilter:true,
      sortingDisable:true
    });
    getCategoryCollection(dataParam, (res) => {
      const { data } = res;
      let filteredData =data?.filter(val=>val?.collections?.length)
      setCollectionListOriginal(filteredData);
      setLoading(() => false);
      const subCategory = filteredData?.map((el) => ({
        id: el?.id,
        value: el?.name,
        slug: el?.slug,
      }));
      dispatch("enableTopIcons", {
        filter: false,
        search: true,
        sorting: false,
        lgView: false,
        xlgView: false,
        smView: false,
        listView: false,
        dropDown: false,
        favourite: false,
        filterSort: false,
        sortFilter:true,
        sortingDisable:false
      });
      dispatch("setSortingOptions", subCategory);

    },true);
  }, [getDiscoverCollection, searchValue]);

  useEffect(() => {
    if (category && categoryList?.length) getCategoryCollectionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, categoryList, searchValue]);

  const updateHeight = () => {
    const root = document.documentElement;
    if (targetRef.current) {
      if (targetRef.current) {
        root?.style.setProperty(
          "--collection-card-height",
          `${targetRef.current.offsetWidth}px`
        );
      }
    }
  };
  useLayoutEffect(() => {
    updateHeight();
    window.addEventListener(
      "resize",
      () => {
        setTimeout(() => {
          updateHeight();
        }, 1000);
      },
      false
    );
  }, []);
  useEffect(() => {
    updateHeight();
  }, [collectionListOriginal]);
  const cardClick = (e) => {
    const id = parseInt(e?.currentTarget?.dataset?.id);
    const policy = e?.currentTarget?.dataset?.policy;
    let data;
    collectionListOriginal?.forEach((collect) =>
      collect?.collections?.forEach((el) => {
        if (el?.id === id) data = el;
      })
    );
    let url="discover/collection/details"
    if(data?.community?.slug) url=`discover/communities/${data?.community?.slug}/collection`
    navigate(`/${url}/${policy}`)
  };

  useEffect(() => {
    if (page !== 1) setPage(1);
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
    timeOut.current = setTimeout(() => {
      setSearchValue(activeMenuIcons?.search);
    }, 600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.search]);


  const navigateToAll = (navName) => {
    const subCategorySlug = collectionListOriginal?.find(
      (el) => el?.name === navName
    )?.slug;
    if (subCategorySlug) {
      window.scrollTo(0, 0);
      navigate(`${subCategorySlug}`);
    }
  };
  useEffect(() => {
    if (activeMenuIcons?.sort) {
      navigateToAll(activeMenuIcons?.sort);
    }
  }, [activeMenuIcons?.sort]);
  return (
    <div  className="discover-collection h-full w-full flex flex-col explore-collection">
      <div className="w-full overflow-flex-auto h-full flex gap-[1.42857rem] flex-col">
        {(loading || (loading === null)) && (
          <div className="flex flex-col">
            {[...Array(2)].map((el,i)=><div className="mt-[3.5rem] mb-[2rem]" key={i+1}>
              <DetailsSkeltonCard 
                    show={true}
                    height={"100%"}
                    count={5}
                    inline={true}
                    details={"img-2-line"}
                    noAbsolute
                    containerClassName={`flex`}
                  />
          </div>)}
            </div>
       )} 
        {!!collectionListOriginal?.length && !loading && (
          <div className="  flex-1 h-full flex flex-col justify-between">
            {[...collectionListOriginal]?.map((val) => {
                return (
                  <div key={val?.id}>
                    <div className="flex justify-between items-center">
                      <div className="text-left my-[.5rem] text-[1.25rem]">{val?.name}</div>
                      <div
                        className="cursor-pointer my-[.5rem] text-[.675rem] text-[#A0ABBB]"
                        onClick={() => navigateToAll(val?.name)}
                      >
                        See all
                      </div>
                    </div>
                    <div className="collection-container grid gap-3 relative mt-4">
                      {[...val?.collections]?.map((list) => {
                        return (
                          <CollectionCard
                            showData={{
                              profileIcon: true,
                              collectionName: true,
                              communityName: true,
                              count: false,
                              tag: true,
                            }}
                            key={list?.id}
                            row={{
                              ...list,
                              filePath256: list?.filePath256 ||  list?.filePath
                            }}
                            cardClick={cardClick}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
        {!loading && loading !== null && !collectionListOriginal?.length && (
          <div className=" flex items-center no-data justify-center">
            No collections to display.
          </div>
        )}
      </div>
    </div>
  );
};

export default ExploreCategoryCollections;
