import "./GalleryPreviewImages.scss"
import { findNumberFromString, getBLOBUrl } from "App/Helper/utilities"
import { memo, useCallback, useMemo } from "react"
import { useRef } from "react"
import html2canvas from "html2canvas"
import React, { useEffect, useState } from "react"
import LayoutCard8 from "./Components/LayoutCard8"
import LayoutCard1 from "./Components/LayoutCard1"
import LayoutCard2 from "./Components/LayoutCard2"
import LayoutCard3 from "./Components/LayoutCard3"
import LayoutCard4 from "./Components/LayoutCard4"
import LayoutCard5 from "./Components/LayoutCard5"
import LayoutCard6 from "./Components/LayoutCard6"
import LayoutCard7 from "./Components/LayoutCard7"
import { useStore } from "App/hooks-store/store"

const GalleryPreviewImage = ({ className, layoutCount, clearCache = false, addedGallery = false, selectedNfts = '', 
onScreenCapture, fileName, layoutType, src, multiViewClass= '', imageRatioCalculated, selectedLayout = {}, isMultiGallery = false, 
onChainRenders = false, loadSmallSize = false, onChainReRender = 0 }) => {
    const [image, setImage] = useState()
    const exportRef = useRef(); 
    const containerRef = useRef();
    const [cntr, setCntr] = useState(0)
    const [frameCount, setFrameCount] = useState(0)
    const [allLoad, setAllLoad] = useState(false)
    const [nftCntr, setNftCntr] = useState(0)
    const [failsafeScreenshot, setFailsafecreenshot] = useState(null)
    const [countAssetFrame, setCountAssetFrame] = useState([])
    const [{ screenShotTake }, dispatch] = useStore(false);
    const timer = useRef(null);
    const buildCards = useCallback((layoutCount, list) => {
        switch (layoutCount) {
            default:
                setImage(list)
                break;
        }
    }, [])
    useEffect(() => {
        if (selectedNfts?.length) {
            buildCards(layoutCount, selectedNfts)
        }
    }, [selectedNfts, layoutType, layoutCount, buildCards])

    const screenshotImage = (timer) => {
        console.log("taking screenshot",timer)
        const htmlCanvas = () => {
            console.log("creating canvas")
            const input = exportRef.current
            html2canvas(input, {
                logging: true,
                allowTaint: false,
                useCORS: true,
                backgroundColor: null
            }).then((canvas) => {
                console.log("saving canvas")
                canvas.toBlob(blob => {
                    const obj = {
                        [fileName]: { blob, url: getBLOBUrl(blob) },
                    }
                    console.log("emitting canvas blob", {
                        fileName,
                        obj
                    })
                    if (fileName === 'file0') {
                        emitScreenshot(obj)
                    }
                });

            })
        }
        if (failsafeScreenshot)
        {
            clearTimeout(failsafeScreenshot);
        }

        const timeoutid = setTimeout(() => {
            htmlCanvas();
        }, timer);

        if (timer === 10000)
        {
            setFailsafecreenshot(timeoutid);
        }        
    }
    const emitScreenshot = (obj) => {
        if (timer.current) {
            clearTimeout(timer.current)
        }
        timer.current = setTimeout(() => {
            onScreenCapture(obj);
        }, 3000);
    }
    const onNFTloads = useCallback((e) => {
        setNftCntr(c => c + 1)
    }, [])
    const onFrameLoaded = useCallback((e) => {
        setCntr(c => c + 1)
    }, [])
    
    useEffect(() => {
        if (countAssetFrame.length && countAssetFrame.length ===  cntr && !screenShotTake?.frame) {
            if (onScreenCapture && fileName) {
                dispatch('setScreenShotTake', {frame: true})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cntr, screenShotTake])
   
    useEffect(() => {
        if (countAssetFrame.length === cntr && selectedNfts.length === nftCntr) {
            if (onScreenCapture && fileName) {
                if (failsafeScreenshot)
                {
                    clearTimeout(failsafeScreenshot) 
                }
                screenshotImage(5000);
            }
        }
        else if (onScreenCapture && fileName && !failsafeScreenshot && cntr === 0 && nftCntr === 0)
        {
            screenshotImage(10000);            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cntr,nftCntr, countAssetFrame?.length])
    

    useEffect(() => {
        let values = []
        selectedNfts?.filter((x) => {
            if (x?.frameId && x?.frameId !== null) {
                values?.push(x)
            }
            return values
        })
        if (fileName === 'file0') {
            setCountAssetFrame(values)
        }
        
        setFrameCount(values?.length)
    }, [selectedNfts, fileName])  
    useEffect(() => {
        setNftCntr(0);
        setCntr(0);
    }, [selectedNfts?.length, onChainReRender])
    const selectedList = useMemo(() => {
        let tempArr = [];
            (selectedLayout?.metaInfo?.length && image?.length) && image?.forEach((obj, index) => {
                let tempObj = {...obj, metaPos: selectedLayout?.metaInfo[index] !== undefined ? selectedLayout?.metaInfo[index] : null}
                tempArr.push(tempObj)
            });
          return tempArr;
    }, [image, selectedLayout?.metaInfo])
    useEffect(()=> {
        let loading = (frameCount === cntr) && (selectedNfts.length === nftCntr);
        if(isMultiGallery)
            dispatch('setLoadingLayout', {loading: (!loading), loadingIndex: fileName ? findNumberFromString(fileName) : 0})
        setAllLoad(loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cntr, nftCntr, frameCount, selectedNfts, fileName])
    return (
        <div className={`${className}  preview-frame relative preview-gallery-new rounded-[.5rem]`}>
            <div ref={containerRef} className={`${addedGallery ? 'added-frame-image' : 'wall-image'} frame-image rounded-[.5rem`} style={{
                backgroundImage: `url(${src})`,
            }}>
                <div ref={exportRef} className={`relative rounded-[.5rem] ${selectedNfts?.length >= 8 ? 'gallery-frame-wall' : `layout-card-${selectedNfts?.length}`} grid-frame gallerylayout ${multiViewClass}`} >
                    {selectedNfts?.length === 1 && 
                        <LayoutCard1 
                            isMultiGallery={isMultiGallery} loadingData={allLoad}
                            loadSmallSize={loadSmallSize} parentCards={selectedList} onNFTLoad={onNFTloads} 
                            clearCache={clearCache} onFrameLoaded={onFrameLoaded} 
                            imageRatioCalculated={imageRatioCalculated} 
                            onChainRenders={onChainRenders} onChainReRender={onChainReRender} />}
                    {selectedNfts?.length === 2 && 
                        <LayoutCard2 
                            isMultiGallery={isMultiGallery} loadingData={allLoad} 
                            loadSmallSize={loadSmallSize} parentCards={selectedList} onNFTLoad={onNFTloads} 
                            clearCache={clearCache} onFrameLoaded={onFrameLoaded} 
                            imageRatioCalculated={imageRatioCalculated} 
                            onChainRenders={onChainRenders} onChainReRender={onChainReRender} />}
                    {selectedNfts?.length === 3 && 
                        <LayoutCard3 
                            isMultiGallery={isMultiGallery} loadingData={allLoad} 
                            loadSmallSize={loadSmallSize} parentCards={selectedList} onNFTLoad={onNFTloads} 
                            clearCache={clearCache} onFrameLoaded={onFrameLoaded} 
                            imageRatioCalculated={imageRatioCalculated} 
                            onChainRenders={onChainRenders} onChainReRender={onChainReRender} />}
                    {selectedNfts?.length === 4 && 
                        <LayoutCard4 
                            isMultiGallery={isMultiGallery} loadingData={allLoad} 
                            loadSmallSize={loadSmallSize} parentCards={selectedList} onNFTLoad={onNFTloads} 
                            clearCache={clearCache} onFrameLoaded={onFrameLoaded} 
                            imageRatioCalculated={imageRatioCalculated} 
                            onChainRenders={onChainRenders} onChainReRender={onChainReRender} />}
                    {selectedNfts?.length === 5 && 
                        <LayoutCard5 
                            isMultiGallery={isMultiGallery} loadingData={allLoad} 
                            loadSmallSize={loadSmallSize} parentCards={selectedList} onNFTLoad={onNFTloads} 
                            clearCache={clearCache} onFrameLoaded={onFrameLoaded} 
                            imageRatioCalculated={imageRatioCalculated} 
                            onChainRenders={onChainRenders} onChainReRender={onChainReRender} />}
                    {selectedNfts?.length === 6 && 
                        <LayoutCard6 
                            isMultiGallery={isMultiGallery} loadingData={allLoad} 
                            loadSmallSize={loadSmallSize} parentCards={selectedList} onNFTLoad={onNFTloads} 
                            clearCache={clearCache} onFrameLoaded={onFrameLoaded} 
                            imageRatioCalculated={imageRatioCalculated} 
                            onChainRenders={onChainRenders} onChainReRender={onChainReRender} />}
                    {selectedNfts?.length === 7 && 
                        <LayoutCard7 
                            isMultiGallery={isMultiGallery} loadingData={allLoad} 
                            loadSmallSize={loadSmallSize} parentCards={selectedList} onNFTLoad={onNFTloads} 
                            clearCache={clearCache} onFrameLoaded={onFrameLoaded} 
                            imageRatioCalculated={imageRatioCalculated} 
                            onChainRenders={onChainRenders} onChainReRender={onChainReRender} />}
                    {selectedNfts?.length >= 8 && 
                        <LayoutCard8 
                            isMultiGallery={isMultiGallery} loadingData={allLoad} 
                            loadSmallSize={loadSmallSize} parentCards={selectedList} onNFTLoad={onNFTloads} 
                            clearCache={clearCache} onFrameLoaded={onFrameLoaded} 
                            imageRatioCalculated={imageRatioCalculated} 
                            onChainRenders={onChainRenders} onChainReRender={onChainReRender} />}
                </div></div>
        </div>
    )
}

export default memo(GalleryPreviewImage)