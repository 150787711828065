import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import { useEffect, useState } from "react";

const FrameViewer = (props) => {
    const [frameSrc, setFrameSrc] = useState(null)
    const [key, setKey] = useState(0);
    const [imageLoaded, setImageLoaded] = useState(false);
    useEffect(() => {
        setFrameSrc(props?.frameURL);
        setKey(prevKey => prevKey + 1 + (props?.onChainReRender ?? 0)); //if same url updating to make force re-mount(to call onload), using key
        return ()=> setImageLoaded(false);
    }, [props?.frameURL, props?.onChainReRender]);
    const onLoad = (key) => {
        setImageLoaded(true);
        props?.frameLoaded(key);
    }
    return (
        <div className={props?.mainClass}>
            <img crossOrigin="anonymous" key={key} data-id={key} onLoad={onLoad} className={imageLoaded ? props?.className : 'hidden'} alt="NoImg" src={frameSrc} style={props?.maxHeight && { "maxHeight": props?.maxHeight }} />
            {!imageLoaded && <SkeletonLoader className={`absolute w-full h-full z-[10]`} count={1}/>}
            {props?.overlayClass && <div className={props?.overlayClass}></div>}
        </div>
    )
}
export default FrameViewer;