import styles from "../../../../../Styles/MyCollections.module.scss"
import React, { useCallback, useEffect, useState } from 'react'
import NftZoomed from "App/Components/NftZoomed";
import useFrames from "App/hooks/use-frames";
import useScreenSize from "App/hooks/use-screen-size";
import adaIcon from 'Assets/Images/ada.svg';
import { formatLargeNumber, getCeilValue, numberWithCommas } from "App/Helper/utilities";
import CustomTooltip from "App/Components/Ui/CustomTooltip";

const NftRow = (props) => {
    const screenSize = useScreenSize()?.width;
    const [frameDetails, setFrameDetails] = useState(null)
    const { getFrameFromStore } = useFrames();
    const collectionSelectHandler = useCallback(() => {
      if(!props?.noClick)  props.onCollectionSelect(props.nft)
    }, [props])
    const openDetailsTabHandler = useCallback((frame) => {
        setTimeout(() => {
            props.onClick({ nft: props.nft, frame: frame })
        }, 280)
    }, [props])
    const ImageClickHandler = useCallback((e, frame) => {
        switch (e.detail) {
            case 1:
                openDetailsTabHandler(frame)
                break
            case 2:
                collectionSelectHandler()
                break
            default:
        }
    }, [collectionSelectHandler, openDetailsTabHandler])
    useEffect(() => {
        if (!!props.framesList)
            getFrameId()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.framesList])

    const getFrameId = () => {
        for (let frames of props.framesList) {
            if (frames.asset === props.nft.asset) {
                getFrame(frames.frameId)
            }
        }
    }
    const frameApiResult = (res) => {
        const data = res
        let temp = {
            name: data.name,
            image: data.s3ImageUrl,
            type: data.category.name,
            style: data.style.name,
            color: data.color.name,
            features: data.addOn.name,
            complexity: data.complexity.name,
            metaInfo: data.metaInfo
        }
        setFrameDetails(temp)
    }
    const getFrame = (frameId) => {
        getFrameFromStore(frameId, (res) => {
            frameApiResult(res)
        })
    }
    // const copyClipHandler = () => {
    //     var textArea = document.createElement("textarea");
    //     textArea.value = props.nft.policy;
    
    //     // Avoid scrolling to bottom
    //     textArea.style.top = "0";
    //     textArea.style.left = "0";
    //     textArea.style.width = ".09px";
    //     textArea.style.opacity = "0";
    
    //     textArea.style.position = "fixed";
    
    //     document.body.appendChild(textArea);
    //     textArea.focus();
    //     textArea.select();
    //     document.execCommand("copy");
    //     dispatch("showToast", {
    //       toast: { toastMode: "success", message: "Copied to clipboard" },
    //     });
    //     document.body.removeChild(textArea);
    //   };

      function formatDate(dateString) {
        if (isNaN(new Date(dateString))) {
            return 'None';
        }
        
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    }

    //   const setCategoryView=(data)=>{
    //     return data?.subCategoryName?`${data?.categoryName}-${data?.subCategoryName}`:data?.categoryName?? "-"
    //   }
    return (
        <tr key={props.index}>
            {/* Collection */}
            <td className={`${(props?.selectedNft || screenSize <= 550) && 'flex-col'} items-center gap-4 w-[22%]`}>
                <div className="flex items-center gap-2">
                    <div className={`${styles["nft-img"]} ${!props?.noClick&&"cursor-pointer"}`}>
                        {!!(props.nft.collectionImage256??props.nft.nftcdnimage256) && <NftZoomed displayNft="list" className="collection-table-image w-[4rem] h-[4rem]" data-index={props.index} onClick={ImageClickHandler} nft={props.nft?.collectionImage256??props.nft.nftcdnimage256} frameDetails={frameDetails} />}

                    </div>  
                    <div className={`${!props?.noClick && "cursor-pointer"} flex 
                    flex-col gap-2 w-full`} onClick={ImageClickHandler}>
                        <div className="truncate text-left z-[1] max-w-[80%] text-[0.8rem]"  data-tooltip-content={props.nft.collectionName ?? props.nft.name} data-tooltip-id={props.nft.asset}>
                        <CustomTooltip reactTooltip={true} tooltipId={props.nft.asset} />
                        {props.nft.collectionName ?? props.nft.name ?? ''}
                        </div>
                        <div className="flex items-center gap-1 bg-[var(--cu-black-800)] w-fit px-2 rounded-xl text-[0.75rem]">
                            <img src={adaIcon} alt="Icon" className="filter w-[1.5rem] h-[1.5rem]"></img>
                            <span className="mb-[0.1rem]">
                                {`Cardano` ?? props.nft?.collectionName ?? '-'}
                            </span>
                        </div>
                    </div>
                </div>
            </td>

            {/* community */}
            <td className="w-[9%]">
                {props.nft?.communityName ? <div className="text-left">
                <CustomTooltip reactTooltip={true} tooltipId={`${props.nft.asset}+${props.nft.collectionName ?? props.nft.name}`} />
                    <p data-tooltip-id={`${props.nft.asset}+${props.nft.collectionName ?? props.nft.name}`} data-tooltip-content={props.nft?.communityName} className="truncate text-[0.8rem] max-w-[10rem]">
                        {props.nft?.communityName ?? 'No Community'}
                    </p>
                </div> :
                    <div className="text-[0.8rem] text-left">
                        No Community
                    </div>}
            </td>

            {/* Floor */}
            <td className="w-[7%]">
                {props.nft?.floorPrice ?
                    <div className="flex items-center gap-1 justify-end">
                        <img src={adaIcon} alt="Icon" className="filter w-[1.5rem] h-[1.5rem] z-1"></img>
                        <span>
                            {props.nft?.floorPrice ?? '-'}
                        </span>
                    </div>
                    :
                    <div className="text-right">
                {'-'}
            </div>
                }
            </td>

            {/* My Assets */}
            <td className="w-[9%]">
                <div className="">
                    <span>
                        {`${props.nft?.quantity}/${numberWithCommas(props.nft?.supply) ?? numberWithCommas(props.nft?.assetCount) ?? '-'}`}
                    </span>
                </div>
            </td>

            {/* My Value */}
            <td className="w-[9%]">
            {props.nft?.myValue ?
                    <div className="flex items-center gap-1 justify-end">
                        <img src={adaIcon} alt="Icon" className="filter w-[1.5rem] h-[1.5rem] z-1"></img>
                        <span>
                            {formatLargeNumber(props.nft?.myValue) ?? '-'}
                        </span>
                    </div>
                    :
                    <div className="text-right">
                    {'-'}
                </div>
                }
            </td>

            {/* Total Volume */}
            <td className="w-[9%]">
                {getCeilValue(props.nft?.allVolume) ?
                    <div className="flex items-center justify-end">
                        <img src={adaIcon} alt="Icon" className="filter w-[1.5rem] h-[1.5rem] z-1"></img>
                        <span>
                            {formatLargeNumber(getCeilValue(props.nft?.allVolume)) ?? '-'}
                        </span>
                    </div>
                    :
                    <div className="text-right">
                    {'-'}
                </div>
                }
            </td>

            {/* Owners */}
            <td className="w-[7%]">
                <div className="">
                    <span>
                        {numberWithCommas(props.nft?.ownerCount) ?? '-'}
                    </span>
                </div>
            </td>

            {/* Avg Holdings or Pieces per owner */}
            <td className="w-[9%]">
                <div className="text-right">
                   {props.nft?.piecesPerOwner ? props.nft?.piecesPerOwner?.toFixed(1) : `NA`}                  
                </div>
            </td>

            {/* Mint date */}
            <td className="w-[9%]">
                {props.nft?.mintDate ?
                <div className="text-right">
                    {formatDate(props.nft?.mintDate)}
                </div>
                 : 
                 <div className="text-right">
                {'-'}
            </div>
            }
            </td>

            {/* Lock date */}
            <td className="w-[9%]">
            {props.nft?.lockDate ? <div className="text-right">
                {formatDate(props.nft?.lockDate)}
                </div> 
                : 
                <div className="text-right">
                {'-'}
            </div>
            }
            </td>
        </tr>
    )
}

export default NftRow