import CategoryBox from "App/Components/CategoryBox";
import { categoryList } from "App/Helper/getDate";
import CollectionItemGallery from "App/Pages/HomeV2/Components/Creators/Components/CollectionItemGalley";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import userAvatar from "Assets/Icons/logo-square.svg";

const CommunityCardBox = (props) => {
  const targetRef = useRef(null);
  const collectionTargetRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const handleCardClick = (e) => {
    const id = parseInt(e.currentTarget?.dataset?.id);
    props?.onCardClick && props?.onCardClick(id, props?.id);
  };

  const updateHeight = () => {
    if (targetRef.current) {
      if (targetRef.current) {
        setCardWidth(targetRef.current.offsetWidth);
      }
    }
  };
  useLayoutEffect(() => {
    updateHeight();
    window.addEventListener(
      "resize",
      () => {
        setTimeout(() => {
          updateHeight();
        }, 1000);
      },
      false
    );
  }, []);
  useEffect(() => {
    updateHeight();
  }, [props?.list]);
  const blurHandler = () => {
    setIsHovered(false);
  };

  const onMouseBlur = useCallback(() => {
    setIsHovered(false);
  }, []);

  const onMouseFocus = useCallback(() => {
    setIsHovered(() => true);
  }, []);
  const updateCollectionHeight = () => {
    const root = document.documentElement;
    if (collectionTargetRef.current && isHovered) {
      if (collectionTargetRef.current) {
        root?.style.setProperty(
          "--collection-sm-card-height",
          `${collectionTargetRef.current.offsetWidth}px`
        );
      }
    }
  };
  useLayoutEffect(() => {
    updateCollectionHeight();
    window.addEventListener(
      "resize",
      () => {
        setTimeout(() => {
          updateCollectionHeight();
        }, 1000);
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered]);

  useEffect(() => {
    updateCollectionHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered]);
  return (
    <div
      className={`card mb-2 community-box-wrapper`}
      ref={targetRef}
      key={props?.data?.key ?? props?.data?.id}
      data-id={props?.data?.id}
      onClick={handleCardClick}
      onMouseEnter={onMouseFocus}
      onMouseLeave={onMouseBlur}
      onBlur={blurHandler}
      onMouseOverCapture={onMouseFocus}
    >
      <div className="flex  flex-col">
        <div className={`card-image-container relative`}>
          <div className={`hover-community`}>
            <p className="description">
              {" "}
              {props?.data?.description &&
              props?.data?.description?.length < 155
                ? props?.data?.description
                : `${props?.data?.description?.slice(0, 155)}...`}
            </p>
            <CollectionItemGallery
              collectionCount={
                props?.data?.collectionsCount ||
                props?.data?.collectionCount ||
                0
              }
              assetsCount={props?.data?.totalAssetCount || 0}
              galleryCount={props?.data?.galleryCount || 0}
            />
            <div className="collection-box mt-[0.125rem]">
              {props?.data?.collectionFilePath256
                ?.slice(0, 11)
                ?.map((el, i) => {
                  return (
                    <div
                      className="collection-img"
                      key={i}
                      ref={collectionTargetRef}
                    >
                      <img src={el} alt="" />
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            className="card-image"
            style={{
              backgroundImage: `url('${props?.data?.thumbnailPicture512}')`,
            }}
          >
            <div className="screenshot h-full">
              <img src={props?.data?.assetImg} alt="" className="h-full" />
            </div>
          </div>
        </div>
        <div className="card-detail-container flex items-start justify-start">
          <div className="card-details">
            <div className="detail-profile">
              <img src={props?.data?.displayPicture256||userAvatar} alt="img" />
            </div>
            <div
              className={`detail-name text-left flex flex-col gap-1 items-start justify-start has-categories`}
            >
              <div className="detail-name text-left pt-2">
                <span>{props?.data?.name}</span>
              </div>

              {props?.data?.categoryCount && (
                <div
                  className="flex categories"
                  style={{ width: `${cardWidth - 65}px` }}
                >
                  <div
                    className="flex gap-2 mt-2 flex-wrap"
                    //title={categoryList(props?.data?.categoryCount)}
                    // onMouseEnter={onMouseBlur}
                    // onMouseLeave={onMouseBlur}
                    // onBlur={blurHandler}
                    // onMouseOverCapture={onMouseBlur}
                  >
                    {Object.entries(props?.data?.categoryCount)
                      ?.slice(0, 3)
                      ?.map(([key, val], i) => (
                        <CategoryBox key={i} data={key} value={val} />
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          {props?.type === "collector" && (
            <div className="card-details">
              <div className="detail-name text-left">
                <span>Rank 2</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommunityCardBox;
