import { useCallback, useEffect, useRef, useState } from "react";
import { useStore } from 'App/hooks-store/store';
import { useNavigate } from 'react-router-dom';
import '../../Community.scss';
import useHttp from 'App/hooks/use-http'
import ListingCollection from "App/Pages/CommunityCollection/Collections/Components/ListingCollection";
import plus from "Assets/Icons/plus.svg"
import useScreenSize from "App/hooks/use-screen-size";
import FormatField from "App/Components/Ui/FormatField";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import { useSelector } from "react-redux";
import PageListing from "App/Pages/Discover/Communities/Components/CommunityCard";
import ModalOuter from "App/Components/Ui/ModalOuter";
import closeIcon from "Assets/Icons/close.svg"
import CommunityUpdate from "./Components/Details";
import CollectionUpdate from "App/Pages/CommunityCollection/Collections/Components/UpdateCollection";
import RemoveWarning from "App/Components/RemoveWarning";
import AddPlusIconButton from "App/Components/AddPlusIconButton";

const paginationConfig = {
    perPage: 100
}
const Community = () => {
    const screensize = useScreenSize()?.width;
    const [{ currentUserId, topActiveMenu },dispatch] = useStore();
    const navigate = useNavigate()
    const { triggerAPI } = useHttp();
    const [totalPage, setTotalPage] = useState(0);
    const [communityList, setCommunityList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [collectionType, setCollectionType] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopupForCollection, setOpenPopupForCollection] = useState(false);
    const [refetchCollections, setRefetchCollections] = useState(false)
    const targetRef = useRef();
    
    
    useEffect(() => {
            getCommunityList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserId])
    

    const getCommunityListResult = useCallback((res) => {
        setLoading(false);
        const { data } = res;
        setCommunityList(data?.list||[])
        setTotalPage(data?.pageMeta.totalItems) 
        setOpenPopup(false)       
    }, [])
    const getCommunityList = useCallback(() => {
        if(currentUserId) {
            triggerAPI({
                url: `community/get`, method: 'post', data: {
                    page: 1,
                    items: paginationConfig?.perPage,
                    filter: { userId: currentUserId },
                    search: ""
                  }
            }, getCommunityListResult);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCommunityListResult,screensize, triggerAPI, currentUserId])

    const navigateToAdd = (e) => {
        setOpenPopup(true);
        // navigate(`/community/update-data`)
    }

    const cardClick = (id) => {
        navigate(`/community/${id}/overview`)
    }
    
    const collectionEditClick = (e,id) => {
        e && e.stopPropagation()
        setOpenPopupForCollection(true)
        const url = id ? `/community?id=${id}` : `/community?type=requested`;
        navigate(url)  
    }

    const onCloseCollectionPopup = ()=> {
        setCollectionType('')
        setOpenPopupForCollection(false)
        navigate(`/community`)
    }

    const collectionRequestedEditClick = (e,id) => {
        e && e.stopPropagation()
        setCollectionType('requested')
        setOpenPopupForCollection(true)
        const url = id ? `/community?id=${id}` : `/community`;
        navigate(url)  
    }
const onFetch=() => {
    setOpenPopupForCollection(false);
    navigate("/community")
    setTimeout(()=> setRefetchCollections((prev)=>!prev),500);
}
const cardClickHandler=(e,pId)=>{
    const communityId = e?.currentTarget?.dataset?.communityid;
    navigate(`/community/${communityId}/collection/${pId}`)
}
return (
    <div className="flex flex-col sm:gap-[1.875rem] gap-[1rem] sm:mt-0 community-content-section">
        {/* Popup for Community */}
        {openPopup && <ModalOuter onClose={() =>{}}
            openModal={openPopup}
            parentClass={openPopup ? '!z-[10]' : '!hidden !z-[10]'}
            contentClass={'animated-slideIn w-full max-w-[1000px] h-auto max-h-[91dvh] overflow-auto p-5 bg-[var(--cu-black-700)] border border-[var(--cu-black-100)] rounded-lg mt-[5dvh] mx-[5%]'}
        >
            <div className="w-full flex items-center justify-between" >
                <span />
                <h2 className="text-[1.125rem]">Community</h2>
                <button onClick={() => setOpenPopup(false)}><img src={closeIcon} alt="close" /></button>
            </div>
            <CommunityUpdate onClose={() => setOpenPopup(false)} onFetch={getCommunityList} />
        </ModalOuter>}
        {/* Popup for Collection */}
        {openPopupForCollection && <ModalOuter onClose={() =>{}}
            openModal={openPopupForCollection}
            parentClass={openPopupForCollection ? '!z-[10]' : '!hidden !z-[10]'}
            contentClass={'animated-slideIn w-full max-w-[850px] h-auto max-h-[85dvh] overflow-y-auto py-5 px-10 bg-[var(--cu-black-700)] border border-[var(--cu-black-100)] rounded-lg mt-[5dvh] mx-[5%]'}
        >
            <div className="w-full flex items-center justify-between" >
                <span />
                <h2 className="text-[1.125rem]">Verify Collection</h2>
                <button onClick={onCloseCollectionPopup}><img src={closeIcon} alt="close" /></button>
            </div>
            <CollectionUpdate communityRefetch={getCommunityList} isType={collectionType} onClose={onCloseCollectionPopup} onFetch={onFetch} />
        </ModalOuter>}
        <div className="flex flex-col sm:gap-[0.25rem] gap-[1rem] community-container">
            <div className="flex sm:items-center items-center header community-title-sec flex-row gap-4">
                <h3 className="community-header">Communities <span>{`(${totalPage})`}</span></h3>
                {!!(communityList?.length<2)&& !loading&&
                <AddPlusIconButton onClick={navigateToAdd} className={"mt-1"}/>
                }
            </div>
           

       <DetailsSkeltonCard
       show={loading}
       height={screensize > 551 ? "100%" : "h-[10rem]"}
       count={3}
       inline={true}
       details={"img-2-line"}
       noAbsolute
       parentDiv="grid community-grid mt-0 aspect-pt-six"
       containerClassName={`flex`}
     />           
            {![...communityList]?.length && !loading && <div className="flex items-center justify-center no-data-text">Group all of your collections into one community to easily connect with all of your holders.</div>}
       {!!communityList?.length &&  
       <PageListing
         list={[...communityList]}
         onCardClick={cardClick}
       /> 
    //    <div className={`${ screensize > 767 ? 'grid  home-grid list-container' : 'grid  grid-cols-2 list-container'}`}>
    //             {[...communityList]?.map((list, index) => {
    //                 return (
    //                     <div ref={targetRef} data-id={list?.id} key={list?.id} onClick={cardClick} className="card" >
    //                         <div className='flex gap-2 flex-col'>
    //                             <div className="card-image-container">
    //                                 <div className="card-image" style={{backgroundImage:`url('${list?.thumbnailPicture256}')`}}>
    //                                 </div>
    //                             </div>
    //                             <div className="card-detail-container flex items-center justify-between">
    //                                 <div className='card-details'>
    //                                     <div className='detail-profile'>
    //                                         <img src={list?.displayPicture} alt='img' />
    //                                     </div>
    //                                     <div className='detail-name text-left pt-[.3rem]'>
    //                                         <FormatField type="sub" maxWidth={20} value={list?.name} />
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 )
    //             })}
    //         </div>
            }

        </div>
        <ListingCollection onCardClick={cardClickHandler} onEditClick={collectionEditClick}  communityList={communityList} refetch={refetchCollections} />
        <ListingCollection onCardClick={()=>{}} onEditClick={collectionRequestedEditClick} communityList={communityList} type="requested" refetch={refetchCollections} />
    </div>
)
}

export default Community;
