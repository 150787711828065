import SearchIcons from "Assets/Images/SearchIcons.svg"
import closeIcon from "Assets/Icons/close.svg"
import "./SearchFilter.scss"
import { useEffect, useRef } from "react"

const SearchFilter = (props) => {
    const inputOnchange = (e) => {
        props.onSearch(e.target.value)
    }
let searchRef=useRef(null)
useEffect(()=>{
searchRef.current.focus()
},[])
    return (
        <div className={`${props?.className ?? ''}`}>
            {!props?.hideLabel && <div className="sort-txt">
                <p>Search:</p>
            </div>}
            <div className="search search-position" >
                {/* <div className="search-icon-search cursor-pointer">
                    <img alt="NoImg" className="h-[1rem]" src={SearchIcons} />
                </div> */}
                 <input type="text" ref={searchRef} value={props?.value} onChange={inputOnchange} onKeyUp={inputOnchange} placeholder="Search"  />
                <div className="search-icon">
                    <img className="img-icon" src={SearchIcons} alt="NoImg" />
                </div>
                <div className="search-icon-close cursor-pointer" data-id="close" onClick={props?.onClick}>
                    <img className="img-icon h-[1rem]" alt="NoImg" src={closeIcon} />
                </div>
            </div>
        </div>
    )

}

export default SearchFilter