import AnnouncementsList from "App/Pages/CommunityCollection/Community/Components/Announcements/Components/AnnouncementLists";
import DiscoverCollection from "../DiscoverCollection";
import DiscoverGallery from "../DiscoverGallery";
import "./OverView.scss";
import { useStore } from "App/hooks-store/store";
import { EventsCard } from "App/Components/Ui/EventCard";
import addIcon from "Assets/Icons/add-icon.svg";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import Announcements from "App/Pages/CommunityCollection/Community/Components/Announcements";
import Events from "App/Pages/CommunityCollection/Community/Components/Events";
import CommunityGallery from "App/Pages/CommunityCollection/Community/Components/CommunityGallery";
import useScreenSize from "App/hooks/use-screen-size";

const OverView = (props) => {
  const [{ spinnerMode }] = useStore(false);
  const screensize = useScreenSize()?.width;
  const galleryListSlice = (array) => {
    if (!Array.isArray(array) || array.length < 3) {
      return array;
    }

    return array.slice(0, 3);
  };
  return (
    <div className="flex flex-col gap-[0.5625rem] sm:pb-0 pb-[4rem]">
      <div className="flex flex-col gap-4">
        <div className="head-title">
          <span className="title flex gap-4 items-center">
            Collection {`(${props?.totalCollectionCount ?? "0"})`}
            {!!(props?.page === "community") && (
               <span className={`add-img ${(props?.communityData&&!props?.collectionLoader)?"cursor-pointer":"cursor-no-drop"} mt-[3px]`}>
                <img src={addIcon} onClick={props?.createCollection} alt="" />
              </span>
            )}
          </span>
          {props?.collectionList && !!props?.collectionList?.length && (
            <span
              className="link"
              data-id={props.page === "creators" ? 1 : "collection"}
              onClick={props?.handleTabs}
            >
              See all
            </span>
          )}
        </div>
        {typeof props?.totalCollectionCount !== "number" ||
        props?.collectionLoader ? (
          <DetailsSkeltonCard
            show={true}
            height={"100%"}
            count={5}
            inline={true}
            details={"img-2-line"}
            noAbsolute
            containerClassName={`flex`}
          />
        ) : (
          <div className="overview-content mt-[0.5625rem]">
            <DiscoverCollection
              className={"overview-collection"}
              hidePagination={true}
              collectionList={props?.collectionList}
              perPage={props?.perPage}
              edit={props?.page === "community"}
              delete={props?.page === "community"}
              reFetch={props?.getCollectionList}
              collectionEditCardClick={props?.collectionEditCardClick}
              totalItems={props?.totalCollectionCount}
              collectionCardClick={props?.collectionCardClick}
              classNames={"ml-[1rem] mr-[2rem]"}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-[0.71429rem]">
        <div className="head-title">
          <span className="title flex gap-4 items-center">
            Galleries {`(${props?.totalGalleryCount ?? 0})`}
            {!!(props?.page === "community") && !!props?.totalCollectionCount&&(
             <span className={`add-img ${(props?.communityData&&!props?.galleryLoader)?"cursor-pointer":"cursor-no-drop"} mt-[3px]`}>
                <img onClick={props?.navToCreateGallery} src={addIcon} alt="" />
              </span>
            )}
          </span>
          {!!props?.galleryList?.length && (
            <span
              className="link"
              data-id={props.page === "creators" ? 2 : "my-galleries"}
              onClick={props?.handleTabs}
            >
              See all
            </span>
          )}
        </div>
        {props?.page === "community" ? (
          <CommunityGallery
            notFull
            galleryList={galleryListSlice(props?.galleryList)}
            onMenuTabs={props?.setMenuTabs}
            setReloadCount={props?.setReloadCount}
            callBackApi={props?.getGalleryList}
            handleCardClick={props?.handlePreviewGallery}
            refetchGallery={props?.refetchGallery}
            galleryLoader={props?.galleryLoader}
            setRefetchGallery={props?.setRefetchGallery}
            onPreviewGalleryDetails={props?.handlePreviewGalleryDetails}
          />
        ) : props?.galleryLoader ? (
          <DetailsSkeltonCard
            show={true}
            height={"100%"}
            count={screensize <= 550 ? 2 : 3}
            inline={true}
            details={"1-line"}
            noAbsolute
            parentDiv="grid home-grid mt-0 aspect-pt-six details-gap"
            containerClassName={`flex`}
          />
        ) : (
          <DiscoverGallery
            galleryList={galleryListSlice(props?.galleryList)}
            className={"home-grid"}
            handleCardClick={props?.handlePreviewGallery}
            totalItems={props?.totalGalleryCount}
            hidePagination={true}
            perPage={props?.perPage}
            showSlider={true}
            communityOverview={props.communityOverview}
          />
        )}
      </div>
      <div className="flex flex-col gap-[0.71429rem] pb-[2rem]">
        <div className="flex gap-[1.43rem] w-full flex-col">
          <div className="w-full">
            <div className="head-title">
              <span className="title flex gap-4 items-center">
                Posts
                {!!(props?.page === "community") && (
                  <span
                    onClick={props?.newAnnouncementHandler}
                    className={`add-img ${(props?.communityData&&!props?.postLoader)?"cursor-pointer":"cursor-no-drop"} mt-[3px]`}
                  >
                    <img src={addIcon} alt="" />
                  </span>
                )}
              </span>
              {!!props?.announcementList?.length && (
                <span
                  className="link"
                  data-id={props.page === "creators" ? 3 : "posts"}
                  onClick={props?.handleTabs}
                >
                  See all
                </span>
              )}
            </div>
            <div className="overview-content flex flex-col gap-[1.25rem] mt-[0.5625rem]">
              {props?.page === "community" ? (
                <Announcements
                  data={props.announcementList}
                  notFull
                  page={props.page}
                  postLoader={props?.postLoader}
                  createEveAnnHandler={props?.createEveAnnHandler}
                  callBackApi={props?.getAnnouncementList}
                />
              ) : props?.postLoader ? (
                <div className={`posts-skeleton-wrap`}>
                  <DetailsSkeltonCard
                    show={true}
                    height={"100%"}
                    count={4}
                    inline={true}
                    noAbsolute
                    details="posts"
                    containerClassName={` flex pt-[1rem]`}
                  />
                </div>
              ) : (
                <AnnouncementsList
                  data={props.announcementList}
                  normalAccordion={true}
                  page={props.page}
                />
              )}
            </div>
          </div>
          <div className="w-full">
            <div className="head-title">
              <span className="title flex gap-4 items-center">
                Events
                {!!(props?.page === "community") && (
                  <span
                    onClick={props?.newEventHandler}
                    className={`add-img ${(props?.communityData&&!props?.eventLoader)?"cursor-pointer":"cursor-no-drop"} mt-[3px]`}
                  >
                    <img src={addIcon} alt="" />
                  </span>
                )}
              </span>
              {!!props?.eventsList?.length && (
                <span
                  className="link"
                  data-id={props.page === "creators" ? 4 : "events"}
                  onClick={props?.handleTabs}
                >
                  See all
                </span>
              )}
            </div>
            {props?.page === "community" ? (
              <Events
                notFull
                type="events"
                eventLoader={props?.eventLoader}
                data={props?.eventsList}
                createEveAnnHandler={props?.createEveAnnHandler}
                callBackApi={props?.getEventList}
              />
            ) : props?.eventLoader ? (
              <div className="event-skeleton-wrap">
                <DetailsSkeltonCard
                  show={true}
                  height={"100%"}
                  count={screensize > 1620 ? 4 : 3}
                  inline={true}
                  noAbsolute
                  containerClassName={` flex pt-[1rem]`}
                />
              </div>
            ) : (
              <div
                className={`${
                  props?.eventsList?.length
                    ? "overview-content-event grid "
                    : ""
                }   gap-4 mt-[0.5625rem] `}
              >
                {props?.eventsList?.length
                  ? props?.eventsList.map((event, i) => {
                      const matchedCommunity = !props.communityOverview
                        ? props?.collectionList?.find(
                            (communityItem) =>
                              communityItem.community.name ===
                              event.communityName
                          )
                        : props.communityData;
                      return (
                        <EventsCard
                          data={event}
                          selectedCard={props?.selectedEvent}
                          community={matchedCommunity}
                          isCreator={false}
                          key={i}
                          fromCommunity={props.communityOverview}
                        />
                      );
                    })
                  : !spinnerMode && (
                      <div className="flex items-center no-data justify-center">{`There are currently no events.`}</div>
                    )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverView;
