/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from "react"
import "./NftGalleries.scss";
import MyGallery from "./Component/MyGallery";
import GalleryCollection from "./Component/MyGallery/Components/GalleryCollection";
import { useEffect } from "react";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback } from "react";
import { getInitial } from "App/Helper/formValidation";
import useCollectionLoader from "App/hooks/use-collectionLoader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SkeltonCard from "App/Components/Ui/SkeltonCard";
import useAuth from 'App/hooks/use-auth';
import WalletSelectPop from 'App/Components/Ui/WalletSelectPop'
import storage from "App/Helper/storage";
import DeleteGallery from "App/Components/Ui/DeleteGallery";
import { deepCopy, getAvailableGallery } from "App/Helper/utilities";
import useCollectionDropDown from "App/hooks/use-collection-dropdwon";
import { useDispatch, useSelector } from "react-redux";
import { getFormInitiated, setCurrentFormValues, setFormInitiated } from "App/ReduxStore/Slicers/gallerySlice";
import useFrames from "App/hooks/use-frames";
import { getLoggingOutStatus, getShowWalletPop, setShowWalletPop } from "App/ReduxStore/Slicers/authSlice";
const NftGallery = () => {
    const { communityid, pgaction, galleryId } = useParams();
    const navigate = useNavigate()
    const { pathname } = useLocation();
    const [warningPopup, setWarningPopup] = useState(false)
    const { triggerAPI } = useHttp();
    const [spinnerShow, setSpinnerShow] = useState(false)
    const [galleryList, setGalleryList] = useState([])
    const [galleryListNew, setGalleryListNew] = useState([])
    const [galleryUpdateId, setGalleryUpdateId] = useState('')
    const { sliceCollectionAPI } = useCollectionLoader();
    const [formGroup, setFormGroup] = useState({})
    const [{ tokens, collections, collectionLoadingStatus, currentSelectedNfts,collectionWalletData,
        currentGalleryUpdateId, currentWallet, allCollectionData, currentGallery, currentGalleryAssets,
        currentCommunityGallery, allUserCollection }, dispatch] = useStore(false);
    const [galleryPreview, setGalleryPreview] = useState({ mode: false, fromList: false })
    const [deleteWarning, setDeleteWarning] = useState(false);
    const [dltId, setDltId] = useState(0);
    const [showGallerySkelton, setShowGallerySkelton] = useState(true);
    const isLoggedIn = useAuth()[0]
    const [collectionList, setCollectionList] = useState([]);
    const [finalCollectionList, setFinalCollectionList] = useState([]);
    const [galleryCount, setGalleryCount] = useState(0);
    const [isSubscriptionReached, setSubscriptionReached] = useState(false);
    const { dropDownFilter, updateCollectionFilter, getDropValueByData } = useCollectionDropDown();
    const [collectionLevelLoading, setCollectionLevelLoading] = useState(true);
    const {getUserFrames} = useFrames();
    const dispatchRedux = useDispatch();
    const formInitiated = useSelector(getFormInitiated)
    const showAuthPop = useSelector(getShowWalletPop);
    const loggingOutStatus=useSelector(getLoggingOutStatus)
    const [isCreating, setIsCreating] = useState(false);
    const [isToken,setIsToken] = useState(false);
    useEffect(()=>{
        setIsToken(pgaction === "token")
    },[pgaction])
    const pageTypeUrls = ['add', 'view', 'edit'];  
    const galleryBaseUrl = useMemo(() =>{
        let offset = communityid ? 4 : 3;
        return pathname.split('/').slice(0, offset).join('/');
    }, [pathname, communityid])
    const pagetype = useMemo(() => {
       return pathname?.split('/')[communityid ? 4 : 3]
    },[pathname, communityid])    

    const galleryid = useMemo(() => {
        return parseInt(galleryId) ?? null;
    },[pathname]);

    const actionsUrls = useMemo(() => {
        let temp = ['collection', 'save', 'token'];
        if(galleryid) temp?.push(galleryid)
        return temp;
    },[pathname, galleryid]);
     
    useEffect(() => {
        if((pgaction && !(actionsUrls?.includes(pgaction))) && (pagetype && !(pageTypeUrls?.includes(pgaction)))) {
            navigate(galleryBaseUrl);
        }
    }, [pgaction, pagetype, galleryBaseUrl])
    
    useEffect(()=>{
        if(!loggingOutStatus) 
        dispatchRedux(setShowWalletPop((!isLoggedIn || (collectionWalletData !== null && !collectionWalletData?.length)) && collectionLoadingStatus !== "in-progress"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loggingOutStatus,isLoggedIn,collectionWalletData,collectionLoadingStatus])

    useEffect(() => {
        if(actionsUrls?.includes(pgaction) && !formInitiated) {
            navigate(galleryBaseUrl, {replace: true});
        }
    }, [pgaction, formInitiated, galleryBaseUrl])

    useEffect(() => {
        if(pathname === '/curate/my-galleries') {
            clearAll();
        }
    }, [pathname])
    const isCreator = useMemo(() => {
        return !!(communityid);
    },[communityid]);

    let formGroupMycollection = useMemo(() => {
        let temp = {
            name: {
                value: undefined,
                isRequired: true
            },
            description: {
                value: undefined,
                isRequired: true
            },
            galleryTypeId: {
                value: undefined,
                isRequired: false
            },
            categoryName: {
                value: undefined,
                isRequired: false
            },
            twoDWallBackgroundId: {
                value: undefined,
                isRequired: true
            },
            threeDWallBackgroundId: {
                value: undefined,
                isRequired: false
            },
            assets: {
                value: undefined,
                isRequired: true
            },
            layoutId: {
                value: undefined,
                isRequired: true
            },
            file: {
                value: undefined,
                isRequired: false
            }
        }
        if(communityid) 
            temp = {...temp, 
                rentalPrice : {
                    value: undefined, isRequired: true
                },
                rentalSplit : {
                    value: undefined, isRequired: true, zeroAllowed:true
                }
            }
        return temp;
    }, [communityid]);
    const apiResult = (res) => {
        const { message } = res;
        dispatch("hideSpinner");
        getGalleryList()
        dispatch("showToast", { toast: { toastMode: "success", message } });
      };
    const setPrimaryHandler=(id)=>{
        dispatch("showSpinner");
        triggerAPI(
            {
                url: `gallery/set-primary/${id}`,
                data:{},
                method: "patch",
            },
            apiResult,
            (err) => {
                dispatch("hideSpinner");
                dispatch("showToast", {
                toast: {
                    toastMode: "error",
                    message:
                    err?.response?.data?.error?.message || "Setting primary gallery failed",
                },
                });
            }
        ); 
    }

    useEffect(() => {
        if (galleryid && !Array.isArray(currentGallery)) { 
            loadAssetCollection();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[galleryid, currentGalleryUpdateId])
    useEffect(() => {
        if(!Array.isArray(currentGallery) && galleryid && currentGalleryAssets?.length) {
                let selectedListGallery = [] 
                currentGallery && currentGallery?.assets?.map((x, i) => {
                    let tempObj = currentGalleryAssets?.find((val) => val?.asset === x?.asset)
                    if(tempObj) {
                        tempObj = {...tempObj, frameId: x.frameId ?? null, isLandscape: x?.isLandscape ?? false, sequence: x?.sequence}
                    }
                    return selectedListGallery.push(tempObj)
                })
                selectedListGallery.sort(({sequence:a}, {sequence:b}) => a-b);
                selectedListGallery = selectedListGallery.filter(x => x)
                dispatch('setCurrentSelectedNfts', selectedListGallery)
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentGalleryAssets, currentGallery, galleryid])

    /* My Collection Asset Fetching */
    useEffect(() => {        
        if ((!!currentWallet?.address || isLoggedIn) && !isCreator) {
            getNfts()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collections, currentSelectedNfts, currentWallet?.address])

    const getNfts = useCallback(() => {
        if (collectionLoadingStatus === 'idle') {
            setSpinnerShow(true)
        }
        else {
            buildGalleryCollection(collections, 'my-collect')
            setSpinnerShow(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionLoadingStatus, collections, currentSelectedNfts])

    const buildGalleryCollection = (collection, type) => {
        if (typeof (collection) !== "string" && (!!currentWallet?.address || isLoggedIn)) {
            getFramesList((res) => {
                const collectionArray = deepCopy(collection);
                let newArr = collectionArray?.map(x => {
                    const matchingFrame = res?.find(frame => x.asset === frame.asset);
                
                    if (matchingFrame) {
                        return {...x, frameId: matchingFrame.frameId, isLandscape: matchingFrame.isLandscape ?? false};
                    }
                
                    return x;
                });
                if (currentSelectedNfts?.length) {
                    buildSelectedNftsCollection(newArr)
                } else {
                    setCollectionList(newArr)
                }
            })
        }
        else {
            setCollectionList([])
        }
    }
    const buildSelectedNftsCollection = (value) => {
        const collectionArray = JSON.parse(JSON.stringify(value))
        let newArr = collectionArray?.map(x => {
            const matchingSelected = currentSelectedNfts.find(selected => x.asset === selected.asset);
        
            if (matchingSelected) {
                return {...x, frameId: matchingSelected.frameId, isLandscape: matchingSelected.isLandscape ?? false};
            }
        
            return x;
        });
        
        setCollectionList(newArr);
    }
    /* My Collection Asset Fetching End*/


    const getProfileResult = (res,count,callback) => {
        dispatch('setGalleryCreateCount', res?.data?.availableGalleriesCount);
        setSubscriptionReached(res?.data?.isSubscriptionLimitReached)
        if(!(res?.data?.availableGalleriesCount === -1 || res?.data?.availableGalleriesCount === undefined || (res?.data?.availableGalleriesCount > count))) {
            setWarningPopup(true)
            callback && callback(false)
        } else{
            setWarningPopup(false) 
            callback && callback(true)
        }  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }
   
    const onClickHandler = (count,callback) => {
            triggerAPI({
                url: `user/profile/get`, method: 'get'
            }, (res)=>getProfileResult(res,count,callback));     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const onCreateGalleryClick = () => {
        onClickHandler(galleryCount,(status)=>{
            setIsCreating(true)
           if(status){
            navigate(`${galleryBaseUrl}/add/collection`)
            dispatchRedux(setFormInitiated(true));
            clearAll();
           }
        })
      
    }

    const getFramesList = (callBack) => {
        getUserFrames((data)=> {
            callBack(data)
        },true);
    }

    const getGalleryResult = useCallback((res) => {
        const { list,pageMeta } = res.data;
        setGalleryListNew(list)
        setGalleryCount(()=>pageMeta?.totalItems)
        onClickHandler(pageMeta?.totalItems)
        setTimeout(()=>{
            setShowGallerySkelton(false)
        },600)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isCreator) {
            setGalleryList(galleryListNew)
        }
    }, [galleryListNew, collections, allCollectionData, isCreator])
    
    const getGalleryList = useCallback(() => {
        const token = storage().get('token');
        if(token && !isCreator) {
            let filter = {
                sortBy: "",
                orderBy: ""
            }
            if(!!(currentWallet?.address) || isLoggedIn) {
                setShowGallerySkelton(true)
                triggerAPI({
                    url: `gallery/user/list/get`, data: {
                        page: 1,
                        items: 1000,
                        filter
                    }, method: 'post'
                }, getGalleryResult);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getGalleryResult, triggerAPI, communityid])

    const deleteResult = useCallback((res) => {
        setDeleteWarning(false)  
        setDltId(0)
        if(res >= 0) { 
            if(res) {
                setGalleryUpdateId('')
                dispatch('setCurrentGalleryUpdateId', {});
                getGalleryList()
                navigate(`${galleryBaseUrl}`)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[communityid])
    const onClickDeleteGallery = useCallback((e = '', id) => {
            e && e.stopPropagation();
           setDeleteWarning(true) 
           setDltId(id)       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteResult])
    
    useEffect(() => {
        return () => {
            dispatch('resetAll')
            dispatch("setPage",communityid?"community-gallery":"gallery")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communityid])

    useEffect(() => {
        if(pathname === '/curate/my-galleries') {
            setShowGallerySkelton(true)
            if (!isCreator) {
                if(!!currentWallet?.address || isLoggedIn) { 
                    getGalleryList()
                }            
            }
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWallet?.address, pathname])

    useEffect(() => {
        dispatchRedux(setCurrentFormValues(getInitial(formGroupMycollection)))
        setFormGroup(formGroupMycollection)
    }, [])
    const closeDeleteWarnHandler = () => {
        setDeleteWarning(false);
    }
    const handleEdit  = useCallback((e, id) => {
        e.stopPropagation();
        setIsCreating(true)
        if(collectionLoadingStatus === 'finished') {
            let currentArray = galleryList?.find((gallery) => gallery.id === id )
            let assets = getAvailableGallery(allUserCollection, currentArray?.assets)
            currentArray = {...currentArray, assets}
            dispatch('setCurrentGallery', currentArray)
            navigate(`${galleryBaseUrl}/edit/${id}/save`)
            dispatchRedux(setFormInitiated(true));
        } else {
            dispatch("showToast", { toast: { toastMode: "warning", message: 'Please wait untill Collection loading' } });
        }
    },[galleryList, allUserCollection, collectionLoadingStatus, galleryBaseUrl]);

    const loadAssetCollection = useCallback(() => {
        if(currentGallery?.assets) sliceCollectionAPI(currentGallery?.assets, 'setCurrentGalleryAssets', 'updateCurrentGalleryAssetsLoadingStatus');        
    },[currentGallery?.assets]);


    
    useEffect(() => {
        if(currentCommunityGallery?.id) {
            let currentArray = currentCommunityGallery;
            // eslint-disable-next-line array-callback-return
            let assets = currentArray?.assets?.filter(asset => {
                if(asset) {
                    return {...asset, collectionType: 'communityCollection', networkType: 'MAINNET'};
                }
            })
            currentArray = {...currentArray, assets}
            dispatch('setCurrentGallery', currentArray)
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [currentCommunityGallery?.id])
     
    useEffect(() => {  
        if(isCreator)              
            getCollectionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreator])
      
    const getCollectionListResult = useCallback((res) => {
        setCollectionLevelLoading(false)
        const { data } = res;
        let count = 0;
        let mappeddata = data?.list?.map(d => {
            count++;
            return {
                asset:count.toString(),
                collectionImage:d.filePath,
                collectionName:d.name,
                files:[],
                image:d.filePath,
                collectionImage256:d.filePath256,
                lockDate:d.lockDate,
                mintDate:d.mintDate,
                networkType: "MAINNET",
                newName:d.name,
                onchain_metadata:null,
                policy:d.collectionId,
                quantity:d.assetsCount,
                walletId:null,
                categoryId:d.categoryId,
                community:d.community,
                firstSubcategoryId:d.firstSubcategoryId,
                termsId:d.termsId,
                formats:d.formats,
                types:d.types,
                tags:d.tags,
                collectionEditionsId:d.collectionEditionsId,
                collectionStorageId:d.collectionStorageId,
                aspectRatios:d.aspectRatios,
                category:d.category,
                firstSubcategory:d.firstSubcategory,
                subCategoryName:d?.firstSubcategory?.name,
            }
        });
        if(communityid){
            dispatch("setCommunityGalleryCollections",mappeddata)
        }
        setCollectionList(mappeddata)          
        //setTotalPage(data?.pageMeta.totalItems)        
    }, [])
  

    const getCollectionList = useCallback(() => {        
        setCollectionLevelLoading(true)
        let filterData = {}        
        filterData = {communityId: communityid}        
        
        triggerAPI({
            url: `community/collection/user/get`, method: 'post', data: {
                page: 1,
                items: 9999999,
                filter: filterData,
                search: ""
            }
        }, getCollectionListResult);
    }, [getCollectionListResult, triggerAPI, communityid])  
     
    const clearAll = useCallback(() => {
        setGalleryUpdateId('')
        dispatch('setCurrentGalleryUpdateId', '');
        dispatch('setCurrentSelectedNfts', [])
        dispatch('setCurrentList', [])
        dispatch('setCurrentGallery', {})
        dispatch('setCurrentFormValues', getInitial(formGroup))
        dispatchRedux(setCurrentFormValues(getInitial(formGroup)))
        return ()=>{
           
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    useEffect(() => {
        if(communityid){
            dispatch("setCommunityGalleryCollections",collectionList)
        }
    }, [communityid,collectionList,pathname])
    useEffect(() => {
        let finalList = deepCopy(collectionList);
        if(!isCreator) {
            finalList = getDropValueByData(collectionList, tokens)
        }
        setFinalCollectionList(finalList);
    }, [collectionList, dropDownFilter, isCreator, tokens, getDropValueByData])
    const setShowAuthPop =()=>  dispatchRedux(setShowWalletPop(true))
    useEffect(() => {
        return () => {
            dispatchRedux(setCurrentFormValues(getInitial(formGroup)))
        }
    }, [pagetype])
    useEffect(() => {
        dispatch("setPage",communityid?"community-gallery":"gallery")
    }, [communityid,pathname])
    const getFirstPart = (pathname) => {
        const pathParts = pathname.split('/');
        return pathParts[1]; 
      };
    const firstPart = getFirstPart(pathname);
    
    return (
        < div className={` sm:mb-2 md:pt-0 w-full h-full nft-gallery-sm full-gallery curate-gallery  ${isCreating || firstPart==="community" ? 'relative overflow-hidden' : 'overflow-auto'} ${!isCreator  && 'page-shadow'}`
        }>
            <div className={`${((!isLoggedIn||(collectionWalletData !== null && !collectionWalletData?.length))&&collectionLoadingStatus !== "in-progress")&&"hidden"} h-full`}>
            {!pagetype && <SkeltonCard show={spinnerShow} height="15.4rem" count={5} inline={true} containerClassName={` grid gap-2.5 home-grid px-4 pt-[1rem]`} />
            }
            {pagetype && <SkeltonCard show={spinnerShow} height="17rem" count={5} inline={true} containerClassName={` grid gap-2.5 gallery-grid-lg px-4 pt-[1rem]`} />
            }
            {!pagetype && !spinnerShow && 
                <div>
                    {<MyGallery warningPopup={warningPopup} isSubscriptionReached={isSubscriptionReached} 
                        setPrimaryHandler={setPrimaryHandler} handleEdit={handleEdit} 
                        showGallerySkelton={showGallerySkelton} selectedCollection='My Collections' 
                        setGalleryUpdateId={setGalleryUpdateId} galleryList={galleryList} 
                        onClick={onCreateGalleryClick} setGalleryPreview={setGalleryPreview} 
                        onClickDeleteGallery={onClickDeleteGallery} onCancel={closeDeleteWarnHandler} 
                    />}
                </div>}
            {pagetype && !spinnerShow && 
                <GalleryCollection
                    pgaction={pgaction} pageType={pagetype} galleryid={galleryid}
                    collectionLevelLoading={collectionLevelLoading} setCollectionLevelLoading={setCollectionLevelLoading}
                    isToken={isToken} formGroup={formGroup} 
                    showPrimaryDiscoverable={galleryBaseUrl?.includes("curate")}
                    onClickDeleteGallery={onClickDeleteGallery}
                    galleryUpdateId={galleryUpdateId}
                    nftCollections={finalCollectionList}
                    updateCollectionFilter={updateCollectionFilter}
                    setShowAuthPop={setShowAuthPop}
                    galleryPreview={galleryPreview} isCreator={isCreator} 
                    setIsCreating={setIsCreating}
                    galleryBaseUrl={galleryBaseUrl}
                    />
            }
            </div>
            {/* Pop to select wallet in without sign in */}
            {showAuthPop && <WalletSelectPop show={showAuthPop} />}
            {/* delete pop for listing and preview page */}
            {deleteWarning && <DeleteGallery show={true} dltId={dltId} deleteResult={deleteResult} />}
            
        </div >
    )
}


export default NftGallery
