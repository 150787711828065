import { useStore } from 'App/hooks-store/store'
import useHttp from 'App/hooks/use-http'
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import DisplayMyGalleries from "./Components/DisplayMyGalleries";
import { useParams } from "react-router-dom";
import storage from "App/Helper/storage";
import useAuth from 'App/hooks/use-auth';
import closeIcon from "Assets/Images/close-circle-icon.svg"
import infoSVG from 'Assets/Icons/infoIconSquare.svg'
import Border from 'App/Pages/Discover/Components/SubComponents/Border';
import userAvatar from 'Assets/Icons/logo-square.svg'
import GalleryPreviewPlugin from 'App/Pages/GalleryPreview/Component';
import shapesEmpty from "Assets/Images/shapesEmpty.svg";
import './DisplayItem.scss';
import useShapes from 'App/hooks/use-shapes';
import SkeletonLoader from 'App/Components/Ui/SkeletonLoader';
const DisplayItem = (props) => {    
    const [activeTab, setActiveTab] = useState("mygalleries");        
    let navigate = useNavigate();
    const { id } = useParams();
    const { triggerAPI } = useHttp();
    const [display, setDisplay] = useState(null);
    const [showGallerySkelton, setShowGallerySkelton] = useState(false);     
    const [galleryList, setGalleryList] = useState([])
    const [gallery, setGallery] = useState(null)
    const [{ currentWallet}, dispatch] = useStore(false);
    const isLoggedIn = useAuth()[0]
    const [displayGallery, setDisplayGallery] = useState(null)
    const [previewLoading, setPreviewLoading] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [activeGallerData, setActiveGalleryData] = useState(null)
    const [standAloneDetails, setStandAloneDetails] = useState([])
    const [activeGalleryId, setActiveGalleryId] = useState(0)
    const [shapes, setShapes] = useState(shapesEmpty);
    const {  getShapes } = useShapes();
    const handleTabChange = (tab) => {
        setActiveTab(tab);
      };

    const onCloseHandler = ()=> {
        navigate("/display");
    }

    const onSaveHandler = ()=> {
        dispatch('showSpinner')
        triggerAPI({
            url: `display/updategallery`, data: displayGallery
            , method: 'post'
        }, (res) => {
            dispatch('hideSpinner');            
            dispatch('showToast', { toast: { toastMode: 'success', message: "Display Gallery Updated" } })
            //navigate("/display");
        }, err => {
            dispatch('hideSpinner');
            dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.error?.message||"Display Gallery Update Failed" } })
        });        
    }

    const onGalleryPageTypeClick = (pageType)=> {        
        setDisplayGallery({...displayGallery, type:pageType});
    }

    const getGalleryDefaults = () => {
        return {
            displayId: display?.id,
            galleryId: display?.activeGalleryId,
            timerMs: 10000, //should come from the active user pref
            isFramed: true,
            frameAssetColorId: 2, //should be loaded as black from link table
            captionMins: 0,
            captionPlacement: 'right',
            isLiveRender: false,
            isZoomed: false,
            twoDWallBackgroundId: gallery?.twoDWallBackgroundId,
            transition: 'fade',
            shapes: null,
            venue: 1,
            type: 'mono'
        }
    };

    const getDisplayGalleryResult = useCallback((res) => {                    
        if (!res.data)    {            
            res.data = getGalleryDefaults();           
        }        
        setDisplayGallery(res.data); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display, gallery]);
    
    const getDisplayGallery = useCallback(() => {           
        triggerAPI(
          {
            url: `display/getgallery?displayId=${display.id}&galleryId=${display.activeGalleryId}`,
            method: "get"        
          },
          getDisplayGalleryResult
        );
    }, [getDisplayGalleryResult, triggerAPI,display]);    

    const gallerySelected = useCallback((id)=> {    
        setDisplay({...display, activeGalleryId:id});  
        setActiveGalleryId(id);
        setPreviewLoading(true)
        setActiveGalleryData(null)    
    }, [display]);

    const getDisplayResult = useCallback((res) => {        
        setDisplay(res.data);           
        setActiveGalleryId(res?.data?.activeGalleryId);   
    }, []);


    useEffect(()=> {
        if (display?.id && display?.activeGalleryId) {
            getDisplayGallery(); 
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display])

    const getDisplay = useCallback(() => {                
        triggerAPI(
            {
            url: `display/get/${id}`,
            method: "get"        
            },
            getDisplayResult
        );
    }, [getDisplayResult, triggerAPI, id]);

    const getGalleryResult = useCallback((res) => {
        const { list } = res.data;
        setGalleryList(list)
        setTimeout(()=>{
            setShowGallerySkelton(false)
        },600)        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getGalleryList = useCallback(() => {
        const token = storage().get('token');
        if(token) {
            let filter = {
                sortBy: "",
                orderBy: ""
            }
            if(!!(currentWallet?.address) || isLoggedIn) {
                setShowGallerySkelton(true)
                triggerAPI({
                    url: `gallery/user/list/get`, data: {
                        page: 1,
                        items: 1000,
                        filter
                    }, method: 'post'
                }, getGalleryResult);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getGalleryResult, triggerAPI])    

    useEffect(()=>{
        getGalleryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(()=> {
        getDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=> {
        if (display?.activeGalleryId && galleryList.length > 0)
        {   
            let gallery = galleryList.find(g => g.id == display.activeGalleryId);
            setGallery(gallery);   
            if (displayGallery && !displayGallery.id)
            {
                displayGallery.twoDWallBackgroundId = gallery.twoDWallBackgroundId;
            }
        }   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryList,display, displayGallery])
    const standAloneResult = useCallback((res) => {
        const data = res?.data;
            let ratios = data?.Shapes?.map(shape => Number(shape.aspectRatio));
            setShapes(getShapes(ratios));
        setStandAloneDetails(prev => [...prev, {galleryId: data?.id, data}]);
    }, []);
    const getStandAloneDetails = useCallback((id) => {
        const url = 'gallery/stand-alone/get/';
        triggerAPI({
            url: `${url}${id}?isVerified=0&compression=0`, method: 'get'
        }, standAloneResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [standAloneDetails]);
    useEffect(() => {
        if(activeGalleryId) {
            let tempActiveGallery = standAloneDetails?.find(item => item?.galleryId === activeGalleryId);
            if(tempActiveGallery) {
                let selectedGallery = tempActiveGallery?.data;
                let galleryInfo = {name: selectedGallery?.name, userName: selectedGallery?.userData?.username ?? ''};
                let layoutId = selectedGallery?.layoutId;
                let multiBg = selectedGallery?.thumbnailBackground;
                let initalTimes = {
                    hr: selectedGallery?.preference?.slideTimerHours ?? "0",
                    min: selectedGallery?.preference?.slideTimerMinutes ?? "0",
                    sec: selectedGallery?.preference?.slideTimerSeconds ?? "10"
                }
                let galleryData = selectedGallery;
                let activeGallery =  {
                    galleryInfo, layoutId, multiBg, initalTimes, galleryData
                }
                setActiveGalleryData(activeGallery); 
                setPreviewLoading(false)
            } else {
                getStandAloneDetails(activeGalleryId);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeGalleryId, standAloneDetails])
    const handleGalleryView = (type) => {
        if(type)
            onGalleryPageTypeClick(type)
    }
    return  (
        <div className="display-item-overlay" onClick={onCloseHandler}>        
            <div className="display-item-content" onClick={(e) => e.stopPropagation()}>
                <div className="display-gallery-edit overflow-y-scroll pr-2">
                    {display?.activeGalleryId ? (<>
                        <div className="display-gallery-header">
                            <div className="display-header-info">
                                <div className="flex gap-2 items-center h-full">
                                    <p className="text-[1rem] text-[#E7EAEE] font-medium">
                                        {gallery?.name}
                                    </p>
                                    <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className='mt-[2px]'>
                                        <img src={infoSVG} alt='' />
                                    </button>
                                    <div className='display-tag'>
                                        {display?.name}
                                    </div>
                                </div>                                
                            </div>
                            <div className="actions">
                                <button onClick={onSaveHandler}>Save</button>
                                {/* <button onClick={onCloseHandler}>Close</button> */}
                            </div>
                            <div className="close-div  absolute top-3 right-3 h-[1.5rem] w-[1.5rem]">
                                <div className="close-icon" onClick={onCloseHandler}>
                                </div>
                            </div>
                        </div>
                        {previewLoading ? <SkeletonLoader height={`calc(100% - 13rem)`}></SkeletonLoader> : <GalleryPreviewPlugin
                            key={activeGallerData?.galleryData?.id}
                            className='diplay-module-gallery-preview'
                            galleryData={activeGallerData?.galleryData} 
                            galleryType={displayGallery?.type}
                            isAppV1={false}
                            noEnlargeView
                            initalTimes={activeGallerData?.initalTimes}
                            layoutId={activeGallerData?.layoutId}
                            multiBgData={activeGallerData?.multiBg}
                            galleryInformation={activeGallerData?.galleryInfo}
                            handleGalleryView={handleGalleryView}
                        />}
                         {(isDropdownOpen && activeGallerData) &&                              
                                    <div className='display-info-overlay'>
                                        <div className='flex flex-col w-full gap-[1.5rem]'>
                                        <div className='flex flex-col gap-[0.5rem] '> 
                                            <div className='flex w-[100%] justify-between'>
                                            <div className='flex gap-2'>
                                            <img style={{borderRadius: "50%", height:"1.5rem", width:"1.5rem"}} src={gallery?.user?.profilePicUrl256 || userAvatar} alt="" />
                                            <div className='font-medium text-[#E7EAEE] text-[1rem] '>
                                                    {gallery?.name}
                                            </div>
                                            </div>
                                                <img onClick={() => setIsDropdownOpen(!isDropdownOpen)} src={closeIcon} className='cursor-pointer' alt=''/>
                                        </div>
                                                <div className='text-[0.875rem] text-[#B8C0CC] text-left pl-[2.12rem]'>
                                                {gallery?.user?.name}
                                            </div>
                                        </div>
                                            <div>
                                                <div className="flex gap-[.5rem] details-container-display max-w-[20%]">
                                                <div className="data-box">
                                                    <div className="data-title">Pieces</div>
                                                    <div className="data-value">
                                                        {gallery?.assets?.length}
                                                    </div>
                                                </div>
                                                <Border className={"right-panel-v-border hidden"} />
                                                <div className="data-box items-mob">
                                                    <div className="data-title">Shapes</div>
                                                    <div className="data-value">
                                                    <img
                                                        className="w-auto h-[1rem] ml-2.5 shapes-img"
                                                        alt="Img"
                                                         src={shapes}
                                                    />
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className='text-left text-[#E7EAEE] text-[0.875rem]'>
                                            {gallery.description}
                                            </div>
                                        </div>
                                    </div>}
                                    {!previewLoading && 
                                    <div className="gallery-type">
                                        {gallery?.files?.files?.length > 0 && (
                                            <div className={`image-container mono-image ${displayGallery?.type=='mono'?'type-selected':''}`} onClick={(e)=> {onGalleryPageTypeClick("mono")}}>
                                                <img src={gallery.files.files[0]} alt='' />                            
                                            </div>
                                        )}
                                        {gallery?.files?.sliders?.length > 0 && (
                                            <div className={`image-container multi-image ${displayGallery?.type=='multi'?'type-selected':''}`}
                                                style={{backgroundImage: `url(${gallery?.thumbnailBackground?.imageUrl256}`}}
                                                onClick={(e)=> {onGalleryPageTypeClick("multi")}}>
                                                <img src={gallery.files.sliders[0]} alt='' />                        
                                            </div>
                                        )}
                                        <div className={`image-container meta-image ${displayGallery?.type=='meta'?'type-selected':''}`} onClick={(e)=> {onGalleryPageTypeClick("meta")}}>
                                            <img src="/metagalleryassets/images/meta1256.jpg" alt='' />                    
                                        </div>                            
                                    </div>}
                            </>) : (
                        <>
                        <div className="display-gallery-none">Select a gallery from the list on the right to show on your display</div>
                        <div className="close-div  absolute top-3 right-3 h-[1.5rem] w-[1.5rem]">
                                <div className="close-icon" onClick={onCloseHandler}>
                                </div>
                            </div>
                            </>
                    )}
                </div>                                    
                <div className="display-gallery-lists">
                    
                        <div className="display-gallery-headertab-list mb-[1rem]">
                            <div className={`tab-datas flex gap-[1.42857rem]`}>
                                <div className={`tabs ${activeTab === "mygalleries" ? "active" : "not-active"}`}
                                    onClick={() => handleTabChange("mygalleries")}>                                
                                    My Galleries
                                </div>
                                <div className={`tabs ${activeTab === "rentals" ? "active" : "not-active"}`}
                                    onClick={() => handleTabChange("rentals")}>                                
                                    Rentals
                                </div>   
                                <div className={`tabs ${activeTab === "display" ? "active" : "not-active"}`}
                                    onClick={() => handleTabChange("display")}>                                
                                    Display
                                </div>   
                            </div>                        
                        </div>
                        <div className="tab-container-details">
                        <div className="tab-content">
                            {activeTab === "mygalleries" && (<DisplayMyGalleries gallerySelected={gallerySelected} galleryList={galleryList} activeGalleryId={display?.activeGalleryId} />)}
                            {activeTab === "rentals" && (<div className="rentals-content">Coming Soon</div>)}
                            {activeTab === "display" && (<div className="display-content">  
                                <div className='display-info-title'>Information</div>
                                <div className='display-info'>
                                    <div className='display-keys'>
                                        {display?.modelInformation && Object.entries(display.modelInformation)?.sort((a,b)=>a[0]>b[0]?1:-1).map(i=> {
                                            return (                                        
                                                    <div className="display-key">{i[0]}</div>                                            
                                            )
                                        })}                              
                                    </div>
                                    <div className='display-values'>
                                        {display?.modelInformation && Object.entries(display.modelInformation)?.sort((a,b)=>a[0]>b[0]?1:-1).map(i=> {
                                            return (                                        
                                                <div className="display-value">{i[1]}</div>                                        
                                            )
                                        })}                              
                                    </div>
                                </div>
                            </div>)}
                        </div>                        
                    </div>                                        
                </div>
            </div>      
        </div>
    )
}

export default DisplayItem