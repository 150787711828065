import { queryGenerator } from "App/Helper/utilities";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import userAvatar from 'Assets/Icons/userAvatars.svg'

const useDiscoverApiHelper = () => {    
    const dispatch = useStore(false)[1];
    let { triggerAPI } = useHttp();
    const getGalleryDataAPI = (data, callback) => {
        triggerAPI({
            url: `gallery/verified-communities/get`, data, method: 'post'
          }, (res => {
            const { list, pageMeta } = res?.data;            
            callback({list: list, pageMeta, allList: list})
          }));
    }
    const getCollectorGalleryAPI = (data, callback) => {
        triggerAPI({
            url: `gallery/collectors/gallery/get`, data, method: 'post'
          }, (res => {
            const { list, pageMeta } = res?.data;            
            callback({list: list, pageMeta, allList: list})
          }));
    }
    const getCollectorGalleryDataAPI = (data, callback) => {
        dispatch('showSpinner')
        triggerAPI({
            url: `user/discover/get-creators-or-collectors/:${data?.type}?${data?.data}`, method: 'get'
          }, (res => {
            dispatch('hideSpinner')
            const { list, pageMeta } = res?.data;
            // eslint-disable-next-line array-callback-return
            const tempArray = list?.map( item => {
              if(item?.UserGalleries?.length)
                return {
                    id: item?.id,
                    name: item?.name ?? '',
                    profilIcon: item?.profilePicUrl ?? userAvatar,
                    bgImage: item?.UserGalleries?.length && item?.UserGalleries[0]?.thumbnailBackground?.imageUrl,
                    assetImg: item?.UserGalleries?.length && item?.UserGalleries[0]?.files?.sliders[0],
                }
            })
            callback({list: tempArray, pageMeta, allList: list})
          }));
    }
    const getUserGalleryAPIByUserCommunity = (data, callback) => {
       // dispatch('showSpinner')
        triggerAPI({
            url: `community/gallery/get`, data, method: 'post'
          }, (res => {
           // dispatch('hideSpinner')
            const { list, pageMeta } = res?.data;
            callback({list, pageMeta})
          }));
    }
    const getUserGalleryDataAPI = (data, callback) => {
        //dispatch('showSpinner')
        triggerAPI({
            url: `gallery/list/get`, data, method: 'post'
          }, (res => {
          //  dispatch('hideSpinner')
            const { list, pageMeta } = res?.data;
            callback({list, pageMeta})
          }));
    }
    const getUserCollectionDataAPI = (data, callback) => {
       // dispatch('showSpinner')
        triggerAPI({
            url: `community/creators/collections`, data, method: 'post'
          }, (res => {
           // dispatch('hideSpinner')
            const { list, pageMeta } = res?.data;
            callback({list, pageMeta})
          }));
    }
    const getUserAnnouncementDataAPI = (data, callback) => {
       // dispatch('showSpinner')
        triggerAPI({
            url: `community/announcement/get`, data, method: 'post'
          }, (res => {
           // dispatch('hideSpinner')
            const { list, pageMeta } = res?.data;
            callback({list, pageMeta})
          }));
    }
    const getUseEventsDataAPI = (data, callback) => {
        // dispatch('showSpinner')
        triggerAPI({
            url: `community/event/get`, data, method: 'post'
          }, (res => {
            // dispatch('hideSpinner')
            const { pageMeta, upcomingEvents, pastEvents } = res?.data;
            callback({ pageMeta, upcomingEvents, pastEvents })
          }));
    }
    const getStandAloneGallery = (data, callback, handleError) => {
      //dispatch('showSpinner')
      triggerAPI({
          url: `gallery/stand-alone/get/${data?.id}?isVerified=0&compression=0`, method: 'get'
        }, (res => {
        //  dispatch('hideSpinner')
          const data = res?.data;
          let tempAssets = data?.assets.map(assetData => {
            return {
                details: {
                  nftcdnimage: assetData?.details?.nftcdnimage,
                  nftcdnimage256: assetData?.details?.nftcdnimage256,
                  nftcdnimage512: assetData?.details?.nftcdnimage512,
                },                
                asset: assetData?.details?.asset,
                frameId: assetData?.frameId,
                name: assetData?.details?.name,
                policy: assetData?.details?.policy,
                isLandscape: assetData?.isLandscape
            };
          })
          data.assets = tempAssets
          callback({data})
        }), (error) => {dispatch('hideSpinner'); handleError && handleError(error)});
    }
    const getDiscoverCollection = (data, callback) => {
      let queryList=queryGenerator(data?.queryData);
      queryList=`categoryIds=["${data?.categoryIds}"]${data?.firstSubcategoryIds?`&firstSubcategoryIds=["${data?.firstSubcategoryIds}"]`:""}&${queryList}`
      triggerAPI({
          url: `community/creators/collections?${queryList}`, method: 'get'
        }, (res => {
          const { list, pageMeta } = res?.data;
          callback({list: list, pageMeta })
        }));
    }
    const getDiscoverCommunities = (data, callback,cancelApi) => {
      let queryList=queryGenerator(data?.queryData);
      queryList=`categoryId=${data?.categoryId}&${queryList}&isFollowed=${data?.isFollowed}`
      triggerAPI({
          url: `category/filter/community-group?${queryList}`, method: 'get', cancelApi
        }, (res => {
          const { list, pageMeta ,name } = res?.data;
          callback({list: list, pageMeta ,name})
        }));
    }
    const getCommunityData = (data, callback) => {
      triggerAPI({
          url: `community/get/${data?.id}`, data, method: 'get'
        }, (res => {
          const data = res?.data;
          callback({ data })
        }));
    }
    const getCategoryCollection = (data, callback,cancelApi) => {
      triggerAPI({
          url: `category/subcategory-collections?parentCategoryId=${data?.id}&search=${data?.search}`,  method: 'get',cancelApi
        }, (res => {
          const data = res?.data;
          callback({ data })
        }));
    }
    const getCategoryCommunity = (data, callback) => {
      triggerAPI({
          url: `category/community-group?search=${data?.search}&isFollowed=${data?.isFollowed}`,  method: 'get'
        }, (res => {
          const data = res?.data;
          callback({ data })
        }));
    }
    return {
        getGalleryDataAPI, getUserGalleryDataAPI, getUserCollectionDataAPI, 
        getUserAnnouncementDataAPI, getUseEventsDataAPI, getCollectorGalleryDataAPI,
        getStandAloneGallery, getUserGalleryAPIByUserCommunity, getDiscoverCollection,
        getCommunityData, getCollectorGalleryAPI,getCategoryCollection,getCategoryCommunity,getDiscoverCommunities
    }
}
export default useDiscoverApiHelper;