import { getCaptionControl } from "App/ReduxStore/Slicers/galleryPreviewSlice";
import { memo } from "react";
import { useSelector } from "react-redux";
const GalleryCaption = (props) => {
    const captionControl = useSelector(getCaptionControl)
    return (
        <div className={`${props?.captionCardClass ?? ''} caption-container flex flex-col gap-4 
            ${captionControl?.defaultPos === 'left' ? 'left-[5%]' : 'right-[5%]'} 
            absolute ${captionControl?.captionCard ? 'opacity-1 show' : 'opacity-0'} `}>
            <span>{props?.cardData?.cardName}</span>
            {props?.cardData?.assetName && <span className="nft-name">{props?.cardData?.assetName}</span>}
            <span className="created-name community-name">by 
                <span className="capitalize">{props?.cardData?.userName ? ` ${props?.cardData?.userName}` : ' Unknown'}</span>
            </span>
        </div>
    )
}
export default memo(GalleryCaption);