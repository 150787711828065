import './TermsTable.scss';
const TermsTable = (props) => {
 return (
    <div className="flex w-full gap-4 collection-terms-container flex-col"> 
        <table className="collection-terms-table">
            <thead>
                <tr>
                    <th className='text-left'>License </th>
                    <th>Copy, Display & Distribute </th>
                    <th>Sublicense </th>
                    <th>Commercial Use </th>
                    <th>Modify & Adapt </th>
                    <th>All  Speech Permitted </th>
                    <th>Creator Retains no Rights </th>
                </tr>
            </thead>
            <tbody>
                {props?.termsList?.map((terms) => {
                    return (
                        <tr className='' key={terms?.id}>
                            <th className='flex row-head'>
                                <div className="overall-round round relative">
                                    <input type="checkbox" 
                                        id={`checkbox${terms?.id}`} 
                                        disabled={props?.disabled}
                                        checked={props?.values === terms?.id}
                                        data-id={terms?.id} onChange={props?.handleTerms} />
                                    <label htmlFor={`checkbox${terms?.id}`} className={`${props?.disabled&&"disabled"}`}></label>
                                </div>
                                <span>{terms?.liscenceName}</span>
                            </th>
                            <td className=''>
                                {terms?.copyDisplayDistribute && 
                                    <div className="round relative">
                                        <input type="checkbox" id="" defaultChecked={true} readOnly />
                                        <label htmlFor=""></label>
                                    </div>
                                }
                            </td>
                            <td className=''>
                                {terms?.subliscence && 
                                    <div className="round relative">
                                        <input type="checkbox" id="" defaultChecked={true} readOnly />
                                        <label htmlFor=""></label>
                                    </div>
                                }
                            </td>
                            <td className=''>
                                {terms?.commercialUse && 
                                    <div className="round relative">
                                        <input type="checkbox" id="" defaultChecked={true} readOnly />
                                        <label htmlFor=""></label>
                                    </div>
                                }
                            </td>
                            <td className=''>
                                {terms?.modifyAdapt && 
                                    <div className="round relative">
                                        <input type="checkbox" id="" defaultChecked={true} readOnly />
                                        <label htmlFor=""></label>
                                    </div>
                                }
                            </td>
                            <td className=''>
                                {terms?.allSpeechPermitted && 
                                    <div className="round relative">
                                        <input type="checkbox" id="" defaultChecked={true} readOnly />
                                        <label htmlFor=""></label>
                                    </div>
                                }
                            </td>
                            <td className=''>
                                {terms?.creatorRetainNoRights && 
                                    <div className="round relative">
                                        <input type="checkbox" id="" defaultChecked={true} readOnly />
                                        <label htmlFor=""></label>
                                    </div>
                                }
                            </td>                        
                        </tr>
                    )
                })}
            </tbody>
        </table>
    </div>
 )
}

 export default TermsTable;