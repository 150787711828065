import"./SelectedNftDetails.scss"
import { useCallback, useEffect, useState } from "react"
import FrameitTab from "./Components/FrameitTab"
import { useStore } from "App/hooks-store/store"
import useHttp from "App/hooks/use-http"
import { popupMobileScrollAdj } from "App/Helper/utilities"
import useAuth from 'App/hooks/use-auth';
import storage from 'App/Helper/storage'
import ImagesTab from "./Components/ImagesTab"
import AttributesTab from "./Components/AttributesTab"
import useScreenSize from "App/hooks/use-screen-size"
import GalleryImages from "App/Components/Ui/GalleryFrameImages"
import chevronRight from 'Assets/Icons/chevron-right.svg'
import chevronLeft from 'Assets/Icons/chevron-left.svg'
import enlargeIcon from 'Assets/Icons/enlarge-icon.svg'
import Ada from "Assets/Icons/ada_svg_grey.svg";
import userAvatar from "Assets/Icons/logo-square.svg";
import FormatField from "App/Components/Ui/FormatField"
import copyIcon from "Assets/Icons/copy-icon-grey.svg";
import desenlargeIcon from 'Assets/Icons/desenlarge-icon.svg'
import TransparentPopup from "./Components/TransparentPopup"
import SubscriptionWarning from "App/Pages/NftGallery/Component/SubscriptionWarning"
import { useLocation } from 'react-router-dom'
import ListenList from "../ListenList"
const SelectedNftDetails = props => {
    const screenSize = useScreenSize()?.width;
    const screenHeight = useScreenSize()?.height;
    const screenRatio = screenSize/screenHeight
    const [freeFrameList, setFreeFrameList] = useState({ list: [], totalItems: 0 })
    const [purchasedList, setPurchasedList] = useState({ list: [], totalItems: 0 })
    const [premiumFrames, setPremiumFrames] = useState({ list: [], totalItems: 0 })
    const [allFrameList, setAllFrameList] = useState({ list: [], totalItems: 0 })
    const [freeFrameLoading, setFreeFrameLoading] = useState(true)
    const [frameApplied, setFrameApplied] = useState(null)
    const [{ currentWallet }, dispatch] = useStore(false);
    const isLoggedIn = useAuth()[0]
    const isLoggedInGuest = useAuth()[4]
    const { triggerAPI } = useHttp();
    const zoom = false;
    const [aspectRatio, setAspectRatio] = useState(null)
    const [activeTab, setActiveTab] = useState(props?.nft?.nft?.files?.filter(f=>f.mediaType?.indexOf("audio")>-1).length > 0?"listen":"details");
    const [previewUrl, setPreviewUrl] = useState(props?.nft?.nft?.nftcdnimage1024||props?.nft?.nft?.nftcdnimage512 || '');
    const [enlargedView, setEnlargedView] = useState(false);
    const [filterShape,setFilterShape] = useState([])
    const [filterAge,setFilterAge] = useState([])
    const [showSubscriptionPopup,setSubscriptionPopup] = useState(false)
    const [subscriptionReached,setSubscriptionReached] = useState(false)
    const location = useLocation();
    
    const handleTabChange = (tab) => {
      if(tab==="tab3"){
        checkFrameCount((res)=>{
          setActiveTab(tab)
        })
      }else setActiveTab(tab);
      };
      
    const previewFrameHandler = e => {
        let data = e
        if (e && e.metaInfo?.portrait) {
            data = { ...e }
            data.metaInfo = e && e?.isLandscape ? e?.metaInfo?.landscape : e?.metaInfo?.portrait;
            data.image = (e && e?.isLandscape) ? (e?.imageUrlLand ?? e?.imageUrl) : e?.imageUrl;
            data.image512 = (e && e?.isLandscape) ? (e?.imageUrlLand512 ?? e?.imageUrl512) : e?.imageUrl512;
            data.image256 = (e && e?.isLandscape) ? (e?.imageUrlLand256 ?? e?.imageUrl256) : e?.imageUrl256;
        }
        setFrameApplied(data)
    }
    const applyFrameResult = useCallback((res) => {
        dispatch('hideSpinner');
        // setShowSpiner(false)
        props.onReload(props.nft.nft.asset)
    }, [dispatch, props])

    const checkFrameCount = useCallback((callback) => {
      const triggerFrame = () => {
          const data = {
              page: 1,
              items: 1,
              filter: {categoryId: [3],sortBy:"modernWestern"}
          }
          triggerAPI({
              url: `frame-asset/user/get`, method: 'post', data
          }, (res) => {
              const { userAvailablePremiumFrames,userAddedPremiumFrames,isSubscriptionLimitReached } = res.data;
              setSubscriptionPopup(userAddedPremiumFrames>=userAvailablePremiumFrames)
              setSubscriptionReached(isSubscriptionLimitReached)
              callback()
          },()=>callback());
      }

      triggerFrame();


      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, triggerAPI])

    const applyFrameHandler = useCallback((e) => {
        if (isLoggedIn) {

            let data = {
                "asset": props.nft.nft.asset,
                "networkType": props.nft.nft.networkType,
                "frameId": e ? e.id : null,
                "isLandscape": e ? e?.isLandscape : false,
            }
            let url
            let method
            if (!!e) {
                url = `user/nft-collection/frame-it/add`
                method = 'post'
            }
            else {
                url = `user/nft-collection/frame-it/remove`
                method = 'delete'
                delete data.isLandscape;
            }
            dispatch('showSpinner')
            triggerAPI({
                url: url, data: data, method: method
            }, applyFrameResult,()=>{
              dispatch("hideSpinner");
          });
        } else {
            props?.setShowAuthPop(true);
        }
    }, [dispatch, applyFrameResult, triggerAPI, props, isLoggedIn])
    const reloadFrameHandler = useCallback(() => {
        getFreeFrameList()
        getStyles()
        getAges()
        const token = storage().get('token')
        if ((isLoggedInGuest || isLoggedIn) && token) {
            getPurchasedList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWallet?.address]);
    useEffect(() => {
        if (!!props.nft)
            setFrameApplied(props.nft?.frame)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nft])

    const getFreeFrameList = useCallback(() => {
        let page = 1;
        let temp = [];
        const triggerFetchFreeFrame = () => {
            const data = {
                "page": page,
                "items": 40,
                filter: {  categoryId: [2],sortBy:"modernWestern"},
                
            }
            triggerAPI({
                url: `frame-asset/user/get`, data: data, method: 'post'
            }, (res) => {
                const { list, pageMeta } = res.data;
                let arr_temp = list.map(frame => {
                    return {
                        id: frame.id,
                        name: frame.name,
                        isUserAdded: frame.isUserAdded,
                        imageUrl: frame.s3ImageUrl,
                        imageUrlLand: frame?.s3ImageUrlLandscape,
                        imageUrl256: frame.s3ImageUrl256,
                        imageUrlLand256: frame?.s3ImageUrlLandscape256,
                        imageUrl512: frame.s3ImageUrl512,
                        imageUrlLand512: frame?.s3ImageUrlLandscape512,
                        price: frame.price ? frame.price : "00",
                        inStock: 30,
                        ageId:frame?.ageId,
                        shapeId:frame?.shapeId,
                        metaInfo: frame.metaInfo,
                        isLandscape: false
                    }
                })
                temp = [...temp, ...arr_temp]
                if (temp.length < pageMeta.totalItems) {
                    page++;
                    triggerFetchFreeFrame();
                } else {
                    setFreeFrameList({
                        list: temp,
                        totalItems: pageMeta.totalItems
                    })
                    setFreeFrameLoading(false);
                }
            });
        }

        triggerFetchFreeFrame();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, triggerAPI])
    const copyClipHandler = (copyVal) => {
        var textArea = document.createElement("textarea");
        textArea.value = copyVal || props?.nft?.policy;
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.width = ".09px";
        textArea.style.opacity = "0";
    
        textArea.style.position = "fixed";
    
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand("copy");
        dispatch("showToast", {
          toast: { toastMode: "success", message: "Copied to clipboard" },
        });
        document.body.removeChild(textArea);
      };
    const getPurchasedList = useCallback(() => {

        let page = 1;
        let temp = [];
        const triggerFetchFreeFrame = () => {
            const data = {
                page: page,
                items: 60,
                filter: {categoryId: [3],sortBy:"modernWestern"}
            }
            triggerAPI({
                url: `frame-asset/user/get`, method: 'post', data
            }, (res) => {
                const { list, pageMeta } = res.data;
                let arr_temp = list.map(frame => {
                    return {
                        id: frame.id,
                        name: frame.name,
                        imageUrl: frame.s3ImageUrl,
                        isUserAdded: frame.isUserAdded,
                        imageUrlLand: frame?.s3ImageUrlLandscape,
                        imageUrl256: frame.s3ImageUrl256,
                        imageUrlLand256: frame?.s3ImageUrlLandscape256,
                        imageUrl512: frame.s3ImageUrl512,
                        imageUrlLand512: frame?.s3ImageUrlLandscape512,
                        price: frame.price ? frame.price : "00",
                        inStock: 30,
                        ageId:frame?.ageId,
                        shapeId:frame?.shapeId,
                        metaInfo: frame.metaInfo,
                        isLandscape: false
                    }
                })
                temp = [...temp, ...arr_temp]
                if (temp.length < pageMeta.totalItems) {
                    page++;
                    triggerFetchFreeFrame();
                } else {
                    setPurchasedList({
                        list: temp,
                        totalItems: pageMeta.totalItems
                    })
                    setFreeFrameLoading(false);
                }

            });
        }

        triggerFetchFreeFrame();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, triggerAPI])

    // useEffect(() => {
    //     setAllFrameList({ list: [...purchasedList?.list, ...freeFrameList?.list], totalItems: freeFrameList?.totalItems + purchasedList?.totalItems })
    // }, [purchasedList, freeFrameList])

    useEffect(() => {
        getFreeFrameList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.nft?.image])
    useEffect(() => {
        const token = storage().get('token')
        if ((isLoggedInGuest || isLoggedIn) && token) {
            getPurchasedList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.nft?.image, currentWallet?.address, frameApplied])

    useEffect(() => {
        popupMobileScrollAdj(zoom)
    }, [zoom])

    const agesApiResult = (res) => {
      const data = res.data
      let list = []
      list = data.map(age => { return { name: age.name, id: age.id } })
      setFilterAge(prevState => { return { ...list } })

  }
  const getAges = () => {
      triggerAPI({
          url: `frame-asset/age/get`, method: 'get'
      }, agesApiResult);
  }

    //Styles filter populate api
    const getStyles = () => {
      triggerAPI({
          url: `frame-asset/style/get`, method: 'get'
      }, stylesApiResult);

  }
  const stylesApiResult = (res) => {
      const data = res.data
      let list
      list = data.map(style => { return { name: style.name, id: style.id } })
      setFilterShape(prevState => { return { ...list} })

  }
    const calculateAspectRatio = () => {
        if (props?.nft?.nft && props?.nft?.nft.nftcdnimage512) {
          const img = new Image();
          img.src = props?.nft?.nft?.nftcdnimage512;
      
          img.onload = () => {
            const aspectRatio = img.width / img.height;
            setAspectRatio(aspectRatio);
          };
        }
      };
      useEffect(() => {
        let audioTypeRemovedFiles = props?.nft?.nft?.files?.filter(f=>f.mediaType?.indexOf("audio") === -1)
        let newPreviewUrl = props?.nft?.nft?.nftcdnimage512;
        if(audioTypeRemovedFiles?.length) {
          newPreviewUrl = audioTypeRemovedFiles?.[0]?.nftcdnfile;
        }
        if (props?.readBook)
        {
          let epub = props?.nft?.nft?.files?.filter(f=> f.mType == 'epub')?.[0];
          if (epub)
          {
            newPreviewUrl = epub.nftcdnfile;
            setEnlargedView(true);
          }
          else
          {
            let pdf = props?.nft?.nft?.files?.filter(f=> f.mType == 'pdf')?.[0];
            if (pdf)
            {           
              newPreviewUrl = pdf.nftcdnfile;
              setEnlargedView(true);
            }
          }            
        }
        setPreviewUrl(newPreviewUrl);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.nft, props.readBook]);
      useEffect(() => {
          calculateAspectRatio();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props?.nft?.nft]);
      useEffect(() => {
        let age = filterAge?.find((el) => el?.name === "modern")?.id;
        let shape = filterShape?.find((el) => el?.name === "western")?.id;
        let myFrame = [
          ...freeFrameList.list,
          ...(purchasedList.list.filter(
            (frame) => frame.isUserAdded === true
          ) || []),
        ];
        let myFrameFilter=myFrame?.filter(el=>el?.ageId===age||el?.shapeId===shape);
        let myFrameWithoutFilter=myFrame?.filter(el=>el?.ageId!==age&&el?.shapeId!==shape);
        setAllFrameList({
          ...freeFrameList,
          list:[...myFrameFilter,...myFrameWithoutFilter]
        });
        setPremiumFrames( { list: [
          ...(purchasedList.list.filter(frame => frame.isUserAdded === false) || [])
        ]
      })
      }, [freeFrameList,purchasedList,filterAge,filterShape]);

    const buyNft = async () => {      
      alert("Coming Soon...")
      // const name = storage().get('currentWallet');
      // console.log("name",name)
      // let wallet = await walletConnector.connectWallet(name);
      // console.log("walletConnector",walletConnector)
      // if (wallet.signTx)
      // {
      //   let tx = await walletConnector.constructMarketplaceTx(wallet, props?.nft?.nft?.marketplace);      
        
      //   await wallet.signTx(tx);
      // }            
    }
    return (
    <div className="flex flex-col gap-0 min-h-[95vh]">
{ !enlargedView &&     <>
    <div className={`${props.className} view-gallery-all flex  w-full gap-[2rem] `}>
    <div className={` flex flex-col align-center ${screenRatio > 1.65 ? 'h-[73vh]' : 'h-[77vh]'}  `}>
          <div className="frame-container-gallery ">
              <div className="h-full w-full aspect-square square-container m-auto ">
              <GalleryImages
                key={previewUrl}
                clearCache={false}
                frameToggle={props?.frameToggle}
                isDetailedView={true}
                galleryNew={props?.nft?.nft}
                src={previewUrl}
                clicked={true}
                className="h-[100%] overflow-hidden aspect-square"
                nftGalleryNew={true}
                frameName={frameApplied}
                framePreview={true}
                onChainRenders={props?.onChainRenders}
                imageFileToUse={props?.nft?.nft?.files?.filter(f=> f.nftcdnfile === previewUrl)?.[0]}
                playAudio={true}
              />
                </div>
          </div>
      </div>
      <div className="flex gap-[2.5rem] absolute bottom-[1rem] right-[49.1%]">
        <div className="h-[100%] justify-center cursor-pointer" onClick={props?.selectPreviousNft}>
                <img src={chevronLeft} alt="chevron left" className="chevron-container"/>
        </div>
        <div className="h-[100%] justify-center cursor-pointer">
          <img src={enlargeIcon} alt="chevron left" className="chevron-container" onClick={()=>setEnlargedView(true)}/>
        </div>
        <div className="h-[100%] justify-center cursor-pointer" onClick={props?.selectNextNft}>
                <img src={chevronRight} alt="chevron right" className="chevron-container"/>
        </div>
        </div>
      {/* {props?.nft?.collectionName ?  */}
      <div className="w-[100%] min-w-[30vw]">
        {!props?.zoom && (
          <div
            className={`${
              screenSize <= 550 && props?.type === "collectors" && "bp-none"
            }  flex flex-col gap-[1rem] overflow-hidden ${screenRatio > 1.65 ? 'h-[73vh]' : 'h-[77vh]'}  `}
            >
            <div
              className={`description-tab  flex flex-col gap-2  `}
            >
              <div className="flex  justify-between">
                <div className="flex gap-4">
                  <div className="flex gap-2 items-center">
                    <img alt=""
                      style={{
                        borderRadius: "50%",
                        height: "1.5rem",
                        width: "1.5rem",
                      }}
                      className="object-cover"
                      src={props?.nft?.nft?.community?.displayPicture256 || userAvatar}
                    />
                  </div>
                  <div className="collection-title truncate flex flex-col justify-center">
                    {props?.nft?.nft?.collectionName ?? "NA"}
                  </div>
                  <div className="data flex gap-[0.28571rem] items-center text-[#B8C0CC]">
                    <img className={``} alt="Img" src={Ada} />
                    <FormatField
                      type="substring"
                      maxLength={20}
                      startLength={4}
                      endLength={4}
                      value={props?.nft?.nft?.policy}
                    />
                    <img
                      onClick={()=>copyClipHandler(props?.nft?.nft?.policy)}
                      className="cursor-pointer"
                      alt="Img"
                      src={copyIcon}
                    />
                  </div>
                </div>
                <div className="   cursor-pointer absolute top-[1rem] right-[1rem] " >
                  {/* <button className="close-button min-w-[1.5rem]" onClick={props?.onCloseDetails}>

                    {" "}
                    CLOSE{" "}
                  </button> */}
          <div className="close-div   justify-end h-[1.5rem] w-[1.5rem]">
                  <div className="close-icon" onClick={props?.onCloseDetails}>
                  </div>
                </div>

                </div>
              </div>

              <div className="text-left text-[0.875rem] text-[#D0D5DD] pl-[2.5rem]">
                <p>{props?.nft?.nft?.community?.name ?? "No community"}</p>
              </div>
            </div>
            <div className="tab-container-details mobileTab">
            <div className="tab-list ">
            <div
                className={`tab-data flex gap-[1.42857rem]`}
              >
              {props?.nft?.nft?.files?.filter(f=>f.mediaType?.indexOf("audio")>-1).length > 0 && <div
                className={`tab ${activeTab === "listen" ? "active" : "not-active"}`}
                onClick={() => handleTabChange("listen")}
              >
                Listen
              </div>}
              <div
                className={`tab ${activeTab === "details" ? "active" : "not-active"}`}
                onClick={() => handleTabChange("details")}
              >
                Details
              </div>
              {/* {!props?.hideFrameTabs&&<> */}
              <div
                className={`tab ${activeTab === "tab2" ? "active" : "not-active"}`}
                onClick={() => handleTabChange("tab2")}
              >
                My Frames
              </div>
              <div
                className={`tab ${activeTab === "tab3" ? "active" : "not-active"}`}
                onClick={() => handleTabChange("tab3")}
              >
                Frames
              </div>
              {/* </>} */}
            </div>
            </div>
            </div>
            {activeTab === "details" && (
            <div className={` description-tab side-scroller`}>
              <div className="asset-title sm:pb-[.75rem] pb-[7px]">
                <div className="">{props?.nft?.nft?.name}</div>
              </div>
              {props?.nft?.nft?.marketplace?.price &&
                <div className={`flex flex-row gap-5 mt-[5px]`}>
                  <div className="asset-title mt-[3px]">{props?.nft?.nft?.marketplace.price/1000000} &#8371;</div>
                  <button className="add-button" onClick={buyNft}>Buy Now</button>
                </div>
              }
              <div className="description-details border-[#191D23]  border-b pb-4">
                <div className="description-data flex flex-col">
                <div className="title">Description</div>
                <div className="data">
                  {props?.nft?.nft?.description ? (
                    Array.isArray(props.nft.nft.description) ? (
                      props.nft.nft.description.map((line, index) => (
                        <span key={index}>
                          {line}{' '}
                        </span>
                      ))
                    ) : (
                      <div>{props.nft.nft.description}</div>
                    )
                  ) : (
                    <span>No description</span>
                  )}
                </div>

                </div>
              </div>
            
              <div className="description-details2 overflow-y-scroll overflow-x-hidden">
                <div className="description-details2">
              <div className="header">Information</div>
              <AttributesTab nft={props?.nft?.nft} />  
            </div>
              </div>
              {/* <div className="buy-container">
                  <div className="flex w-full items-center gap-[0.5rem]"> 
                    <img className="w-[2rem]" alt="Img" src={Ada} />
                    <span className="ada-price"> 30 </span>
                  </div>
                  <div className="w-full h-full"> 
                    <RoundedButton className="buy-btn" Name="Buy Now"> </RoundedButton>
                  </div>
                </div> */}
              </div>
       
             )}
             {activeTab === "tab2" &&
             <div className={`h-[100%] overflow-auto`}>
              <FrameitTab 
              reloadFrameHandler={reloadFrameHandler} 
              defaultFrame={frameApplied} 
              selectedFrame={frameApplied} 
              onApply={applyFrameHandler} 
              onPreview={previewFrameHandler} 
              nft={props.nft.nft} 
              freeFrameList={allFrameList} 
              freeFrameLoading={freeFrameLoading} 
              aspectRatio={aspectRatio}
              applyFrameButton={!props?.hideFrameTabs}
              />
             </div>
              }
              {activeTab === "tab3" &&
             <div className={`h-[100%] overflow-auto relative`}>
              <FrameitTab 
              reloadFrameHandler={reloadFrameHandler} 
              defaultFrame={frameApplied} 
              selectedFrame={frameApplied} 
              onApply={applyFrameHandler} 
              onPreview={previewFrameHandler} 
              nft={props.nft.nft} 
              freeFrameList={premiumFrames} 
              freeFrameLoading={freeFrameLoading} 
              aspectRatio={aspectRatio}
              applyFrameButton={!props?.hideFrameTabs}
              hideNoFrame={true}
              />
              {!!showSubscriptionPopup&&<TransparentPopup>
                <SubscriptionWarning isSubscriptionReached={subscriptionReached} content="Frames" />
                </TransparentPopup>}
             </div>
              }
              {activeTab === "listen" &&
              <ListenList className={"popup-listen"} collection={[props.nft.nft]} isPreview={location?.pathname?.toLowerCase().startsWith("/discover")}/>
              }
          </div>
      
        )}
      </div> 
     
      </div>
      <ImagesTab nft={props?.nft?.nft} className="pop-up-filetype-wrapper"  showFileType  setPreviewUrl={setPreviewUrl} previewUrl={previewUrl}
      />
    </>}
    {enlargedView &&
    <div className={`min-h-[95vh] view-gallery-all flex flex-col `}>
       <div className={` flex flex-col align-center ${props?.readBook ? 'h-[85vh]' : screenRatio > 1.65 ? 'h-[85vh]' : 'h-[87vh]'} `}>
          <div className="frame-container-gallery ">
              <div className="h-full w-full aspect-square square-container m-auto">
              <GalleryImages
                clearCache={false}
                frameToggle={props?.frameToggle}
                isDetailedView={true}
                galleryNew={props?.nft?.nft}
                src={previewUrl}
                clicked={true}
                className="h-[100%] overflow-hidden aspect-square"
                nftGalleryNew={true}
                frameName={frameApplied}
                framePreview={true}
                onChainRenders={props?.onChainRenders}
                imageFileToUse={props?.nft?.nft?.files?.filter(f=> f.nftcdnfile === previewUrl)?.[0]}
                playAudio={true}
              />
                </div>
          </div>
      </div>
      <div className="   cursor-pointer absolute top-[1rem] right-[1rem] " onClick={props?.onCloseDetails}>
      <div className="close-div   justify-end h-[1.5rem] w-[1.5rem]">
                  <div className="close-icon" onClick={props?.onCloseDetails}>
                  </div>
                </div>
                </div>
      {!props?.readBook && <div className="flex gap-[2.5rem] absolute bottom-[1rem] right-[49.1%]">
        <div className="h-[100%] justify-center cursor-pointer" onClick={props?.selectPreviousNft}>
                <img src={chevronLeft} alt="chevron left" className="chevron-container"/>
        </div>
        <div className="h-[100%] justify-center cursor-pointer">
          <img src={desenlargeIcon} alt="chevron left" className="chevron-container" onClick={()=>setEnlargedView(false)}/>
        </div>
        <div className="h-[100%] justify-center cursor-pointer" onClick={props?.selectNextNft}>
                <img src={chevronRight} alt="chevron right" className="chevron-container"/>
        </div>
      </div>}
    </div>
    }
    </div>
    )
    
    // <div className={`${!props.nft && 'hidden'} ${styles["nft-details"]} ${zoom && props.nft && styles["zoom-nft"]} relative`}>
    //     {props.nft && <Panel border-r-1 borderLess className="h-full">
    //         <div className={`${styles["nft-panel"]} ${styles["nft-panel-pad"]} ${zoom ? styles["zoom-panel"] : ""}`}>
    //             <div className={`${styles["tool-bar"]} ${styles["zoom-close"]}`}>
    //                 <img onClick={zoomHandler} className={`${styles["zoom"]}`} alt="NoImg" src={zoom ? maximizeIcon : maximizeIcon} />
    //                 <img onClick={closeHandler} className={`${styles["close"]}`} alt="NoImg" src={closeIcon} />
    //             </div>
                
    //             <div className={`${zoom?styles["nft-zoom-img"]:styles["nft-img"]} ${zoom && props.nft.nft && !!frameApplied ? styles['frame-image-container'] : ''}`}>                    
    //                 <NftFrame
    //                     skeltonClass='flex h-full w-full'
    //                     imageContainerClass={`${zoom?styles['selected-zoom-frame-container']:(styles['selected-detail-nft']+" h-full")}`}
    //                     isDetailView={true}
    //                     maxHeight="100%"
    //                     displayNft={props.displayNft}                                                
    //                     className={`${styles["selected-det"]}`}
    //                     frameDetails={frameApplied}                
    //                     nft={zoom ? props.nft.nft.nftcdnimage : props.nft.nft.nftcdnimage512}              
    //                     imageFile={props?.nft?.nft?.files}
    //                     shapeCollectionId={props?.nft?.nft?.collectionId}  
    //                     imageRatioCalculated={imageRatioCalculated}
    //                 />
    //             </div>
    //             <div className={`${styles["details"]}`}>
    //                 <div className={`${styles["details-box"]}`}>
    //                     <div className={`${styles["tab-box"]} flex gap-2.5 justify-between w-full`}>
    //                         <div className="flex gap-2.5">
    //                             {tabs.map(tab => <div className={styles["tab-sm"]} key={tab}><Tabs dataTab={tab} className={`p-1 flex gap-2 ${styles['tab-btn-sm']}`} onSelect={tabSelectHandler} selected={selectedTab === tab ? true : false}>{tab}{!!(selectedTab === "Frame"&&tab==="Frame") && <div onClick={(e)=>tabSelectHandler(tab,e)} className={`${frameStyle["frame-title"]} justify-end`}>
    //                                 <div className={`cursor-pointer flex ${styles["refresh-img"]}`}>
    //                                     <img src={Reload} alt="Noimg" />
    //                                 </div>
    //                             </div>}</Tabs></div>)}
    //                         </div>
    //                     </div>
    //                     <SkeltonCard show={showSpinner} height="7rem" count={2} noAbsolute={true} inline={true} containerClassName={`h-full col-frame-skelton pt-4`} />

    //                     {/* {showSpinner &&
    //                         <div className="flex w-full h-full justify-center z-10 items-center">
    //                             <Spinner show={showSpinner}
    //                                 className="flex frame-spinner justify-center items-center z-10" size={40} /></div>
    //                     } */}
    //                     {selectedTab === tabs[0] && !showSpinner && <DescriptionTab nft={props.nft.nft} />}
    //                     {selectedTab === tabs[1] && !showSpinner && <FrameitTab reloadFrameHandler={reloadFrameHandler} defaultFrame={frameApplied} selectedFrame={frameApplied} onApply={applyFrameHandler} onPreview={previewFrameHandler} nft={props.nft.nft} freeFrameList={allFrameList} freeFrameLoading={freeFrameLoading} aspectRatio={aspectRatio}/>}
    //                     {selectedTab === tabs[2] && !showSpinner && <AttributesTab nft={props.nft.nft} />}
    //                     {selectedTab === tabs[3] && !showSpinner && <ImagesTab nft={props.nft.nft} fetchDefault={getDefaultImage} />}
    //                 </div>
    //             </div>
    //         </div>
    //     </Panel>}
    // </div>
}

export default SelectedNftDetails