import React, { useEffect } from "react";
import SignIn from "../Pages/AuthLayout/Components/SignIn";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import AuthLayout from "../Pages/AuthLayout";
import Register from "../Pages/AuthLayout/Components/Register";
import Profile from "App/Pages/UserProfile/Components/Profile";
import Account from "App/Pages/UserProfile/Components/Account";
import UserProfile from "App/Pages/UserProfile";
import ForgotPassword from "App/Pages/AuthLayout/Components/ForgotPassword";
import ResetPassword from "App/Pages/AuthLayout/Components/ResetPassword";
import MyVerifiedNFTPolicies from "App/Pages/UserProfile/Components/MyVerifiedNFTPolicies";
import AccessPlans from "App/Pages/UserProfile/Components/AccessPlans";
import Preferences from "App/Pages/UserProfile/Components/Preferences";
import MyNFTCalendar from "App/Pages/UserProfile/Components/MyNFTCalendar";
import NFTResiduals from "App/Pages/UserProfile/Components/NFTResiduals";
import GalariesRentOut from "App/Pages/UserProfile/Components/GalariesRentOut";
import GalariesRent from "App/Pages/UserProfile/Components/GalariesRent";
import NftFrameShop from "App/Pages/NftFrameShop";
import TransactionSummery from "App/Pages/TransactionSummary";
import InformationDesk from "App/Pages/InformationDesk";
import InformationDetails from "App/Pages/InformationDesk/Components/InformationDetails";
import NftGallery from "App/Pages/NftGallery";
import ComingSoon from "App/Pages/ComingSoon";
import PublicRoute from "./PublicRoutes";
import Collections from "App/Pages/Collections";
import WalletAuthMesh from "App/Pages/UserProfile/Components/WalletAuthMesh";
import PreviewGallery from "App/Pages/GalleryPreview";
import Purchase from "App/Pages/UserProfile/Components/Purchase";
import Rewards from "App/Pages/UserProfile/Components/Rewards";
import Community from "App/Pages/CommunityCollection/Community";
import CommunityList from "App/Pages/CommunityCollection/Community/Components/Community";
import AuthRoute from "./AuthRoute";
import ReactGA from "react-ga4";
import AnnouncementsList from "App/Pages/Announcements";
import Discover from "App/Pages/Discover";
import Events from "App/Pages/Events";
import CreatorDetail from "App/Pages/Discover/Components/Creators/Components/CreatorDetail";
import Collectors from "App/Pages/Discover/Components/Collectors";
import CollectorDetail from "App/Pages/Discover/Components/Collectors/Components/CollectorDetail";
import CreatorCommunityDetail from "App/Pages/CommunityCollection/Community/Components/Community/Components/OverView";
import HomeV2 from "App/Pages/HomeV2";
import Subscription from "App/Pages/UserProfile/Components/Subscription";
import NftCollectionsV3 from "App/Pages/Collections/Components/NftCollectionsV3";
import Explore from "App/Pages/Explore";
import ExploreCategoryCollections from "App/Pages/Explore/Components/CategoryCollections";
import SubcategoryCollection from "App/Pages/Explore/Components/SubCategoryCollection";
import Display from "App/Pages/Display";
import DisplayItem from "App/Pages/Display/DisplayItem";
import CommunitiesCategory from "App/Pages/Discover/Communities";
import CommunityListing from './../Pages/Discover/Communities/Components/CommunityListing/index';
import Artists from "App/Pages/Discover/Artists";
import Photographers from "App/Pages/Discover/Photographers";
import DisplayGallery from "App/Pages/DisplayGallery";
import Musicians from "App/Pages/Discover/Musicians";


try {
  ReactGA.initialize(process.env.REACT_APP_G4_MEASUREMENT_ID);
} catch (error) {
  console.log(error);
}

const RoutesMain = () => {
  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Custom",
    });
  }, []);
  return (
      <Routes>
        <Route path="/" element={<HomeV2 />} />
        <Route
          path="/auth"
          element={
            <AuthRoute>
              <AuthLayout />
            </AuthRoute>
          }
        >
          <Route path="/auth/sign-in" element={<SignIn />} />
          <Route path="/auth/register" element={<Register />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
          <Route path="/auth/reset-password" element={<ResetPassword />} />
        </Route>
        <Route
          path="/curate/frame-shop"
          element={
            <PublicRoute>
              <NftFrameShop />
            </PublicRoute>
          }
        />
        <Route
          path="/transaction-summary"
          element={
            <PublicRoute>
              <TransactionSummery />
            </PublicRoute>
          }
        />
        <Route
          path="/:basePathType/collections"
          exact
          element={
            <PublicRoute>
              <Collections />
            </PublicRoute>
          }
        >
          <Route
            path="/:basePathType/collections/:collectionType" exact
            element={<NftCollectionsV3 />}
          />
            <Route
            path="/:basePathType/collections/:collectionType/:policyId" exact
            element={<NftCollectionsV3 />}
          />
        </Route>
        <Route
          path="/explore"
          element={
            <PublicRoute>
              <Explore />
            </PublicRoute>
          }
        >
          <Route
            path="/explore/:category"
            element={
              <PublicRoute>
                <ExploreCategoryCollections />
              </PublicRoute>
            }
          />
          <Route
            path="/explore/:category/:subcategory"
            element={
              <PublicRoute>
                <SubcategoryCollection />
              </PublicRoute>
            }
          />
          </Route>
        <Route
          path="/discover"
          element={
            <PublicRoute>
              <Discover />
            </PublicRoute>
          }
        >
          {/* <Route
            path="/discover/collections"
            element={
              <PublicRoute>
                <DiscoverCollections />
              </PublicRoute>
            }
          /> */}
          {/* <Route
            path="/discover/allcollections"
            element={
              <PublicRoute>
                <DiscoverAllCollections />
              </PublicRoute>
            }
          /> */}
          {/* <Route path="/discover/creators" element={<Creators />} /> */}
          <Route path="/discover/communities" element={<CommunitiesCategory />} />
          <Route path="/discover/communities/:categoryId" element={<CommunityListing />} />
          <Route path="/discover/artists" element={<Artists />} />
          <Route path="/discover/photographers" element={<Photographers />} />
          <Route path="/discover/onchain-artists" element={<Artists />} />
          <Route path="/discover/musicians" element={<Musicians />} />
          <Route
            path="/discover/collection/details/:dataid"
            element={<CreatorDetail />}
          />
          <Route
            path="/discover/communities/:communityId/:tab"
            element={<CreatorDetail />}
          />
          <Route
            path="/discover/communities/:communityId/:tab/:dataid"
            element={<CreatorDetail />}
          />
          
          <Route path="/discover/collectors" element={<Collectors />} />
          <Route
            path="/discover/collectors/details/:userId"
            element={<CollectorDetail />}
          />
        </Route>
        <Route
          path="/home"
          element={
            <PublicRoute>
              <HomeV2 />
            </PublicRoute>
          }
        />
        {/* <Route path="/homeV2" element={<PublicRoute><HomeV2 /></PublicRoute>} /> */}
        <Route path="/preview-gallery" element={<PreviewGallery />} />
        <Route
          path="/information-desk"
          element={
            <PublicRoute>
              <InformationDesk />
            </PublicRoute>
          }
        />
        <Route
          path="/information-desk/:id"
          element={
            <PublicRoute>
              <InformationDetails />
            </PublicRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <UserProfile />
            </PrivateRoute>
          }
        >
          {/* <Route path="/profile/user" element={<Profile />} /> */}
          <Route path="/profile/user/:tab" element={<Profile />} />
          <Route path="/profile/user/:tab/view" element={<Profile />} />
          <Route path="/profile/subscription" element={<Subscription />} />
          <Route path="/profile/account" element={<Account />} />
          <Route path="/profile/preferences" element={<Preferences />} />
          <Route
            path="/profile/my-verified-nft-policies"
            element={<MyVerifiedNFTPolicies />}
          />
          <Route
            path="/profile/wallet-authentication"
            element={<WalletAuthMesh />}
          />
          <Route path="/profile/access-plans" element={<AccessPlans />} />
          <Route path="/profile/my-nft-calendar" element={<MyNFTCalendar />} />
          <Route path="/profile/nft-residuals" element={<NFTResiduals />} />
          <Route
            path="/profile/galaries-rent-out"
            element={<GalariesRentOut />}
          />
          <Route path="/profile/galaries-rent" element={<GalariesRent />} />
          <Route path="/profile/purchase" element={<Purchase />} />
          <Route path="/profile/rewards" element={<Rewards />} />
        </Route>
        <Route path="/curate" element={ <PublicRoute> <NftGallery /> </PublicRoute>}>
          <Route path="my-galleries" element={<NftGallery />}>
            <Route path="view/:galleryId" element={<NftGallery />} />
            <Route path="add/:pgaction" element={<NftGallery />} />
            <Route path="add/:pgaction/:policyid" element={<NftGallery />} />
            <Route path="edit/:galleryId/:pgaction" element={<NftGallery />} />
            <Route path="edit/:galleryId/:pgaction/:policyid" element={<NftGallery />} />
          </Route>
        </Route>
        <Route path="/community" exact element={ <PrivateRoute> <Community /> </PrivateRoute> } >
          <Route path="/community" exact element={<CommunityList />} />
          {/* <Route path="/community/update-data" exact element={<CommunityUpdate />} />
          <Route path="/community/update-data/:id" exact element={<CommunityUpdate />} /> */}
          {/* <Route exact path="/community/my-galleries/:communityid"  element={<NftGallery />} /> */}
          {/* <Route exact path="/community/collections/update-collection" element={<UpdateCollection />} />
          <Route exact path="/community/collections/update-collection/:collectionId" element={<UpdateCollection />} /> */}
          <Route path="/community/:communityid/:tab" element={<CreatorCommunityDetail />} />
          <Route path="/community/:communityid/:tab/:collectionId" element={<CreatorCommunityDetail />} />
          <Route path="/community/:communityid/:tab/view/:galleryId" exact element={<CreatorCommunityDetail />} />
          {/* <Route path="/community/:communityid/my-galleries/view/:galleryId" exact element={<NftGallery />} /> */}
          <Route path="/community/:communityid/my-galleries/add/:pgaction" exact element={<NftGallery />} />
          <Route path="/community/:communityid/my-galleries/add/:pgaction/:policyid" element={<NftGallery />} />
          <Route path="/community/:communityid/my-galleries/edit/:galleryId/:pgaction" element={<NftGallery />} />
          <Route path="/community/:communityid/my-galleries/edit/:galleryId/:pgaction/:policyid" element={<NftGallery />} />
        </Route>
        <Route
          path="/curate/posts"
          element={
            <PublicRoute>
              <AnnouncementsList />
            </PublicRoute>
          }
        />
        <Route
          path="/curate/events"
          element={
            <PublicRoute>
              <Events />
            </PublicRoute>
          }
        />         
        <Route
          path="/displaygallery"
          exact
          element={
            <PublicRoute>
              <DisplayGallery />
            </PublicRoute>
          }
        />
        <Route
          path="/display"
          exact
          element={
            <PublicRoute>
              <Display />
            </PublicRoute>
          }
        />                    
        <Route
          path="/display/:id"
          exact
          element={
            <PublicRoute>
              <DisplayItem />
            </PublicRoute>
          }
        />                  
        {/* <Route
          path="/listen"
          exact
          element={
            <PublicRoute>
              <Collections />
            </PublicRoute>
          }
        >
          <Route
            path="/listen/collections/:collectionType" exact
            element={<NftCollectionsV3 listen={true}/>}
          />
           <Route
            path="/listen/collections/:collectionType/:policyId" exact
            element={<NftCollectionsV3 listen={true}/>}
          />
        </Route> */}
        <Route path="*" element={<ComingSoon />} />
      </Routes>
  );
};
export default RoutesMain;
