import { Fragment, useCallback, useEffect, useLayoutEffect, useRef } from "react"
import GalleryImages from "../../../../../../Components/Ui/GalleryFrameImages"
import AddNewGallery from "Assets/Images/AddNew.svg";
import CreateGallery from "./Components/CreateGallery";
import { useState } from "react";
import { useStore } from "App/hooks-store/store";
import useScreenSize from "App/hooks/use-screen-size";
import { useSelector, useDispatch } from "react-redux";
import closeIcon from "Assets/Images/close-circle-icon.svg"
import { getCurrentFormValues, setCurrentFormValues } from "App/ReduxStore/Slicers/gallerySlice";
import { useNavigate } from "react-router-dom";
import {  sequenceUpdate } from "App/Helper/utilities";

const AddedNfts = (props) => {
    const screenSize = useScreenSize()?.width;
    const navigate = useNavigate()
    const [frameAsset, setFrameAsset] = useState('')
    const [screenshotNfts, setScreenshotNfts] = useState({});
    const [draggedItemId, setDraggedItemId] = useState(null);
    const [draggedOverContainerId, setDraggedOverContainerId] = useState(null);
    const [{ currentGallery, currentSelectedNfts, currentFormValues, leftMenuCollapse }, dispatch] = useStore(false);
    const [frameAssets, setFrameAssets] = useState({})
    const [reRenderList, setReRenderList] = useState(0); //used to when remove asset need to trigger render to capture ratio
    const targetRef = useRef();
    const dispatchRedux = useDispatch();
    const currentForm = useSelector(getCurrentFormValues)
    
    useEffect(()=>{
        if(currentGallery?.assets?.length){
            setFrameAsset({ asset: currentGallery?.assets[0]?.asset, networkType:currentGallery?.assets[0]?.networkType, frameId: currentGallery?.assets[0]?.frameId })
        }else{
            setFrameAsset({})

        }
    },[currentGallery?.assets])
    const onClickAddedNfts = (e) => {
        let frames = []
        let updateGallery = currentGallery?.assets?.find((x) => x?.asset === e.currentTarget?.dataset?.id)
        if (updateGallery) {
            frames = updateGallery
        }
        else {
            frames = currentSelectedNfts?.find((x) => x?.asset === e.currentTarget?.dataset?.id)
        }
        setFrameAsset({ asset: e.currentTarget?.dataset?.id, networkType: e.currentTarget?.dataset?.network, frameId: frames?.frameId })
    }
    useEffect(() => {
        setFrameAssets(frameAsset)
    }, [frameAsset])

    const onChangeFrame = (e) => {
        setFrameAsset({ ...frameAsset, ...e })
    }
    useEffect(() => {
        if(currentSelectedNfts?.length) {
            
            let assetArray = currentSelectedNfts?.map((el, i) => { return {data:el, asset: el?.asset ?? null, frameId: el?.frameId ?? null, sequence: el?.sequence || (i + 1), isLandscape: el?.isLandscape ?? false } });
           
            let layoutId = currentSelectedNfts?.length === currentForm?.assets?.length ? currentForm?.layoutId : undefined
            if(!currentGallery?.id || (currentForm?.assets?.length !== currentSelectedNfts?.length)) {
                assetArray &&  assetArray?.map((ele, index) => ele.sequence = index + 1 )   
            }
            dispatch('setCurrentFormValues', {
                ...currentFormValues,
                assets: assetArray,
                layoutId: layoutId
            })
            dispatchRedux(setCurrentFormValues({...currentForm, assets: assetArray, layoutId}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectedNfts]);
    useEffect(() => {
        props?.setRatios([]);
        setReRenderList(prev => prev + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentForm?.assets?.length])
    const onScreenCaptureNfts = (e) => {
        setScreenshotNfts((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }

    useEffect(() => {
        if (currentSelectedNfts?.length && Object.keys(screenshotNfts).length === currentSelectedNfts?.length) {
            props?.screenCapture(screenshotNfts)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenshotNfts])

    useEffect(() => {
        if (currentSelectedNfts?.length) {
            let checkValid=currentSelectedNfts?.some(el=>el?.asset===frameAssets?.asset)
           if(!Object.keys(frameAssets)?.length || !checkValid) setFrameAssets({ asset: currentSelectedNfts[0]?.asset, networkType: currentSelectedNfts[0]?.asset?.network, frameId: currentSelectedNfts[0]?.frameId, isLandscape: currentSelectedNfts[0]?.isLandscape ?? false })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectedNfts])

    useEffect(() => {
        setFrameAsset('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateHeight = () => {
        
        const root = document.documentElement;
        if (targetRef.current) {
            root?.style.setProperty(
                "--collection-added-card-height", `${targetRef.current.offsetWidth}px`
            );
        }
    }
    useLayoutEffect(() => {
        updateHeight()
        // window.addEventListener("resize", () => {
        //     setTimeout(() => {
        //         updateHeight()            
        //     }, 1000);
        // }, false);
    },[]);
    useLayoutEffect(() => {
        updateHeight()
    },[targetRef?.current?.offsetWidth]);
    useEffect(() => {
        setTimeout(() => {
            updateHeight()            
        }, 1000);
        updateHeight()
    },[leftMenuCollapse, currentSelectedNfts, screenSize]);
    const onImageClick = (e) => {
            e.stopPropagation();
            props?.action(e);        
    }


  const handleDragStart = (id) => setDraggedItemId(id);
  const handleDragEntered = (id) => {
    if(currentSelectedNfts?.length>1){
        setDraggedOverContainerId(id)
    }
    
}
  const handleDragLeave = () => setDraggedOverContainerId(null);
  const handleDrop = () => {

    if (!draggedOverContainerId) {
     // clearState();
      return;
    }
    const fromIndex = currentSelectedNfts.findIndex((w) => w.asset === draggedItemId);
    const toIndex = currentSelectedNfts.findIndex((w) => w.asset === draggedOverContainerId);
    dispatch('setCurrentSelectedNfts', sequenceUpdate(currentSelectedNfts, fromIndex, toIndex))
    clearState();
  };

  const clearState = () => {
    setDraggedItemId(null);
    setDraggedOverContainerId(null);
  };

  useEffect(()=>{
    if(!draggedOverContainerId&&draggedItemId&&currentSelectedNfts?.length>1){
        setDraggedOverContainerId(draggedItemId)
    }
  },[draggedItemId,draggedOverContainerId,currentSelectedNfts])
  useEffect(() => {
    if(props?.pageType === 'add' && !currentSelectedNfts?.length)
        navigate(`${props?.galleryBaseUrl}/${props?.pageType}/collection`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.pageType, currentSelectedNfts?.length])
  const backtoSelect = useCallback(() => {
    navigate(`${props?.galleryBaseUrl}/${props?.pageType}/collection`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.pageType]);
  useEffect(() => {
    dispatch('resetAll');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div className="flex">
                <div className="flex add-nft added-nfts h-full added-nft-sm my-4">
                    <div className={`grid-lg pb-8 pl-4 scroller`} >
                    <div className="nft-gallery-add flex flex-col aspect-square items-center justify-center" onClick={backtoSelect} onDragEnter={handleDragLeave}>
                            <img src={AddNewGallery} alt="gallery" className="w-[4rem] h-[4rem]"/>
                            <div>Curate</div>
                            </div>
                            {currentSelectedNfts?.map((selectedNfts, i) =>
                                    <div onDrop={handleDrop}
                                        onDragStart={() => handleDragStart(selectedNfts.asset)}
                                        onDragEnter={() => handleDragEntered(selectedNfts.asset)}
                                        onDragOver={(e) => e.preventDefault()}
                                        key={(reRenderList + selectedNfts?.asset + (selectedNfts?.frameId ?? -1))} className={`${selectedNfts?.asset === frameAssets?.asset ? 'active' : ''} ${(selectedNfts.asset === draggedOverContainerId)&&"drag-start"} drag-n-drop relative nft-selected-full ${selectedNfts?.isLandscape && ''}`}>
                                            <div 
                                                ref={targetRef} 
                                                draggable className={`added-card collection-card flex flex-col `} 
                                                data-network={selectedNfts?.networkType} 
                                                data-id={selectedNfts?.asset}
                                                onClick={onClickAddedNfts}>
                                                    <GalleryImages
                                                        frameName={`frame${i}`}
                                                        clearCache={false}
                                                        onScreenCaptureNfts={onScreenCaptureNfts}
                                                        addedNfts={true}
                                                        frameToggle={true}
                                                        actionImage={currentSelectedNfts?.length>1&&closeIcon}
                                                        action={currentSelectedNfts?.length>1&&'close'}
                                                        index={i}
                                                        onClick={currentSelectedNfts?.length>1?onImageClick:undefined}
                                                        fileName={`file${i}`}
                                                        dataId={selectedNfts?.asset}
                                                        galleryNew={selectedNfts}
                                                        src={`${selectedNfts?.nftcdnimage256}`}
                                                        nftGalleryNew={true}
                                                        imageRatioCalculated={props?.imageRatioCalculated}
                                                        className="nft-gallery-card-image"
                                                        />
                                                    {/* {captionToggle && <div className="flex absolute nft-selected-name ">
                                                        <span className={`name name-lg`}>
                                                            {selectedNfts?.name ? selectedNfts?.name : 'No Name'}
                                                        </span>

                                                    </div>} */}
                                            </div>
                                    </div>                            
                            )}
                    </div>
                </div>
                <div className="flex flex-1 create-gallery-view create-gallery-view-sm" onDragEnter={handleDragLeave}>
                    <CreateGallery 
                        setFormVaildState={props?.setFormVaildState}
                        onChangeFrame={onChangeFrame} selectedWall={props?.selectedWall}
                        OnSelectWall={props?.OnSelectWall}
                        showPrimaryDiscoverable={props?.showPrimaryDiscoverable}
                        setFrameAsset={setFrameAsset}
                        frameAsset={frameAssets}
                        selectedNfts={currentSelectedNfts}
                        onSmFramClick={onClickAddedNfts}
                        onClickAdd={props?.onClickAdd}
                        onClickExit={props?.onClickExit}
                        onScreenCaptureNfts={onScreenCaptureNfts}
                        onToGallery={props.onToGallery}
                    />
                </div>
            </div>

        </>
    )
}

export default AddedNfts