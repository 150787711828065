import "./CommunityCard.scss";
import CommunityCardBox from "../CommunityCardBox";

const PageListing = (props) => {

  return (
    <div className="community-grid list-container grid page-listing-community">
      {props?.list?.map((data, index) => {
        return <CommunityCardBox key={data?.id||index} data={data}  {...props}/>
      })}
    </div>
  );
};

export default PageListing;
