import useHttp from "App/hooks/use-http";
import { useEffect } from "react";
import { useState } from "react";
import GalleryImages from "App/Components/Ui/GalleryFrameImages"
import SliderGallery from "App/Components/Ui/SliderGallery";
import { useStore } from "App/hooks-store/store";
import SkeltonCard from "App/Components/Ui/SkeltonCard";
import BackgroundWallFilter from "App/Components/Ui/BackgroundWallFilter";
import useLayoutData from "App/hooks/use-layout";
import LayoutDesign from "./Components/Layouts";
import "./GalleryPreview.scss";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentFormValues, setCurrentFormValues } from "App/ReduxStore/Slicers/gallerySlice";

const GalleryPreview = (props) => {
    const [list, setList] = useState();
    const { triggerAPI } = useHttp();
    const [image, setImage] = useState(props?.selectedWall);
    const [spinnerShow, setSpinnerShow] = useState(true)
    const [selectedLayout, setSelectedLayout] = useState(null)
    const [layoutList, setLayoutList] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [noColor, setNoColor] = useState(false);
    const { getLayoutFromStore } = useLayoutData();
    const [{ currentFormValues }, dispatch] = useStore(false);
    const dispatchRedux = useDispatch();
    const currentForm = useSelector(getCurrentFormValues)
    const onClickHandler = (e) => {
        const newImage = e.currentTarget?.dataset?.image
        setImage(newImage)
        props?.OnSelectWall(newImage)

        inputOnChange({ twoDWallBackgroundId: Number(e.currentTarget?.dataset?.id) })
    }
    const getWallResult = (res) => {
        const list = res.data
        const [dataset = {}] = res.data.reverse()
        dispatch('setCurrentWallList', res?.data)
        setList(list)
        setSpinnerShow(false);
       
        if (!currentForm?.id) {
            const newImageWall = dataset?.imageUrl
            if (!props?.selectedWall) {
                setImage(newImageWall)
                props?.OnSelectWall(newImageWall)
                inputOnChange({ twoDWallBackgroundId: dataset?.id })
            }
           // !noColor&& setColor(dataset?.color)
        }
        //else{
           // !noColor&& setColor(currentFormValues?.thumbnailBackground?.color)
       // }
    }
    useEffect(() => {
        getLayoutFromStore((res) => {
            setLayoutList(res);
        })        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getWallDetails = () => {
        setSpinnerShow(true)
        triggerAPI({
            url: `background-thumbnail/user/get`, data: { color: props?.color, style: props?.style }, method: 'post'
        }, getWallResult);
    }

    useEffect(() => {
        if(props?.colorFetched)
            getWallDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.style, props?.color, props?.colorFetched])

    const inputOnChange = (e) => {
        dispatch('setCurrentFormValues', {
            ...currentFormValues,
            ...e
        })
        dispatchRedux(setCurrentFormValues({...currentForm, ...e}));
    }
    useEffect(() => {
        if (currentForm?.twoDWallBackgroundId && currentForm?.id && !image) {
            const value = list?.find((val) => val.id === currentForm?.twoDWallBackgroundId)?.imageUrl
            setImage(value)
            props?.OnSelectWall(value)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentForm?.id, list])

    useEffect(() => {
        if (props?.selectedNfts?.length && layoutList?.length) {
            let count = props?.selectedNfts?.length >= 8 ? 8 : props?.selectedNfts?.length;
            let temp = props?.selectedNfts?.length !== currentForm?.assets?.length ? layoutList?.find(item => item?.count === count) : layoutList?.find(item => currentForm?.layoutId === item?.id) || layoutList?.find(item => item?.count === count)
                setSelectedLayout(temp);
                dispatch('setCurrentFormValues', {
                    ...currentFormValues,
                    layoutId: temp?.id
                })
                dispatchRedux(setCurrentFormValues({...currentForm, layoutId: temp?.id}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.selectedNfts?.length, layoutList])
    
    
    return (


        <div className="flex flex-col items-start glry-arrang overflow-hidden gallery-preview gap-4">
           <div className="selection-head flex pt-[0.6rem] sm:hidden " >
                <div>
                    Gallery ({props?.selectedNfts?.length})
                </div>
            </div>
            <div className="gallery-preview-section w-full">
                {(!image && spinnerShow) ? <SkeltonCard noAbsolute={true} show={true} height="18.75rem" inline={true} containerClassName={`flex preview-skelton`} /> :
                    props?.selectedNfts?.length === 0 ?
                        <div className="py-5 flex justify-center">
                            No Nfts Added
                        </div> : image && props?.selectedNfts?.length !== 0 &&
                        <div className="preview-slider">
                            <SliderGallery selectedLayout={selectedLayout} 
                                addedGallery={true} 
                                frameDetails={props?.frameDetails} 
                                src={props?.selectedWall} 
                                className="gallery-preview-wall flex justify-center items-center" 
                                selectedNfts={props?.selectedNfts}
                                sliderClass="gallery-slider-1"
                            />
                        </div>
                }
            </div>
            <div className="w-full background-list-wrapper mt-[0.5rem] overflow-y-auto overflow-x-hidden">
                <div className="sm:mx-[0.875rem] mx-0 sm:pr-0 pr-[0.45rem] pb-[0.375rem] filter-field flex justify-between flex-col gap-4 items-center mb-3">
                    <LayoutDesign layoutList={layoutList?.filter(item => item?.count <= props?.selectedNfts?.length)} selected={selectedLayout} setSelectedLayout={setSelectedLayout} count={props?.selectedNfts?.length >= 8 ? 8 : props?.selectedNfts?.length} />
                </div>
                <div className="sm:mx-[0.875rem] mx-0 sm:pr-0 pr-[0.45rem] pb-[0.375rem] filter-field flex justify-between items-center">
                    <span className=" text-left pb-1 flex w-full background-text">Background</span>
                    <BackgroundWallFilter setNoColor={setNoColor} colorFetched={props?.colorFetched} setColorFetched={props?.setColorFetched} style={props?.style} color={props?.color} setStyle={props?.setStyle} setColor={props?.setColor} />
                </div>
                <div className="flex wall-style sm:mx-[0.625rem] mx-0 pt-[0.625rem] w-full ">
                {!!(!spinnerShow&&!list?.length)&&<div className="py-[2rem] flex justify-center w-full mr-[0.625rem]">
                        No background walls found
                    </div>}
                    {!!(spinnerShow)&&<div className="grid w-full cursor-pointer sm:mr-[1.5rem] mr-0 sm:pr-0 pr-[0.45rem]" >
                    <SkeltonCard show={spinnerShow} height="100%" count={1} noAbsolute={true} inline={true} containerClassName={`h-full min-h-[6rem]`} />
                        

                    </div> }
                  {!!(!spinnerShow&&list?.length)&&<div className="grid w-full cursor-pointer overflow-auto pb-4 sm:mr-[1.5rem] mr-0 pr-[0.45rem]" >
                        {list?.map((wall, index) =>
                            <div key={index} onClick={onClickHandler} data-image={wall?.imageUrl512} data-id={wall?.id} className={`${currentForm?.twoDWallBackgroundId === wall?.id ? 'add' : 'wall-border'} `}>
                                <GalleryImages galleryPreview={true} key={index} gallery={true} src={wall?.imageUrl256} className="gallery-preview-image gallery-frames " />
                            </div>

                        )}

                    </div> }

                </div>
            </div>
        </div>
    )
}

export default GalleryPreview