import styles from './Profile.module.scss'
import { useEffect, useState, useCallback } from 'react'
import EditProfile from './Components/EditProfile'
import ChangePassword from './Components/ChangePassword'
import MessageBox from 'App/Components/Ui/MessageBox'
import { getInitial, markAsTouched, validateFormGroup } from "App/Helper/formValidation";
import { useStore } from 'App/hooks-store/store'
import useHttp from 'App/hooks/use-http'
import { scrollToTop } from 'App/Helper/utilities'
import useScreenSize from 'App/hooks/use-screen-size'
import ModalOuter from 'App/Components/Ui/ModalOuter'
import closeIcon from "Assets/Icons/close.svg"
import ProfileView from './Components/ProfileView'

const Profile = () => {
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const [changePassword, setChangePassword] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [formVaildState, setFormVaildState] = useState({});
    const screenSize = useScreenSize()?.width;
    const formGroup = {
        username: {
            value: undefined,
            isRequired: true
        },
        email: {
            value: undefined,
            isRequired: false
        },
        description: {
            value: undefined,
            isRequired: false
        },
        nationality: {
            value: undefined,
            isRequired: false
        },
        hashtags: {
            value: undefined,
            isRequired: false
        },
        instagram: {
            value: undefined,
            isRequired: false
        },
        twitter: {
            value: undefined,
            isRequired: false
        },
        youtube: {
            value: undefined,
            isRequired: false
        },
        website: {
            value: undefined,
            isRequired: false
        }
    }
    const inputOnChange = (e) => {
        setEditUser((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])

    const [user, setUser] = useState({
        username: undefined,
        email: "",
        description: "",
        nationality: "",
        hashtags: "",
        instagram: "",
        twitter: "",
        youtube: "",
        website: ""
    })
    const [editUser, setEditUser] = useState(getInitial(formGroup));
    const [isEdit, setIsEdit] = useState(false)
    const [nations, setNations] = useState([])
    // const [userAgree, setUserAgree] = useState(false)
    const [agreeWarning, setAgreeWarning] = useState(false)
    //Get user profile Api
    const getProfileResult = useCallback((res) => {
       // dispatch('hideSpinner');
        const data = res.data
        let hashtagStr = ''
        if (data.hashtags) {
            data.hashtags.forEach(element => {
                hashtagStr = hashtagStr + (element + ' ')
            });
        }
        const profileData = {
            username: data.username ? data.username : undefined,
            email: data.email ? data.email : "",
            description: data.description ? data.description : "",
            nationality: data.nationality ? data.nationality : "",
            hashtags: hashtagStr,
            instagram: data.instagram ? data.instagram : "",
            twitter: data.twitter ? data.twitter : "",
            youtube: data.youtube ? data.youtube : "",
            website: data.website ? data.website : "",
        }
        setUser(profileData)
        // setUserAgree(data.isNFTAccessGranted)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    const getProfile = useCallback(() => {
        //dispatch('showSpinner')
        triggerAPI({
            url: `user/profile/get`, method: 'get'
        }, getProfileResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, getProfileResult, triggerAPI]);

    const getCountryResult = useCallback((res) => {
      //  dispatch('hideSpinner');
        const data = res.data;
        setNations([{id:'', value:'None'},...data.countryList]);
        // setUserAgree(data.isNFTAccessGranted)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    useEffect(() => {
        if(nations.length){
            getProfile();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[nations])
    const getCountryList = useCallback(() => {
       // dispatch('showSpinner')
        triggerAPI({
            url: `user/country-list`, method: 'get'
        }, getCountryResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, getCountryResult, triggerAPI]);

    useEffect(() => {
        // getProfile()
        getCountryList()
        dispatch("setPageTitle", "Profile")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const cancelButtonHandler = () => {
        setIsEdit(setIsEdit(false))
    }
    const editButtonHandler = () => {
        if (isEdit) {
            const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: editUser });
            if (valid) {
                // if (userAgree) {
                const data = {...editUser}
                delete data['id'];
                delete data['email'];
                const hashtagArr = data.hashtags.split(' ').filter(e => { return e.trim().length > 0; })
                dispatch('showSpinner')
                triggerAPI({
                    url: `user/profile/update`, data: { ...data, hashtags: hashtagArr }, method: 'patch'
                }, (res) => {
                    dispatch('hideSpinner');
                    setIsEdit(false)
                    setRefresh((prev)=>!prev)
                    getProfile()
                    dispatch('showToast', { toast: { toastMode: 'success', message: "Profile updated Successfully" } })
                }, err => {
                    dispatch('hideSpinner');
                    dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.error?.message||"Profile update failed" } })
                });
                // }
                // else
                //     setAgreeWarning(true)
            }
            else {
                const newValues = markAsTouched(editUser);
                setEditUser(newValues);
            }
        }
        else {
            setEditUser(user)
            setIsEdit(true)
        }
    }
    // const userAgreeHandler = toggleValue => {
    //     dispatch('showSpinner')
    //     triggerAPI({
    //         url: `user/nft-access/update`, data: {
    //             "isNFTAccessGranted": toggleValue
    //         }, method: 'patch'
    //     }, (res) => {
    //         dispatch('hideSpinner');
    //         getProfile()
    //     }, err => {
    //         dispatch('hideSpinner');
    //     });
    // }

    //Change password 
    const changePasswordHandler = () => {
        setChangePassword(true)
    }
    const changePassCloseHandler = () => {
        setChangePassword(false)
    }
    const changePasswordCompleteHandler = () => {
        setChangePassword(false)
    }

    //User agree popup
    const agreeWarningCloseHandler = () => {
        setAgreeWarning(false)
    }
    useEffect(() => {
        if (screenSize < 551) {
            setTimeout(() => {
                scrollToTop();
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={`w-full ${styles['content-sec']}`}>
            {isEdit && <ModalOuter onClose={() => { }}
                openModal={isEdit}
                parentClass={isEdit ? '!z-[10]' : '!hidden !z-[10]'}
                contentClass={'animated-slideIn w-full max-w-[900px] h-auto max-h-[85dvh] overflow-x-hidden overflow-y-auto p-5 bg-[var(--cu-black-700)] border border-[var(--cu-black-100)] rounded-lg mx-[5%]'}
            >
                <div className="w-full flex items-center justify-between" >
                    <span />
                    <h2>Profile</h2>
                    <button onClick={cancelButtonHandler}><img src={closeIcon} alt="close" /></button>
                </div>
                <EditProfile user={editUser} nations={nations} onUpdateButtonClick={editButtonHandler} onChangePassword={changePasswordHandler} onInputChange={inputOnChange} collectErrors={collectErrors} onCancelEdit={cancelButtonHandler}></EditProfile>
            </ModalOuter>}

            <ProfileView refetchData={refresh} onEditButtonClick={editButtonHandler}/>
            
            {changePassword && <ChangePassword onCancel={changePassCloseHandler} onComplete={changePasswordCompleteHandler}></ChangePassword>

            }
            {agreeWarning && <MessageBox onCloseBtnClick={agreeWarningCloseHandler}>Please accept the term and condition to proceed</MessageBox>}
        </div>

    )
}

export default Profile