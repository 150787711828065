/* eslint-disable react-hooks/exhaustive-deps */
import styles from './Header.module.scss'
import logo from 'Assets/Images/Logo.svg'
import miniLogo from 'Assets/Icons/cur8-logo-mini.svg';
import hamburgerIcon from 'Assets/Icons/hamburger.svg'

// import i from 'Assets/Icons/i.svg'
// import discordIcon from 'Assets/Icons/discord.svg'
// import twitterIcon from 'Assets/Icons/twitter.svg'
import userAvatar from 'Assets/Icons/userAvatars.svg'
import userIcon from 'Assets/Icons/user-white.svg'
import userIconActive from 'Assets/Icons/user-blue.svg'
import logOutIcon from 'Assets/Icons/logout-white.svg'
import logOutIconActive from 'Assets/Icons/logout-blue.svg'
import creatorIcon from 'Assets/Icons/creator-icon.svg'
import creatorIconActive from 'Assets/Icons/creator_active.svg'
import cur8Icon from 'Assets/Icons/logo-square.svg'
// import menuIcon from 'Assets/Icons/menu_icon.svg'
import { useLocation, useNavigate } from "react-router-dom";
import RoundedButton from 'App/Components/Ui/RoundedButton'
import { useCallback, useEffect, useState } from 'react'
import { useStore } from 'App/hooks-store/store'
import useHttp from 'App/hooks/use-http'
import useAuth from 'App/hooks/use-auth'
import DropDown from 'App/Components/Ui/DropDown'
import LogOutWarn from './Components/LogOutWarn/LogOutWarn'
import WalletSelector from './Components/WalletSelector'
import storage from 'App/Helper/storage'
import AuthPop from 'App/Components/Ui/AuthPop'
import WalletSelectPop from 'App/Components/Ui/WalletSelectPop'
import ImagePopup from 'App/Components/Ui/ImagePopup'
import Register from 'App/Pages/AuthLayout/Components/Register'
import useCollectionLoader from "App/hooks/use-collectionLoader"
import TopHeader from 'App/Components/TopHeader'
import useScreenSize from 'App/hooks/use-screen-size';
import { arrangeAssetData, removeDuplicateByElement } from 'App/Helper/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { getShowWalletPop ,setLoggingOutStatus,setShowWalletPop} from 'App/ReduxStore/Slicers/authSlice';
import { getCurrentFormValues } from 'App/ReduxStore/Slicers/gallerySlice';
const Header = props => {
    const [{ currentWallet,leftMenuCollapse }, dispatch] = useStore(false);
    const { pathname } = useLocation()
    let { triggerAPI } = useHttp();
    const store = useStore()[0];
    const [showAuthPop, setShowAuthPop] = useState(false)
    const [dropDown, setDropDown] = useState(false);
    const [logOutWarning, setLogOutWarning] = useState(false)
    const [userData, setUserData] = useState({})
    const [showRegisterPop, setShowRegisterPop] = useState(false)
    const [profilePicUrl, setprofilePicUrl] = useState(cur8Icon)
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()
    const [walletAuthPop,setWalletAuthPop] = useState(false)
    const logoutMenu = {name: 'Logout', value:'Logout', icon: logOutIcon, activeIcon: logOutIconActive}
    const dropDownElements = [{name: 'Profile',value: 'Profile', icon: userIcon, activeIcon: userIconActive}, 
    {name: 'Creators', value: 'Creators', icon: creatorIcon, activeIcon: creatorIconActive},logoutMenu];
    const dropDownElementsGuest = [{name: 'Login',value: 'Login', icon: logOutIcon, activeIcon: logOutIconActive}];
    const { profilePic, enableScrollMenu } = store    
    const { sliceCollectionAPI,sliceTokenAPI } = useCollectionLoader()
    const isLoggedIn = useAuth()[0]
    const logout = useAuth()[1];
    const isLoggedInGuest = useAuth()[4];    
    const handleB2CLogin = useAuth()[6];    
    const screenSize = useScreenSize()?.width;
    const isCommunity = pathname === '/community';
    const isGalleryPreview = pathname.includes('preview-gallery');
    const dispatchRedux = useDispatch();
    const showWalletAuthPop = useSelector(getShowWalletPop);
    const currentForm = useSelector(getCurrentFormValues)
    const [{allCollectionData,collections}] = useStore();
    useEffect(() => {
        console.log('currentForm', currentForm);
    }, [currentForm])
    const getConnectedWallets = useCallback((data) => {
        triggerAPI({
          url: `user/connected-wallet/get`, method: 'get'
        }, (res)=>{
            getTokenAssetIdsListUpdate(data,res?.data)
        },()=>{
            getTokenAssetIdsListUpdate(data,[])
        });
      }, [triggerAPI, dispatch])    

    const getWallResult = (res) => {
        dispatch('setCurrentWallList', res.data)
       
    }
    const getWallDetails = () => {
        triggerAPI({
            url: `background-thumbnail/user/get`,data:{}, method: 'post'
        }, getWallResult);
    }
    useEffect(()=> {
        if (!isLoggedIn)
        {
            //try a silent login            
            handleB2CLogin(true);
        }
    }, [isLoggedIn])
    useEffect(() => {
        if ((isLoggedIn || isLoggedInGuest) && store?.isUserSet) {            
            if (allCollectionData?.collection?.length === 0 || collections?.length === 0)
            {
                dispatch("setCollections", { loading: false, data: [], count: 0 })
                dispatch("setTokenCollection", { loading: false, data: [], count: 0 })
                dispatch("setArtBank", { loading: false, data: [], count: 0 })
                dispatch("setNftDrop", { loading: false, data: [], count: 0 })
                dispatch("updateCollectionLoadingStatus", 'idle')
                dispatch("updateTokenLoadingStatus", 'idle')
                dispatch("updateArtBankLoadingStatus", 'idle')
                dispatch("updateNftDropLoadingStatus", 'idle')
            }
        }
        if(isLoggedIn) {
            getWallDetails()
        }
        if(!isLoggedIn && currentWallet?.address) {
            setprofilePicUrl(cur8Icon)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, currentWallet?.address, store?.isUserSet])    
 
    useEffect(() => {        
        if ((isLoggedIn && !isGalleryPreview && store?.isUserSet)) {                              
            if (currentWallet?.address)
            {
                // removed this code, as it triggers every time a wallet is connected, not just upon wallet link
                // setTimeout(function() {             
                //     getTokenAssetIdsList()
                // }, 2000);
            }
            else
            {
                if (allCollectionData?.collection?.length === 0 || collections?.length === 0)
                {
                    //token fetching
                    getTokenAssetIdsList()
                }                
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, currentWallet?.address, store?.isUserSet])
    useEffect(() => {
        if (isLoggedIn)
            getProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profilePic,isLoggedIn])
    useEffect(() => { 
        getCategories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //token asset

    const getTokenAssetIdsListUpdate=(data,wallets)=>{
        if (typeof data?.wallets === "object" && Object.values(data?.wallets)?.length > 0)
        {
            let assets = [];
            for (let i = 0; i < Object.values(data.wallets).length; i++) {
                const wallet = Object.values(data.wallets)[i];
                assets = assets.concat(wallet.asset_list.map(asset => {
                    return {
                        asset: asset.policy_id + asset.asset_name,
                        asset_name: asset.asset_name,
                        policy_id: asset.policy_id,
                        quantity: asset.quantity,
                        stakeAddress: wallet.stake_address,
                        walletId: i,
                        createdAt: asset.createdAt
                    }                    
                }));
            }                
            
            if (assets.length > 0)
            {
                const result = assets.reduce(function (r, a) {
                    r[a.walletId] = r[a.walletId] || [];
                    r[a.walletId].push(a);
                    return r;
                }, {});
                var groups = Object.keys(result).map(function (key) {
                    let findWallet=wallets?.find((el)=>el?.stakeAddress===result[key][0]?.stakeAddress)
                    return {walletId: key, assetCount: result[key].length, walletName:findWallet?.walletName,id:findWallet?.id};
                });
                //all user asset are setting in new redux store
                let allUserAssets = [];
                wallets?.map(x => allUserAssets.push(...x?.asset_details));
                dispatch("setAllUserCollection", allUserAssets)                
                dispatch("updateCollectionWalletData", groups)                
                dispatch("updateAllCollectionData", {collection: assets})                
                
                const arrangedData = arrangeAssetData(assets)                
                sliceCollectionAPI(assets)
                dispatch("updateCollectionRawData", arrangedData?.groups)
                dispatch("updateCollectionAssetCount", arrangedData?.assetCount)
            }
            else {
                dispatch("setCollections", { loading: false, data: [], count: 0 })
                dispatch("updateCollectionLoadingStatus", 'finished')  
            }
        } else 
            dispatch("updateCollectionWalletData", [])      
        if (data?.tokens?.length) {
            data.tokens = removeDuplicateByElement(data.tokens, 'asset_name')                
            dispatch("updateAllCollectionData", {tokens: data})                
           let arrangedData = arrangeAssetData(data?.tokens)
           let tokenFetchAsset = arrangedData?.fetchAssets?.map(item => { return {...item, asset: `${item?.policy_id}${item?.asset}`}})
           arrangedData = {...arrangedData, fetchAssets: tokenFetchAsset}
           sliceTokenAPI(arrangedData?.fetchAssets)
            dispatch("updateTokenRawData",arrangedData?.groups)
            dispatch("updateTokenAssetCount", arrangedData?.assetCount)
        }
        else {
            dispatch("setTokenCollection", { loading: false, data: [], count: 0 })
            dispatch("updateTokenLoadingStatus", 'finished')
            dispatch("updateCollectionLoadingStatus", 'finished')
        }
        
        dispatch("updateCollectionAssetCount", data?.totalAssetsCount ?? 0)
        dispatch("updateArtBankAssetCount", data?.totalArtBankAssetsCount ?? 0)
        dispatch("reFetchTokenDone");
    }
    const getTokenAssetIdsListResult = useCallback((res) => {
        try {
            const { data } = res;
            getConnectedWallets(data)
        } catch (error) {
            console.log(error)
        }
        // setTimeout(()=>{
        // dispatch("updateTokenLoadingStatus", 'finished')
        // dispatch("updateCollectionLoadingStatus", 'finished')
        // },[3000])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
    const getTokenAssetIdsList = useCallback(() => {
        const token = storage().get('token');
        if(token) {
            dispatch("updateCollectionLoadingStatus", 'in-progress')
            dispatch("setTokenCollection", { loading: true, data: [], count: 0 })
            triggerAPI({
                url: 'user/wallets/assets/get', method: 'get'
            }, getTokenAssetIdsListResult, (er) => {
                console.log("ERROR ",er)
                dispatch("updateTokenLoadingStatus", 'finished')
                dispatch("updateCollectionLoadingStatus", 'finished')
                dispatch("reFetchTokenDone");
            })
        }
    }, [dispatch, getTokenAssetIdsListResult, triggerAPI])

    const profileNavHandler = () => {
        navigate("profile/user/my-galleries")
    }
    const homeNavHandler = () => {
        navigate("home")
    }
    
    const dropDownHandlerClose = () => {
        setDropDown(false)
    }
    const selectHandler = selected => {
        reduxDispatch(setShowWalletPop(false))
        switch (selected) {
            case "Profile":
                profileNavHandler()
                break
            case "Register":
                setShowRegisterPop(true)
                break
            case "Creators":
                if(!isCommunity)
                    navigate("/community")
                break
            case "Logout":
                setWalletAuthPop(showWalletAuthPop)
                setLogOutWarning(true)
                break
            case "Login":
                handleB2CLogin();
                break;
            default:
        }
    }

    //Logout Warning Closer
    const logOutWarnCloseHandler = (params) => {
        if(walletAuthPop) reduxDispatch(setShowWalletPop(true))
        setLogOutWarning(false)
    }


    //Logout Api
    const logoutApiResponse = useCallback((res) => {
        dispatch('hideSpinner');
        logOutWarnCloseHandler()
            logout()
        setTimeout(()=>{
            reduxDispatch(setLoggingOutStatus(false))
        },200)
    }, [dispatch, reduxDispatch,logout])
    const logoutHandler = () => {
        reduxDispatch(setLoggingOutStatus(true))
        dispatchRedux(setShowWalletPop(false));
        dispatch('showSpinner')
        triggerAPI({
            url: `user/logOut`, method: 'post'
        }, logoutApiResponse);
    }
    const getProfileResult = useCallback((res) => {
        setUserData(res?.data)
        setprofilePicUrl(res.data.profilePicUrl256 ?? cur8Icon)
        dispatch('updateUsername', res?.data?.email)
        dispatch('setUserId', res?.data?.id);
        dispatch('setGalleryCreateCount', res?.data?.availableGalleriesCount);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
    const getProfile = () => {
        triggerAPI({
            url: `user/profile/get`, method: 'get'
        }, getProfileResult);
    }
    const handleTrialClick = (e) => {
        dispatchRedux(setShowWalletPop(true))
    }

    const getCategories = () => { 
        triggerAPI({
            url: `category/get`, method: 'post',
            data:{
                "page": 1,
                "items": 100,
                "filter": {},
                "search": ""
              }
        }, ((response)=>{
            // setCategoriesOption(response.data.list);
            response.data.list.sort((a,b)=>a.name>b.name?1:-1);            
            for (let i = 0; i < response.data.list.length; i++) {
                const element = response.data.list[i];
                element.subcategories.sort((a,b)=>a.name>b.name?1:-1);
            }
            dispatch('setCategoryOptions' ,response.data.list)
        }));
    }
    return (
    <>
        {!isLoggedIn && 
            <>    
                <AuthPop show={showAuthPop} className={styles['home-auth-pop']} onTrialClick={handleTrialClick} onClose={setShowAuthPop} />
                <WalletSelectPop show={showWalletAuthPop} className={styles['home-wallet-pop']} />
            </>
        }              
        <ImagePopup show={{mode: showRegisterPop} } onClose={setShowRegisterPop} cssClass={styles['register-pop']}>
            <Register isGuestRegister={true} onClose={setShowRegisterPop} />
        </ImagePopup>  
        <div id="header-bar" className={`${styles['title-bar']} ${enableScrollMenu&&styles["scroll-menu-header"]} ${styles['nav-bar']} ${isLoggedIn ? '' : 'z-10'} ${leftMenuCollapse && styles['collpased']}`}>
           <div className={`${enableScrollMenu&&styles["scroll-menu-div"]} flex items-center h-full`}>
            <div className={`${styles['nav-wrapper']}`}>
                <div className={`${styles['logo-box']}`}>
                    <div className={`${styles['mob-head']} mr-3`}>
                        <div className={props?.className}>
                            <button
                                onClick={props.onHamburgerClick}
                            >
                                <img className='h-6 w-6' alt='NoImg' src={hamburgerIcon} />
                            </button>
                        </div>
                    </div>
                    <div className={`${styles['head-logo']}`}>
                        <img className={`${leftMenuCollapse && styles['collpased-logo']} ${!leftMenuCollapse && 'opacity-0'}`} onClick={homeNavHandler} alt="No Img" src={ miniLogo} />
                        <img className={`${!leftMenuCollapse && styles['expanded-logo']} ${leftMenuCollapse && 'opacity-0'}`} onClick={homeNavHandler} alt="No Img" src={ logo} />
                        {/* {!leftMenuCollapse && <div className={`${!leftMenuCollapse && styles['alpha-text']}`}>Alpha</div>} */}
                    </div>
                </div>
            {/* <div className={styles['search-bar-sm']}>
                {isLoggedIn && <div className={`${styles['header-wallet']} header-wallet pl-2`}>
                    <div className={`wallet-select`}>

                        <div className="cursor-pointer flex items-center justify-center">
                            <img src={WalletIcon} alt="wallet icon" />
                        </div>
                    </div>
                </div>}
            </div> */}
            {/* <div className={`${styles['search-bar-lg']} h-full flex items-center`}>
                <div className={`relative w-full flex items-center ${styles['search-bar-container']}`}>
                    <div className='relative flex items-center'>
                        <select className={`${styles['search-select']}`}>
                            <option>All</option>
                        </select>
                        <img alt="No Img" className={`${styles['select-arrow']}`} src={downArrow} />
                    </div>
                    <div className='relative w-full flex items-center'>
                        <input className={`${styles['search-input']} w-full`}></input>
                        <img alt="No Img" src={search} className={`${styles['search-icon']}`}></img>
                    </div>
                </div>
                {isLoggedIn && <div className='h-full  pl-2'>
                    <WalletSelector />
                </div>}

            </div> */}
            <div className={`${styles['web-filter']}`}>
                <TopHeader />
            </div>
            <div className={`${styles["title-btns"]} flex flex-row justify-content-center items-center gap-2.5 ml-auto mr-8 pr-[.6rem]`}>
                {/* <div className={`${styles['settings']} flex flex-row gap-2 justify-center items-center`}>
                    <img alt="No Img" className={`${styles['icons']}`} src={i}></img>
                    <img alt="No Img" className={`${styles['icons']}`} src={discordIcon}></img>
                    <img alt="No Img" className={`${styles['icons']}`} src={twitterIcon}></img>
                </div> */}
                <div className={`${styles['search-bar-lg']} h-full  pl-2`}>
                    <WalletSelector />
                </div>
                {/* Log in */}
                {!isLoggedIn && screenSize>550 &&
                    <>
                        <div className={styles['signIn-button']}>                    
                            <RoundedButton OnClick={handleB2CLogin} className={styles['register-btn']} Name="Login" Type={screenSize > 550 ? '13' : '7'}></RoundedButton>                                                        
                        </div>
                    </>
                }
                {/* Profile menu */}
                {(isLoggedIn) && screenSize>550 &&
                    // <div className={`${styles['user-avatar-box']}`}
                    //     onMouseOverCapture={onMouseFocus} onMouseLeave={onMouseBlur} 
                    // >
                   
                    // {<div className={`${styles["profile-box"]} ${!profilePicUrl ? styles["no-profile-box"] : ""} cursor-pointer`} onClick={dropDownHandler}>
                    //     <div className={`${styles['profile-pic']}`} style={{ backgroundImage: `url(${profilePicUrl ?? userAvatar})` }}>
                    //     </div>
                    // </div>}
                    <DropDown className={`${styles['head-dropdown']}`} data={userData} open={dropDown} onBlur={dropDownHandlerClose} onSelect={selectHandler} elements={isLoggedIn ? dropDownElements : dropDownElementsGuest} profilePicUrl={profilePicUrl} isLoggedIn={isLoggedIn} userAvatar={userAvatar} />
                }
                {logOutWarning &&
                    <LogOutWarn onLogOut={logoutHandler} onCancel={logOutWarnCloseHandler} />
                }
            </div>
            </div>
            </div>
            <div className={`${styles['mob-filter']}`}>
                <TopHeader />
            </div>
        </div >
        {/* <div id="shadow" className={`${styles['shadow-div']}`}></div> */}
    </>
)
}

export default Header