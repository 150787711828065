import styles from "./InformationCard.module.scss";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";

const InformationCard = (props) => {
    const navigate = useNavigate();

    const categoryDetailsHandler = (e) => {
        const id =props.categoryInfo.id
        navigate(`${id}`)
    }

    return  (
        <div className={`${styles['info-cat-wrp']}`}>
            <div className={`flex flex-col ${styles.info}`} key={props.categoryInfo.id}  onClick={categoryDetailsHandler}>
                <Fragment>
                    <div className={`flex justify-center items-center cursor-pointer ${styles['image-container']}`}
                        data-infoid={props?.categoryInfo?.id} 
                        onClick={categoryDetailsHandler}
                        >
                            <div className={`${styles['image-cls']}`}  style={{'backgroundImage': "url("+props?.categoryInfo.imageUrl+")" }}></div>
                    </div>
                    <div className={`pt-2 text-left flex flex-col ${styles['card-text']}`}>
                        <span  className={`${styles['info-title']}`}>{props?.categoryInfo?.name}</span>
                    </div>
                </Fragment>
            </div>
        </div>
    )
}

export default InformationCard