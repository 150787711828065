import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SelectedNftDetails from "../../../SubComponents/SelectedCollectionV2/Components/SelectedNftDetails";
import styles from "../../../../Styles/MyCollections.module.scss";
import stylesA from "../../../SubComponents/SelectedCollection/SelectedCollection.module.scss";
import NftTableDisplay from "../../../SubComponents/NftTableDisplay";
import NftCard from "../../../SubComponents/NftCard";
import SelectedNftCard from "../../../SubComponents/SelectedCollection/Components/SelectedNftCard";
import { useStore } from "App/hooks-store/store";
import SelectedNftTableDisplay from "../../../SubComponents/SelectedCollection/Components/SelectedNftTableDisplay";
import RoundedButton from "App/Components/Ui/RoundedButton";
import useAuth from "App/hooks/use-auth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useScreenSize from "App/hooks/use-screen-size";
import { sortObject } from "App/Helper/utilities";
import TopNftDetails from "../../../SubComponents/TopNftDetails";
import MyCollectionTileView from "App/Components/Charts/MyCollectionTileView/indexV2";
import GroupBy from "../../../SubComponents/GroupBy";

const MyCollections = React.memo((props) => {
  const navigate = useNavigate();
  const isLoggedIn = useAuth()[0];
  const isLoggedInGuest = useAuth()[4];
  const [searchParams] = useSearchParams();
  const [displayNft, setDisplayNft] = useState("tile");
  const [selectedNft, setSelectedNft] = useState(null);
  const [filteredList, setFilteredList] = useState([]);
  const [tableList, setTableList] = useState({ sort: "name", list: [] });
  const [nftCollections, setNftCollections] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [filterSm, setFilterSm] = useState(false);
  const [{  collections, collectionLoadingStatus, collectionRawData,
      activeMenuIcons, collectionAssetCount, tokenAssetCount, tokenRawData, tokenLoadingStatus, allCollectionData}, dispatch] = useStore();
  const { pathname } = useLocation()
  const [captionToggle, setCaptionToggle] = useState(false);
  const [frameitToggle, setFrameitToggle] = useState(true);
  const [assets, setAssets] = useState([]);
  const [reload, setReload] = useState({});
  const screenSize = useScreenSize()?.width;
  const select = useRef();
  const selectFilter = useRef();
  const [loadingStatus , setLoadingStatus] = useState("");
  const isToken = pathname.includes('my-tokens');
  const listRef = useRef();
  const [selectedCollectionList, setSelectedCollectionList] = useState()
  const [isGroupByActive, setIsGroupByActive] = useState(false);

  // const sortOptions = useMemo(() => {
  //   let temp = [
  //       { id: 6, value: "Recent Additions" },
  //       { id: 0, value: "Collection A-Z" },
  //       { id: 1, value: "Collection Z-A" },
  //       { id: 2, value: "Count H-L" },
  //       { id: 3, value: "Count L-H" },
  //       { id: 4, value: "Release Date L-F" },
  //       { id: 5, value: "Release Date F-L" },
  //     ]
  //     if(props?.isToken)
  //       temp.splice(3, 2);

  //   return temp;
  // }, [props?.isToken]);
  const newSortOptions = useMemo(() => {
    let temp = [
        { id: 'sort', value: '', isHeading: true, headingLabel: 'Sort' },
        { id: 1, value: "By Recency" },
        { id: 2, value: "By Name" },
        { id: 3, value: "By Price" },
        { id: 4, value: "By Collection Size" },
        { id: 'group', value: '', isHeading: true, headingLabel: 'Group By' },
        { id: 5, value: "No Grouping" },
        { id: 6, value: "By Category", isHide: props?.basePathType === "listen" },
        { id: 6, value: "By Sub-Category" },
        { id: 6, value: "By Community" },
      ]
    if (props?.isToken)
      temp = [{ id: 2, value: "Count H-L" },
      { id: 3, value: "Count L-H" },]
    return temp;
  }, [props?.isToken, props?.basePathType]);
  const tileWeightingOptions = useMemo(() => {
    let temp = [        
        { id: 0, value: "Number of Pieces" },
        { id: 1, value: "Total Floor Value" },
        { id: 2, value: "Recent Volume" },        
      ]      

    return temp;
  }, []);
    useEffect(() => {
        dispatch("setPage","collections")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])
  const collectionOptions = useMemo(()=>{
    let temp = [{
      id: 0,
      value: "My Collections",
      count: collectionAssetCount ?? 0,
      url:`/${props?.basePathType}/collections/my-collections`,
      showSubDrop: true,
    }];
    if(props?.displayMode !== 'tile')
      temp = [...temp, {
        id: 1,
        value: "My Tokens",
        url:`/${props?.basePathType}/collections/my-tokens`,
        count: tokenAssetCount ?? 0,
      }]
    return temp
  },[collectionAssetCount, tokenAssetCount,props?.basePathType, props?.displayMode]);
  
  const favourites = [
    { id: 0, value: "All Collections" },
    { id: 1, value: "Favourites" },
  ];
  const group = [
    { id: 0, value: "Collections" },
    { id: 1, value: "Assets" },
  ];
  useEffect(() => {
    if(!!(searchParams.get('walletid'))) {            
        props?.updateCollectionFilter(null, searchParams.get('walletid'))            
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[searchParams.get('walletid')])
  useEffect(() => {
    if(activeMenuIcons?.dropDown === 'My Tokens' || activeMenuIcons?.dropDown === 'My Collections') {
        props?.updateCollectionFilter(activeMenuIcons?.dropDown, null)
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.dropDown])

  useEffect(()=>{
    setTimeout(()=>{
      if(isToken) {
        setLoadingStatus(tokenLoadingStatus);
      } else {
        setLoadingStatus(collectionLoadingStatus);
      }
    },600)
  },[isToken, tokenLoadingStatus, collectionLoadingStatus])

  //top icons enable
  useEffect(() => {
      dispatch('enableTopIcons', { 
        lgView: true,
        smView: true,
        listView: !props?.isToken,
        chartView: !props?.isToken && (props?.tab!=="my-connected-wallet") && isLoggedIn,
        dropDown: !(props?.tab==="my-connected-wallet"),
        favourite: !(props?.tab==="my-connected-wallet"),
        walletSorting: true,
        search: true,
        filter: !props?.isToken,
        sorting: props?.displayMode !== 'list' && props?.displayMode !== 'tile',
        tileWeighting: props?.displayMode === 'tile',
        multiSelectFilter: true
      });
    dispatch("setSortingOptions", newSortOptions);
    dispatch("setTileWeightingOptions", tileWeightingOptions);
    dispatch('setActiveIcons', { hideCategoriesFilter: props?.displayMode === 'tile'});
    setDisplayNft(props?.isToken&&props?.displayMode==="list"?"lg":props?.displayMode);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } ,[newSortOptions,props?.tab,isLoggedIn,props?.displayMode, props?.isToken])

  useEffect(() => {
      dispatch("enableTopIcons", {
        filter: !props?.isToken && props?.basePathType !== "listen",
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.isToken,props?.displayMode,props?.basePathType,isLoggedIn])
  useEffect(() => {
      if(props?.basePathType === "listen"){
        setIsGroupByActive(false);
      }
      dispatch('setActiveIcons', { sort: newSortOptions[6]?.value ?? newSortOptions[0]?.value});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.basePathType, props?.isToken])

  // useEffect(()=>{
  // dispatch('setActiveIcons', { displayType:props?.basePathType==="curate"?"tile":"lg"});
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[props?.basePathType])
  
  useEffect(() => {
    dispatch('setActiveIcons', { sort: newSortOptions[6]?.value ?? newSortOptions[0]?.value});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch('setActiveIcons', {dropDown: searchParams.get('walletid')? searchParams.get('walletid') : collectionOptions?.find(item => item?.url === pathname)?.value});
    dispatch("setDropDownOptions", collectionOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pathname, searchParams.get('walletid'), collectionOptions])

  useEffect(() => {
    if(activeMenuIcons?.displayType&&activeMenuIcons?.displayType!=="all"){
        props?.setDisplayMode(activeMenuIcons?.displayType);
        if(activeMenuIcons?.displayType==="list"&&props?.isToken){
          dispatch('setActiveIcons', {displayType: "lg"});
      }
      if(displayNft === 'list'){
        dispatch('setActiveIcons', { sort: newSortOptions[6]?.value ?? newSortOptions[0]?.value});
      }
    }
    else {
      dispatch('setActiveIcons', {displayType: props?.isToken? 'lg' : props?.displayMode??'tile'});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [activeMenuIcons?.displayType, props?.isToken])

  useEffect(() => {
    dispatch('setTopMenuBarOptions', {showMenuIcons: true, showTopMenus: false})
    return () => {
      //to reset all icons
      dispatch('resetAll');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  useEffect(() => {
    let catList = [{ id: 0, value: "All Categories" }];
    for (let items of nftCollections) {
      let included = false;
      catList.forEach((cat) => {
        if (cat.value === items.type) included = true;
      });
      if (!!items.type && !catList.includes(items.type) && !included) {
        catList.push({
          id: nftCollections.indexOf(items) + 1,
          value: items.type,
        });
      }
    }
    filterItemsHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftCollections]);

  useEffect(() => {
    if (collections && typeof collections !== "string")
      arrangeCollectionsHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections , props.nftCollections]);

  useEffect(() => {
    sortHandler({ value: "Name A-Z" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nftCollections]);

  //Arrange Collections based on policyId
  const arrangeCollectionsHandler = () => {
    let policyList = [];
    let policy = {};
    props.nftCollections.forEach((item) => {
      if (!policy[item.policy]) {
        policy[item.policy] = 1;
      } else {
        policy[item.policy] += 1;
      }
    });
    props.nftCollections.forEach((item) => {
      if (policy[item.policy] && policy[item.policy] > 1) {
        const included = policyList.some((x) => x.policy === item.policy);
        if (!included) {
          const object = { ...item, quantity: policy[item.policy] };
          policyList.push(object);
        }
      } else {
        const object = { ...item, quantity: 1 };
        policyList.push(object);
      }
    });
    setNftCollections(policyList);
  };

  const [filter, setFilter] = useState({
    sort: newSortOptions[6]?.value ?? newSortOptions[0]?.value,
    favourites: favourites[0].value,
    group: group[0].value,
  });

  const nftSelectHandler = (e) => {
   if(!props?.isToken) setSelectedNft(e);
  };
  const closeDetailsHandler = () => {
    setSelectedNft(null);
  };
  useEffect(() => {
    filterItemsHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const filterItemsHandler = () => {
    const rawData = isToken ? tokenRawData : collectionRawData
    groupFilter();
    let newCollection = [];
    if (filter.group === group[0].value) {
      newCollection = nftCollections;
    } else {
      newCollection = collections;
    }
    if (typeof newCollection !== "string") {
      let list = newCollection?.filter((item) => {
        const isAssetData = rawData?.find(
          (x) => x.policy_id === item.policy
        );
        if (isAssetData) {
          item.quantity = isAssetData?.assetCount;
        }
        return favouriteFilter(item);
      });
      list = sortObject(list, filter.sort, [], allCollectionData?.collection);
      setTableList({ sort: filter?.sort, list: list });
      setFilteredList([...list]);
    }
  };
  const filterSetHandler = useCallback((e) => {
    if (e.group !== filter.group) setSelectedNft(null);
    setFilter((prevState) => {
      return { ...prevState, ...e };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  const sortTableHandler = (e) => {
    let list = tableList.list;
    sortObject(list, e, [], allCollectionData?.collection);
    setTableList({ sort: e, list: list });
  };
  const groupFilter = () => {
    // setSelectedNft(null)
    switch (filter.group) {
      case group[0].value:
        setTableList({ sort: "name", list: nftCollections });
        return true;
      case group[1].value:
        sortHandler({ value: "Name A-Z" });
        setTableList({ sort: "name", list: collections });
        break;
      default:
    }
  };
  const favouriteFilter = (item) => {
    return (
      filter.favourites === favourites[0].value || !!item.isCollectionFavourite
    );
  };
  const reverseList = () => {
    let list = tableList.list;
    list = list.map((item, idx) => list[list.length - 1 - idx]);
    setTableList({ sort: "", list: list });
  };

  const sortHandler = (e) => {
    let sorted;
    sorted = sortObject(collections, e.value, [], allCollectionData);
    if(sorted){
    setAssets([...sorted]);
    setTableList({ sort: e?.value, list: sorted });
    }
  };
  const onChangeSort = (e) => {
    filterItemsHandler();
  };

  const reloadHandler = (e) => {
    setReload({ asset: e });
    props.getFramesList();
  };

  useEffect(() => {
    if (displayNft === "list") {
      filterSetHandler({
        sort: newSortOptions[6]?.value ?? newSortOptions[0]?.value,
        favourites: favourites[0].value,
        group: filter?.group,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.group, displayNft,props?.isToken, isLoggedIn]);

  const onReturnClick = () => {
    filterSetHandler({
      sort: filter?.sort,
      favourites: filter?.favourites,
      group: group[0].value,
    });
  };

  useEffect(() => {
    if (screenSize < 551) {
      setDisplayNft("lg");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.group]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClickOutside = () => {
    setFilterSm(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        select.current &&
        !select?.current?.contains(event?.target) &&
        !selectFilter?.current?.contains(event.target)
      ) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);
  //Menu Options start
  useEffect(() => {
    if (activeMenuIcons?.folderOption) {
      const selectedValue = {
        group: activeMenuIcons?.folderOption,
      };
      filterSetHandler(selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.folderOption]);
  useEffect(() => {
    if (activeMenuIcons?.favourite) {
      filterSetHandler({ favourites: "Favourites" });
    } else {
      filterSetHandler({
        favourites: "All Collections",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.favourite]);

  const sortValueChangeHandler = (sortType) => {
    if(sortType === 'By Recency') {
      return "Recent Additions";
    }
    if(sortType === 'By Name') {
      return "Collection A-Z";
    }
    if(sortType === 'By Price') {
      return "Floor Price H-L";
    }
    if(sortType === 'By Collection Size') {
      return "Count H-L";
    }
    return sortType;
  }
  useEffect(() => {
    if (activeMenuIcons?.sort) {
      if(listRef.current)
        listRef.current.scroll({
          top: 0,
          behavior: 'smooth'
        });
      const sortValue = {
        sort: sortValueChangeHandler(activeMenuIcons?.sort),
        value: sortValueChangeHandler(activeMenuIcons?.sort),
      };
      const groupBySelected = activeMenuIcons?.sort === 'By Category' || activeMenuIcons?.sort === 'By Sub-Category' ||activeMenuIcons?.sort === 'By Community' 
      setIsGroupByActive(groupBySelected)
      if (filter?.group === group[1].value) {
        sortHandler({ ...sortValue });
      } else {
        filterSetHandler({ ...sortValue });
        onChangeSort();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.sort]);

  useEffect(() => {
    setFrameitToggle(!!activeMenuIcons?.frame);
  }, [activeMenuIcons?.frame]);

  useEffect(() => {
    setCaptionToggle(!!activeMenuIcons?.caption);
  }, [activeMenuIcons?.caption]);

  
  const navToWallet = () => {
    navigate("/profile/wallet-authentication");
  }
  const tileclick = useCallback((data) => {   
    let nft = collections.filter(c => c.asset === data.asset)?.[0];
    if (nft)
    {  
      setSelectedNft({nft}); 
    } 
  },[collections])
  const closeAssetHandler = () => {
    setSelectedNft(null)
  }
  useEffect(()=>{
    function searchByPolicy(nftCollections, policy) {
      return nftCollections.filter(item => item.policy === policy);
    }
    setSelectedCollectionList(searchByPolicy(props.nftCollections, selectedNft?.nft?.policy)) 
  },[selectedNft, props.nftCollections])
  const wrapInNftObject = (inputArray) => {
    if (inputArray){
    return {
      nft: inputArray,
    };
     }
  };
  function selectNextNft() {
    const currentPolicy = selectedNft.nft?.asset;
    const currentIndex = selectedCollectionList.findIndex(item => item.asset === currentPolicy);
    
    if (currentIndex !== -1) {
      const nextIndex = currentIndex < selectedCollectionList.length - 1 ? currentIndex + 1 : 0;
      const selectedAsset = wrapInNftObject(selectedCollectionList[nextIndex]);
      setSelectedNft(selectedAsset);
    } else {
      setSelectedNft(selectedCollectionList[0].nft);
    }
  }
  function selectPreviousNft() {
    const currentPolicy = selectedNft.nft?.asset;
    const currentIndex = selectedCollectionList.findIndex(item => item.asset === currentPolicy);
    if (currentIndex !== -1) {
      const previousIndex = currentIndex > 0 ? currentIndex - 1 : selectedCollectionList.length - 1;
      const selectedAsset = wrapInNftObject(selectedCollectionList[previousIndex])
      setSelectedNft(selectedAsset);
    } else {
      setSelectedNft(selectedCollectionList[0].nft);
    }
  }
  return (
    <div 
      className={`${styles["nft-collections"]} ${stylesA["nft-collections"]} my-colection `}
    >
       {selectedNft && (
            <Fragment>
              {displayNft === "tile" ?   
                  (<div className="gallery-overlay" onClick={closeAssetHandler}>
                  <div className={` slider-user2`}  onClick={(e) => e.stopPropagation()}>                     
                  <SelectedNftDetails 
                  // onReload={reloadHandler} 
                  onCloseDetails={closeAssetHandler} 
                  nft={selectedNft}                   
                  selectNextNft={selectNextNft}
                  selectPreviousNft={selectPreviousNft}  
                  details={props.nftCollections}
                  onChainRenders={false}
                  />
                  </div>
                  </div>
              ) : filter.group === group[0].value ? (
                <TopNftDetails
                  className={`${styles["nft-frame-viewer-collection"]}`}
                  collections={collections}
                  parent="nft-collection"
                  onCollectionSelect={props.onCollectionSelect}
                  onCloseDetails={closeDetailsHandler}
                  nft={selectedNft}
                  isList={displayNft === "list"}
                  
                />
              ) : filter.group === group[1].value ? (
                <SelectedNftDetails
                  onReload={reloadHandler}
                  onCloseDetails={closeDetailsHandler}
                  nft={selectedNft}
                />
              ) : (
                <></>
              )}
            </Fragment>
          )}
      {props.nftCollections?.length ? (
        <div ref={listRef}
          className={`${props?.walletHasNFTs && stylesA["guest-user-collections"]} ${styles["my-collections"]} ${
            stylesA["my-collections"]
          } 
          ${displayNft === "list" ? stylesA["my-colection-list"]  : stylesA["my-collections"]}
          ${displayNft === "xlg" ? "flex-1" : ""}`}
        >
          {!!props.message && !props.nftCollections && (
            <p className="mt-[12rem] w-full">{props.message}</p>
          )}
          <div 
            className={`${styles["nft-display-panel"]} ${styles[displayNft]} ${
              filter.group === group[1].value && screenSize < 551 ? "mb-8" : ""
            }`}
          >
            {(displayNft === "sm" || displayNft === "lg") && isGroupByActive && filteredList !== null &&
                filter.group === group[0].value &&
              <GroupBy
                listRef={listRef}
                nftList={filteredList}
                styles={styles}
                stylesA={stylesA}
                styleCondition={filter.group === group[0].value || screenSize > 551}
                collections={collections}
                framesList={props.framesList}
                selected={selectedNft?.nft.asset}
                onCollectionSelect={props.onCollectionSelect}
                noFavourite={props?.tab === "my-connected-wallet"}
                noClick={props?.isToken}
                onImgClick={nftSelectHandler}
                displayNft={displayNft}
                filterItemsHandler={filterItemsHandler}
              />}

            <div
              className={`${displayNft !== "list" && styles["panel-area"]}  ${
                displayNft === "lg"
                  ? filter.group === group[0].value || screenSize > 551
                    ? styles["nft-display-lg"]
                    : styles["nft-display-lg-asset"]
                  : styles["nft-display-lg"]
              } ${displayNft === "list" && styles["nft-display-list"]} ${
                displayNft === "lg" ? styles["lg-card"] : styles["sm-card"]
              }  grid gap-[0.45625rem]`}
            >
              {displayNft === "sm" &&
                filteredList !== null && !isGroupByActive &&
                filter.group === group[0].value &&
                filteredList.map((nft, index) => (
                  <div
                    className={`${styles["card-area"]} `}
                    key={`${nft.policy}${index}`}
                  >
                    <NftCard
                      showAddDiv={true}
                      addDivClass={styles["hover-overlay"]}
                      parent="nft-collection"
                      collections={collections}
                      framesList={props.framesList}
                      filteredList={true}
                      selected={!!(selectedNft?.nft.asset === nft.asset)}
                      index={index}
                      onCollectionSelect={props.onCollectionSelect}
                      nft={nft}
                      noFavourite={props?.tab==="my-connected-wallet"}
                      noClick={props?.isToken}
                      onImgClick={nftSelectHandler}
                      displayNft={displayNft}
                      filterItemsHandler={filterItemsHandler}
                    />
                  </div>
                ))}
              {displayNft === "lg" && !isGroupByActive &&
                filteredList !== null &&
                filter.group === group[0].value &&
                filteredList.map((nft, index) => (
                  <div
                    className={`${styles["card-area"]} `}
                    key={`${nft.policy}${index}`}
                  >
                    <NftCard
                      showAddDiv={true}
                      addDivClass={styles["hover-overlay"]}
                      parent="nft-collection"
                      collections={collections}
                      framesList={props.framesList}
                      filteredList={true}
                      selected={!!(selectedNft?.nft.asset === nft.asset)}
                      index={index}
                      noFavourite={props?.tab==="my-connected-wallet"}
                      onCollectionSelect={props.onCollectionSelect}
                      nft={nft}
                      noClick={props?.isToken}
                      onImgClick={nftSelectHandler}
                      displayNft={displayNft}
                      filterItemsHandler={filterItemsHandler}
                    />
                  </div>
                ))}
              {displayNft === "sm" &&
                filter.group === group[1].value &&
                assets.map((nft, index) => (
                  <div
                    className={`${styles["card-area"]} `}
                    key={`${nft.policy}${index}`}
                  >
                    <SelectedNftCard
                      reload={reload}
                      framesList={props.framesList}
                      filteredList={true}
                      selected={!!(selectedNft?.nft.asset === nft.asset)}
                      index={index}
                      framed={frameitToggle}
                      caption={captionToggle}
                      hideName={!captionToggle}
                      onCollectionSelect={props.onCollectionSelect}
                      nft={nft}
                      noClick={props?.isToken}
                      onImgClick={nftSelectHandler}
                      displayNft={displayNft}
                    />
                  </div>
                ))}
              {displayNft === "lg" &&
                filter.group === group[1].value &&
                assets.map((nft, index) => (
                  <div
                    className={`${styles["card-area"]} `}
                    key={`${nft.policy}${index}`}
                  >
                    <SelectedNftCard
                      reload={reload}
                      hideName={!captionToggle}
                      framesList={props.framesList}
                      filteredList={true}
                      selected={!!(selectedNft?.nft.asset === nft.asset)}
                      index={index}
                      framed={frameitToggle}
                      caption={captionToggle}
                      onCollectionSelect={props.onCollectionSelect}
                      nft={nft}
                      noClick={props?.isToken}
                      onImgClick={nftSelectHandler}
                      displayNft={displayNft}
                    />
                  </div>
                ))}
            </div>
            {displayNft === "list" && filter.group === group[0].value && (
              <NftTableDisplay
                displayNft={displayNft}
                disableSort={loadingStatus !== "finished"}
                onSort={sortTableHandler}
                onReverse={reverseList}
                noClick={props?.isToken}
                onImgClick={nftSelectHandler}
                framesList={props.framesList}
                nfts={tableList}
                onCollectionSelect={props.onCollectionSelect}
                selectedNft={selectedNft}
                listRef={listRef}
              />
            )}
            {displayNft === "list" && filter.group === group[1].value && (
              <SelectedNftTableDisplay
                displayNft={displayNft}
                framed={frameitToggle}
                onSort={sortTableHandler}
                onReverse={reverseList}
                noClick={props?.isToken}
                onImgClick={nftSelectHandler}
                framesList={props.framesList}
                nfts={tableList}
                selectedNft={selectedNft}
              />
            )}  
            {displayNft === "tile" && collectionLoadingStatus === "finished" && (
              <div className={`${styles["treemap"]} py-[1rem] pr-[5px]`}>
                <MyCollectionTileView collections={collections} filteredList={filteredList??collections} nftclick={tileclick} walletId={searchParams.get('walletid')} weighting={activeMenuIcons?.weighting} basePathType={props?.basePathType}/>                
              </div>
            )}          
          </div>
        </div>
      ) : (
        <div
          className={`flex flex-col justify-center items-center no-data ${
            filter?.favourites === favourites[1]?.value &&
            !filteredList?.length &&
            "hidden"
          }`}
        >
          {((isLoggedIn || isLoggedInGuest) && (!filteredList?.length || !props?.oldCollection?.length) && (!!loadingStatus && loadingStatus === 'finished'))
            ?
            <>
            <div className={`${styles["empty-page"]}`}>No collections to display.</div>
            
            {(props.walletListLength === 0) && <div className={`${styles["empty-page"]}`}>Please <span className="cursor-pointer" onClick={navToWallet}>link</span> your wallet(s).</div>}
            </>
            : ""}
            {/* {((isLoggedIn || isLoggedInGuest) && !filteredList?.length && loadingStatus === 'finished')
            ? <div className={`${styles["empty-page"]}`}>No collections found</div>
            : ""} */}
        </div>
      )}      
      {filter?.favourites === favourites[1]?.value && !filteredList?.length && (
        <div className="absolute flex justify-center items-center h-[20rem] left-0 right-0 top-[3rem] bottom-0 no-data">
          No favourites found!!
        </div>
      )}
      {filter.group === group[1].value && (
        <div className={`${styles["btn-mob"]} mb-3 px-4`}>
          <RoundedButton
            className={`${styles["round-btn"]}`}
            Name="Back"
            Type="8"
            OnClick={onReturnClick}
          ></RoundedButton>
        </div>
      )}      
    </div>
  );
});

export default MyCollections;
