import './PageListing.scss';
const PageListing = (props) => {
    const handleCardClick = (e) => {
        const id = parseInt(e.currentTarget?.dataset?.id);
        props?.onCardClick && props?.onCardClick(id);
    }
    return (
        <div className="home-grid list-container grid">
            {props?.list?.map((data, index) => {
                return (
                    <div className="card mb-2" key={data?.key ?? data?.id} data-id={data?.id} onClick={handleCardClick}>
                        <div className='flex  flex-col'>
                            <div className="card-image-container">
                                <div className="card-image" style={{backgroundImage:`url('${data?.bgImage}')`}}>
                                    <div className='screenshot h-full'>
                                        <img src={data?.assetImg} alt='' className='h-full' />
                                    </div>
                                </div>
                            </div>
                            <div className="card-detail-container flex items-start justify-start">
                                <div className='card-details'>
                                    <div className='detail-profile'>
                                        <img src={data?.profilIcon} alt='img' />
                                    </div>
                                    <div className={`detail-name text-left flex ${data?.categories?'flex-col gap-1 items-start justify-start has-categories':''}`}>
                                        <div className='detail-name text-left pt-2'>
                                            <span>{data?.name}</span>
                                            <div className=''></div>
                                        </div>

                                        {data?.categories && <div className='flex categories'>
                                            {data?.categories.map((category,index) => {
                                            return index< 2 && <div className='detail-name' key={index}>
                                                <span>{category?.category}</span>
                                                <span> &bull;</span>
                                                <span>{category?.count}</span>
                                            </div>})}
                                        </div>}                                        
                                    </div>
                                </div>
                                {props?.type === 'collector' && 
                                <div className='card-details'>
                                    <div className='detail-name text-left'>
                                        <span>Rank 2</span>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                )
            })}            
        </div>
    )
}

export default PageListing;