import useResizeObserver from "@react-hook/resize-observer";
import { deepCopy, getMetadata, getSplice } from "App/Helper/utilities";
import useFrames from "App/hooks/use-frames";
import useHttp from "App/hooks/use-http";
import { useEffect, useState, useRef, useCallback, useMemo, memo } from "react";
import GallerySlider from "./GallerySlider";
import GalleryCaption from "./GalleryCaption";
import MetaGallery from "App/Pages/MetaGallery";
import GalleryControlPanel from "./GalleryControlPanel";
import { setwaittime, setispaused, setnextimage } from '../../MetaGallery/Component/MetaGalleryState';
import { useDispatch } from "react-redux";
import { toMilliseconds } from "App/Helper/getTime";
import useLayoutData from "App/hooks/use-layout";
import { updateCaptionControls, updateFrameControls } from "App/ReduxStore/Slicers/galleryPreviewSlice";
import "./GalleryPreviewPlugin.scss";
import MultiLoader from "App/Pages/NftGallery/Component/MyGallery/Components/AddedNfts/Components/GalleryPreviewImage/Components/MultiLoader";
let timer;
const GalleryPreviewPlugin = ({galleryData, galleryType = 'mono',noEnlargeView, initalTimes = null, multiLayout = null, multiBgData = null, settingsData = null, 
    screenData = null, isAppV1 = false, galleryInformation = null, className = '', handleGalleryView = null, getControlPanelVisibility = null}) => {
    const dispatch = useDispatch();
    var timerChanged = 0;
    const { getLayoutFromStore } = useLayoutData();
    const { getFrameDetails } = useFrames();
    const galleryPreviewRef = useRef(null);
    const gallerySliderRef = useRef(null);
    const isMono = galleryType === 'mono' || !galleryType;
    const isMulti = galleryType === 'multi';
    const isMeta = galleryType === 'meta';
    const [screenDimension, setScreenDimension] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [controlPanelVisibility, setControlPanelVisibility] = useState(false);
    const [toggleControls, setToggleControls] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [galleryInfo, setgalleryInfo] = useState(null);
    const metWallList = useMemo(() => {
        return [
            {
                id: 1,
                imageUrl256: "/metagalleryassets/images/meta1256.jpg",
                isFavourite: false,
                name: "Small Gallery",
            }, {
                id: 2,
                imageUrl256: "/metagalleryassets/images/meta2256.jpg",
                isFavourite: false,
                name: "Large Gallery",
            }, {
                id: 3,
                imageUrl256: "/metagalleryassets/images/meta3256.jpg",
                isFavourite: false,
                name: "Cocktail Bar",
            }
        ]
    },[])    
    const { triggerAPI } = useHttp();
    const [galleryList, setGalleryList] = useState([]);
    const [filteredGalleryList, setFilteredGalleryList] = useState([]);
    const [multiGalleryList, setMultiGalleryList] = useState([]);
    const [multiWallList, setMultiWallList] = useState([]);
    const [multiColorList, setMultiColorList] = useState([]);
    const [multiStyleList, setMultiStyleList] = useState([]);
    const [multiLayoutList, setMultiLayoutList] = useState([])
    const [bgWallFilter, setBgWallFilter] = useState({ color: '', style: '' });
    const [selectedMultiLayout, setSelectedMultiLayout] = useState({layout: null, size: '1.15'});
    const [selectedMultiBg, setSelectedMultiBg] = useState(isMeta ? metWallList[0] : null);
    const [screenControls, setScreenControls] = useState({
        styleType: 'ful-scr',
        frameType: true,
        imageLayout: { size: '2.5', bgColor: '#000'},
        imageBorder: { thickness: 0, bgColor_1: '#fff', bgColor_2: '#fff' }
    });
    const [settingControls, setSettingControls] = useState({
        animation: 'fade',
        captionCard: {
            postition: 'right', 
            time: 0,
            captionCard: false,
            disableControl: false
        },
        enhancedContent: { 
            show: false,
            onChainRenders: false,
            clickCount: 0
        }
    });
    const initialTime = useMemo(() => {return { hr: '0', min: '0', sec: '10' }}, [])
    const [currentTimer, setCurrentTimer] = useState({ hr: '0', min: '0', sec: '10' })
    const [slidePlaying, setSlidePlaying] = useState(false);
    const [currentPlayingAsset, setcurrentPlayingAsset] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [musicPlayerList, setMusicPlayerList] = useState([]);
    //end of state declaration
    
    useEffect(() => {
        setToggleControls(false)
    }, [galleryType])
    
    useEffect(() => {
        if(galleryInformation) {
            setgalleryInfo(galleryInformation)
        }
    }, [galleryInformation])
    
    useEffect(() => {
        if(multiBgData && galleryType === 'multi') {
            setSelectedMultiBg(multiBgData)
        } else if (galleryType === 'meta') {
            setSelectedMultiBg(metWallList[0])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiBgData, galleryType])
    useEffect(() => {
        if(screenData) {
            setScreenControls(prev => { return {...prev, screenData}})
        }
    }, [screenData])
    useEffect(() => {
        if(settingsData) {
            setSettingControls(prev => { return {...prev, settingsData}})
        }
    }, [settingsData])
    useEffect(() => {
        if(multiLayout?.size) {
            setSelectedMultiLayout(prev => { return {...prev, size: multiLayout?.size}})
        }
    }, [multiLayout?.size])
    useEffect(() => {
        if(initalTimes) {
            setCurrentTimer(initalTimes)
            gallerySliderRef && gallerySliderRef?.current?.toggleSlidePlay(currentTimer);  //intialy start slide to play
            setSlidePlaying(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initalTimes])
    const updatePageData = useCallback((response) => {
        const data = deepCopy(response);
        setSelectedMultiLayout(prev => { return {...prev, layout: data?.Layout}})
        let galleryList = [], frameIds = [];
        (data?.assets || []).forEach(iteratingItem => {
            const item = {...iteratingItem};
            if (item.details) {
                item.details = {...item.details, image: item.details.nftcdnimage256};

                if (item.frame) {
                    const metaInfo = item.isLandscape ? item.frame.metaInfo?.landscape : item.frame.metaInfo?.portrait;
                    const image = item.frame.s3ImageUrl;
                    item.frame = { ...item.frame, metaInfo, image };
                } else {
                    item.frameId = null;
                }
                const onchainMetadata = getMetadata(item?.details);
                item.details = {...item?.details, onchain_metadata: onchainMetadata?.onchain_metadata || onchainMetadata?.metadata}
                if (onchainMetadata?.files?.length > 0) 
                    setSettingControls(prev =>  { 
                        return {...prev, enhancedContent: { ...prev.enhancedContent, show: true }}
                    })

                if (item.frameId && !frameIds.includes(item.frameId)) {
                    frameIds.push(item.frameId);
                }
                galleryList.push(item);
            }
        });
        if(frameIds?.length)
            getFrameDetails(frameIds, () => {
                setFilteredGalleryList(galleryList);
                setGalleryList(galleryList); //if frames avaiable in side gallery, this list will set after frames fetched
            });   
        else {
            setGalleryList(galleryList);
            setFilteredGalleryList(galleryList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryData, galleryType])

    useEffect(() => {
        if(galleryData)
            updatePageData({...galleryData})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryData, galleryType])
    //end of inital data update from parent

    const fetchMultiWallListResult = useCallback((res) => {
        const { data } = res;
        const tempWall = data?.map(item => { item.value = item.id; return item });
        setMultiWallList(tempWall);
    }, [])

    const fetchMultiWallList = useCallback(() => {
        const url = `background-thumbnail/user/get`;
        triggerAPI({
            url: url, data: { ...bgWallFilter }, method: "post"
        }, fetchMultiWallListResult);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMultiWallListResult, triggerAPI]);

    useEffect(() => {
        fetchMultiWallList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bgWallFilter])

    const fetchMultiColorListResult = useCallback((res) => {
        const { data } = res;
        let list;
        list = data.map((clr) => {
            return { name: clr.name, id: clr.id };
        });
        setMultiColorList(list);
    }, [])
    const fetchMultiColorList = useCallback(() => {
        triggerAPI({
                url: `frame-asset/color/get`,
                method: "get",
            },fetchMultiColorListResult);
    }, [fetchMultiColorListResult, triggerAPI])

    const fetchMultiStyleListResult = useCallback((res) => {
        const { data: list } = res;
        let result = list?.map((a) => {
            return { value: a.style, name: a.style };
        });
        const newObjec = { value: "", name: "all" };
        result.unshift(newObjec)
        setMultiStyleList(result);
    }, [])
    const fetchMultiStyleList = useCallback(() => {
        triggerAPI({
                url: `background-thumbnail/style/get`,
                method: "get",
            },fetchMultiStyleListResult);
    }, [fetchMultiStyleListResult, triggerAPI])

    const handleZoomIn = () => {
        if (document.body.requestFullscreen) {
            document.body.requestFullscreen();
        } else if (document.body.webkitRequestFullscreen) {
            document.body.webkitRequestFullscreen();
        } else if (document.body.msRequestFullscreen) {
            document.body.msRequestFullscreen();
        }
        setFullScreen(true);
        setSlidePlaying(false);
    }

    const exitHandler = () => {
        if (!(document.fullscreenElement || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement)) {
            setFullScreen(false);
            setSlidePlaying(false);
        }
    }
    useEffect(() => {
        fetchMultiStyleList();
        fetchMultiColorList();
        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
        document.addEventListener('MSFullscreenChange', exitHandler);
        return () => {
            document.removeEventListener('fullscreenchange', exitHandler);
            document.removeEventListener('webkitfullscreenchange', exitHandler);
            document.removeEventListener('mozfullscreenchange', exitHandler);
            document.removeEventListener('MSFullscreenChange', exitHandler);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    //end of api call for fetch lists
    useResizeObserver(galleryPreviewRef, (entry) => {   
        if(screenDimension?.width !== entry.contentRect.width || screenDimension?.height !== entry.contentRect.height) {
            setScreenDimension({ width: entry.contentRect.width, height: entry.contentRect.height});
        }   
    },[]); 
    useEffect(() => {
        getLayoutFromStore((res) => {
            setMultiLayoutList(res);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleMouseLeave = useCallback(() => {
        setControlPanelVisibility(false)
    },[]);

    useEffect(() => {
        if (galleryList?.length && isMulti) {
            let splice = selectedMultiLayout?.layout?.count ? selectedMultiLayout?.layout?.count : 8;
            const result = getSplice(galleryList, splice)
            setMultiGalleryList(result)
        }
    }, [galleryList, isMulti, selectedMultiLayout]);
    const timerOnchange = useCallback((e) => {
        if (e) {
            const { hr, min, sec } = e;
            setCurrentTimer({ hr, min, sec })
            let ms = toMilliseconds(hr, min, sec);
            if (isMeta) {
                dispatch(setwaittime(ms));
            } else {
                ++timerChanged;
                if(timerChanged > 3) { //first 3 times for inital setup
                // if(true) { //first 3 times for inital setup
                    gallerySliderRef && gallerySliderRef?.current?.toggleSlidePause(0);
                    setSlidePlaying(false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const toggleSlidePlay = useCallback(() => {
        if (isMeta) {
            dispatch(setispaused(false));
        } else {
            gallerySliderRef && gallerySliderRef?.current?.toggleSlidePlay(currentTimer);
        }
        setSlidePlaying(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTimer, isMeta]);
    const toggleSlidePause = useCallback(() => {
        if (isMeta) {
            dispatch(setispaused(true));
        } else {
            gallerySliderRef && gallerySliderRef?.current.toggleSlidePause(0);
        }
        setSlidePlaying(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isMeta]);
    const handleNextSlide = () => {
        if (isMeta) {
            dispatch(setnextimage(true));
            toggleSlidePlay();
        } else {
            gallerySliderRef && gallerySliderRef?.current.goToNextSlide();
            setSlidePlaying(false);
        }                
    }
    const handlePrevSlide = () => {
        gallerySliderRef && gallerySliderRef?.current.goToPrevSlide();
        setSlidePlaying(false);               
    }
    const slideGoTo = (slideIndex) => {
        gallerySliderRef?.current.goToSlide(slideIndex);
    }
    const captionUpdater = useCallback((currentTimer) => {

        let captionTiming = currentTimer ?? settingControls?.captionCard?.time;
        if (captionTiming) {
            if (captionTiming !== -1) {
                let timeInterval = parseFloat(captionTiming) * 60000;
                setSettingControls(prev => { return { ...prev, captionCard: {...prev?.captionCard, captionCard: true } } })
                const updateTimings = () => {
                    setSettingControls(prev => { return { ...prev, captionCard: {...prev?.captionCard, disableControl: true } } })
                    setTimeout(() => {
                        setSettingControls(prev => { return { ...prev, captionCard: {...prev?.captionCard, captionCard: false, disableControl: false } } })
                        //clear any existing timers, to ensure we don't duplicate them
                        clearInterval(timer);
                        timer = setInterval(() => {
                            setSettingControls(prev => { return { ...prev, captionCard: {...prev?.captionCard, captionCard: true } } })
                            clearInterval(timer);
                            updateTimings();
                        }, timeInterval);
                    }, 3000);
                }
                updateTimings();
            } else {
                setSettingControls(prev => { return { ...prev, captionCard: {...prev?.captionCard, captionCard: true } } })
                clearInterval(timer);
            }
        } else {
            setSettingControls(prev => { return { ...prev, captionCard: {...prev?.captionCard, captionCard: false } } })
            clearInterval(timer);
        }
    }, [settingControls?.captionCard])
    useEffect(() => {
        captionUpdater()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingControls?.captionCard?.time])
    useEffect(() => {
        //to use caption controls in caption card
        dispatch(updateCaptionControls(settingControls?.captionCard));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingControls?.captionCard?.captionCard, settingControls?.captionCard?.disableControl])
    useEffect(() => {
        //to use frame controls in mono
        dispatch(updateFrameControls(screenControls?.styleType === 'custom' ? screenControls?.frameType : (screenControls?.styleType === 'ful-scr' ? false : true)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenControls?.frameType, screenControls?.styleType, galleryType])
    useEffect(() => {
        setShowLoader(true);
        setTimeout(() => {
            setShowLoader(false);
        }, 2000);
    }, [screenControls?.imageBorder?.thickness])
    const styles = useMemo(() => {
        let style = {'--image-size-padding': '0%', '--multi-slide-transform': `scale(1)`}
        if(isMulti) {
            if(!!selectedMultiLayout?.size) 
                style = {...style, '--multi-slide-transform': `scale(${selectedMultiLayout?.size})`}
        }
        if(isMono) {
            if(screenControls?.styleType === 'custom') {
                const basePadding = 5;
                let newpadding = basePadding - Number(screenControls?.imageLayout?.size ?? 0)
                style = {...style, 
                            background: `${screenControls?.imageLayout?.bgColor}`, 
                            'borderWidth': `${screenControls?.imageBorder?.thickness}rem`,
                            'borderImage': `linear-gradient(to right, ${screenControls?.imageBorder?.bgColor_1}, ${screenControls?.imageBorder?.bgColor_2}) 1`,
                            '--image-size-padding': `${newpadding}%` ?? '0%',
                        }
            } else if(screenControls?.styleType === 'ful-scr') { 
                style = {...style, 
                    '--mono-slide-transform': 'scale(2.5)'
                }
            } else {
                style = {...style, 
                    background: '#000'
                }
            }
        }
        
        return style
    }, [isMulti, isMono, screenControls?.imageLayout?.bgColor, screenControls?.styleType, 
        screenControls?.imageBorder, screenControls?.imageLayout?.size, selectedMultiLayout?.size]);

    useEffect(() => {
        //updating control visibility to parent
        getControlPanelVisibility && getControlPanelVisibility(controlPanelVisibility)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlPanelVisibility])

    useEffect(() => {
        let tempMusicPlayerList = [];
        galleryList?.forEach((element, index) => {
            if(!!element?.details?.onchain_metadata?.files) {
                let tempFiles = element?.details?.onchain_metadata?.files;
                let audioFiles = tempFiles?.filter(item => item.mediaType.includes("audio"))
                if(!!audioFiles?.length) {
                    tempMusicPlayerList = [...tempMusicPlayerList, {...element?.details, files: audioFiles}]
                }
            }
        });
        setMusicPlayerList(tempMusicPlayerList);
    }, [galleryList])
    return (
        <div ref={galleryPreviewRef}
            onMouseLeave={handleMouseLeave} className={`${className} h-full`} style={{"--gallery-width": screenDimension.width + 'px', "--gallery-height": screenDimension.height + 'px'}}>
            <div className={`modern-gallery-preview-container inview fram-bg ${isMulti && 'multi-view'}
                ${ isMono && `mono-view ${screenControls?.styleType}`}`}
                style={styles}>
                {galleryType === 'multi' ?
                    <>
                        <GallerySlider ref={gallerySliderRef}  sliderList={multiGalleryList} 
                            onChainControl={settingControls?.enhancedContent}
                            selectedLayout={selectedMultiLayout?.layout}
                            animation={'fade'} //always fade for multi slide
                            isMulti={true}
                            multiInnerClassName={''}
                            bgSrc={selectedMultiBg?.imageUrl}
                            setcurrentPlayingAsset={setcurrentPlayingAsset}
                        />
                        <GalleryCaption cardData={{ 
                            cardName: galleryInfo?.name,
                            userName: galleryInfo?.userName ?? '' 
                        }} />
                    </>
                    :
                    isMeta ?
                        <MetaGallery galleryList={galleryList} captionControl={settingControls?.captionCard} key={selectedMultiBg?.id} galleryId={selectedMultiBg?.id} oldGalleryId={null} />
                        :
                        <div className={`slider-container relative`}>
                            <MultiLoader className={`${showLoader ? 'show modern-gallery-mono-loader' : ''}`}/>
                            <GallerySlider ref={gallerySliderRef}  sliderList={filteredGalleryList} showLoader={showLoader}
                                isMulti={false} animation={settingControls?.animation} setcurrentPlayingAsset={setcurrentPlayingAsset}
                            />
                        </div>
                }
            </div>
            <GalleryControlPanel 
                isMeta={isMeta} isMulti={isMulti} isMono={isMono} galleryType={galleryType}
                galleryList={galleryList}
                setFilteredGalleryList={setFilteredGalleryList}
                timerOnchange={timerOnchange}
                initialTime={initialTime}
                toggleControls={toggleControls}
                setToggleControls={setToggleControls}
                handleNextSlide={handleNextSlide}
                handlePrevSlide={handlePrevSlide}
                toggleSlidePlay={toggleSlidePlay}
                toggleSlidePause={toggleSlidePause}
                slidePlaying={slidePlaying}
                currentPlayingAsset={currentPlayingAsset}
                slideGoTo={slideGoTo}
                setSettingControls={setSettingControls} settingControls={settingControls}
                setScreenControl={setScreenControls} screenControls={screenControls}
                selectedMultiLayout={selectedMultiLayout} setSelectedMultiLayout={setSelectedMultiLayout} multiLayoutList={multiLayoutList?.filter(item => item?.count <= galleryList?.length)}
                multiStyleList={multiStyleList} multiColorList={multiColorList} bgWallFilter={bgWallFilter} setBgWallFilter={setBgWallFilter} multiWallList={multiWallList}
                selectedMultiBg={selectedMultiBg} setSelectedMultiBg={setSelectedMultiBg} metWallList={metWallList}
                controlPanelVisibility={controlPanelVisibility} setControlPanelVisibility={setControlPanelVisibility} isAppV1={isAppV1}
                handleZoomIn={handleZoomIn} zoom={fullScreen || noEnlargeView}
                handleGalleryView={handleGalleryView}
            />
            
        </div>
    )
}

export default memo(GalleryPreviewPlugin);