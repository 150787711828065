import React, { useCallback, useEffect, useState } from 'react';
import './ProfileView.scss';
import DiscoverTopPanel from 'App/Pages/Discover/Components/SubComponents/DiscoverTopPanel';
import { useStore } from 'App/hooks-store/store';
import useHttp from 'App/hooks/use-http';
import { useNavigate, useParams } from 'react-router-dom';
import Border from 'App/Pages/Discover/Components/SubComponents/Border';
import GalleryTab from './Components/GalleryTab';
import storage from 'App/Helper/storage';
import useAuth from 'App/hooks/use-auth';
import DetailsTab from './Components/DetailsTab';
import DetailsSkeltonCard from 'App/Components/Ui/detailsSkeleton';

const ProfileView = (props) => {

    const { triggerAPI } = useHttp();
    const navigate = useNavigate();
    const { tab } = useParams();
    const isLoggedIn = useAuth()[0]
    const [{ currentWallet }, dispatch] = useStore(false);

    const [user, setUser] = useState(null);
    const [currentTab, setCurrentTab] = useState('')
    const [noGallery, setNoGallery] = useState(false)
    const [menuTabs, setMenuTabs] = useState([
        // { id: "overview", element: 'Overview', count: 0 }, 
        // { id: "collection", element: 'Collections', count: 0 }, 
        { id: "my-galleries", element: 'Galleries', count: 0 },
        // { id: "portfolio", element: 'Portfolio', count: 0 },
        { id: "details", element: 'Details', count: 0 },
        // { id: "posts", element: 'Posts', count: 0 }, 
        // { id: "events", element: 'Events', count: 0 }, 
        // { id: "about", element: 'About', count: 0 },
    ]);

    const handleCountUpdate = (id, count) => {
        const updatedTabs = menuTabs.map((item) => (item?.id === id) ? { ...item, count } : item)
        setMenuTabs(updatedTabs)
    }

    const getGalleryList = useCallback(() => {
        const token = storage().get('token');
        if (token) {
            let filter = {
                sortBy: "",
                orderBy: ""
            }
            if (!!(currentWallet?.address) || isLoggedIn) {
                triggerAPI({
                    url: `gallery/user/list/get`, data: {
                        page: 1,
                        items: 1000,
                        filter
                    }, method: 'post'
                }, (res) => {
                    const { pageMeta } = res.data;
                    handleCountUpdate('my-galleries', pageMeta?.totalItems)
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerAPI])

    //Get profile Api
    const getProfileResult = useCallback((res) => {
       // dispatch('hideSpinner');
        const profileData = { ...res?.data, name: res?.data?.username }
        setUser(profileData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
    const getProfile = useCallback(() => {
       // dispatch('showSpinner')
        triggerAPI({
            url: `user/profile/get`, method: 'get'
        }, getProfileResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, getProfileResult, triggerAPI])

    const clearTabValues = () => {
        dispatch('resetAll')
        dispatch("setTopMenuBarOptions", {
            showMenuIcons: false,
            showTopMenus: true,
        });
        dispatch("setMenuOptions", [
            {
                id: 1,
                element: "Profile",
                link: "user",
            },
            {
                id: 2,
                element: "Subscription",
                link: "subscription",
            },
            {
                id: 3,
                element: "Rewards",
                link: "rewards",
            },
            {
                id: 4,
                element: "Purchases",
                link: "purchase",
            },
            {
                id: 6,
                element: "Preferences",
                link: "preferences",
            },
            {
                id: 7,
                element: "Wallets",
                link: "wallet-authentication",
            },
        ]);
        dispatch("setActiveMenuOptions", 0);
    }

    const handleTabs = (e) => {
      const selectTab = e.currentTarget?.dataset?.id;
        setCurrentTab(selectTab);
        clearTabValues()
        navigate(`/profile/user/${selectTab}`)
    }

    const handleManageClick = () => navigate(`/curate/my-galleries`);

    useEffect(() => {
        getProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refetchData])
    useEffect(() => {
        getGalleryList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        setCurrentTab(tab);;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])

    return (
      <div className="profile_view_wrapper-container">
        {!user ? (
          <div className="relative mb-[3.6875rem]">
            <DetailsSkeltonCard
              show={true}
              height={"16.3125em"}
              count={1}
              inline={true}
              details={"banner-profile-img"}
              noAbsolute
              parentDiv={"banner-profile-skeleton"}
              containerClassName={`flex mt-0 banner-profile-skeleton-banner`}
            />
          </div>
        ) : (
          <DiscoverTopPanel
            className="flex"
            profileData={user}
            fromOther={true}
            learnMoreLink="details"
            loaderClass="mb-[3.6875rem]"
            editClick={props.onEditButtonClick}
            setSelectedTab={handleTabs}
          />
        )}
        <div className="tab-container">
          <div className="tab-list flex sm:gap-[1.42857rem] gap-[0.625rem]">
            {menuTabs?.map((menu) => {
              return (
                <div
                  onClick={handleTabs}
                  data-id={menu?.id}
                  key={menu?.id}
                  className={`tab-data ${menu?.id === currentTab ? "active":"not-active"}`}
                >
                  {menu?.element}{" "}
                  {!!(menu?.id === 2) ? (
                    <span className=" inline-block">{`(${parseInt(
                      menu?.count
                    )})`}</span>
                  ) : (
                    menu?.id === "my-galleries" && (
                      <span className=" inline-block">{`(${menu?.count})`}</span>
                    )
                  )}
                </div>
              );
            })}
          </div>
          <Border className={"right-panel-b-border w-auto"} />
        </div>
        <div className="profile_view-tabs">
          {currentTab === "my-galleries" && !noGallery ? (
            <GalleryTab
              onManageClick={handleManageClick}
              setNoGallery={setNoGallery}
              onCountUpdate={handleCountUpdate}
              onBackToGallery={clearTabValues}
            />
          ) : currentTab === "my-galleries" && noGallery ? (
            <div className="manage-btn-wrapper-box">
              <div className="manage_btn_inline-box">
                <button
                  onClick={handleManageClick}
                  className="text-[var(--cu-blue-60)]"
                >
                  Manage
                </button>{" "}
                Gallery
              </div>
            </div>
          ) : null}
          {currentTab === "details" && <DetailsTab user={user} />}
        </div>
      </div>
    );
}

export default ProfileView