import styles from "./UserProfile.module.scss";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation, NavLink, useParams } from "react-router-dom";
import { useStore } from "App/hooks-store/store";
import useScreenSize from "App/hooks/use-screen-size";
import Banner from "App/Components/Banner";
import { scrollToTop } from 'App/Helper/utilities'
export default function UserProfile() {
  const navigate = useNavigate();
  const { tab } = useParams();
  const { pathname } = useLocation();
  const screenSize = useScreenSize()?.width;
  // eslint-disable-next-line no-unused-vars
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [profileNavToggle, setProfileNavToggle] = useState(false);
  const [{ topActiveMenu, topMenuList }, dispatch] = useStore();
  const store = useStore()[0];
  const { pageTitle } = store;
  const navElements = [
    {
      id: 1,
      element: "Profile",
      link: "user",
    },
    {
      id: 2,
      element: "Subscription",
      link: "subscription",
    },
    {
      id: 3,
      element: "Rewards",
      link: "rewards",
    },
    {
      id: 4,
      element: "Purchases",
      link: "purchase",
    },
    // {
    //     id: 3,
    //     element: "Account",
    //     link: "account"
    // },
    // {
    //   id: 5,
    //   element: "Access",
    //   link: "access-plans",
    // },
    {
      id: 6,
      element: "Preferences",
      link: "preferences",
    },
    // {
    //     id: 6,
    //     element: "Creations",
    //     link: "my-verified-nft-policies"
    // },
    // {
    //     id: 6,
    //     element: "My NFT Calendar",
    //     link: "my-nft-calendar"

    // },
    {
      id: 7,
      element: "Wallets",
      link: "wallet-authentication",
    },
    // {
    //     id: 8,
    //     element: "NFT Residuals",
    //     link: "nft-residuals"

    // },
    // {
    //     id: 9,
    //     element: "Galleries I Rent Out",
    //     link: "galaries-rent-out"

    // },
    // {
    //     id: 10,
    //     element: "Galleries I Rent",
    //     link: "galaries-rent"

    // }
  ];

  //Menu options
  useEffect(() => {
    return () => {
      dispatch("resetAll");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!topMenuList?.length) {
      if (screenSize > 550) {
        dispatch("setTopMenuBarOptions", {
          showMenuIcons: false,
          showTopMenus: true,
        });
        dispatch("setMenuOptions", navElements);
        const index = navElements.findIndex((obj) =>
          pathname?.includes(obj.link)
        );
        dispatch("setActiveMenuOptions", index);
      }      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topMenuList]);

  // //Menu options
  useEffect(() => {
    const currentMenu = navElements[topActiveMenu];
    if (currentMenu && currentMenu?.element === 'Profile') {
      navigate(`${currentMenu?.link}/${tab ?? 'my-galleries'}`);
    } else if (currentMenu) {
      navigate(`${currentMenu?.link}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topActiveMenu]);
  const profileNavToggleHandler = () => {
    setProfileNavToggle(prevState => { return !prevState })
    if (screenSize < 551) {
        setTimeout(() => {
            scrollToTop();
        }, 1000);
    }

}
  return (
    <div
      className={`${styles["main-frame"]} ${pageTitle?.replace(/\s+/g, "-")} `}
    >
      {isPortrait&&screenSize < 551 && <div className={`${styles["baner"]} min-h-[1.43rem]`}>
        <Banner className="h-full  ">
            <div className='flex h-full '>
                <div className={`${styles["mobile-head"]} flex  justify-start items-center`}>
                    {
                        (navElements).map(elem => (<div key={elem.id}>
                            <NavLink className={({ isActive }) => (isActive ? styles['linkActive'] : '') + " w-full p-2.5 pb-0 nav-link"}
                                onClick={profileNavToggleHandler} to={elem.link}>
                                {elem.element}
                            </NavLink></div>))
                    }
                </div>
            </div>
        </Banner>
      </div>
      }
      <div
        className={`${styles["frame"]} ${!pathname?.includes('profile/user') && 'p-[1rem]'}  ${
          !isPortrait&& screenSize > 550 ? "page-shadow" : 'mt-[1.43rem]'
        }`}
      >
        <div className={styles.profile}>
          <div
            className={`${styles["sub-content"]} h-full flex gap-2.5 relative`}
          >
            <div
              className={`${styles.content} ${
                profileNavToggle && styles["content-display"]
              }`}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
