
import styles from "./Tabs.module.scss"
const Tabs = props => {
    const onTabSelect = e => {
        props.onSelect(e.target.dataset['tab'])
    }
    return <div className={`${styles["tabs"]} ${props?.bannerTab && props.selected && styles["select-tabs"]}  ${props.selected && styles["tab-selected"]}`}>
        <button className={`${props.className}`} data-tab={props.dataTab || props.children} onClick={onTabSelect}>{props.children}</button>
    </div>
}

export default Tabs