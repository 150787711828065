import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    captionControls: {},
    frameControls: {},
    aspectRatiosMono: {}
}

export const galleryPreviewSlice = createSlice({
  name: 'galleryPreview',
  initialState,
  reducers: {
    updateCaptionControls: (state, action) => {
        state.captionControls = action.payload
    },
    updateFrameControls: (state, action) => {
        state.frameControls = action.payload
    },
    updateAspectRationControls: (state, action) => {
        state.aspectRatiosMono = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateCaptionControls, updateFrameControls, updateAspectRationControls } = galleryPreviewSlice.actions
export const getCaptionControl = ({galleryPreview}) => galleryPreview?.captionControls;
export const getFrameControl = ({galleryPreview}) => galleryPreview?.frameControls;
export const getAspectRatioControl = ({galleryPreview}) => galleryPreview?.aspectRatiosMono;
export default galleryPreviewSlice.reducer