import React, { useEffect } from "react";
import "./InfiniteLoader.scss";
import SkeltonCard from "../Ui/SkeltonCard";
import DetailsSkeltonCard from "../Ui/detailsSkeleton";

const InfiniteLoader = ({
  length,
  children,
  loadData,
  hasMore,
  loader,
  loaderCount = 5,
  scrollOffset = 100,
  parentDiv="",
  containerStyle="",
  noAbsolute
}) => {
  const onScrollHandler = (e) => {
    if (
      hasMore &&
      e.target.scrollTop + e.target.clientHeight >
        e.target.scrollHeight - scrollOffset
    ) {
      loadData();
    }
  };
  useEffect(() => {
    if (loaderCount) {
      const root = document.documentElement;
      root?.style.setProperty("--skelton-count", `${loaderCount}`);
    }
  }, [loaderCount]);
  return (
    <div className={`infinite-scroll-component ${containerStyle}`} onScroll={onScrollHandler}>
      {children}
      {hasMore ? (
        loader === "skelton" ? (
          <SkeltonCard
            show={true}
            height={"100%"}
            count={loaderCount || 5}
            inline={true}
            noAbsolute={noAbsolute}
            containerClassName={`skelton-card flex body ${parentDiv}`}
          />
        ) : (
          loader === "details-skeleton" && (
            <DetailsSkeltonCard
              show={true}
              height={"100%"}
              count={loaderCount || 5}
              inline={true}
              details={"img-2-line"}
              noAbsolute
              containerClassName={`flex`}
              parentDiv={parentDiv}
            />
          )
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default InfiniteLoader;
