import { memo, useCallback, useEffect, useRef, useState } from "react"
import SelectedNftDetails from "./Components/SelectedNftDetails"
import styles from "./SelectedCollection.module.scss"
import SelectedNftTableDisplay from "./Components/SelectedNftTableDisplay"
import SelectedNftCard from "./Components/SelectedNftCard"
import { useStore } from "App/hooks-store/store"
import WalletSelectPop from "App/Components/Ui/WalletSelectPop"
import NftEnlarged from "../NftEnlarged"
import useScreenSize from "App/hooks/use-screen-size"
import TopNftDetails from "../TopNftDetails"
import useHttp from "App/hooks/use-http";
import AnnouncementsList from "App/Pages/CommunityCollection/Community/Components/Announcements/Components/AnnouncementLists"
import { EventsCard } from "App/Components/Ui/EventCard"
import DetailsTab from "./Components/SelectedNftDetails/Components/DetailsTab"
import useDiscoverApiHelper from "App/Pages/Discover/Components/API/apiHelper"
import { useDispatch, useSelector } from "react-redux"
import { getShowWalletPop, setShowWalletPop } from "App/ReduxStore/Slicers/authSlice"
import Chart from "App/Components/Chart"
import ListenList from "./Components/ListenList"

const SelectedCollectionV2 = props => {
    const listRef = useRef();
    const sortOptions = [
        { id: 1, value: "Name A-Z" },
        { id: 2, value: "Name Z-A" },
    ]
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    const screensize = useScreenSize()?.width;
    const [displayNft, setDisplayNft] = useState(props?.displayMode)
    const [selectedNft, setSelectedNft] = useState(null)
    const [captionToggle, setCaptionToggle] = useState(false)
    const [frameitToggle, setFrameitToggle] = useState(true)
    const [readBook, setReadBook] = useState(false)
    const [policyList, setPolicyList] = useState([])
    const [tableList, setTableList] = useState({ sort: 'name', list: [] })
    const [reload, setReload] = useState({})
    // eslint-disable-next-line no-unused-vars
    const [filterSm, setFilterSm] = useState(false)
    const select = useRef()
    const selectFilter = useRef()
    const [{ activeMenuIcons }, dispatch] = useStore()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [wrappedArray, setWrappedArray] = useState(null);
    const [activeTab, setActiveTab] = useState(props?.listen===true?"Listen":"Collection");
    const [communityUserDetails, setCommunityUserDetails] = useState();
    const [announcementData, setAnnouncementdata] = useState([]);
    const [eventsListAll, setEventsListAll] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const {
      getUseEventsDataAPI,
    } = useDiscoverApiHelper();
    const reduxDispatch = useDispatch();
    const showAuthPop = useSelector(getShowWalletPop);
    const paginationConfig = 20 
    const page = 1 
    const { triggerAPI } = useHttp();
    const filterpolicy = () => {
        let temp = []
        for (let items of props.nftCollections) {
            if (props.collection?.policy === items?.policy) {
                let tempObj = {...items}
                temp.push(tempObj)
            }                
        }
        setDataLoaded(true)        
        setPolicyList([...temp])
        return [...temp];
    }
    const handleTabChange = (tab) => {
        setActiveTab(tab);
      };
    useEffect(() => {
        filterpolicy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.nftCollections, props?.collection?.policy])
    
    useEffect(() => {
        setTableList({ sort: 'name', list: policyList })
    }, [policyList])
    const displayChangeHandler = e => {
        setDisplayNft(e)
    }

    const nftSelectHandler = e => {
        setReadBook(e.readBook);
        setSelectedNft(e)
    }
    const closeAssetHandler = () => {
        setReadBook(false);
        setSelectedNft(null)
    }
    const sortObject = (arr, sortVal) => {
        if (typeof arr === 'object' && arr.length) {
            switch (sortVal) {
                case ("Name A-Z"):
                    return arr.sort((a, b) => a["name"] ? b["name"] ? a["name"]?.toLowerCase() > b["name"]?.toLowerCase() ? 1 : -1 : -1 : 1);
                case ("Name Z-A"):
                    return arr.sort((a, b) => a["name"] ? b["name"] ? a["name"]?.toLowerCase() < b["name"]?.toLowerCase() ? 1 : -1 : -1 : 1);
                default:
                    return filterpolicy();
            }
        } else {
            return []
        }
    }
    const sortHandler = useCallback((e) => {
        let sorted
        sorted = sortObject(policyList, e.value)
        setPolicyList([...sorted])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[policyList]);
    const sortTableHandler = (e) => {
        let list = tableList.list
        sortObject(list, e)
        setTableList({ sort: e, list: list })
    }
    const reverseList = () => {
        let list = tableList.list
        list = list.map((item, idx) => list[list.length - 1 - idx])
        setTableList({ sort: "", list: list })
    }
    const reloadHandler = (e) => {
        setReload({ asset: e })
        props.getFramesList()
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onClickOutside = () => {
        setFilterSm(false)
    }
    const collectionSelectHandler = e => {
        // if(e.policy) {
        //     const filterCollction = collectionRawData.find(aData => aData.policy_id === e.policy)
        //     dispatch("updateCollectionLoadingStatus", 'in-progress');
        //     // sliceCollectionAPI(filterCollction?.data);
        // }
    }   
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (select.current && !select?.current?.contains(event?.target) && !selectFilter?.current?.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside])
  
    useEffect(() => {
      dispatch('setTopMenuBarOptions', {showMenuIcons: true, showTopMenus: false})
      dispatch('setSortingOptions', sortOptions);
      dispatch('enableTopIcons', 
        { lgView: !((isPortrait && screensize <= 550)) && (activeTab === "Collection" || activeTab === "Marketplace"),
          search: !((isPortrait && screensize <= 550)) && (activeTab === "Collection" || activeTab === "Marketplace"), 
          smView: !((isPortrait && screensize <= 550)) && (activeTab === "Collection" || activeTab === "Marketplace"),
          listView: !((isPortrait && screensize <= 550)) && (activeTab === "Collection" || activeTab === "Marketplace"), 
          back: true,  
          caption: (displayNft === 'sm' || displayNft === 'lg') && (activeTab === "Collection" || activeTab === "Marketplace"), 
          frame: (displayNft === 'sm' || displayNft === 'lg') && (activeTab === "Collection" || activeTab === "Marketplace"), 
          sorting: (displayNft === 'sm' || displayNft === 'lg') && (activeTab === "Collection" || activeTab === "Marketplace")
        });
        console.log('displayNft', displayNft);
        dispatch('setActiveIcons', { frame: true, displayType: displayNft, back: false});
     // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isPortrait, screensize, displayNft, activeTab])
    
    useEffect(() => {
      dispatch('setActiveIcons', { sorting: sortOptions[0]});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(()=> {
      return () => {
        dispatch('resetAll')
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    useEffect(()=> {
      return () => {
        dispatch('setActiveIcons', {displayType: displayNft});
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[displayNft])

    useEffect(()=> {
        setFrameitToggle(!!activeMenuIcons?.frame)       
    },[activeMenuIcons?.frame])

    useEffect(()=> {
        setCaptionToggle(!!activeMenuIcons?.caption)       
    },[activeMenuIcons?.caption])

    useEffect(()=> {
        if(activeMenuIcons?.sort && dataLoaded) {
          if(listRef.current)
          listRef.current.scroll({
            top: 0,
            behavior: 'smooth'
          });
            const sortValue =  { sort: activeMenuIcons?.sort, value: activeMenuIcons?.sort };
            sortHandler({...sortValue});
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeMenuIcons?.sort, dataLoaded])

    useEffect(() => {
        if(activeMenuIcons?.displayType)
            displayChangeHandler(activeMenuIcons?.displayType === 'tile' ? 'lg': activeMenuIcons?.displayType);
        else 
            dispatch('setActiveIcons', {displayType: props?.displayMode === 'tile' ? 'lg' : props?.displayMode});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons?.displayType])
    useEffect(() => {
        if(activeMenuIcons?.back) {
            props.onReturnClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons?.back]) 
    const filterNftCollections = (allNftCollections, selectedCollectionPolicy) => {
        let filteredNftCollections = [];
    
        for (let item of allNftCollections) {
            if (selectedCollectionPolicy === item?.policy) {
                filteredNftCollections=item;
            }
        }
    
        return filteredNftCollections;
    };
    const filterNftCollectionsByPolicy = () => {
        const selectedCollectionPolicy = props.collection?.policy;
        const allNftCollections = props.nftCollections;

        const filteredNftCollections = filterNftCollections(allNftCollections, selectedCollectionPolicy);

        return filteredNftCollections;
    };


   

    const wrapInNftObject = (inputArray) => {
        if (inputArray){
        return {
          nft: inputArray,
        };
         }
      };
      useEffect(() => {
        const selectedPolicy = filterNftCollectionsByPolicy();
        const wrappedArray = wrapInNftObject(selectedPolicy);
        setWrappedArray(wrappedArray);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []); 
      function selectNextNft() {
        const currentPolicy = selectedNft.nft?.asset;
        const currentIndex = policyList.findIndex(item => item.asset === currentPolicy);
        
        if (currentIndex !== -1) {
          const nextIndex = currentIndex < policyList.length - 1 ? currentIndex + 1 : 0;
          const selectedAsset = wrapInNftObject(policyList[nextIndex]);
          setSelectedNft(selectedAsset);
        } else {
          setSelectedNft(policyList[0].nft);
        }
      }
      function selectPreviousNft() {
        const currentPolicy = selectedNft.nft?.asset;
        const currentIndex = policyList.findIndex(item => item.asset === currentPolicy);
        if (currentIndex !== -1) {
          const previousIndex = currentIndex > 0 ? currentIndex - 1 : policyList.length - 1;
          const selectedAsset = wrapInNftObject(policyList[previousIndex])
          setSelectedNft(selectedAsset);
        } else {
          setSelectedNft(policyList[0].nft);
        }
      }
    
      const escPressed = () => {
        closeAssetHandler()
      };
      useEffect(() => {
        const keyDownHandler = event => {
    
          if (event.key === 'Escape') {
            event.preventDefault();
    
            escPressed();
          }
        };
    
        document.addEventListener('keydown', keyDownHandler);
    
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      
      useEffect(() => {
        getCollectionDetails(props?.collection?.policy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.collectionId]);
    const getCollectionDetails = (collectionId) => {
        const params = new URLSearchParams({
          collectionId
        });        
        triggerAPI(
          {
            url: `community/collection/get-one?${params}`,            
            method: "get",
          },
          (res) => {
            // dispatch("hideSpinner");
            setCommunityUserDetails(res?.data);
            getAnnouncementList(res?.data?.communityId);
            getEventstList(res?.data?.communityId);
          }
        );
      };
      const announcementListApiResult = useCallback(
        (res) => {
          const { list } = res.data;
          const updatedData = list?.map((val) => {
            return {
              ...val,
              createdAt: new Date(val?.createdAt).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
              }),
            };
          });
          setAnnouncementdata(updatedData);
        },
        []
      );
      const getAnnouncementList = useCallback(
        (id) => {
          // dispatch("showSpinner");
          triggerAPI(
            {
              url: "community/announcement/get",
              data: {
                page: page,
                items: paginationConfig,
                filter: {
                  communityId: id,
                },
                search: "",
              },
              method: "post",
            },
            announcementListApiResult
          );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, announcementListApiResult, triggerAPI, page]
      );
 
  const getEventstList = (id) => {
    const dataParam = {
      page: 1,
      items: 20,
      search: "",
      filter: { communityId: id },
    };
    getUseEventsDataAPI(dataParam, (res) => {
      const { upcomingEvents, pastEvents }  = res;
      const allEvents = [...upcomingEvents, ...pastEvents]
      setEventsListAll(allEvents)
      setUpcomingEvents(upcomingEvents);
      const filterPastEvents = pastEvents?.length && pastEvents?.length > 8 ? pastEvents?.slice(0, 8) : pastEvents
      setPastEvents(filterPastEvents)
    });
  };

  const setShowAuthPop=()=>{
    reduxDispatch(setShowWalletPop(true))
  }
    return <div className={`${styles["nft-collections"]} ${styles["no-gap"]} my-colection`}>
        {/* <AuthPop show={showAuthPop} onTrialClick={handleTrialClick} onClose={setShowAuthPop} /> */}
                <TopNftDetails
                  className={`${styles["nft-frame-viewer-collection"]}`}
                  nftCollections={props.nftCollections}
                  parent="nft-collection"
                  onCollectionSelect={collectionSelectHandler}
                  onCloseDetails={props.onReturnClick}
                  nft={wrappedArray}
                  setSelectedNft={setSelectedNft}
                  isList={displayNft === "list"}
                  policyList={policyList}
                  setSelectedTab={setActiveTab}
                  communityUserDetails = {communityUserDetails}
                  listen={props?.listen}
                />
        <div className="tab-container-details2 mobileTab mt-4 pb-2">
                <div className="tab-list ">
                <div className={`tab-data flex gap-[1.42857rem] mr-[1rem]`}>
                        {props?.listen===true && <div className={`tab ${activeTab === "Listen" ? "active" : "not-active"}`}onClick={() => handleTabChange("Listen")}>
                            Listen
                        </div>}
                         <div className={`tab ${activeTab === "Collection" ? "active" : "not-active"}`}onClick={() => handleTabChange("Collection")}>
                            Collection ({policyList.length})
                        </div>
                        <div className={`tab ${activeTab === "Marketplace" ? "active" : "not-active"}`}onClick={() => handleTabChange("Marketplace")}>
                            Marketplace
                        </div>
                     {wrappedArray?.nft?.community?.id && <>
                      <div className={`tab ${activeTab === "Events" ? "active" : "not-active"}`} onClick={() => handleTabChange("Events")}>
                             Events ({eventsListAll.length})
                        </div>
                      <div className={`tab ${activeTab === "Posts" ? "active" : "not-active"}`} onClick={() => handleTabChange("Posts")}>
                             Posts ({announcementData.length})
                        </div>
                        </>}
                        <div className={`tab ${activeTab === "Details" ? "active" : "not-active"}`} onClick={() => handleTabChange("Details")}>
                             Details
                        </div>
                        <div className={`tab ${activeTab === "Analytics" ? "active" : "not-active"}`} onClick={() => handleTabChange("Analytics")}>
                             Analytics
                        </div>
                    </div>
                </div>
            </div>
            {showAuthPop && <WalletSelectPop show={showAuthPop}  />}
        {activeTab === "Collection" && (
        <div ref={listRef} className={`${styles["my-collections"]} ${displayNft === "list" ? styles["my-colection-list"]  : styles["my-collections"]} ${styles[displayNft]} ${!selectedNft && styles['xlgView']}`}>
            <div className={`${styles["nft-display-panel"]} `}>
                <div className={`${styles["panel-area"]} ${displayNft === "lg" && styles["nft-display-lg"]} ${displayNft === "list" && styles["nft-display-list"]} ${(displayNft === "lg") ? styles["lg-card"] : (displayNft === "sm" ? styles["sm-card"] : '')}  grid
                 gap-[0.45625rem]`}>
                    {(displayNft === "sm" || displayNft === "lg") &&
                        policyList?.map((nft, index) => 
                        <div key={`${nft.asset}${index}`} className={`${styles["grid-card-level"]}`}><SelectedNftCard reload={reload} framesList={props.framesList} policy={props.collection?.policy} selected={!!(selectedNft?.nft.asset === nft.asset)} framed={frameitToggle} index={index} caption={captionToggle} hideName={!captionToggle} nft={nft} onImgClick={nftSelectHandler} displayNft={displayNft} size={displayNft === "sm"?256:512} read={props?.read}/></div>
                        )
                    }
                </div>
                {displayNft === "list" && <SelectedNftTableDisplay 
                framed={frameitToggle} 
                onSort={sortTableHandler} 
                onReverse={reverseList} 
                onImgClick={nftSelectHandler} 
                framesList={props.framesList} 
                nfts={tableList}
                
                />}
                {!policyList?.length&&<div className={`flex items-center justify-center no-data  ${styles["visibleSlowly"]}`}>No pieces to display.</div>}
         
                {displayNft === "xlg" && 
                <NftEnlarged onReload={reloadHandler} reload={reload} displayNft={displayNft} framed={frameitToggle} onImgClick={nftSelectHandler} framesList={props.framesList} nfts={policyList} />}
            </div>  

            {selectedNft &&   
            <div className="gallery-overlay" onClick={closeAssetHandler}>
            <div className={` slider-user2`}  onClick={(e) => e.stopPropagation()}>            
            <SelectedNftDetails 
            onReload={reloadHandler} 
            onCloseDetails={closeAssetHandler} 
            nft={selectedNft} 
            setShowAuthPop={setShowAuthPop}
            selectNextNft={selectNextNft}
            selectPreviousNft={selectPreviousNft}  
            details={props.nftCollections}
            onChainRenders={false}
            readBook={readBook}
            />
            </div>
            </div>
              }
        </div>
         )}
         {activeTab === "Listen" && <ListenList collection={policyList}/>}
         {activeTab === "Marketplace" &&  <div ref={listRef} className={`${styles["my-collections"]} ${displayNft === "list" ? styles["my-colection-list"]  : styles["my-collections"]} ${styles[displayNft]} ${!selectedNft && styles['xlgView']}`}>
            <div className={`${styles["nft-display-panel"]} `}>
                <div className={`${styles["panel-area"]} ${displayNft === "lg" && styles["nft-display-lg"]} ${displayNft === "list" && styles["nft-display-list"]} ${(displayNft === "lg") ? styles["lg-card"] : (displayNft === "sm" ? styles["sm-card"] : '')}  grid
                 gap-[0.45625rem]`}>
                    {(displayNft === "sm") &&
                        policyList.map((nft, index) => <div key={`${nft.asset}${index}`} className={`${styles["grid-card-level"]}`}><SelectedNftCard reload={reload} framesList={props.framesList} policy={props.collection?.policy} selected={!!(selectedNft?.nft.asset === nft.asset)} framed={frameitToggle} index={index} caption={captionToggle} hideName={!captionToggle} nft={nft} onImgClick={nftSelectHandler} displayNft={displayNft} size={256}/></div>
                        )
                    }
                    {(displayNft === "lg") &&
                        policyList.map((nft, index) => <div key={`${nft.asset}${index}`} className={`${styles["grid-card-level"]}`}><SelectedNftCard reload={reload} framesList={props.framesList} policy={props.collection?.policy} selected={!!(selectedNft?.nft.asset === nft.asset)} framed={frameitToggle} index={index} caption={captionToggle} hideName={!captionToggle} nft={nft} onImgClick={nftSelectHandler} displayNft={displayNft} size={512}/></div>
                        )
                    }
                </div>
                {displayNft === "list" && <SelectedNftTableDisplay 
                framed={frameitToggle} 
                onSort={sortTableHandler} 
                onReverse={reverseList} 
                onImgClick={nftSelectHandler} 
                framesList={props.framesList} 
                nfts={tableList}
                />}
                {!policyList?.length&&<div className="flex items-center justify-center no-data">No pieces to display.</div>}
         
                {displayNft === "xlg" && 
                <NftEnlarged onReload={reloadHandler} reload={reload} displayNft={displayNft} framed={frameitToggle} onImgClick={nftSelectHandler} framesList={props.framesList} nfts={policyList} />}
            </div>  

            {selectedNft &&   
            <div className="gallery-overlay" onClick={closeAssetHandler}>
            <div className={` slider-user2`}  onClick={(e) => e.stopPropagation()}>            
            <SelectedNftDetails 
            onReload={reloadHandler} 
            onCloseDetails={closeAssetHandler} 
            nft={selectedNft} 
            setShowAuthPop={setShowAuthPop}
            selectNextNft={selectNextNft}
            selectPreviousNft={selectPreviousNft}  
            details={props.nftCollections}
            onChainRenders={false}
            />
            </div>
            </div>
              }
        </div>}

         {activeTab === "Details" && (
             <DetailsTab
             communityUserDetails={communityUserDetails}
             collection={props?.collection}
             details={props.nftCollections}
             array={wrappedArray}
             className={'pt-2'}
             />
         )}
          {activeTab === "Posts" && ( 
            <div className="overflow-auto pr-[1.25rem] pt-[0.4rem]">
                <AnnouncementsList
               
                data={announcementData}
                normalAccordion={true}
                formatDates={true}
                />
                </div>
          )}
          {activeTab === "Events" && ( 
            <div className="overflow-auto pt-[0.4rem]">
          <div className=" text-left pl-1 ">
          <p>Upcoming Events <span className="text-[#606573]">({ upcomingEvents?.length})</span></p>
        </div>
      {upcomingEvents?.length ? <div className="event-grid py-4 pr-[1.25rem] gap-2">
        {upcomingEvents?.map((event, index)=>{
          return (
            
          <EventsCard
          data={event}
          fromCommunity={true} 
          key={index}
            
          />

        )}
        )
        }
      </div>
      :
      <div className="h-[17rem] flex flex-col justify-center">
      <p className="text-[#606573]">No upcoming events</p>
      </div>
      }
      <div className=" text-left pl-1 pt-2">
          <p>Past Events <span className="text-[#606573]">({ pastEvents?.length})</span></p>
        </div>
      {pastEvents?.length ?  
      <div className="event-grid py-4 pr-[1.25rem] gap-2">
        {pastEvents?.map((event, index)=>{
          return (
          <EventsCard
            data={event}
            fromCommunity={true} 
            key={index}
          />

        )}
        )
        }
      </div>
    :
    <div className="h-[17rem] flex flex-col justify-center">
    <p className="text-[#606573]">No past events</p>
    </div>}
          </div>
          )}
          {activeTab === "Analytics" && 
            <div className={`${styles["my-collections"]} pr-[1rem]`}>
            <Chart collectionPrimaryKey={wrappedArray.nft.collectionId} type="collection/price_chart" title="Floor Price / Volume"/>
          </div>}
    </div>

}

export default memo(SelectedCollectionV2)