/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "../../CategoryCollections.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "App/hooks-store/store";
import CollectionCard from "App/Components/CollectionCard";
import useDiscoverApiHelper from "App/Pages/Discover/Components/API/apiHelper";
import useHttp from "App/hooks/use-http";
import InfiniteLoader from "App/Components/InfiniteLoader";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
const paginationConfig = {
  perPage: 25,
};
const SubcategoryCollection = () => {
  const { category, subcategory } = useParams();
  const [collectionListOriginal, setCollectionListOriginal] = useState(null);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState(null);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(null);
  const [{ activeMenuIcons }, dispatch] = useStore();
  const { getDiscoverCollection, getCategoryCollection } =
    useDiscoverApiHelper();
  const targetRef = useRef(null);
  const navigate = useNavigate();
  const timeOut = useRef(null);
  const [subCategoryItem, setSubCategoryItem] = useState({});
  const { triggerAPI } = useHttp();

  const getCategoryList = useCallback(() => {
    const dataParam = {
      search: "",
      id: category,
    };
    getCategoryCollection(dataParam, (res) => {
      const { data } = res;
      data.forEach((el) => {
        if (el?.slug === subcategory) {
          setSubCategoryItem(() => el);
        }
      });
    });
  }, [dispatch,subcategory, triggerAPI]);

  useEffect(() => {
    if (subcategory) getCategoryList();
  }, [category, subcategory]);

  useEffect(() => {
    dispatch("setTopMenuBarOptions", { showMenuIcons: true });
    dispatch("enableTopIcons", {
      filter: true,
      search: true,
      sorting: false,
      lgView: false,
      xlgView: false,
      smView: false,
      listView: false,
      dropDown: false,
      favourite: false,
      filterSort: false,
    });
    dispatch("setFilterContent", { category: false });
    dispatch("setActiveIcons", { search: "" });
    return () => {
      dispatch("resetAll");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCollectionList = useCallback(
    (pageNo) => {
      const dataParam = {
        categoryIds: category,
        firstSubcategoryIds:
          subcategory !== "newest-collections" ? subcategory : "",
        queryData: {
          page: pageNo ?? page,
          items: paginationConfig?.perPage,
          search: searchValue ?? "",
          collectionEditionsIds: activeMenuIcons?.filter?.editionIds ?? [],
          collectionStorageIds: activeMenuIcons?.filter?.storageIds ?? [],
          termsIds: activeMenuIcons?.filter?.termsIds ?? [],
          collectionFormatIds: activeMenuIcons?.filter?.formatIds ?? [],
          collectionTagIds: activeMenuIcons?.filter?.tagIds ?? [],
          collectionTypeIds: activeMenuIcons?.filter?.typeIds ?? [],
          shapes: activeMenuIcons?.filter?.shapes
            ? activeMenuIcons?.filter?.shapes?.map((el) => `"${el}"`)
            : [],
        },
      };
      setLoading(true);
      getDiscoverCollection(dataParam, (res) => {
        const { list, pageMeta } = res;
        setCollectionListOriginal((prev) =>
          pageNo ===1 ? [...list] : [...prev, ...list]
        );
        setTotalPage(pageMeta.totalItems);
        setLoading(false);
      });
    },
    [ searchValue, activeMenuIcons?.filter]
  );

  const updateHeight = () => {
    const root = document.documentElement;
    if (targetRef.current) {
      if (targetRef.current) {
        root?.style.setProperty(
          "--collection-card-height",
          `${targetRef.current.offsetWidth}px`
        );
      }
    }
  };
  useLayoutEffect(() => {
    updateHeight();
    window.addEventListener(
      "resize",
      () => {
        setTimeout(() => {
          updateHeight();
        }, 1000);
      },
      false
    );
  }, []);
  useEffect(() => {
    updateHeight();
  }, [collectionListOriginal]);
  const cardClick = (e) => {
    const id = parseInt(e?.currentTarget?.dataset?.id);
    const policy = e?.currentTarget?.dataset?.policy;
    let data;
    collectionListOriginal?.forEach((collect) => {
      if (collect?.id === id) data = collect;
    });
    let url="discover/collection/details"
    if(data?.community?.slug) url=`discover/communities/${data?.community?.slug}/collection`
    navigate(`/${url}/${policy}`)
  };

  useEffect(() => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }

    timeOut.current = setTimeout(() => {
      setSearchValue(activeMenuIcons?.search);
      setPage(1);
    }, 600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.search]);

  useEffect(() => {
    setLoading(true)
    if (Object.keys(activeMenuIcons)?.length&&searchValue!==null) {
      setCollectionListOriginal([]);
      window.scrollTo(0, 0);
      setPage((pre) => 1);
      getCollectionList(1);
    }
  }, [searchValue, activeMenuIcons?.filter]);

  const loadMore = (e) => {
    if (!loading) {
      getCollectionList(page+1);
      setPage((pre) => pre + 1);
      
    }
  };
  return (
    <div className="discover-collection h-full w-full flex flex-col explore-collection">
      {!!subCategoryItem?.name&&!!collectionListOriginal&&<div className="text-left mt-[.5rem] mb-[1.25rem] text-[1.25rem]">
        {subCategoryItem?.name}
      </div>}
      {/* <div className="w-full overflow-flex-auto h-full flex gap-[1.42857rem]"> */}
      {collectionListOriginal&&!!collectionListOriginal?.length && !!subCategoryItem?.name&&(
        <div className="  flex-1 h-full flex flex-col justify-between">
          <InfiniteLoader
            loadData={loadMore}
            length={collectionListOriginal?.length}
            loaderCount={5}
            hasMore={collectionListOriginal?.length < totalPage}
            loader="details-skeleton"
          >
            <div className={`collection-container grid gap-3 relative ${(!subCategoryItem?.name||!collectionListOriginal)&&"mt-[3.5rem]"}`}>
              {[...collectionListOriginal]?.map((list) => {
                return (
                  <CollectionCard
                    showData={{
                      profileIcon: true,
                      collectionName: true,
                      communityName: true,
                      count: false,
                      tag: true,
                    }}
                    key={list?.id}
                    row={list}
                    cardClick={cardClick}
                  />
                );
              })}
            </div>
          </InfiniteLoader>
        </div>
      )}
      {(loading || !subCategoryItem?.name||!collectionListOriginal)&& (
      <div className={`${(!subCategoryItem?.name||!collectionListOriginal)&&"mt-[3.5rem]"}`}>
       <DetailsSkeltonCard
       show={true}
       height={"100%"}
       count={10}
       inline={true}
       details={"img-2-line"}
       noAbsolute
       containerClassName={`flex`}
     />
     </div>
      )}
      {!loading && loading !== null && collectionListOriginal && !collectionListOriginal?.length && subCategoryItem?.name&& (
        <div className=" flex items-center no-data justify-center">
          No collections to display.
        </div>
      )}
    </div>
    //   </div>
  );
};

export default SubcategoryCollection;
