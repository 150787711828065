import { useCallback, useEffect, useState } from "react";
import useHttp from "App/hooks/use-http";
import GalleryPreviewPlugin from 'App/Pages/GalleryPreview/Component';
import { useSearchParams } from "react-router-dom";

const DisplayGallery = () => {   
  // eslint-disable-next-line no-unused-vars
  const [displayRenderData, setDisplayRenderData] = useState(null)  
  const [searchParams] = useSearchParams();
  const [layoutId, setLayoutId] = useState(null);
  const [multiBg, setMultiBg] = useState(null);
  const [initalTimes, setInitalTimes] = useState(null);
  const [galleryData, setGalleryData] = useState(null);
  const [galleryInfo, setgalleryInfo] = useState(null);
  const [previewType, setPreviewType] = useState('mono');
  let isAppV1 = searchParams.get("source") === 'appv1';
  const { triggerAPI } = useHttp();         
  const getPageResult = useCallback((res) => {
    const { data } = res;
    setgalleryInfo({name: data?.name, userName: data?.userData?.username ?? ''})
    setLayoutId(data?.layoutId);
    setMultiBg(data?.thumbnailBackground);
    const timer = {
        hr: data?.preference?.slideTimerHours ?? "0",
        min: data?.preference?.slideTimerMinutes ?? "0",
        sec: data?.preference?.slideTimerSeconds ?? "10"
    }
    setInitalTimes(timer);
    setGalleryData(data);
}, [])
const getPageData = (id) => {
    const url = 'gallery/stand-alone/get/';
    triggerAPI({
        url: `${url}${id}?isVerified=0&compression=0`, method: 'get'
    }, getPageResult);
}
  const getDisplayRenderDataResult = useCallback((res) => {    
    if(res.data) {
      setDisplayRenderData(res.data);   //need to use this after setting saved to db
      getPageData(res?.data?.activeGalleryId); 
      setPreviewType(res?.data?.type); 
    }        
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDisplayRenderData = useCallback(() => {                
    const queryParameters = new URLSearchParams(window.location.search)
    const uniqueId = queryParameters.get("uniqueId");
    const time = queryParameters.get("time");
    const signature = queryParameters.get("signature");
    triggerAPI(
        {
        url: `display/getRenderData?uniqueId=${uniqueId}&time=${time}&signature=${signature}`,
        method: "get"        
        },
        getDisplayRenderDataResult
    );
  }, [getDisplayRenderDataResult, triggerAPI]);

  useEffect(()=> {    
    getDisplayRenderData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const handleGalleryView = (type) => {
    setPreviewType(type)
  }
  return (
    <div className="modern-gallery-preview">
      <GalleryPreviewPlugin
          galleryData={galleryData} 
          galleryType={previewType}
          isAppV1={isAppV1}
          initalTimes={initalTimes}
          layoutId={layoutId}
          multiBgData={multiBg}
          galleryInformation={galleryInfo}
          handleGalleryView={handleGalleryView}
      />
    </div>
  );
};

export default DisplayGallery;