import { useCallback, useEffect, useState } from "react";
import useHomeApiHelper from "../API/apiHelper";
import { useNavigate } from "react-router-dom";
import SkeltonCard from "App/Components/Ui/SkeltonCard";
import FormatField from "App/Components/Ui/FormatField";
import { EventsCard } from "App/Components/Ui/EventCard";

export const HomeEvents = ({ handlePreview = () => {}, screenSize='' }) => {
  const { getEvents } = useHomeApiHelper();
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const getData = useCallback(() => {
    const req = {
      page: 1,
      items: screenSize > 550 ? 6 : 4,
      filter: {
        showOnHomePageDisplay: true,
        start: new Date(),
      },
    };
    setLoading(true);
    getEvents(req, (res) => {
      setLoading(false);
      const updatedData = res?.data?.upcomingEvents?.map((val) => {
        return {
          ...val,
          eventDate: new Date(val?.start).toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
          title: val?.community?.name,
          startDate: new Date(val?.start)
            .toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })
            .toLowerCase(),
          endDate: new Date(val?.end)
            .toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })
            .toLowerCase(),
        };
      });
    
      // const sortedEvents = updatedData.sort((a, b) => new Date(a.start) - new Date(b.start));
      setEventList(updatedData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize]);
  useEffect(() => {
    getData();
  }, [getData, screenSize]);
  const navigateToAll = () => {
    navigate(`/curate/events`);
    window.scrollTo(0, 0);
  };
  return (
    <div className="flex flex-col gap-[1.25rem]">
      <div className="title-section flex justify-between">
        <div className="title">Attend Upcoming Events</div>
     {!!eventList?.length&& <div className="link" onClick={navigateToAll}>
          See all
        </div>}
      </div>
      {!loading && !eventList?.length&&<div className="flex items-center no-data justify-center">{`There are currently no upcoming events.`}</div>}
      <div className="event-grid gap-4">
        {!loading && !!eventList?.length&&
          eventList?.map((item, index) => {
            return (           
          <EventsCard
          data={item}
          fromCommunity={true} 
          key={index}           
          />
            );
          })}
      </div>
    </div>
  );
};
