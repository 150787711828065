import React, { useEffect, useState } from "react";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import HolderDistributionChart from "./Components/HolderDistributionChart";
import ChartSkelton from "./Components/ChartSkelton";
import TopHolders from "./Components/TopHolders";
import './Chart.scss';
import CommonEChart from "./Components/CommonEChart";
import SalesNVolChart from "./Components/SalesnVolChart";

const Chart = ({ type, title = '', collectionPrimaryKey = null, fetchData = true }) => {

  const { triggerAPI } = useHttp();
  // const [{ collections, allCollectionData }] = useStore();
  const dispatch = useStore(false)[1];

  const [isLoading, setIsLoading] = useState(true);

  const [volumeAndSalesData, setVolumeAndSalesData] = useState({
    data: [],
    maxValue: 0
  });
  const [floorData, setFloorData] = useState({
    data: [],
    maxValue: 0
  });
  const [ownersData, setOwnersData] = useState({
    data: [],
    maxValue: 0
  });
  const [listingsData, setListingsData] = useState({
    data: [],
    maxValue: 0
  });

  const [headerValues, setHeaderValues] = useState({});
  const [holdersData, setHoldersData] = useState({
    total: 0,
    data: []
  });
  const [comparedData, setComparedData] = useState(null)
  const [topHoldersData, setTopHoldersData] = useState({
    totalTopHoldersCount: 0,
    data: []
  });

  const [filterValue, setFilterValue] = useState('30days');
  const [filterDate, setFilterDate] = useState(null);
  const dateFilterData = [
    { id: 1, label: 'Last 24 hours', value: '1day' },
    { id: 2, label: 'Last 7 days', value: '7days' },
    { id: 3, label: 'Last 30 days', value: '30days' },
  ];

  const compareMetrics = (newMetricsDate, latestPreviousMetrics) => {
    const changes = {};
    for (const key in newMetricsDate) {
      const currentValue = newMetricsDate[key];
      const previousValue = latestPreviousMetrics[key];

      if (currentValue && previousValue) {
        if (currentValue > previousValue) {
          changes[key] = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" fill="green" /></svg>
        } else if (currentValue < previousValue) {
          changes[key] = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M17 15.586 6.707 5.293 5.293 6.707 15.586 17H7v2h12V7h-2v8.586z" fill="red" /></svg>
        } else {
          changes[key] = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M11.293 4.707 17.586 11H4v2h13.586l-6.293 6.293 1.414 1.414L21.414 12l-8.707-8.707-1.414 1.414z" fill="skyblue" /></svg>
        }
      } else {
        changes[key] = '';
      }
    }
    return changes;
  };

  const handleDateFormat = (data) => {

    const options = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const sortedData = data?.length ? [...data].sort((a, b) => a.dateRetrieved.localeCompare(b.dateRetrieved)) : [];

    const formattedData = sortedData.map(item => ({
      ...item,
      fullDate: new Date(item.dateRetrieved).toLocaleString(undefined, options),
      onlyDate: new Date(item.dateRetrieved).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }),
      onlyDateAndTime: new Date(item.dateRetrieved).toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric', hour12: true })
    }));

    return formattedData;
  }

  const bindChartData = (result) => {
    const { data } = result;
    let totalHoldersDistribution = 0;
    const holdersDistributionData = data?.holdersDistribution?.length ? data?.holdersDistribution?.map((holdersDis, index) => {
      if (holdersDis?.length === 2) {
        totalHoldersDistribution = holdersDis[1] + totalHoldersDistribution;
      }
      return { header: `${holdersDis[0]} ${index === 0 ? 'Piece' : 'Pieces'} `, value: holdersDis[1] }
    }) : [];

    setHoldersData({
      total: totalHoldersDistribution,
      data: holdersDistributionData
    })

    let totalTopHoldersCount = data?.topHolders.length ?
      data?.topHolders.reduce((acc, item) => acc + item?.amount, 0)
      : 0;

    setTopHoldersData({ totalTopHoldersCount, data: data?.topHolders });

    if (data?.metrics?.length && data?.latestPreviousMetrics?.length) {
      setComparedData(compareMetrics(data?.metrics[0], data?.latestPreviousMetrics[0]))
    }

    setHeaderValues(data?.metrics[0]);

    const formattedData = handleDateFormat(data?.metrics);

    // Floor data calculation
    const floorData = formattedData?.filter(item => ((item?.floorPrice && item?.floorPrice !== 0 && item?.floorPrice !== '0') && {
      ...item,
      date: item?.fullDate,
      floorPrice: item?.floorPrice ? parseInt(item?.floorPrice) : 0
    }));
    const floorDataMaxValue = floorData.reduce((maxValue, item) => (
      Math.max(maxValue, item.floorPrice)
    ), 0);
    setFloorData({
      data: floorData,
      maxValue: floorDataMaxValue
    })

    // Volume and Sales data calculation
    const volumeAndSales = formattedData
      .filter(item => (
        item?.dailyVolume && item?.dailyVolume !== '0' &&
        item?.dailySales && item?.dailySales !== '0'
      ))
      .map(item => ({
        ...item,
        date: item?.fullDate,
        dailyVolume: parseInt(item?.dailyVolume),
        dailySales: parseInt(item?.dailySales)
      }));
    const volumeAndSalesMaxValue = volumeAndSales.reduce((maxValue, item) => (
      Math.max(maxValue, item.dailyVolume, item.dailySales)
    ), 0);
    setVolumeAndSalesData({
      data: volumeAndSales,
      maxValue: volumeAndSalesMaxValue
    });

    // Listing data calculation
    const listingsData = formattedData?.filter(item => ((item?.listings && item?.listings !== 0 && item?.listings !== '0') && {
      ...item,
      date: item?.fullDate,
      listings: item?.listings ? parseInt(item?.listings) : 0
    }));
    const listingsMaxValue = listingsData.reduce((maxValue, item) => (
      Math.max(maxValue, item.listings)
    ), 0);
    setListingsData({
      data: listingsData,
      maxValue: listingsMaxValue
    });

    // Owners data calculation
    const ownersData = formattedData?.filter(item => ((item?.owners && item?.owners !== 0 && item?.owners !== '0') && {
      ...item,
      date: item?.fullDate,
      owners: item?.owners ? parseInt(item?.owners) : 0
    }));
    const ownersMaxValue = ownersData.reduce((maxValue, item) => (
      Math.max(maxValue, item.owners)
    ), 0);
    setOwnersData({
      data: ownersData,
      maxValue: ownersMaxValue
    });

    setIsLoading(false);

  }

  useEffect(() => {
    if (type && collectionPrimaryKey && filterDate) {
      setIsLoading(true);
      triggerAPI({
        url: `community/${type}/filter/${collectionPrimaryKey}?date=${filterDate}`, method: 'get'
      }, bindChartData, (err) => {
        console.log(err)
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message: err?.response?.data?.error?.message,
          },
        });
        setIsLoading(false);
      });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, collectionPrimaryKey, filterDate])


  const getPrevDateHandler = (backTo) => {

    const currentDate = new Date();
    const prevDays = new Date(currentDate);
    prevDays.setDate(currentDate.getDate() - backTo);
    return prevDays.toISOString().split('T')[0]; // Format: YYYY-MM-DD
  }
  const onChangeFilterHandler = e => {
    const selectedOne = e?.target?.value;
    const backTo = selectedOne === '1day' ? 1 : selectedOne === '7days' ? 7 : 30
    setFilterDate(getPrevDateHandler(backTo));
    setFilterValue(selectedOne)
  }

  useEffect(() => {
    setFilterValue('30days')
    setFilterDate(getPrevDateHandler(30));
  }, []);


  return (
    <div className="w-full pb-[2rem]">
      <form className="w-full flex justify-end">
        <select defaultValue={filterValue} disabled={isLoading} onChange={onChangeFilterHandler} id="chart-filter" className="bg-[var(--cu-black-500)] border border-[var(--cu-black-50)] text-sm rounded-lg block p-2.5">
          {dateFilterData?.map((option) => <option key={option.id}
            value={option.value}>{option?.label}
          </option>)}
        </select>
      </form>
      {isLoading ?
        <ChartSkelton /> :
        <div className="h-full">
          <HeaderData key={'header-data'} headerData={headerValues} comparedData={comparedData} />
          <div className="h-full grid grid-cols-2 gap-10">
            <CommonEChart key={1} type={type} chartHeader={'Floor Price'} apiData={floorData?.data} label={'Floor'}/>
            <SalesNVolChart type={type} chartHeader={'Sales & Volume'} apiData={volumeAndSalesData?.data} label={'Sales & Volume'}/>
            <CommonEChart key={3} type={type} chartHeader={'Listings'} apiData={listingsData?.data} label={'Listings'}/>
            <CommonEChart key={4} type={type} chartHeader={'Owners'} apiData={ownersData?.data} label={'Owners'}/>
            <HolderDistributionChart key={'holder-table'} holdersData={holdersData} />
            <TopHolders topHoldersData={topHoldersData?.data} totalTopHoldersCount={topHoldersData?.totalTopHoldersCount} />
          </div>
        </div>}
    </div>
  );
};

function HeaderData({ headerData, comparedData }) {

  return <div className="grid grid-cols-5 gap-4 py-[1rem]">
    <div className="flex flex-col items-start justify-center  px-5 border border-[var(--cu-black-50)] bg-[var(--cu-black-500)]  rounded-xl h-20">
      <p>Floor Price</p>
      <div className="w-full flex items-center gap-4">
        <span className="text-xl">{headerData?.floorPrice ? `₳ ${headerData?.floorPrice}` : '-'}</span>
        <span className="w-[1rem] scale-[0.8] mt-1">{headerData?.floorPrice ? comparedData?.floorPrice : null}</span>
      </div>
    </div>
    <div className="flex flex-col items-start justify-center  px-5 border border-[var(--cu-black-50)] bg-[var(--cu-black-500)]  rounded-xl h-20">
      <p>Volume</p>
      <div className="w-full flex items-center gap-4">
        <span className="text-xl">{headerData?.dailyVolume ? `₳ ${headerData?.dailyVolume}` : '-'}</span>
        <span className="w-[1rem] scale-[0.8] mt-1">{headerData?.dailyVolume ? comparedData?.dailyVolume : null}</span>
      </div>
    </div>
    <div className="flex flex-col items-start justify-center  px-5 border border-[var(--cu-black-50)] bg-[var(--cu-black-500)]  rounded-xl h-20">
      <p>Sales</p>
      <div className="w-full flex items-center gap-4">
        <span className="text-xl">{headerData?.dailySales ? `${headerData?.dailySales}` : '-'}</span>
        <span className="w-[1rem] scale-[0.8] mt-1">{headerData?.dailySales ? comparedData?.dailySales : null}</span>
      </div>
    </div>
    <div className="flex flex-col items-start justify-center  px-5 border border-[var(--cu-black-50)] bg-[var(--cu-black-500)]  rounded-xl h-20">
      <p>Pieces listed</p>
      <div className="w-full flex items-center gap-4">
        <span className="text-xl">{headerData?.listings ? `${headerData?.listings}` : '-'}</span>
        <span className="w-[1rem] scale-[0.8] mt-1">{headerData?.listings ? comparedData?.listings : null}</span>
      </div>
    </div>
    <div className="flex flex-col items-start justify-center  px-5 border border-[var(--cu-black-50)] bg-[var(--cu-black-500)]  rounded-xl h-20">
      <p>Owners</p>
      <div className="w-full flex items-center gap-4">
        <span className="text-xl">{headerData?.owners ? `${headerData?.owners}` : '-'}</span>
        <span className="w-[1rem] scale-[0.8] mt-1">{headerData?.owners ? comparedData?.owners : null}</span>
      </div>
    </div>
  </div>
}

export default Chart