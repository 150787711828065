import ModalOuter from "App/Components/Ui/ModalOuter";
import CreateAnnouncementsAndEvents from "../../../Components/CreateAnnouncementsEvents";
import closeIcon from "Assets/Icons/close.svg";

const CreateEventModal = ({
  add,
  newEventHandler,
  setSubjects,
  selectedData,
  subjects,
  onSaveHandler,
}) => {

  if(!add) return
  return (
    <ModalOuter
      onClose={() => {}}
      openModal={add}
      parentClass={add ? "!z-[10]" : "!hidden !z-[10]"}
      contentClass={
        "animated-slideIn w-full max-w-[900px] h-auto max-h-[75dvh] overflow-x-hidden overflow-y-auto p-5 bg-[var(--cu-black-700)] border border-[var(--cu-black-100)] rounded-lg mx-[5%]"
      }
    >
      <div className="w-full flex items-center justify-between">
        <span />
        <h2>Event</h2>
        <button onClick={newEventHandler}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>
      <CreateAnnouncementsAndEvents
        page="event"
        data={selectedData}
        onCancel={newEventHandler}
        onSaveHandler={onSaveHandler}
        subjectsArray={subjects?.length ? subjects : false}
        onChangeSubjectArray={setSubjects}
      />
    </ModalOuter>
  );
};
export default CreateEventModal;
