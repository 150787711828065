import { useEffect, memo } from 'react';
import './MetaGallery.scss';
import MetaGalleryBaseScene from './Component/MetaGalleryBaseScene';
import { useSelector,useDispatch } from 'react-redux'
import { setframepositions,setisviewingframe } from './Component/MetaGalleryState';
import MetaGallery1 from './MetaGallery1';
import MetaGallery2 from './MetaGallery2';
import MetaGallery3 from './MetaGallery3';
const MetaGallery = (props) => {
    const dispatch = useDispatch();
    let framepositions = useSelector((state) => state.appState.framepositions.payload)??[];      
    useEffect(() => {        
        framepositions.length = 0;
        dispatch(setframepositions(framepositions));  
        dispatch(setisviewingframe(false));  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.galleryId])
    
    return (  
        <>
            {((props.galleryId === 1 && !props.oldGalleryId) || props.oldGalleryId === 1) && 
                <div className={props.oldGalleryId?'metagallery':'metagallery'}>
                    <MetaGalleryBaseScene key={props.galleryId} oldGalleryId={props.oldGalleryId}>                        
                        <MetaGallery1 args={[0, 2, 0]} position={[0, 0, 0]} galleryList={props.galleryList} captionControl={props?.captionControl?.captionCard} />                
                    </MetaGalleryBaseScene>        
                </div>
            }

            {((props.galleryId === 2 && !props.oldGalleryId) || props.oldGalleryId === 2) && 
                <div className={props.oldGalleryId?'metagallery':'metagallery'}>
                <MetaGalleryBaseScene oldGalleryId={props.oldGalleryId}>                        
                    <MetaGallery2 args={[0, 2, 0]} position={[0, 0, 0]} galleryList={props.galleryList} captionControl={props?.captionControl?.captionCard} />
                </MetaGalleryBaseScene>        
                </div>
            }

            {((props.galleryId === 3 && !props.oldGalleryId) || props.oldGalleryId === 3) &&
                <div className={props.oldGalleryId?'metagallery':'metagallery'}>
                <MetaGalleryBaseScene oldGalleryId={props.oldGalleryId}>                        
                    <MetaGallery3 args={[0, 2, 0]} position={[0, 0, 0]} galleryList={props.galleryList} captionControl={props?.captionControl?.captionCard} />
                </MetaGalleryBaseScene>        
                </div>
            }
        </>      
    );
}

export default memo(MetaGallery);