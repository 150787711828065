import { useCallback, useEffect, useState } from "react"
import useHomeApiHelper from '../API/apiHelper';
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export const HomeStatitics = () => {
    const [data, setData] = useState({})
    const { getStatitics } = useHomeApiHelper();
    const [loading, setLoading] = useState(false);
    const getData = useCallback(() => {
        setLoading(true);
        getStatitics({}, (res) => {
            setLoading(false)
            setData(res?.data);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        getData()
    },[getData])
    return (
        <div className="flex">
            <div className="statistics-container flex gap-4">
                <div className="statistics-data">
                    <div className="data-count">
                        <span>{loading ? <SkeletonLoader height={10} count={1} inline={true} /> : 
                            <VisibilitySensor partialVisibility offset={{ top:100 }}>
                            {({ isVisible }) => {
                              return (
                              <div>
                                {
                                isVisible 
                                ? <CountUp start={0} end={Number(data?.collectorsCount)} delay={0}>
                                    {({ countUpRef }) => (
                                      <span ref = {countUpRef} className = "count-element">{Number(data?.collectorsCount)||0}</span>
                                    )}
                                  </CountUp>
                                : <span className = "count-element">{Number(data?.collectorsCount)||0}</span>
                                }
                              </div>
                            )
                            }}
                          </VisibilitySensor>
                         }</span>
                    </div>
                    <div className="data-name">
                        <span> Collectors </span>
                    </div>
                </div>
                <div className="statistics-data">
                    <div className="data-count">
                        <span>{loading ? <SkeletonLoader height={10} count={1} inline={true} /> :
                          <VisibilitySensor partialVisibility offset={{ top:100 }}>
                          {({ isVisible }) => {
                            return (
                            <div>
                              {
                              isVisible 
                              ? <CountUp start={0} end={Number(data?.creatorsCount)} delay={0}>
                                  {({ countUpRef }) => (
                                    <span ref = {countUpRef} className = "count-element">{Number(data?.creatorsCount)||0}</span>
                                  )}
                                </CountUp>
                              : <span className = "count-element">{Number(data?.creatorsCount)||0}</span>
                              }
                            </div>
                          )
                          }}
                        </VisibilitySensor>
                        }</span>
                    </div>
                    <div className="data-name">
                        <span> Creators </span>
                    </div>
                </div>
                <div className="statistics-data">
                    <div className="data-count">
                        <span>{loading ? <SkeletonLoader height={10} count={1} inline={true} /> :
                          <VisibilitySensor partialVisibility offset={{ top:100 }}>
                          {({ isVisible }) => {
                            return (
                            <div>
                              {
                              isVisible 
                              ? <CountUp start={0} end={Number(data?.collectionsCount)} delay={0}>
                                  {({ countUpRef }) => (
                                    <span ref = {countUpRef} className = "count-element">{Number(data?.collectionsCount)||0}</span>
                                  )}
                                </CountUp>
                              : <span className = "count-element">{Number(data?.collectionsCount)||0}</span>
                              }
                            </div>
                          )
                          }}
                        </VisibilitySensor>
                        }</span>
                    </div>
                    <div className="data-name">
                        <span> Collections </span>
                    </div>
                </div>
                <div className="statistics-data">
                    <div className="data-count relative">
                        <span>{loading ? <SkeletonLoader height={12} count={1} inline={false} /> :
                          <VisibilitySensor partialVisibility offset={{ top:100 }}>
                          {({ isVisible }) => {
                            return (
                            <div>
                              {
                              isVisible 
                              ? <CountUp start={0} end={Number(data?.galleryCount)} delay={0}>
                                  {({ countUpRef }) => (
                                    <span ref = {countUpRef} className = "count-element">{Number(data?.galleryCount)||0}</span>
                                  )}
                                </CountUp>
                              : <span className = "count-element">{Number(data?.galleryCount)||0}</span>
                              }
                            </div>
                          )
                          }}
                        </VisibilitySensor>
                        }</span>
                    </div>
                    <div className="data-name">
                        <span> Galleries </span>
                    </div>
                </div>
            </div>
        </div>
    )
}