import Banner from "App/Components/Banner";
import { useStore } from "App/hooks-store/store";
import useScreenSize from "App/hooks/use-screen-size";
import { useEffect, useMemo, useRef } from "react"
import { NavLink, Outlet, useLocation, useParams } from "react-router-dom"

const Community = () => {
    const screensize = useScreenSize()?.width;
    const { pathname } = useLocation();
    const { tab } = useParams();
    const divRef = useRef();
    const dispatch = useStore(false)[1];
    const topMenuOption = [{id:1, element: 'Community', link: 'community'}]
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    useEffect(() => {
        if(divRef.current)
            divRef.current.scroll({
                top: 0,
                behavior: 'smooth'
                });
        return () => {
            dispatch('resetAll')
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])
    const tabUrls = useMemo(() => {
        return ['overview', 'collection', 'my-galleries', 'posts', 'events', 'about'];
    }, [])
    return (
        <div className={`${!pathname?.includes('community/detail') && 'page-shadow'} ${(!tabUrls?.includes(tab) && !pathname?.includes('my-galleries')) && 'community-panel overflow-auto'} h-full`} ref={divRef}>
            {isPortrait && screensize < 551 && <div className={`creator-profile-banner min-h-[1.43rem]`}>
                <Banner className="h-full ">
                    <div className='flex h-full'>
                        <div className={`mobile-head flex justify-start items-center`}>
                            {
                                (topMenuOption).map(elem => (<div key={elem.id}>
                                    <NavLink 
                                        className={"linkActive w-full p-2.5 pb-0 nav-link"}
                                        to={elem.link}>
                                        {elem.element}
                                    </NavLink></div>))
                            }
                        </div>
                    </div>
                </Banner>
                </div>
            }
            <Outlet />
        </div>
    )
}

 export default Community