import { useLayoutEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css'
import './App.scss';
import { updateViewPortMeta } from './Helper/utilities';
import Main from './Layouts/Main'
import Store from './Store';
import { MsalProvider } from "@azure/msal-react";

function App({instance}) {
  
  useLayoutEffect(() => {
    updateViewPortMeta()
  },[])
  return (
    <div className="App h-full">
      <MsalProvider instance={instance}>
        <Store>
          <Main />
        </Store>
      </MsalProvider>
    </div>
  );
}

export default App;
