import configureSpinnerStore from "App/hooks-store/spinner-store";
import configureTitleStore from "App/hooks-store/title-store";
import configureToastStore from "App/hooks-store/toast-store";
import configureCollectionsStore from "App/hooks-store/collection-store"
import profilepicStore from "App/hooks-store/profile-pic-store"
import artBankStore from "App/hooks-store/artBank-store"
import nftDropStore from "App/hooks-store/nftDrop-store"
import allCollectionStore from "App/hooks-store/all-collection-store"
import { Fragment } from "react";
import walletStore from "App/hooks-store/mesh-wallet-store";
import galleryStore from "App/hooks-store/gallery-store";
import frameStore from "App/hooks-store/frame-store";
import topMenuStore from "App/hooks-store/topMenu-store";
import communityStore from 'App/hooks-store/community-store';
import layoutStore from 'App/hooks-store/layout-store';
import token from "App/hooks-store/token-store";
import configureSingleCollectionStore from "App/hooks-store/single-collection-store";
import galleryPreviewStore from "App/hooks-store/gallery-preview-store";
import { db } from "../Helper/db";
import configureMusicStore from "App/hooks-store/music-store";

let dbstate = await db.state.toArray();
document.cur8dbstate = {};
dbstate.forEach(item => {
    document.cur8dbstate[item.id]=item.value;
});
document.cur8dbstateminimal = {
    username: document.cur8dbstate?.username,
    schemaVersionNumber: document.cur8dbstate?.schemaVersionNumber
};

const Store = (props) => {
    configureSpinnerStore();
    configureToastStore();
    configureTitleStore();
    configureCollectionsStore();
    profilepicStore();
    walletStore();
    artBankStore();
    galleryStore();
    frameStore();
    nftDropStore();
    allCollectionStore();
    topMenuStore();
    communityStore();
    layoutStore();
    token();
    configureSingleCollectionStore();
    galleryPreviewStore();
    configureMusicStore();
    return (
        <Fragment>
            {props.children}
        </Fragment>
    )
}
export default Store;