import { Fragment, useCallback, useLayoutEffect, useRef } from 'react';
import { useState } from 'react';
import { useStore } from 'App/hooks-store/store';
import useHttp from 'App/hooks/use-http';import { useEffect } from 'react';
import GalleryImages from "App/Components/Ui/GalleryFrameImages"
import { getFrameFilterList } from 'App/Helper/aspectRatioCalc';
import './NFTFrameGallery.scss';
import FramePopup from './FramePopup';
import zoomIcon from "Assets/Icons/enlargeIcon.png";
import SkeltonCard from 'App/Components/Ui/SkeltonCard';
import useScreenSize from 'App/hooks/use-screen-size';

const NFTFrameGallery = (props) => {
    const { triggerAPI } = useHttp();
    const [freeFrames, setFreeFrames] = useState([])
    const [nftFrames, setNftFrames] = useState([])
    const [selectedNftFrame, setSelectedNftFrame] = useState('')
    const [selectedFrame, setSelectedFrame] = useState('')
    const [{ currentSelectedNfts,leftMenuCollapse }, dispatch] = useStore(false);
    const [filterFreeFrames, setFilterFreeFrames] = useState([])
    const [filterNftFrames, setFilterNftFrames] = useState([])
    const [currentFullFreeFrames, setCurrentFullFreeFrames] = useState([])
    const [currentFullNftFrames, setCurrentFullNftFrames] = useState([])
    const [frameCollapse, setFrameCollapse] = useState(false)
    const [spinnerShow, setSpinnerShow] = useState(true)
    // eslint-disable-next-line no-unused-vars
    const [nftList, setNftList] = useState(props?.selectedNfts || [])
    const [filterNft, setFilterNft] = useState(props?.selectedNfts || [])
    const [imagesFrame, setImagesFrame] = useState({})
    const [showPopup, setShowPopup] = useState({ mode: false })
    const screenSize = useScreenSize()?.width;
    const [showName, setShowName] = useState(true)
    const [loaderPreview, setLoaderPreview] = useState(false)
    const targetRef = useRef(0)
    //executed when frames are selected
    const frameSelectedHandler = (e) => {
        setLoaderPreview(true)
        let item = e.currentTarget.dataset["ind"]
        let type = e.currentTarget.dataset["type"]
        let updateFrame = type === 'free' ? setSelectedFrame : setSelectedNftFrame
        let frame = type === 'free' ? freeFrames : nftFrames
        let nonUpdateFrame = type === 'free' ? setSelectedNftFrame : setSelectedFrame
        setFrameCollapse(false);
        if (item) {
            updateFrame(frame[item])
            nonUpdateFrame(null)
        }
    }

    //to update the selected frame with the exisiting frames
    const updateGalleryFrame = ({ frameId, assetId }) => {
        const newArray = [...currentSelectedNfts]?.map((x) => {
            if(x.asset === assetId) {
               return  {...x, frameId: frameId?.id ?? null, isLandscape: frameId?.isLandscape ?? null}
            }
            return x;
        })
        dispatch('setCurrentSelectedNfts', [...newArray])
    }

    //to remove Frame
    const onRemoveHandler = (e) => {
        updateGalleryFrame({ frameId: null, assetId: props?.frameAssetNew?.asset })
        setSelectedFrame(null)
        setSelectedNftFrame(null)
        props?.onChangeFrame({frameId: null, asset: props?.frameAssetNew?.asset})
    }

    useEffect(() => {
        if (props?.frameAssetNew?.asset && selectedNftFrame?.id) {
            applyFrameHandler('nft')
        }
        else if (props?.frameAssetNew?.asset && selectedFrame?.id) {
            applyFrameHandler('free')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNftFrame, selectedFrame])

    const applyFrameHandler = (type) => {
        updateGalleryFrame({ frameId: type === 'nft' ? selectedNftFrame : selectedFrame, assetId: props?.frameAssetNew?.asset })

        if (type === 'nft') {
            props?.onChangeFrame({frameId: selectedNftFrame.id, asset: props?.frameAssetNew?.asset})
        }
        else {
            props?.onChangeFrame({frameId: selectedFrame.id, asset: props?.frameAssetNew?.asset})
        }

    }

    const createFrame = (list) => {
        return list?.map(frame => {
            return {
                id: frame?.id,
                name: frame.name,
                s3ImageUrl: frame.s3ImageUrl,
                s3ImageUrlLandscape: frame.s3ImageUrlLandscape,
                price: frame.price ? frame.price : "00",
                inStock: 30,
                metaInfo: frame.metaInfo,
                isLandscape: frame.isLandscape
            }
        })
    }

    const getFreeFrameList = useCallback(() => {
        let page = 1;
        let temp = [];
        const triggerFetchFreeFrame = () => {
            const data = {
                "page": page,
                "items": 15,
                filter: { categoryId: [2] }
            }
            triggerAPI({
                url: `frame-asset/user/get`, data: data, method: 'post'
            }, (res) => {
                const { list, pageMeta } = res.data;
                let arr_temp = list.map(frame => {
                    return {
                        id: frame.id,
                        name: frame.name,
                        s3ImageUrl: frame.s3ImageUrl,
                        s3ImageUrlLandscape: frame.s3ImageUrlLandscape,
                        s3ImageUrl256: frame.s3ImageUrl256,
                        s3ImageUrlLandscape256: frame?.s3ImageUrlLandscape256,
                        s3ImageUrl512: frame.s3ImageUrl512,
                        s3ImageUrlLandscape512: frame?.s3ImageUrlLandscape512,
                        price: frame.price ? frame.price : "00",
                        inStock: 30,
                        metaInfo: frame.metaInfo,
                        isLandscape: false
                    }
                })
                temp = [...temp, ...arr_temp]
                if (temp.length < pageMeta.totalItems) {
                    page++;
                    triggerFetchFreeFrame();
                } else {
                    setCurrentFullFreeFrames(temp)
                }

            });
        }
        triggerFetchFreeFrame();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, triggerAPI])

    const getNftFrames = useCallback(() => {
        let page = 1;
        let temp = [];
        const triggerFetchNftFrame = () => {
            const data = {
                page: page,
                items: 15,
                filter: {categoryId: [3], isUserAdded: true}
            }
            triggerAPI({
                url: `frame-asset/user/get`, method: 'post', data
            }, (res) => {
                const { list, pageMeta } = res.data;
                let arr_temp = list.map(frame => {
                    return {
                        id: frame.id,
                        name: frame.name,
                        s3ImageUrl: frame.s3ImageUrl,
                        s3ImageUrlLandscape: frame.s3ImageUrlLandscape,
                        s3ImageUrl256: frame.s3ImageUrl256,
                        s3ImageUrlLand256: frame?.s3ImageUrlLandscape256,
                        s3ImageUrl512: frame.s3ImageUrl512,
                        s3ImageUrlLand512: frame?.s3ImageUrlLandscape512,
                        price: frame.price ? frame.price : "00",
                        inStock: 30,
                        metaInfo: frame.metaInfo,
                        isLandscape: false
                    }
                })
                temp = [...temp, ...arr_temp]
                if (temp.length < pageMeta.totalItems) {
                    page++;
                    triggerFetchNftFrame();
                } else {
                    setCurrentFullNftFrames(temp)
                }

            });
        }
        triggerFetchNftFrame();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, triggerAPI])
    
    useEffect(() => {
        setSpinnerShow(true)
        getNftFrames()
        getFreeFrameList()
        setSelectedFrame(null)
        setSelectedNftFrame(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.frameAssetNew?.asset])

    useEffect(() => {
        dispatch('setFreeFrames', currentFullFreeFrames)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFullFreeFrames])
    useEffect(() => {
        dispatch('setNftFrames', currentFullNftFrames)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFullNftFrames])

    useEffect(() => {
        setFreeFrames(createFrame(filterFreeFrames))
    }, [filterFreeFrames])
    useEffect(() => {
        setNftFrames(createFrame(filterNftFrames))
    }, [filterNftFrames])

    useEffect(() => {
        const found = currentSelectedNfts?.find((x) => x?.asset === props?.frameAssetNew?.asset)
        let value = getFrameFilterList(found?.nftcdnimage256, currentFullFreeFrames)
        value.then((a) => {
            setFilterFreeFrames([...a])
        });
        setSpinnerShow(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFrame?.id, currentFullFreeFrames, imagesFrame])

    useEffect(() => {
        const found = currentSelectedNfts?.find((x) => x?.asset === props?.frameAssetNew?.asset)
        let value = getFrameFilterList(found?.nftcdnimage256, currentFullNftFrames)
        value.then((a) => {
            setFilterNftFrames([...a])
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNftFrame?.id, currentFullNftFrames])

    useEffect(() => {
        const val = currentSelectedNfts?.find((val) => val?.asset === props?.frameAssetNew?.asset)
        setImagesFrame({ ...val })
    }, [props?.frameAssetNew, currentSelectedNfts])

    const onImageClick = (e) => {
        props?.onSmFramClick(e);
        setFrameCollapse(false)
    }

    useEffect(() => {
        setFilterNft(currentSelectedNfts)
    }, [currentSelectedNfts])
    const onClosePopup = (e) => {
        setShowPopup({ mode: false })
    }

    const onClick = () => {
        setShowPopup({ mode: true })
    }
    const updateHeight = () => {
        const root = document.documentElement;
        if(targetRef.current) {
            if(targetRef.current) {
                root?.style.setProperty(
                    "--selected-gallery-height", `${targetRef.current.offsetWidth}px`
                );
            }
        }
    }
    useLayoutEffect(() => {
        updateHeight()
        window.addEventListener("resize", () => {
            setTimeout(() => {
                updateHeight()            
            }, 1000);
        }, false);
    },[]);
    
    useEffect(() => {
        setTimeout(() => {
            updateHeight()            
        }, 1000);
        updateHeight()
    },[leftMenuCollapse]);
    return (
        < Fragment >
            <div className={`frame-selection-sm ${frameCollapse ? 'active' : ''} ${props?.frameAssetNew?.asset ? '' : 'nft-b-select'}`}>
                <div className="selection-head flex " >
                    <div>
                        Gallery ({currentSelectedNfts?.length})
                    </div>

                </div>

                <div className={`selection-continer`}>
                    <div className={`selection-frames scroller ${props?.frameAssetNew?.asset ? '' : 'active'}`}>
                        {filterNft?.map((selectedNfts, i) =>
                            <div key={(selectedNfts?.asset + (selectedNfts?.frameId ?? -1))} ref={targetRef}>
                                <div className={`${selectedNfts?.asset === props?.frameAssetNew?.asset ? 'active' : ''} py-1 frame-sm-card`} data-network={selectedNfts?.networkType} data-id={selectedNfts?.asset}
                                    onClick={onImageClick}>
                                    <GalleryImages
                                        frameName={`frame${i}`}
                                        clearCache={false}
                                        addedNfts={false}
                                        fileName={`file${i}`}
                                        galleryNew={selectedNfts}
                                        src={selectedNfts?.nftcdnimage256}
                                        setShowName={setShowName}
                                        onScreenCaptureNfts={props?.onScreenCaptureNfts}
                                        className="nft-gallery-card-image"
                                        
                                        />
                                    {!showName && <div className="flex absolute nft-selected-name hidden-mb">
                                        <span className={`name name-sm`}>
                                            {selectedNfts?.name ? selectedNfts?.name : 'No Name'}
                                        </span>

                                    </div>}
                                </div>
                            </div>

                        )}
                    </div>
                </div>

            </div>
            {
                props?.frameAssetNew?.asset ?

                    <Fragment>
                        {screenSize < 551 ?
                            <FramePopup onClose={onClosePopup} show={showPopup} nftGalleryNew={true} nftFrames={true} gallery={true} galleryNew={imagesFrame} src={imagesFrame?.image} frameClass="gallery-frame-size" />
                            : <div className='relative'>
                                <SkeltonCard show={loaderPreview} noAbsolute={false} height="100%" inline={true}
                                    containerClassName="absolute top-0 bottom-0 left-0 right-0 w-full h-full flex frame-spinner justify-center items-center z-9" />
                                <div className={loaderPreview ? 'opacity-0' : 'opacity-100'}> 
                                    <GalleryImages 
                                        key={imagesFrame?.nftcdnimage512}
                                        setLoaderPreview={setLoaderPreview} className="banner-img-main w-full" 
                                        isDetailedView={true} nftGalleryNew={true} nftFrames={true} gallery={true} 
                                        galleryNew={imagesFrame} src={imagesFrame?.nftcdnimage512} frameClass="gallery-frame-size" frameName={'details'} 
                                    />
                                </div>
                            </div>}
                        <div className="flex flex-col nftdrop-options frames-listing-gallery items-start ">
                            {/* <div className='flex justify-between buy-wrapper mb-[1.25rem] w-full'>
                                <a className='' href={`/frame-shop?previewNft=${props?.frameAssetNew?.asset}`} target="_blank" rel="noreferrer">
                                    <div className='text-[1rem]'>Add Frame</div>
                                </a>
                                <div className='frame-title cursor-pointer flex justify-end items-center'>
                                    <img alt="NoImg" src={ReloadIcon} onClick={onReload} />
                                </div>
                            </div> */}
                            <Fragment>
                                <div className="grid !grid-cols-4 w-full relative scroller cursor-pointer side-scroller" >
                                    <div className={`frame-display aspect-square  ${!props?.frameAssetNew?.frameId ? "selected" : ""} no-frame-display`} data-index={null} onClick={onRemoveHandler}>
                                        <div className="w-full h-full flex flex-col">
                                            <div className={`no-frame-text h-full flex justify-center items-center`}>
                                            </div>
                                        </div>
                                    </div>
                                    <SkeltonCard show={spinnerShow} height="100%" count={1} noAbsolute={true} inline={true} containerClassName={`aspect-square`} />

                                    {!spinnerShow && filterFreeFrames?.length >= 1 &&
                                        filterFreeFrames?.map((el, index) =>
                                            <div key={index} data-ind={index} data-type='free' onClick={props?.frameAssetNew?.frameId === el?.id ? ()=> {} : frameSelectedHandler} className={`${props?.frameAssetNew?.frameId === el?.id ? 'add' : ''} frame-bord border-none frame-selection-section`}>
                                                <GalleryImages showZoom={`${screenSize < 551 ? true : false}`} actionImage={`${screenSize < 551 ? zoomIcon : false}`} onClick={onClick} frameDetailsNew={props?.frameDetailsNew} nftFrames={true} gallery={true} src={el.isLandscape ? (el?.s3ImageUrlLandscape256 ?? el?.s3ImageUrl256) : el?.s3ImageUrl256} className="gallery-preview-image" />
                                            </div>
                                        )}
                                    {!spinnerShow && filterNftFrames?.length >= 1 &&
                                        filterNftFrames?.map((el, index) =>
                                            <div key={index} data-ind={index} data-type='nft' onClick={props?.frameAssetNew?.frameId === el?.id ? ()=> {} : frameSelectedHandler} className={`${props?.frameAssetNew?.frameId === el?.id ? 'add' : ''} frame-bord border-none frame-selection-section`}>
                                                <GalleryImages showZoom={`${screenSize < 551 ? true : false}`} actionImage={`${screenSize < 551 ? zoomIcon : false}`} frameDetailsNew={props?.frameDetailsNew} nftFrames={true} gallery={true} src={el.isLandscape ? (el?.s3ImageUrlLandscape ?? el?.s3ImageUrl) : el?.s3ImageUrl} className="gallery-preview-image " />
                                            </div>
                                        )

                                    }
                                </div> </Fragment>
                        </div>
                    </Fragment >
                    :
                    <div className='pt-3 nftdrop-options nft-b-select text-xs'>No items selected</div>
            }

        </Fragment >
    )
}

export default NFTFrameGallery