import styles from "./InformationDesk.module.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import useHttp from "App/hooks/use-http";
import InformationCard from "./Components/InformationCard";
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import { useStore } from "App/hooks-store/store";
import twitterIcon from "Assets/Images/TwitterUser.svg";
import youtubeIcon from "Assets/Images/Youtube.svg";
import discordIcon from "Assets/Images/Discord.svg";
import useScreenSize from "App/hooks/use-screen-size";
import Banner from "App/Components/Banner";
import { NavLink, useLocation } from "react-router-dom";
import PartnersComponent from "./Components/Partners";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";

const InformationDesk = () => {
  const screenSize = useScreenSize()?.width;
  const [categoryList, setCategoryList] = useState([]);
  const [termsUrl, setTermsUrl] = useState("");
  const [policyUrl, setPolicyUrl] = useState("");
  const [{ topActiveMenu }, dispatch] = useStore();
  const { triggerAPI } = useHttp();
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [spinnerShow, setSpinnerShow] = useState(false);
  const tabs = useMemo(() => {
    let tab = [
      { id: 0, element: "Tutorials" },
      { id: 1, element: "User Guide" },
      { id: 2, element: "Partners" },
      { id: 3, element: "Terms of Use" },
      { id: 4, element: "Privacy Policy" },
    ];
    if (isPortrait && screenSize <= 550) {
      tab.shift();
      tab.push({ id: 5, element: "Contact" });
    }
    return tab;
  }, [isPortrait, screenSize]);
  const [tabSelected, setTabSelected] = useState(0);
  const [type, setType] = useState(1);
  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      setTimeout(() => {
        dispatch("setActiveMenuOptions", Number(state));
        setTabSelected(Number(state));
      }, 200);
    }
  }, [state]);
  const getInformationDeskResult = useCallback((res) => {
    setSpinnerShow(false);
    const categoryList = res.data.list;
    setCategoryList(categoryList);
  }, []);

  const getInformationDesk = useCallback(() => {
    setSpinnerShow(true);
    const params = {
      filter: { sortBy: "name", orderBy: "ASC" },
    };

    triggerAPI(
      {
        url: `information-desk/user/category/list`,
        method: "post",
        data: params,
      },
      getInformationDeskResult
    );
  }, [getInformationDeskResult, triggerAPI]);

  useEffect(() => {
    getInformationDesk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTermsResponse = useCallback((res) => {
    const { data } = res;
    setTermsUrl(data?.fileUrl ?? data?.link);
  }, []);
  const getTerms = () => {
    triggerAPI(
      {
        url: `terms-conditions/get-active/1`,
        method: "get",
      },
      getTermsResponse
    );
  };
  const getPolicyResponse = useCallback((res) => {
    const { data } = res;
    setPolicyUrl(data?.fileUrl ?? data?.link);
  }, []);
  const getPolicy = () => {
    triggerAPI(
      {
        url: `terms-conditions/get-active/2`,
        method: "get",
      },
      getPolicyResponse
    );
  };
  useEffect(() => {
    getTerms();
    getPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Menu options
  useEffect(() => {
    if (isPortrait && screenSize <= 550) {
      dispatch("setInfoDeskIcons", false);
      dispatch("setActiveMenuOptions", 1);
    } else {
      dispatch("setTopMenuBarOptions", {
        showMenuIcons: false,
        showTopMenus: true,
      });
      dispatch("setMenuOptions", tabs);
      dispatch("setActiveMenuOptions", 0);
      dispatch("setInfoDeskIcons", false);
    }
    return () => {
      dispatch("resetAll");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPortrait, screenSize, tabs]);

  //Menu options
  useEffect(() => {
    setTabSelected(topActiveMenu);
    if (topActiveMenu !== 0) {
      setType(topActiveMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topActiveMenu]);
  const handleTabMob = (e) => {
    dispatch("setActiveMenuOptions", parseInt(e.currentTarget?.dataset?.id));
  };
  return (
    <div className={`${styles["info-list-wrp"]} page-shadow`}>
      <div
        className={`sm:hidden inline-block creator-profile-banner min-h-[1.43rem]`}
      >
        <Banner className="h-full ">
          <div className="flex h-full ">
            <div className={`mobile-head flex  justify-start items-center`}>
              {tabs.map((elem) => (
                <div key={elem.id}>
                  <NavLink
                    className={`w-full p-2.5 pb-0 nav-link ${
                      tabSelected === elem?.id ? "linkActive" : ""
                    }`}
                    onClick={handleTabMob}
                    data-id={elem.id}
                  >
                    {elem.element}
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        </Banner>
      </div>
      {tabSelected === 0 && (
        <div
          className={`w-full ${styles["info-desk-mob"]} pt-4 py-2 pl-4 pr-4 ${
            !spinnerShow ? "overflow-flex-auto" : ""
          } flex flex-col justify-between`}
        >
          {spinnerShow ? (
                     <DetailsSkeltonCard
                     show={true}
                     height={"100%"}
                     count={18}
                     inline={true}
                     details={"1-line"}
                     noAbsolute
                     parentDiv="grid home-grid mt-0 aspect-pt-six"
                     containerClassName={`flex`}                 
                 /> 
            // <SkeletonLoader
            //   height="15rem"
            //   count={5}
            //   inline={true}
            //   containerClassName="leading-none  h-full  grid gap-2.5 home-grid"
            // />
          ) : (
            <div
              className={`sm:pt-0 pt-4 cursor-pointer grid gap-2.5 home-grid ${styles["info-desk-home-grid"]}`}
            >
              {categoryList?.map((info) => (
                <InformationCard key={info.id} categoryInfo={info} />
              ))}
            </div>
          )}
          {!categoryList?.length && !spinnerShow && (
            <div className="flex justify-center items-start h-full pt-2 w-full">
              No data found!!
            </div>
          )}
        </div>
      )}
      {(tabSelected === 1 || tabSelected === 3 || tabSelected === 4) && (
        <div className={`sm:pt-4 pt-[2.37rem]  p-4 h-full w-full`}>
          <div
            className={`${styles["terms"]} ${
              screenSize < 551 ? "p-4" : ""
            }  overflow-auto`}
          >
            {type === 1 &&
              (termsUrl ? (
                <iframe
                  className={`${
                    screenSize < 551 ? "pt-[1.25rem] pb-[1.25rem]" : ""
                  }`}
                  src={termsUrl.replace("/legal/terms-of-use", "")}
                  width="100%"
                  title="Terms and Conditions"
                  height="100%"
                ></iframe>
              ) : (
                <div className="flex pt-4 items-center w-full">
                  No Active {tabSelected} Added
                </div>
              ))}
            {type === 3 &&
              (termsUrl ? (
                <iframe
                  className={`${
                    screenSize < 551 ? "pt-[1.25rem] pb-[1.25rem]" : ""
                  }`}
                  src={termsUrl}
                  width="100%"
                  title="Terms and Conditions"
                  height="100%"
                ></iframe>
              ) : (
                <div className="flex pt-4 items-center w-full">
                  No Active {tabSelected} Added
                </div>
              ))}
            {type === 4 &&
              (policyUrl ? (
                <iframe
                  className={`${
                    screenSize < 551 ? "pt-[1.25rem] pb-[1.25rem]" : ""
                  }`}
                  src={policyUrl}
                  width="100%"
                  title="Terms and Conditions"
                  height="100%"
                ></iframe>
              ) : (
                <div className="flex pt-4 items-center w-full">
                  No Active {tabSelected} Added
                </div>
              ))}
          </div>
        </div>
      )}
      {tabSelected === 2 && (
        <div className={`${styles[""]}`}>
          <PartnersComponent />
        </div>
      )}

      {tabSelected === 5 && (
        <div
          className={`${styles["contact-container"]} flex flex-col m-4 gap-4`}
        >
          <div className={`${styles["info-container"]} flex p-[1.15rem]`}>
            <div className="flex justify-between items-center">
              <div className={`${styles["info-name"]}`}>
                <span>@CurateOfficial</span>
              </div>
              <div className="flex gap-2 items-center">
                <img src={twitterIcon} alt="icon" />
              </div>
            </div>
            <div className={`${styles["info-social-btn"]}`}>
              <a
                href={`https://twitter.com/Cur8Labs`}
                target={"_blank"}
                rel="noreferrer"
              >
                Follow
              </a>
            </div>
          </div>
          <div className={`${styles["info-container"]} flex p-[1.15rem]`}>
            <div className="flex justify-between items-center">
              <div className={`${styles["info-name"]}`}>
                <span>@CurateChannel</span>
              </div>
              <div className="flex gap-2 items-center">
                <img src={youtubeIcon} alt="icon" />
              </div>
            </div>
            <div className={`${styles["info-social-btn"]}`}>
              <a
                href={`https://www.youtube.com/@cur8galleries`}
                target={"_blank"}
                rel="noreferrer"
              >
                Follow
              </a>
            </div>
          </div>
          <div className={`${styles["info-container"]} flex p-[1.15rem]`}>
            <div className="flex justify-between items-center">
              <div className={`${styles["info-name"]}`}>
                <span>@Cur8</span>
              </div>
              <div className="flex gap-2 items-center">
                <img src={discordIcon} alt="icon" />
              </div>
            </div>
            <div className={`${styles["info-social-btn"]}`}>
              <a
                href={`https://discord.com/invite/vHqfQ5vPFd`}
                target={"_blank"}
                rel="noreferrer"
              >
                Follow
              </a>
            </div>
          </div>
          <div className={`${styles["info-container"]} flex p-[1.15rem]`}>
            <div className="flex justify-center items-center">
              <div className={`${styles["info-name"]}`}>
                <span>Contact Us</span>
              </div>
            </div>
            <div className={`${styles["info-social-btn"]}`}>Senda Message</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InformationDesk;