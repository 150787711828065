import TileView from "App/Components/Ui/TileView/indexV2";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MyCollectionTileView = ({collections, filteredList, nftclick, walletId, weighting, basePathType}) => {
    const d3 = require('d3');
    const [tileData, setTileData] = useState([]);
    const format = d3.format(",d");
    let navigate = useNavigate();
    const tooltipHtml = useCallback((d) => {
      let html = d?.data?.name;
      if (d?.data?.count)
      {
        html += `<p>
              ${format(d?.data?.count)} Piece${Number(format(d?.data?.count))>1?"s":""}<p>
              Total Floor Value ${d?.data?.nftsvalue} &#8371;<p>
              Recent Volume ${d?.data?.nftsvolume} &#8371;`;
      } 
      return html;              
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const arrangeData = useCallback(() => {
      let data = [];    
      let categoryName = '';    
      let category = null;
      let subCategoryName = '';    
      let subCategory = null;
      let collectionName = '';
      let collection = null;
      let count = 0;
      let nftsvalue = 0;
      let nftsvolume = 0;
          
      //merge collection array with collectiondata to ensure counts are correct
      let matchingArray = [];
      filteredList.forEach(item=> {
        collections?.filter(ac=> ac.policy === item.policy && (ac.walletId === Number(walletId) || (walletId??null)==null)).forEach(match => {    
          matchingArray = [...matchingArray, match];
        });      
      })    
      //sort data from top to bottom of tile hierarchy, category-subcategory-collection-nft
      let sortedcols = matchingArray.sort((a,b)=> {
        if ((a.category.name??'Unclassified') < (b.category.name??'Unclassified')) {
          return -1;
        } else if ((a.category.name??'Unclassified') > (b.category.name??'Unclassified')) {
          return 1;
        } else if ((a.subCategoryName??'Unclassified') < (b.subCategoryName??'Unclassified')) {
          return -1;
        } else if ((a.subCategoryName??'Unclassified') > (b.subCategoryName??'Unclassified')) {
          return 1;
        } else if (a.collectionName < b.collectionName) {
          return -1;
        } else {
          return 1;        
        }      
      });
      if (weighting === "Total Floor Value") {
        sortedcols = sortedcols.filter(c => c.metrics?.[0]?.floorPrice > 0);
      }
      sortedcols.forEach((item, index) => {
        if((item.category.name ?? 'Unclassified') !== categoryName)  {
          categoryName = item.category.name ?? 'Unclassified';
          let arr = [...sortedcols].filter(s => (s.category.name ?? 'Unclassified') === categoryName);        
          let mostpopular = arr.sort((a,b) => arr.filter(v => v.collectionName === a.collectionName).length - arr.filter(v => v.collectionName === b.collectionName).length).pop();        
          mostpopular = sortedcols.filter(c=>c.policy === mostpopular.policy)?.[0];
          category = { name: categoryName, children: [], count:0, nftsvalue:0, nftsvolume: 0, image256: mostpopular?.nftcdnimage256, image512: mostpopular?.nftcdnimage512, image1024: mostpopular?.nftcdnimage1024 }          
          data = [...data, category]    
          subCategoryName = '';    
          subCategory = null;
        }
        if ((item.subCategoryName??'Unclassified') !== subCategoryName) {
          subCategoryName = item.subCategoryName ?? 'Unclassified';
          let arr = [...sortedcols].filter(s=>(s.subCategoryName ?? 'Unclassified') === subCategoryName);        
          let mostpopular = arr.sort((a,b) => arr.filter(v => v.collectionName === a.collectionName).length - arr.filter(v => v.collectionName === b.collectionName).length).pop();        
          mostpopular = sortedcols.filter(c=>c.policy === mostpopular.policy)?.[0];
          subCategory = { name: subCategoryName, children: [], count:0, nftsvalue:0, nftsvolume:0, image256:mostpopular.nftcdnimage256, image512:mostpopular.nftcdnimage512, image1024:mostpopular.nftcdnimage1024 }
          category.children.push(subCategory);
          collectionName = '';
          collection = null;
        }
        if (subCategory && item.collectionName !== collectionName)  {
          collectionName = item.collectionName;        
          count = sortedcols.filter(c => c.policy === item.policy).length;
          nftsvalue = parseInt((item.metrics?.[0]?.floorPrice) ?? 0);       
                  
          if (!((parseInt((item.metrics?.[0]?.allVolume)??0)) > nftsvalue*10)) {
            nftsvalue = 0; //total volume needs to be at least 10x floor price for the floor to be valid
          } else {
            nftsvalue *= count; //multiply by number of nfts owned
          }

          nftsvolume = parseInt((item.metrics?.[0]?.dailyVolume) ?? 0); 
          collection = { 
            name: item.collectionName,           
            image256:item.nftcdnimage256, 
            image512:item.nftcdnimage512,
            image1024: item.nftcdnimage1024,
            count: count,
            nftsvalue: nftsvalue, 
            nftsvolume: nftsvolume, 
            children: [] 
          };
          
          if (basePathType==='listen')
          {
            collection.link = `/listen/collections/my-collections/${item?.policy}`;
            collection.value = weighting === "Total Floor Value"?nftsvalue:weighting === "Recent Volume"?nftsvolume:count;
          }
          subCategory.children.push(collection);
          
          category.count += count;
          subCategory.count += count;
          category.nftsvalue += nftsvalue;        
          subCategory.nftsvalue += nftsvalue;
          category.nftsvolume += nftsvolume;
          subCategory.nftsvolume+=nftsvolume;
        }
        if (collection && !collection.link) {
          let weight = 1;
          if (weighting === "Total Floor Value")
          {
            weight = nftsvalue/count;
          }           
          else if (weighting === "Recent Volume")
          {
            weight = nftsvolume;
          }
          let nft = {
            name: item.name,
            image256:item.nftcdnimage256, 
            image512:item.nftcdnimage512,
            image1024: item.nftcdnimage1024,
            asset: item.asset,  
            value: weight,
            type: 'nft',
            children: [] };
          
          collection.children.push(nft);                        
        }
      })
      
      if (weighting === "Total Floor Value" || weighting === "Recent Volume") {
        let propToCheck = weighting === "Total Floor Value"?"nftsvalue":"nftsvolume";
  
        for (let i = 0; i < data.length; i++) {
          const classification = data[i];
          if (classification[propToCheck] === 0) {
            data.splice(i,1);
            i--;
          } else {          
            for (let j = 0; j < classification.children.length; j++) {
              const subclassification = classification.children[j];
              if (subclassification[propToCheck] === 0) {
                classification.children.splice(j,1);
                j--;
              } else {
                for (let k = 0; k < subclassification.children.length; k++) {
                  const nft = subclassification.children[k];
                  if (nft[propToCheck] === 0)
                  {
                    subclassification.children.splice(k,1);
                    k--;
                  }
                }
  
                //set images via weighting
                let mostpopular = [...subclassification.children].sort((a,b)=>a[propToCheck] > b[propToCheck]? 1 : -1).pop();               
                subclassification.image256 = mostpopular.image256;                   
                subclassification.image512 = mostpopular.image512;
                subclassification.image1024 = mostpopular.image1024; 
                let count = 0;
                let nftsvalue = 0;
                let nftsvolume = 0;
                subclassification.children.forEach(c => { count+=c.count;nftsvalue+=c.nftsvalue;nftsvolume+=c.nftsvolume });                           
                subclassification.count = count;
                subclassification.nftsvalue = nftsvalue;
                subclassification.nftsvolume = nftsvolume;
              }            
            }
  
            //set images via weighting
            let mostpopular = [...classification.children].sort((a,b)=>a[propToCheck]>b[propToCheck]?1:-1).pop();               
            classification.image256 = mostpopular.image256;                   
            classification.image512 = mostpopular.image512;
            classification.image1024 = mostpopular.image1024;
            let count = 0;
            let nftsvalue = 0;
            let nftsvolume = 0;
            classification.children.forEach(sc => { sc.children.forEach(c => {count+=c.count;nftsvalue+=c.nftsvalue;nftsvolume+=c.nftsvolume})});      
            classification.count = count;
            classification.nftsvalue = nftsvalue;
            classification.nftsvolume = nftsvolume;
          }
        }
      }    
      data = { 
          name: "My Collection", //root name
          children: data
      };  
      setTileData(data);
    }, [collections, filteredList, walletId, weighting, basePathType]);
      
    useEffect(() => {
      arrangeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collections, filteredList, walletId, weighting])

    const tileclick = useCallback((event) => {
      if (event.data.link) {
        navigate(event.data.link)
      }
      else if (event.data.type === "nft") {                              
        nftclick(event.data)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <TileView data={tileData} tileclick={tileclick} tooltipHtml={tooltipHtml}/>
    )
}

export default MyCollectionTileView