import './CustomizedTable.scss';
import sortArrow from 'Assets/Images/sort-arrow.svg';

const CustomizedTable = (props) => {
    return (
        <div className={props?.mainContainerClass}>
            {props?.tableHead?.length ? <div className={`${props?.tableHeadClass}`}>
                <table cellPadding="0" cellSpacing="0" border="0" className={`customizedTable`}>
                    <thead className={`tbl-header`}>
                        <tr>
                            {props?.tableHead.map((tableHead, i) =>
                                <th key={i} className={`${tableHead?.isSort ? 'cursor-pointer' : ''}`}
                                    onClick={(e) => {
                                        if (tableHead?.isSort && props?.onClick) {
                                            props?.onClick(e, tableHead?.name)
                                        }
                                    }} data-sortby={tableHead?.sortName}>
                                    <div className='flex items-center gap-2'>
                                        <span className='capitalize'>{tableHead?.name}</span>
                                        {tableHead?.isSort &&
                                            <span>
                                                <img className={`${(props?.activeSort?.field === tableHead?.name) ? `${props?.activeSort?.flip && 'flip-icon'} opacity-100` : 'opacity-50'}`} alt='icon' src={sortArrow} />
                                            </span>
                                        }
                                    </div>
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody className='tbl-content'>
                        {props.children}
                    </tbody>
                </table>
            </div> : null}
        </div>
    )
}

export default CustomizedTable