/* eslint-disable react-hooks/exhaustive-deps */
import ListingCollection from "App/Pages/CommunityCollection/Collections/Components/ListingCollection";
import DiscoverTopPanel from "App/Pages/Discover/Components/SubComponents/DiscoverTopPanel";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import './OverView.scss';
import CommunityGallery from "../../../CommunityGallery";
import Announcements from "../../../Announcements";
import Events from "../../../Events";
import DiscoverTopPanelMob from "App/Pages/Discover/Components/SubComponents/DiscoverTopPanelMob";
import useScreenSize from "App/hooks/use-screen-size";
import DetailsInfo from "App/Pages/Discover/Components/SubComponents/Details";
import OverView from "App/Pages/Discover/Components/SubComponents/OverView";
import useCollectionLoader from "App/hooks/use-collectionLoader";
import AboutTab from "App/Pages/Discover/Components/Creators/Components/AboutTab";
import ModalOuter from "App/Components/Ui/ModalOuter";
import CommunityUpdate from "../Details";
import closeIcon from "Assets/Icons/close.svg"
import { setCurrentFormValues, setFormInitiated } from "App/ReduxStore/Slicers/gallerySlice";
import CollectionUpdate from "App/Pages/CommunityCollection/Collections/Components/UpdateCollection";
import { useDispatch } from "react-redux";
import CreatePostModal from "../../../Announcements/Components/CreatePostModal";
import { capitalizeFirstLetter } from "App/Helper/utilities";
import CreateEventModal from "../../../Events/Components/CreateEventModal";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";

const CreatorCommunityDetail = () => {
  const location = useLocation();
    const [menuTabs,setMenuTabs] = useState([
        { id: "overview", element: 'Overview',count:0 },   { id: "collection", element: 'Collections',count:0 }, { id: "my-galleries", element: 'Galleries',count:0 }, 
        { id: "posts", element: 'Posts', count:0 }, { id: "events", element: 'Events', count:0 }, {id: "about", element: 'About', count:0 },
    ])
    const screenSize = useScreenSize()?.width;
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    const navigate = useNavigate()
    const { communityid,tab, galleryId,collectionId } = useParams();
    const [{ activeMenuIcons,currentUserId}, dispatch] = useStore();
    const { triggerAPI } = useHttp();
    const [detailsInfo, setDetailsInfo] = useState({});
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [communityData, setCommunityData] = useState(null);
    const [createEveAnn, setCreateEveAnn] = useState(false);
    const [refetchGallery, setRefetchGallery] = useState(true)
    const [galleryData, setGalleryData] = useState(null)
    const [collectionData, setCollectionData] = useState(null)
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [announcementList, setAnnouncementList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const { sliceCollectionAPI } = useCollectionLoader();
    const [totalCollectionCount, setTotalCollectionCount] =useState(null)
    const [totalGalleryCount, setTotalGalleryCount] =useState(null)
    const [reloadCount, setReloadCount] = useState(0)
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopupForCollection, setOpenPopupForCollection] = useState(false);
    const [refetchCollections, setRefetchCollections] = useState(false)
    const [collectionLoader, setCollectionLoader] = useState(false);
    const [add, setAdd] = useState(false);
    const [subjects, setSubjects] = useState([])
    const [selectedData, setSelectedData] = useState({});
    const [addEvent, setAddEvent] = useState(false);
    const [postLoader, setPostLoader] = useState(true);
    const [galleryLoader, setGalleryLoader] = useState(true);
    const [eventLoader, setEventLoader] = useState(true);
    const [subjectsEvents, setSubjectsEvents] = useState([])
    const [selectedEventData, setSelectedEventData] = useState({});
    const dispatchRedux = useDispatch();
    const paginationConfig = {
        perPage: screenSize > 550 ? 10 : 50,
      };
    const handleTabs = (e) => {
        const selectTab = e.currentTarget?.dataset?.id;
        navigate(`/community/${communityid}/${selectTab}`,{replace:true})
        const root = document.getElementById('top');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

    }
    const getDetailedCollection = useCallback((cId) => {   
     // dispatch('showSpinner')  
      if(collectionId || cId) 
      { 
        const params = new URLSearchParams({
          collectionId
        });        
          triggerAPI(
          {
            url: `community/collection/get-one?${params}`,            
            method: "get",
          },
          (res) => {    
          //  dispatch('hideSpinner')
            if(res?.data) {
              let aspectRatio=res?.data?.shapes?.map(el=>el?.aspectRatio?.toString()).join(",")
              setSelectedCollection({...res.data,aspectRatio}) 
            } else {
              navigate(`/community/${communityid}/collection`)
            }
          }      
        );
      }
    }, [dispatch,collectionId, triggerAPI]);  
    useEffect(() => {
      if((collectionId && tab === 'collection')|| (!communityid && !!collectionId)) 
        getDetailedCollection(collectionId);
       else
        setSelectedCollection(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [collectionId]);
    useEffect(()=>{
      if(communityid) {
        getCollectionList()
        getGalleryList()
      }
    },[communityid,currentUserId])
    useEffect(() => {
        // dispatch('resetCommunityCollections');
        setDetailsInfo({})
        dispatch('setTopMenuBarOptions', {showMenuIcons: true, showTopMenus: false})
        if(screenSize > 550) {             
            dispatch('setActiveMenuOptions', 0);        
        }
        dispatch("setActiveIcons", { back: false });
        dispatch('enableTopIcons', {back: true})
        return () => {
            dispatch('resetAll');
        }      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[screenSize, location?.pathname])
    useEffect(() => {
        if(activeMenuIcons?.back) {
            if(detailsInfo?.id){
                setDetailsInfo({})
              } else{
            dispatch("setActiveIcons", { back: false });
            navigate(-1)
            }
            dispatch("setActiveIcons", { back: false });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeMenuIcons?.back])
    const getDetailedCommunityResult = useCallback((res) => {
       // dispatch('hideSpinner');
        const { data } = res;
        const temp = {
            bannerPicUrl: data?.bannerPicture,
            profilePicUrl: data?.displayPicture,
            name: data?.name,
            twitter: data?.twitter,
            discord: data?.discord,
            youtube: data?.youtube,
            description: data?.description,
            id:data?.id,
            createdAt:data?.createdAt,
            user: data?.user,
            isFollowing: data?.isFollowing,
        }
        setCommunityData(temp);
        setOpenPopup(false);
        setOpenPopupForCollection(false);
    }, [dispatch])
    const getDetailedCommunity = useCallback(() => {
       // dispatch('showSpinner')
        triggerAPI({
            url: `community/get/${communityid}`, method: 'get'
        }, getDetailedCommunityResult);
    }, [dispatch, getDetailedCommunityResult, triggerAPI, communityid])
    useEffect(() => {
      if(communityid)  getDetailedCommunity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[communityid])

    const onFetch=() => {
      getCollectionList()
      setOpenPopupForCollection(false);
      navigate(`/community/${communityid}/${tab}`)
      setTimeout(()=> setRefetchCollections((prev)=>!prev),500);
  }

  const toCollection = (e,cid) => {
    e && e.stopPropagation()
    if(!communityData||collectionLoader) return
    setOpenPopupForCollection(true);
    if(!cid) return;
    const url = `${location?.pathname}?id=${cid}`;
    const navigateParam = { state: { pageType: 'view' } };
    navigate(url, navigateParam)
  }
    const editClick = () => {
        setOpenPopup(true)
        // navigate(`${location?.pathname}?communityid=${communityid}`)  
    }
    const onClosePopup = ()=> {
      setOpenPopupForCollection(false);
      setOpenPopup(false);
    //  const path = location?.pathname?.split("?")[0]
    //   navigate(path)   
    }
    const onClosePopupCollection = ()=> {
      setOpenPopupForCollection(false);
     const path = location?.pathname?.split("?")[0]
      navigate(path)   
    }
    const onDetailsInfoHandler=(info)=>setDetailsInfo(info)
    const getCollectionListResult = useCallback((res) => {
      setCollectionLoader(false)
        const { data } = res;
        setMenuTabs((prev)=>prev.map(el=>{
            if(el.id==="collection"){
                return {...el,count:data?.pageMeta.totalItems}
            }
            return el
        }))
        setTotalCollectionCount(data?.pageMeta.totalItems)
        setCollectionData(data)
    }, [])
    const getCollectionList = useCallback(() => {
        let filterData = {}
        setCollectionLoader(true)
            filterData = { userId: currentUserId, communityId: [parseInt(communityid)] }
        triggerAPI({
            url: `community/collection/user/get`, method: 'post', data: {
                page: 1,
                items: 5,
                filter: filterData,
                search: ""
            }
        }, getCollectionListResult);
    }, [getCollectionListResult, triggerAPI, communityid,currentUserId])
    const getGalleryListResult = useCallback((res) => {
        const { data } = res;
        setGalleryLoader(false)
        //dispatch("hideSpinner");
        setMenuTabs((prev)=>prev.map(el=>{
            if(el.id==="my-galleries"){
                return {...el,count:data?.pageMeta.totalItems}
            }
            return el
        }))
        setTotalGalleryCount(data?.pageMeta.totalItems)
      setGalleryData(data)
    }, [dispatch])
    
    const getGalleryList = useCallback(() => {
       // dispatch('showSpinner')
       setGalleryLoader(true)
        triggerAPI({
            url: `community/gallery/get`, method: 'post', data: {
                page: 1,
                items: 5,
                filter: {
                  communityId: parseInt(communityid)
                },
                search: ""
              }
        }, getGalleryListResult,()=>{
          // dispatch("hideSpinner");
      });
    }, [dispatch, getGalleryListResult, triggerAPI, communityid])

    const createEveAnnHandler=()=>{
        if(isPortrait&&screenSize<=550){
            setCreateEveAnn((pre)=>!pre)
        }
    }
    const announcementListApiResult = useCallback(
        (res) => {
          //dispatch("hideSpinner");
          setPostLoader(false)
          const { list, pageMeta } = res.data;
          const updatedData = list?.slice(0,4)?.map((val) => {
            return {
              ...val,
              createdAt: new Date(val?.createdAt).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
              }),
            };
          });
          setMenuTabs((prev)=>prev.map(el=>{
            if(el.id==="posts"){
                return {...el,count: pageMeta.totalItems}
            }
            return el
        }))
        // setTotalCollectionCount(pageMeta.totalItems);
          setAnnouncementList(updatedData);
        },
        [dispatch,page]
      );
    
      const getAnnouncementList = useCallback(
        (pageNo) => {
         // dispatch("showSpinner");
         setPostLoader(true)
          triggerAPI(
            {
              url: "community/announcement/get",
              data: {
                page: pageNo ? pageNo : page,
                items: paginationConfig.perPage,
                filter: {
                  communityId: parseInt(communityid),
                },
                search: "",
              },
              method: "post",
            },
            announcementListApiResult
          );
        },
        [dispatch, announcementListApiResult, triggerAPI, page, communityid]
      );
    
      useEffect(() => {
        getAnnouncementList();
        getEventList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [page]);
      const eventListApiResult = useCallback(
        (res) => {
         // dispatch("hideSpinner");
         setEventLoader(false)
          const { pageMeta, upcomingEvents, pastEvents } = res.data;
          setMenuTabs((prev)=>prev.map(el=>{
            if(el.id==="events"){
                return {...el,count: upcomingEvents?.length }
            }
            return el
        }))
          setTotalPage(pageMeta.totalItems);
          setEventList({upcomingEvents, pastEvents});
        },
        [dispatch,page]
      );
    
      const getEventList = useCallback(
        (pageNo) => {
        //  dispatch("showSpinner");
        setEventLoader(true)
          triggerAPI(
            {
              url: "community/event/get",
              data: {
                page: pageNo ? pageNo : page,
                items: paginationConfig.perPage,
                filter: {
                  communityId: parseInt(communityid),
                },
                search: "",
              },
              method: "post",
            },
            eventListApiResult
          );
        },
        [page, triggerAPI, eventListApiResult, dispatch, communityid]
      );

    const collectionEditCardClick = (e,id) => {
     e && e.stopPropagation()
      if(!id) return;
      const url = `${location.pathname}?id=${id}`;
      const navigateParam = { state: { pageType: 'view' } };
      navigate(url, navigateParam)
      setOpenPopupForCollection(true);
    }
    
    // const collectionCardClickOver=(e)=>{
    //   const cid= e.currentTarget.dataset.id
    //   const url = cid ? `/community/collections/update-collection/${cid}` : `/community/collections/update-collection`;
    //   const navigateParam = { state: { pageType: 'view' }};
    //   if(!cid) 
    //       navigateParam.state = { communityId: parseInt(communityid) }
    //   navigate(url, navigateParam)  
    // }
    


  const handlePreviewGallery = (id) => {
    navigate(`/community/${communityid}/my-galleries/view/${id}`)
  };

  const handlePreviewGalleryDetails = (gallery) => {
    if (isPortrait && screenSize <= 550) {
      window.scrollTo(0, 0);
    }
    navigate(`/community/${communityid}/my-galleries/view/${gallery?.id}`)
  }
  const handleCardClick = (e,pId) => {
    navigate(`/community/${communityid}/collection/${pId}`)
  //  setIsDetailsOpen(true);
  };
  const navToCreateGallery = () => {
    if(!communityData||galleryLoader) return
    dispatchRedux(setFormInitiated(true));
    dispatch('setCurrentCommunityGallery', {})
    dispatch('setCurrentSelectedNfts', [])
    dispatch("resetSingleCollection");
    dispatchRedux(setCurrentFormValues({}))
    dispatch('setCurrentGalleryUpdateId', { })
    dispatch('setCurrentGallery', { })
    setTimeout(() => {
      navigate(`/community/${communityid}/my-galleries/add/collection`);
    }, 500);
    // 
  }
  const getPostSubjects = useCallback(() => {
    triggerAPI(
      {
        url: `community/announcements/categories/get`,
        method: "get",
      },
      (res)=>setSubjects(res?.data)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, triggerAPI]);
  const getEventsSubjects = useCallback(() => {
    triggerAPI(
      {
        url: `community/event/categories/get`,
        method: "get",
      },
      (res)=>setSubjectsEvents(res?.data)
    );
  }, [dispatch, triggerAPI]);
  useEffect(() => {
    getAnnouncementList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getPostSubjects();
    getEventsSubjects()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const newAnnouncementHandler = () => {
    if(!communityData||postLoader) return
    if (add) {
      setSelectedData({});
      getPostSubjects();
    }
    setAdd((pre) => !pre);
    createEveAnnHandler()
  };
  const saveAnnouncementApiResult = useCallback(
    (res) => {
      dispatch("showToast", {
        toast: {
          toastMode: "success",
          message: capitalizeFirstLetter(res.message),
        },
      });
      dispatch("hideSpinner");
      newAnnouncementHandler();
      getAnnouncementList();
      setPage(1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const saveAnnouncement = useCallback(
    (data) => {
      dispatch("showSpinner");
      triggerAPI(
        {
          url: "community/announcement/create",
          data,
          method: "post",
        },
        saveAnnouncementApiResult,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message: err?.response?.data?.error?.message||"Postt creation failed",
            },
          });
        }
      );
    },
    [dispatch, saveAnnouncementApiResult, triggerAPI]
  );
  const onPostSaveHandler = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, val]) => {
      let value = Array.isArray(val) ? JSON.stringify(val) : val;
      formData.append(key, value);
    });
      formData.append("communityId", Number(communityid));
      saveAnnouncement(formData);
  };
  const newEventHandler = () => {
    if(!communityData||eventLoader) return
    if (addEvent) {
      setSelectedEventData({});
      getEventsSubjects()
    }
    setAddEvent((pre) => !pre);
  };

  const saveEventApiResult = useCallback(
    (res) => {
      dispatch("showToast", {
        toast: {
          toastMode: "success",
          message: capitalizeFirstLetter(res.message),
        },
      });
      dispatch("hideSpinner");
      newEventHandler();
      getEventList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const saveEvent = useCallback(
    (data) => {
      dispatch("showSpinner");
      triggerAPI(
        {
          url: "community/event/create",
          data,
          method: "post",
        },
        saveEventApiResult,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: { toastMode: "error", message: err?.response?.data?.error?.message||"Event creation failed" },
          });
        }
      );
    },
    [dispatch, saveEventApiResult, triggerAPI]
  );
  const onEventSaveHandler = async(data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, val]) => {
      let value = Array.isArray(val) ? JSON.stringify(val) : val;
      formData.append(key, value);
    });
      formData.append("communityId", Number(communityid));
      saveEvent(formData);
  };
    return (
      <div id="top" className="h-full flex flex-col community-details-overview overflow-auto">
        {/* Popup for Community */}
        {openPopup && (
          <ModalOuter
            onClose={() => {}}
            openModal={openPopup}
            parentClass={openPopup ? "!z-[10]" : "!hidden !z-[10]"}
            contentClass={
              "animated-slideIn w-full max-w-[1000px] h-auto max-h-[91dvh] overflow-auto p-5 bg-[var(--cu-black-700)] border border-[var(--cu-black-100)] rounded-lg mt-[5dvh] mx-[5%]"
            }
          >
            <div className="w-full flex items-center justify-between">
              <span />
              <h2 className="text-[1.125rem]">Community</h2>
              <button onClick={onClosePopup}>
                <img src={closeIcon} alt="close" />
              </button>
            </div>
            <CommunityUpdate
              onClose={onClosePopup}
              onFetch={getDetailedCommunity}
            />
          </ModalOuter>
        )}
        <CreatePostModal
          subjects={subjects}
          setSubjects={setSubjects}
          onSaveHandler={onPostSaveHandler}
          selectedData={selectedData}
          newAnnouncementHandler={newAnnouncementHandler}
          add={add}
        />
        <CreateEventModal
          subjects={subjectsEvents}
          setSubjects={setSubjectsEvents}
          selectedData={selectedEventData}
          onSaveHandler={onEventSaveHandler}
          newEventHandler={newEventHandler}
          add={addEvent}
        />
        {/* Popup for Collection */}
        {openPopupForCollection && (
          <ModalOuter
            onClose={() => {}}
            openModal={openPopupForCollection}
            parentClass={openPopupForCollection ? "!z-[10]" : "!hidden !z-[10]"}
            contentClass={
              "animated-slideIn w-full max-w-[850px] h-auto max-h-[85dvh] overflow-auto py-5 px-10 bg-[var(--cu-black-700)] border border-[var(--cu-black-100)] rounded-lg mt-[5dvh] mx-[5%]"
            }
          >
            <div className="w-full flex items-center justify-between">
              <span />
              <h2 className="text-[1.125rem]">Verify Collection</h2>
              <button onClick={onClosePopupCollection}>
                <img src={closeIcon} alt="close" />
              </button>
            </div>
            <CollectionUpdate
              communityid={communityid}
              onClose={onClosePopupCollection}
              onFetch={onFetch}
            />
          </ModalOuter>
        )}
        {!detailsInfo?.id ? (
          <>
            {!communityData ? (
              <div className="sm:flex hidden relative mb-[5.625rem]">
                <DetailsSkeltonCard
                  show={true}
                  height={"16.3125em"}
                  count={1}
                  inline={true}
                  details={"banner-profile-img"}
                  noAbsolute
                  parentDiv={"banner-profile-skeleton"}
                  containerClassName={`flex mt-0 banner-profile-skeleton-banner`}
                />
              </div>
            ) : (
              <>
                <DiscoverTopPanel
                  className="sm:flex hidden"
                  profileData={communityData}
                  fromOther={true}
                  loaderClass="mb-[5.625rem]"
                  editClick={editClick}
                  setSelectedTab={handleTabs}
                  learnMoreLink={"about"}
                  isImageTag={true}
                />
                {!createEveAnn && (
                  <DiscoverTopPanelMob
                    profileData={communityData}
                    dontCallApi={true}
                    onDetailsInfoHandler={onDetailsInfoHandler}
                    fromOther={true}
                    isImageTag={true}
                    editClick={editClick}
                    className={
                      " p-[1rem] pt-[1rem] sm:mt-0 mt-[1.5rem] sm:hidden flex"
                    }
                  />
                )}
              </>
            )}

            {/* {!!(screenSize>550)? */}
            {!createEveAnn && (
              <div id="discover-tab" className="tab-container">
                <div className="tab-list flex sm:gap-[1.42857rem] gap-[0.625rem] border-[#30333A] border-b">
                  {menuTabs?.map((menu, index) => {
                    return (
                      <div
                        onClick={handleTabs}
                        data-id={menu?.id}
                        key={menu?.id}
                        className={`tab-data ${menu?.id === tab ? "active":"not-active"}`}
                      >
                        {menu?.element}{" "}
                        {!!(menu?.id === 2) ? (
                          <span className=" inline-block">{`(${
                            parseInt(menu?.count) - parseInt(reloadCount)
                          })`}</span>
                        ) : (
                          menu?.id !== "overview" &&
                          menu?.id !== "about" && (
                            <span className=" inline-block">{`(${menu?.count})`}</span>
                          )
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* :<Border className={'right-panel-b-border mx-[1rem] w-auto'} />} */}
            <div
              id="discover-content"
              className={`${
                screenSize > 550 && "page-shadow"
              } h-full community-overview ${
                !!galleryId ? "pt-0" : "p-[1rem]"
              } ${selectedCollection && "pt-0"}`}
            >
              {tab === "collection" &&
              !(collectionId && !selectedCollection) ? (
                <ListingCollection
                  selectedCollection={selectedCollection}
                  setSelectedCollection={setSelectedCollection}
                  fromCommunity={true}
                  communityId={communityid}
                  onCardClick={handleCardClick}
                  reFetch={getCollectionList}
                  onEditClick={toCollection}
                  refetch={refetchCollections}
                />
              ) : (
                <></>
              )}
              {tab === "my-galleries" && (
                <CommunityGallery
                  onMenuTabs={setMenuTabs}
                  setReloadCount={setReloadCount}
                  refetchGallery={refetchGallery}
                  setRefetchGallery={setRefetchGallery}
                  reFetch={getGalleryList}
                  noVerifiedCollectionForCommunity={!totalCollectionCount}
                  navToCreateGallery={navToCreateGallery}
                  onPreviewGalleryDetails={handlePreviewGalleryDetails}
                />
              )}
              {tab === "posts" && (
                <Announcements
                  createEveAnnHandler={createEveAnnHandler}
                  callBackApi={getAnnouncementList}
                />
              )}
              {tab === "events" && (
                <Events
                  type="events"
                  createEveAnnHandler={createEveAnnHandler}
                  callBackApi={getEventList}
                />
              )}
              {tab === "overview" && (
                <OverView
                  handleTabs={handleTabs}
                  galleryList={galleryData?.list}
                  totalGalleryCount={totalGalleryCount}
                  handlePreviewGallery={handlePreviewGallery}
                  perPage={4}
                  collectionList={collectionData?.list}
                  totalCollectionCount={totalCollectionCount}
                  communityOverview={true}
                  collectionLoader={collectionLoader}
                  collectionEditCardClick={collectionEditCardClick}
                  announcementList={announcementList}
                  collectionCardClick={handleCardClick}
                  communityData={communityData}
                  reloadCount={reloadCount}
                  getAnnouncementList={getAnnouncementList}
                  getEventList={getEventList}
                  createEveAnnHandler={createEveAnnHandler}
                  eventsList={eventList?.upcomingEvents?.slice(0, 4)}
                  onMenuTabs={setMenuTabs}
                  setReloadCount={setReloadCount}
                  getCollectionList={getCollectionList}
                  refetchGallery={refetchGallery}
                  getGalleryList={getGalleryList}
                  setRefetchGallery={setRefetchGallery}
                  onPreviewGalleryDetails={handlePreviewGalleryDetails}
                  page={"community"}
                  createCollection={toCollection}
                  navToCreateGallery={navToCreateGallery}
                  newAnnouncementHandler={newAnnouncementHandler}
                  newEventHandler={newEventHandler}
                  postLoader={postLoader}
                  eventLoader={eventLoader}
                  galleryLoader={galleryLoader}
                />
              )}
              {tab === "about" && <AboutTab communityData={communityData} className="px-0 py-0" />}
            </div>
          </>
        ) : (
          <DetailsInfo className="sm:mt-0 mt-[1.3rem]" data={detailsInfo} />
        )}
      </div>
    );
}
export default CreatorCommunityDetail;