import React, { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import styles from "./InformationDetails.module.scss";
import { useStore } from "App/hooks-store/store"
import useHttp from "App/hooks/use-http"
import InformationSlider from "./InformationSlider";
import SelectedInformation from "./SelectedInformation";
import ArrowRight from 'Assets/Icons/arrow_right.svg';
import ArrowLeft from 'Assets/Icons/arrow_left.svg';


const InformationDetails = () => {
    const navigate = useNavigate();
    const timer = useRef(null);
    const backHandler = () => {
        navigate('/information-desk')
    }
    const [navBar, setNavBar] = useState(false);
    const { id } = useParams();
    const [categoryData, setCategoryData] = useState([]);
    const [dataLen, setDataLen] = useState(0);
    const [nextArrow, setNextArrow] = useState(1);
    const [prevArrow, setPrevArrow] = useState(-1);
    const [activeSlide, setActiveSlide] = useState(0);
    const [currentlySelectedInfo, setCurrentlySelectedInfo] = useState();
    const { triggerAPI } = useHttp();
    const [{activeMenuIcons}, dispatch] = useStore();

    const getInformationDeskResult = useCallback((res) => {
        dispatch('hideSpinner');
        const data = res.data.rows;
        setCategoryData(data);
        setDataLen(data.length);
        setCurrentlySelectedInfo(data[0]);
        if (data.length > 0)
            setActiveSlide(data[0].id);
    }, [dispatch])

    const getCategoryRelatedData = useCallback(() => {
        dispatch('showSpinner');
        triggerAPI({
            url: `information-desk/user/category/content/${id}`, method: 'get'
        }, getInformationDeskResult);
    }, [dispatch, getInformationDeskResult, id, triggerAPI])

    const getCategoryResult = useCallback((res) => {
        dispatch('hideSpinner');
    }, [dispatch])

    const getCategory = useCallback(() => {
        dispatch('showSpinner');
        triggerAPI({
            url: `information-desk/category/get/${id}`, method: 'get'
        }, getCategoryResult);
    }, [dispatch, getCategoryResult, id, triggerAPI])

    useEffect(() => {
        getCategory()
        getCategoryRelatedData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const handleSelected = (data) => {
        setCurrentlySelectedInfo(data);
        var index = categoryData.findIndex(x => x.id === data.id);
        setArrow(index);
        setActiveSlide(categoryData[index].id);
        setNavBar(false);
    }

    const dbonceHandler = (mode) => {
        if (timer.current) {
            clearTimeout(timer.current)
        }
        timer.current = setTimeout(() => {
            setNavBar(mode);
        }, 500);
    }
    const handleClick = () => {
        dbonceHandler(!navBar);
    }
    const mouseHoverHandler = () => {
        dbonceHandler(true);
    }
    const mouseLeaveHandler = () => {
        dbonceHandler(false);
    }
    const handleArrow = (index) => {
        setCurrentlySelectedInfo(categoryData[index]);
        setActiveSlide(categoryData[index].id);
        setArrow(index);
    }
    const setArrow = (index) => {
        setNextArrow((index + 1));
        setPrevArrow((index - 1));
    }
      //Menu options
      useEffect(() => {
        dispatch('setTopMenuBarOptions', {showMenuIcons: false, showTopMenus: false});
        dispatch('enableTopIcons', {back: true});
        dispatch('setActiveIcons', {back: false});
        return () => {
            dispatch('resetAll')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if(activeMenuIcons?.back) {
            backHandler();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons?.back])
    return (
        <div className={`${styles['info-list-wrp']} page-shadow`}>
            <div className={`w-full ${styles['overflow-flex-auto']} relative`}>
                <div className={`pt-4 pl-4 pr-4 grid grid-cols-1 md:grid-cols-1 2xl:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-4 h-full ${styles['grid-style-row']}`}>
                    <div className={`${styles['selected-container']} h-full`}>
                        {nextArrow < (dataLen) && <div className={`${styles['slide-arrows']} ${styles['slide-arrow-right']}`}
                            onClick={() => handleArrow(nextArrow)}>
                            <img src={ArrowRight} className='' alt='right-arrow' />
                        </div>}

                        {prevArrow >= 0 && <div className={`${styles['slide-arrows']} ${styles['slide-arrow-left']}`}
                            onClick={() => handleArrow(prevArrow)} >
                            <img src={ArrowLeft} className='' alt='left-arrow' />
                        </div>}

                        {currentlySelectedInfo && <SelectedInformation selectedData={currentlySelectedInfo} />}
                    </div>
                    <div onMouseOver={mouseHoverHandler} onMouseLeave={mouseLeaveHandler} onClick={handleClick}
                        className={`${styles['selected-container-nav']} ${navBar ? styles['selected-container-nav-open'] : styles['selected-container-nav-closed']}`}>
                        <InformationSlider categoryData={categoryData} handleSelected={handleSelected} activeSlideId={activeSlide} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InformationDetails