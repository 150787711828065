import Accordion from 'App/Components/Ui/Accordion'
import AccordionExpander from 'App/Components/Ui/Accordion/AccordionExpander'
import CheckBox from 'App/Components/Ui/CheckBox'
import { useEffect, useState } from 'react'

const FilterCheckBox = props => {
    const [checked, setChecked] = useState([])
    const [expand, setExpand] = useState(false)
    useEffect(() => {
        setChecked(!!props.filter[props.title] && props.filter[props.title])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filter])
    const checkOptionHandler = e => {
        props.onCheckOption(props.title, e)
    }
    const toggleAccordian = () => {
        setExpand((prev) => !prev)
    }
    return (
        <Accordion>
            <div className="expander text-left cursor-pointer">
                <div onClick={toggleAccordian} className={`accordion-arrow ${expand ? 'accordion-arrow-expanded' : 'accordion-arrow-collapsed'}`}>{props.title}</div>
                <AccordionExpander expand={expand} >
                    {
                        props.options.map((option, index) =>
                            <CheckBox multiple={true} className="text-[.625rem] p-[.1875rem] gap-2.5 capitalize"
                                checked={checked.includes(option.name)} key={index}
                                onClick={checkOptionHandler}>
                                {option.name}
                            </CheckBox>
                        )
                    }
                </AccordionExpander>
            </div>
        </Accordion>
    )
}

export default FilterCheckBox