/* eslint-disable react-hooks/exhaustive-deps */
import Validators from "App/Components/Ui/Forms/Validators";
import Input from "App/Components/Ui/Input";
import {
  checkHtmlContent,
  getInitial,
  markAsTouched,
  validateFormGroup,
} from "App/Helper/formValidation";
import { useStore } from "App/hooks-store/store";
import { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { useCallback } from "react";
import "../../Community/Components/Details/Details.scss";
import "./Create.scss";
import Panel from "App/Components/Ui/Panel";
import RoundedButton from "App/Components/Ui/RoundedButton";
import useHttp from "App/hooks/use-http";
import MySelect from "App/Components/Ui/Select/MySelect";
import useScreenSize from "App/hooks/use-screen-size";
import { useParams } from "react-router-dom";
import userAvatarHover from 'Assets/Icons/avatar_upload.svg'
import mdDefault from 'Assets/Images/md-default.svg'
import dummyImage from 'Assets/Images/dummypic.svg';

import { handleDragEnter, handleDragLeave, handleDragOver, handleDrop } from "App/Helper/files";
import RichTextEditor from "App/Components/RichTextEditor";
import BlurBGImage from "App/Components/Ui/BlurBGImage";

const CreateAnnouncementsAndEvents = ({
  page,
  data,
  onCancel,
  onSaveHandler,
  subjectsArray,
  onChangeSubjectArray,
}) => {
  const { id } = useParams();
  const screensize = useScreenSize()?.width;
  const [{ currentUserId }, dispatch] = useStore(false);
  const [formValues, setFormvalues] = useState({});
  const [formVaildState, setFormVaildState] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const [isDateEmpty, setIsDateEmpty] = useState(null);
  const [disableEventDateEdit, setDisableEventDateEdit] = useState(false);
  const [richTxtErrors, setRichTxtErrors] = useState('');
  const [categoryErrors, setCategoryErrors] = useState('');
  const [targetCollection, setTargetCollection] = useState([]);
  const [multipleDaysEvent, setMultipleDaysEvent] = useState(false);
  const [dateObject, setDateObject] = useState({start:null, end:null});
  const targetRef= useRef(null)
  const thumbnailWrapRef = useRef(null);
  const { triggerAPI } = useHttp();
  const [thumbnailPicture, setThumbnailPicture] = useState(null)
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const targetCollectionApiResult = useCallback(
    (res) => {
      const { list } = res.data;
      setTargetCollection(list);
    },
    [dispatch]
  );


  const getTargetCollectionList = useCallback(
    (userId) => {
      triggerAPI(
        {
          url: "community/collection/user/get",
          data: {
            page: 1,
            items: 1000,
            filter: {communityId: [parseInt(id)]},
            search: "",
          },
          method: "post",
        },
        targetCollectionApiResult
      );
    },
    [dispatch, targetCollectionApiResult, triggerAPI, page]
  );

  const formGroup = useMemo(() => {
    let formVal = {
      details: {
        value: undefined,
        isRequired: true,
      },
      headline: {
        value: undefined,
        isRequired: true,
      },
    };
    formVal.targetCollection = {
      value: undefined,
      isRequired: true,
    };
    if (page !== "announcement")  {
      formVal.start = {
        value: undefined,
        isRequired: true,
        notChanged: Object?.keys(data)?.length ? true : false,
      };
      formVal.end = {
        value: undefined,
        isRequired: true,
        notChanged: Object?.keys(data)?.length ? true : false,
      };
      formVal.startCheck = {
        value: false,
        isRequired: false,
      };
      formVal.endCheck = {
        value: false,
        isRequired: false,
      };
      formVal.thumbnailPicture = {
        value: false,
        isRequired: false,
      };
    }
    return formVal;
  }, [page, data]);

  useEffect(() => {
    setFormvalues(getInitial(formGroup));
  }, [formGroup]);

  useEffect(() => {
    if (Object?.keys(data)?.length) {
      let updatedData = {
        headline: data?.headline,
        details: data?.details,
        categories: data?.selectedCategoryId
      };
      getTargetCollectionList(updatedData?.createdBy);
        updatedData.targetCollection =
          data?.targetCollection && data?.targetCollection?.length
            ? data?.targetCollection
            : undefined;
      if (page !== "announcement") {
        updatedData.start = data?.start;
        updatedData.end = data?.end;
        updatedData.startCheck = true;
        updatedData.endCheck = true;
        setDateObject({start:data?.start, end:data?.end})
        setMultipleDaysEvent(!data?.isOneDayEvent)
        if (data?.filePath) {
          setThumbnailPicture(data.filePath);
        }
        updatedData.thumbnailPicture = thumbnailPicture
      }
      if(data?.selectedCategoryId?.length === 2){
        handelSubjectSelect(data?.selectedCategoryId[0], data?.selectedCategoryId[1])
      } else {
        onChangeSubjectArray(data?.categories);
      }
      setFormvalues(updatedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, data]);

  useEffect(() => {
    
      getTargetCollectionList(currentUserId);
    
  }, [page]);
  const imageHandle = (imgFile) => {
    if (imgFile) {     
        const file = imgFile?.thumbnailPicture;
        const fileSize = file?.size / 1024 / 1024;
        const extension = file?.type?.split("/").shift();
        const fileExtension = extension.toLowerCase();
        if (
            fileExtension === "image" 
        ) {
          if (fileSize <= 2) {
            const imageUrl = URL.createObjectURL(file);
            if (imgFile?.thumbnailPicture) {
              inputOnChange({ thumbnailPicture: file });
              setThumbnailPicture(imageUrl);
            }
          } else {
            dispatch("showToast", {
              toast: {
                toastMode: "error",
                message: "The maximum size of an image should be 2 MB",
              },
            });
          }
        } else {
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message: "Please upload an image",
            },
          });
        }
    }
}
  const inputOnChange = (e) => {
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e,
      };
    });
  };

  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);

  function handelSubjectSelect(mainId, subId){
    if (!mainId || !subId) return;
    setCategoryErrors('');
    const filterSubjectArray = subjectsArray?.map((item) => ({
      ...item,
      subcategories: item?.subcategories?.map((sub) => ({
        ...sub,
        isSelected: item?.id === mainId && sub?.id === subId,
      })),
    }));
    setFormvalues((prev)=>({...prev, categories: [mainId, subId]}))
    onChangeSubjectArray(filterSubjectArray);
  }

  useEffect(() => {
    if (touched) {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  useEffect(() => {
    const { valid } = validateFormGroup({
      errors: formVaildState,
      formGroup,
      values: formValues,
    });
    setIsValid(valid);
  }, [formValues, formVaildState, formGroup]);

  const saveHandler = () => {
    const { isEmpty } = checkHtmlContent(formValues?.details);

    const dateValidation = page !== "announcement" ? (dateObject?.start && dateObject?.end) : true;

    if (isValid && formValues?.details && !isEmpty && formValues?.categories?.length && dateValidation) {
      let saveData = formValues;
      delete saveData?.startCheck;
      delete saveData?.endCheck;
      onSaveHandler({...saveData, isOneDayEvent: !multipleDaysEvent});
    } else {
      if(formValues?.details === undefined || isEmpty){
        setRichTxtErrors('Required field');
      }
      if(!formValues?.categories?.length){
        setCategoryErrors('Required field');
      }

      if(!dateValidation){
        if(!dateObject?.start || !dateObject?.end){
          setIsDateEmpty('Required field');
        }
      }
      setTouched(true);
    }
  };

  const onTragetCollectionHandler = () => {
    const ids = targetCollection.map((val) => val?.id);
    inputOnChange({ targetCollection: ids });
  };
  
  useEffect(() => {
  const mappedIds = targetCollection?.map((val) => val?.id);
  setFormvalues((prevFormValues) => ({
    ...prevFormValues,
    targetCollection: {
      ...prevFormValues.targetCollection,
      value: mappedIds,
    },
  }));
  onTragetCollectionHandler();
}, [targetCollection]);

  // const onTragetCollectionHandler = (e) => {
  //   const value = e?.currentTarget?.dataset?.id;
  //   let ids = [];
  //   if (value === "all") {
  //     ids =
  //       formValues?.targetCollection?.length === targetCollection?.length
  //         ? []
  //         : targetCollection?.map((val) => val?.id);
  //   } else {
  //     if (formValues?.targetCollection?.includes(Number(value))) {
  //       ids = formValues?.targetCollection?.filter(
  //         (val) => val !== Number(value)
  //       );
  //     } else {
  //       let collection = formValues?.targetCollection
  //         ? formValues?.targetCollection
  //         : [];
  //       ids = [...collection, Number(value)];
  //     }
  //   }
  //   inputOnChange({ targetCollection: ids });
  //   console.log(targetCollection?.map((val) => val?.id) )
  // };
  const onDateSelect = (e) => {
  //  let check = e.end?new Date(e.end).getFullYear()===new Date(start).getFullYear()&&new Date(e.end).getMonth()===new Date(start).getMonth()&&new Date(e.end).getDate()===new Date(start).getDate():true
    if (typeof e === "object") {
      let date ={ ...e};
      setIsDateEmpty(null);
      setDateObject(date)
      // if (e?.start) {
      //  // date.end =new Date(new Date(e.start).getFullYear(),new Date(e.start).getMonth(),new Date(e.start).getDate(),new Date(end).getHours(),new Date(end).getMinutes(),0,0).toString();
      //   date.startCheck=false;
      //   date.endCheck=false;
      //   setStart(e.start)
      // }else  {
      //   setEnd(e.end)
      //   date.endCheck=false;
   // }
   inputOnChange({ ...date});
    }
  };

  const updateHeight = () => {
    const root = document.documentElement;
    if(targetRef.current) {
      
        if(targetRef.current) {
            root?.style.setProperty(
                "--collection-card-height", `${targetRef.current.offsetWidth}px`
            );
        }
    }
  }

  const handleOnChangeOnRichText = (value) => {
    const { length } = checkHtmlContent(value);
    if (value && length <= 1000) {
      setRichTxtErrors('')
      inputOnChange({ details: value });
    } else {
      inputOnChange({ details: formValues.details });
      setRichTxtErrors('Reached max(1000) character')
    }
  }

  const handleCleanUp = () => {
    setRichTxtErrors('');
  }

useLayoutEffect(() => {
    updateHeight()
    window.addEventListener("resize", () => {
      setTimeout(() => {
          updateHeight()            
      }, 1000);
  }, false);
},[]);



// const isDateReached = (startDate) => {
//   const currentDate = new Date();
//   const targetDate = new Date(startDate);
//   setDisableEventDateEdit(currentDate >= targetDate);
// };

  const checkValueForOneDayEvent = (dateFormat) => {
    if (dateObject?.start && dateObject?.end) {
      if(dateFormat === 'onlyDate'){
        return `${new Date(dateObject?.start).toLocaleString(
          "en-US",
          {
            month: "short",
            day: "2-digit",
            year: "numeric",
          }
        )

        } - ${new Date(dateObject?.end).toLocaleString(
          "en-US",
          {
            month: "short",
            day: "2-digit",
            year: "numeric",
          }
        )}`
      }else{

   
      return `${new Date(dateObject?.start).toLocaleString(
          "en-US",
          {
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }
        )

        } - ${new Date(dateObject?.end).toLocaleString(
          "en-US",
          {
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }
        )}`  
       }
    } else {
      return null
    }
  }

useEffect(()=>{
    updateHeight()
},[targetCollection])

useEffect(()=>{
  if(Object.keys(data)?.length && page !== "announcement"){
    // isDateReached(data?.start);
    setDisableEventDateEdit(true)
  }
},[data])

  return (
    <Panel className={`py-4 gap-3 sm:px-4 px-0 announcement-event-wrapper mb-4`}>
      <div className="community-form create flex flex-col gap-2">
        <div className="flex flex-col gap-6 w-full p-[1rem]">

          {/* Input wrapper */}
          <div className="flex flex-col gap-[0.5rem] text-left">
              <label className="!text-[0.85rem] opacity-90">Headline</label>
            <div className="">
              <Input 
                maxlength={100}
                type="text"
                label="headline"
                validateOnBlur
                placeholder="Headline 100 characters max"
                onChange={inputOnChange}
                controlName="headline"
                value={formValues?.headline}
              >
                <Validators
                  type="Required"
                  value={formValues?.headline}
                  message="Required field"
                  controlName="headline"
                  onErrors={collectErrors}
                />
              </Input>
            </div>
          </div>

      {/* Date wrapper */}
      {  !!(page !== "announcement") &&  <div className="flex flex-col gap-2 text-left">
            <div className={`flex gap-2 justify-start items-center event-date-scheduler-box`}>
              <input readOnly  type="radio" className={`event_radio-input ${multipleDaysEvent && 'active'} ${disableEventDateEdit ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                onClick={() => {
                  if(disableEventDateEdit) return;
                  setDateObject({ start: null, end: null })
                  setMultipleDaysEvent((prev)=>!prev);
                }} checked={multipleDaysEvent} />
               <span className={"text-[0.75rem] text-gray-300"}>Multi-Day Event</span>
            </div>

              <div className="flex justify-between gap-4 date-time-wrapper">
                {!multipleDaysEvent && 
                <div className="flex gap-2 date-wrapper !w-[50%]">
                  <span className="sub-title sm:inline-block hidden w-[10rem] text-[0.85rem] !text-[var(--cu-white-100)] opacity-90">Date & Time (UTC)</span>
                  <span className="sub-title sm:hidden inline-block text-[0.85rem] !text-[var(--cu-white-100)] opacity-90">Date & Time (UTC)</span>
                  <div className="flex sm:pb-2 w-full sm:pt-1 pb-0 pt-0">
                    <div className="w-full flex flex-col gap-1">
                    <MySelect
                        type="one-day"
                        className="select-box-new"
                        noHover={true}
                        showTime={true}
                        onDateSelect={onDateSelect}
                        keyName="start"
                        placeholder="Select date and time"
                        noDropArrow={true}
                        minDate={dateObject?.start}
                        disableSelect={disableEventDateEdit}
                        dateSelected={
                          dateObject?.start
                        }
                        dateObject={{
                          start:dateObject?.start
                          && new Date(dateObject?.start).toLocaleString(
                              "en-US",
                              {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            ),
                            end:dateObject?.end
                            && new Date(dateObject?.end).toLocaleString(
                                "en-US",
                                {
                                  month: "short",
                                  day: "2-digit",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }
                              )
                        }}
                        value={checkValueForOneDayEvent()}
                        mainClassName="select-box-new-gallery-date"
                        label="startTime"
                      ></MySelect>
                    </div>
                    </div>
                  </div>}
                {multipleDaysEvent && 
                <div className="flex gap-2 date-wrapper !w-[50%]">
                  <span className="sub-title sm:inline-block hidden w-[10rem] text-[0.85rem] !text-[var(--cu-white-100)] opacity-90">Dates</span>
                  <span className="sub-title sm:hidden inline-block text-[0.85rem] !text-[var(--cu-white-100)] opacity-90">Dates</span>
                  <div className="flex sm:pb-2 w-full sm:pt-1 pb-0 pt-0">
                    <div className="w-full flex flex-col gap-1">
                    <MySelect
                        type="range-date-picker"
                        className="select-box-new"
                        noHover={true}
                        showTime={false}
                        onDateSelect={onDateSelect}
                        keyName="start"
                        placeholder="Select start and end dates"
                        noDropArrow={true}
                        minDate={dateObject?.start}
                        disableSelect={disableEventDateEdit}
                        dateSelected={
                          dateObject?.start
                        }
                        dateObject={{
                          start:dateObject?.start
                          && new Date(dateObject?.start).toLocaleString(
                              "en-US",
                              {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            ),
                            end:dateObject?.end
                            && new Date(dateObject?.end).toLocaleString(
                                "en-US",
                                {
                                  month: "short",
                                  day: "2-digit",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }
                              )
                        }}
                        value={checkValueForOneDayEvent('onlyDate')}
                        mainClassName="select-box-new-gallery-date"
                        label="startTime"
                      ></MySelect>


                    </div>
                    </div>
                  </div>}                  
              </div>
              <div className="error-validation text-left -mt-3">{isDateEmpty}</div>
            </div>}

            <div className="w-full flex justify-between items-start">

             {/* Subject wrapper */}
              <div className={`subject-container flex flex-col gap-2 text-left ${page === "announcement" ? "w-full" : 'w-2/3'}`}>
                <div className="w-full flex flex-col gap-1">
                <p className="text-[0.85rem] opacity-90 text-[var(--cu-white-100)]">{subjectsArray && 'Subject'}</p>
                <label className="error-validation">{categoryErrors ?? null}</label>
                </div>
              <div className={`subject-list-box ${page === "announcement" ? "flex-3-per" : 'grid-2-per'}  `}>{subjectsArray && subjectsArray?.map((item) => {
                return (<div className="flex flex-col gap-2" key={item?.id}>
                  <p className="w-full text-[0.75rem] opacity-80 text-[var(--cu-white-100)]">{item?.name}</p>
                  <div className="flex flex-wrap items-start gap-3" key={item?.id}>{item?.subcategories?.map((tag, i) => <p key={tag?.id} className={`${tag?.isSelected && "active"} mono-subject opacity-90`} onClick={() => handelSubjectSelect(item?.id, tag?.id)}>{tag?.name}</p>)} </div>
                </div>)
              })}</div>
              </div>
               {/* Image wrapper */}
              {page !== "announcement" && (<div className="w-1/3 flex gap-2 flex-col  text-left">
                  <label className="!text-[0.85rem] opacity-90"> Thumbnail</label>
                <div className="upload-thumbnail-image" ref={thumbnailWrapRef}
                  id="thumbnail"
                  onDrop={(e) =>
                    handleDrop(e, thumbnailWrapRef, imageHandle, "thumbnailPicture", "thumbnail")
                  }
                  onDragOver={handleDragOver}
                  onDragEnter={(e) => handleDragEnter(e, thumbnailWrapRef, "thumbnail")}
                  onDragLeave={(e) => handleDragLeave(e, thumbnailWrapRef, "thumbnail")}>
                  <div className={'relative h-full w-full'}>
                    <label className="cursor-pointer" htmlFor="upload-button-thumb">
                      <img
                        alt="No Img"
                        src={userAvatarHover}
                        className={`upload-icon`}
                      ></img>
                      {!thumbnailPicture && (
                        <div className="dummy-wrap long-banner">
                          <img
                            className={`image-uploaded`}
                            alt="NoImg"
                            src={(isPortrait && screensize <= 550) ? mdDefault : dummyImage}
                          ></img>
                          <p>
                            Recommended size <br />
                            750px x 450px <br />
                          </p>
                        </div>
                      )}
                      {thumbnailPicture && (
                        <div className="relative rounded-xl image-uploaded actual-img">
                          <BlurBGImage mainImageSrc={thumbnailPicture} bgImageSrc={thumbnailPicture} />
                        </div>)}
                    </label>
                    <input
                      type="file"
                      id="upload-button-thumb"
                      name={'thumbnailPicture'}
                      className={'hidden'}
                      accept="image/*"
                      onChange={(e) => imageHandle({ "thumbnailPicture": e.target.files[0] })}
                    />
                  </div>
                </div>
              </div>)}

            </div>

          <div className="flex flex-col gap-2">
              <label className="!text-[0.85rem] text-left opacity-90">Description</label>
            <div className="">
            <RichTextEditor parentClass={`dangerously-innerHTML-container h-[200px] mb-14 `} rQuillClass={`h-full border-0`} isPlaceholder={`Details 1000 characters max`} value={formValues?.details} onChange={handleOnChangeOnRichText} />
                <div className="error-validation text-left -mt-2">{richTxtErrors ?? null}</div>
            </div>
          </div>
      </div>
        <div className="button-container-create-events-post">
          <RoundedButton
            className={"cancel-btn"}
            OnClick={() => {
              handleCleanUp()
              onCancel()
            }}
            Type="1"
            Name={"Cancel"}
          />
          <RoundedButton
            className={`add-btn save ${isValid ? "active" : ""} `}
            OnClick={saveHandler}
            Type="1"
            Name={Object.keys(data)?.length ? "Save" : "Add"}
          />
        </div>
      </div>
    </Panel>
  );
};
export default CreateAnnouncementsAndEvents;