import { removeDuplicateByElement } from "App/Helper/utilities";

const { initStore } = require("./store")
const configureStore = () => {
    const actions = {
        setCollections: (current, collections) => {
            let currentData = current?.collections ?? [] ;
            const data = [...currentData, ...collections.data];
            const finalData = removeDuplicateByElement(data, 'asset');
            return {
                collectionsLoaded: 0,
                collectionsLoading: collections.loading,
                collections: finalData,
                collectionsCount: collections.count
            }
        },
        resetCollections: (current, collections) => {
            return {
                collectionsLoaded: 0,
                collectionsLoading: false,
                collections: [],
                collectionsCount: 0
            }
        },
        setAssetsList: (current, assetsList) => {
            return {
                collectionAssetList: assetsList,

            }
        },
        updateCount: (current) => {
            if (current.collectionsLoaded < current.collectionsCount)
                return {
                    collectionsLoaded: current.collectionsLoaded + 1
                }
        },
        updateCollectionLoadingStatus: (current,status) => {
            return {
                collectionLoadingStatus: status
            }
        },        
        updateCollectionRawData: (current,data) => {
            return {
                collectionRawData: data
            }
        },        
        updateCollectionAssetCount: (current, data) => {
            return {
                collectionAssetCount: data
            }
        },        
        updateCollectionWalletData: (current, data) => {
            return {
                collectionWalletData: data
            }
        },        
        reFetch: (current) => {
            return {
                reFetchCollections: {mode:true}
            }
        },
        favToggle: (current, object) => {
            let find = current.collections.find(collec => collec.policy === object.policy)
            if (find)
                find.isCollectionFavourite = object.value
            return {
                collections: [...current.collections]
            }
        },
        favAssetToggle: (current, object) => {
            let find = current.collections.find(collec => collec.asset === object.asset)
            if (find)
                find.isFavourite = object.value
            return {
                collections: current.collections
            }
        },
        updateUsername: (current, newUsername) => {
            return {
                username: newUsername
            }
        }
    }
    initStore(actions, {
        collectionAssetList: [],
        collectionsLoading: false,
        collectionsLoaded: 0,
        collectionAssetCount: 0,
        collections: [],
        collectionsCount: 0,
        collectionLoadingStatus:'finished', //defaulted to finished so cached collections will display
        collectionRawData:[],
        collectionWalletData: null,
        reFetchCollections: {mode:false},
        username:'',
        schemaVersionNumber: 2 //increment this number when you change the collection or token schema, it will trigger local collection data to be refetched
    });
}
export default configureStore;