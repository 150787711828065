import React from 'react'

const userProfileIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 73.825c-100.613 0-182.18 81.562-182.18 182.17a182.18 182.18 0 0 0 364.36 0c0-100.608-81.572-182.17-182.18-182.17zm.553 268.12h-78.93c0-56.91 49.98-56.901 61.07-71.773l1.27-6.793c-15.582-7.893-26.582-26.939-26.582-49.201 0-29.33 19.081-53.122 42.619-53.122 23.532 0 42.61 23.793 42.61 53.122 0 22.078-10.802 41-26.175 49.017l1.442 7.716c12.172 14.16 60.486 15.082 60.486 71.034z" data-name="Profile Male" fill='#fff' /></svg>

const TopHolders = ({ topHoldersData, totalTopHoldersCount }) => {

  return (
    <div className="border border-[var(--cu-black-50)] bg-[var(--cu-black-500)]  rounded-xl max-h-[45dvh]">
      <div className="flex justify-between items-center border-b border-b-gray-700 p-[1rem]">
        <p>Top Holders</p>
        {topHoldersData?.length ? <p>{totalTopHoldersCount} Holders</p> : null}
      </div>
      {topHoldersData?.length ? <div className='h-full'>
        <div className="w-full grid grid-cols-4 gap-2 bg-[var(--cu-black-700)] py-[1rem]">
          <div>#</div>
          <div>HOLDER</div>
          <div>PIECES OWNED</div>
          <div>% OWNED</div>
        </div>
        <div className='max-h-[32dvh] overflow-y-auto'>
          {topHoldersData?.map((topHolder, index) => <div key={index} className={`grid grid-cols-4 gap-2 py-[1rem] ${topHoldersData?.length === index + 1 ? '' : 'border-b border-b-gray-700'}`}>
            <div>{index + 1}</div>
            <div className='flex items-center gap-1'>
              <div className='min-w-[2rem]'>{userProfileIcon}</div>
              <p className='truncate hover:text-clip mb-1'>{topHolder?.address}</p>
            </div>
            <div>{topHolder?.amount}</div>
            <div>{(topHolder?.amount / totalTopHoldersCount * 100)?.toFixed(2)} %</div>
          </div>)}
        </div>
      </div> : <div className="py-[1rem] h-[15dvh] flex justify-center items-center">{`No data available`}</div>}

    </div>
  )
}

export default TopHolders