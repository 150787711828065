
import useWallet from "App/hooks/use-wallet";
import "./WalletAuthMesh.scss";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { useCallback } from "react";
import useHttp from "App/hooks/use-http";
import WalletList from "./Components/WalletList";
import Panel from "App/Components/Ui/Panel";
import { useStore } from "App/hooks-store/store";
import storage from "App/Helper/storage";
import BanerSm from "App/Layouts/Baner-sm";
import RoundedButton from "App/Components/Ui/RoundedButton";
import EternalSVG from 'Assets/Images/eternl_wallet.svg';
import FlintWallet from 'Assets/Images/flintWallet.svg';
import NamiWallet from 'Assets/Images/NamiWallet.svg';
import TyphonWallet from 'Assets/Images/TyphonWallet.svg';
import useAuth from "App/hooks/use-auth";
import useScreenSize from "App/hooks/use-screen-size";
import geroWalletIcon from 'Assets/Icons/gero-wallet-icon.svg';

const WalletAuthMesh = () => {
  const walletConnector = useWallet();
  const [installedWallets, setInstalledWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [isEdit, setisEdit] = useState(false);
  const [isLinking, setisLinking] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [{ currentWallet ,collectionWalletData}, dispatch] = useStore(false);
  const tabs = ["Add wallet", "Connected"]
  const [tabSelected, setTabSelected] = useState(tabs[0])
  const { triggerAPI } = useHttp();
  const removeItem = useAuth()[5];
  const screenSize = useScreenSize()?.width;
  const changeTabHandler = (e) => {
    setTabSelected(e?.currentTarget?.dataset?.id)
  }
  const connectHandler = async (e) => {
    const installUrl = e?.currentTarget?.dataset?.id
    if (installUrl) {
      window.open(installUrl)
    }
    else {
      setisLinking(true);
      const name = e.target.value;
      walletConnector.connectWallet(name);
      setSelectedWallet(name)
    }

  }

  const callAPI = async (walletAddress, stakeAddress) => {
    return new Promise((resolve) => {
      const data = {
        walletAddress,
        stakeAddress
      }
      triggerAPI({
        url: `user/wallet/nfts`, data, method: 'post'
      }, (res => {
        resolve(res.data)
      }));
    })
  }
  const connectedWalletstResult = useCallback((res) => {
    const val = res?.data;        
        const apiPromise = val.map((wallet) => {
          return callAPI(wallet.walletAddress, wallet.stakeAddress);
        });
        Promise.all(apiPromise).then((res)=>{
          const walletPromise = res.map((elem, index) => {
            return {...val[index],nftCount: elem?.nftCount ? elem?.nftCount : 0 }
          })          
          dispatch('hideSpinner')
          setWalletList(walletPromise)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const setWalletName = (availableWallet) => {
    const { connecting } = walletConnector;
    const storedWalletName = selectedWallet;

    const walletFound = availableWallet.find((wlt) => (storedWalletName ?? storage().get('currentWallet')) === wlt.name);
    if (walletFound) {
      walletFound.status = connecting;
    }

    return availableWallet;
  }
  const getConnectedWallets = useCallback(() => {
    dispatch('showSpinner')
    triggerAPI({
      url: `user/connected-wallet/get`, method: 'get'
    }, connectedWalletstResult);
  }, [triggerAPI, connectedWalletstResult, dispatch])

  const otherWalletResponse = useCallback((res) => {
    const { data } = res;
    dispatch('hideSpinner');
    if (!(typeof data === 'string' && data === 'Duplicate')) {
      getConnectedWallets()
      dispatch("reFetch")
    }
  }, [dispatch, getConnectedWallets])
  const walletAddHandler = useCallback((walletAddress) => {
    if (walletAddress) {
      dispatch('showSpinner')
      const data = {
        walletAddress,
      }
      triggerAPI({
        url: `user/connect-wallet/via/manually`, data: data, method: 'post'
      }, otherWalletResponse, (err) => {
        dispatch('hideSpinner');
        removeItem('currentWallet');
        dispatch('setCurrentWallet', {})
         dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.error?.message } })
      });
    }
    else {
      dispatch('showToast', { toast: { toastMode: 'error', message: "Invalid valid wallet address" } })
      dispatch('setCurrentWallet', {})
      removeItem('currentWallet');
    }
  }, [dispatch, otherWalletResponse, triggerAPI, removeItem])

  const updateListHandler = () => { 
    setisEdit(false);
  }
  const applyEditMode = () => {
    setisEdit(true);
  }
  const getUserlistResult = useCallback((res) => {
    dispatch('hideSpinner');
    dispatch('setWalletUpdatingStatus', true)
    getConnectedWallets()
    dispatch("reFetch")
    dispatch('showToast', { toast: { toastMode: 'success', message: "Wallet deleted successfully" } })
  }, [dispatch, getConnectedWallets])
  const deleteWallet = (id) => {
    dispatch('resetCollections')
    dispatch('showSpinner')
    triggerAPI({
      url: `user/connected-wallet/remove`, data: { id }, method: 'delete'
    }, getUserlistResult, err => {
      dispatch('hideSpinner');
      dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.error?.message } })
  });
  }
  const setActiveWallet = () => {
    let defaultWallet = [{
      name: "eternl",
      icon: EternalSVG,
      version: "0.1.0",
      installUrl: "https://chrome.google.com/webstore/detail/eternl/kmhcihpebfmpgmihbkipmjlmmioameka",
    },
    {
      name: "Flint Wallet",
      icon: FlintWallet,
      version: "0.1.0",
      installUrl: "https://chrome.google.com/webstore/detail/flint-wallet/hnhobjmcibchnmglfbldbfabcgaknlkj",
    }, {
      name: "Nami",
      icon: NamiWallet,
      version: "0.1.0",
      installUrl: "https://chrome.google.com/webstore/detail/nami/lpfcbjknijpeeillifnkikgncikgfhdo",
    }, {
      name: "Typhon Wallet",
      icon: TyphonWallet,
      version: "0.1.0",
      installUrl: "https://chrome.google.com/webstore/detail/typhon-wallet/kfdniefadaanbjodldohaedphafoffoh",
    }]
    const availableWallet = walletConnector.availableWallet();
    let installedWalletList = setWalletName(availableWallet)
    installedWalletList = installedWalletList?.filter((x, i) => installedWalletList?.findIndex((y, j) => y?.name === x?.name) === i)
    const walletDefault = installedWalletList?.map(wallet => {
      const existWallet = defaultWallet?.filter(x => x?.name === wallet?.name)
      if (existWallet?.length) {

        defaultWallet = defaultWallet?.filter((x) =>
          !existWallet?.some(y => y?.name === x?.name))
      }
      if(wallet?.name === "GeroWallet")
        wallet = {...wallet, icon: geroWalletIcon}
      return wallet
    })
    setInstalledWallets([...defaultWallet, ...walletDefault])

  }
  useEffect(() => {
    if (currentWallet?.address) {
      const { address, walletName } = currentWallet;
      setSelectedWallet(walletName)
      if (isLinking)
      {
        walletAddHandler(address);
        setisLinking(false);
      }      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWallet])

  useEffect(() => {
    setActiveWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletConnector, selectedWallet])

  useEffect(() => {
    getConnectedWallets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const nameHandler=(name)=>{
    return name?.replace("Wallet","")
}
const editWallet = (data,resCallback) => {
  let payload= {
    walletName: data?.walletName,
    walletAddress: data?.walletAddress,
  }
  dispatch("showSpinner");
  triggerAPI(
    {
      url: `user/edit/connect-wallet-name`,
      data:payload,
      method: "patch",
    },
    (res) => {
      dispatch("hideSpinner");
      getConnectedWallets()
      resCallback()
      let updateWallet=collectionWalletData?.map((el)=>{
        if(el?.id===data?.id){
          return {...el,walletName:data?.walletName}
        }
        return el
      })
      dispatch("updateCollectionWalletData", updateWallet)  
      dispatch("showToast", {
        toast: { toastMode: "success", message: res?.message },
      });
    },(err)=>{
      dispatch("hideSpinner");
      dispatch("showToast", {
        toast: { toastMode: "error", message: err?.response?.data?.error?.message },
      });
    }
  );
};
  return (
    < Fragment >
      <BanerSm className="wallet-mob-head">
        {tabs && tabs.map((tabs) => {
          return (
            <div key={tabs} data-id={tabs} onClick={changeTabHandler}
              className={`font-14 text-[#64748B] cursor-pointer custome-tab mr-3 ${tabSelected === tabs ? 'active' : ''}`}>
              {tabs}
            </div>
          )
        })}
      </BanerSm>
      {
        screenSize < 551 ? tabSelected === 'Add wallet' &&
          <Panel className="add-wallet-sm">
            <div className="wallet-connectors">
              <ul className="flex justify-center text-sm p-7 items-center">
                No wallets found
              </ul>
            </div>
          </Panel> : <Panel>
          <div className="wallet-connectors">
            {!installedWallets?.length ? <p className="flex justify-center items-center h-full">Please install any of the Cardano wallets.</p> :
              <p>Link your wallet</p>}
            <ul className="wallet-connectors-list">
              {
                installedWallets.map((wallet) => (
                  <li key={wallet.name}>

                    <div className="wallet-connectors-group">
                      <img src={wallet.icon} alt={wallet.name} />
                      <div>{nameHandler(wallet.name)}</div>
                    </div>
                    <button className="mt-6" data-id={wallet?.installUrl} onClick={connectHandler} value={wallet.name}>
                      {(wallet?.status ? 'Linking' : 'Link')}
                    </button>
                  </li>
                ))
              }
            </ul>
          </div>
        </Panel>
      }
      {
        screenSize < 551 ? tabSelected === 'Connected' &&
          <Fragment>
            <Panel className="add-wallet-sm">
              <div className="flex flex-col p-2 items-center justify-between text-left w-full order-1">
                <div className="flex px-4 justify-between w-full items-center">
                  <div className="justify-center w-full">Linked Wallets</div>
                  <div className="justify-end flex text-end">
                    {isEdit ? <RoundedButton Name="Update" Type="9" OnClick={updateListHandler}></RoundedButton> :
                      <RoundedButton Name="Edit" Type="9" OnClick={applyEditMode}></RoundedButton>}
                  </div>
                </div>
                <div className="w-full grid">
                  <WalletList list={walletList} isEdit={isEdit} onDelete={deleteWallet} editWallet={editWallet}/>
                </div>
              </div>
            </Panel></Fragment> :
          <Fragment>
            <div className="p-2 items-center justify-between text-left w-full order-1 flex">
              <div>Linked Wallets</div>
              {isEdit ? <RoundedButton Name="Update" Type="9" OnClick={updateListHandler}></RoundedButton> :
                <RoundedButton Name="Edit" Type="9" OnClick={applyEditMode}></RoundedButton>}
            </div>
            <Panel>
              <div className="w-full">
                <WalletList list={walletList} isEdit={isEdit} onDelete={deleteWallet} editWallet={editWallet}/>
              </div>
            </Panel></Fragment>
      }
    </Fragment >
  );
}
export default WalletAuthMesh;