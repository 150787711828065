import minusIcon from "Assets/Icons/GalleryControls/operation-minus.svg";
import plusIcon from "Assets/Icons/GalleryControls/operation-plus.svg";
import './InputSlider.scss';
import { useCallback, useEffect, useState } from "react";
const InputSlider = ({className = '', showOperationicon = true, max= 100, min=0, onChange, value=0, step = 1}) => {
    const [rangeValue, setRangeValue] = useState(0)
    useEffect(() => {
        setRangeValue(value);
    }, [value])
    const handleChange = (e) => {
        let val = Number(e.target.value);
        onChange(val);
    }
    const increment = useCallback((e) => {        
        setRangeValue(prev => {
            let newValue = Number((Number(prev) + Number(step)).toFixed(2));
            let val = newValue <= max ? newValue : Number(prev);
            onChange(val);
            return val
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[max]);
    const decrement = useCallback((e) => {        
        setRangeValue(prev => {
            let newValue = Number((Number(prev) - Number(step)).toFixed(2));
            let val = newValue >= min ? newValue : Number(prev);
            onChange(val);
            return val
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[min]);
    return (
        <div className={`input-slider ${className}`}>
            {showOperationicon && <span className="cursor-pointer icon" onClick={decrement}><img src={minusIcon} alt="" /></span>}
            <input type="range" onChange={handleChange} value={rangeValue} min={min} max={max} step={step}
            className="w-full h-1 bg-gray-200 rounded-lg appearance-none cursor-pointer range-sm" />
            {showOperationicon && <span className="cursor-pointer icon" onClick={increment}><img src={plusIcon} alt="" /></span>}
        </div> 
    )
}
export default InputSlider;