import { useStore } from "App/hooks-store/store";
import AccordionList from "../../../Components/Accordion";
import userAvatar from "Assets/Icons/userAvatars.svg";
import SmCard from "App/Pages/Discover/Components/SubComponents/AccordianCard/SmCard";
import { useEffect, useState } from "react";
import useScreenSize from "App/hooks/use-screen-size";
import { findSelectedSubcategory } from "App/Helper/utilities";

const AnnouncementsList = ({
  data,
  page,
  editHandler,
  deleteHandler,
  normalAccordion,
  readApi,
  type,
  formatDates
}) => {
  const screenSize = useScreenSize()?.width;
  const [{ communityDetails ,spinnerMode}] = useStore(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [loader, setLoader] = useState(0);
  useEffect(()=>{
    const clearTime =setTimeout(()=>setLoader((prev)=>prev+1),1000)
    return ()=>clearTimeout(clearTime);
  },[spinnerMode])
  const handleAnnouncementClick = (e) => {
    let id = parseInt(e.currentTarget?.dataset?.id);
    if (id !== selectedAnnouncement?.id) {
      let selected = data?.find((item) => item?.id === id);
      setSelectedAnnouncement(selected);
    } else {
      setSelectedAnnouncement(null);
    }
  };  
  const shortenMonthName = (dateString) => {
    const monthAbbreviations = {
      January: 'Jan', February: 'Feb', March: 'Mar', 
      April: 'Apr', May: 'May', June: 'Jun',
      July: 'Jul', August: 'Aug', September: 'Sep', 
      October: 'Oct', November: 'Nov', December: 'Dec'
    };
  
    for (const [month, abbreviation] of Object.entries(monthAbbreviations)) {
      if (dateString.includes(month)) {
        return dateString.replace(month, abbreviation);
      }
    }
    return dateString; // Return original string if no month name is found
  };
  const formatDate = (dateString) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
  }; 
  const titleHandler = (value) => {
    const formattedDate = formatDates  ? formatDate(value.createdAt) : shortenMonthName(value.createdAt);
    const selectedSubcategory = findSelectedSubcategory(value?.categories ?? []);
    return (
      <>
       {!(page === "creators") && (
        <div className="flex time-title-wrapper gap-2" key={value?.id}>
        
            <div className="pro-img">
              <img
                src={
                  value?.community?.displayPicture256 ||
                  communityDetails?.displayPicture256 ||
                  userAvatar
                }
                alt=""
              />
            </div>
            <div>
              <div className="flex flex-col items-start gap-2 md:flex-row md:items-center mb-2">
              <h5 className="titles-text !min-w-fit">
                {value?.community?.name || communityDetails?.name}
              </h5>
              {value?.isFollowing && 
              <span className="post_following_tag text-[0.8rem] text-[var(--cu-white-100)] px-2 pb-1 bg-[var(--cu-black-800)] rounded-sm">Following</span>}
              </div>
              <p className="brief-desc" key={value?.id}>
                {value?.headline}
              </p>
            </div>
        </div>
          )}
      
          {!!(page === "creators") && (
            <div className="flex gap-[1rem]">
                    <div className="pro-img">
                    <img
                      src={
                        value?.communityPic ||
                        userAvatar
                      }
                      alt=""
                    />
                  </div>
                  <div>
                  <h5 className="titles-text  w-[10rem]">
            {value?.communityName}
          </h5>
              <p className="brief-desc " key={value?.id }>
              {value?.name}
            </p>
            </div>
            </div>
          )}
      
        <div>
          {selectedSubcategory && <span className="post_category_tag text-[0.8rem] text-[var(--cu-white-100)] px-2 pb-1 bg-[var(--cu-black-800)] rounded-sm mr-6">{selectedSubcategory}</span>}
          <span className="date mr-[2rem]">{formattedDate}</span>
        </div> 
      </>
    );
  };
  return (
    <div
      className={`accordion-list ${
        screenSize <= 550 && "flex flex-col gap-[0.625rem]"
      }`}
    >
      {data?.length ? (
        data?.map((val, i) => {
          return screenSize > 550 ? (
            <AccordionList
              key={val?.id}
              Title={() => titleHandler(val)}
              desc={val?.details}
              value={val}
              readApi={readApi}
              normalAccordion={normalAccordion}
              editHandler={editHandler}
              deleteHandler={deleteHandler}
            />
          )
           : 
           // page === "creator" ? (
          //   <AccordionList
          //     key={val?.id}
          //     Title={() => titleHandler(val)}
          //     desc={val?.details}
          //     value={val}
          //     readApi={readApi}
          //     normalAccordion={normalAccordion}
          //     editHandler={editHandler}
          //   />
          // ) : (
            <SmCard
              className={""}
              data={val}
              page={page}
              readApi={readApi}
              editHandler={editHandler}
              deleteHandler={deleteHandler}
              communityName={true}
              selectedCard={selectedAnnouncement}
              handleCardClick={handleAnnouncementClick}
            />
          // );
        })
      ) : (type==="main-page"?loader>=1:true) && !spinnerMode? (
        <div className="flex items-center justify-center no-data visibleSlowly">
          There are currently no posts. 
        </div>
      ) : null}
    </div>
  );
};

export default AnnouncementsList;
