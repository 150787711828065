
const CustomBtn = props => {
    return (
        <button
            data-a={props.dataA}
            className={`${props.className}`}
            type="button"
            onClick={props.onClick}
        >
            {props.children}
        </button>

    )
}

export default CustomBtn