import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'App/hooks-store/store';
import useHttp from 'App/hooks/use-http';
import { useNavigate, useParams } from 'react-router-dom';
import DiscoverGallery from 'App/Pages/Discover/Components/SubComponents/DiscoverGallery';
import useScreenSize from 'App/hooks/use-screen-size';
import useDiscoverApiHelper from 'App/Pages/Discover/Components/API/apiHelper';
import storage from 'App/Helper/storage';
import useAuth from 'App/hooks/use-auth';
import PreviewGallery from 'App/Pages/NftGallery/Component/MyGallery/Components/PreviewGallery';
import DetailsSkeltonCard from 'App/Components/Ui/detailsSkeleton';

const paginationConfig = {
    perPage: 100
}

const GalleryTab = (props) => {

    const { triggerAPI } = useHttp();
    const navigate = useNavigate();
    const { tab } = useParams();
    const screenSize = useScreenSize()?.width;
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    const isLoggedIn = useAuth()[0]
    const [{ currentWallet }, dispatch] = useStore(false);

    // Gallery related
    const [galleryData, setGalleryData] = useState(null);
    const [publicGalleryData, setPublicGalleryData] = useState(null);
    const [privateGalleryData, setPrivateGalleryData] = useState(null);
    const [previewGallery, setPreviewGallery] = useState({ mode: false, data: [] });
    const [selectedGallery, setSelectedGallery] = useState(null);
    const [assetPreviewGallery, setAssetPreviewGallery] = useState({ mode: false, data: [] });
    const [page, setPage] = useState(1);
    const [totalGalleryCount, setTotalGalleryCount] = useState(0);
    const [galleryListNew, setGalleryListNew] = useState([])
    const [showGallerySkelton, setShowGallerySkelton] = useState(false);

    const getGalleryResult = useCallback((res) => {
        const { list, pageMeta } = res.data;
        setGalleryData(list);
        props.setNoGallery(list?.length ? false : true)
        setTotalGalleryCount(() => pageMeta?.totalItems);
        props.onCountUpdate('my-galleries', pageMeta?.totalItems)
        setTimeout(() => {
            setShowGallerySkelton(false)
        }, 600)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getGalleryList = useCallback(() => {
        const token = storage().get('token');
        if (token) {
            let filter = {
                sortBy: "",
                orderBy: ""
            }
            if (!!(currentWallet?.address) || isLoggedIn) {
                setShowGallerySkelton(true)
                triggerAPI({
                    url: `gallery/user/list/get`, data: {
                        page: 1,
                        items: 1000,
                        filter
                    }, method: 'post'
                }, getGalleryResult);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getGalleryResult, triggerAPI])

    const onPageChangeHandler = (page) => {
        setPage(page)
    }
    const openPreviewGallery = (e) => {
        setPreviewGallery({ mode: true, data: { id: e, type: 0 } })
    }

    const handlePreviewGalleryCard = (e) => {
        openPreviewGallery(selectedGallery?.id);
    }

    const openAssetPreviewGallery = (e) => {
        setAssetPreviewGallery({ mode: true, data: { id: e?.id, type: 0 } })
    }
    const handleGalleryCardClick = (e) => {
        let selected = galleryData && galleryData?.find((gal => gal?.id === parseInt(e)));
        let data = {
            id: selected?.id
        }
        setPreviewGallery({ mode: true, data: { id: e, type: 0 } })
        navigate(`/profile/user/${tab}/view`)
        // getStandAloneGallery(data, (res => {
        //     selected.assets = res?.data?.assets;
        //     selected.Shapes = res?.data?.Shapes;
        //     setSelectedGallery(selected);
        //     isPortrait && screenSize <= 550 && openAssetPreviewGallery(selected)
        // }))
    }
    const handleBackToGallery = () => {
        setPreviewGallery({ mode: false, data: [] });
        props.onBackToGallery()
    }

    useEffect(() => {
        if (!!tab) {
            setGalleryData([])
            setGalleryListNew([])
            if (!!currentWallet?.address || isLoggedIn) {
                getGalleryList()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWallet?.address, tab])

    const discoverableGalleries = galleryData?.filter((gallery) => gallery?.isDiscoverable === true);
    const nonDiscoverableGalleries = galleryData?.filter((gallery) => gallery?.isDiscoverable === false);

    return (
        <div className='profile_view-gallery-tab'>
            {!previewGallery?.mode && <div className='profile_view-manage-btn-box'>
                <button onClick={props.onManageClick} className='new-btn_style_001 profile_view-manage-btn'>
                    {' '} Manage Gallery</button>
            </div>}
            {previewGallery?.mode ?
                <div className="">
                    <PreviewGallery
                        galleryUpdateId={previewGallery?.data?.id}
                        viewFromOther={true}
                        standalone={true}
                        enableEdit={false}
                        selectedWall={''}
                        galleryType={previewGallery?.data?.type}
                        fromDiscover={false}
                        onClickExit={handleBackToGallery}
                        applyFrameButton={false}
                        className={'top-0 px-[1rem] pt-0 pb-0'}
                        onClose={handleBackToGallery}
                    />
                </div>
                :
                <div className={`flex flex-col`}>
                    <DiscoverGalleryWrapper
                        galleryList={discoverableGalleries}
                        handleGalleryCardClick={handleGalleryCardClick}
                        selectedGallery={selectedGallery}
                        page={page}
                        assetPreviewGallery={assetPreviewGallery}
                        hidePagination={true}
                        totalGalleryCount={totalGalleryCount}
                        openAssetPreviewGallery={openAssetPreviewGallery}
                        handlePreviewGalleryCard={handlePreviewGalleryCard}
                        onPageChangeHandler={onPageChangeHandler}
                        setSelectedGallery={setSelectedGallery}
                        showGallerySkelton={showGallerySkelton}
                        title={'Public'}
                    />
                    <DiscoverGalleryWrapper
                        galleryList={nonDiscoverableGalleries}
                        handleGalleryCardClick={handleGalleryCardClick}
                        selectedGallery={selectedGallery}
                        page={page}
                        assetPreviewGallery={assetPreviewGallery}
                        hidePagination={true}
                        totalGalleryCount={totalGalleryCount}
                        openAssetPreviewGallery={openAssetPreviewGallery}
                        handlePreviewGalleryCard={handlePreviewGalleryCard}
                        onPageChangeHandler={onPageChangeHandler}
                        setSelectedGallery={setSelectedGallery}
                        showGallerySkelton={showGallerySkelton}
                        title={'Private'}
                    />
                </div>
            }
        </div>
    )
}

function DiscoverGalleryWrapper(props) {

    return (<>
        <div className="w-full text-start px-[1rem]">{props?.title} <span className="text-[#606573]">({props?.galleryList?.length})</span>
        </div>
        {
            !props.showGallerySkelton ?
                <div className="px-[1rem]">
                    <DiscoverGallery
                        galleryList={props.galleryList}
                        handleCardClick={props.handleGalleryCardClick}
                        selectedGallery={props.selectedGallery}
                        page={props.page}
                        type="collectors"
                        previewGallery={props.assetPreviewGallery}
                        hidePagination={true}
                        totalItems={props.totalGalleryCount}
                        openAssetPreviewGallery={props.openAssetPreviewGallery}
                        perPage={paginationConfig?.perPage}
                        openPreviewGallery={props.handlePreviewGalleryCard}
                        onPageChangeHandler={props.onPageChangeHandler}
                        setSelectedGallery={props.setSelectedGallery}
                    />
                </div>
                : <div className={`pt-0 p-4 pr-sm `}>
                    <DetailsSkeltonCard
                        show={props.showGallerySkelton}
                        height={"100%"}
                        count={3}
                        inline={true}
                        details={"1-line"}
                        noAbsolute
                        parentDiv="grid home-grid mt-0 aspect-pt-six  details-gap"
                        containerClassName={`flex`}
                    />
                </div>}
    </>)

}
export default GalleryTab