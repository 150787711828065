import React from 'react'
import ReactECharts from 'echarts-for-react';
import { useState, useEffect } from "react";
import * as echarts from 'echarts';

const SalesNVolChart = ({ apiData, type, chartHeader, label }) => {

    const [chartOptions, setChartOptions] = useState(null);
    const [loading, setLoading] = useState(true);

    const bindChartData = (apiData) => {
        const data = []
        apiData?.map((item, i) => {
            data[i] = [
                item?.onlyDate,
                item?.dailySales,
                item?.dailyVolume
            ]
            return item
        })
        let option = {
            dataset: {
                source: data
            },
            tooltip: {
                trigger: 'axis',
                //trigger: 'item', //needed to custom format tooltip
                axisPointer: {
                    type: 'line',
                },
                formatter: function (params) {
                    let rez = '<p>' + (params[0].axisValue) + '</p>';
                    rez += `<p> Sales ${params[0].data[1]} </p>`;
                    rez += `<p>Volume ${params[0].data[2]} </p>`;

                    return rez;
                }
            },
            grid: [
                {
                    left: '10%',
                    right: '10%',
                    bottom: 80,

                },
                {
                    left: '10%',
                    right: '10%',
                    bottom: 80
                }
            ],
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {
                        onZero: false,
                        lineStyle: { color: "#64748B" }
                    },

                    splitLine: { show: false },
                    axisLabel: {
                        formatter: function (params) {
                            return params;
                        }
                    },
                },
                {
                    type: 'category',
                    gridIndex: 1,
                    boundaryGap: false,
                    axisLine: { onZero: false },
                    axisTick: { show: false },
                    splitLine: { show: false },
                    axisLabel: { show: false },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: 'Sales',
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    offset: 30,
                    splitLine: {
                        show: false,
                        lineStyle:
                            { color: '#2e343e' }
                    },
                    nameTextStyle: {
                        padding:  [0, 0, 10, 30],
                        color: '#D0D5DD'
                    },
                    axisLabel: {
                        color: "#64748B"
                    },
                    position: 'right',
                    axisLine: {
                        show: !true,
                        lineStyle: {
                            color: '#D0D5DD'
                        }
                    }
                },
                {
                    scale: true,
                    gridIndex: 1,
                    // axisLabel: { show: false },
                    // axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: false },
                    type: 'value',
                    name: 'Volume',
                    position: 'left',
                    alignTicks: true,
                    yAxisIndex: 1,
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#D0D5DD'
                        }
                    },
                    nameTextStyle: {
                        padding:  [0, 50, 10, 0]
                    },
                    axisLabel: {
                        color: "#64748B"
                    },
                    offset: 10,

                },

            ],


            series: [
                {
                    type: 'bar',
                    smooth: true,
                    itemStyle: {
                        color: '#64748B',
                    },
                    showSymbol: false,
                    encode: {
                        x: 0,
                        y: 1
                    },
                    tooltip: {
                        formatter: 'Test'
                    },
                    barWidth: '50%',
                },
                {
                    name: 'Volume',
                    type: 'line',
                    itemStyle: {
                        color: '#A0ABBB',
                        borderRadius: [20, 20, 0, 0],
                    },
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    encode: {
                        x: 0,
                        y: 2
                    }
                }
            ]
        };
        setChartOptions(option);
        setLoading(false);
    }

    useEffect(() => {
        bindChartData(apiData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiData])

    return (<>
        {
            chartOptions && !loading ?
                <div className={`border  border-[var(--cu-black-50)] bg-[var(--cu-black-500)]  rounded-xl`}>
                    <div className="flex border-b border-b-gray-700 p-[1rem]">
                        <p>{chartHeader}</p>
                    </div>
                    {apiData?.length ? <div className={`h-[40dvh] m-[1rem] relative`}>
                    <span className={`absolute -rotate-90 top-1/2 transform  -translate-y-1/2 text-[#64748B] -left-12`}>{label}</span>
                        <ReactECharts
                            style={{
                                height: '100%',
                            }}
                            option={chartOptions}
                        />
                    </div> : <div className="h-[15dvh] py-[1rem] flex justify-center items-center">{`No data available`}</div>}
                </div>
                : null}
    </>)
}

export default SalesNVolChart