/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDiscoverApiHelper from "../Components/API/apiHelper";
import InfiniteLoader from "App/Components/InfiniteLoader";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import PageListing from "../Communities/Components/CommunityCard";
import { useStore } from "App/hooks-store/store";

const paginationConfig = {
  perPage: 24,
};
const Photographers = () => {
  const [photographersListOriginal, setPhotographersListOriginal] = useState([]);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState(null);
  const [showFollowing, setShowFollowing] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(null);
  const [{ activeMenuIcons }, dispatch] = useStore();
  const { getDiscoverCommunities } = useDiscoverApiHelper();
  const navigate = useNavigate();
  const timeOut = useRef(null);
  const userGroupOptions = useMemo(() => {
    return [
      {
        id: 1,
        label:'All Communities',
        value: "all",
        count: ``,
      },
      {
        id: 2,
        label:'Following',
        value: "following",
        count: ``,
      },
    ]
  }, []);

  useEffect(() => {
    dispatch("setTopMenuBarOptions", { showMenuIcons: true });
    dispatch("enableTopIcons", {
      filter: true,
      search: true,
      sorting: false,
      lgView: false,
      xlgView: false,
      smView: false,
      listView: false,
      dropDown: false,
      favourite: false,
      filterSort: false,
      userGroup: true,
    });
    dispatch("setFilterContent", { category: false,collectionStorage:false });
    dispatch("setActiveIcons", { search: "" });
    dispatch("setUserGroup", userGroupOptions);
    return () => {
      dispatch('resetAll')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCollectionList = useCallback(
    (pageNo, following) => {
      const dataParam = {
        categoryId: "photography",
        isFollowed : following ?? showFollowing,
        queryData: {
          page: pageNo ?? page,
          items: paginationConfig?.perPage,
          search: searchValue??"",
          collectionEditionsIds: activeMenuIcons?.filter?.editionIds ?? [],
          collectionStorageIds: activeMenuIcons?.filter?.storageIds ?? [],
          termsIds: activeMenuIcons?.filter?.termsIds ?? [],
          collectionFormatIds: activeMenuIcons?.filter?.formatIds ?? [],
          collectionTagIds: activeMenuIcons?.filter?.tagIds ?? [],
          collectionTypeIds: activeMenuIcons?.filter?.typeIds ?? [],
          shapes: activeMenuIcons?.filter?.shapes
            ? activeMenuIcons?.filter?.shapes?.map((el) => `"${el}"`)
            : [],
        },
      };
      setLoading(true);
      getDiscoverCommunities(dataParam, (res) => {
        const { list, pageMeta } = res;
        setPhotographersListOriginal((prev) =>
          pageNo === 1 ? [...list] : [...prev, ...list]
        );
        setTotalPage(pageMeta.totalItems);
        setLoading(false);
      });
    },
    [searchValue, activeMenuIcons?.filter]
  );

  useEffect(() => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }

    timeOut.current = setTimeout(() => {
      setSearchValue(activeMenuIcons?.search);
      setPage(1);
    }, 600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.search]);

  useEffect(() => {
      setLoading(true);
    if (Object.keys(activeMenuIcons)?.length&&searchValue!==null) {
        setPhotographersListOriginal([]);
      window.scrollTo(0, 0);
      setPage((pre) => 1);
      getCollectionList(1);
    }
  }, [searchValue, activeMenuIcons?.filter]);


  useEffect(() => {
    setShowFollowing(()=>activeMenuIcons?.userGroup?.value === 'following' ? true : false);
    getCollectionList(1, activeMenuIcons?.userGroup?.value === 'following' ? true : false);
  }, [activeMenuIcons?.userGroup]);

  const loadMore = (e) => {
    if (!loading) {
      getCollectionList(page + 1);
      setPage((pre) => pre + 1);
    }
  };
  const onCardClick = (id) => {
    const data = photographersListOriginal?.find(
      (collect) => collect?.id === parseInt(id)
    );
    if (id) {
      window.scrollTo(0, 0);
      navigate(`/discover/communities/${data?.slug}/overview`);
    }
  };
  return (
    <div className="discover-collection h-full w-full flex flex-col communities-listing">
        <div className="text-left mt-[.5rem] mb-[0.5rem] text-[1.25rem]">
          Photographers
        </div>
      {!!photographersListOriginal?.length && (
        <div className="flex-1 h-full flex flex-col justify-between">
          <InfiniteLoader
            loadData={loadMore}
            length={photographersListOriginal?.length}
            loaderCount={3}
            hasMore={photographersListOriginal?.length < totalPage}
            loader="details-skeleton"
            parentDiv="grid home-grid mt-0 aspect-pt-six-three"
          >
             <div className="relative">
             <PageListing
              list={photographersListOriginal}
              onCardClick={onCardClick}
            />
            </div>
          </InfiniteLoader>
        </div>
      )}
      {loading && !photographersListOriginal?.length && (
        <div className="pr-4 mt-4">
          <DetailsSkeltonCard
            show={true}
            height={"100%"}
            count={6}
            inline={true}
            details={"img-2-line"}
            noAbsolute
            parentDiv="grid home-grid mt-0 aspect-pt-six-three"
          />
        </div>
      )}
      {!loading && loading !== null && !photographersListOriginal?.length && (
        <div className="flex items-center no-data justify-center">
          No items to display.
        </div>
      )}
    </div>
  );
};

export default Photographers;
