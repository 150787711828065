import { Outlet, useLocation, useParams } from "react-router-dom";
import "../Discover/Components/SubComponents/CommonStyle.scss";
import { useEffect } from "react";
import { useStore } from "App/hooks-store/store";
import useScreenSize from "App/hooks/use-screen-size";
const Explore = () => {
  const dispatch = useStore(false)[1];
  const screenSize = useScreenSize()?.width;
  const { subcategory } = useParams();
  useEffect(() => {
    return () => {
      dispatch("resetAll");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { pathname } = useLocation();
  useEffect(()=>{
    const element = document.getElementById('explore');
    if (element) {
        setTimeout(() => {
            element.scroll({ behavior: 'smooth', top: 0 })
        }, 80)
    }
  },[pathname])
  return (
    <div id="explore"
      className={`h-full discover-module ${
        subcategory
          ? "pb-16 sm:pb-2 pl-4 pr-0 sm:pr-0 pt-4"
          : !pathname?.includes("creators/details")
          ? "pb-16 sm:pb-2 pl-4 pr-4 sm:pr-4 pt-4"
          : ""
      } ${screenSize > 550 && "page-shadow pt-4"} ${
        subcategory ? "overflow-hidden" : "overflow-lg-auto"
      }`}
    >
      <Outlet />
    </div>
  );
};
export default Explore;
