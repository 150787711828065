import "./WalletList.scss"
import { Fragment } from "react"
import WarningBox from "App/Components/Ui/WarningBox"
import { useState } from "react"
import Listing from "./listing"

const WalletList = ({ list = [], isEdit = true, onDelete,editWallet }) => {
    const [show, setShow] = useState(false);
    const [selectedWallet, setSelectedWallet] = useState();
   
    const onDeleteConfirm = (e) => {
        const { dataset } = e.currentTarget;
        setShow(true)
        setSelectedWallet(dataset.wallet)
    }
    const cancelPopup = () => {
        setShow(false)
    }
    const onDeleteHandler = (e) => {
        setShow(false)
        onDelete(selectedWallet)
    }
   
    return (
        <Fragment>
            <div className="flex w-full gap-4">
                {list?.length ?
                    <ul className="flex justify-between items-start w-full flex-wrap mob-connected gap-4 p-4">
                        {list?.map((wallet, index) => <Listing editWallet={editWallet} onDeleteConfirm={onDeleteConfirm} key={wallet?.id} wallet={wallet} isEdit={isEdit} index={index} />
                      )}
                    </ul> : <p className="p-4">No Wallets Available</p>}
            </div>
            {show && <WarningBox onApprove={onDeleteHandler} onCancel={cancelPopup} approveBtn="Disconnect" cancelBtn="Cancel">
                Are you sure you want to Disconnect this wallet?
            </WarningBox>}
        </Fragment>
    )
}
export default WalletList;