import NftRow from "./Components/NftRow"
import { useMemo, useState } from "react"
import { useEffect } from "react"
import { useStore } from "App/hooks-store/store";
import '../../../NftTableDisplay/NftTableDisplay.scss';
import CustomizedTable from "App/Components/Ui/CustomTable/CustomizedTable";

const SelectedNftTableDisplay = props => {
    // eslint-disable-next-line no-unused-vars
    const [arrowFlip, setArrowFlip] = useState({field: null, sortName: 'Name A-Z', flip: false})
    const [nftDetails, setNftDetails] = useState([]);
    const [nftList, setNftList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([])
    // eslint-disable-next-line no-empty-pattern
    const [{ }, dispatch] = useStore();
    
    useEffect(() => {
        if(props.nfts?.list?.length){
            setNftDetails([...props.nfts?.list]);
        }
    },[props?.nfts?.list])

    const uniqueMetaData = useMemo(()=>{
        const metaData = [];
        props?.nfts?.list?.forEach((nft)=>metaData?.push(nft?.onchain_metadata));
        const getUniqueItems = (arr) => {
            const uniqueData = {};
        
            arr.forEach((item) => {
              const keys = Object.keys(item);
        
              keys.forEach((key) => {
                if (!uniqueData[key]) {
                  uniqueData[key] = [];
                }
        
                if (!uniqueData[key].includes(item[key])) {
                  uniqueData[key].push(item[key]);
                }
              });
            });
        
            return uniqueData;
        };

        let title = [{ name: 'Pieces', isSort: true, sortName: 'Name Z-A' }]
          if(metaData?.length){
            const unique = metaData?.length > 1 ? getUniqueItems(metaData) : metaData[0];
            Object.entries(unique)?.forEach(([key, value]) => {
                let lengthOfValue = Array.isArray(value) ? value?.length > 1 : true;
                value = Array.isArray(value) ? value[0] : value;

                if(lengthOfValue && key !== 'name' && typeof value === "string" && key !== 'image' && key !== 'mediatype' && key !== "mediaType"&&!value?.includes("ipfs")&&!value?.includes("www.")&&!key.includes("--") && !value?.includes("https://")&&!value?.includes(".com") && !!value) {
                    title.push({ name: key, isSort: true, sortName: 'Name Z-A' })
                } else if (key === 'parameters'){
                    title.push({ name: key, isSort: false, })
                }
            })
          }
          
        setNftList([...props.nfts?.list]);
        return title
    },[props.nfts?.list])

    useEffect(() => {
        dispatch('enableTopIcons', {
            frame: false,
            caption: false,
            search: true,
            sorting: false,
        })
        return () => {
            dispatch('enableTopIcons', {
                frame: false,
                caption: false,
                favourite: false
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const sortByKey = (key, orderAz) => {
        const tempData = nftList?.map((nft) => ({ ...nft, ...nft?.onchain_metadata }));
        const sortedData = [...tempData].sort((a, b) => {
            if (typeof a[key] === 'string') {
                return orderAz ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
            } else if (typeof a[key] === 'number') {
                return orderAz ? a[key] - b[key] : b[key] - a[key];
            } else {
                return 0; // Default case if the type is neither string nor number
            }
        });
        setNftList(sortedData)
    };

    useEffect(() => {
        const filedName = arrowFlip?.field === 'Pieces' ? 'name' : arrowFlip?.field;
        sortByKey(filedName, arrowFlip?.flip)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arrowFlip]);

    const sortByHandler = (_e, headerName) => {
        if(arrowFlip?.field && headerName === arrowFlip?.field){
            setArrowFlip({field: headerName, flip: !arrowFlip?.flip})
        } else {
            setArrowFlip({field: headerName, flip: true})
        }
    }

    return (
        <CustomizedTable tableHead={props?.nfts?.list?.length && uniqueMetaData?.length ? uniqueMetaData : []} mainContainerClass={'collectionTable2'} tableHeadClass={'tbl-header-asset'} activeSort={arrowFlip} onClick={sortByHandler}  >
            {nftList?.map((nft, index) =>
                    <NftRow
                        key={nft.asset}
                        tableHeader={uniqueMetaData}
                        onImgClick={props?.onImgClick} index={index}
                        framesList={props.framesList} nft={nft}
                        selectedRows={selectedRows}
                    />
                )
            }
        </CustomizedTable>
    )
}

export default SelectedNftTableDisplay