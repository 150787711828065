import ControlBody from "../SubComponents/ControlBody";
import ControlCard from "../SubComponents/ControlCard";
import ControlContent from "../SubComponents/ControlContent";
import ControlTitleCard from "../SubComponents/ControlTitleCard";
import displayPortIcon from "Assets/Icons/GalleryControls/display-port-d.svg";
import displayPortIconActive from "Assets/Icons/GalleryControls/display-port-s.svg";
import displayLandIcon from "Assets/Icons/GalleryControls/display-land-d.svg";
import displayLandIconActive from "Assets/Icons/GalleryControls/display-land-s.svg";
import displaySqIcon from "Assets/Icons/GalleryControls/display-sq-d.svg";
import displaySqIconActive from "Assets/Icons/GalleryControls/display-sq-s.svg";
import displayPlayIcon from "Assets/Icons/GalleryControls/display-play.svg";
import GalleryImages from "App/Components/Ui/GalleryFrameImages";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { removeDuplicateByElement } from "App/Helper/utilities";
const DisplayControl = ({className = '', list = [], setFilteredGalleryList, currentPlayingAsset = null, slideGoTo, show = false}) => {
    const [playingList, setPlayingList] = useState([]);
    const [selectedRatios, setSelectedRatios] = useState([]);
    const [allAspectRatio, setAllAspectRatio] = useState([]);
    const [availAspectRatio, setAvailAspectRatio] = useState([]);
    const getShapes = (aspectRatio) => {
        let hasSquare = aspectRatio?.filter(ar => ar === 1).length > 0;
        let hasLandscape = aspectRatio?.filter(ar => ar > 1).length > 0;
        let hasPortrait = aspectRatio?.filter(ar => ar !== "" && ar < 1).length > 0;   
        return { hasSquare, hasLandscape, hasPortrait}
    }
    const shapes = useMemo(() => {
        let tempShape = getShapes(availAspectRatio)

        let tempSelectedShapes = [];
        if(tempShape?.hasLandscape)
            tempSelectedShapes = [...tempSelectedShapes, 'land']
        if(tempShape?.hasPortrait)
            tempSelectedShapes = [...tempSelectedShapes, 'port']
        if(tempShape?.hasSquare)
            tempSelectedShapes = [...tempSelectedShapes, 'sq']
        setSelectedRatios(tempSelectedShapes);
        return tempShape;
    }, [availAspectRatio])
    const newList = useMemo(() => {
        let newlist = removeDuplicateByElement(list, 'asset');
        if(list?.length === allAspectRatio?.length){
            newlist = newlist?.map(item => {
                let temp = allAspectRatio?.find(ratio => ratio?.index === item?.asset)
                let tempShape = getShapes([temp?.ratio])
                let shape = tempShape?.hasLandscape ? 'land' : (tempShape?.hasPortrait ? 'port' : 'sq')
                if(temp)
                    item = {...item, ratio: temp?.ratio, shape: shape}
                return item
            })
        }
        return newlist;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[list, allAspectRatio])
    useEffect(() => {
        let filterList = [...newList];
        if(selectedRatios?.length)
            filterList = filterList?.filter(item => selectedRatios?.includes(item?.shape));
        setFilteredGalleryList(filterList);
        setPlayingList(filterList);
        if(newList?.length !== filterList?.length)
            slideGoTo(0) //any changes after filter slider needs to start with 0th index
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newList, selectedRatios])
    const imageRatioCalculated = useCallback((ratio, index) => {
        setAllAspectRatio(prevState => [...prevState, { ratio, index }]);
        if (!availAspectRatio.includes(ratio))
            setAvailAspectRatio(prev => [...prev, ratio]);
    },[availAspectRatio])
    const handleShapesClick = useCallback((e) => {
        let type = e?.currentTarget?.dataset?.type;
        let newAspects = selectedRatios?.includes(type) ?  () => {
                                //atleast one selected
                                return selectedRatios?.length > 1 ? selectedRatios?.filter(item => item !== type) : selectedRatios 
                            } :  [...selectedRatios, type];
        setSelectedRatios(newAspects);
    },[selectedRatios]);
    const selectToPlay = useCallback((asset) => {
        let slideIndex = playingList?.findIndex(item => item?.asset === asset) ?? 0; //find index with filtered list
        slideGoTo(slideIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playingList])
    return (
        <ControlCard className={`${className} pop  flex-col gap-5 display-list-control w-[50rem] ${show ? 'show-display-control' : 'hidden-display-control'}`}>
            <ControlBody className="display-list-section">
                <div className="flex gap-2 justify-between">
                    <ControlTitleCard>{`Display List`}</ControlTitleCard>
                    <div className="display-filter flex gap-2 items-end">
                        {shapes?.hasLandscape &&
                            <div className="filter-img" data-type={'land'} onClick={handleShapesClick}>
                                <img src={selectedRatios?.includes('land') ? displayLandIconActive : displayLandIcon} alt="" />
                            </div>
                        }
                        {shapes?.hasPortrait &&
                            <div className="filter-img" data-type={'port'} onClick={handleShapesClick}>
                                <img src={selectedRatios?.includes('port') ? displayPortIconActive : displayPortIcon} alt="" />
                            </div>
                        }
                        {shapes?.hasSquare &&
                            <div className="filter-img" data-type={'sq'} onClick={handleShapesClick}>
                                <img src={selectedRatios?.includes('sq') ? displaySqIconActive : displaySqIcon} alt="" />
                            </div>
                        }
                    </div>
                </div>
                <ControlContent className="display-content listing-grid-control-panel">
                        {newList?.map((item, index) => {
                            let currentPlaying = currentPlayingAsset === item?.asset;
                            let hasInPlayingList = selectedRatios?.includes(item?.shape);
                            return (
                                <div className={`display-list-item collection-card min-view ${currentPlaying && 'selected'}
                                flex flex-col aspect-square w-full relative`} key={item?.asset}> 
                                    <GalleryImages
                                        action='close'
                                        galleryNew={item}
                                        src={item?.details?.nftcdnimage256}
                                        dataId={item?.asset}
                                        className="" nftGalleryNew={true} 
                                        imageRatioCalculated={(e) => imageRatioCalculated(e, item?.asset)}
                                    />
                                    {!currentPlaying && 
                                        <div className={`control-overlay ${!hasInPlayingList && 'disabled'} play-hover`}>
                                            {hasInPlayingList && 
                                                <div className="play-image opacity-0" onClick={()=> selectToPlay(item?.asset)}>
                                                    <img src={displayPlayIcon} alt="hover" />
                                                </div>
                                            }                        
                                        </div>
                                    }
                                </div>
                            )
                        })}
                </ControlContent>
            </ControlBody>
        </ControlCard>
    )
}
export default memo(DisplayControl);