import { useEffect, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import ImagePopup from "../ImagePopup";
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import "./DatePickerFilter.scss"
import RoundedButton from "../RoundedButton";
import { useStore } from "App/hooks-store/store";

const SingleEvent = ({ disableDayPicker = false, setshowDate = () => { }, minDate, otherDate, disableMonthPicker = false, disableYearPicker = false, className, show, showTime, dateSelected, dateObject, onDateSelect }) => {

    const dispatch = useStore(false)[1];
    const [actualFormat, setActualFormat] = useState(null)
    const [mainDate, setMainDate] = useState(null)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const setSelectedDateRange = useState(new Date())[1];

    useEffect(() => {
        if (!!dateObject?.start && !!dateObject?.end) {
            setMainDate(dateObject?.start)
            setStartDate(new Date(dateObject?.start))
            setEndDate(new Date(dateObject?.end))
        } else {
            const defaultStartDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 9, 0, 0, 0)
            const defaultEndDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 10, 0, 0, 0)
            setMainDate(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
            setStartDate(defaultStartDate)
            setEndDate(defaultEndDate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateObject])

    // Only for start time
    const startTimeChange = (e, reset) => {
        if (!actualFormat) {
            dispatch("showToast", {
                toast: {
                    toastMode: "error",
                    message: "Select date first",
                },
            });
            return
        }
        const updatedDate = reset ? new Date(`${actualFormat.month.number}/${actualFormat.day}/${actualFormat.year} 09:00`) : new Date(`${actualFormat.month.number}/${actualFormat.day}/${actualFormat.year} ${e.format("HH:mm")}`)
        setStartDate(updatedDate)
    }
    // Only for end time
    const endTimeChange = (e, reset) => {
        if (!actualFormat) {
            dispatch("showToast", {
                toast: {
                    toastMode: "error",
                    message: "Select date first",
                },
            });
            return
        }
        const updatedDate = reset ? new Date(`${actualFormat.month.number}/${actualFormat.day}/${actualFormat.year} 10:00`) : new Date(`${actualFormat.month.number}/${actualFormat.day}/${actualFormat.year} ${e.format("HH:mm")}`)
        setEndDate(updatedDate)
    }

    // Only for date
    const dateChange = (e) => {
        setActualFormat(e)
        const updatedDate = new Date(`${e.month.number}/${e.day}/${e.year}`)
        setMainDate(updatedDate)
    }

    const onClose = () => {
        if (dateSelected) setSelectedDateRange(new Date(dateSelected))
        else setSelectedDateRange(new Date())
        setshowDate({ mode: false })
        setActualFormat(null)
    }
    const onSave = () => {
        const currentDate = new Date(startDate);
        const targetDate = new Date(endDate);
        if (currentDate >= targetDate) {
            dispatch("showToast", {
                toast: {
                    toastMode: "error",
                    message: "End time must be grater than start time",
                },
            });
        } else {
            onDateSelect({ start: new Date(startDate).toISOString(), end: new Date(endDate).toISOString() })
            setshowDate({ mode: false })
        }
    }

    useEffect(() => {
        if (!!actualFormat) {
            startTimeChange(actualFormat, true)
            endTimeChange(actualFormat, true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualFormat])

    return (
        <ImagePopup show={show} cssClass={`date-desk ${className} ${(dateSelected || actualFormat) && "active"}`} onClose={onClose}>
            <div className='flex p-4'>
                <Calendar
                    // format={"MM/DD/YYYY"}
                    format={"MM/DD/YYYY HH:mm"}
                    value={mainDate}
                    onChange={dateChange}
                    numberOfMonths={1}
                    minDate={new Date(minDate)}
                    disableDayPicker={disableDayPicker}
                    disableMonthPicker={disableMonthPicker}
                    disableYearPicker={disableYearPicker}
                />
                <div className='flex flex-col justify-center gap-4'>
                    <div className='flex flex-col items-center '>
                        <p className="text-[0.75rem]">Start Time</p>
                        <Calendar
                            value={startDate}
                            onChange={startTimeChange}
                            disableDayPicker
                            // format="HH:mm"
                            format={"MM/DD/YYYY HH:mm"}
                            minDate={new Date(minDate)}
                            plugins={[
                                <TimePicker hideSeconds />
                            ]}
                        />
                    </div>
                    <div className='flex flex-col items-center'>
                        <p className="text-[0.75rem]">End Time</p>
                        <Calendar
                            value={endDate}
                            onChange={endTimeChange}
                            disableDayPicker
                            // format="HH:mm"
                            format={"MM/DD/YYYY HH:mm"}
                            minDate={new Date(minDate)}
                            plugins={[
                                <TimePicker hideSeconds />
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center gap-2 px-[1.5rem] date-btn-wrapper mt-[0.5rem] mb-2">
                <RoundedButton
                    className={"cancel-btn flex-1 btn-style"}
                    OnClick={onClose}
                    Name={"Cancel"}
                ></RoundedButton>
                <RoundedButton
                    disabled={!actualFormat}
                    className={`save-btn btn-style save flex-1`}
                    OnClick={onSave}
                    Name={'Confirm'}
                ></RoundedButton>
            </div>
        </ImagePopup>
    )
}


export default SingleEvent