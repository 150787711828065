import GalleryPreviewImage from "App/Pages/NftGallery/Component/MyGallery/Components/AddedNfts/Components/GalleryPreviewImage";
import { memo } from "react";

const MultiSlider = memo(({item, i, onChainControl, selectedLayout, multiInnerClassName = '', bgSrc = ''}) => { 
    return (<div key={`${Math.random + onChainControl?.clickCount}`} className="preview-wrap">
        <GalleryPreviewImage fileName={`file${i}`} selectedLayout={selectedLayout} addedGallery={true} layoutType='Card-8' layoutCount={item?.length} 
        src={bgSrc} className={multiInnerClassName} selectedNfts={item} onChainRenders={onChainControl?.onChainRenders}
        multiViewClass={'multi-view'} isMultiGallery={true}/>
</div>)
})
export default MultiSlider;