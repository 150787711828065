import { useEffect, useState } from "react"
import copyIcon from "Assets/Icons/copy-icon-grey.svg";
import adaIcon from "Assets/Icons/ada_svg_grey.svg";
import shapesEmpty from "Assets/Images/shapesEmpty.svg";
import { useStore } from "App/hooks-store/store";
import useShapes from "App/hooks/use-shapes";
import { numberWithCommas } from "App/Helper/utilities";
const DetailsTab = props => {
    const [shapes, setShapes] = useState(shapesEmpty);
    const [{ }, dispatch] = useStore()
    const { saveCollectionShape, getShapes } = useShapes();

    useEffect(() => {
        let ratios = props?.communityUserDetails?.shapes.map(shape => Number(shape.aspectRatio));
        setShapes(getShapes(ratios));

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props?.communityUserDetails])
    

      function formatDate(dateString) {
        if (dateString === null || isNaN(new Date(dateString))) {
            return 'None';
        }
    
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    }
      const copyClipHandler = () => {
        var textArea = document.createElement("textarea");
        const policyId = props.communityUserDetails?.collectionId ?? props?.collection?.policy ??props?.nft?.nft?.policy
        textArea.value = policyId;
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.width = ".09px";
        textArea.style.opacity = "0";
    
        textArea.style.position = "fixed";
    
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand("copy");
        dispatch("showToast", {
          toast: { toastMode: "success", message: "Copied to clipboard" },
        });
        document.body.removeChild(textArea);
      };
    return (<div className={`${props?.className} flex flex-col items-start pb-[1rem] pr-[1.25rem] overflow-auto h-[100%] bg-[#0D0F11]`}>
 <div className="flex flex-col text-[#B8C0CC] text-[.875rem] font-normal leading-normal tracking-[.0175rem]">Description</div>
    <div className="flex flex-col   border-[#191D23] border-b py-[1.5rem] text-[#E7EAEE] text-[.875rem] text-[.875rem] font-normal leading-normal tracking-[.0175rem] text-left w-[100%] h-fit whitespace-pre-wrap ">{props.communityUserDetails?.description || props?.collection?.description || "No description"}</div>
    <div className="flex flex-col  py-[1.5rem]  text-[#B8C0CC] text-[.875rem] font-normal leading-normal tracking-[.0175rem] ">Information</div>
    <div className="flex flex-col gap-2 text-[.875rem] w-[100%] pb-8  border-[#191D23] border-b">
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#64748B] text-right ">ID</div>
            <div
              className={`collection-ids flex`}
            >
              <div className="value flex gap-2 align-center">
              <img
                className={``}
                alt="Img"
                src={adaIcon}
              />
              {props?.collection?.policy ? props?.collection?.policy?.length < 8
                ? props?.collection?.policy
                : props?.collection?.policy?.slice(0, 4) +
                "..." +
                props?.collection?.policy?.slice(-4)
              :
              props.communityUserDetails?.collectionId.slice(0, 4) +
              "..." +
              props.communityUserDetails?.collectionId.slice(-4)
              }
              <img
                onClick={copyClipHandler}
                className="cursor-pointer"
                alt="Img"
                src={copyIcon}
              />
            </div>
            </div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#64748B] text-right ">Category</div>
            <div>
            {props.communityUserDetails?.category?.name ?? '-'}
            {props.communityUserDetails?.firstSubcategory?.name && ` - ${props.communityUserDetails?.firstSubcategory?.name}`}
          </div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#64748B] text-right ">Pieces</div>
            <div>{numberWithCommas(props?.communityUserDetails?.metrics[0]?.supply ?? props.array?.nft?.assetCount ?? props.communityUserDetails?.assetCount) ?? '-'}</div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#64748B] text-right ">Owners</div>
            <div>{numberWithCommas(props?.communityUserDetails?.metrics[0]?.owners ?? props.array?.nft?.ownerCount ?? props.communityUserDetails?.ownerCount) ?? '-'}</div>
        </div>
        <div className="flex gap-2">
            <div className="w-[5rem] text-[#64748B] text-right ">Shapes</div>
            <div className={`flex w-8 justify-center`}>
                <img
                  className={`max-w-[1.3rem]`}
                  alt="Img"
                  src={shapes}
                />
              </div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#64748B] text-right ">Formats</div>
            <div>{props.communityUserDetails?.formats.map(format => format.name).join('/') ?? '-'}</div>
        </div>
        <div className="flex gap-4">
        <div className="w-[5rem] text-[#64748B] text-right">Types</div>
          <div>
          {props.communityUserDetails?.types.length
            ? props.communityUserDetails.types.map((format) => format.name).join('/')
            : '-'}
            </div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#64748B] text-right ">Editions</div>
            <div>{props.communityUserDetails?.collectionEditions?.name ?? '-'}</div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#64748B] text-right ">Storage</div>
            <div>{props.communityUserDetails?.collectionStorage?.name ?? '-'}</div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#64748B] text-right ">Terms</div>
            <div>{props.communityUserDetails?.collectionTerms?.liscenceName ?? '-'}</div>
        </div>
        <div className="flex gap-4">
        <div className="w-[5rem] text-[#64748B] text-right">Tags</div>
        <div>
          {props.communityUserDetails?.tags.length
              ? props.communityUserDetails.tags.map((format) => format.name).join('/')
              : '-'}
            </div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#64748B] text-right ">Mint Date</div>
            <div>{formatDate(props.communityUserDetails?.mintDate) ?? '-'}</div>
        </div>
   
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#64748B] text-right ">Lock Date</div>
            <div>{formatDate(props.communityUserDetails?.lockDate) ?? '-'}</div>
        </div>
    </div>
   {props.communityUserDetails?.community?.name && <div className="pt-[1rem]">{props.communityUserDetails?.community?.name}</div>}
    {props.communityUserDetails?.community?.description && <><div className="flex flex-col w-full text-left  py-[1.5rem] text-[#B8C0CC] text-[.875rem] font-normal leading-normal tracking-[.0175rem]">Description</div>
    <div className="flex flex-col  text-[#E7EAEE] text-[.875rem] font-normal leading-normal tracking-[.0175rem] text-left w-[100%] h-fit whitespace-pre-wrap pb-[2rem]">{props.communityUserDetails?.community?.description}</div></>}
    {props.communityUserDetails?.community?.about && <><div className="border-[#191D23] border-t flex flex-col w-full text-left  pt-[2rem] pb-[1.5rem] text-[#B8C0CC] text-[.875rem] font-normal leading-normal tracking-[.0175rem]">About Us</div>
    <div className="flex flex-col  pb-[1.5rem] text-[#E7EAEE] text-[.875rem] font-normal leading-normal tracking-[.0175rem] text-left w-[100%] h-fit whitespace-pre-wrap ">{props.communityUserDetails?.community?.about}</div></>}
 </div>)
}

export default DetailsTab