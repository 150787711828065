import Input from "App/Components/Ui/Input";
import { useEffect, useState } from "react";
import "./ListenList.scss";
import MusicPlayer from "App/Components/MusicPlayer";
import searchIcon from "Assets/Icons/TopMenu/search-icon.svg";
import duration from "Assets/Icons/duration.svg";
import { useStore } from "App/hooks-store/store";
import { getDurationOfAudio, getMetadataValue } from "App/Helper/utilities";
import SortHeader from "./Components/sortHeader";

const ListenList = ({ collection, isPreview, className }) => {
  const [searchValue, setSearchValue] = useState("");
  const [playList, setPlayList] = useState([]);
  const [initialPlayList, setInitialPlayList] = useState([]);
  useEffect(() => {
    if (collection?.length) {
      if (playList?.length !== collection?.length) {
        let updatedPolicyList = collection?.map((nft) => {
          let audioFiles = nft?.files?.filter((file) =>
            file?.mediaType?.toLowerCase()?.includes("audio")
          );
          let updatedAudioFileWithTitleAndDuration = audioFiles?.map((file) => {
            let nfttitle =
              nft?.onchain_metadata?.song_title ??
              nft?.onchain_metadata?.song?.title ??
              nft?.onchain_metadata?.song?.song_title ??
              nft?.onchain_metadata?.song?.name ??
              nft?.onchain_metadata?.title ??
              nft?.onchain_metadata?.name ??
              "Unknown";
            let title =
              getMetadataValue(
                [file.song, file],
                ["song_title", "title", "name"]
              ) ??
              nfttitle ??
              "Unknown";

            let duration = getMetadataValue(
              [file.song, file],
              ["song_duration", "duration"]
            );

            if (duration?.startsWith("PT")) {
              duration = getDurationOfAudio(duration);
            }
            return {
              ...file,
              song_details: { song_name: title, song_duration: duration },
            };
          });

          return { ...nft, files: [...updatedAudioFileWithTitleAndDuration] };
        });
        setInitialPlayList(updatedPolicyList);
      }
    } else setInitialPlayList([]);
  }, [collection, playList]);
  const dispatch = useStore(false)[1];
  const onSearchPlayListHandler = (value) => {
    let searchResult = initialPlayList?.map((nft) => {
      let audioFiles = nft?.files?.filter((file) =>
        file?.mediaType?.toLowerCase()?.includes("audio")
      );
      let filteredFile = audioFiles?.filter((file) =>
        file?.song_details?.song_name?.toLowerCase()?.includes(value)
      );
      return { ...nft, files: [...filteredFile] };
    });
    setPlayList(searchResult);
  };
  const onChangeHandler = (e) => {
    setSearchValue(e.search);
  };
  const playCollection = () => {
    dispatch("setMusicPlaylist", playList);
  };
  useEffect(() => {
    if (searchValue?.trim()) {
      onSearchPlayListHandler(searchValue?.trim());
    } else {
      setPlayList(initialPlayList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, initialPlayList]);

  return (
    <div className={`listen-playlist pt-4 overflow-auto ${className}`}>
      <div className="filter-section flex items-center justify-between pr-[1.625rem] pl-[0.625rem]  mb-4">
        <div
          className="playlist-icon cursor-pointer"
          onClick={playCollection}
        ></div>
        <div className="search-section create-edit-community max-w-[13.75rem] w-full">
          <Input
            maxlength={60}
            type="text"
            label="search"
            icon={searchIcon}
            onChange={onChangeHandler}
            controlName="search"
            value={searchValue}
          ></Input>
        </div>
      </div>
      <div className="listen-list-title flex items-center justify-between ">
        <div className="flex gap-[0.725rem]">
          <div className="header-title">#</div>
          <SortHeader
            playList={playList}
            setPlayList={setPlayList}
            keyName={"song_name"}
          >
            Title
          </SortHeader>
        </div>
        <div className="header-title">
          <SortHeader
            playList={playList}
            setPlayList={setPlayList}
            keyName={"song_duration"}
          >
            <img src={duration} alt="" />
          </SortHeader>
        </div>
      </div>
      <MusicPlayer collection={playList} isPreview={isPreview}></MusicPlayer>
    </div>
  );
};

export default ListenList;
