import styles from './Navbar.module.scss'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import arrow from 'Assets/Icons/nav-arrow.svg'
import menuExpandIcon from 'Assets/Icons/TopMenu/arrow-withbg.svg'
import { useCallback, useState } from 'react'
import useScreenSize from 'App/hooks/use-screen-size'
import LogOutWarn from 'App/Layouts/Header/Components/LogOutWarn/LogOutWarn'
import { useStore } from 'App/hooks-store/store'
import useHttp from 'App/hooks/use-http'
import useAuth from 'App/hooks/use-auth'
import RoundedButton from '../Ui/RoundedButton'
import WalletSelectPop from '../Ui/WalletSelectPop'
import Twitter from "Assets/Icons/x.svg";
import Youtube from "Assets/Icons/utube.svg";
import Discord from "Assets/Icons/w-discord.svg";
import Support from 'Assets/Icons/social/Support.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getShowWalletPop,setLoggingOutStatus,setShowWalletPop } from 'App/ReduxStore/Slicers/authSlice'

const NavBar = props => {
    const { pathname } = useLocation()
    const screenSize = useScreenSize()?.width;
    const [openNav, setOpenNav] = useState('')
    const [{ currentWallet }, dispatch] = useStore(false);
    const logout = useAuth()[1];
    const isLoggedIn = useAuth()[0]
    const navigate = useNavigate()
    const [logOutWarning, setLogOutWarning] = useState(false)
    const { triggerAPI } = useHttp();
    const reduxDispatch = useDispatch();
    const showWalletAuthPop = useSelector(getShowWalletPop);

    const openNavHandler = e => {
        if (openNav === e.target.dataset["nav"])
            setOpenNav('')
        else
            setOpenNav(e.target.dataset["nav"])
    }
    const onMainNavCollapse = () => {
        setOpenNav('')
        props?.onMainNavCollapse();
    }
    const closeSubNav = () => {
        if (props?.mainNavExpand)
            setOpenNav('')
    }
    const closeSubNavFromMain = () => {
        setOpenNav('')
    }
    const onLogout = () => {
        reduxDispatch(setShowWalletPop(false))
        props.onNavCollapse()
        setLogOutWarning(true)
    }
    const logoutHandler = () => {
        reduxDispatch(setShowWalletPop(false));
        reduxDispatch(setLoggingOutStatus(true))
        dispatch('showSpinner')
        triggerAPI({
            url: `user/logOut`, method: 'post'
        }, logoutApiResponse);
    }  
    const logoutApiResponse = useCallback((res) => {
        dispatch('hideSpinner');
        logOutWarnCloseHandler()
            logout()
        setTimeout(()=>{
            reduxDispatch(setLoggingOutStatus(false))
        },200)
    }, [dispatch,reduxDispatch, logout])
    const logOutWarnCloseHandler = (params) => {
        setLogOutWarning(false)
    }
    const handleTrialClick = (e) => {
        props.onNavCollapse()
         reduxDispatch(setShowWalletPop(false))
    }
    const appLinks = [{ id: 1, text: "User Guide" }, { id: 4, text: "Privacy Policy" }, { id: 3, text: "Terms of Use" }]
    const appLinkClick = (tab) => {
        props.onNavCollapse()
        navigate("information-desk", { state: tab })
    }
    const onClickHandler=(e,elm)=>{
    if(!elm?.walletCheck) reduxDispatch(setShowWalletPop(false))
     dispatch('setCurrentGalleryUpdateId', '');
    }
    const loginHandler=()=>{
        reduxDispatch(setShowWalletPop(false));
         props?.onNavCollapse(); 
         navigate("auth/sign-in")
    }
    return (
        <>
            <div className={`${styles['nav-bar']} ${(props.BorderRadius === 10) && 'border-rad-10'} ${props.classList} w-full flex-auto ${props?.mainNavExpand ? styles['active'] : ''} `}>
                {props?.isMainNav &&
                    <div className='w-full'>
                        <div className={`flex  ${styles['second-nav']} ${props?.mainNavExpand ? styles['active'] : 'justify-between'} items-center`}>
                            <span className={`${styles["menu-text"]} ${props?.mainNavExpand ? 'opacity-0 w-0' : ''} ${styles["main-text"]}`}>Dashboard</span>
                            <span className={`cursor-pointer ${props?.mainNavExpand ? `${styles['active']} flex justify-center w-full ml-[0.1rem]` : ''}`}>                            <img src={menuExpandIcon} alt='menu expand icon' className={`${styles["menu-icon-top"]}`} onClick={onMainNavCollapse} />
                            </span>
                        </div>
                    </div>
                }
                <ul className={`w-full ${styles["unordered-list"]}`}>
                    {
                        (props.Elements).map(elem => (<li className='flex-col' key={elem.id} onClick={(e)=>onClickHandler(e,elem)}>
                            {!!elem.externalLink && <a href={elem.link} target="_blank" rel="noreferrer">
                                <div className='flex items-center gap-[0.625rem]'>
                                    <img src={elem?.icon} className={`${styles["menu-icon"]}`} alt='icon' />
                                    <span className={` ${styles["menu-text"]} ${props?.mainNavExpand ? `${styles["inactive"]} ` : 'active'}`}>{elem.element}</span>
                                </div>
                            </a>}
                            {!!elem?.header && <div className={`flex  ${props?.mainNavExpand ? styles['collapsed-nav'] :  styles['first-nav']} justify-between  items-center`}>
                            <span className={`${styles["menu-text"]} ${props?.mainNavExpand ? styles["third-nav"] : ""} ${styles["expand-text"]} ${styles["main-text"]}`}>{elem?.header}</span>
                                </div>}
                                {!!elem?.divider && <hr className={`${styles["divider"]}`}/>}
                            {!elem.subNav && !elem.externalLink &&  
                                <NavLink onClick={props.onNavCollapse} className={({ isActive }) => (isActive ? styles['link-active'] : 'active')} to={elem.link}>
                                    <div className='flex items-center gap-[0.625rem]' onClick={closeSubNavFromMain}>
                                        <img src={elem?.icon} className={`${props?.isMainNav ? 'expandActive' : ''} ${styles["menu-icon"]}`} alt='icon' />
                                        <span className={` ${styles["menu-text"]} ${props?.mainNavExpand ? `${styles["inactive"]} ` : 'active'}`}>{elem.element}</span>
                                    </div>
                                </NavLink>}
                            {elem.subNav && !elem.externalLink &&
                                <div className='flex flex-col w-full'>
                                    <div data-nav={elem.element} onClick={openNavHandler} className={`${styles["nav-parent"]} ${props?.mainNavExpand && elem?.subNav?.some(val => pathname?.includes(val?.link)) && styles['link-active']} flex gap-2.5`}>
                                        <div data-nav={elem.element} onClick={openNavHandler}>
                                            <div className={` flex items-center gap-[0.625rem]`} data-nav={elem.element} onClick={openNavHandler}>
                                                <img src={elem?.icon} data-nav={elem.element} onClick={openNavHandler} className={`${styles["menu-icon"]}`} alt='icon' />
                                                <span data-nav={elem.element} onClick={openNavHandler} className={`${styles["menu-text"]} ${props?.mainNavExpand ? `${styles["inactive"]} ` : ''}`}>{elem.element}</span>
                                            </div>
                                        </div>
                                        <div className={`hidden sm:flex items-center `}>
                                            <img data-nav={elem.element} onClick={openNavHandler} alt="NoImg" className={`${styles["menu-icon-arrow"]} self-center ${openNav === elem.element && styles["menu-icon-arrow-active"]}`} src={arrow} />
                                            {/* {openNav === elem.element && <img data-nav={elem.element} onClick={openNavHandler} alt="NoImg" className={`${styles["menu-icon-arrow"]} self-center`} src={arrowDown} />} */}
                                        </div>
                                    </div>
                                    {((openNav === elem.element) || screenSize <= 550) && <ul className={`.${styles["unordered-list"]} ${styles["menu-text"]} ${elem?.section && styles["menu-section"]} ${styles["menu-sub-nav"]} ${props?.mainNavExpand ? `${styles["inactive"]} ` : ''} ${elem.subNav.length ? styles["childul"] : ''}`}>
                                        {(elem.subNav).map((subNav, index) => {
                                            return <li key={index} className={`${styles["nav-child"]} ${props?.mainNavExpand && pathname?.includes(subNav.link) && styles['link-active']} font-14 `} onClick={closeSubNav}>
                                                <NavLink onClick={props.onNavCollapse} target={subNav.externalLink ? "_blank" : "_self"} className={({ isActive }) => (isActive ? styles['link-active2'] : '') } to={subNav.link}>
                                                    <div onClick={() => pathname?.includes(subNav.link) && window.location.reload()}>{subNav.element}</div>
                                                </NavLink>
                                            </li>
                                        })
                                        }
                                    </ul>}
                                    {openNav === elem.element && props?.mainNavExpand && <div className={`${styles["menu-collpased-overlay"]}`} onClick={closeSubNav}></div>}
                                </div>
                            }
                        </li>))
                    }
                </ul>
                {screenSize <= 550 &&<> <div className={styles['nav-button-wrapper']}>
                    {!isLoggedIn && !currentWallet?.address ?
                        <div className='flex flex-col gap-[0.25rem]'>
                            <div className={styles['signIn-button']}>
                                <RoundedButton OnClick={loginHandler} className={styles['register-btn']} Name="Login" Type={screenSize > 550 ? '1' : '7'}></RoundedButton>
                            </div>
                            <div OnClick={handleTrialClick} className={styles['trial-button']}>
                                <RoundedButton OnClick={handleTrialClick} Name="Trial" Type="1"></RoundedButton>
                            </div>
                        </div> : <div onClick={onLogout} className={styles['trial-button']}>
                            <RoundedButton onClick={onLogout} Name="Log Out" Type={'1'}></RoundedButton>
                        </div>

                    }
                </div>
                <div className={styles['border-bottom-nav']}></div>
                </>
                }
                {screenSize <= 550 && <><div className={`flex justify-between ${styles["app-link-wrapper"]}`}>
                    {appLinks?.map(el => <div key={el?.id} onClick={() => appLinkClick(el?.id)} className={styles["app-link"]}>{el?.text}</div>)}
                </div>
                
                <div className={`${styles["social-media"]}`}>
                <span className={""}>
                    <a
                      href="https://twitter.com/Cur8Labs"
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <img className="" src={Twitter} alt="twitter" />
                    </a>
                  </span>
                  <span className={""}>
                    <a
                      href="https://www.youtube.com/@cur8galleries"
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <img src={Youtube} alt="twitter" />
                    </a>
                  </span>
                  <span className={""}>
                    <a
                      href="https://discord.gg/vHqfQ5vPFd"
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <img className="" src={Discord} alt="discover" />
                    </a>
                  </span>
                  <span className={""}>
                  <a href='' className="social-icons" target="_blank" rel="noreferrer">
                    <img alt='NoImg' className="social-icons" src={Support} />
                </a>
                </span>
                </div>
                </>}

            </div>
            {!isLoggedIn &&
                <>
                    <WalletSelectPop show={showWalletAuthPop} className={styles['home-wallet-pop']} />
                </>
            }
            {logOutWarning &&
                <LogOutWarn onLogOut={logoutHandler} onCancel={logOutWarnCloseHandler} />
            }
        </>
    )

}

export default NavBar