import Panel from "App/Components/Ui/Panel";
import  "./TopNFTdetails.scss";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import copyIcon from "Assets/Icons/copy-icon-grey.svg";
import adaIcon from "Assets/Icons/ada_svg_grey.svg";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useEffect } from "react";
// import enlargeIcon from "Assets/Icons/enlargeIcon.png";
// import zoomOutIcon from "Assets/Icons/minimize.svg";
import { formatLargeNumber, getCeilValue, numberWithCommas, popupMobileScrollAdj } from "App/Helper/utilities";
// import shapesThree from "Assets/Images/shapesThree.svg";
// import shapesSquare from "Assets/Images/shapesSquare.svg";
// import shapesLandscape from "Assets/Images/shapesLandscape.svg";
// import shapesPortrait from "Assets/Images/shapesPortrait.svg";
// import shapesSquareLandscape from "Assets/Images/shapesSquareLandscape.svg";
// import shapesSquarePortrait from "Assets/Images/shapesSquarePortrait.svg";
// import shapesLandscapePortrait from "Assets/Images/shapesLandscapePortrait.svg";
import shapesEmpty from "Assets/Images/shapesEmpty.svg";
import getTime from "App/Helper/getTime"
import { getShortDate } from "App/Helper/getDate";
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import useShapes from "App/hooks/use-shapes";
import userAvatar from "Assets/Icons/logo-square.svg";
import musicPlay from 'Assets/Icons/music-play.svg';
import xIcon from 'Assets/Icons/social/Twitter.svg'
import discordIcon from 'Assets/Icons/social/Discord.svg'
import youtubeIcon from 'Assets/Icons/social/YouTube.svg'
import shareIcon from 'Assets/Icons/gray-share-icon.svg'
import recycleIcon from 'Assets/Icons/Recycle-icon.svg'
import infoIcon from 'Assets/Icons/infoIconSquare.svg'

const TopNftDetails = React.memo((props) => {
  const navigate = useNavigate();
  const [tabs, setTabs] = useState(["Collection", "Announcements", "Events"]);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [metricsData, setMetricsData] = useState({});
  const [eventAnnouncementData, setEventAnnouncementData] = useState([]);
  const dispatch = useStore(false)[1];
  const [isFav, setIsFav] = useState(false);
    const { triggerAPI } = useHttp();
  const [zoom, setZoom] = useState(false);
  const [shapes, setShapes] = useState(shapesEmpty);
  const [imageHasLoaded, setImageHasLoaded] = useState(false);
  const [mainImageLoaded, setMainImageLoaded] = useState(false);
  const [collectionUpdateLoading, setCollectionUpdateLoading] = useState(false);
  const location = useLocation();
  const [store] = useStore();
  const { collections } = store;
  const { saveCollectionShape, getShapes } = useShapes();
  const communityUserDetails = props?.communityUserDetails

  useEffect(() => {
    if (location.pathname.includes('collections/my-tokens')) {
      setTabs(["Token"]);
      setSelectedTab("Token");
    } else {
      setTabs(["Collection", "Announcements", "Events"]);
      setSelectedTab("Collection");
    }
  }, [location.pathname]);
  useEffect(() => {
    props?.collections?.forEach((element) => {
      if (element?.asset === props.nft?.nft?.asset) {
        setIsFav(element.isCollectionFavourite);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nft]);
  const collectionSelectHandler = () => {
    props?.onCollectionSelect(props.nft?.nft);
    window.scrollTo(0, 0);
  };
  const copyClipHandler = () => {
    var textArea = document.createElement("textarea");
    textArea.value = props.nft?.nft?.policy;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = ".09px";
    textArea.style.opacity = "0";

    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
    dispatch("showToast", {
      toast: { toastMode: "success", message: "Copied to clipboard" },
    });
    document.body.removeChild(textArea);
  };

  const truncateText = (text, maxWords) => {
    if (!text) return ''; 
  
    const words = text.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '... ';
    }
    return text;
  };

  useEffect(() => {
    popupMobileScrollAdj(zoom);
  }, [zoom]);
  useEffect(() => {
    let collection = collections?.filter(c => c.collectionId === props?.nft?.nft.collectionId);
    if (collection.length > 0) collection = collection[0];
    let ratios = !!collection?.aspectRatios ? collection?.aspectRatios?.split(',').map(ratio => Number(ratio)) : null;
    setShapes(getShapes(ratios));
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [props?.nft, imageHasLoaded]);

  const imageLoaded = (e) => {    

    if (props?.nft?.nft?.collectionImage256 || props?.nft?.nft?.nftcdnimage256) {
      setTimeout(() => {
        setMainImageLoaded(true)
      }, 500)
    }
    if (e?.target && !props?.nft?.nft?.collectionImage)
    {                
        let ratio = e.target.naturalWidth/e.target.naturalHeight                
        saveCollectionShape(props?.nft?.nft?.collectionId, ratio, () => { setImageHasLoaded(true) });        
    }
  }
  useEffect(() => {
    if (props.nft && props.nft.nft) {
      collectionSelectHandler();
      if(props?.nft?.nft?.metrics?.length){
        setMetricsData(props?.nft?.nft?.metrics[0])
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nft]);
  const navigatocreator = (e) => {
    e.stopPropagation();
    navigate(`/discover/communities/${communityUserDetails?.community?.slug}/overview`);
  }

  const playCollection = () => {    
    dispatch('setMusicPlaylist', props.policyList);    
  }
  const descriptionHandler=()=>{
    let nftDescription = Array.isArray(props?.nft?.nft?.description)?props?.nft?.nft?.description?.join(" "):typeof props?.nft?.nft?.description==="string"?props?.nft?.nft?.description:"";
    return communityUserDetails?.description || nftDescription
  }
  const openInNewTab = (url) => {
    if (url) {
      window.open(url, "_blank", "noreferrer");
    }
  };
  return (
    <div
      className={`nft-details pr-[1rem] ${zoom && props.nft && "zoom-nft"
        } `}
    >
        <Panel border-r-1 borderLess className="h-full">
          <div
            className={`nft-panel2`}
          >
            <div className="h-[10rem] aspect-square">
             <img
                className={`${mainImageLoaded ? 'h-full w-full  rounded-md object-cover object-center' : 'opacity-0 h-0 w-0'}`}
                src={props?.nft?.nft?.collectionImage256 ?? props?.nft?.nft?.nftcdnimage256}
                alt="NoImg"
                onLoad={imageLoaded}
              /> 
              {!mainImageLoaded && <SkeletonLoader className={`h-[10rem] aspect-square`} count={1}/>}
          </div>
            <div className={`details-tryout h-full overflow-hidden`}>
              {selectedTab === tabs[0] ? (
                <>
                  <div
                    className={`details-box w-[100%]`}
                  >
                    <div className="flex justify-between w-full">
                      <div className="flex gap-4 items-center">
                      <img
                        className="w-[1.5rem] h-[1.5rem] rounded-full object-cover"
                        alt="Img"
                        src={communityUserDetails?.community?.displayPicture || userAvatar}
                      />

                      <div className={`flex flex-col justify-center`}>
                        {/* <img alt="NoImg" src={nftIcon} /> */}
                          <p className="collection-named">{props?.nft?.nft?.collectionName??props?.nft?.nft?.name}</p>
                        {/* <div>{props.nft.nft.total}</div> */}
                        {/* <ul className={`details-title-sm`}><li>Owners</li></ul>
                                <div>{props.nft.nft.owners}</div> */}
                      </div>
                      <div className={`flex gap-3 items-center`}>
                        <p className="community-named">
                          {communityUserDetails?.community?.name && <span>by </span>} 
                          {communityUserDetails?.community?.name ? 
                          <span onClick={navigatocreator} className="cursor-pointer">{communityUserDetails?.community?.name}</span>
                          : ""}
                          </p>
                          {!!communityUserDetails?.community?.name && <img
                            className="cursor-pointer w-[16px] hover:opacity-90"
                            alt="Img"
                            src={shareIcon}
                            // onClick={}
                          />}
                        </div>
                    </div>
                    <div 
                      onClick= {props.onCloseDetails}
                      style={{
                          color: "#A0ABBB",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                          textDecorationLine: "underline",
                          cursor: "pointer",
                      }}
                  >
                      All Collections
                  </div>
                      </div>

                  <div
                    className={`flex w-full justify-between`}
                  >
                    {/* Detail Boxes */}
                    <div
                      className={`flex w-full mt-[0.5rem] max-h-[3.5rem]`}>

                    {/* Floor box */}
                    <div className={`common__data-box`}>
                      <p className={`data-title`}>Floor</p>
                      <div className={`data-value`}>
                        {metricsData?.floorPrice ? <span className={`flex items-center gap-1`}> <img
                          className={`common_image_style`}
                          alt="Img"
                          src={adaIcon}
                        /> {metricsData?.floorPrice}</span> : <p className="data-value">NA</p>}
                      </div>
                    </div>

                    {/* Volume box */}
                    <div className={`common__data-box`}>
                      <p className={`data-title`}>Volume</p>
                      <div className={`data-value`}>
                        {metricsData?.allVolume ? <span className={`flex items-center gap-1`}> <img
                          className={`common_image_style`}
                          alt="Img"
                          src={adaIcon}
                        /> {formatLargeNumber(metricsData?.allVolume) ?? 'NA'}</span> : <p className="data-value">NA</p>}
                      </div>
                    </div>

                    {/* Pieces box */}
                    <div className={`common__data-box`}>
                      <p className={`data-title`}>{selectedTab !== "Token" ? "Pieces" : "Supply"}</p>
                      {collectionUpdateLoading ? <SkeletonLoader height={15} count={1} inline={true} /> :
                        <div className={`data-value flex`}>
                          {numberWithCommas(metricsData?.supply ?? props.communityUserDetails?.assetCount) ?? "0"}
                        </div>
                      }
                    </div>

                    {!!(selectedTab !== "Token") && <>

                      {/* Owners box */}
                      <div className={`common__data-box`}>
                        <p className={`data-title`}>Owners</p>
                        {collectionUpdateLoading ? <SkeletonLoader height={15} count={1} inline={true} /> :
                          <div className={`data-value flex`}>
                            {numberWithCommas(metricsData?.owners ?? props.communityUserDetails?.ownerCount) ?? "NA"}
                          </div>
                        }
                      </div>

                       {/* Shapes box */}
                      <div className={`common__data-box`}>
                        <p className={`data-title`}>Shapes</p>
                        <div className={`data-value`}>
                          <img
                            className={`shapes_box-style`}
                            alt="Img"
                            src={shapes}
                          />
                        </div>
                      </div>

                       {/* Collection id box */}
                      <div className={`common__data-box`}>
                        <div className={`data-title`}>Collection ID</div>
                        <div
                          className={`collection-ids flex`}
                        >
                          <div className="data-value flex gap-1 items-center">
                            <img
                              className={`common_image_style`}
                              alt="Img"
                              src={adaIcon}
                            />
                            {props?.nft?.nft?.policy?.length < 8
                              ? props?.nft?.nft?.policy
                              : props?.nft?.nft?.policy?.slice(0, 4) +
                              "..." +
                              props?.nft?.nft?.policy?.slice(-4)}
                            <img
                              onClick={copyClipHandler}
                              className="cursor-pointer common_image_style"
                              alt="Img"
                              src={copyIcon}
                            />
                          </div>
                        </div>
                      </div>
                    </>}

                    {/* Social box */}
                    {(!!communityUserDetails?.community?.twitter || !!communityUserDetails?.community?.discord || !!communityUserDetails?.community?.youtube) ?
                      <div className={`data-box-last`}>
                        <p className={`data-title`}>Socials</p>
                        <div className={`flex items-center gap-2`}>
                          {!!communityUserDetails?.community?.twitter && <img
                            className="cursor-pointer w-[12px] hover:opacity-90"
                            alt="Img"
                            src={xIcon}
                            onClick={() => openInNewTab(communityUserDetails?.community?.twitter)}
                          />}
                          {!!communityUserDetails?.community?.discord && <img
                            className="cursor-pointer w-[18px] hover:opacity-90"
                            alt="Img"
                            src={discordIcon}
                            onClick={() => openInNewTab(communityUserDetails?.community?.discord)}
                          />}
                          {!!communityUserDetails?.community?.youtube && <img
                            className="cursor-pointer w-[18px] hover:opacity-90"
                            alt="Img"
                            src={youtubeIcon}
                            onClick={() => openInNewTab(communityUserDetails?.community?.youtube)}
                          />}
                        </div>
                      </div> : null}
                    {/* {props?.listen && <div>
                          <button onClick={playCollection}><img className="music-play" src={musicPlay} alt={"Show Music Player"} /></button>
                        </div>}*/}
                  </div>

                    {!!communityUserDetails?.community &&
                    <div className="flex gap-4 items-start">
                       <img
                        className="cursor-pointer w-[2rem] hover:rotate-[360deg] transition duration-500"
                        alt="Img"
                        src={recycleIcon}
                        onClick={() => openInNewTab(`https://www.splash.club/utilities/recycle`)}
                      />
                        <button className="recycle_info_icon_parent" >
                          <img
                            className="cursor-pointer w-[1.4rem]"
                            alt="Img"
                            src={infoIcon}
                          />
                        </button>
                      </div>}
                  </div>

                    {communityUserDetails ? <div className={`details-description`}>
                          {truncateText(descriptionHandler(), 45)}
                          {descriptionHandler() &&
                          <span 
                            className="learn-more-link ml-[.5rem]" 
                            onClick={() => props.setSelectedTab("Details")}
                          >
                            Learn More
                          </span>
                          }
                    </div> :
                    <SkeletonLoader height={'2.5rem'} count={1}/>
                    }
                  </div>
                </>
              ) : (
                <></>
              )}

              {selectedTab === tabs[1] || selectedTab === tabs[2] ? (
                <>
                  <div
                    className={`details-box side-scroller scroller ${eventAnnouncementData?.length === 0 && "pb-none"}`}
                    style={{ height: `${eventAnnouncementData?.length === 0?"100%":window?.innerHeight+"px"}` }}
                  >
                    {eventAnnouncementData?.map((ele, idx) => {
                      return (
                        <div
                          className={`event-container`}
                          key={idx + "_container"}
                        >
                          <div
                            className={`event-date`}
                            key={idx + "_date"}
                          >
                            <p>{getShortDate(ele?.createdAt)}</p>
                            <p className={`event-time`} style={{ display: selectedTab === tabs[2] ? "block" : "none" }}> {getTime(ele?.start)} {getTime(ele?.end)}</p>
                          </div>
                          <div
                            className={`event-content   ${selectedTab === tabs[2] ? "event-text" : ""}`}
                            key={idx + "_content"}
                          >
                            <p className={`event-title`} style={{ display: selectedTab === tabs[2] ? "block" : "none" }}>
                              {ele?.headline}
                            </p>
                            <p style={{ WebkitLineClamp: selectedTab === tabs[1] ? "2" : "1" }}>{ele?.details}</p>
                          </div>
                        </div>
                      );
                    })}
                    <p className={`view-more`} style={{ display: eventAnnouncementData?.length > 10 ? "display" : "none" }}>View more...</p>
                    <p className={`view-more h-full`} style={{ display: eventAnnouncementData?.length === 0 ? "display" : "none" }}>{selectedTab==="Announcements"?"No Announcements":"No Events"}</p>

                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Panel>

    </div>
  );
});

export default TopNftDetails;