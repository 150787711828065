import NftFrame from "App/Components/NftFrame";
import RoundedButton from "App/Components/Ui/RoundedButton";
import { useStore } from "App/hooks-store/store";
import { useEffect, useState } from "react";
const EnLargeFrameList = (props) => {
  const [frameitToggle, setFrameitToggle] = useState(true);
  const [{ activeMenuIcons }] = useStore();
  useEffect(() => {
    setFrameitToggle(!!activeMenuIcons?.frame);
  }, [activeMenuIcons?.frame]);
  return (
    !!props?.currentAsset && (
      <div
        className={`${frameitToggle ? " active" : ""} frame-option-container  overflow-visible`}
      >
        <div className="frame-slider-container flex flex-col gap-4">
          {props?.filteredFrames?.map((framesData, index) => {
            return (
              <div key={`${index}-${framesData.id}`} className="cursor-pointer">
                <div
                  key={index}
                  data-index={index}
                  onClick={props?.onFrameSelect}
                  className={`frame-container ${
                    props?.frameApplied?.id === framesData?.id
                      ? "frameApplied"
                      : ""
                  }`}
                >
                  <div className="w-full h-full mt-auto flex flex-col">
                    <div
                      className={`enlarge-frame ${
                        props?.frameApplied?.id === framesData?.id
                          ? "active"
                          : ""
                      }`}
                    >
                      <NftFrame
                        imageContainerClass={"enlarge-frame-img"}
                        data-index={index}
                        className={""}
                        type="frame-option"
                        frame={
                          framesData?.isLandscape
                            ? framesData?.imageUrlLand256 ??
                              framesData?.imageUrl256
                            : framesData.imageUrl256
                        }
                        skeltonClass="min-h-[4rem]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={` flex flex-col justify-center absolute left-0 right-0 bottom-[1rem] gap-[3rem]`}
        >
          <div className="no-frame-option flex flex-col gap-[0.625rem]">
            <span
              data-index={null}
              onClick={props?.onFrameSelect}
              className={!props?.frameApplied?.id ? "inactive" : ""}
            >
              <RoundedButton Name="No Frame" Type="7"></RoundedButton>
            </span>
            <a
              href={`/curate/frame-shop?previewNft=${props?.currentAsset?.asset}`}
              target="_blank"
              rel="noreferrer"
            >
              <RoundedButton Name="Add Frame" Type="7"></RoundedButton>
            </a>
          </div>
          <div className="no-frame-option flex flex-col gap-4">
            <span className="apply">
            <RoundedButton
              Name="Apply"
              Type="7"
              OnClick={props?.applyFrameHandler}
            ></RoundedButton>
            </span>
          </div>
        </div>
      </div>
    )
  );
};

export default EnLargeFrameList;