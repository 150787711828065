import { BrowserWallet,largestFirst  } from '@meshsdk/core';
import storage from "App/Helper/storage";
import { useStore } from "App/hooks-store/store";
import { useCallback } from "react";
import { useState, useMemo } from "react";
import axios from "axios";
const useWallet = () => {
    const [wallet, setWallet] = useState({});
    const [walletConnected, setWalletConnected] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [walletNameConnected, setWalletNameConnected] = useState('');
    const [connectedAddress, setConnectedAddress] = useState('');
    const [currentNetwork, setCurrentNetwork] = useState(undefined)
    const dispatch = useStore(false)[1];

    const getNFTs = async (_wallet) => {
        let assets = await _wallet.getAssets();
        return assets.filter(a=> a.quantity == 1).map(a => { 
            return {asset: a.unit, policy_id: a.policyId, asset_name: a.unit.replace(a.policyId,'')}
        });    
    }
    const connectWallet =  useCallback(async (walletName) => {
        setConnecting(true);
        let _wallet;
        try {
             _wallet = await BrowserWallet.enable(walletName);
            const _address = await _wallet.getUsedAddresses();
            const _network = await _wallet.getNetworkId();
            const balance = await _wallet.getLovelace();
            if (_wallet) {
                setWallet(_wallet);
                storage().set('currentWallet', walletName);
                let nfts = await getNFTs(_wallet);
                dispatch('setCurrentWallet', { ..._wallet, walletName, balance:`${balance/1000000} ADA`,address:_address[0], roundBalance:`${Math.round(balance/1000000)} ADA`, NFTs: nfts})
                dispatch('setWalletUpdatingStatus', true)
                // dispatch("reFetch")
                setWalletNameConnected(walletName);
                setWalletConnected(true);
                setConnectedAddress(_address[0]);
                if (_network === 0) setCurrentNetwork("Testnet");
                if (_network === 1) setCurrentNetwork("Mainnet");
            }
        } catch (error) {
            
        }
        setConnecting(false);
        return _wallet;
    },[dispatch]);
    const availableWallet = () => {
        return BrowserWallet.getInstalledWallets()
    }
    const constructMarketplaceTx = async (wallet, listing) => {
        const utxos = await wallet.getUtxos();        
        const selectedUtxos = largestFirst((listing.price + 20000000).toString(), utxos, true);
        const changeAddress = await wallet.getChangeAddress();
        let data = {
            "action": "BUY",
            "inputs":selectedUtxos.map(s=>{ return {...s.input, assets: s.output.amount.map(a => {return {lovelace: parseInt(a.quantity)}})[0]}}),
            "txHash": listing.txHash,
            "txIndex": listing.txIndex,
            "outputAddress": changeAddress,
            "offeredToken": {
                "policy": listing.policy,
                "tokenName": listing.tokenName
            }
        }

        
        let axiosData={
            method: "POST",
            url: `https://api.tokeopay.io/api/v1/tx/listing`,          
            headers: {},
            data: data
        }                    
        let response = await axios(axiosData);        
        console.log(response);
        return response.data;
    }
    const memoedValue = useMemo(
        () => ({
            availableWallet,
            wallet,
            connecting,
            walletNameConnected,
            walletConnected,
            connectWallet,
            connectedAddress,
            currentNetwork,
            constructMarketplaceTx
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [wallet, connecting, walletNameConnected, walletConnected, connectedAddress, currentNetwork]
    );

    return memoedValue
}
export default useWallet