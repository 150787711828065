import { useEffect, useState } from "react";
import NftFrame from "App/Components/NftFrame"
import"./ImagesTab.scss"
import { useStore } from "App/hooks-store/store";

const ImagesTab = (props) => {
  const [filesToView, setFilesToView] = useState([])  
  const dispatch = useStore(false)[1];
  
  useEffect(()=> {
    setFilesToView(props?.nft?.files?.filter(f=>f.mediaType?.indexOf("audio") === -1));
  }, [props?.nft?.files])
  
  const setAsDefaultImage = (image) => {      
    let file = filesToView[image];    
    if (file?.mediaType?.indexOf('audio') > -1)
    {                 
      let newPlaylist = [{ ...props.nft, files: [file] }];
      dispatch('setMusicPlaying', false);
      dispatch('setMusicPlayerVisible', true);
      dispatch('setMusicPlaylist', newPlaylist);         
    }
    else
    {      
      props.setPreviewUrl(file.nftcdnfile ?? props?.nft?.nftcdnimage1024);    
    }    
  }

  const imgFunction = (item, index,showFileType) => {
    return <div  className={`${props?.previewUrl === item.nftcdnfile ? 'highlight' : ''} aspect-square object-contain square-container overflow-hidden cursor-pointer image-tab-item ${showFileType&&"file-type-box"}`} key={index} onClick={() => setAsDefaultImage(index)}>      
      <NftFrame imageFile={[item]} showFileType={showFileType}></NftFrame>      
    </div>
  }
  return (
    <div className={`grid grid-cols-6 gap-2 pt-3 h-[100%] max-w-[32.5rem] ${props?.className}`}>
      {filesToView?.map((item, index) => (
        imgFunction(item, index,props?.showFileType)
      ))}
      {!filesToView?.length && props?.nft?.image && imgFunction({ src: props?.nft?.image })}
      {filesToView?.length <= 0 &&!props?.nft?.image? <p className="no-data">No Images</p> : <></>}
    </div>
  );
};
export default ImagesTab;
