/* eslint-disable react-hooks/exhaustive-deps */
import MyCollections from "./Components/MyCollections"
import { useCallback, useEffect, useMemo, useState } from "react"
import SelectedCollectionV2 from "../SubComponents/SelectedCollectionV2"
import { useStore } from "App/hooks-store/store"
import useCollectionLoader from "App/hooks/use-collectionLoader"
import useAuth from 'App/hooks/use-auth';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { filterCollections } from 'App/Helper/utilities';
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton"
import useFrames from "App/hooks/use-frames";
import { useDispatch, useSelector } from "react-redux"
import { getColelctionSpinner, setCollectionSpinner } from "App/ReduxStore/Slicers/spinSlice"
import useCollectionDropDown from "App/hooks/use-collection-dropdwon"
import Border from 'App/Pages/Discover/Components/SubComponents/Border';
import { setShowWalletPop} from 'App/ReduxStore/Slicers/authSlice';
import "./MyCollections.scss"

const NftCollectionsV3 = () => {
    const [store, dispatch] = useStore();
    const { pathname } = useLocation();
    const {collectionType, policyId, basePathType} = useParams();
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()
    const [selectedCollection, setSelectedCollection] = useState(null)
    const [nftCollections, setNftCollections] = useState([])
    const [collectionsList, setNftCollectionsList] = useState([])
    const [framesList, setFramesList] = useState([])
    const [spinnerShow, setSpinnerShow] = useState(false);
    const [collectionLoading, setCollectionLoading] = useState(false);
    const collectionSpinner = useSelector(getColelctionSpinner)
    const { collections, collectionLoadingStatus, currentWallet, tokenRawData,collectionWalletData,
        collectionRawData, activeMenuIcons,tokens, 
        tokenLoadingStatus, singleCollection } = store
    const { sliceTokenAPI, sliceCollectionAPI, sliceSingleCollectionAPI } = useCollectionLoader()
    const [message, setMessage] = useState(null)
    const [displayMode, setDisplayMode] = useState("tile");
    const {getUserFrames} = useFrames();
    const isLoggedIn = useAuth()[0]
    const isLoggedInGuest = useAuth()[4]
    const [searchValue , setSearchValue] = useState("");
    const [loadingStatus , setLoadingStatus] = useState("");
    const [isToken , setIsToken] = useState(collectionType === "my-tokens");
    const { dropDownFilter, updateCollectionFilter, getDropValueByData } = useCollectionDropDown();
    const menuTabs = useMemo(() => {
        return [        
            { id: "all-wallets", element: 'All Wallets', count: collections?.length??0, dependsOn: 'isLoggedIn' },        
            { id: "my-connected-wallet", element: 'Connected Wallet', count: currentWallet?.NFTs?.length??0, dependsOn: 'currentWallet' },        
            { id: "listings", element: 'Listings', count: 0, dependsOn: 'currentWallet' },        
        ]
    }, [currentWallet?.NFTs?.length, collections?.length])
    const [tab, setTab] = useState('all-wallets');
    const [walletHasNFTs, setwalletHasNFTs] = useState(false);    
        useEffect(()=>{
                if(basePathType === 'listen' || basePathType === 'read') {
                    setDisplayMode("lg")
                    dispatch('setActiveIcons', {
                        displayType: 'lg'
                    }) 
                } else {
                    setDisplayMode(walletHasNFTs?'lg':"tile")
                    dispatch('setActiveIcons', {
                        displayType: walletHasNFTs?'lg':'tile'
                    }) 
                }     
            
        },[basePathType,walletHasNFTs])

        // useEffect(()=>{
        //     if(!policyId){
        //         setDisplayMode(displayMode)
        //         dispatch('setActiveIcons', {
        //             displayType: displayMode
        //         }) 
        //     }     
        // },[policyId])

        useEffect(() => {
          if (walletHasNFTs&&currentWallet?.NFTs) {
            setSpinnerShow(true)
            dispatch("resetSingleCollection")
            sliceSingleCollectionAPI(currentWallet?.NFTs);
            setTimeout(()=>setSpinnerShow(false),1000)
          }
        }, [walletHasNFTs,currentWallet]);
    const handleTabs = (selectedTab) => {
        setTab(selectedTab);        
        if (selectedTab === 'my-connected-wallet')
        {
            if (!singleCollection?.length)
            {
                setNftCollectionsList([]);
                if (currentWallet?.NFTs?.length)
                {
                    sliceSingleCollectionAPI(currentWallet?.NFTs);    
                }            
            }    
            else
            {
                setMyCollection(singleCollection,tokens,activeMenuIcons?.filter,searchValue) 
            }    
            setDisplayMode('lg')    
            dispatch('setActiveIcons', {
                displayType: 'lg'
            })
            reduxDispatch(setShowWalletPop(false))
        }
        else if (selectedTab === 'all-wallets')
        {
            setMyCollection(collections,tokens,activeMenuIcons?.filter,searchValue)   
            setDisplayMode('tile') 
            dispatch('setActiveIcons', {
                displayType: 'tile'
            })            
        }
        navigate(`/${basePathType}/collections/my-collections`);
    }    

    useEffect(() => {
        if (currentWallet?.NFTs?.length)
        {            
            setwalletHasNFTs(true);
            if (!isLoggedIn)
            {
                handleTabs('my-connected-wallet');
            }            
        }
    }, [currentWallet])

    useEffect(() => {
        setMyCollection(singleCollection,tokens,activeMenuIcons?.filter,searchValue)   
    }, [singleCollection])

    useEffect(() => {
        if(isLoggedIn || isLoggedInGuest) {
            if(isToken) {
                getToken()
            } else {
                getNfts()
            }           
        }        
        if (basePathType === "listen") {            
            dispatch("setActiveIcons", { filter: { categoryIds: [18]}});
        } else if (basePathType === "read") {
            dispatch("setActiveIcons", { filter: { categoryIds: [17]}});
        }
        else {
            dispatch("setActiveIcons", { filter: { categoryIds: []}});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWallet?.address, basePathType, pathname])
    
    useEffect(()=>{
        setLoadingStatus(""); 
        setSelectedCollection(null)
        setIsToken(collectionType==="my-tokens")
    },[collectionType])

    useEffect(() => {
        return ()=>{
            dispatch("resetAll");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if(isLoggedIn)            
            getFramesList(); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if((isLoggedIn || isLoggedInGuest) && !isToken)
            getNfts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionLoadingStatus,pathname, collections, isToken])

    // useEffect(() => {
    //     if (currentWallet?.NFTs?.length)
    //     {
    //         console.log("currentWallet?.NFTs",currentWallet?.NFTs)
    //         let pageresults = currentWallet?.NFTs?.filter(a => a.asset_name !== "").map(a => {return { asset: policyId??a.policy_id + a.asset_name, policy_id: policyId??a.policy_id,  asset_name: a.asset_name}});              
    //         console.log("pageresults",pageresults)
    //         sliceSingleCollectionAPI(pageresults);    
    //     }
    // }, [currentWallet?.NFTs])

    useEffect(() => {
        if((isLoggedIn || isLoggedInGuest) && isToken)
            getToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenLoadingStatus, tokens,pathname, isToken])

    const getNfts = useCallback(() => {
        setLoadingStatus(collectionLoadingStatus)
        if (collectionLoadingStatus !== 'finished' && collectionLoadingStatus !== 'idle') {
            if(policyId) reduxDispatch(setCollectionSpinner(true))
            else setSpinnerShow(true)
        }
        else {
            if (typeof (collections) !== "string")
                setNftCollections(collections)
            else {
                setNftCollections([])
                setMessage(collections)
            }
            setSpinnerShow(false)
            reduxDispatch(setCollectionSpinner(false))
        }
    }, [collectionLoadingStatus, collections, dispatch,pathname,policyId])

    const getToken = useCallback(() => {
        setLoadingStatus(tokenLoadingStatus)
        if ((tokenLoadingStatus !== 'finished' && tokenLoadingStatus !== 'idle')||(tokenLoadingStatus === 'idle')) {
            if(policyId) reduxDispatch(setCollectionSpinner(true))
          else setSpinnerShow(true)
        }
        else {
            // if (typeof (tokens) !== "string")
            // // setNftTokens(tokens)
            // else {
            //     setNftTokens([])
            //     setMessage(tokens)
            // } we directly using tokens array

            setSpinnerShow(false)
            reduxDispatch(setCollectionSpinner(false))
        }
        
        
    }, [tokenLoadingStatus, tokens, dispatch,pathname,policyId])

    useEffect(() => {
        let loadingStatus = isToken ? tokenLoadingStatus : collectionLoadingStatus;
        if(loadingStatus === 'finished' && collectionsList?.length) {
            dispatch('enableTopIcons', {sortingDisable: false, searchDisable: false, favDisable: false});
        } else {            
            dispatch('enableTopIcons', {sortingDisable: true, searchDisable: true, favDisable: true});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenLoadingStatus, collectionLoadingStatus, isToken, collectionsList])

    useEffect(() => {
        let loadingStatus = isToken ? tokenLoadingStatus : collectionLoadingStatus;
        dispatch('setMenuLoading', true);
        if(loadingStatus !== 'in-progress')
            setTimeout(() => {
                dispatch('setMenuLoading', false)
            }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenLoadingStatus, collectionLoadingStatus, isToken])
    useEffect(() => {
        setCollectionLoading(true)
        setTimeout(() => {
            setCollectionLoading(false)
        }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionType, basePathType])
    //Frames List API
    const getFramesList = () => {
        getUserFrames((data)=> {
            setFramesList(data)
        },true);
    }

    //Collection Select Handler
    const collectionClickHandler= useCallback((e)=>{
        collectionSelectHandler(e)
        navigate(`/${basePathType}/collections/my-collections/${e.policy}`)
        dispatch('setActiveIcons', {displayType: displayMode});
    },[displayMode, basePathType]);

    const collectionSelectHandler = e => {
        setSelectedCollection({ policy: e.policy, name: e.name })
        if(e.policy) {
            if (tab === 'all-wallets')
            {
                const filterCollction = collectionRawData.find(aData => aData.policy_id === e.policy)
                dispatch("updateCollectionLoadingStatus", 'in-progress');
                sliceCollectionAPI(filterCollction?.data);
            }                      
        }
    }
    const tokenClickHandler=useCallback((e)=>{
        tokenSelectHandler(e)
        navigate(`/${basePathType==="listen"?'listen':'curate'}/collections/my-tokens/${e.policy}`)
        dispatch('setActiveIcons', {displayType: displayMode});
    },[displayMode]);
    const tokenSelectHandler = e => {
        setSelectedCollection({ policy: e.policy, name: e.name })
        if(e.policy) {
            const filterCollction = tokenRawData.find(aData => aData.policy_id === e.policy)
            dispatch("updateTokenLoadingStatus", 'in-progress')
            sliceTokenAPI(filterCollction?.data);
        }
    }

    //Return to collection button click handler
    const returnCollectionHandler = () => {
        navigate(-1)
        //setSelectedCollection(null)  
        dispatch('enableTopIcons', {back: false});
    }
    useEffect(() => {
            setSearchValue(activeMenuIcons?.search);
    }, [activeMenuIcons?.search])

    const getCollection = () => {
        
        return tab === 'all-wallets' ? collections : singleCollection;
    }

    useEffect(() => {     
        setMyCollection(getCollection(),tokens,activeMenuIcons,searchValue)       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collections,singleCollection,tokens,dropDownFilter,activeMenuIcons?.filter,searchValue, basePathType])

    const setMyCollection = (collections, tokens, activeMenuIcons, searchValue) => {
        let tempArray = getDropValueByData(collections, tokens)
        tempArray = filterCollections([...tempArray], activeMenuIcons?.filter);
        const searchFilterFrame = searchValue ? tempArray?.filter((val) =>selectedCollection? val?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) : val?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) || val?.community?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) || val?.asset?.toLowerCase()?.includes(searchValue?.toLowerCase()) || val?.collectionName?.toLowerCase()?.includes(searchValue?.toLowerCase())) : tempArray;
        setNftCollectionsList(searchFilterFrame);
    }

    useEffect(() => {
        dispatch("setPage","collections")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])
    
    useEffect(()=>{
        dispatch("setActiveIcons", { search: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedCollection])


      
    useEffect(() => {
        if(activeMenuIcons?.dropDown==="My Collections" || activeMenuIcons?.dropDown==="My Tokens")
            dispatch('setActiveIcons', {filterByWallet: ""});
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ activeMenuIcons?.dropDown])
    useEffect(()=>{
        let collections = getCollection();
        if(policyId && collections?.length){
            let findCollection = collections?.find((el)=>el?.policy===policyId)
            if(findCollection){
                setSelectedCollection(findCollection)
            }else {
                setSelectedCollection(null)
                navigate(`/${basePathType}/collections/my-collections`)
            }
        } else{
            setSelectedCollection(null)
        }
    },[policyId, collections, pathname,basePathType])
    useEffect(() => {
      return () => {
        let filter = basePathType === 'listen' ? {categoryIds: [18]} : {} //by defaul listen page should be music category selected
        dispatch("setActiveIcons",{search:"",sort:"",favourite:false,dropDown:"",folderOption:"",frame:"",filterByWallet:"",filter ,sorting:""});
      };
    }, [basePathType]);
return <div className={`curate-collections overflow-hidden h-full w-full relative ${((!isLoggedIn||(collectionWalletData !== null && !collectionWalletData?.length))&&collectionLoadingStatus !== "in-progress"&&(!currentWallet || !currentWallet?.NFTs?.length))&&"hidden"}`}>
           {walletHasNFTs && <div className="tab-container">
          <div className="tab-list flex sm:gap-[1.42857rem] gap-[0.625rem] sm:z-9 sm:sticky">
            {menuTabs?.map((menu) => {
              return (
                <div
                  key={menu?.id}
                  onClick={() => handleTabs(menu?.id)}
                  className={`tab-data ${menu?.id === tab ? "active":"not-active"} ${(menu.dependsOn === 'isLoggedIn'&&!isLoggedIn)?'hidden':''} ${(menu.dependsOn === 'currentWallet' && !walletHasNFTs) ? 'hidden' : ''}`}
                >
                  {menu?.element}{" "}                  
                    <span className=" inline-block">{`(${parseInt(
                      menu.id === 'all-wallets'?collections?.length??0:menu.id === 'my-connected-wallet'?currentWallet?.NFTs?.length??0:0
                    )})`}</span>                  
                </div>
              );
            })}
          </div>
          <Border className={"right-panel-b-border w-auto"} />
        </div>} 
        <DetailsSkeltonCard 
            show={(((spinnerShow || collectionLoading) && !selectedCollection && !collectionSpinner))}
            height={"100%"}
            count={20}
            inline={true}
            details={"img-2-line"}
            noAbsolute
            parentDiv={`${activeMenuIcons?.displayType==="lg"?"large-view":"small-view"} collection-loader mx-4`}
            containerClassName={` flex ${walletHasNFTs?"pt-2":"pt-[1rem]"}`}           
         />
        {(tab==='all-wallets'||tab==='my-connected-wallet') && <>
        {!selectedCollection && loadingStatus  !== 'idle' && !policyId&& !spinnerShow &&  <MyCollections tab={tab} walletHasNFTs={walletHasNFTs} updateCollectionFilter={updateCollectionFilter} isToken={isToken} oldCollection={nftCollections} message={message} loading={loadingStatus === 'idle'} getFramesList={getFramesList} framesList={framesList} basePathType={basePathType} nftCollections={collectionsList} walletListLength={!!collectionWalletData ? collectionWalletData?.length : '0'} onCollectionSelect={isToken ? tokenClickHandler : collectionClickHandler} setDisplayMode={setDisplayMode} displayMode={displayMode}/>}
        {!!selectedCollection && loadingStatus !== 'idle' && policyId&& !spinnerShow&&  <SelectedCollectionV2 tab={0} framesList={framesList} nftCollections={collectionsList} getFramesList={getFramesList} onReturnClick={returnCollectionHandler} collection={selectedCollection} displayMode={displayMode} listen={basePathType==="listen"} read={basePathType==="read"}/>}
        </>}

    </div >
}

export default NftCollectionsV3