import "./EventDetails.scss";
import FormatField from "../../FormatField";
import BlurBGImage from "../../BlurBGImage";
import { useLocation, useNavigate } from "react-router-dom";

export const EventDetails = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { eventData, fromCommunity, isCreator } = props;

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }

  function getTimezoneOffsetString(offset) {
    const sign = offset > 0 ? "-" : "+";
    const hours = Math.floor(Math.abs(offset) / 60);
    return `${sign}${hours}`;
  }
  const handleCommunityView = (slug) => {
    if(!slug) return;
    if (pathname === '/home' || pathname === '/curate/events') {
      window.scrollTo(0, 0);
      navigate(`/discover/communities/${slug}/overview`);
    }
  };

  function formatDateTimeRange(date) {
    let dateOnly = formatDate(date);
      const formatted = new Date(date)?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      const timezoneOffset = new Date(date)?.getTimezoneOffset();
      const utcDate = ` (UTC${getTimezoneOffsetString(timezoneOffset)})`;
      return `${dateOnly} ${formatted}${utcDate}`;
  }
  
  return (
    <div
      className={`${props.openDetails ? "event-details-overlay" : "hidden"}`}
      onClick={props.closeDetails}
    >
      <div className="details-container" onClick={(e) => e.stopPropagation()}>
        {eventData && (
          <div className="w-full flex gap-[4rem] h-full">
            {!props.fromCommunity && (
              <div className="relative flex w-1/2 justify-center">
                  <BlurBGImage mainImageSrc={eventData?.filePath ?? eventData.community?.thumbnailPicture512 ?? eventData.community?.thumbnailPicture} bgImageSrc={eventData?.filePath ?? eventData.community?.thumbnailPicture512 ?? eventData.community?.thumbnailPicture}/>
                <span className={`${props?.data?.isFollowing ? 'event-body-following' : 'hidden'}`}>Following</span>
              </div>
            )}
            {props.fromCommunity && (
              <div className="relative w-1/2 flex min-w-[50rem] h-full justify-center overflow-hidden">
                <BlurBGImage mainImageSrc={eventData?.filePath ?? eventData.community?.thumbnailPicture512 ?? eventData.community?.thumbnailPicture} bgImageSrc={eventData?.filePath ?? eventData.community?.thumbnailPicture512 ?? eventData.community?.thumbnailPicture}/>
                <span className={`${props?.data?.isFollowing ? 'event-body-following' : 'hidden'}`}>Following</span>
              </div>
            )}
            <div className="flex flex-col gap-[1rem] w-1/2">
              <div className="flex gap-3 justify-between">
                <div className="flex gap-3">
                  {!props.fromCommunity && eventData.communityPic && (
                    <img
                      src={eventData.communityPic}
                      className="card-profile-icon object-cover"
                      alt=""
                    ></img>
                  )}
                  {(props.fromCommunity ||
                    eventData.community?.displayPicture) && (
                      <img
                        src={eventData.community?.displayPicture256}
                        className="card-profile-icon object-cover"
                        alt=""
                      ></img>
                    )}
                  <div onClick={()=> handleCommunityView(eventData?.community?.slug)} className={`flex justify-center items-center h-full cursor-pointer`}>
                    <span className="title" 
                    // title={eventData.name}
                    >
                      {" "}
                      <FormatField
                        type="sub"
                        maxWidth={200}
                        value={
                          eventData.name ??
                          isCreator
                            ? eventData.communityName
                            : fromCommunity
                            ? eventData.community.name
                            :  eventData?.community?.name
                        }
                      />
                    </span>
                  </div>                 
                </div>
                <div className=" close-div  justify-end w-[1.5rem] h-[1.5rem]">
                  <div className="close-icon" onClick={props?.closeDetails}>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start gap-[1rem]">
              {eventData?.selectedCategory && <span className="text-[0.8rem] text-[var(--cu-white-100)] px-2 py-1 bg-[var(--cu-black-800)] rounded-sm mr-6">{eventData?.selectedCategory}</span>}
                <div className="head sm:gap-3 gap-[0.5rem] flex flex-col items-center justify-start">
                  <div className="flex sm:gap-4 gap-[0.25rem] items-center w-full">
                    <span className="time">
                    {props?.modifiedDate?.date ?? formatDateTimeRange(props?.data?.start)}
                  </span>
                  <span className="time">
                    {props?.modifiedDate?.date ? props?.modifiedDate?.time : formatDateTimeRange(props?.data?.end)}
                  </span>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-[0.5rem]">
                  <div
                    className="foot text-left font-bold text-[1.125rem]"
                    title={eventData.headline}
                  >
                    {eventData.headline ?? (props?.data?.name ?? "")}
                  </div>
                  <div
                    className="text-left text-[#F7F8F9] sm:text-[.875rem] text-[0.875rem] h-[40dvh] pb-10 overflow-auto"
                  >
                    <div className="dangerously-innerHTML-container" dangerouslySetInnerHTML={{__html: eventData.details}}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};