import styles from "./LogOutWarn.module.scss"
import Popup from 'App/Components/Ui/Popup'
import React from 'react'

const LogOutWarn = (props) => {
    return (
        <Popup>
            <div className={styles["logOut-warn"]}>
                <p className={styles["logOut-txt"]}>Are you sure you want to logout from Cur8?</p>
                <div className={styles["button-box"]}>

                    <button onClick={props.onCancel} className={styles["success"]} >
                        Cancel
                    </button>
                    <button onClick={props.onLogOut} className={styles["error"]}>
                        Logout
                    </button>

                </div>
            </div>
        </Popup>
    )
}

export default LogOutWarn
