/* eslint-disable no-use-before-define */
import { useState, useEffect, useCallback, memo } from "react";
import useWallet from "App/hooks/use-wallet";
import "./WalletSelectPop.scss";
import EternalWallet from 'Assets/Images/eternl_wallet.svg';
import FlintWallet from 'Assets/Images/flintWallet.svg';
import NamiWallet from 'Assets/Images/NamiWallet.svg';
import TyphonWallet from 'Assets/Images/TyphonWallet.svg';
import { useStore } from "App/hooks-store/store";
import ImagePopup from "../ImagePopup";
import useHttp from "App/hooks/use-http"
import useAuth from "App/hooks/use-auth";
import storage from "App/Helper/storage";
import { useNavigate } from "react-router-dom";
import geroWalletIcon from 'Assets/Icons/gero-wallet-icon.svg';

const WalletSelectPop = memo((props) => {
    const [{ currentWallet }, dispatch] = useStore(false);
    const navigate = useNavigate()
    const [wallets, setWallets] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState();
    const [installedWallets, setInstalledWallets] = useState([])
    const [loading, setLoading] = useState();
    const [connecting, setConnecting] = useState(false);
    const [registrationStatus,setRegistrationStatus] = useState(true)
    const walletConnector = useWallet();
    const { triggerAPI } = useHttp();
    const handleB2CLogin = useAuth()[6]    
    // eslint-disable-next-line no-unused-vars
    const [isLoggedIn, logout, login, guest, isGuest, removeItem] = useAuth();    
    const [isLinking, setisLinking] = useState(false);
    const getConnectWalletResponse = useCallback((res) => {
        login(res?.data)
        guest(true)
        props?.onClose(false)
        setConnecting(false)        
        navigate("/curate/collections/my-collections")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const getRegistrationStatusResponse = useCallback(
        (res) => {
         
          setRegistrationStatus(res?.data?.allowNewRegistration);
        },
        []
      );
    
      const getRegistrationStatus = useCallback(() => {
        
        triggerAPI(
          {
            url: `app-preference/get`,
            method: "get",
          },
          getRegistrationStatusResponse
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dispatch, getRegistrationStatusResponse, triggerAPI]);
      useEffect(() => {
        getRegistrationStatus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      
    const connectWallet = useCallback((walletAddress) => {
        removeItem('token');
        setConnecting(true)
        triggerAPI({
            url: `user/guest-login`, data: {
                walletAddress
            }, method: 'post'
          }, getConnectWalletResponse, (err)=> {
            setConnecting(false)
            if(err?.response?.status === 409) {
                dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.message } })
                dispatch('setCurrentWallet', {})
                removeItem('currentWallet');
            } else {
                props?.onClose?.(false)
                dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.error?.message } })
                setTimeout(() => {
                    logout();
                }, 1000);
            }
            
          });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[triggerAPI]);
    
    
    useEffect(() => {
        const availableWallet = walletConnector.availableWallet();
        setInstalledWallets(availableWallet)
        const { connecting } = walletConnector;
        setLoading(connecting)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletConnector, currentWallet])
    
    useEffect(() => {
        let defaultWallet = [{
            name: "eternl",
            icon: EternalWallet,
            version: "0.1.0",
            installUrl: "https://chrome.google.com/webstore/detail/eternl/kmhcihpebfmpgmihbkipmjlmmioameka",
        },
        {
            name: "Flint Wallet",
            icon: FlintWallet,
            version: "0.1.0",
            installUrl: "https://chrome.google.com/webstore/detail/flint-wallet/hnhobjmcibchnmglfbldbfabcgaknlkj",
        }, {
            name: "Nami",
            icon: NamiWallet,
            version: "0.1.0",
            installUrl: "https://chrome.google.com/webstore/detail/nami/lpfcbjknijpeeillifnkikgncikgfhdo",
        }, {
            name: "Typhon Wallet",
            icon: TyphonWallet,
            version: "0.1.0",
            installUrl: "https://chrome.google.com/webstore/detail/typhon-wallet/kfdniefadaanbjodldohaedphafoffoh",
        }]
        let val = installedWallets?.filter((x, i) => installedWallets?.findIndex((y, j) => y?.name === x?.name) === i)
        const walletDefault = val?.map(wallet => {
            const existWallet = defaultWallet?.filter(x => x?.name === wallet?.name)
            if (existWallet?.length) {

                defaultWallet = defaultWallet?.filter((x) =>
                    !existWallet?.some(y => y?.name === x?.name))
            }
            if(wallet?.name === "GeroWallet")
                wallet = {...wallet, icon: geroWalletIcon}
            return wallet
        })
        setWallets([...defaultWallet, ...walletDefault])
        if (currentWallet) {
            val?.map(w => w.active = false);
            const { walletName, roundBalance } = currentWallet;
            const foundIcon = val?.find(w => w.name === walletName);
            if (foundIcon) {
                foundIcon.active = true;
            }
            setSelectedWallet({ walletName, balance: roundBalance, icon: foundIcon?.icon })
        }
    }, [installedWallets, currentWallet, walletConnector])
    const connectHandler = async (e) => {
        const installUrl = e?.currentTarget?.dataset?.id
        if (installUrl) {
          window.open(installUrl)
        }
        else {
          setisLinking(true);
          const name = e.target.value;
          walletConnector.connectWallet(name);
        }
    
    }
    const selectWallet = useCallback((walletName) => {
        walletConnector.connectWallet(walletName);
    }, [walletConnector])
    useEffect(() => {
        const name = storage().get('currentWallet');
        if (name) {
            selectWallet(name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (!currentWallet) {
            setSelectedWallet({})            
        }
        else
        {
            if (isLinking)
            {                
                connectWallet(currentWallet.address);
                setisLinking(false);
            }   
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWallet])

    const nameHandler=(name)=>{
        return name?.replace("Wallet","")
    }
    return (
        <ImagePopup show={{mode: props?.show} } mainOnly onClose={props?.onClose} noOuterClose cssClass={`relative wallet-select-pop `}>
            {loading ? <div className="wallet-pop-container">Loading</div> :
                <div className="wallet-pop-container flex flex-col items-center gap-[2rem]">
                    <div className="head flex flex-col gap-4">
                    {/* {errorMsg && <div className="error-msg">
                        {errorMsg}
                    </div>} */}
                    {!isLoggedIn?(!!registrationStatus)&&<div className="foot">
                        <div className="font-normal">
                           <span className="underline underline-offset-4 cursor-pointer" onClick={handleB2CLogin}>Login</span> to curate and display your collection
                        </div>
                    </div>:<div>
                                <p className="font-normal">Link your wallet and start viewing your collection right away</p>
                           </div>}
                    </div>
                    {isLoggedIn && <><div className="body flex gap-4">
                        {wallets.map((wallet, index) => {
                           return <div className="flex flex-col items-center gap-[10px]" key={index}>
                                    <div className="wallet-icon">
                                        <img src={wallet.icon} alt={wallet.name} />
                                    </div>
                                    <div className="wallet-label capita">
                                        {(wallet.active && !connecting) ? selectedWallet?.balance : nameHandler(wallet.name)}
                                    </div>
                                    {/* <div className="flex wallet-button items-center"> */}
                                        <button className="wallet-button" data-id={wallet?.installUrl} onClick={connectHandler} value={wallet.name}>
                                        {((wallet?.status || connecting) ? 'Linking' : 'Link')}
                                        </button>
                                    {/* </div> */}
                                </div>
                        })}
                    </div>
                    {/* <div>
                        <p className="font-normal">Connect your wallet and start viewing your collection right away</p>
                    </div> */}
                    </>}
                </div>}
        </ImagePopup>
    )
})
export default WalletSelectPop;