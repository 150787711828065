/* eslint-disable react-hooks/exhaustive-deps */
import "App/Components/Ui/Forms/InputBox/InputBox.scss"
import Validators from "App/Components/Ui/Forms/Validators"
import Input from "App/Components/Ui/Input"
import Label from "App/Components/Ui/Label"
import TextArea from "App/Components/Ui/TextArea"
import { useStore } from "App/hooks-store/store"
import {  useCallback, useEffect, useMemo, useRef, useState } from "react"
import CheckBox from "App/Components/Ui/CheckBox"
import { useLocation } from "react-router-dom"
import useHttp from "App/hooks/use-http";
import "./gallerySettings.scss";
import { useDispatch, useSelector } from "react-redux"
import { getCurrentFormValues, setCurrentFormValues } from "App/ReduxStore/Slicers/gallerySlice"
import monetIcon from 'Assets/Icons/monet-sm-icon.svg';
import { platFormFee } from "App/Configs/staticValues"
const GallerySettings = (props) => {
    const { pathname } = useLocation()
    const isCuratePage = pathname.includes('curate/my-galleries');
    const [{ currentFormValues }, dispatch] = useStore(false);
    const [showSetPrimary, setShowSetPrimary] = useState(false);
    const [disableSetPrimary, setDisableSetPrimary] = useState(false);
    const [clickSetPrimary, setClickSetPrimary] = useState(false);
    const [finalPrice, setFinalPrice] = useState(0);
    const [privateCheck, setPrivate] = useState(false);
    const { triggerAPI } = useHttp();
    const dispatchRedux = useDispatch();
    const currentForm = useSelector(getCurrentFormValues)
    const inputRef = useRef(null);
    const rentalPriceValidator = useMemo(() => ([{
        type: 'Required',
        message: 'Daily rental is required field'
      }, {
        type: 'Number',
        message: 'Invalid value'
      }, {
        type: 'minVal',
        message: 'Minimum price should be 1000'
      }]), []);
    const rentalSplitValidator = useMemo(() => ([{
        type: 'NumberWithZero',
        message: 'Rental split is required field'
      }, {
        type: 'Number',
        message: 'Invalid Value'
      }, {
        type: 'minVal',
        message: 'Minimum rental split should be 0'
      },{
        type: 'maxVal',
        message: 'Maximum rental split should be 80'
      }
    ]), []);

    useEffect(()=>{
        if(!currentForm?.id && !currentForm?.name)
            inputRef.current.focus(); //by default focus gallery name
    },[])

    useEffect(()=>{
        if(currentForm?.id&&!currentForm?.isDiscoverable) setPrivate(true)
        else setPrivate(false)
    },[currentForm?.id])

    useEffect(()=>{
        let renPrice = parseInt(currentForm?.rentalPrice) || 0,
        rentalSplit = parseInt(currentForm?.rentalSplit) || 0;
        let rental = ( renPrice * ((rentalSplit + platFormFee) / 100))
        rental = isNaN(rental) ? 0 : rental;
        renPrice = isNaN(renPrice) ? 0 : renPrice;
        let price = renPrice - rental;
        setFinalPrice(price)
    },[currentForm?.rentalPrice, currentForm?.rentalSplit, platFormFee])

    const inputOnChange = (e) => {
        dispatch('setCurrentFormValues', {
            ...currentFormValues,
            ...e
        })
        dispatchRedux(setCurrentFormValues({...currentForm, ...e}))
    }
    const collectErrors = useCallback((error) => {
        props?.setFormVaildState && props?.setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const onClickToggle = (e) => {
    //     setToggleChangeSm(e?.currentTarget?.dataset?.a)
    //     if (e?.currentTarget?.dataset?.a === 'Manual') {
    //         setToggleChange(false)
    //     }
    //     else {
    //         setToggleChange(true)
    //     }

    // }
    const checkOptionHandler = () => {
        if(!(currentForm?.id&&currentForm?.priority&&!clickSetPrimary)&&!disableSetPrimary) {
            inputOnChange({ priority: currentForm?.priority === 1 ? 0 : 1 })
            setClickSetPrimary(true)
        }
    }
    const checkDiscoverableOptionHandler = () => {
        if(!currentForm?.isDiscoverable){
            triggerAPI({
                url: `gallery/get-creators-gallery-priority`, method: 'get'
            }, (res)=>{
                    setDisableSetPrimary((res?.data?.userDiscoverableGalleryCount===1&&currentForm?.id&&!privateCheck)?true:res?.data?.setAsPrimaryKeyCheckBox)  
                    inputOnChange({isDiscoverable: !currentForm?.isDiscoverable, priority: (res?.data?.userDiscoverableGalleryCount===1&&currentForm?.id&&!privateCheck)?1:res?.data?.setAsPrimaryKeyCheckBox  ? 1 : 0 })     
            });
        }else  {
            setDisableSetPrimary(false)  
            inputOnChange({isDiscoverable: !currentForm?.isDiscoverable, priority: 0 }) 
        } 
    }

    useEffect(() => {
        inputOnChange({ showPrimaryDiscoverable: props?.showPrimaryDiscoverable })
        setShowSetPrimary((pre)=>currentForm?.isDiscoverable)
    }, [currentForm?.isDiscoverable])

    const rentalPrice= useMemo(()=>{
        if(finalPrice){
        if(finalPrice>=0){
           return finalPrice
        }else return ""
    }else return 0
    },[finalPrice])
    return (
        <div className="gallery-settings px-4 side-scroller">
            <div className="flex justify-start frame-border pb-1.5 text-[1rem]">
                General Information
            </div>

            <div className="flex flex-col gap-[0.5rem] pt-[1.5rem]">
                <div className="text-left items-start pt-0.5">
                    <Label className="gallery-settings-label">Gallery Name*
                    </Label>
                </div>
                <div className='flex pb-2 w-full'>
                    <Input ref={inputRef} sampleName={''} className="gallery-settings-form desc" validateOnBlur controlName="name" value={currentForm?.name} placeholder="40 Characters" onChange={inputOnChange} label="galleryName" type="text" maxlength="40">
                        <span className="flex justify-between items-center">
                            <Validators type="Required" value={currentForm?.name} message="Required field" controlName="name" onErrors={collectErrors} />
                        </span>

                    </Input>
                </div>

            </div>
            <div className="flex flex-col gap-[0.5rem] pt-[0.5rem]">
                <div className="text-left items-start pt-0.5">
                    <Label className="gallery-settings-label">Description
                    </Label>
                </div>
                <div className={`flex w-full ${!props?.showPrimaryDiscoverable?"pb-2":"pb-0"}`}>
                    <TextArea className="gallery-settings-form desc min-h-[8.75rem]" value={currentForm?.description ?? ''} controlName="description" onChange={inputOnChange} label="description" type="text" placeholder="Describe your Gallery.. (300 Characters)" maxLength="300">
                    </TextArea>

                </div>
            </div>
            {!!props?.showPrimaryDiscoverable ? 
                <>                
                    {isCuratePage &&
                        <div className="checkbox-wrapper flex items-center mt-[1px] mb-[3px] discoverable">
                            <CheckBox className="text-[.875rem] p-[.1875rem] gap-2.5"
                                checked={currentForm?.isDiscoverable}
                                onClick={checkDiscoverableOptionHandler}>
                                Public
                            </CheckBox>
                        </div>
                    } 
                    <div className={`checkbox-wrapper mb-[1.5rem] ${showSetPrimary ? "flex" : "hidden"}`}>
                        <CheckBox greyCheck={false} className={`text-[.625rem] p-[.1875rem] gap-2.5 set-primary ${((currentForm?.id && currentForm?.priority && !clickSetPrimary)||disableSetPrimary)?"cursor-not-allowed":"cursor-pointer"}`}
                            checked={currentForm?.priority === 1}
                            disabled={(currentForm?.id&&currentForm?.priority&&!clickSetPrimary)||disableSetPrimary}
                            onClick={checkOptionHandler}>
                            Set as primary gallery
                        </CheckBox>
                    </div>
                </>
                : 
                <>
                    <div className="flex justify-start frame-border pb-1.5 text-[1rem]">
                        Rental Information
                    </div>
                    <div className="flex flex-col gap-[0.5rem] pt-[1.5rem]">
                        <div className="text-left items-start pt-0.5">
                            <Label className="gallery-settings-label">Daily rental price
                            </Label>
                        </div>
                        <div className='flex pb-2 w-full'>
                            <Input icon={monetIcon} className="gallery-settings-form desc" regex="number" placeholder={'1000'} validateOnBlur controlName="rentalPrice" value={currentForm?.rentalPrice} onChange={inputOnChange} label="rentalPrice" type="text">
                                <span className="flex justify-between items-center">
                                    <Validators value={currentForm?.rentalPrice} controlName="rentalPrice" onErrors={collectErrors} validators={rentalPriceValidator} minVal={1000} />
                                </span>
                            </Input>
                        </div>
                    </div>                    
                    <div className="flex flex-col gap-[0.5rem] pt-[0.5rem]">
                        <div className="text-left items-start pt-0.5 w-full">
                            <Label className="gallery-settings-label">{`Rental split with collectors (%)`}
                            </Label>
                        </div>
                        <div className='flex pb-2 w-full'>
                            <Input className="gallery-settings-form desc" placeholder={'0'} regex="number" validateOnBlur controlName="rentalSplit" value={currentForm?.rentalSplit} onChange={inputOnChange} label="rentalSplit" type="text">
                                <span className="flex justify-between items-center">
                                    <Validators value={currentForm?.rentalSplit} controlName="rentalSplit" onErrors={collectErrors} validators={rentalSplitValidator} minVal={0} maxVal={80} />
                                </span>
                            </Input>
                        </div>
                    </div>                    
                    <div className="flex flex-col gap-[0.5rem] pt-[0.5rem]">
                        <div className="text-left items-start pt-0.5">
                            <Label className="gallery-settings-label w-full">Daily Rental Price (after {platFormFee}% platform fee)
                            </Label>
                        </div>
                        <div className='flex pb-2 w-full'>
                            <div className="flex justify-start items-center gap-4 w-full gallery-settings-form bg-transparent">
                                <div>
                                    <img src={monetIcon} alt="" />
                                </div>
                                <div className="flex items-center">
                                    <p className="rental-price ">{rentalPrice}</p>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </>
            }
        </div>

    )
}

export default GallerySettings