/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./Communities.scss";
import { useNavigate } from "react-router-dom";
import { useStore } from "App/hooks-store/store";
import { categorySort } from "App/Helper/utilities";
import useDiscoverApiHelper from "App/Pages/Discover/Components/API/apiHelper";
import useHttp from "App/hooks/use-http";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import PageListing from "./Components/CommunityCard";
import "./Communities.scss";

const CommunitiesCategory = () => {
  const [communitiesListOriginal, setCommunitiesListOriginal] = useState([]);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [showFollowing, setShowFollowing] = useState(false);
  const [loading, setLoading] = useState(null);
  const [{ activeMenuIcons }, dispatch] = useStore();
  const { triggerAPI } = useHttp();
  const { getCategoryCommunity } = useDiscoverApiHelper();
  const targetRef = useRef(null);
  const navigate = useNavigate();
  const timeOut = useRef(null);
  const [categoryList, setCategoryList] = useState([]);
  const getCategoryResponse = useCallback(
    (res) => {
      const cateList = categorySort([...res?.data?.list]);
      setCategoryList(cateList);
    },
    [dispatch]
  );
  const userGroupOptions = useMemo(() => {
    return [
      {
        id: 1,
        label:'All Communities',
        value: "all",
        count: ``,
      },
      {
        id: 2,
        label:'Following',
        value: "following",
        count: ``,
      },
    ]
  }, []);
  const getCategoryList = useCallback(() => {
    triggerAPI(
      {
        url: `category/get`,
        data: {
          page: 1,
          items: 1000,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getCategoryResponse
    );
  }, [dispatch, getCategoryResponse, triggerAPI]);

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    dispatch("setTopMenuBarOptions", { showMenuIcons: true });
    dispatch("enableTopIcons", {
      filter: false,
      search: true,
      sorting: false,
      lgView: false,
      xlgView: false,
      smView: false,
      listView: false,
      dropDown: false,
      favourite: false,
      filterSort: false,
      sortFilter: true,
      userGroup: true,
    });
    dispatch("setActiveIcons", { search: "", sort: undefined });
    dispatch("setUserGroup", userGroupOptions);
    return () => {
      dispatch("resetAll");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCategoryCollectionList = useCallback(() => {
    const dataParam = {
      search: searchValue ?? "",
      isFollowed : showFollowing
    };
    setLoading(() => true);
    dispatch("enableTopIcons", {
      filter: false,
      search: true,
      sorting: false,
      lgView: false,
      xlgView: false,
      smView: false,
      listView: false,
      dropDown: false,
      favourite: false,
      filterSort: false,
      sortFilter: true,
      sortingDisable: true,
      userGroup: true,
    });
    getCategoryCommunity(dataParam, (res) => {
      const { data } = res;
      let filteredData = data?.filter((val) => val?.community?.length);
      setCommunitiesListOriginal(filteredData);
      setLoading(() => false);
      const subCategory = filteredData?.map((el) => ({
        id: el?.id,
        value: el?.name,
        slug: el?.slug,
      }));
      dispatch("enableTopIcons", {
        filter: false,
        search: true,
        sorting: false,
        lgView: false,
        xlgView: false,
        smView: false,
        listView: false,
        dropDown: false,
        favourite: false,
        filterSort: false,
        sortFilter: true,
        sortingDisable: false,
        userGroup: true,
      });
      dispatch("setSortingOptions", subCategory);
    });
    return () => {
      dispatch('resetAll')
    }
  }, [getCategoryCommunity, searchValue]);

  useEffect(() => {
    if (categoryList?.length) getCategoryCollectionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryList, searchValue, showFollowing]);

  useEffect(() => {
    if (page !== 1) setPage(1);
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
    timeOut.current = setTimeout(() => {
      setSearchValue(activeMenuIcons?.search);
    }, 600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.search]);

  const navigateToAll = (navName) => {
    const subCategorySlug = communitiesListOriginal?.find(
      (el) => el?.name === navName
    )?.slug;
    if (subCategorySlug) {
      window.scrollTo(0, 0);
      navigate(`${subCategorySlug}`);
    }
  };
  const onCardClick = (id,cardId) => {
    const data = communitiesListOriginal?.find(
      (collect) => collect?.id === parseInt(cardId)
    ).community?.find(el=>el?.id===parseInt(id))
    ;
    if (id) {
      window.scrollTo(0, 0);
      navigate(`/discover/communities/${data?.slug}/overview`);
    }
  };
  useEffect(() => {
    if (activeMenuIcons?.sort) {
      navigateToAll(activeMenuIcons?.sort);
    }
  }, [activeMenuIcons?.sort]);
  useEffect(() => {
    setShowFollowing(()=>activeMenuIcons?.userGroup?.value === 'following' ? true : false);
  }, [activeMenuIcons?.userGroup]);
  return (
    <div className="discover-collection h-full w-full flex flex-col explore-collection">
      <div className="w-full overflow-flex-auto h-full flex gap-[1.42857rem] flex-col">
        {(loading || loading === null) && (
          <div className="flex flex-col">
            {[...Array(2)].map((el, i) => (
              <div className="mt-[2.5rem] mb-[2rem]" key={i + 1}>
                <DetailsSkeltonCard
                  show={true}
                  height={"100%"}
                  count={6}
                  inline={true}
                  details={"img-2-line"}
                  noAbsolute
                  parentDiv="grid community-grid mt-0 aspect-pt-six-three"
                />
              </div>
            ))}
          </div>
        )} 
        {!!communitiesListOriginal?.length && !loading && (
          <div className="  flex-1 gap-4 h-auto flex flex-col justify-start">
            {[...communitiesListOriginal]?.map((val) => {
              return (
                <div key={val?.id}>
                  <div className="flex justify-between items-center">
                    <div className="text-left my-[.5rem] text-[1.25rem]">{val?.name}</div>
                    <div
                      className="cursor-pointer my-[.5rem] text-[.675rem] text-[#A0ABBB]"
                      onClick={() => navigateToAll(val?.name)}
                    >
                      See all
                    </div>
                  </div>
                  {!loading && (
                    <PageListing
                    id={val?.id}
                      list={[...val?.community]}
                      onCardClick={onCardClick}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
        {!loading && loading !== null && !communitiesListOriginal?.length && (
          <div className="flex items-center no-data justify-center">
            No items to display.
          </div>
        )}
      </div>
    </div>
  );
};

export default CommunitiesCategory;
