import "./Rewards.scss";
import Panel from "App/Components/Ui/Panel";
import Table from "App/Components/Ui/Table";
import { useState, useEffect, useCallback } from "react";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import TableHeader from "App/Components/Ui/TableHeader";
import tableSort from "App/Components/Ui/TableSort";
import Pagination from "App/Components/Ui/Pagination";
import { convertPriceToAda, thousandCommaSeparator } from "App/Helper/utilities";
import exterNalLinkIcon from "Assets/Icons/external-link-sm.png";
import monentIcon from "Assets/Icons/monet-sm-icon.svg";
import monentLgIcon from "Assets/Icons/monet-lg-icon.svg";
import cardanoIcon from "Assets/Icons/cardano-sm-icon.svg";
import cardanoLgIcon from "Assets/Icons/cardano-lg-icon.svg";
import useScreenSize from "App/hooks/use-screen-size";
const paginationConfig = {
  perPage: 8,
};
const paymentStatusLink = "https://cardanoscan.io/transaction/";
const Rewards = () => {
  const dispatch = useStore(false)[1];
  const { triggerAPI } = useHttp();
  const [purchaseList, setPurchaseList] = useState([]);
  const [monetData, setMonetData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [balance, setBalance] = useState({ ada: 0.0, monet: 0.0 });
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [sort, setSort] = useState({ direction: "", sortKey: "createdAt" });
  const [filteredData, setFilteredData] = useState("none");
  const onSortHandler = useCallback(
    (sortKey) => {
      const dir = sort.sortKey === sortKey ? sort.direction : "";
      const direction = tableSort(dir);
      setSort({ direction, sortKey });
    },
    [sort]
  );
  // useEffect(() => {
  //   if(activeTab === 0  || activeTab === 2) {
  //     setPurchaseList(monetData);
  //   } else {
  //     setPurchaseList([]);
  //   }
  // }, [monetData, activeTab])
  const onPageChangeHandler = (currentPage) => {
    setPage(currentPage);
  };
  const getRewardResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      const { rows,totalAda,totalMonet, pageMeta } = res.data;
      // eslint-disable-next-line no-unused-vars
      setBalance({ ada: totalAda, monet: totalMonet });
      const purchaseList = rows?.map((rewards) => {
        const transactionType = rewards?.transactionType?.split(" ") ?? [];
        rewards.createdAt = new Date(rewards.createdAt).toLocaleDateString(
          "en-us",
          { year: "numeric", month: "short", day: "numeric" }
        );
        rewards.currency = "ADA";
        // rewards.description = `${rewards?.countNfts} x ${rewards?.asset?.name}`;
        rewards.state = `${
          rewards?.state === "prepared"
            ? "In progress"
            : rewards?.state === "finished"
            ? "Completed"
            : "Cancelled"
        }`;
        rewards.price = convertPriceToAda(rewards?.priceInLovelace);
        // rewards.transactionType = transactionType.length
        //   ? transactionType[0]
        //   : "";
        rewards.transactionType=rewards?.type
        return rewards;
      });
       setPurchaseList(purchaseList);
      //  setTotalPage(pageMeta.totalItems);
    },
    [dispatch]
  );

  const getRewardList = useCallback(() => {
    dispatch("showSpinner");
    let filter = { isReward: 'all'  };
    if (sort.direction || filteredData) {
      if (sort.direction) {
        filter = {
          ...filter,
          sortBy: sort.sortKey,
          orderBy: sort.direction,
        };
      }
      if (filteredData && filteredData !== "none") {
        filter = {
          ...filter,
          currency: filteredData,
        };
      }
    }
    triggerAPI(
      {
        url: `user/rewards/get`,
        data: {
          filter,
        },
        method: "post",
      },
      getRewardResult
    );
  }, [dispatch, getRewardResult, page, sort, triggerAPI, filteredData]);

  useEffect(() => {
    getRewardList();
    dispatch("setPageTitle", "Purchase");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, page, filteredData]);

  const getBalanceResult = useCallback((res) => {
    const { data } = res;
    setBalance({ ada: data?.totalAda, monet: data?.totalMonet });
  }, []);
  const getBalance = useCallback(() => {
    triggerAPI(
      {
        url: `user/wallet/monet-balance`,
        method: "get",
      },
      getBalanceResult
    );
  }, [triggerAPI, getBalanceResult]);

  const getMonetResult = useCallback((res) => {
    const { data } = res;
    const monetDataTemp = [
      {
        createdAt: "--",
        currency: "monet",
        description: `xx`,
        state: `finished`,
        price: parseInt(data?.initialTranferAmount)?thousandCommaSeparator(parseInt(data?.initialTranferAmount)?.toFixed(2)):parseInt(data?.initialTranferAmount),
        transactionType: "Registration",
      },
    ];
    setMonetData(monetDataTemp);
    // setPurchaseList(monetData);
    // setBalance({ada: data?.totalAda, monet: data?.totalMonet});
  }, []);
  const getMonetData = useCallback(() => {
    triggerAPI(
      {
        url: `user/user-monet-token/get`,
        method: "get",
      },
      getMonetResult
    );
  }, [triggerAPI, getMonetResult]);

  useEffect(() => {
    // getBalance();
    // getMonetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterChange = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    const index = parseInt(e?.currentTarget?.dataset?.index);
    setFilteredData(id);
    setActiveTab(index);
    setPage(1);
  };
  return (
    <>
      <h4 className="balance-text">Account Balances</h4>
      <div className={`w-full flex flex-col gap-2`}>
        <div className="text-left flex gap-[0.625rem] items-center flex-col sm:flex-row">
          <div
            className={`${
              activeTab === 0 && "active"
            } reward-card flex flex-col gap-2 cursor-pointer p-3`}
            data-index={0}
            data-id={"none"}
            onClick={onFilterChange}
          >
            <div className="">
              <span>All Transactions</span>
            </div>
            <div className="flex gap-[0.625rem] transaction-img">
              <img src={cardanoIcon} alt="icon" />
              <img src={monentIcon} alt="icon" />
            </div>
          </div>
          <div
            className={`${
              activeTab === 1 && "active"
            } reward-card flex items-center gap-[0.75rem] cursor-pointer p-3`}
            data-index={1}
            data-id={"ada"}
            onClick={onFilterChange}
          >
            <div className="transaction-img">
              <img src={cardanoLgIcon} alt="icon" />
            </div>
            <div className="flex flex-col gap-[0.375rem] justify-center">
              <span>ADA </span>
              <span className="font-normal bal-count">{thousandCommaSeparator(Number(balance?.ada))} </span>
            </div>
          </div>
          <div
            className={`${
              activeTab === 2 && "active"
            } reward-card flex items-center gap-[0.75rem] cursor-pointer p-3`}
            data-index={2}
            data-id={"monet"}
            onClick={onFilterChange}
          >
            <div className="transaction-img">
              <img src={monentLgIcon} alt="icon" />
            </div>
            <div className="flex flex-col gap-[0.375rem] justify-center">
              <span>Monet </span>
              <span className="font-normal bal-count">{thousandCommaSeparator(Number(balance?.monet))} </span>
            </div>
          </div>
        </div>
        <div className="rewards-section">
          <div className="sm-none">
            <Panel className="p-5 gap-5">
              <Table>
                <thead className="text-left rewards-table-head">
                  <tr>
                    <th>
                      <TableHeader
                        label="Date"
                        sort={sort}
                        sortKey="createdAt"
                        onSort={onSortHandler}
                      />
                    </th>
                    <th>
                      <TableHeader label="Reward Type"sort={sort}
                        sortKey="type"
                        onSort={onSortHandler} />
                    </th>
                    
                     <th className="">
                        <TableHeader label="Description" sort={sort}
                        sortKey="description"
                        onSort={onSortHandler}/>
                      </th>
                      
                      <th >
                        <TableHeader label="Token" sort={sort}
                        sortKey="currency"
                        onSort={onSortHandler} />
                      </th>
                    <th>
                      <TableHeader label="Amount" sort={sort}
                        sortKey="amount"
                        onSort={onSortHandler} />
                    </th>
                  </tr>
                </thead>
                <tbody className="text-left rewards-table-body">
                  {purchaseList?.map((rewards, index) => {
                    return (
                      <tr key={rewards?.id + "table" + index}>
                        <td>{rewards?.createdAt}</td>
                        <td className="capitalize">{rewards?.transactionType}</td>
                        <td>{`${rewards?.description?.charAt(0)?.toUpperCase()}${rewards?.description?.slice(1)}`}</td>
                        <td className="capitalize"><div className="flex gap-1 items-center"><img src={rewards?.currencyType?.name==="monet"?monentLgIcon:cardanoLgIcon} className="mr-1 token-icon"  alt="icon"/>{rewards?.currencyType?.name}</div></td>
                        <td className="capitalize">{thousandCommaSeparator(Number(rewards?.amount))}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {!purchaseList?.length && (
                <div className="text-center no-data-found no-rows pt-4">
                  <p>No rewards found</p>
                </div>
              )}
              {/* <div className="rewards-pagination mt-6 mb-5">
                <Pagination
                  itemsPerPage={paginationConfig.perPage}
                  rows={purchaseList?.length}
                  total={totalPage}
                  onPageChange={onPageChangeHandler}
                  selected={page - 1}
                />
              </div> */}
            </Panel>
          </div>
        </div>

        <div className="sm-block">
          <div className="rewards-container-sm flex flex-col gap-[2rem]">
            {/* <div className='ml-auto'>
                        <SelectBox className={`select-box-new cursor-pointer`}
                            mainClassName={`rewards-select-filter flex justify-end`}
                            itemsClassName={`pruchase-filter-items`}
                            blueArrow={true}
                            borderLess={false}
                            currentId={filteredData}
                            onChange={onFilterChange}
                            options={stateFilterOption} />
                    </div> */}
            {purchaseList?.length?purchaseList?.map((rewards, index) => {
              return (
                <div
                  className="rewards-item flex justify-between flex-col gap-2"
                  key={rewards?.id + "table" + index}
                >
                  <div className="item-left flex gap-2 justify-between">
                    <div className="flex justify-between gap-6 grow shrink basis-[30%]">
                      <div className="text-left grey-w-color text-[0.625rem] capitalize">
                        {rewards?.createdAt}
                      </div>
                    </div>
                    <div className="flex gap-2 grow shrink basis-[50%] justify-between">
                      <div className="flex justify-between flex-col gap-[7px] flex-1">
                        <div className="text-left sm:text-[0.625rem] text-[0.75rem] highlight-color grey-w-color font-normal capitalize">
                          {rewards?.transactionType
                            ? rewards?.transactionType
                            : "NA"}
                        </div>
                        <div className="item-left flex gap-2">
                          <div className="text-left sm-date capitalize">
                            {rewards?.description}
                          </div>
                        </div>
                      </div>
                      <div className="sm:gap-6 gap-[0.375rem] flex justify-between ">
                        <div className="text-[0.625rem] grey-w-color flex sm:mt-[2px] mt-0 sm:items-center items-start font-normal">
                        <img src={rewards?.currencyType?.name==="monet"?monentLgIcon:cardanoLgIcon} className="mr-1 token-icon"  alt="icon"/>{thousandCommaSeparator(Number(rewards?.amount))}
                        </div>
                        {rewards?.txHash && (
                          <a
                            href={`${paymentStatusLink}${rewards?.txHash}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={exterNalLinkIcon}
                              alt="external link icon"
                            />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }):<div className="text-center no-data-found pt-4">
            <p>No rewards found</p>
          </div>}
            {/* <div className="rewards-pagination mt-6 mb-5">
            {!!purchaseList?.length&&<Pagination
                itemsPerPage={paginationConfig.perPage}
                rows={purchaseList?.length}
                total={totalPage}
                onPageChange={onPageChangeHandler}
                selected={page - 1}
              />}
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Rewards;
