/* eslint-disable no-sequences */
import PageListing from "../SubComponents/PageListing";
import { useCallback, useEffect, useRef, useState } from "react";
import useDiscoverApiHelper from "../API/apiHelper";
import { useStore } from "App/hooks-store/store";
import { useNavigate } from "react-router-dom";
import Pagination from "App/Components/Ui/Pagination";
import SkeltonCard from "App/Components/Ui/SkeltonCard";
import userAvatar from 'Assets/Icons/userAvatars.svg'
import useScreenSize from "App/hooks/use-screen-size";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";

const Collectors = () => {
    const screenSize = useScreenSize()?.width;
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    const [{ activeMenuIcons, topActiveMenuData, topActiveMenu }, dispatch] = useStore();
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [collectorList, setCollectorList] = useState([]);
    const { getCollectorGalleryAPI } = useDiscoverApiHelper();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const timeOut = useRef(null);
    const paginationConfig = {
        perPage: 100
    }
    const topMenuOption = [
        {
            id: 1,
            element: "Collections",
            link: "/discover/collections",
        },
        { id: 2, element: "Creators", link: `/discover/creators` },
        {
            id: 3,
            element: "Collectors",
            link: `/discover/collectors`,
        }
    ];

    const getCollectorList = useCallback(() => {
        const dataParam = {
            page: page,
            items:isPortrait && screenSize<=550?10: paginationConfig?.perPage,
            search: searchValue ?? '',
            filter: {
                priority: 1,
                userType: "collectors"
            }

        }
        if (searchValue) {
            dataParam.filter.searchUsers = 1;
        }
        setLoading(true)
        getCollectorGalleryAPI(dataParam, (res => {
            const { list, pageMeta } = res;
            const tempArray = list?.map((item, index) => {
                let assetImg = '';
                if (item?.files?.sliders?.length)
                    assetImg = item?.files?.sliders[0];
                return {
                    id: item?.user?.id,
                    key: `${item?.id}${index}`,
                    name: item?.user?.username,
                    profilIcon: item?.user?.profilePicUrl ?? userAvatar,
                    bgImage: item?.thumbnailBackground?.imageUrl,
                    assetImg: assetImg
                }
            })
            setCollectorList(tempArray);
            setTotalPage(pageMeta.totalItems);
            window.scrollTo(0, 0);
            setLoading(false)
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page,isPortrait,screenSize, getCollectorGalleryAPI, searchValue])

    useEffect(() => {
        dispatch('setTopMenuBarOptions', { showMenuIcons: true })
        dispatch('enableTopIcons', {
            favourite: false,
            filterSort: false,
            search: true,
            dropDown: false,
            sorting: false
        }) 

        dispatch('setActiveIcons', { search: '' })
        return () => {
            dispatch('resetAll')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        getCollectorList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchValue,isPortrait,screenSize])
    const tabChange = () => {
        const currentMenu = topMenuOption[topActiveMenu];
        if (currentMenu?.link)
            navigate(currentMenu?.link)
    }
    useEffect(() => {
        if (isPortrait && screenSize<=550 && [1, 2].includes(Number(topActiveMenu))) {
            tabChange()
        } else if (isPortrait && screenSize<=550 && Number(topActiveMenu) === 0 && topActiveMenuData?.element === "Collections") {
            tabChange()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topActiveMenu])

    const toDetail = (id) => {
        const userId = parseInt(id)
        navigate(`/discover/collectors/details/${userId}`)
    }
    const onPageChangeHandler = (page) => {
        setPage(page)
    }
    useEffect(() => {
        if (page !== 1)
            setPage(1)
        if (timeOut.current) {
            clearTimeout(timeOut.current)
        }
        timeOut.current = setTimeout(() => {
            setSearchValue(activeMenuIcons?.search)
        }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons?.search])
    let content = (className) => <div className={`w-full overflow-flex-auto sm:mb-0 sm:mb-[3.5rem] mb-0 flex h-full gap-[1.42857rem] ${className}`}>
             {!collectorList?.length &&  <DetailsSkeltonCard
            show={loading}
            height={"100%"}
            count={6}
            inline={true}
            details={"img-2-line"}
            noAbsolute
            parentDiv="grid home-grid mt-0 aspect-pt-six w-full"
          />}
        <div className={`flex w-full flex-1 h-fit ${!collectorList?.length&&"justify-center"}`}>
 
        {collectorList?.length?<PageListing
            list={collectorList}
            setSelectedCard={false}
            onCardClick={toDetail}
        />:!loading&&<div className="flex items-center no-data justify-center visibleSlowly">No discover collectors found</div>}
</div>
    </div>
    return (
        <div className="w-full flex flex-col discover-collectors discover-wrapper justify-between h-full gap-[1.42857rem]">
            <div className="sm:hidden flex flex-col h-full gap-[1rem]">
                {content("sm:hidden flex")}
                {(!!collectorList?.length && !loading) &&
                    <div className="mb-2 sm:hidden block nftdrops-btn pb-[4rem]">
                        <Pagination itemsPerPage={paginationConfig.perPage} total={totalPage} rows={collectorList?.length} onPageChange={onPageChangeHandler} active={page} />
                    </div>}
            </div>
            {content("sm:flex hidden")}
            {(!!collectorList?.length && !loading) &&
                <div className={`mt-2 sm:mb-2 mb-0 sm:pb-0 pb-[3.5rem] sm:block hidden nftdrops-btn`}>
                    {/* <Pagination itemsPerPage={isPortrait && screenSize<=550?10: paginationConfig?.perPage} total={totalPage} rows={collectorList?.length} onPageChange={onPageChangeHandler} active={page} /> */}
                </div>}
        </div>
    )
}
export default Collectors;