import Panel from "App/Components/Ui/Panel"
import React, { useEffect, useState } from "react"
import FrameDisplay from "./Components/FrameDisplay"
import FrameDetails from "./Components/FrameDetails"
import styles from "./NewFrames.module.scss"
import { getFrameFilterList } from "App/Helper/aspectRatioCalc"
import { popupMobileScrollAdj } from "App/Helper/utilities"
import SkeltonCard from "App/Components/Ui/SkeltonCard"
import WalletSelectPop from "App/Components/Ui/WalletSelectPop"
import useScreenSize from "App/hooks/use-screen-size"
import InfiniteLoader from "App/Components/InfiniteLoader"
import '../../FrameShop.scss';
import { useDispatch, useSelector } from "react-redux"
import { getShowWalletPop } from "App/ReduxStore/Slicers/authSlice"

let functionCount = 0;
const NewFrames = React.memo((props) => {

    const [selectedFrame, setSelectedFrame] = useState(null)
    const [url, setURL] = useState({})
    const [openDetails, setOpenDetails] = useState(false)
    const [isZoom, setZoom] = useState(false)
    const [frames, setFrames] = useState([])
    const screenSize = useScreenSize()?.width;
    const reduxDispatch = useDispatch();
    const showWalletPop = useSelector(getShowWalletPop);
    const [frameCounter, setFrameCounter] =useState()
    useEffect(() => {
        if(props?.isFilterApplied) {
            setOpenDetails(false)
            setSelectedFrame(null)
        }
    }, [props.isFilterApplied])
    useEffect(() => {
        // if (!frames?.length) {
            setFrames(props.frames)
            if (props.nft){
                functionCount++
                arHandler(1)
            }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.frames])
    useEffect(() => {
        if (props.nft) {
            urlChange(props.nft)
            arHandler(2)
        }
        else {
            setURL({})
        }
        setOpenDetails(false);
        setSelectedFrame(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nft])
    const urlChange = async (data) => {
        setURL(data)
    }
    const arHandler = async (count) => { 
        functionCount = functionCount + count;
        props?.setFramesLoading && props?.setFramesLoading(true);
        let temp = []
        if (props.frames) {           
            temp = await getFrameFilterList(props?.nft?.nftcdnimage256, props.frames)
            setFrameCounter(props.frames.length - temp.length)
        }            
        (functionCount >=2 && props?.setFramesLoading) && setTimeout(() => {
            props?.setFramesLoading(false);
            functionCount = 0;
        }, 500);   
        setFrames(temp) 
        console.log('ar handler')
    }
    const frameSelectHandler = e => {
        let frameFilterd = props.frames.find(frame => frame.id === Number(e.currentTarget.dataset["id"]))
        setSelectedFrame(frameFilterd)
        setTimeout(() => {
            e?.target?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        }, 500)
    }
    const detailsCloseHandler = () => {
        popupMobileScrollAdj(false)
        setOpenDetails(false);
        setSelectedFrame(null);
    }
    const detailsOpenHandler = () => {
        popupMobileScrollAdj(true)
        setOpenDetails(true)
        setZoom(false);
        props?.setIsFilterApplied && props?.setIsFilterApplied(false)
    }
    const handleZoom = () => {
        setZoom(prv => !prv);
    }

    const setShowWalletPop=()=>reduxDispatch(setShowWalletPop(true))
    return <div className={`${styles["new-frames"]} frame-shop flex-1 h-full ${openDetails ? styles["active"] : ''}`}>
        <Panel transparent className={`p-2.5 h-full ${styles["custome-frame-panel"]}`}>
            {(!frames?.length && !props.framesLoading) &&
                <div className="h-full grid place-items-center">

                    {props.isMyFramesActive ? `No Frames added` :'No Frames available!'}
                </div>
            }
            {props.framesLoading ?
            <SkeltonCard show={props.framesLoading || frames?.length} height={screenSize > 551 ? props?.displayChange === 'lg' ? "22.85rem" : "18.95rem" : "10.5rem"} count={8} inline={true} noAbsolute={true} containerClassName={`${styles["frame-panel-lg"]} gap-y-[2.5rem] gap-x-[1.5rem] w-full`} /> : 
            !!frames?.length &&      <InfiniteLoader
            loadData={props.loadMore}
            length={frames?.length}
            loaderCount={props?.displayChange === 'lg' ? 8 : 10}
            hasMore={frames?.length < (props?.frameCountState?.totalFrames - frameCounter)}
            loader="skelton"
            containerStyle={'w-full'}
            parentDiv={props?.displayChange === 'lg' ? `frame-loader-container-lg` :`frame-loader-container-sm`}
            noAbsolute={true}
        >
                <div className={`${props?.displayChange === 'lg' ? styles["frame-panel-lg"] : styles["frame-panel-sm"]} gap-y-[2.5rem] gap-x-[1.5rem] sm:pl-0 pl-2.5  sm:pb-3 pb-[3rem] pr-4`}>
                    {frames && frames.map((frame, index) =>{
                        const metaInfoFrame = frame?.isLandscape ? frame?.metaInfo?.landscape : frame?.metaInfo?.portrait;
                        return <div data-id={`${frame.id}`} onClick={frameSelectHandler} key={frame.id} className={`${styles["card-colr"]} flex flex-col object-contain ${frame?.isLandscape ? 'land-frame' : 'port-frame'}`}>
                            <FrameDisplay onZoomFrame={detailsOpenHandler} nft={url} frame={frame} inedx={`${frame.id}-${index}`}
                            selectedFrame={selectedFrame} frameId={frame.id} 
                            frameSrc={frame?.isLandscape ? (frame?.imageUrlLand ?? frame?.imageUrl) : frame?.imageUrl} 
                            metaInfoFrame={metaInfoFrame} framesLoading={props.framesLoading} addButton={true} 
                            isUserAdded={frame.isUserAdded} onAddorRemoveFrame={props?.onAddorRemoveFrame} isAddingFrame={props.isAddingFrame}     useFrameContainer2={true}/>
                            
                        </div>}
                    )}
                </div>
            </InfiniteLoader> }

        </Panel>
        {
            openDetails && <div className={`${styles["frameshop-details"]} preview-nft-view overflow-auto w-full ${isZoom ? styles['active'] : ''}`}>
        <FrameDetails
            nft={url}
            frame={selectedFrame}
            frames={frames}
            setShowWalletPop={setShowWalletPop}
            handleZoom={handleZoom}
            isZoom={isZoom}
            detailsCloseHandler={detailsCloseHandler}
            useFrameContainer2={true}
            onAddorRemoveFrame={props?.onAddorRemoveFrame}
            isAddingFrame={props.isAddingFrame}
        />            </div>
        }
         { showWalletPop && <WalletSelectPop show={showWalletPop} className={''}  /> }
    </div>
})

export default NewFrames