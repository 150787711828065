import { useStore } from 'App/hooks-store/store';
import React, { useEffect, useState } from 'react'
import NftCard from '../NftCard';

const GroupBy = ({ listRef, nftList, styles, stylesA, styleCondition, collections, framesList, selected, onCollectionSelect, noFavourite, noClick, onImgClick, displayNft, filterItemsHandler }) => {

    const [{ activeMenuIcons }] = useStore();

    const [loading, setLoading] = useState(true);
    const [groupedData, setGroupedData] = useState(null);
    const [seeAll, setSeeAll] = useState(null);

    const handleSortData = (data, lastObjectName) => {
        let sortData = [];
        let lastObject = {};
        Object.entries(data).forEach(([key, value]) => {
            if (value?.header === lastObjectName) {
                lastObject = value;
            } else {
                sortData.push(value)
            }
        })
        sortData = sortData?.sort((a, b) => a["header"] ? b["header"] ? a["header"]?.toLowerCase() > b["header"]?.toLowerCase() ? 1 : -1 : -1 : 1);
        sortData?.push(lastObject);
        return sortData;
    }

    const handleCategoryGrouping = (data) => {
        const filteredData = {};

        data?.forEach((piece) => {
            const { category } = piece;

            if (category && category.name) {
                const categoryName = category.name;

                if (!filteredData[categoryName]) {
                    filteredData[categoryName] = { header: categoryName, data: [] };
                }

                filteredData[categoryName].data.push(piece);
            } else {
                const othersCategory = 'Other';

                if (!filteredData[othersCategory]) {
                    filteredData[othersCategory] = { header: othersCategory, data: [] };
                }

                filteredData[othersCategory].data.push(piece);
            }
        });
        const sortedData = handleSortData(filteredData, 'Other')
        setGroupedData(sortedData);
        setLoading(false)
    };

    const handleSubCategoryGrouping = (data) => {
        const filteredData = {};

        data?.forEach((piece) => {
            const { category, subCategoryName } = piece;

            if (category && category.name && subCategoryName) {
                const categoryName = `${category.name} - ${subCategoryName}`;

                if (!filteredData[categoryName]) {
                    filteredData[categoryName] = { header: categoryName, data: [] };
                }

                filteredData[categoryName].data.push(piece);
            } else {
                const othersCategory = 'Other';

                if (!filteredData[othersCategory]) {
                    filteredData[othersCategory] = { header: othersCategory, data: [] };
                }

                filteredData[othersCategory].data.push(piece);
            }
        });
        const sortedData = handleSortData(filteredData, 'Other')
        setGroupedData(sortedData);
        setLoading(false)
    };

    const handleCommunityGrouping = (data) => {
        const filteredData = {};

        data?.forEach((piece) => {
            const { community } = piece;

            if (community && community?.name) {
                const communityName = community.name;

                if (!filteredData[communityName]) {
                    filteredData[communityName] = { header: communityName, data: [] };
                }

                filteredData[communityName].data.push(piece);
            } else {
                const otherCommunity = 'No Community';

                if (!filteredData[otherCommunity]) {
                    filteredData[otherCommunity] = { header: otherCommunity, data: [] };
                }

                filteredData[otherCommunity].data.push(piece);
            }
        });
        const sortedData = handleSortData(filteredData, 'No Community')
        setGroupedData(sortedData);
        setLoading(false)
    };

    useEffect(() => {
        setLoading(true);
        setSeeAll(null)
        if (activeMenuIcons?.sort) {
            if (listRef.current)
                listRef.current.scroll({
                    top: 0,
                    behavior: 'smooth'
                });
            if (activeMenuIcons?.sort === 'By Category') {
                handleCategoryGrouping(nftList)
            }
            if (activeMenuIcons?.sort === 'By Sub-Category') {
                handleSubCategoryGrouping(nftList)
            }

            if (activeMenuIcons?.sort === 'By Community') {
                handleCommunityGrouping(nftList)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons, nftList]);

    return (<>
        <div>
            {!loading && groupedData?.length && groupedData.map((item, key) => {
                const sliceNumber = seeAll === item?.header ? item?.data?.length : displayNft === "lg" ? 8 : 10;
                return (
                    <div className='flex flex-col' key={`${item?.header}${key}`}>

                        <div className='w-full flex items-center justify-between py-[1rem]'>
                            <div className='text-[1.25rem] '>{item?.header}</div>

                            {item?.data?.length > 8 && <div className='text-[0.75rem] cursor-pointer text-[var(--cu-white-100)]' onClick={() => setSeeAll((prev) => {
                                return item?.header === prev ? '' : item?.header;
                            })} >{item?.header === seeAll ? 'See less' : 'See all'}</div>}
                        </div>

                        <div
                            className={`${displayNft !== "list" && styles["panel-area"]}  ${displayNft === "lg"
                                ? styleCondition
                                    ? styles["nft-display-lg"]
                                    : styles["nft-display-lg-asset"]
                                : styles["nft-display-lg"]
                                } ${displayNft === "list" && styles["nft-display-list"]} ${displayNft === "lg" ? styles["lg-card"] : styles["sm-card"]
                                }  grid gap-[0.45625rem]`}
                        >
                            {item?.data?.slice(0, sliceNumber).map((nft, index) => (
                                <div
                                    className={`${styles["card-area"]} `}
                                    key={`${nft.policy}${index}`}
                                >
                                    <NftCard
                                        showAddDiv={true}
                                        addDivClass={styles["hover-overlay"]}
                                        parent="nft-collection"
                                        collections={collections}
                                        framesList={framesList}
                                        filteredList={true}
                                        selected={!!(selected === nft.asset)}
                                        index={index}
                                        onCollectionSelect={onCollectionSelect}
                                        nft={nft}
                                        noFavourite={noFavourite}
                                        noClick={noClick}
                                        onImgClick={onImgClick}
                                        displayNft={displayNft}
                                        filterItemsHandler={filterItemsHandler}
                                    />
                                </div>))}
                        </div>
                    </div>
                )
            })}
        </div>
    </>)
}

export default GroupBy