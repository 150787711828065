import { useEffect, useState } from "react";
import DiscoverTopPanel from "../../../SubComponents/DiscoverTopPanel";
import './CollectorDetail.scss';
import DiscoverGallery from "../../../SubComponents/DiscoverGallery";
import PreviewGallery from "App/Pages/NftGallery/Component/MyGallery/Components/PreviewGallery";
import useDiscoverApiHelper from "../../../API/apiHelper";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "App/hooks-store/store";
import DiscoverTopPanelMob from "../../../SubComponents/DiscoverTopPanelMob";
import useScreenSize from "App/hooks/use-screen-size";
import DetailsInfo from "../../../SubComponents/Details";
const paginationConfig = {
    perPage: 100
}
const CollectorDetail = () => {
    const [totalGalleryCount, setTotalGalleryCount] = useState(0);
    const [selectedGallery, setSelectedGallery] = useState(null);
    const [detailsInfo, setDetailsInfo] = useState({});
    const [previewGallery, setPreviewGallery] = useState({ mode: false, data: [] });
    const [assetPreviewGallery, setAssetPreviewGallery] = useState({ mode: false, data: [] });
    const [galleryData, setGalleryData] = useState(null);
    const { getUserGalleryDataAPI, getStandAloneGallery } = useDiscoverApiHelper();
    const [page, setPage] = useState(1);
    const { userId } = useParams();
    const navigate = useNavigate()
    const [{ activeMenuIcons }, dispatch] = useStore();
    const [activeTab, setActiveTab] = useState("Galleries");
    const onPageChangeHandler = (page) => {
        setPage(page)
    }

    
    const onDetailsInfoHandler=(info)=>setDetailsInfo(info)
    const getGalleryList = (parms) => {
        const dataParam = {
            page: page,
            items: isPortrait && screenSize<=550?1000 : paginationConfig?.perPage,
            search: '',
            filter: { userId: [parseInt(userId)], isDiscoverable: true }

        }
        getUserGalleryDataAPI(dataParam, (res => {
            const { list } = res;
            setTotalGalleryCount(list?.length);
            setGalleryData(list);
        }));
    }
    const screenSize = useScreenSize()?.width;
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    useEffect(() => {
        if (activeMenuIcons?.back) {
            if(detailsInfo?.id){
                setDetailsInfo({})
              } else if(selectedGallery&&isPortrait && screenSize<=550){
                setSelectedGallery(null)
              }
              else{
            dispatch('setActiveMenuOptions', 2);
            navigate('/discover/collectors')
            dispatch('enableTopIcons', { back: false });
              }
              dispatch("setActiveIcons", { back: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons?.back])
    useEffect(() => {
        dispatch('setTopMenuBarOptions', { showMenuIcons: true, showTopMenus: false })
        dispatch('enableTopIcons', {
            back: true,
            filter: false,
            search: false,
            dropDown: false,
            sorting: false
        })
        dispatch('setActiveIcons', { back: false })
        return () => {
            dispatch('resetAll')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getGalleryList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const handleGalleryCardClick = (e) => {
        let selected = galleryData && galleryData?.find((gal => gal?.id === parseInt(e)));
        let data = {
            id: selected?.id
        }
        setPreviewGallery({ mode: true, data: { id: e, type: 0 } })
        getStandAloneGallery(data, (res => {            
            selected.assets = res?.data?.assets;
            selected.Shapes = res?.data?.Shapes;
            setSelectedGallery(selected);
            isPortrait && screenSize<=550 && openAssetPreviewGallery(selected)
        }))
    }
    const toGallery = () => {
        setPage(1)
        setSelectedGallery(null);
        setPreviewGallery({ mode: false, data: {} })
   setTimeout(()=>{
    dispatch('enableTopIcons', {
        back: true,
        filter: false,
        search: false,
        dropDown: false,
        sorting: false
        })
    },10)
     }
    const handlePreviewGalleryCard = (e) => {
        openPreviewGallery(selectedGallery?.id);
    }
    const openPreviewGallery = (e) => {
        setPreviewGallery({ mode: true, data: { id: e, type: 0 } })
    }
    const openAssetPreviewGallery = (e) => {
        setAssetPreviewGallery({ mode: true, data: { id: e?.id, type: 0 } })
    }
    
    useEffect(() => {
        if (selectedGallery) {
            window.scrollTo(0, 0)
        }
    }, [selectedGallery])

    const handleTabChange = (tab) => {
        setActiveTab(tab);
      };
    return (
        <div className={`h-full discover-collector-details flex flex-col overflow-auto`}>
            {!detailsInfo?.id ? <>
               <DiscoverTopPanel self={true} fromCollector={true} className="sm:flex hidden" /> 
                    <DiscoverTopPanelMob onDetailsInfoHandler={onDetailsInfoHandler} self={true} className={"p-[1rem] sm:hidden flex"} />
                    <div className="tab-container-gallery mobileTab">
                            <div className="tab-list-gallery ">
                                <div className={`tab-data-gallery flex gap-[1.42857rem]`}>
                                    <div className={`tab ${activeTab === "Galleries" ? "active" : "active"}`} onClick={() => handleTabChange("Galleries")}>
                                        {
                                        // previewGallery?.mode ? `Gallery Assets (${selectedGallery?.assetsCount})` :
                                        `Galleries (${totalGalleryCount})`}
                                    </div>
                                </div>
                            </div>
                        </div>
                <div className={` h-full ${!(isPortrait&&screenSize<=550) ? "page-shadow" : ""}`}>
                    <div className="flex flex-col sm:gap-[0.72rem] gap-[1rem] pb-4 h-full">
                        {activeTab === "Galleries" ? (
                        previewGallery?.mode ?
                            <div className="discover-gallery-preview-section">
                                <PreviewGallery
                                    galleryUpdateId={previewGallery?.data?.id}
                                    viewFromOther={true}
                                    standalone={true}
                                    enableEdit={false}
                                    selectedWall={''}
                                    galleryType={previewGallery?.data?.type}
                                    fromDiscover={false}
                                    onClose={toGallery}
                                    applyFrameButton={false}
                                    className={'top-0 p-[1rem] pt-0 pb-0'}
                                />
                            </div> :
                             <div className="p-4">
                            <DiscoverGallery
                                galleryList={galleryData}
                                handleCardClick={handleGalleryCardClick}
                                selectedGallery={selectedGallery}
                                page={page}
                                type="collectors"
                                previewGallery={assetPreviewGallery}
                                hidePagination={true}
                                totalItems={totalGalleryCount}
                                openAssetPreviewGallery={openAssetPreviewGallery}
                                perPage={paginationConfig?.perPage}
                                openPreviewGallery={handlePreviewGalleryCard}
                                onPageChangeHandler={onPageChangeHandler}
                                setSelectedGallery={setSelectedGallery}
                              
                            />
                             </div>
                                ): null}
                    </div>
                </div>
            </>
                : <DetailsInfo  data={detailsInfo}/>}
        </div>
    )
}

export default CollectorDetail;