import { Fragment, useRef, useState, useEffect, useLayoutEffect } from "react"
import ExpandIcon from "Assets/Images/ExpandIcon.svg"
import GalleryImages from "../../../../Components/Ui/GalleryFrameImages";
import RoundedButton from "App/Components/Ui/RoundedButton";
import DoubleTap from "Assets/Images/DoubleTap.svg"
import upIcon from "Assets/Icons/curator-up.png";
import downIcon from "Assets/Icons/curator-down.png";
import "./SelectedPopup.scss"
import { useStore } from "App/hooks-store/store";
import closeIcon from "Assets/Images/close-circle-icon.svg"
import useScreenSize from "App/hooks/use-screen-size";
import openArrow from 'Assets/Images/arrow_galleries.svg'

const SelectedPopup = (props) => {
    const screenSize = useScreenSize()?.width;
    const [collapse, setCollapse] = useState(true)
    const [{ currentSelectedNfts, leftMenuCollapse }, dispatch] = useStore(false);
    const curatePanelRef = useRef(null);
    const targetRef = useRef();
    const onClickHandler = () => {
        setCollapse(!collapse)
    }
    const onImageClick = (e) => {
        if (screenSize < 551) {
            e.stopPropagation();
        }

        props?.action(e);
    }
    useEffect(() => {
        if (window.innerWidth < 551) {
            document.addEventListener("click", handleClickOutside, false);
            return () => {
                document.removeEventListener("click", handleClickOutside, false);
            };
        }
    }, []);
    const handleClickOutside = event => {
        if (curatePanelRef.current && !curatePanelRef.current.contains(event.target)) {
            setCollapse(true);
        }
    };
    const handleCurate = (e) => {
        e.stopPropagation();
        props.onClickPreview();
    }
    const updateHeight = () => {
        const root = document.documentElement;
        if(targetRef.current) {
            if(targetRef.current) {
                root?.style.setProperty(
                    "--gallery-min-height", `${targetRef.current.offsetWidth}px`
                );
            }
        }
    }
    useLayoutEffect(() => {
        updateHeight()
        window.addEventListener("resize", () => {
            setTimeout(() => {
                updateHeight()            
            }, 1000);
        }, false);
    },[]);
    useEffect(() => {
        setTimeout(() => {
            updateHeight()            
        }, 1000);
        updateHeight()
    },[leftMenuCollapse, collapse]);
    return (
        <>
            <div className={`selected-popup flex flex-col  p-2 ${collapse ? 'collapse-panel' : 'expand-panel'}`}>
                <div className="selected-font items-center justify-between flex cursor-pointer px-4" onClick={onClickHandler}>
                    <div className="flex items-center gap-[2rem]">
                        Gallery ({currentSelectedNfts?.length ?? 0})
                        <div className="flex gap-2 items-center">
                        <div className="">
                            <img className="w-[1rem] h-[1rem]" alt="NoImage" src={DoubleTap} />
                        </div>
                        <div className="text-[0.75rem] text-[#F7F8F9]">Click to add or remove</div>
                        </div>
                    </div>
                    <div className="w-[12rem]">
                            <img alt="NoImage" src={openArrow} className='collapse-popup' />
                        </div>
                    <RoundedButton className="custom-button" Name="Curate" Type="6" OnClick={handleCurate}></RoundedButton>
                </div>
                {!collapse &&
                    <div className="flex w-full">
                        <div className="nft-gallery-full-card selected-nfts w-full">
                            <div className="pt-4 pb-4 px-4 grid selected-wrapper">
                                {currentSelectedNfts?.map((selectedNfts, i) =>
                                    <Fragment key={i}>
                                        <div className={`${selectedNfts?.isLandscape && ''} selected-pop-boxcard collection-card min-view flex flex-col`} ref={targetRef}>
                                            <GalleryImages
                                                onClick={onImageClick}
                                                actionImage={closeIcon}
                                                action='close'
                                                index={i} galleryNew={selectedNfts}
                                                src={selectedNfts?.nftcdnimage256}
                                                dataId={selectedNfts?.asset}
                                                className="nft-gallery-card-image view-gal-mob" nftGalleryNew={true} />
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="selected-popup-sm" ref={curatePanelRef} onClick={onClickHandler}>
                <div className="selected-popup-head flex">
                    <div className="flex items-center gap-2">
                        Gallery ({currentSelectedNfts?.length ?? 0})
                        <div className="" >
                            {collapse ? <img src={downIcon} alt="downIcon" /> : <img src={upIcon} alt="upIcon" />}
                        </div>
                    </div>

                </div>
                {!collapse &&
                    <div className="selected-popup-container selected-popup-mob nft-gallery-full-card selected-nfts w-full">
                        <div className="pt-4 pb-4 px-4 grid ">
                            {currentSelectedNfts?.map((selectedNfts, i) =>
                                <Fragment key={i}>
                                    <div className={`collection-card flex flex-col py-1 px-1`}>
                                        <GalleryImages
                                            index={i} galleryNew={selectedNfts}
                                            src={selectedNfts?.nftcdnimage256}
                                            onClick={onImageClick}
                                            dataId={selectedNfts?.asset}
                                            actionImage={closeIcon}
                                            action='close'
                                            className="nft-gallery-card-image view-gal-mob" />
                                    </div>
                                </Fragment>
                            )}
                        </div>

                    </div>

                }

            </div>

        </>
    )
}

export default SelectedPopup