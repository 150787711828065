/* eslint-disable react-hooks/exhaustive-deps */
import "./DiscoverCollection.scss";
import { useEffect, useRef, useState,React, useCallback } from "react";
import Pagination from "App/Components/Ui/Pagination";
import useScreenSize from "App/hooks/use-screen-size";
import CollectionCard from "App/Components/CollectionCard";
import { useStore } from "App/hooks-store/store";
import { useNavigate } from "react-router-dom";
import RightPanel from "./Component/RightPanel";
import RemoveWarning from "App/Components/RemoveWarning";
import useHttp from "App/hooks/use-http";

const DiscoverCollection = (props) => {
  const [store,dispatch] = useStore();
  const screenSize = useScreenSize()?.width;
  const targetRef = useRef();
  const { triggerAPI } = useHttp();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [removeClick,setRemoveClick] = useState(false);
  const [clickedCollectionId, setClickedCollectionId] = useState("");
  const [whyCollectionNotDeletedMessage, setWhyCollectionNotDeletedMessage] = useState("");
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  // const [selectedCollection, setSelectedCollection] = useState(null) //I will use this state later for mapping collection Items
  // const {  sliceCollectionAPI } = useCollectionLoader();
  const navigate = useNavigate();

  const {
    spinnerMode} = store
  useEffect(() => {
    const root = document.documentElement;
    if (targetRef.current) {
      root?.style.setProperty(
        "--dicover-collection-ht",
        `${targetRef.current.offsetHeight}px`
      );
    }
  }, [props?.collectionList, props?.selectedCollection]);

  // const collectionSelectHandler = e => {
  //   setSelectedCollection({ policy: e.policy, name: e.name })
  //   if(e.policy) {
  //       const filterCollction = collectionRawData.find(aData => aData.policy_id === e.policy)
  //       dispatch("updateCollectionLoadingStatus", 'in-progress');
  //       sliceCollectionAPI(filterCollction?.data);
  //   }
  // }

  

  const handleCardClick = (e) => {
    let pId = e?.currentTarget?.dataset?.policy;
    props?.collectionCardClick && props?.collectionCardClick(e,pId);
    setIsDetailsOpen(true);
    // collectionSelectHandler(e)
  };

  const handleCardEditClick = (e,id) => {
    props?.collectionEditCardClick && props?.collectionEditCardClick(e,id);
    setIsDetailsOpen(true);
    // collectionSelectHandler(e)
  };

  const onPageChangeHandler = () => {
    props?.onPageChangeHandler && props?.onPageChangeHandler();
  };

  const backHandler = () => {
    props?.setSelectedCollection(null);
    navigate(`/discover/communities/${props?.pageId}/collection`)
  };
  const removeClickHandler=()=>{
    setRemoveClick((pre)=>!pre)
  }
 
  const removeResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      props?.reFetch && props?.reFetch()
      dispatch("showToast", {
        toast: { toastMode: "success", message: res?.message },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
const removeCollection = () =>{
    removeClickHandler()
    dispatch("showSpinner");
    let url = "community/collection/remove/"
    triggerAPI(
      {
        url: `${url}${+clickedCollectionId}`,
        method: "delete",
      },
      removeResult,
      (err) => {
        dispatch("hideSpinner");
        if(err?.response?.data?.error?.message?.toLowerCase()?.includes("sorry") || err?.response?.data?.error?.message?.toLowerCase()?.includes("gallery")){
          setWhyCollectionNotDeletedMessage(err?.response?.data?.error?.message)
      }else{
          setWhyCollectionNotDeletedMessage("")  
          dispatch("showToast", {
            toast: { toastMode: "error", message: err?.response?.data?.error?.message??err.message },
          });
      }
      }
    );
  }
  const onCardDeleteClick = (e,id) => {
    e.stopPropagation()
    setClickedCollectionId(id)
    removeClickHandler()
}
const closeCollectionNotDeletedMessagePopupHandler=()=>{
  setWhyCollectionNotDeletedMessage("")
}
  return (
    <>
    <div className="flex flex-col ">
      {props?.selectedCollection && (
        <RightPanel classNames={props?.classNames} collectionList={props?.collectionList} hideCloseOptn={props?.hideCloseOptn} backHandler={backHandler} setSelectedCollection={props?.setSelectedCollection} selectedCollection={props?.selectedCollection}  />
      )}
      {!props?.selectedCollection && (
        <div className="flex h-full gap-[1.42857rem]">
          <div
            className={`flex w-full flex-1 flex-col gap-4 ${
              (isPortrait&&screenSize <= 550)? props?.selectedCollection && "hidden" : ""
            }`}
          >
            <div
              className={`${props?.className} discover-collection-container w-full`}
              ref={targetRef}
            >
              {props?.collectionList&&props?.collectionList?.map((list,i) => (
                <CollectionCard
                  showData={{
                    profileIcon: true,
                    collectionName: true,
                    communityName: true,
                    count: false,
                    tag: true,
                  }}
                  edit={props?.edit}
                  delete={props?.delete}
                  onEditClick={handleCardEditClick}
                  cardClick={handleCardClick}
                  onDeleteClick={onCardDeleteClick}
                  row={list}
                  mainClass={`${isDetailsOpen ? "details-open" : ""}`}
                  className={""}
                  key={i}
                />
              ))}
            </div>
            {props?.collectionList&&!props?.collectionList?.length&&!spinnerMode&&<div className="flex items-center sm-h-8 justify-center no-data">
            There are currently no collections.
            </div>}
          </div>
        </div>
      )}
      {!props?.selectedCollection && !props?.hidePagination && (
        <div
          className={`pagination-container ${
            screenSize <= 550 ? props?.selectedGallery && "hidden" : ""
          }`}
        >
          <Pagination
            itemsPerPage={props?.perPage}
            total={props?.totalItems}
            rows={props?.collectionList?props?.collectionList?.length:0}
            onPageChange={onPageChangeHandler}
            active={props?.page}
          />
        </div>
      )}
    </div>
    {!!removeClick&&<RemoveWarning onDelete={removeCollection} onCancel={removeClickHandler} approveBtn="Remove">Are you sure you want to remove?</RemoveWarning>}
    {!!whyCollectionNotDeletedMessage&&<RemoveWarning onCancel={closeCollectionNotDeletedMessagePopupHandler} noCancelButton>{whyCollectionNotDeletedMessage}</RemoveWarning>}
    </>
  );
};

export default DiscoverCollection;