import "./subscriptionCard.scss";
import { Fragment, useCallback } from "react";
import ok from "Assets/Icons/ok.svg";
import monettok from "Assets/Images/Monet_round.png";
import { useNavigate } from "react-router-dom";
const SubscriptionCard = ({ plan, currentTab, selectSubscription }) => {
  const navigate = useNavigate();
  const Price = ({ price, requiredMonetHolding }) => {
    if (currentTab !== "Monet Members") {
      return (
        <span className="pricespan">
          ${price}
          <span className="year">/year</span>
        </span>
      );
    } else {
      return (
        <span className="monet">
          <span className="hold">Hold</span>
          <img alt="monettok" src={monettok} />
          <span>{parseInt(requiredMonetHolding).toLocaleString()}</span>
        </span>
      );
    }
  };
  const manageSubscriptionClick = useCallback(() => {
    if (plan.isSubscribed || currentTab === "Monet Members") {
      if (currentTab === "Monet Members" && !plan.isSubscribed) {
        navigate("/profile/rewards");
      }
      return;
    }
    selectSubscription(plan.id);
  }, [selectSubscription, currentTab, plan, navigate]);
  return (
    <Fragment>
      <div
        className={["subscriptioncard", plan.isSubscribed ? "active" : ""].join(
          " "
        )}
      >
        <h3>{plan.name}</h3>

        <h4>
          {plan.planPrice === 0 ? (
            `Free`
          ) : (
            <Price
              requiredMonetHolding={plan.requiredMonetHolding}
              price={plan.planPrice}
            />
          )}
        </h4>
        <div className="items flex">
          <div className="each-options flex gap-3">
            <img alt="ok" src={ok}></img>
            <label>Collection management</label>
          </div>
          <div className="each-options flex gap-3">
            <img alt="ok" src={ok}></img>
            <label>
              {plan.galleryType3D} 3D{" "}
              {plan.galleryType3D === "All" ? `galleries` : `gallery`}
            </label>
          </div>
          <div className="each-options flex gap-3">
            <img alt="ok" src={ok}></img>
            <label>
              {plan.availableGalleries}
              {plan.availableGalleries > 1 ? " galleries" : " gallery"}
            </label>
          </div>
          <div className="each-options flex gap-3">
            <img alt="ok" src={ok}></img>
            <label>
              {plan.devices === 1
                ? `Use on 1 device`
                : `Use on ${plan.devices} devices at once`}
            </label>
          </div>
          {plan.planPrice !== 0 && (
            <div className="each-options flex gap-3">
              <img alt="ok" src={ok}></img>
              <label>
                Display on {plan.anywallDisplayCount} Anywall{" "}
                {plan.anywallDisplayCount > 1 ? `displays` : `display`}
              </label>
            </div>
          )}
          <div className="each-options flex gap-3">
            <img alt="ok" src={ok}></img>
            <label>
              {plan?.availablePremiumFrame}{" "}
              Premium{" "}{+plan?.availablePremiumFrame > 1 ? 'Frames' : 'Frame'}
            </label>
          </div>
          {plan.name === `Premium Collector` && (
            <div className="each-options flex gap-3">
              <img alt="ok" src={ok}></img>
              <label>Discoverable profile</label>
            </div>
          )}
        </div>
        {((plan.planPrice !== 0 && plan.isGetMonet) || plan.isSubscribed) && (
          <button
            onClick={() => manageSubscriptionClick()}
            className={`Subscribed-btn ${plan.isSubscribed && "active"}`}
          >
            {plan.isSubscribed
              ? `Subscribed`
              : currentTab !== "Monet Members"
              ? `Select`
              : "Get Monet"}
          </button>
        )}
      </div>
    </Fragment>
  );
};
export default SubscriptionCard;
