/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
import Panel from "App/Components/Ui/Panel";
import { useStore } from "App/hooks-store/store";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./UpdateCollection.scss";
import dummyImage from "Assets/Images/dummypic.svg";
import Validators from "App/Components/Ui/Forms/Validators";
import Input from "App/Components/Ui/Input";
import TextArea from "App/Components/Ui/TextArea";
import RoundedButton from "App/Components/Ui/RoundedButton";
import Select from 'App/Components/Ui/SelectBox'
import {
  getInitial,
  validateFormGroup,
  markAsTouched,
} from "App/Helper/formValidation";
import userAvatarHover from "Assets/Icons/avatar_hover.svg";
import infoIcon from 'Assets/Icons/infoIconSquare.svg'
import arrowIcon from "Assets/Icons/arrow-white.svg";
import useHttp from "App/hooks/use-http";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { categorySort, copyClipHandler, objectToFormdata, objectToNull, splitWord } from "App/Helper/utilities";
import TermsTable from "./Components/TermsTable";
import {
  handleDragEnter,
  handleDragLeave,
  handleDragOver,
  handleDrop,
} from "App/Helper/files";
import AssetsOnDemand from "./Components/AssetsOnDemand/AssetsOnDemand";
import TermsMob from "./Components/TermsMob";
import mdDefault from 'Assets/Images/md-default.svg'
import useScreenSize from "App/hooks/use-screen-size";
import CheckBox from "App/Components/Ui/CheckBox";
import { useSearchParams } from "react-router-dom";
import WarningBox from 'App/Components/Ui/WarningBox'
import { useDebounce } from "App/hooks/use-debounce";
import RemoveWarning from "App/Components/RemoveWarning";
import copyIcon from "Assets/Icons/copy-icon-grey.svg";
import adaIcon from "Assets/Icons/ada_svg_grey.svg";
import useShapes from "App/hooks/use-shapes";
import shapesEmpty from "Assets/Images/shapesEmpty.svg";
import SubDetails from "./Components/SubDetails";

const CollectionUpdate = (props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formGroup = {
    name: {
      value: undefined,
      isRequired: true,
    },
    blockchainTypeId: {
      value: 1,
      isRequired: true,
    },
    collectionId: {
      value: undefined,
      isRequired: true,
    },
    description: {
      value: undefined,
      isRequired: true,
    },
    categoryId: {
      value: undefined,
      isRequired: false,
    },
    firstSubcategoryId: {
      value: undefined,
      isRequired: false,
    },
    collage: {
      value: undefined,
      isRequired: false,
    },
    // secondSubcategoryId: {
    //   value: undefined,
    //   isRequired: false,
    // },
    collectionStorageId: {
      value: undefined,
      isRequired: true,
    },
    collectionEditionsId: {
      value: undefined,
      isRequired: true,
    },
    collectionFormatIds: {
      value: undefined,
      isRequired: true,
    },
    collectionTypeIds: {
      value: undefined,
      isRequired: true,
    },
    collectionTagIds: {
      value: [''],
      isRequired: false,
    },
    communityId: {
      value: undefined,
      isRequired: true,
    },
    marketplace: {
      value: undefined,
      isRequired: false,
    },
    instagram: {
      value: undefined,
      isRequired: false,
    },
    twitter: {
      value: undefined,
      isRequired: false,
    },
    discord: {
      value: undefined,
      isRequired: false,
    },
    email: {
      value: undefined,
      isRequired: false,
    },
    youtube: {
      value: undefined,
      isRequired: false,
    },
    website: {
      value: undefined,
      isRequired: false,
    },
    termsId: {
      value: undefined,
      isRequired: true,
    },
    file: {
      value: undefined,
      isRequired: true,
    },
  };
  const scrollTopRef = useRef(null);
  const {state: pageState, search: collectionUnique} = useLocation();
  const { triggerAPI } = useHttp();
  const [{ currentUserId, activeMenuIcons }, dispatch] = useStore();
  const [collectionId, setCollectionId] = useState('');
  const [formValues, setFormvalues] = useState(getInitial(formGroup));
  const [formValuesUp, setFormvaluesUp] = useState(getInitial(formGroup));
  const [formVaildState, setFormVaildState] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const [displayPicture, setDisplayPicture] = useState(null);
  const [displayCollagePicture, setDisplayCollagePicture] = useState(null);
  const [category, setCategory] = useState([]);
  const [termsList, setTermsList] = useState([]);
  const [firstSubcategory, setFirstSubcategory] = useState([]);
  const [secondSubcategory, setSecondSubcategory] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const [collectionStorageList, setCollectionStorageList] = useState([]);
  const [collectionEditionsList, setCollectionEditionsList] = useState([]);
  const [collectionFormatList, setCollectionFormatList] = useState([]);
  const [collectionTypeList, setCollectionTypeList] = useState([]);
  const [collectionTagList, setCollectionTagList] = useState([]);
  const [blockchainTypeList, setBlockchainTypeList] = useState([]);
  const [tagType, setTagType] = useState(false);
  const [isUpdate, setIsUpdate] = useState({});
  const [removeClick,setRemoveClick] = useState(false)
  const [activeTabs, setActiveTabs] = useState(1);
  const [tab, setTab] = useState('details');
  const [dataFetched, setDataFetched] = useState({
    category: false,
    data: false,
  });
  const [assetData, setAssetData] = useState([]);
  const navigate = useNavigate();
  const screensize = useScreenSize()?.width;
  const isLandscape = window.matchMedia('(orientation: landscape)').matches;
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const profileWrapRef = useRef(null);
  const topMenuOption = [{ id: 1, element: "Community", link: "/community" }];
  const wordLimit = 25;
  const [isCollectionFoundSearch, setIsCollectionFoundSearch] = useState(false);
  const [isCollectionValid, setIsCollectionValid] = useState(false);
  const [policyDetails, setPolicyDetails] = useState();
  const isRequested = props?.isType === 'requested';
  const [createTagWarning, setCreateTagWarning] = useState(false);
  const [reloadLoader, setReloadLoader] = useState(false);
  const [shapes, setShapes] = useState(shapesEmpty);
  const newtagref = useRef();
  const uploadThumbnailRef=useRef(null);
  const { getShapes } = useShapes();
  const tabsHandler = (e) => {
    const selectTab = e.currentTarget?.dataset?.id;
    setTab(selectTab);
  }
  const menuTabs = useMemo(()=>{
    let tabList= [{ id: "details", element: 'Details'},{ id: "pieces", element: 'Pieces' }]
    return tabList
  },[]);

  const getCategoryResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      let updatedList=categorySort(res?.data?.list)
      const cateList = [...updatedList]?.filter(el=>el?.name?.toLowerCase()!=="other")?.map((item) => {
        item.value = item.id;
        return item;
      })
      setCategory(cateList);
      setDataFetched((prev) => {
        return { ...prev, category: true };
      });
    },
    [dispatch]
  );
  useEffect(() => {
    if (pageState?.communityId||props?.communityid) {
      inputOnChange({ communityId: pageState?.communityId||Number(props?.communityid) });
    }
  }, [pageState,props?.communityid]);
  useEffect(() => {
    if (collectionUnique) {
     setCollectionId(collectionUnique?.split(`?id=`)[1]);
    }   
    else{
      setCollectionId("");
    }
  }, [collectionUnique]);
  const getCategoryList = useCallback(() => {
    triggerAPI(
      {
        url: `category/get`,
        data: {
          page: 1,
          items: 1000,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getCategoryResponse
    );
  }, [dispatch, getCategoryResponse, triggerAPI]);

  const getTermsResponse = useCallback(
    (res) => {
      setTermsList(res?.data?.list ? res?.data?.list.reverse() : []);
    },
    [dispatch]
  );

  const getTermsList = useCallback(() => {
   // dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection-terms/get`,
        data: {
          page: 1,
          items: 100,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getTermsResponse
    );
  }, [dispatch, getTermsResponse, triggerAPI]);

  const getCollectionStorageResponse = useCallback(
    (res) => {
      //dispatch("hideSpinner");
      setCollectionStorageList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );

  const getCollectionStorage = useCallback(() => {
   // dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_storage/get`,
        method: "get",
      },
      getCollectionStorageResponse
    );
  }, [dispatch, getCollectionStorageResponse, triggerAPI]);

  const getCollectionTypeResponse = useCallback(
    (res) => {
     // dispatch("hideSpinner");
      setCollectionTypeList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );

  const getCollectionType = useCallback(() => {
   // dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_type/get`,
        method: "get",
      },
      getCollectionTypeResponse
    );
  }, [dispatch, getCollectionTypeResponse, triggerAPI]);

  const getCollectionTagResponse = useCallback(
    (res) => {
      setCollectionTagList(res?.data?.sort((a,b) => a.name > b.name ? 1:-1));
    },
    [dispatch]
  );

  const getCollectionTag = useCallback((collectionId,collectionTagIds,searchText) => {    
    triggerAPI(
      {
        url: `community/collection_tag/search`,
        method: "post",
        data: {
          collectionTagIds: collectionTagIds,
          collectionId: collectionId,
          searchText: searchText
        }
      },
      getCollectionTagResponse
    );
  }, [dispatch, getCollectionTagResponse, triggerAPI]);

  const getCollectionFormatResponse = useCallback(
    (res) => {
     // dispatch("hideSpinner");
      setCollectionFormatList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );

  const getCollectionFormat = useCallback(() => {
   // dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_format/get`,
        method: "get",
      },
      getCollectionFormatResponse
    );
  }, [dispatch, getCollectionFormatResponse, triggerAPI]);

  const getCollectionEditionsResponse = useCallback(
    (res) => {
     // dispatch("hideSpinner");
      setCollectionEditionsList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );

  const getCollectionEditions = useCallback(() => {
   // dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_editions/get`,
        method: "get",
      },
      getCollectionEditionsResponse
    );
  }, [dispatch, getCollectionEditionsResponse, triggerAPI]);

  const getBlockChainTypeResponse = useCallback(
    (res) => {
     // dispatch("hideSpinner");
      const list = res?.data?.rows?.map((item) => {
        return { name: item?.name, id: item?.id, value: item?.id }
      });
      setBlockchainTypeList(list);
    },
    [dispatch]
  );

  const getBlockChainType = useCallback(() => {
   // dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/blockchain-types/get`,
        method: "get",
      },
      getBlockChainTypeResponse
    );
  }, [dispatch, getBlockChainTypeResponse, triggerAPI]);

  useEffect(() => {
    //MJH there's a race condition between getting the category data, and the categoryid being populated
    //occasionally neither are both populated at the same time, leaving the categories text blank
    //this is a terrible fix, but I couldn't fix the race condition any other way after 3 hours trying...
    setTimeout(function () {
      getCategoryList();
    }, 1000)

    getTermsList();
    getCollectionStorage();
    getCollectionEditions();
    getCollectionFormat();
    getCollectionType();
    getBlockChainType();
  }, []);

  useEffect(() => {
    if (currentUserId) getCommunityList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);
  const getCommunityListResult = useCallback(
    (res) => {
      const { data } = res;
      setCommunityList(data?.list);
      if (!formValues.communityId) 
      {
        setFormvalues((prev) => {
          return {
            ...prev,
            //new collections, default to the first community
            communityId: prev.communityId ?? (formValues?.collectionId ? null : ((data?.list?.length > 0) ? data?.list[0].id : null))
          };
        });        
      }
    },
    [dispatch]
  );
  const getCommunityList = useCallback(() => {
    triggerAPI(
      {
        url: `community/get`,
        method: "post",
        data: {
          page: 1,
          items: 1000,
          filter: { userId: currentUserId },
          search: "",
        },
      },
      getCommunityListResult
    );
  }, [dispatch, getCommunityListResult, triggerAPI, currentUserId]);

  const navToBack = () => {
    navigate("/community");
  };
  useEffect(() => {
    // if(screensize > 550) {
    //   dispatch('setActiveMenuOptions', 0);
    //   dispatch('setMenuOptions', topMenuOption);
    // }
    // dispatch('setTopMenuBarOptions', {showMenuIcons: false, showTopMenus: true})
    // dispatch("setActiveIcons", { back: false });
    // dispatch('enableTopIcons', {back: false})
    // return () => {
    //   dispatch("resetAll");
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPortrait,screensize]);
  useEffect(() => {
    if (activeMenuIcons?.back) {
      dispatch("setActiveIcons", { back: false });
      navigate(`/community`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.back]);
  useEffect(() => {
    if (collectionId) {
      getDetailedCollection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId]);
  useEffect(() => {
    if (isCollectionFoundSearch) {
      getDetailedCollectionResult({ data: policyDetails });
    }
  }, [policyDetails]);

  const subCategoryHandler = useCallback(
    async (cId, keyName = "id") => {
      const subCategory =
        (await category?.find((val) => val[keyName] === cId)?.subcategories) ||
        [];
      const cateList = [...subCategory]?.map((item) => {
        item.value = item.id;
        return item;
      })
      setSecondSubcategory([]);
      setFirstSubcategory(cateList);
      return cateList;
    },
    [category]
  );
  const secondSubCategoryHandler = useCallback(
    async (cId, keyName = "id", subCat) => {
      let subArr = subCat ?? firstSubcategory;
      const secondSub =
        (await subArr?.find((val) => val[keyName] === cId)?.subcategories) ||
        [];
      const cateList = [...secondSub]?.map((item) => {
        item.value = item.id;
        return item;
      })
      setSecondSubcategory(cateList);
      return cateList;
    },
    [firstSubcategory]
  );

  const getDetailedCollectionResult = useCallback(
    (res) => {
      const { data } = res;
      let details = { ...data };
      if (data) {
        setIsUpdate(details);
        let ratios = details?.shapes?.length ? details?.shapes.map(shape => Number(shape.aspectRatio)) : details?.aspectRatios?.split(','); 
        setShapes(getShapes(ratios));
        if (formValues?.collectionId && !details?.collectionId) {
          details = { ...details, collectionId: formValues?.collectionId };
        }
        let newDetails = {
          name: details?.name,
          description: details?.description,
          blockchainTypeId: details?.blockchainTypeId,
          collectionId: details?.collectionId,
          website: details?.website,
          twitter: details?.twitter,
          instagram: details?.instagram,
          discord: details?.discord,
          youtube: details?.youtube,
          email: details?.email,
          communityId: details?.communityId??formValues?.communityId,
          categoryId: details?.categoryId,
          marketplace: details?.marketplace,
          firstSubcategoryId: details?.firstSubcategoryId,
          // secondSubcategoryId: details?.secondSubcategoryId,
          collectionStorageId: details?.collectionStorageId,
          collectionEditionsId: details?.collectionEditionsId,
          collectionFormatIds: details?.formats?.map(f=>f.id),
          collectionTypeIds: details?.types?.map(t=>t.id),
          collectionTagIds: details?.tags?.map(t=>t.id),
          termsId: details?.termsId,
          file: details?.filePath256,
          collage:details?.collage
        };
        setAssetData(details?.assets);
        // updateHandler(newDetails);
        if (!details?.id) {
          newDetails = {
            ...newDetails,
          };
        }
        setFormvalues(newDetails);
        getCollectionTag(collectionId, newDetails.collectionTagIds);
        setDataFetched((prev) => {
          return { ...prev, data: true };
        });
        // if (!isCollectionFoundSearch) {
          setDisplayPicture(details?.filePath256);
          setDisplayCollagePicture(details?.collage)
        // }
      }
      setIsCollectionValid(true);
      dispatch("hideSpinner");
    },
    [dispatch]
  );
  const getDetailedCollection = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      isRequested ?
      {          
        url:`community/collection/temp/get-one`,
        method: "post",
        data: { id: collectionId }
      } :
      {
        url: `user/collection/verify/details/${collectionId}`,
        method: "get",
        data: { id: collectionId },
      },
      getDetailedCollectionResult, (err) => {
        dispatch("hideSpinner");
        if (err?.response?.status === 403) {
          navigate("/community");
        }
      }
    );
  }, [dispatch, getDetailedCollectionResult, triggerAPI, collectionId]);

  const getDetailedCollectionByCollectionId = useCallback(
    (collectionId, blockChainId) => {
      dispatch("showSpinner");
      const data = new URLSearchParams({
        search: collectionId,
        blockchainTypeId: blockChainId,
        networkType: "MAINNET"
      })
      triggerAPI(
        // isRequested ? {
        //   url: `community/collection/find?${data}`,
        //   method: "get"
        // } :
        // {
        //   url:`community/collection/temp/get-one`,
        //   method: "post",
        //   data: { id: collectionId }
        // },
        {
          url: `community/collection/find?${data}`,          
          method: "get"
        },
        (res) => {
          const { data } = res;
          dispatch("hideSpinner");
          if (data?.policyValid) {
            if (data?.policyDetails?.id) {
              setIsCollectionFoundSearch(true);
              setIsCollectionValid(true)
              setPolicyDetails(data?.policyDetails);
            }
          } else {
            setIsCollectionFoundSearch(false);
            setIsCollectionValid(false)
            dispatch('showToast', { toast: { toastMode: 'error', message: 'Policy ID not valid' } })
          }
        }
      );
    },
    [
      dispatch,
      getDetailedCollectionResult,
      triggerAPI,
      formValues?.collectionId,
    ]
  );

  useEffect(() => {
    if (
      !collectionId &&
      formValues?.collectionId &&
      formValues?.collectionId?.toLowerCase() !==
      isUpdate?.collectionId?.toLowerCase()
    ) {
      const nameSearch = setTimeout(() => {
        getDetailedCollectionByCollectionId(formValues?.collectionId, formValues?.blockchainTypeId);
      }, 1000);
      return () => {
        clearTimeout(nameSearch);
      };
    }
  }, [formValues?.collectionId, formValues?.blockchainTypeId]);

  useEffect(() => {
    if (dataFetched?.category && dataFetched?.data) updateHandler();
  }, [dataFetched]);

  const updateHandler = useCallback(
    async (val) => {
      let data = val ?? formValues;
      const subCategory = await subCategoryHandler(data?.categoryId);
      await secondSubCategoryHandler(
        data?.firstSubcategoryId,
        undefined,
        subCategory
      );
      setFormvalues((prev) => {
        return {
          ...prev,
          //new collections, default to the first community
          communityId: prev.communityId ?? (formValues?.collectionId ? null : ((communityList?.length > 0) ? communityList[0].id : null))
        };
      });
    },
    [
      category,setFormvaluesUp,
      formValues?.categoryId,
      formValues?.firstSubcategoryId,
      // formValues?.secondSubcategoryId,
    ]
  );

  useEffect(() => {
    if (
      dataFetched?.category &&
      dataFetched?.data &&
      category &&
      formValues?.categoryId &&
      (formValues?.categoryId !== formValuesUp?.categoryId ||
        // formValues?.secondSubcategoryId !== formValuesUp?.secondSubcategoryId ||
        formValues?.firstSubcategoryId !== formValuesUp?.firstSubcategoryId)
    ) {
      setFormvaluesUp(formValues);
      // upd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataFetched,
    category,
    formValues?.categoryId,
    formValues?.firstSubcategoryId,
    // formValues?.secondSubcategoryId,
  ]);

  useEffect(() => {
    if (touched) {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  useEffect(() => {
    const { valid } = validateFormGroup({
      errors: formVaildState,
      formGroup,
      values: formValues,
    });
    setIsValid(valid);
  }, [formValues, formVaildState, formGroup]);

  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);

  const newTagVal=useDebounce(formValues?.newtag,600);
  useEffect(()=>{
   if(tagType) getCollectionTag(formValues.collectionId, formValues.collectionTagIds, newTagVal);
  },[newTagVal])

  const inputOnChange = (e) => {  
    if (Object.keys(e)[0] === "newtag") {      
      e.newtag = e.newtag.replaceAll(' ','-').replace(/[^a-zA-Z0-9-]/g , '').toLowerCase();
      newtagref.current.value = e.newtag;
      setTagType(()=>true)
    }

    setFormvalues((prev) => {
      const updatedState = {
        ...prev,
        ...e,
      };
  
      return updatedState;
    });
  
    if (Object.keys(e)[0] === "categoryId") {
      subCategoryHandler(e?.categoryId);
    } else if (Object.keys(e)[0] === "firstSubcategoryId") {
      secondSubCategoryHandler(e?.firstSubcategoryId);
    }
  };



  const actionHandler = (list) => {
    // return {}
    const postUrl = isCollectionFoundSearch ? 'community/collection/temp/create' :
      collectionId || isUpdate?.collectionId === list?.collectionId
        ? isRequested ? `community/collection/temp/update/${collectionId || isUpdate?.id}` :
         `community/collection/update/${collectionId || isUpdate?.id}`
        : 'community/collection/temp/create';
    const method = isCollectionFoundSearch ? 'post' :
      collectionId || isUpdate?.collectionId === list?.collectionId
        ? 'put'
        : 'post';
    delete list.category;
    delete list.firstSubcategory;
    delete list.secondSubcategory;
    if (typeof list.file === "string") delete list.file;
    let updatedList = objectToNull(list);
    const formData = objectToFormdata(updatedList);
    triggerAPI(
      {
        url: postUrl,
        method: method,
        data: formData,
      },
      (res) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: { toastMode: "success", message: res?.message },
        });
        if(collectionId || isUpdate?.collectionId === list?.collectionId){
          props?.communityRefetch&&props?.communityRefetch()
        }
        props.onFetch();
        //setUpdateId? setUpdateId(null):
        //setShowListing&& setShowListing(true);
      },
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message: err?.response?.data?.error?.message,
          },
        });
        getCommunityList();
      }
    );
  };
  const saveHandler = useCallback(() => {
    if (isValid) {
      dispatch("showSpinner");
      const list = { ...formValues, collectionId: formValues?.collectionId?.trim() };
      delete list.newtag;
      actionHandler(list);
      // if (
      //   (list?.category && !list?.categoryId) ||
      //   (list?.firstSubcategory && !list?.firstSubcategoryId) ||
      //   (list?.secondSubcategory && !list?.secondSubcategoryId)
      // ) {
      //   if (list?.category && !list?.categoryId) {
      //     triggerAPI(
      //       {
      //         url: `category/create`,
      //         data: { name: list?.category },
      //         method: "post",
      //       },
      //       (res) => {
      //         list.categoryId = res?.data?.id;
      //         inputOnChange({ categoryId: res?.data?.id });
      //         createSubCategoryHandler(list);
      //       }
      //     );
      //   } else {
      //     createSubCategoryHandler(list);
      //   }
      // } else {
      //   actionHandler(list);
      // }
    } else {
      setTouched(true);
    }
  }, [
    dispatch,
    formValues,
    isValid,
    triggerAPI,
    collectionId,
  ]);

  const imageHandle = (imgFile) => {
    if (imgFile) {
      const file = imgFile?.displayPicture;
      const fileSize = file?.size / 1024 / 1024;
      const extension = file?.type?.split("/").shift();
      const fileExtension = extension.toLowerCase();
      if (
        fileExtension === "image"
      ) {
        if (fileSize <= 2) {
          const imageUrl = URL.createObjectURL(file);
          if (imgFile?.displayPicture) {
            inputOnChange({ file: file });
            setDisplayPicture(imageUrl);
          }
        } else {
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message: "The maximum size of an image should be 2 MB",
            },
          });
        }
      } else {
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message: "Please upload an image",
          },
        });
      }
    }
  };
  const handleCollectionStorage = (e) => {
    if(isCollectionValid){
    const id = e?.currentTarget?.dataset?.id;
    let ids;
    if (formValues?.collectionStorageId === Number(id)) {
      ids = null;
    } else {
      ids = Number(id);
    }
    inputOnChange({ collectionStorageId: ids });
  }
  };
  const handleCollectionEdition = (e) => {
    if(isCollectionValid){
    const id = e?.currentTarget?.dataset?.id;
    let ids;
    if (formValues?.collectionEditionsId === Number(id)) {
      ids = null;
    } else {
      ids = Number(id);
    }
    inputOnChange({ collectionEditionsId: ids });
  }
  };
  const handleCollectionFormat = (e) => {
    if(isCollectionValid){
    const id = e?.currentTarget?.dataset?.id;

    let ids =Array.isArray(formValues?.collectionFormatIds)?[...formValues?.collectionFormatIds]:[];
    if (ids.includes(Number(id))) {
      ids.splice(formValues?.collectionFormatIds.indexOf(Number(id)),1);
    } else {
      ids.push(Number(id));
    }
    inputOnChange({ collectionFormatIds: ids });
  }
  };
  const handleCollectionType = (e) => {
    if(isCollectionValid){
    const id = e?.currentTarget?.dataset?.id;
    let ids =Array.isArray(formValues?.collectionTypeIds)?[...formValues?.collectionTypeIds]:[];
    if (ids.includes(Number(id))) {
      ids.splice(ids.indexOf(Number(id)),1);
    } else {
      ids.push(Number(id));
    }
    inputOnChange({ collectionTypeIds: ids });
  }
  };
  const handleCollectionTag = (e) => {
    if(isCollectionValid){
    const id = e?.currentTarget?.dataset?.id;
    let ids =Array.isArray(formValues?.collectionTagIds)?[...formValues?.collectionTagIds]:[];
    if (ids.includes(Number(id))) {
      ids.splice(ids.indexOf(Number(id)),1);
    } else {
      ids.push(Number(id));
    }
    inputOnChange({ collectionTagIds: ids });
  }
  };

  const saveNewTagResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      let ids =Array.isArray(formValues?.collectionTagIds)?[...formValues?.collectionTagIds]:[];
      if (!ids.includes(Number(res.data.id))) {        
        ids.push(Number(res.data.id));
      }
      inputOnChange({ collectionTagIds: ids });
      inputOnChange({ newtag: "" });
      getCollectionTag(formValues.collectionId, ids, formValues.newtag);      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const saveNewTag = useCallback(
    (data) => {
      dispatch("showSpinner");
      triggerAPI(
        {
          url: "community/collection_tag/create",
          data,
          method: "post",
        },
        saveNewTagResult,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: { toastMode: "error", message: err?.response?.data?.error?.message??err.message },
          });
        }
      );
    },
    [dispatch, saveNewTagResult, triggerAPI]
  );

  const handleShowCollectionTag = () => {
    if(formValues.newtag) setCreateTagWarning(true);
  }
  const handleNewCollectionTag = (e) => {
    let data = { name: formValues.newtag }
    saveNewTag(data);
    setCreateTagWarning(false);
  };
  const handleCancelCollectionTag = () => {
    setCreateTagWarning(false);
  }
  const handleTerms = (e) => {
    if(isCollectionValid){
    const id = e?.currentTarget?.dataset?.id;
    let value = parseInt(id);
    if (formValues?.termsId === value) {
      value = null;
    }
    inputOnChange({ termsId: value });
  }
  };
  const handleTabs = (e) => {
    let id = parseInt(e?.currentTarget?.dataset?.id);
    if (id === activeTabs) {
      id = 1;
    }
    setActiveTabs(id);
  };
  // const handleCommunity = (e) => {
  //   const id = e?.currentTarget?.dataset?.id;
  //   let ids;
  //   if (formValues?.communityId === Number(id)) {
  //     ids = null;
  //   } else {
  //     ids = Number(id);
  //   }
  //   inputOnChange({ communityId: ids });
  // };
  const handleCommunity = (selectedOption) => {
    const selectedId = selectedOption?.communityId;
    if (formValues?.communityId !== selectedId) {
      inputOnChange({ communityId: selectedId });
    }
  };
  const communitySocialsClicked = (e) => {
    if(isCollectionValid){
    let community = communityList.filter(c => c.id == formValues.communityId)[0]

    setFormvalues((prev) => {
      return {
        ...prev,
        ...{
          website: community?.website??"",
          twitter: community?.twitter??"",
          instagram: community?.instagram??"",
          discord: community?.discord??"",
          youtube: community?.youtube??""
        },
      };
    });
  }
  }
  const removeResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      props.onFetch();
      dispatch("showToast", {
        toast: { toastMode: "success", message: res?.message },
      });
      navigate("/community");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const removeCollection = () =>{
    removeClickHandler()
    dispatch("showSpinner");
    let url = isRequested?"community/collection/temp/remove/":"community/collection/remove/"
    triggerAPI(
      {
        url: `${url}${+collectionId}`,
        method: "delete",
      },
      removeResult,
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: { toastMode: "error", message: err?.response?.data?.error?.message??err.message },
        });
      }
    );
  }

  const removeClickHandler=()=>{
    setRemoveClick((pre)=>!pre)
  }
  const onImageUploadHandler= (imgRef)=>{
    if(isCollectionValid){
      imgRef.current.click();
    }
  }
  const reloadResult = useCallback(
    (res) => {
      setReloadLoader(false);
      setDisplayCollagePicture(res?.data?.collage)
      inputOnChange({ collage: res?.data?.collage });
      if(!res?.data?.collage){
        dispatch("showToast", {
          toast: { toastMode: "error", message: "Not able to generate collage" },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const onReloadHandler = () =>{
    if(isCollectionValid){
      setReloadLoader(true)
      triggerAPI(
        {
          url: `user/nft-collection/images`,
          method: "post",
          data:{collectionId:formValues?.collectionId}
        },
        reloadResult,
        (err) => {
          setReloadLoader(false)
          dispatch("showToast", {
            toast: { toastMode: "error", message: err?.response?.data?.error?.message??err.message },
          });
        }
      );
    }
  }
  const copyToClipBoardHandler=(value)=>{
    copyClipHandler(value);
    dispatch("showToast", {
      toast: { toastMode: "success", message: "Copied to clipboard" },
    });
  }
  return (
    <>
    <div ref={scrollTopRef}/>
    <div className="community-collection-main-form community-content-section">
      <Panel className={`sm:p-4 p-0 gap-3 sm:pb-[2.30rem] py-[1.25rem] community-pro`}>
        <div className="community-form create-edit-collection text-left flex flex-col gap-4">
          {!collectionId &&<div className="flex justify-between">
            <div className="flex flex-col gap-[0.75rem]">
              <div>
                <div className="section-title sm:mt-0 mt-[0.875rem] w-full">
                  Collection ID
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:gap-4 gap-[0.625rem]  pt-0 sm:mb-0 ">
            {/* <div className="flex justify-center sm:gap-4 gap-[0.625rem] sm:mt-0 mt-[0.5rem] sm:mb-0 ">
              {!!collectionId&&
              <>
              <RoundedButton
                  className={`button save ${isCollectionValid&&"edit-active"}`}
                  OnClick={()=>setIsCollectionValid(true)}
                  Type="1"
                  Name={"Edit"}
                ></RoundedButton>
              <RoundedButton
                  className={"button  remove "}
                  OnClick={removeClickHandler}
                  Type="1"
                  Name={"Remove"}
                />
                </>
                }
                <RoundedButton
                  className={"button  save"}
                  OnClick={navToBack}
                  Type="1"
                  Name={"Cancel"}
                ></RoundedButton>
                <RoundedButton
                  className={`button ${isValid ? "active" : ""} submit`}
                  OnClick={saveHandler}
                  Type="1"
                  Name={screensize >550 ? 'Save & Close' : 'Save'}
                ></RoundedButton>
              </div> */}
            </div>
          </div>}
          <div
            className={`${activeTabs === 1 ? "flex" : "hidden"} flex-col ${tab==="pieces"?"gap-2":"sm:gap-6 gap-4"}`}
          >
            {!collectionId &&<div className="flex flex-col gap-4">
              <div className="flex w-full sm:gap-4 gap-[0.5rem]">
                <div className="flex gap-4 w-full">
                  <div className={`flex w-full  flex-col sm:gap-2 gap-[0.375rem] ${!!collectionId && `disabled_className`}`}>
                    <div className="upload-banner flex flex-col gap-2">
                      <label>Blockchain*</label>
                    </div>
                    <div>
                      <Select
                        disabled={!!collectionId}
                        onChange={inputOnChange}
                        className={'formControl cursor-pointer'}
                        controlName="blockchainTypeId"
                        options={blockchainTypeList} currentId={formValues.blockchainTypeId}>
                      </Select>
                      <Validators type="Required" value={formValues.blockchainTypeId} message="Required field" controlName="blockchainTypeId" onErrors={collectErrors} />
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 w-full">
                  <div className={`flex w-full  flex-col sm:gap-2 gap-[0.375rem]`}>
                    <div className="upload-banner flex flex-col gap-2">
                      <label>{`ID (Policy)`}</label>
                    </div>
                    <div className={`${!!collectionId && `disabled_className`}`}>
                      <Input
                        type="text"
                        placeholder={"Collection ID"}
                        label="collectionId"
                        validateOnBlur
                        onChange={inputOnChange}
                        controlName="collectionId"
                        value={formValues.collectionId}
                        disabled={!!collectionId}
                      >
                        <Validators
                          type={formValues.collectionId ? "String" : "Required"}
                          value={formValues.collectionId}
                          message={
                            formValues.collectionId
                              ? "Must be string"
                              : "Required field"
                          }
                          controlName="collectionId"
                          onErrors={collectErrors}
                        />
                      </Input>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
           {(!!isCollectionValid||!!collectionId) && <>
          <div className={`tab-container border-[#30333A] border-b ${tab==="pieces"&&"mt-4"}`}>
          <div className="tab-list flex sm:gap-[1.42857rem] gap-[0.625rem]">
            {menuTabs?.map((menu) => {
              return (
                <div
                  onClick={tabsHandler}
                  data-id={menu?.id}
                  key={menu?.id}
                  className={`tab-data ${menu?.id === tab ? "active":"not-active"}`}
                >
                  {menu?.element}{" "}
                </div>
              );
            })}
          </div>
          </div>
          {tab==="details"?
          <>
          <div className="name-id flex items-center gap-[2rem]">
            <div className={`${!formValues.name&&"italic"} collection-name`}>{formValues.name||"No name"}</div>
            {!!formValues.collectionId&& <div
              className={`collection-ids flex`}
            >
              <div className="value flex gap-2 align-center">
              <img
                className={``}
                alt="Img"
                src={adaIcon}
              />
              {splitWord(formValues.collectionId,8)}
              <img
                onClick={()=>copyToClipBoardHandler(formValues.collectionId)}
                className="cursor-pointer"
                alt="Img"
                src={copyIcon}
              />
            </div>
            </div>}
            </div>
            <SubDetails data={isUpdate} shapes={shapes}/>
                  
            <div className="flex flex-col gap-4 collection-image-wrapper">
              <div className="flex gap-2 w-full">
              <div className="flex flex-col gap-2 w-[50%]">
                    <div className="upload-banner flex flex-col gap-2">
                        <label>Thumbnail</label>
                    </div>
                    <div className="flex flex-col gap-2">
                      <div
                        className="upload-banner-image profile-img displayPictureCollection"
                        ref={profileWrapRef}
                        onDrop={(e) =>
                          isCollectionValid? handleDrop(
                            e,
                            profileWrapRef,
                            imageHandle,
                            "displayPicture",
                            "pro-pic"
                          ):()=>{}
                        }
                        id="pro-pic"
                        onDragOver={handleDragOver}
                        onDragEnter={(e) =>
                          isCollectionValid? handleDragEnter(e, profileWrapRef, "pro-pic"):()=>{}
                        }
                        onDragLeave={(e) =>
                          isCollectionValid? handleDragLeave(e, profileWrapRef, "pro-pic"):()=>{}
                        }
                      >
                        <div
                          className={
                            "relative flex h-full flex-col justify-center thumbnail"
                          }
                        >
                          <label>
                            <button htmlFor="upload-button-displayPicture" onClick={()=>onImageUploadHandler(uploadThumbnailRef)} className={`select-image-button upload-icon ${isCollectionValid?"cursor-pointer":"cursor-not-allowed"}`}>Select</button>
                            {!displayPicture && (
                              <div className="dummy-wrap">
                                <img
                                  className={`image-uploaded`}
                                  alt="NoImg"
                                  src={(isPortrait&&screensize<=550) ?  mdDefault:dummyImage}
                                />
                                {/* <p className="sm:inline-block hidden">
                                Recommended Size <br />
                                250px x 250px
                                </p> */}
                                {/* <p className="sm:hidden inline-block">
                                  Maximum file <br />
                                  size 2MB <br />
                                  Minimum size <br />
                                  158px x 158px <br />
                                  1:1
                                </p> */}
                              </div>
                            )}
                            {displayPicture && (
                              <img
                                alt="No Img"
                                className={`image-uploaded actual-img`}
                                src={displayPicture}
                              ></img>
                            )}
                          </label>
                          <input
                            type="file"
                            id="upload-button-displayPicture"
                            name={"displayPicture"}
                            className={"hidden"}
                            disabled={!isCollectionValid}
                            accept="image/*"
                            ref={uploadThumbnailRef}
                            onChange={(e) =>
                              imageHandle({ displayPicture: e.target.files[0] })
                            }
                          />
                        </div>
                      </div>
                      <div className="justify-start flex">
                        <span className="recomended-size"> *Recommended size 380px x 240px</span>
                      </div>
                    </div>
                    <Validators
                      type={"Required"}
                      value={formValues.file}
                      message="Required field"
                      controlName="file"
                      onErrors={collectErrors}
                    />
                  </div>
                  <div className="flex flex-col gap-2  w-[50%] collage-image">
                    <div className="upload-banner flex gap-2">
                        <label>Collage</label>
                        <div className={`reload-icon ${isCollectionValid?"cursor-pointer":"cursor-not-allowed"} ${reloadLoader&&"reload-active"}`} onClick={onReloadHandler}></div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <div
                        className="upload-banner-image profile-img"
                      >
                        <div
                          className={
                            "relative flex h-full flex-col justify-center"
                          }
                        >
                          <label>
                            {!displayCollagePicture && (
                              <div className="dummy-wrap">
                                {/* <img
                                  className={`image-uploaded`}
                                  alt="NoImg"
                                  src={(isPortrait&&screensize<=550) ?  mdDefault:dummyImage}
                                /> */}
                                {/* <p className="sm:inline-block hidden">
                                Recommended Size <br />
                                250px x 250px
                                </p> */}
                                <p className="inline-block">
                                  Click the reload button to generate collage image
                                </p>
                              </div>
                            )}
                            {displayCollagePicture && (
                              <img
                                alt="No Img"
                                className={`image-uploaded actual-img`}
                                src={displayCollagePicture}
                              ></img>
                            )}
                          </label>
                  
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-4">
                <div className="flex sm:gap-20 gap-[0.375rem] flex-col sm:flex-row">
                  <div className="flex flex-col sm:gap-4 sm:mt-0 mt-[2px] gap-[0.5rem] w-full">
                    <div className="flex flex-col sm:gap-2 gap-[0.375rem]">
                      <div className="upload-banner flex flex-col gap-2">
                        <label>Name*</label>
                      </div>
                      <div>
                        <Input
                          maxlength={60}
                          type="text"
                          label="name"
                          validateOnBlur
                          disabled={!isCollectionValid}
                          placeholder="Name"
                          onChange={inputOnChange}
                          controlName="name"
                          value={formValues.name}
                        >
                          <Validators
                            type="Required"
                            value={formValues.name}
                            message="Required field"
                            controlName="name"
                            onErrors={collectErrors}
                          />
                        </Input>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="upload-banner flex flex-col gap-2">
                        <label>Description</label>
                      </div>
                      <div>
                        <TextArea
                          placeholder={"Describe the collection (5000 words max)"}
                          label="description"
                          validateOnBlur
                          type="text"
                          rows={7}
                          disabled={!isCollectionValid}
                          onChange={inputOnChange}
                          maxLength={5000}
                          showTextCount={true}
                          controlName="description"
                          value={formValues.description}
                        >
                          <Validators
                            type="Required"
                            value={formValues.description}
                            message="Required field"
                            controlName="description"
                            onErrors={collectErrors}
                          />
                        </TextArea>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:gap-4 gap-[0.5rem]">
              <div>
                <div className="section-title brd-btm w-full sm:mt-0 mt-[0.5rem]">Categorization</div>
              </div>
              <div className="flex w-full sm:flex-row flex-col sm:gap-4 gap-[0.5rem]">
                <div className="flex gap-4 w-full">
                  <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                    <div className="upload-banner flex flex-col gap-2">
                      <label>Category</label>
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <Select
                        onChange={inputOnChange}
                        className={'formControl'}
                        controlName="categoryId"
                        disabled={!isCollectionValid}
                        options={category} currentId={formValues?.categoryId}>
                      </Select>
                      <Validators
                        type={"Required"}
                        value={formValues?.categoryId}
                        message="Required field"
                        controlName="categoryId"
                        onErrors={collectErrors}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 w-full">
                  <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem] ">
                    <div className="upload-banner flex flex-col gap-2">
                      <label>Sub - Category</label>
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <Select
                        onChange={inputOnChange}
                        className={'formControl'}
                        disabled={!isCollectionValid}
                        controlName="firstSubcategoryId"
                        options={firstSubcategory} currentId={formValues?.firstSubcategoryId}>
                      </Select>
                      <Validators
                        type={"Required"}
                        value={formValues.firstSubcategoryId}
                        message="Required field"
                        controlName="firstSubcategoryId"
                        onErrors={collectErrors}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="flex gap-4 w-full">
                  <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                    <div className="upload-banner flex flex-col gap-2">
                      <label>Sub - Category 2</label>
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <Select
                        onChange={inputOnChange}
                        className={'formControl'}
                        controlName="secondSubcategoryId"
                        options={secondSubcategory} currentId={formValues?.secondSubcategoryId}>
                      </Select>
                      <Validators
                        type={"Required"}
                        value={formValues.secondSubcategoryId}
                        message="Required field"
                        controlName="secondSubcategoryId"
                        onErrors={collectErrors}
                      />
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                <div className="upload-banner flex flex-col gap-4">
                  <p className="text-[0.875rem] opacity-70">Subjects</p>
                  <label>Formats (Choose all that apply)</label>
                </div>
                <div>
                  <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox mb-1">
                    {collectionFormatList?.map((format) => (
                      <span
                        key={format?.id}
                        className={
                          `${formValues?.collectionFormatIds?.includes(format?.id) ? `active` : ""} ${!isCollectionValid && "disabled"}`
                        }
                        data-id={format?.id}
                        onClick={handleCollectionFormat}
                      >
                        {format?.name}
                      </span>
                    ))}
                  </div>
                  <Validators
                    type={"Required"}
                    value={formValues?.collectionFormatIds}
                    message="Required field"
                    controlName="collectionFormatIds"
                    onErrors={collectErrors}
                  />

                </div>
              </div>
              <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                <div className="upload-banner flex flex-col gap-2">
                  <label>Types (Choose all that apply)</label>
                </div>
                <div>
                  <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                    {collectionTypeList?.map((type) => (
                      <span
                        key={type?.id}
                        className={
                          `${formValues?.collectionTypeIds?.includes(type?.id) ? `active` : ""} ${!isCollectionValid && "disabled"}`
                        }
                        data-id={type?.id}
                        onClick={handleCollectionType}
                      >
                        {type?.name}
                      </span>
                    ))}
                  </div>
                </div>
                <Validators
                    type="Required"
                    value={formValues?.collectionTypeIds}
                    message="Required field"
                    controlName="collectionTypeIds"
                    onErrors={collectErrors}
                  />
              </div>
              <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                <div className="upload-banner flex flex-col gap-2">
                  <label>Storage</label>
                </div>
                <div>
                  <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox mb-1">
                    {collectionStorageList?.map((storage) => (
                      <span
                        key={storage?.id}
                        className={
                          `${formValues?.collectionStorageId === storage?.id ? `active` : ""} ${!isCollectionValid&& "disabled"}`
                        }
                        data-id={storage?.id}
                        onClick={handleCollectionStorage}
                      >
                        {storage?.name}
                      </span>
                    ))}
                  </div>
                  <Validators
                    type="Required"
                    value={formValues?.collectionStorageId}
                    message="Required field"
                    controlName="collectionStorageId"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
              <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                <div className="upload-banner flex flex-col gap-2">
                  <label>Editions</label>
                </div>
                <div>
                  <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox mb-1">
                    {collectionEditionsList?.map((edition) => (
                      <span
                        key={edition?.id}
                        className={
                          `${formValues?.collectionEditionsId === edition?.id ? `active` : ""} ${!isCollectionValid&&"disabled"}`
                        }
                        data-id={edition?.id}
                        onClick={handleCollectionEdition}
                      >
                        {edition?.name}
                      </span>
                    ))}
                  </div>
                  <Validators
                    type="Required"
                    value={formValues?.collectionEditionsId}
                    message="Required field"
                    controlName="collectionEditionsId"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                <div className="upload-banner flex flex-col gap-2">
                  <label>Tags (Choose all that apply)</label>
                </div>
                <div>
                  <div className={"flex mb-2"} style={{width:'20rem'}}>
                    <Input
                      maxlength={100}
                      type="text"
                      label="name"
                      disabled={!isCollectionValid}
                      placeholder="Search for Tag..."
                      onChange={inputOnChange}
                      width={10}
                      controlName="newtag"
                      value={formValues.newtag}
                      ref={newtagref}
                    >
                    </Input>
                    <RoundedButton
                      className={`button savetag ${!formValues?.newtag && "disable-tag-btn"}`}
                      OnClick={handleShowCollectionTag}
                      Type="9"   
                      disabled={!formValues.newtag}                   
                      Name={"Create New Tag"}
                    ></RoundedButton>
                    {createTagWarning && <WarningBox onApprove={handleNewCollectionTag} onCancel={handleCancelCollectionTag} approveBtn="Create Tag" cancelBtn="Cancel">
            Are you sure you want to create a new tag: {formValues.newtag}?
            </WarningBox>}
                  </div>
                  <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                    {collectionTagList?.map((tag) => (
                      <span
                        key={tag?.id}
                        className={
                          `${formValues?.collectionTagIds?.includes(tag?.id) ? `active` : ""} ${!isCollectionValid && "disabled"}`
                        }
                        data-id={tag?.id}
                        onClick={handleCollectionTag}
                      >
                        {tag?.name}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            <div className="flex flex-col gap-4">
              <div>
                <div className="section-title brd-btm w-full">Community</div>
              </div>
              <div className="flex w-full gap-4">
                <div className="flex gap-4 w-full">
                  <div className="flex w-full flex-col sm:gap-2 gap-[0.375rem]">
                    <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                      <div className="upload-banner sm:flex hidden flex-col gap-2">
                        <label>Community</label>
                      </div>
                      <div>
                          <Select
                          onChange={handleCommunity}
                          className={'formControl'}
                          controlName="communityId"
                          disabled={!isCollectionValid||props?.communityid}
                          options={communityList} currentId={formValues.communityId}
                        />
                        {/* <Select
                          onChange={handleCommunity}
                          className={'formControl'}
                          controlName="communityId"
                          options={communityList}
                          // currentId={communityList}
                          >
                        </Select> */}
                      </div>
                    </div>
                    {/* <div className="community-list-container flex gap-[1.42857rem] body flex-wrap">
                      {communityList?.map((community) => {
                        return (
                          <div
                            className={`item-container cursor-pointer ${(formValues.communityId === community?.id) ?
                                "active" : ''
                              }`}
                            key={community?.id}
                            data-id={community?.id}
                            onClick={handleCommunity}
                          >
                            <div className="image-container">
                              <img src={community?.thumbnailPicture} alt="" />
                            </div>
                            <div className='card-details px-[2px] sm:hidden flex gap-[0.375rem] items-center mr-auto'>
                              <div className='detail-profile'>
                                <img src={community?.displayPicture} alt='img' />
                              </div>
                              <div className="image-text">
                                {community?.name}
                              </div>
                            </div>
                            <div className="title-container sm:flex hidden max-w-[5.75rem]">
                              {community?.name ? (community?.name?.length > wordLimit ? `${community?.name.substring(0,(wordLimit - 1))}...` : community?.name) : '--'}
                            </div>
                          </div>
                        );
                      })}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flex flex-col gap-4">
              <div>
                <div className="section-title brd-btm w-full">Marketplace</div>
              </div>
              <div className="flex w-full gap-4">
                <div className="flex gap-4 w-full">
                  <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                    <div className="upload-banner flex flex-col gap-2">
                      <label>Marketplace</label>
                    </div>
                    <div>
                      <Input
                        label="marketplace"
                        validateOnBlur
                        placeholder="https://www.marketplace.com/link"
                        type="text"
                        onChange={inputOnChange}
                        controlName="marketplace"
                        value={formValues.marketplace}
                      >
                        <Validators
                          type="url"
                          value={formValues.marketplace}
                          message="URL not valid"
                          controlName="marketplace"
                          onErrors={collectErrors}
                        />
                      </Input>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="flex flex-col gap-4">
              <div>
                <div className="section-title brd-btm w-full">Social Information (Provide at least one)</div>
              </div>
              <div className="flex w-full flex-row sm:gap-2 gap-[0.375rem]">
                  <RoundedButton
                    className={`button ${!isCollectionValid&&"cursor-not-allowed"}`}
                    OnClick={communitySocialsClicked}
                    Type="9"
                    Name={"Populate from Community"}
                  ></RoundedButton>
              </div>
              <div className="flex flex-col w-full sm:gap-4 gap-[0.5rem]">
                <div className="flex w-full sm:grid grid-cols-2 flex-col sm:gap-4 gap-[0.5rem]">
                  <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                    <div className="upload-banner flex flex-col gap-2">
                      <label>Twitter</label>
                    </div>
                    <div>
                      <Input
                        label="Twitter"
                        validateOnBlur
                        type="text"
                        disabled={!isCollectionValid}
                        placeholder="Twitter URL"
                        onChange={inputOnChange}
                        controlName="twitter"
                        value={formValues.twitter}
                      >
                        <Validators
                          type="url"
                          value={formValues.twitter}
                          message="Invalid URL"
                          controlName="twitter"
                          onErrors={collectErrors}
                        />
                      </Input>
                    </div>
                  </div>
                  <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                    <div className="upload-banner flex flex-col gap-2">
                      <label>Instagram</label>
                    </div>
                    <div>
                      <Input
                        label="Instagram"
                        validateOnBlur
                        type="text"
                        disabled={!isCollectionValid}
                        placeholder="Instagram URL"
                        onChange={inputOnChange}
                        controlName="instagram"
                        value={formValues.instagram}
                      >
                        <Validators
                          type="url"
                          value={formValues.instagram}
                          message="Invalid URL"
                          controlName="instagram"
                          onErrors={collectErrors}
                        />
                      </Input>
                    </div>
                  </div>
                  <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                    <div className="upload-banner flex flex-col gap-2">
                      <label>Discord</label>
                    </div>
                    <div>
                      <Input
                        label="Discord"
                        validateOnBlur
                        type="text"
                        disabled={!isCollectionValid}
                        placeholder="Discord URL"
                        onChange={inputOnChange}
                        controlName="discord"
                        value={formValues.discord}
                      >
                        <Validators
                          type="url"
                          value={formValues.discord}
                          message="Invalid URL"
                          controlName="discord"
                          onErrors={collectErrors}
                        />
                      </Input>
                    </div>
                  </div>
                  <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                    <div className="upload-banner flex flex-col gap-2">
                      <label>Youtube</label>
                    </div>
                    <div>
                      <Input
                        label="Youtube"
                        validateOnBlur
                        type="text"
                        disabled={!isCollectionValid}
                        placeholder="Youtube URL"
                        onChange={inputOnChange}
                        controlName="youtube"
                        value={formValues.youtube}
                      >
                        <Validators
                          type="url"
                          value={formValues.youtube}
                          message="Invalid URL"
                          controlName="youtube"
                          onErrors={collectErrors}
                        />
                      </Input>
                    </div>
                  </div>
                  {/* <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                    <div className="upload-banner flex flex-col gap-2">
                      <label>Email</label>
                    </div>
                    <div>
                      <Input
                        label="Email"
                        validateOnBlur
                        type="text"
                        placeholder="email@domain.com"
                        onChange={inputOnChange}
                        controlName="email"
                        value={formValues.email}
                      >
                        <Validators
                          type="Email"
                          value={formValues.email}
                          message="Email not valid"
                          controlName="email"
                          onErrors={collectErrors}
                        />
                      </Input>
                    </div>
                  </div> */}
                   <div className="flex w-full flex-col sm:gap-2 gap-[0.375rem]">
                    <div className="upload-banner flex flex-col gap-2">
                      <label>Website</label>
                    </div>
                    <div>
                      <Input
                        label="website"
                        validateOnBlur
                        type="text"
                        disabled={!isCollectionValid}
                        placeholder="https://www.website.com"
                        onChange={inputOnChange}
                        controlName="website"
                        value={formValues.website}
                      >
                        <Validators
                          type="url"
                          value={formValues.website}
                          message="URL not valid"
                          controlName="website"
                          onErrors={collectErrors}
                        />
                      </Input>
                    </div>
                  </div>
                </div>
                <div className="flex sm:gap-4 gap-[0.5rem] sm:flex-row flex-col">

                </div>
              </div>
            </div>
            <div className="flex-col gap-4 hidden sm:flex">
              <div>
              <div className="section-title brd-btm w-full flex relative gap-2">
                  Terms & Conditions
                  <div className="group flex items-end">
                    <img src={infoIcon} alt="info" className="w-[1.25rem] cursor-pointer"/>
                    <div className="collection-terms-container border-container hidden group-hover:block absolute -top-[19rem] left-1/2 transform -translate-x-1/2 p-2 rounded-md w-[101%]">
                      <div className="terms-details flex flex-col gap-[1.3rem]">
                        <div className='flex gap-[2rem]'>
                            <div className='text-head w-[11rem]'>
                                <span>
                                    Copy, Display & Distribute
                                </span>
                            </div>
                            <div className='text-body'>
                                <span>
                                    You can display, distribute, and copy the artwork of your NFT.
                                </span>
                            </div>
                        </div>
                        <div className='flex gap-[2rem]'>
                            <div className='text-head w-[11rem]'>
                                <span>
                                    Sublicense
                                </span>
                            </div>
                            <div className='text-body'>
                                <span>
                                    You can sublincese your rights to the artwork of your NFT or
                                    your modifications and adaptations (if permitted).
                                </span>
                            </div>
                        </div>
                        <div className='flex gap-[2rem]'>
                            <div className='text-head w-[11rem]'>
                                <span>
                                    Commercial Use
                                </span>
                            </div>
                            <div className='text-body'>
                                <span>
                                    You can use the artwork for your NFT for commercial purposes.
                                </span>
                            </div>
                        </div>
                        <div className='flex gap-[2rem]'>
                            <div className='text-head w-[11rem]'>
                                <span>
                                    Modify & Adapt
                                </span>
                            </div>
                            <div className='text-body'>
                                <span>
                                    You can modify and adapt the artwork for your NFT.
                                </span>
                            </div>
                        </div>
                        <div className='flex gap-[2rem]'>
                            <div className='text-head w-[11rem]'>
                                <span>
                                    All Speech Permitted
                                </span>
                            </div>
                            <div className='text-body w-[78%]'>
                                <span>
                                    You may use the artwork of your NFT in any speech.
                                    Licenses without a check enable the prohibition of
                                    use of the artwork of your NFT in a way that is
                                    unlawful, defamatory, harassing, abusive, fradulent,
                                    racist, hateful, vulgar, cruel, illegal or obscene,
                                    or that promotes any such activity.
                                </span>
                            </div>
                        </div>
                        <div className='flex gap-[2rem]'>
                            <div className='text-head w-[11rem]'>
                                <span>
                                    Creator Retains No Rights
                                </span>
                            </div>
                            <div className='text-body'>
                                <span>
                                    You have exclusive rights to the artwork for your NFT
                                    and the creator retains no right to exploit it.
                                </span>
                            </div>
                        </div>
                        </div>
                      </div>
                    {/* <img src={infoIcon} alt="info" className="w-[1.25rem]"></img> */}
                </div>
              </div>
              <div className="flex flex-col w-full gap-2">
                <TermsTable
                  termsList={termsList}
                  disabled={!isCollectionValid}
                  handleTerms={handleTerms}
                  values={formValues?.termsId}
                />
                 <Validators
                  type="Required"
                  value={formValues?.termsId}
                  message="Required field"
                  controlName="termsId"
                  onErrors={collectErrors}
                />
              </div>
            </div>
          </div>
            <div className="flex-col gap-4 sm:hidden ">
              <div>
              <div className="section-title brd-btm w-full flex relative gap-2">
                  Terms & Conditions
                  <div className="group flex items-end">
                    <img src={infoIcon} alt="info" className="w-[1.25rem] cursor-pointer"/>
                    <div className="collection-terms-container border-container hidden group-hover:block absolute -top-[19rem] left-1/2 transform -translate-x-1/2 p-2 rounded-md w-[101%]">
                      <div className="terms-details">
                        <div className='flex gap-[2rem]'>
                            <div className='text-head w-[6rem]'>
                                <span>
                                    Copy, Display & Distribute
                                </span>
                            </div>
                            <div className='text-body'>
                                <span>
                                    You can display, distribute, and copy the artwork of your NFT.
                                </span>
                            </div>
                        </div>
                        <div className='flex gap-[2rem] '>
                            <div className='text-head w-[6rem]'>
                                <span>
                                    Sublicense
                                </span>
                            </div>
                            <div className='text-body'>
                                <span>
                                    You can sublincese your rights to the artwork of your NFT or
                                    your modifications and adaptations (if permitted).
                                </span>
                            </div>
                        </div>
                        <div className='flex gap-[2rem]'>
                            <div className='text-head w-[6rem]'>
                                <span>
                                    Commercial Use
                                </span>
                            </div>
                            <div className='text-body'>
                                <span>
                                    You can use the artwork for your NFT for commercial purposes.
                                </span>
                            </div>
                        </div>
                        <div className='flex gap-[2rem]'>
                            <div className='text-head w-[6rem]'>
                                <span>
                                    Modify & Adapt
                                </span>
                            </div>
                            <div className='text-body'>
                                <span>
                                    You can modify and adapt the artwork for your NFT.
                                </span>
                            </div>
                        </div>
                        <div className='flex gap-[2rem]'>
                            <div className='text-head w-[6rem]'>
                                <span>
                                    All Speech Permitted
                                </span>
                            </div>
                            <div className='text-body '>
                                <span>
                                    You may use the artwork of your NFT in any speech.
                                    Licenses without a check enable the prohibition of
                                    use of the artwork of your NFT in a way that is
                                    unlawful, defamatory, harassing, abusive, fradulent,
                                    racist, hateful, vulgar, cruel, illegal or obscene,
                                    or that promotes any such activity.
                                </span>
                            </div>
                        </div>
                        <div className='flex gap-[2rem]'>
                            <div className='text-head w-[6rem]'>
                                <span>
                                    Creator Retains No Rights
                                </span>
                            </div>
                            <div className='text-body'>
                                <span>
                                    You have exclusive rights to the artwork for your NFT
                                    and the creator retains no right to exploit it.
                                </span>
                            </div>
                        </div>
                        </div>
                      </div>
                    {/* <img src={infoIcon} alt="info" className="w-[1.25rem]"></img> */}
                </div>
              </div>
              </div>
              </div>    
            {/* <div
              className={` mt-[1rem] ${activeTabs === 3 ? "flex" : "hidden"
                } flex-col`}
            >
              <TermsMob
                termsList={termsList}
                handleTerms={handleTerms}
                values={formValues?.termsId}
              />
              <Validators
                type="Required"
                value={formValues?.termsId}
                message="Required field"
                controlName="termsId"
                onErrors={collectErrors}
              />
            </div> */}
            </>:
            <div
                className={`flex flex-col`}
              >
                <AssetsOnDemand
                  assetData={assetData}
                  policyId={formValues?.collectionId}
                />
              </div>}
              </>}
          </div>
          
          {/* {collectionId && (
            <>
              <div className="panel-accord">
                <div className="accord-title">
                  <span>Assets</span>
                </div>
                <div
                  className="accord-arrow cursor-pointer"
                  data-id={2}
                  onClick={handleTabs}
                >
                  <span>
                    <img
                      src={arrowIcon}
                      alt="icon"
                      className={activeTabs === 2 ? "active" : ''}
                    />
                  </span>
                </div>
              </div>
              
            </>
          )} */}
        </div>
        {(!!isCollectionValid||!!collectionId)&&<div className="flex justify-center">
        <div className="collection-update_btn-container flex justify-center sm:gap-4 gap-[0.625rem] sm:mt-0 mt-[0.5rem] sm:mb-0">
        {/* {!!collectionId&& <>
              <RoundedButton
                  className={`save-btn save ${isCollectionValid&&"edit-active"}`}
                  OnClick={()=>{
                    if (scrollTopRef.current) {
                      scrollTopRef.current.scrollIntoView({
                        behavior: 'smooth', 
                        block: 'start',
                      });
                    }
                    setIsCollectionValid(true);
                  }}
                  Type="1"
                  Name={"Edit"}
                ></RoundedButton><RoundedButton
                  className={"cancel-btn  remove "}
                  OnClick={removeClickHandler}
                  Type="1"
                  Name={"Remove"}
                /></>} */}
                <RoundedButton
                  className={"cancel-btn save"}
                  OnClick={props.onClose}
                  Type="1"
                  Name={"Cancel"}
                ></RoundedButton>
                <RoundedButton
                  className={`save-btn ${isValid ? "active" : ""} submit`}
                  OnClick={saveHandler}
                  Type="1"
                  Name={screensize >550 ? 'Save' : 'Save'}
                ></RoundedButton>
              </div>
              </div>}
      </Panel>
    </div>
    {!!removeClick&&<RemoveWarning onDelete={removeCollection} onCancel={removeClickHandler} approveBtn="Remove">Are you sure you want to remove?</RemoveWarning>}
    </>
  );
};

export default CollectionUpdate;