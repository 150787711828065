import { useRef, useState } from "react";
import Accordion from "App/Components/Ui/Accordion";
import AccordionExpander from "App/Components/Ui/Accordion/AccordionExpander";
import "./Accordion.scss";
import RemoveWarning from "App/Components/RemoveWarning";
import EditDelete from "App/Components/EditDelete";

const AccordionList = ({
  Title,
  desc,
  value,
  editHandler,
  deleteHandler,
  normalAccordion,
  readApi
}) => {
  const [expand, setExpand] = useState(false);
  const [removeClick, setRemoveClick] = useState(false);
  const toggleAccordian = (e) => {
    readApi && !expand && value?.isRead === "0" && readApi(value?.id);

    // if (normalAccordion) {
    //   setExpand((prev) => !prev);
    // } else {
    //   if (
    //     accordionLength.current.clientWidth - e.nativeEvent.layerX <= 26 &&
    //     e.nativeEvent.layerY < 30
    //   ) {
    //     editHandler(value);
    //   } else
       setExpand((prev) => !prev);
    // }
  };
  const editHandlerFn=(e)=>{
    e.stopPropagation()
    editHandler(value)
  }
  const deleteHandlerFn=(e)=>{
    e.stopPropagation()
    setRemoveClick(true)
  }
  const deleteHandlerMethod=()=>{
    deleteHandler(value)
    removeClickHandler()
  }
  const removeClickHandler=()=>setRemoveClick(false)
  const accordionLength = useRef();
  return (<>
    <Accordion
      className={`px-0 accordion-wrapper ${
        editHandler && "accordion-with-edit"
      }`}
    >
      <div
        className={`expander text-left cursor-pointer rounded-[.312rem]  border border-[#191D23]`}
      >
        <div
          ref={accordionLength}
          onClick={toggleAccordian}
          className={`accordion-arrow ${normalAccordion && "arrow"} ${
            expand ? "expanded" : "collapsed"
          }`}
        >
          <div className="accordion-title-section">
            <Title />
            <div className={`icons-overlay ${editHandler?"flex":"hidden"} ml-1 mr-2`}>
              <EditDelete isDeletable={true} isEditable={true} editHandlerFn={editHandlerFn} deleteHandlerFn={deleteHandlerFn} />
            </div>
          </div>
        </div>
        <AccordionExpander expand={expand}>
          <div className="dangerously-innerHTML-container" dangerouslySetInnerHTML={{__html: desc}}/>
          </AccordionExpander>
      </div>
    </Accordion>
    {!!removeClick&&<RemoveWarning onDelete={deleteHandlerMethod} onCancel={removeClickHandler} >Are you sure you want to delete this post?</RemoveWarning>}

    </>
  );
};

export default AccordionList;
