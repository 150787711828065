import { Outlet, useLocation, useParams } from "react-router-dom";
import "./Components/SubComponents/CommonStyle.scss";
import { useEffect } from "react";
import { useStore } from "App/hooks-store/store";
import useScreenSize from "App/hooks/use-screen-size";
const Discover = () => {
  const dispatch = useStore(false)[1];
  const screenSize = useScreenSize()?.width;
  const { categoryId, communityId } = useParams();
  useEffect(() => {
    return () => {
      dispatch("resetAll");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let path = ["artists", "photographers","onchain-artists"]
  const checkRoute = ()=>{
return path.some((el)=>pathname?.includes(el))
  }
  const { pathname } = useLocation();
  return (
    <div
      className={`h-full discover-module ${ (categoryId || checkRoute())
        ? "pb-16 sm:pb-2 pl-4 pr-0 sm:pr-0 pt-4":
        !pathname?.includes(`communities/${communityId}`) &&
        !pathname?.includes("collectors/details")
        && !pathname?.includes("discover/collection")
          ? "pb-16 sm:pb-2 pl-4 pr-4 sm:pr-4 pt-4"
          : pathname?.includes("discover/collection") ?
          ""
          : ""
      } ${screenSize > 550 && "page-shadow "} ${(categoryId|| checkRoute()) ? "overflow-hidden" : "overflow-lg-auto"}`}
    >
      <Outlet />
    </div>
  );
};
export default Discover;
