import SelectedGallery from "App/Pages/NftGallery/Component/MyGallery/Components/SelectedGallery";
import CollectionDetailRight from "../../../DetailRightPanel/Components/CollectionDetailRight";
import DetailsTab from "App/Pages/Collections/Components/SubComponents/SelectedCollectionV2/Components/SelectedNftDetails/Components/DetailsTab";
import Chart from "App/Components/Chart";
import { useCallback, useEffect, useRef, useState } from "react";
import useShapes from "App/hooks/use-shapes";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import useScreenSize from "App/hooks/use-screen-size";
import axios from "axios";

const RightPanel = (props) => {
  const [tabClickCount, setTabClickCount] = useState(0); //to re-render selected gallery compoent while switching tabs
  const [assetIds, setAssetIds] = useState(false);
  const [aspectRatios, setAspectRatios] = useState([]);
  const [customStyles, setCustomStyles] = useState(null);
  const { saveCollectionShape } = useShapes();
  const [activeTab, setActiveTab] = useState("Marketplace");
  const { triggerAPI } = useHttp();
  const [store, dispatch] = useStore();
  const screenSize = useScreenSize()?.width;
  const targetRef = useRef();
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const { collectionRawData, spinnerMode } = store;
  const toBack = (e) => {};
  const handleTabChange = (tab) => {
    if(tab === activeTab) return;
    setCustomStyles(`h-[40dvh] absolute`)
    setActiveTab(tab);
    if (tab === "tab2") {
      setTimeout(() => {
        dispatch("enableTopIcons", {
          back: true,
          lgView: false,
          smView: false,
        });
      }, 10);
    }
    setTabClickCount(prev => prev +1)
    dispatch("resetSingleCollection");
  };
  const imageRatioCalculated = useCallback(
    (e) => {
      //this should in theory end up with all the ratios in the array
      //due to react craziness, usually only the last ratio ends up here till a refresh
      if (aspectRatios.findIndex((ar) => ar === e) === -1) {
        let ratios = [...aspectRatios, e];
        setAspectRatios(ratios);
        saveCollectionShape(null, e, null, props.selectedCollection);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aspectRatios, props.selectedCollection]
  );
  useEffect(() => {
    setCustomStyles(null)
  }, [activeTab]);
  useEffect(() => {
    if (props?.selectedCollection?.id) {
      getDetailedCollection();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedCollection]);

  const getDetailedCollection = useCallback(() => {
    setAspectRatios([]);
    if (activeTab==="Marketplace" && props?.selectedCollection?.collectionId)
    {
      async function fetchdata() {
        let axiosData={
          method: "GET",
          url: `https://api.tokeopay.io/api/v1/listings/policy/${props?.selectedCollection?.collectionId}`,          
          headers: {}
        }            
      
        let response = await axios(axiosData);        
        let marketplaceAssets = [...new Set(
                          response.data.filter(a => !a.isCollectionOffer && !a.isTokenOffer) //filter out offers
                          .map(a => a.tokenName))] //get token name, convert to set to remove duplicates
                          .map(a=> {
                            let match = response.data.filter(d=>d.tokenName === a && !d.isCollectionOffer && !d.isTokenOffer)[0];
                            return {...match, asset_name: match.tokenName}
                          }); //map into correct assetids format for core code
        marketplaceAssets = marketplaceAssets.sort((a,b)=>(a.price??9999999999)>(b.price??9999999999)?1:(a.price??9999999999)<(b.price??9999999999)?-1:a.asset_name>b.asset_name?1:-1);
        if (marketplaceAssets.length < 30)
        {
          const params = new URLSearchParams({
            id: props?.selectedCollection.id
          });  
          triggerAPI(
            {
              url: `community/collection/get-one?${params}`,            
              method: "get",
            },
            (res) => {    
              marketplaceAssets = [...marketplaceAssets, ...res.data.assets.slice(0, 30-marketplaceAssets.length).map(a => { return { asset_name: a.asset_name, policy: props?.selectedCollection?.collectionId}})]  
              marketplaceAssets = marketplaceAssets.sort((a,b)=>(a.price??9999999999)>(b.price??9999999999)?1:(a.price??9999999999)<(b.price??9999999999)?-1:a.asset_name>b.asset_name?1:-1);        
              setAssetIds(marketplaceAssets);              
            });
        }
        else
        {
          setAssetIds(marketplaceAssets);        
        }        
      }
      
      fetchdata();        
    }
    else
    {    
      if (props?.selectedCollection?.id && !props?.selectedCollection?.assets) {                        
        const params = new URLSearchParams({
          id: props?.selectedCollection.id
        });        
          triggerAPI(
          {
            url: `community/collection/get-one?${params}`,            
            method: "get",
          },
          (res) => {
            props.setSelectedCollection(res.data);
            setAssetIds(res.data.assets);
            let ratios = res.data.shapes?.map((s) => s.aspectRatio);
            setAspectRatios(ratios);
            dispatch("setActiveIcons", { caption: true });
          }
        );           
      } else {
        setAssetIds(props?.selectedCollection?.assets);
        let ratios = props?.selectedCollection.shapes?.map((s) => s.aspectRatio);
        setAspectRatios(ratios);
        dispatch("setActiveIcons", { caption: true });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, activeTab, triggerAPI, props?.selectedCollection?.id]);
  const hasCollectionWithPolicyId = collectionRawData?.some(
    (collection) =>
      collection?.policy_id === props?.selectedCollection?.collectionId
  );
  const collectionWithPolicyId = collectionRawData?.find(
    (collection) =>
      collection?.policy_id === props?.selectedCollection?.collectionId
  );
  return (
    <>
      <div className=" sm:z-9 sm:sticky top-[0]">
        <div className="details-content ">
          <CollectionDetailRight
            selectedCollection={props?.selectedCollection}
            className="dis-creator-collection"
            backHandler={props?.backHandler}
            isCollection={true}
            hideCloseOptn={props?.hideCloseOptn}
            aspectRatios={aspectRatios}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
      <div className="flex h-full gap-[1.42857rem]">
        <div
          className={`flex w-full flex-1 flex-col gap-2 ${
            isPortrait && screenSize <= 550
              ? props?.selectedCollection && "hidden"
              : ""
          }`}
        >
          <div className="tab-styles   py-2 sm:z-9 sm:sticky top-[15rem]">
            <div className="tab-list ">
              <div className={`tab-data flex gap-[1.42857rem]`}>
                <div
                  className={`tab ${
                    activeTab === "Marketplace" ? "active" : "not-active"
                  }`}
                  onClick={() => handleTabChange("Marketplace")}
                >
                  Marketplace
                </div>
                {hasCollectionWithPolicyId && (
                  <div
                    className={`tab ${
                      activeTab === "Collection" ? "active" : "not-active"
                    }`}
                    onClick={() => handleTabChange("Collection")}
                  >
                    My Collection
                  </div>
                )}
                <div
                  className={`tab ${activeTab === "tab2" ? "active" : "not-active"}`}
                  onClick={() => handleTabChange("tab2")}
                >
                  Details
                </div>
                <div
                  className={`tab ${activeTab === "Analytics" ? "active" : "not-active"}`}
                  onClick={() => handleTabChange("Analytics")}
                >
                  Analytics
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${customStyles ? customStyles : `${props?.className} w-full relative discover-selected-collection `}`}
            ref={targetRef}
          >
            {activeTab === "Collection" ? (
              <SelectedGallery
                key={tabClickCount}
                policyId={props?.selectedCollection?.collectionId}
                onDemandAssetIds={collectionWithPolicyId?.data}
                displayMode="lg"
                value={{ mode: false }}
                filter={{ sort: "Name A-Z" }}
                scrollOffset={800}
                pageCount={9}
                type="discover"
                isFrameable={false}
                isSearchable={false}
                isSortable={false}
                OnClick={toBack}
                switchable={true}
                imageRatioCalculated={imageRatioCalculated}
                classNames={`dicover-collection-lg ${props.classNames}`}
                setCommunityCollectionAssetIds={setAssetIds}
                collectionView={true}
                activeTab={activeTab}
                parentScrollContainerQuery=".discover-creator-details"
              />
            ) : activeTab === "Marketplace" ? (
              <SelectedGallery
                key={tabClickCount}
                policyId={props?.selectedCollection?.collectionId}
                onDemandAssetIds={assetIds}
                displayMode="lg"
                value={{ mode: false }}
                filter={{ sort: "Name A-Z" }}
                scrollOffset={800}
                pageCount={9}
                type="discover"
                isFrameable={false}
                isSearchable={false}
                isSortable={false}
                OnClick={toBack}
                switchable={true}
                imageRatioCalculated={imageRatioCalculated}
                classNames={`dicover-collection-lg ${props.classNames}`}
                setCommunityCollectionAssetIds={setAssetIds}
                collectionView={true}
                activeTab={activeTab}
                parentScrollContainerQuery=".discover-creator-details"
              />
            ) : activeTab === "Analytics" ? (
              <div className={`dicover-collection-lg`}>
                <Chart
                  collectionPrimaryKey={props?.selectedCollection?.id}
                  type="collection/price_chart"
                  title="Floor Price / Volume"
                />
              </div>
            ) : (
              <DetailsTab
                communityUserDetails={props?.selectedCollection}
                collection={props?.selectedCollection}
                details={props?.selectedCollection}
                // array={wrappedArray}
              />
            )}
          </div>
          {props?.collectionList &&
            !props?.collectionList?.length &&
            !spinnerMode && (
              <div className="flex items-center sm-h-8 justify-center no-data">
                There are currently no collections.
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default RightPanel;
