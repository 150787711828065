import { useEffect, useState } from "react";
import FormatField from "App/Components/Ui/FormatField";
import './EventCard.scss'
import { EventDetails } from "./EventDetails";
import EditDelete from "App/Components/EditDelete";
import RemoveWarning from "App/Components/RemoveWarning";
import BlurBGImage from "../BlurBGImage";
import userAvatar from "Assets/Icons/logo-square.svg";
import { findSelectedSubcategory } from "App/Helper/utilities";

export const EventsCard = (props) => {
  const [openDetails, setOpenDetails] = useState()
  const [removeClick, setRemoveClick] = useState(false);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [isOneDayEvent, setIsOneDayEvent] = useState(false);
  const [modifiedDate, setModifiedDate] = useState({date:'', time:''});
  function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
    });
}
const changeEvent = (direction) => {
  if (direction === 'next') {
    setCurrentEventIndex((prevIndex) => 
      prevIndex + 1 < props.data.length ? prevIndex + 1 : prevIndex
    );
  } else if (direction === 'prev') {
    setCurrentEventIndex((prevIndex) => 
      prevIndex - 1 >= 0 ? prevIndex - 1 : prevIndex
    );
  }
};
const value = props?.data
function formatTimeRange(startTime, endTime) {
  const start = new Date(startTime);
  const end = new Date(endTime);

  const formattedStart = start.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  const formattedEnd = end.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

  const timezoneOffsetStart = start.getTimezoneOffset();
  const timezoneOffsetEnd = end.getTimezoneOffset();

  const utcStart = ` (UTC${getTimezoneOffsetString(timezoneOffsetStart)})`;
  const utcEnd = ` (UTC${getTimezoneOffsetString(timezoneOffsetEnd)})`;

  return `${formattedStart} - ${formattedEnd}${utcEnd}`;
}

function getTimezoneOffsetString(offset) {
  const sign = offset > 0 ? '-' : '+';
  const hours = Math.floor(Math.abs(offset) / 60);
  return `${sign}${hours}`;
}

function formatDateTimeRange(date) {
let dateOnly = formatDate(date);
  const formatted = new Date(date)?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  const timezoneOffset = new Date(date)?.getTimezoneOffset();
  const utcDate = ` (UTC${getTimezoneOffsetString(timezoneOffset)})`;
  // return `${dateOnly} ${formatted}${utcDate}`;
  return `${dateOnly} ${formatted}`;
}

const editEvent= (value) => {
  props.editHandler(value)
}
const eventDetailsHandler = () => {
  setOpenDetails(true)
}
const closeDetails = () => {
  setOpenDetails(false)
}
const escPressed = () => {
  closeDetails()
};
useEffect(() => {
  const keyDownHandler = event => {

    if (event.key === 'Escape') {
      event.preventDefault();

      escPressed();
    }
  };

  document.addEventListener('keydown', keyDownHandler);

  return () => {
    document.removeEventListener('keydown', keyDownHandler);
  };
}, []);
const editHandlerFn=(e)=>{
  e.stopPropagation()
  props.editHandler(value)
}
const deleteHandlerFn=(e)=>{
  e.stopPropagation()
  setRemoveClick(true)
}
const deleteHandlerMethod=()=>{
  props.deleteHandler(value)
  removeClickHandler()
}
const removeClickHandler=()=>setRemoveClick(false)
function isHTML(str) {
  const doc = new DOMParser()?.parseFromString(str, 'text/html');
  return Array?.from(doc?.body?.childNodes)?.some(node => node?.nodeType === 1);
}
const selectedCategory = findSelectedSubcategory(props?.data?.categories);

  const dateConverter = (startDate, endDate, withTime) => {
    let start = new Date(startDate)
    let end = new Date(endDate)
    let options;
    let formattedDate;
    let formattedTime;
    if (withTime) {
      options = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
      formattedDate = `${start?.toLocaleDateString('en-US', options)?.split(',')[0]}, ${start?.toLocaleDateString('en-US', options)?.split(',')[1]}`
      formattedTime = `${start?.toLocaleDateString('en-US', options)?.split(',')[2]} - ${end?.toLocaleDateString('en-US', options)?.split(',')[2]}`
    } else {
      options = { month: 'short', day: 'numeric', year: 'numeric' };
      const startYear = start?.toLocaleDateString('en-US', options)?.split(',')[1]
      const endYear = end?.toLocaleDateString('en-US', options)?.split(',')[1] 
      formattedDate = `${start?.toLocaleDateString('en-US', options)?.split(',')[0]}${startYear === endYear ? '' : ','} ${startYear === endYear ? '' : start?.toLocaleDateString('en-US', options)?.split(',')[1]} - ${end?.toLocaleDateString('en-US', options)}`
    }
    return { date: formattedDate, time: formattedTime }
  }

  const getDatePortion = (dateString) => dateString?.slice(0, 10);

  useEffect(() => {
    if (props?.data?.start && props?.data?.end) {
      const areDatesEqual = getDatePortion(props?.data?.start) === getDatePortion(props?.data?.end);
      setModifiedDate(dateConverter(props?.data?.start, props?.data?.end, areDatesEqual));
      setIsOneDayEvent(areDatesEqual)
    }
  }, [props?.data?.start, props?.data?.end])

  return (
    <>
    <div className="event-container">
        {/* {loading && (
          <div className="min-h-[20rem]">
            <SkeltonCard
              show={loading}
              height="11rem"
              count={2}
              inline={true}
              containerClassName={` flex gap-2`}
            />
          </div>
        )} */}
        {
        // !loading && !!eventList?.length&&
        <div className=" event-card flex flex-col sm:gap-2 gap-[0.5rem] min-h-[14rem] "
        onClick={eventDetailsHandler}
        > 
        <div className="flex justify-between items-center mx-[0.55rem]">
            <div  className={`flex gap-3 justify-center items-start`}
            //  onClick={()=>{navigate("/discover/creators/details/"+props?.community?.id)}}
             >
                      {
                        !props.fromCommunity && props?.data?.communityPic  &&
                        <img src={props?.data?.communityPic ?? props?.data?.community?.displayPicture256} className="card-profile-icon object-cover" alt=""></img>
                      }
                      {
                        (props.fromCommunity || props?.data?.community?.displayPicture256) &&
                        <img src={props?.data?.community?.displayPicture256||userAvatar} className="card-profile-icon object-cover" alt=""></img>
                      }
                <div className={``}>
                    <span className="title text-left font-semi  bold text-[.875rem] text-[#D0D5DD]"
                    //  title={props.data.name}
                     >
                        {" "}
                        <FormatField
                        type="sub"
                        maxWidth={20}
                        value={ props?.data?.name ?? props.isCreator ? props?.data?.communityName : props.fromCommunity ? props.data.community.name :  props?.data?.community?.name   }
                        />
                    </span>
                </div>  
            </div>
            <div className={`${props.editHandler?"flex":"hidden"} ml-1`}>
              <EditDelete isDeletable={true} isEditable={true} editHandlerFn={editHandlerFn} deleteHandlerFn={deleteHandlerFn} />
            </div>
          </div>     
          { !props.fromCommunity && <div className="body h-[11rem]  max-h-[11rem] overflow-hidden mx-[0.55rem]">
          <BlurBGImage mainImageSrc={props?.data?.filePath ?? props?.data.community?.thumbnailPicture256} bgImageSrc={props?.data?.filePath ?? props?.data.community?.thumbnailPicture256}/>
                <span className={`${props?.data?.isFollowing ? 'event-body-following' : 'hidden'}`}>Following</span>
            </div>}
            { props.fromCommunity && <div className="body h-[11rem] min-h-[11rem] max-h-[11rem] overflow-hidden mx-[0.55rem]">
            <BlurBGImage mainImageSrc={props?.data?.filePath ?? props?.data.community?.thumbnailPicture256} bgImageSrc={props?.data?.filePath ?? props?.data.community?.thumbnailPicture256}/>
                <span className={`${props?.data?.isFollowing ? 'event-body-following' : 'hidden'}`}>Following</span>
            </div>}

            <div className="event_desc_box relative h-full flex flex-col gap-2">
              <div className="head sm:gap-3 gap-[0.5rem] flex flex-col items-start">
                <span className={`text-[0.8rem] text-[var(--cu-white-100)] px-2 py-1  rounded-sm mr-6 ${selectedCategory ? 'bg-[var(--cu-black-800)]' : 'h-7'}`}>{selectedCategory}</span>
                <div className="flex gap-1 items-center w-full flex-wrap justify-between truncate">
                  <span className="time">
                    {modifiedDate?.date ?? formatDateTimeRange(props?.data?.start)}
                  </span>
                  <span className="time">
                    {modifiedDate?.date ? modifiedDate?.time : formatDateTimeRange(props?.data?.end)}
                  </span>
                </div>
              </div> 
              {/* <div> */}
                <div className="foot text-left font-bold   sm:text-[.875rem] text-[.75rem]" title={props?.data?.headline}>
                  {props?.data?.headline || (props?.data?.name ?? "")}
                </div>
                <div className="text-left text-ellipsis text-[#D0D5DD] sm:text-[0.95rem] text-[0.75rem]">
                  <FormatField
                    type={isHTML(props?.data?.details) ? "htmlContent" : 'sub'}
                    maxWidth={45}
                    value={props?.data?.details ?? ""}
                  />
                </div>
              {/* </div>  */}
           <div className="absolute event_details_box bottom-0 w-full">
           <div className="dangerously-innerHTML-container" dangerouslySetInnerHTML={{__html: props?.data?.details}}/>
           </div>
            </div>
          </div>}
       
        <EventDetails 
            openDetails={openDetails} 
            closeDetails={closeDetails}
            eventData={{...props.data, selectedCategory}}
            fromCommunity={props.fromCommunity}
            isCreator={props.isCreator}
            changeEvent={changeEvent}
            isOneDayEvent={isOneDayEvent}
            modifiedDate={modifiedDate}
        /> 
    </div>
    {!!removeClick&&<RemoveWarning onDelete={deleteHandlerMethod} onCancel={removeClickHandler} >Are you sure you want to delete this event?</RemoveWarning>}

    </>
  );
};