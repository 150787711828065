import WarningBox from 'App/Components/Ui/WarningBox'

const RemoveWarning = props => {
    return (
        <WarningBox onApprove={props.onDelete} onCancel={props.onCancel} approveBtn={props?.approveBtn||"Delete"} cancelBtn={!props?.noCancelButton&&"Cancel"}>
            {props?.children}
        </WarningBox>
    )
}

export default RemoveWarning
