import DisplayUpdate from "./Components/DisplayUpdate";
import DisplayList from "./Components/DisplayList";
import "./Display.scss";
import { useCallback, useEffect, useState } from "react";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";

const Display = () => {   
  const [pairShow, setPairShow] = useState(false);
  const [displayList, setDisplayList] = useState(null);
  const [{ currentUserId }, dispatch] = useStore();
  const { triggerAPI } = useHttp();
  const [spinnerShow, setSpinnerShow] = useState(true);      
  const [viewDisplays, setViewDisplays] = useState(false);
    
  const getDisplaysForUserResult = useCallback((res) => {
    setSpinnerShow(false);        
    setDisplayList(res.data?.sort((a,b)=>b.createdAt>a.createdAt?1:-1));    
  }, []);

  const getDisplaysForUser = useCallback(() => {
    setSpinnerShow(true);

    triggerAPI(
      {
        url: `display/listforuserid?userId=${currentUserId}`,
        method: "get"        
      },
      getDisplaysForUserResult
    );
  }, [getDisplaysForUserResult, triggerAPI, currentUserId]);

  useEffect(() => {
    if (currentUserId)
    {
      getDisplaysForUser();
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  const pairClick=()=> {
    setPairShow(true);
  }

  const onClose=()=> {
    setPairShow(false);
    getDisplaysForUser();
  }

  return (
    <>
      <div className="display-page page-shadow h-full pb-16 sm:pb-2 pl-4 pr-4 sm:pr-4 page-shadow pt-4 overflow-lg-auto">
        {/* {!spinnerShow && !viewDisplays && (<DisplaySummary pairClick={pairClick} viewDisplaysClick={displayList.length > 0 ? viewDisplaysClick : null}></DisplaySummary>)} */}
        { (<DisplayList displayList={displayList} pairClick={pairClick} currentUserId={currentUserId}></DisplayList>)} 
      </div>
      {pairShow && (<DisplayUpdate onClose={onClose}></DisplayUpdate>)}
    </>
  );
};

export default Display;