import "./DiscoverTopPanelMob.scss";
import twitterIcon from "Assets/Icons/social/Twitter.svg";
import discordIcon from "Assets/Icons/social/Discord.svg";
import youtubeIcon from "Assets/Icons/social/YouTube.svg";
import { useCallback, useEffect, useState } from "react";
import useHttp from "App/hooks/use-http";
import { useParams } from "react-router-dom";
import { useStore } from "App/hooks-store/store";
import userAvatar from 'Assets/Icons/userAvatars.svg';
import rightArrow from 'Assets/Icons/right-arrow.svg';

const DiscoverTopPanelMob = (props) => {
  const [{ currentUserId }, dispatch] = useStore();
  const { triggerAPI } = useHttp();
  const { userId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [isFollowed, setIsFollowed] = useState(false);

  useEffect(() => {
    if (props?.fromOther || props?.dontCallApi) setProfileData(props?.profileData)
    else getCreatorDetail();
    setIsFollowed(props?.profileData?.isFollowing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.profileData, props?.fromOther])
  const getCreatorDetailResponse = useCallback(
    (res) => {
     // dispatch("hideSpinner");
      setProfileData(res?.data);
      setIsFollowed(res?.data?.isFollowed);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const getCreatorDetail = useCallback(() => {
   // dispatch("showSpinner");
    triggerAPI(
      {
        url: `user/creator/get/${userId}`,
        method: "get",
      },
      getCreatorDetailResponse
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerAPI, getCreatorDetailResponse, dispatch, userId]);

  const handleFollow = () => {
    const data = {
      owner: profileData?.user?.id,
    };
    const url = isFollowed ? "user/unfollow" : "user/follow";
    const method = isFollowed ? "DELETE" : "POST";
    dispatch("showSpinner");
    triggerAPI(
      {
        url: url,
        method,
        data,
      },
      (res) => {
        dispatch("hideSpinner");
        setIsFollowed(!isFollowed);
      }
    );
  };
  const details = (className1, className2) => <>
    <div className={`z-1 ${className1}`}>
      <img className="pro-img " src={profileData?.profilePicUrl ?? userAvatar} alt="" />
    </div>
    <div className={`flex justify-between flex-col sm:flex-row gap-2 flex-1 z-1 ${className2}`}>
    <span className="text-left name-text">{profileData?.name}</span>
      <div className="profile-social flex items-center gap-[0.71429rem] sm:flex hidden">
        {profileData?.twitter && (
          <a
            href={profileData?.twitter}
            rel="noreferrer"
            target="_blank"
          >
            <img src={twitterIcon} alt="" />
          </a>
        )}
        {profileData?.discord && (
          <a
            href={profileData?.discord}
            rel="noreferrer"
            target="_blank"
          >
            <img src={discordIcon} alt="" />
          </a>
        )}
        {profileData?.youtube && (
          <a
            href={profileData?.youtube}
            rel="noreferrer"
            target="_blank"
          >
            <img src={youtubeIcon} alt="" />
          </a>
        )}
      </div>
    </div>
  </>
  const onDetailsHandler=()=>{
    props?.onDetailsInfoHandler && props?.onDetailsInfoHandler(profileData)
  }
  return (
    <div id="discover-overview" className={`discover-details-profile ${props?.className}`}>
      <div className="profile-section">
        <div className="profile-details flex flex-col justify-between items-center w-full">
          <div className="profile-img background-banner flex items-center gap-[0.71429rem] w-full" style={{ backgroundImage: `url(${profileData?.bannerPicUrl})` }}>
            {details("sm:block hidden", "sm:flex hidden")}
          </div>
          <div className="details-wrapper">
            {details("sm:hidden block", "sm:hidden flex")}

          </div>
        </div>
        <div className="details-desc-short w-full sm:hidden flex" onClick={onDetailsHandler}>
          <p>{profileData?.description?.slice(0, 43)}</p>
          <img src={rightArrow} alt="" />
        </div>
        {(currentUserId !== profileData?.user?.id && props?.self) && (
          <div className="flex follow-section mt-[0.625rem] w-full">
            <div className="follow-btn-container w-full" onClick={handleFollow}>
              <span>{isFollowed ? "Following" : "Follow"}</span>
            </div>
          </div>
        )}
        {(props?.fromOther && !props?.self) &&
          <div className="flex follow-section mt-[0.625rem] w-full" onClick={props?.editClick}>
            <div className="follow-btn-container w-full">
              <span>{`Edit`}</span>
            </div>
          </div>
        }
        {/* <div className="profile-desc">
          <div className="desc description">
            <span>{profileData?.description}</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default DiscoverTopPanelMob;
