import ControlBody from "../SubComponents/ControlBody";
import ControlCard from "../SubComponents/ControlCard";
import ControlContent from "../SubComponents/ControlContent";
import ControlTitleCard from "../SubComponents/ControlTitleCard";
import { memo, useCallback, useState } from "react";
import closeIcon from "Assets/Icons/close.svg";
const MultiBgControl = ({className = '', multiStyleList = [], multiColorList = [], bgWallFilter = null, setBgWallFilter = null, multiWallList=[], isMeta = false, selectedMultiBg, setSelectedMultiBg}) => {
    const [dropdown, setDropDown] = useState(false);

    const onMouseBlur = useCallback(() => {
        setDropDown(false);
      }, []);
      const onMouseFocus = useCallback(() => {
        setDropDown(true);
      }, []);
    const onStyleHandler = (type) => {
        setBgWallFilter(prev => {
            return {...prev, style: bgWallFilter?.style === type ? "" : type}
        });
    };
    const onColorClose = ()=>{
        setBgWallFilter(prev => { return {...prev, color: ''} });
        onMouseBlur();
    }
    const onColorSelect = (e) => {
        setBgWallFilter(prev => {
            return {...prev, color: bgWallFilter?.color === e.currentTarget.dataset.id ? "" : e.currentTarget.dataset.id}
        });
        onMouseBlur();
    };
    const handleClick = useCallback((e) => {
        const id = parseInt(e.currentTarget.dataset?.id);
        let selected = multiWallList?.find(item => item?.id === id)
        setSelectedMultiBg(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiWallList]);
    return (
        <ControlCard className={`${className} pop flex flex-col gap-5 mbg-list-control w-[50rem]`}>
            <ControlBody className="mbg-list-section">
                <div className="flex gap-2 justify-between">
                    <ControlTitleCard>{isMeta ? 'Venue' : 'Background'}</ControlTitleCard>
                    {!isMeta && 
                        <div className="mbg-filter flex gap-2 items-end">
                            <div className="flex items-center gap-8">
                                <div className="flex items-center">
                                    {multiStyleList?.map((val, i) => (
                                        <div
                                            key={i + 1}
                                            className={`style-box capitalize ${
                                                bgWallFilter?.style === val?.value && "active"
                                            }`}
                                            onClick={() => onStyleHandler(val?.value)}
                                        >
                                            {val?.name}
                                        </div>
                                    ))}
                                </div>
                                <div
                                    className="color-wrapper w-[4.081875rem] flex items-center relative"
                                    onMouseOverCapture={onMouseFocus}
                                    onMouseLeave={onMouseBlur} >
                                    {!!bgWallFilter?.color && (
                                    <div
                                        className={`color-show ${bgWallFilter?.color === "multi-color" ? "multi" : ""}`}
                                        style={{ backgroundColor: bgWallFilter?.color }}
                                    ></div>
                                    )}
                                    {dropdown && (
                                        <div className="color-list">
                                            <div className="flex justify-end mb-[0.5rem]">
                                                <img src={closeIcon} onClick={onColorClose} alt="" className='cursor-pointer close-icon' />
                                            </div>
                                            <div className="color-list-wrapper">
                                                {multiColorList?.length ? 
                                                    multiColorList?.map((colors) => (
                                                        <div
                                                            onClick={onColorSelect}
                                                            key={colors.id}
                                                            data-id={colors?.name}
                                                            className={`color-circle ${
                                                                bgWallFilter?.color === colors?.name ? "selected" : ""
                                                            } ${colors.name === "multi-color" ? "multi" : ""}`}
                                                            style={{ backgroundColor: colors.name }}
                                                        />
                                                    ))
                                                    :
                                                    <p className="flex justify-center h-full w-full items-center no-color no-data">No colors found</p>
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                </div>                
                <ControlContent className="mbg-content">
                    {multiWallList?.length ? <div className={`grid mbg-sec-list listing-grid-control-panel`}>

                        {multiWallList?.map((wall, index) => (
                            <div className={`cursor-pointer background-item ${selectedMultiBg?.id === wall?.id ? 'active' : ''}`} data-id={wall?.id} key={`${wall?.id}${index}`} onClick={handleClick}>
                                <img alt="" src={wall?.imageUrl256} />
                            </div>
                        ))
                        }</div>
                        :
                        <p className="flex justify-center text-[var(--cu-black-10)] h-32 w-full items-center no-color">No background walls found</p>
                    }
                </ControlContent>
            </ControlBody>
        </ControlCard>
    )
}
export default memo(MultiBgControl);