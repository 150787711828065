import { removeDuplicateByElement } from "App/Helper/utilities";

const { initStore } = require("./store")
const configureStore = () => {
    const actions = {          
        updateAllCollectionData: (current, data) => {
            let existing = current?.allCollectionData ?? [];
            return {
                allCollectionData: {...existing, ...data}
            }
        },
        setCurrentGalleryAssets : (current, assets) => {
            let currentData = current?.currentGalleryAssets ?? [] ;
            const data = [...currentData, ...assets.data];
            const finalData = removeDuplicateByElement(data, 'asset');
            return {
                currentGalleryAssets: finalData
            }            
        },
        resetCurrentGalleryAssets : () => {
            return {
                currentGalleryAssets: []
            }            
        },
        updateCurrentGalleryAssetsLoadingStatus: (current,status) => {
            return {
                currentGalleryAssetsLoadingStatus: status
            }
        },
        setAllUserCollection : (current, assets) => {
            let currentData = current?.allUserCollection ?? [] ;
            const data = [...currentData, ...assets];
            const finalData = removeDuplicateByElement(data, 'asset');
            return {
                allUserCollection: finalData
            }            
        },
        setOverAllAssets : (current, assets) => {
            let currentData = current?.overAllAssets ?? [] ;
            const data = [...currentData, ...assets];
            const finalData = removeDuplicateByElement(data, 'asset');
            return {
                overAllAssets: finalData
            }            
        },
    }
    initStore(actions, {
        allCollectionData: { collection: [], nftDrop: [], artBank: [] },
        allUserCollection: [],
        currentGalleryAssets: [],
        overAllAssets: [],
        currentGalleryAssetsLoadingStatus: ''
    });
}
export default configureStore;