/* eslint-disable react-hooks/exhaustive-deps */
import styles from "../../../../SelectedCollection.module.scss"
import React, { useEffect, useState } from 'react';
import useFrames from "App/hooks/use-frames";
import NftZoomed from "App/Components/NftZoomed";
import CustomTooltip from "App/Components/Ui/CustomTooltip";

const NftRow = (props) => {
    const [frameDetails, setFrameDetails] = useState(null)
    const { getFrameFromStore } = useFrames();
    const [nftDetails, setNftDetails] = useState([])
    useEffect(() => {
        if (!!props.framesList)
            getFrameId()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.framesList])
    useEffect(() => {
        let values = []
        if (props.nft?.onchain_metadata) {
           let newVal= props?.tableHeader?.slice(1)?.map(val => props.nft?.onchain_metadata[val.name] || "")
            values=[...newVal]
        }
        setNftDetails(values)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.nft, props?.tableHeader])

    const getFrameId = () => {
        for (let frames of props.framesList) {
            if (frames.asset === props.nft.asset) {
                getFrame(frames.frameId)
            }
        }
    }
    const frameApiResult = (res) => {
        const data = res
        let isLandscape = data?.isLandscape ?? false;
        if (props?.framesList?.length) {
            let userFrame = props?.framesList.filter((x) => x.frameId === data?.id);
            if (userFrame.length) {
                isLandscape = userFrame[0]?.isLandscape ?? false;
            }
        }
        let temp = {
            name: data.name,
            image: isLandscape ? (data.s3ImageUrlLandscape ?? data.s3ImageUrl) : data.s3ImageUrl,
            type: data.category.name,
            style: data.style.name,
            color: data.color.name,
            features: data.addOn.name,
            complexity: data.complexity.name,
            metaInfo: isLandscape ? data.metaInfo?.landscape : data.metaInfo?.portrait
        }
        setFrameDetails(temp)
    }
    const getFrame = (frameId) => {
        getFrameFromStore(frameId, (res) => {
            frameApiResult(res)
        })
    }
    const ImageClickHandler = (e) => {
        setTimeout(() => {
            e?.target?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        }, 500)
        props.onImgClick({ nft: props.nft, frame: frameDetails })

    }

    const handleParameter = (val, key) => {
        const render = []
        Object.entries(val).forEach(([key, value])=>render.push(`Parameter/ ${key}:${value}`))
        let titleCaption = ''
        render?.map((item, i)=>titleCaption = titleCaption + item + `</br>`)
        return <td key={key}>
            <CustomTooltip parentClass={`w-[min-content]`} tooltipTitle={titleCaption}>
                <div className={`truncate max-w-[12rem]`}>
                    {render?.map((item, i) => <span key={i}>{item}</span>)}
                </div>
            </CustomTooltip>
        </td>
    }
    const handleArrayOfValues = (val, key) => {
        let titleCaption = ''
        val?.map((item, i)=>titleCaption = titleCaption + item + `</br>`)
        return <td key={key}>
            <CustomTooltip parentClass={`w-[min-content]`} tooltipTitle={titleCaption}>
                <div className={`truncate max-w-[12rem]`}>
                    {val?.map((item, i) => <span key={i}>{item}</span>)}
                </div>
            </CustomTooltip>
        </td>
    }

    return (
        <tr key={props.index}>

            <td className="">
            <div className="flex">
                    <div className={`${styles["nft-img"]} cursor-pointer`}>
                        {!!props.nft.nftcdnimage256 && <NftZoomed displayNft="list" className="col-art-full-img collection-table-image-nft" data-index={props.index} onClick={ImageClickHandler} nft={props.nft?.nftcdnimage256} frameDetails={frameDetails} />}
                    </div>
                        <div className="list-name-wid-name eclipse-wid" data-tooltip-content={props?.nft?.name} data-tooltip-id={`${props?.nft?.name}${props.index}`}>
                        {/* <CustomTooltip tooltipId={`${props?.nft?.name}${props.index}`} reactTooltip={true} /> */}
                            {props.nft.name}
                        </div>
                </div>
            </td>
            {nftDetails?.map((val,i) =>{
                if (typeof val !== 'string' && typeof val !== 'number' && !Array.isArray(val)) {
                    return handleParameter(val, i + 1)
                }
                if (Array.isArray(val)) {
                    return handleArrayOfValues(val, i + 1)
                }
                return <td key={i + 1}>
                    <CustomTooltip parentClass={`w-[min-content]`} tooltipTitle={val ?? ''}>
                        <div className={`truncate max-w-[12rem]`}>
                            {val ?? '-'}
                        </div>
                    </CustomTooltip>
                </td>
            })}
        </tr>
    )
}

export default NftRow
