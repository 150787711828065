import { useState } from "react";
import { Calendar } from "react-multi-date-picker";
import ImagePopup from "../ImagePopup";
import "./DatePickerFilter.scss"
import RoundedButton from "../RoundedButton";
import { useStore } from "App/hooks-store/store";

const RangeDatePicker = ({ disableDayPicker = false, setshowDate = () => { }, minDate, otherDate, disableMonthPicker = false, disableYearPicker = false, className, show, showTime, dateSelected, dateObject, onDateSelect }) => {

    const dispatch = useStore(false)[1];
    const [actualFormat, setActualFormat] = useState(null)
    const setSelectedDateRange = useState(new Date())[1];
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [values, setValues] = useState([])


    const dateChange = (e) => {
        if (e?.length === 2) {
            setActualFormat(e)
            const firstDate = e[0].format("DD/MM/YYYY");
            const lastDate = e[1].format("DD/MM/YYYY");
            if (firstDate === lastDate) {
                dispatch("showToast", {
                    toast: {
                        toastMode: "error",
                        message: "Please select different end date",
                    },
                });
                return
            } else {
                const selectedStartDate = new Date(`${e[0].month.number}/${e[0].day}/${e[0].year}`)
                selectedStartDate.setHours(23)
                selectedStartDate.setMinutes(59)
                // selectedStartDate.toLocaleString("en-US")
                
                const selectedEndDate = new Date(`${e[1].month.number}/${e[1].day}/${e[1].year}`)
                selectedEndDate.setHours(23)
                selectedEndDate.setMinutes(59)
                // selectedEndDate.toLocaleString("en-US")
                setStartDate(selectedStartDate)
                setEndDate(selectedEndDate)
                setValues(e)
            }
        }else{
            setValues(e)
        }
    }

    const onClose = () => {
        if (dateSelected) setSelectedDateRange(new Date(dateSelected))
        else setSelectedDateRange(new Date())
        setshowDate({ mode: false })
        setActualFormat(null)
    }
    const onSave = () => {
        if (values?.length === 2) {
            onDateSelect({ start: new Date(startDate).toISOString(), end: new Date(endDate).toISOString() })
            setshowDate({ mode: false })
        } else {
            dispatch("showToast", {
                toast: {
                    toastMode: "error",
                    message: "Please select start and end date",
                },
            });
            onDateSelect({ start: null, end: null })
        }
    }


    return (
        <ImagePopup show={show} cssClass={`date-desk ${className} ${(dateSelected || actualFormat) && "active"}`} onClose={onClose}>
            <div className='flex p-4'>
                <Calendar
                    dateSeparator={"to"}
                    minDate={new Date()}
                    value={values}
                    onChange={dateChange}
                    range
                >
                    <div className="w-full flex justify-center gap-2 px-[1.5rem] date-btn-wrapper mt-[0.5rem] mb-2">
                        <RoundedButton
                            className={"cancel-btn flex-1 btn-style"}
                            OnClick={onClose}
                            Name={"Cancel"}
                        ></RoundedButton>
                        <RoundedButton
                            disabled={!actualFormat}
                            className={`save-btn btn-style save flex-1 disabled:cursor-not-allowed`}
                            OnClick={onSave}
                            Name={'Confirm'}
                        ></RoundedButton>
                    </div>
                </Calendar>
            </div>
        </ImagePopup>)
}


export default RangeDatePicker