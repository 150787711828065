import { useNavigate } from "react-router-dom";
import storage from "App/Helper/storage"
import { useCallback } from "react";
import { destructStore } from "App/hooks-store/store";
import { useMsal } from '@azure/msal-react';
import API from "App/Helper/api";
import { setShowWalletPop } from "App/ReduxStore/Slicers/authSlice";
import { useDispatch } from "react-redux";

const { useStore,clearDB } = require("App/hooks-store/store");
//import { dispatch } from "d3";
const useAuth = () => {        
    const { instance } = useMsal();    
    let navigate = useNavigate();
    const store = storage();
    
    const [unusedstore,dispatch] = useStore();    
    const reduxDispatch = useDispatch()
    const token = store.get('token') ?? '';
    const isGuest = JSON.parse(store.get('isGuest')) ?? '';
    const clearToken = useCallback(async () => {
        reduxDispatch(setShowWalletPop(false));
        store.clearAll();
        await clearDB();
        destructStore();
        await instance.logout();        
        navigate("/home");
    }, [navigate, store,reduxDispatch])
    const setToken = useCallback((token) => {
        store.set('token', token);
    }, [store])
    const setUser = useCallback((isGuest) => {
        store.set('isGuest', isGuest);
    }, [store])
    const deleteStore = useCallback((storageName) => {
        store.remove(storageName);
    }, [store])
    const handleB2CLogin = useCallback(async (silent) => {
        try {            
            console.log("msal init")
            await instance.initialize(); 

            var loginrequest = {                      
                popupWindowAttributes: {
                    popupSize: {
                        height: 750,
                        width: 450
                    }
                },        
                extraScopesToConsent: [`https://${process.env.REACT_APP_B2C_AUTHORITY_NAME}/${process.env.REACT_APP_B2C_API_CLIENT_ID}/${process.env.REACT_APP_B2C_API_PERMISSION}`]               
            };
            
            if(silent===true)
            {
                console.log("msal ssoSilent")
                let result = await instance.ssoSilent(loginrequest);         
            }
            else
            {
                console.log("msal loginpopup")
                await instance.loginPopup(loginrequest);            
            }
            
            let accounts = instance.getAllAccounts();                        
            if (accounts?.length > 0)
            {            
                instance.setActiveAccount(accounts[0]);                
                dispatch("updateUsername", accounts[0].username);
                var accessrequest = {
                    scopes: [ `https://${process.env.REACT_APP_B2C_AUTHORITY_NAME}/${process.env.REACT_APP_B2C_API_CLIENT_ID}/${process.env.REACT_APP_B2C_API_PERMISSION}` ]                
                }                
                let newtoken = await instance.acquireTokenSilent(accessrequest);                                                    
                setToken(newtoken.accessToken);   
                const res = await API({
                    url: `user/sign-in-b2c`, method: 'post'
                });
                setUser(false)                
                if (res?.data?.data?.isFirstSignIn)
                navigate("/profile/wallet-authentication")
                else
                window.location.reload()
            }
        } catch (error) {
            console.error(error);
        }
    })

    return [(!!token && !isGuest), clearToken, setToken, setUser, !!isGuest, deleteStore, handleB2CLogin]
}
export default useAuth;