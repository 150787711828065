// import { useStore } from "App/hooks-store/store";
import useScreenSize from "App/hooks/use-screen-size";
import { useEffect, useState } from "react";
import { EventsCard } from "App/Components/Ui/EventCard";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";

const EventList = ({
  data,
  notFull,
  eventLoader,
  editHandler,
  // page,
  deleteHandler,
}) => {
  // const [{ communityDetails }] = useStore(false);
  const screenWidth = useScreenSize()?.width;
  // const [selectedEvent, setSelectedEvent] = useState(null);
  const [pastEvents, setPastEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  // const handleEventClick = (e) => {
  //   let id = parseInt(e.currentTarget?.dataset?.id);
  //   if (id !== selectedEvent?.id) {
  //     let selected = data?.find((item) => item?.id === id);
  //     setSelectedEvent(selected);
  //   } else {
  //     setSelectedEvent(null);
  //   }
  // };
  useEffect(() => {
    if (!notFull) {
      const { pastEvents, upcomingEvents } = data;
      setPastEvents(pastEvents);
      setUpcomingEvents(upcomingEvents);
    } else {
      setUpcomingEvents(data);
    }
  }, [data, notFull]);
  return (
    <>
      {!notFull && (
        <div className=" text-left  pt-[1rem] pl-1">
          <p>
            Upcoming Events{" "}
            <span className="text-[#606573]">({upcomingEvents?.length})</span>
          </p>
        </div>
      )}
      {eventLoader ? (
        <div className="event-skeleton-wrap">
          <DetailsSkeltonCard
            show={true}
            height={"100%"}
            count={screenWidth > 1620 ? 4 : 3}
            inline={true}
            noAbsolute
            containerClassName={` flex pt-[1rem]`}
          />
        </div>
      ) : upcomingEvents?.length ? (
        <div className="event-grid sm:py-4 py-2  gap-2">
          {upcomingEvents?.map((event, index) => {
            return (
              <EventsCard
                data={event}
                fromCommunity={true}
                key={index}
                editHandler={editHandler}
                deleteHandler={deleteHandler}
                // selectedCard={props?.selectedEvent}
                // community={matchedCommunity}
              />
            );
          })}
        </div>
      ) : (
        <div className="h-[17rem] flex flex-col justify-center">
          <p className="text-[#606573]">{`${
            notFull ? `There are currently no events.` : `No upcoming events.`
          }`}</p>
        </div>
      )}
      {!notFull && (
        <div className=" text-left  sm:pt-2 pl-1">
          <p>
            Past Events{" "}
            <span className="text-[#606573]">({pastEvents?.length})</span>
          </p>
        </div>
      )}
      {!notFull && (
        <>
          {eventLoader ? (
            <div className="event-skeleton-wrap">
              <DetailsSkeltonCard
                show={true}
                height={"100%"}
                count={screenWidth > 1620 ? 4 : 3}
                inline={true}
                noAbsolute
                containerClassName={` flex pt-[1rem]`}
              />
            </div>
          ) : pastEvents?.length ? (
            <div className="event-grid sm:py-4 py-2  gap-2">
              {pastEvents?.map((event, index) => {
                return (
                  <EventsCard
                    data={event}
                    fromCommunity={true}
                    key={index}
                    // selectedCard={props?.selectedEvent}
                    // community={matchedCommunity}
                  />
                );
              })}
            </div>
          ) : (
            <div className="h-[17rem] flex flex-col justify-center">
              <p className="text-[#606573]">No past events</p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EventList;
