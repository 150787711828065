/* eslint-disable react-hooks/exhaustive-deps */
import NftRow from "./Components/NftRow"
import { useMemo, useState } from "react"
import { useEffect } from "react"
import CustomTable from "App/Components/Ui/CustomTable";
import { useStore } from "App/hooks-store/store";
import '../../../NftTableDisplay/NftTableDisplay.scss';
import useHttp from "App/hooks/use-http";
const SelectedNftTableDisplay = props => {
    // eslint-disable-next-line no-unused-vars
    const [arrwFlip, setArrowFlip] = useState({sortName: 'Name A-Z', flip: false})
    const [nftDetails, setNftDetails] = useState({})
    const { triggerAPI } = useHttp();
    const tempRows = [
        { row: "priceSource", title: "Price Source" },
        { row: "value", title: "Estimated Value" },
        { row: "color", title: "Color" },
        { row: "circle", title: "Circle" },
        { row: "complexity", title: "Complexity" },
    ]
    const [selectedRows, setSelectedRows] = useState([])
    // eslint-disable-next-line no-empty-pattern
    const [{ }, dispatch] = useStore();
    useEffect(() => {
        tableRowFilter()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getNFTResult = (res) => {
        dispatch('hideSpinner')
        setNftDetails(res?.data)

    }
    const getNFTDetails = (asset, networkType) => {
        dispatch('showSpinner')
        const data = new URLSearchParams({
            asset: asset,
            networkType: networkType
        })
        triggerAPI({
            url: `user/nft-collection/asset/detail?${data}`, method: 'get'
        }, getNFTResult);
    }

    useEffect(() => {
        if (props.nfts?.list?.length) {
            getNFTDetails(props.nfts?.list[0]?.asset, props.nfts?.list[0]?.networkType)
        }
    }, [props.nfts?.list?.asset])

    const tableHeader = useMemo(() => {
        let title = [{ name: 'Asset', isSort: true, sortName: 'Name Z-A' }]
        if (nftDetails?.onchain_metadata) {
            Object.entries(nftDetails?.onchain_metadata)?.length && Object.entries(nftDetails?.onchain_metadata)?.forEach(([key, value],i) => {
                if (typeof value === "string" && key !== "image"&&value !== "N/A"&&!value.includes("ipfs")&&!value.includes("www.")&&!key.includes("--") &&!value.includes("https://")&&!value.includes(".com") &&key !== "name" && key !== "mediaType"&&title?.length<6) {
                    title.push({ name: key })
                }
            })
        }
        return title
    }, [nftDetails?.asset])

    const tableRowFilter = (params) => {
        let temp = tempRows
        let filteredRows = []
        for (let items of props.nfts.list) {
            for (let row of temp) {
                if (!!items[row.row]) {
                    temp.splice(temp.indexOf(row), 1)
                    filteredRows.push(row)
                }
            }
        }
        setSelectedRows(filteredRows)
    }
    useEffect(() => {
        dispatch('enableTopIcons', {
            frame: false,
            caption: false,
            search: true,
            sorting: false,
        })
        return () => {
            dispatch('enableTopIcons', {
                frame: false,
                caption: false,
                favourite: false
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const sortByHandler = (e) => {
        let sortBy = e.currentTarget.dataset["sortby"]
        if (!arrwFlip?.flip) {
            props.onReverse()
            setArrowFlip({sortName: 'Name Z-A', flip: true})
        }
        else {
            setArrowFlip({sortName: 'Name A-Z', flip: false})
            props.onSort(sortBy)
        }
    }
    return (
        <CustomTable tableHead={tableHeader} mainContainerClass={'collectionTable'}  activeSort={arrwFlip} onClick={sortByHandler}  >
            {
                props.nfts.list && props.nfts?.list.map((nft, index) =>
                    <NftRow
                        key={nft.asset}
                        tableHeader={tableHeader}
                        noClick={props?.noClick}
                        onImgClick={props?.onImgClick} index={index}
                        framesList={props.framesList} nft={nft}
                        selectedRows={selectedRows}
                    />
                )
            }
        </CustomTable>
    )
}

export default SelectedNftTableDisplay