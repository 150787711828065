import React from 'react'
import ReactECharts from 'echarts-for-react';
import { useState, useEffect } from "react";
import * as echarts from 'echarts';

const CommonEChart = ({ apiData, type, chartHeader, label }) => {
    const [chartOptions, setChartOptions] = useState(null);
    
    const bindChartData = (apiData) => {
        const date = []
        const data = [];
        apiData?.map((item)=>{
            date?.push(item?.onlyDate)
            if(label === 'Floor'){   
                data?.push(item?.floorPrice)
            }
            if(label === 'Owners'){   
                data?.push(item?.owners)
            }
            if(label === 'Listings'){   
                data?.push(item?.listings)
            }
            return item
        })
        const option = {
            xAxis: {
                type: 'category',
                data: [...date],
                boundaryGap: false,
                axisLine: {
                    onZero: false,
                    lineStyle: { color: "#64748B" }
                },
            },
            tooltip: {
                trigger: 'axis',
                //trigger: 'item', //needed to custom format tooltip
                axisPointer: {
                    type: 'line'
                },

                formatter: function (params) {
                    let rez = '<p>' + params[0].axisValue + '</p>';
                    rez += `<p>${params[0].data}</p>`;

                    return rez;
                }
            },
            yAxis: {
                type: 'value',
                splitNumber: 4,
                scale: true,
                offset: 0,
                splitArea: {
                    show: false
                },
                splitLine: {
                    show: false,
                    // lineStyle:
                    //     { color: '#2e343e' }
                },
                axisLabel: {
                    color: "#64748B"
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#D0D5DD'
                    }
                },
                min: 0,
            },
            series: [
                {
                    data: [...data],
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                    itemStyle: {
                        color: '#64748B',
                    }
                }
            ]
        };
        setChartOptions(option);
    }
    useEffect(() => {
        bindChartData(apiData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiData])
    return (
        <> {chartOptions && <div className={`border  border-[var(--cu-black-50)] bg-[var(--cu-black-500)]  rounded-xl`}>
            <div className="flex border-b border-b-gray-700 p-[1rem]">
                <p>{chartHeader}</p>
            </div>
            {apiData?.length ? <div className={`h-[40dvh] m-[1rem] relative`}>
            <span className={`absolute -rotate-90 top-1/2 transform  -translate-y-1/2 text-[#64748B] -left-4`}>{label}</span>
                <ReactECharts
                    style={{
                        height: '100%',
                    }}
                    option={chartOptions}
                />
            </div> : <div className="py-[1rem] h-[15dvh] flex justify-center items-center">{`No data available`}</div>}
        </div>}
        </>)
}

export default CommonEChart