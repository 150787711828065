import NftFrame from "App/Components/NftFrame";
import EnLargeFrameList from "../EnLargeFrameList";
import useScreenSize from "App/hooks/use-screen-size";
const EnLargeSelectedAsset = ({currentAssetLoading, currentAsset, showPanel, frameApplied, filteredFrames, applyFrameHandler, onFrameSelect}) => {
    const screenSize = useScreenSize()?.width;
    
    return <div className={`active-card flex items-center ${!showPanel && 'in-active'} relative  py-[.25rem]`}>
        <div className={`${!currentAssetLoading ? 'opacity-1' : 'opacity-0'} active-card ${!showPanel && 'in-active'} w-full h-full`}>  
            {!currentAssetLoading && (!!currentAsset ?
            (<>  
                      
                <NftFrame
                     skeltonClass='flex h-full w-full'                                                        
                    className={`selected-nft-cards-collection inner-nft`}                            
                    isDetailView='true'
                    frameDetails={frameApplied}                
                    nft={currentAsset.nftcdnimage}    
                    imageFile={currentAsset.files}             
                />
               
            </>)
                :
                <p className="flex-1"></p>
            )}
              </div> 
            <EnLargeFrameList
                filteredFrames={filteredFrames}
                frameApplied={frameApplied}
                currentAsset={currentAsset}
                applyFrameHandler={applyFrameHandler}
                onFrameSelect={onFrameSelect}
            />
        </div>
}

 export default EnLargeSelectedAsset;