import styles from '../../FrameFilter.module.scss'
import { useEffect, useState } from 'react'
import NftZoomed from 'App/Components/NftZoomed'
import Accordion from 'App/Components/Ui/Accordion'
import AccordionExpander from 'App/Components/Ui/Accordion/AccordionExpander'
import dropDown from "Assets/Icons/TopMenu/dropdown-icon-frames.svg"
import search from "Assets/Icons/TopMenu/search-icon-frames.svg"
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import { useStore } from "App/hooks-store/store"
import './SearchPanel.scss';

const SearchPanel = props => {
    const [nftList, setNftList] = useState([])
    const [expand, setExpand] = useState(false)    
    const [{collectionLoadingStatus}] = useStore();

    useEffect(() => {
        searchNft();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchValue, props.nfts])
    const searchNft = () => {
        let temp = []
        if (!!props.nfts && props.searchValue?.length >= 3)
        {
            for (let items of props.nfts) {
                let name = items.name?.toLowerCase()
                let collectionName = items.collectionName?.toLowerCase()
                if ((name && name.includes(props.searchValue.toLowerCase())) || 
                    (collectionName && collectionName.includes(props.searchValue.toLowerCase()))) {                            
                    temp.push(items)
                }
            }            
        }
        setNftList(temp)                                 
    }
    const selectNftHandler = (e) => {
        let nft = nftList.filter(n => n.asset == e.currentTarget.dataset["asset"])?.[0]??{};
        props.onSelectNft({ ...nft, img: e.currentTarget.dataset["src"], asset: e.currentTarget.dataset["asset"] })
    }
    const toggleAccordian = () => {
        setExpand((prev) => !prev)
    }
    
    const toggleList = () => {
        props?.setIsCollection(!props?.isCollection);
    }
    return    <Accordion>
    <div className="expander text-left cursor-pointer fs-nft">
        <div onClick={toggleAccordian} className={` accordion-arrow ${expand  ? 'accordion-arrow-expanded': 'accordion-arrow-collapsed'}`}>Image</div>
        {
            <AccordionExpander expand={expand} scroll={false}>
                <div className={`${styles["search-panel"]} flex flex-col gap-2.5`}>
                    {/* <div className='search-input-container'>
                        <input onFocus={props?.searchFramesHandler} onChange={props?.searchFramesHandler} value={props?.searchValue} placeholder="The Art Bank" className={`${styles["filter-search"]}`} />
                    </div> */}
                    <div className="search-input-container relative" >
                        
                                {/* <div className="search-icon-search cursor-pointer">
                                    <img alt="NoImg" className="h-[1rem]" src={dropDown} onClick={toggleList} />
                                </div> */}
                                <input type="text" value={props?.searchValue} placeholder={props?.isCollection ? 'Search Collection' : 'The Art Bank'} className={'text-indented'} onClick={() =>props?.setSearchStatus(true)} onBlur={() =>props?.setSearchStatus(false)} onChange={props?.searchFramesHandler} onKeyUp={props?.searchFramesHandler}/>
                                
                                <div className="search-icon-close cursor-pointer" data-id="close" onClick={props?.onClick}>
                                    <img className="h-[1rem]" alt="NoImg" src={search} />
                                </div>
                    </div>
                    {collectionLoadingStatus!='in-progress'&& <div className={`${styles["search-result"]}`}>                        
                        {nftList?.map((nft, index) => (<div key={nft.asset} className={`${styles["nft-box"]} ${((props.selectedNft?.asset === nft.asset) && styles["selected-nft"])}`} data-src={nft.nftcdnimage256} data-asset={nft.asset} onClick={selectNftHandler}>
                            <NftZoomed nft={nft.nftcdnimage256} noScrolls={true} />
                        </div>))}
                    </div>}
                    {(collectionLoadingStatus=='in-progress' || props.loading) && <SkeletonLoader height={18} count={1} inline={true}  />
                    }
                </div>
            </AccordionExpander>
        }
    </div>
</Accordion>
}

export default SearchPanel