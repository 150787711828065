import React from 'react'
import warning from "Assets/Icons/warning.svg";
import "./SubscriptionWarning.scss";
import { useNavigate } from 'react-router-dom';

const SubscriptionWarning = ({isSubscriptionReached,content}) => {
    const navigate = useNavigate();
    const onUpgradeHandler=()=>{
       navigate("/profile/subscription")
    }
    return (
            <div className={"flex warning-wrap"}>
                <div className="flex items-start mt-1">
              <span className={`rotate-180`}>
                <img alt="" src={warning} />
              </span>
            </div>
                {isSubscriptionReached?<p>You have reached the maximum number of {`${content}`}.</p>:<p className={""}>You have reached the maximum number of {`${content}`}.<br/> Please <span className='upgrade-link' onClick={onUpgradeHandler}>upgrade</span> your plan to add more.</p>}
            </div>
    )
}

export default SubscriptionWarning