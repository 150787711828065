import styles from './NftFrame.module.scss'
import './NftFrame.scss'
import React, { createRef, memo, useCallback, useEffect, useReducer, useState } from "react";
import NftViewer from './Components/NftViewer';
import { getMobileOperatingSystem } from 'App/Helper/utilities';
import SkeltonCard from '../Ui/SkeltonCard';
import useResizeObserver from '@react-hook/resize-observer';
import useShapes from "App/hooks/use-shapes";
import FrameViewer from './Components/FrameViewer';

const imageReducer = (state, action) => {
    switch (action.type) {
        case 'frame':
            return { ...state, frame: false };
        case 'image':
            return { ...state, image: false };
        default:
            return { frame: true, image: true };
    }
}
const imagesLoader = {
    frame: true,
    image: true
}
const NftFrame = (props) => {
    const containerRef = createRef();
    const [frameURL, setFrameURL] = useState(null);
    const [loadedFrameURL, setLoadedFrameURL] = useState(null);
    const [reload, setReload] = useState(true)
    const [count, setCount] = useState(0)
    const [imagesLoaded, stateImagesLoaded] = useReducer(imageReducer, imagesLoader)
    const [isMac,setIsMac] = useState(false);
    const [frameRatio,setFrameRatio] = useState(null);
    const [imageRatio,setImageRatio] = useState(null);
    const [frameWidth,setFrameWidth] = useState("100%");
    const [frameHeight,setFrameHeight] = useState("100%");          
    const [frameIsSized,setFrameIsSized] = useState(false);   
    const { saveCollectionShape } = useShapes();

    useEffect(() => {
        if (!reload && count < 2) {
            setCount(prevState => prevState + 1)
            setReload(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])    
    useEffect(() => {
        stateImagesLoaded({ type: 'reset' })
        if (frameURL !== loadedFrameURL)
        {
            setFrameIsSized(false)
        } 
        else
        {
            //if we already loaded the frame, don't reset its loading state, or it will never finish loading
            stateImagesLoaded({ type: 'frame' }) 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nft])
    const onClickHandler = (e) => {
        if (props.onClick)
            props.onClick(e, props.frameDet)
    }
    const applyFrameStyle = useCallback(() => {
        if(props.isDetailView){
            const os = getMobileOperatingSystem();
            if(os === 'iOS' || os === 'Safari'){
                setIsMac(true)
            }
        }
    },[props.isDetailView])
    const frameLoaded = useCallback((e) => {
        if(frameURL) {
            applyFrameStyle();
            setTimeout(() => {
                stateImagesLoaded({ type: 'frame' })  
                setLoadedFrameURL(frameURL);
                if (!props.nft)
                {
                    stateImagesLoaded({ type: 'image' })  
                }                      
                if (props?.onFrameLoaded) {
                    props?.onFrameLoaded()
    
                }
                if (e?.target)
                {
                    setFrameRatio(e.target.naturalWidth/e.target.naturalHeight);
                    
                }
            });
        } else {
            stateImagesLoaded({ type: 'frame' })  
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frameURL])
    const imageLoaded = useCallback((e) => {
        setTimeout(() => {
            stateImagesLoaded({ type: 'image' })                        
            if (props?.setImageLoaded) {
                props?.setImageLoaded(false)
   
            }
            if (e?.target)
            {                
                let ratio = e.target.naturalWidth/e.target.naturalHeight
                setImageRatio(ratio);
                if (props?.shapeCollectionId)
                {                             
                    saveCollectionShape(props?.shapeCollectionId, ratio);
                }              
                if (props?.imageRatioCalculated)  
                {
                    props?.imageRatioCalculated(ratio, props?.asset);
                }
            }
            //if (!props.frameDetails) {
                //frameLoaded()
            //}
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const imgErrorHandler = (params) => {
        setReload(false)
    }
    useEffect(() => {
        if (!props.frameDetails && !props.frame)
        {
            setFrameURL(null)
            frameLoaded()
        }
        else
        {
            calcFrame();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyFrameStyle, props.frameDetails, props.isDetailView, props.frame]);

    const calcFrame = (width, height) => {        
        let frametouse = props.frame??props.frameDetails?.image;
        if (height)
        {
            if (height <= 512 && height > 256 && props.frameDetails?.image512)
            {
                frametouse = props.frameDetails?.image512;
            }
            else if (height <= 256 && props.frameDetails?.image256)
            {
                frametouse = props.frameDetails?.image256;
            }
        }        

        // if (props.isLandscape && props.frameDetails?.s3ImageUrlLandscape)
        // {            
        //     frametouse = props.frameDetails?.s3ImageUrlLandscape;
        // }        
        
        setFrameURL(frametouse)        
        applyFrameStyle();
    };
    
    useResizeObserver(containerRef, (entry) => {        
        updateSizes(entry.contentRect.width, entry.contentRect.height);
    })    

    const updateSizes = (width, height) => {
        if (width !== 0 && height !== 0) {
            if (props.frame) {
                //if the frame image is provided, its the frame chooser, which has no correct landscape metadata
                //if there is no frame, just set the image to fill
                setFrameWidth("100%");
                setFrameHeight("100%");
            } else {
                let tempContainer = (width / height);
                const isFrameOrImageRatioOne = Math.round(frameRatio * 100) / 100 === 1 || Math.round(imageRatio * 100) / 100 === 1;
    
                if (isFrameOrImageRatioOne) {
                    if (Math.round(tempContainer * 100) / 100 >= 1) {
                        setFrameWidth("auto");
                        setFrameHeight("100%");
                    } else {
                        setFrameWidth("100%");
                        setFrameHeight("auto");
                    }
                } else if (frameRatio || imageRatio) {
                    const isWithinTolerance = ratio => ratio > 0.98 && ratio < 1.02;
                    ////hack to stop repeated resizing, if the container is within tolerance (2%), then just constrain the height, rather than switching every frame
                    if (!isWithinTolerance(tempContainer / (frameRatio || imageRatio))) {
                        if (tempContainer >= (frameRatio || imageRatio)) {
                            //wider, therefore height is constrained
                            setFrameWidth("auto");
                            setFrameHeight("100%");
                        } else {
                            //narrower, therefore width is constrained
                            setFrameWidth("100%");
                            setFrameHeight("auto");
                        }
                    }
                }
            }
    
            if (props.frameDetails) 
                calcFrame(width, height);
    
            setTimeout(() => {
                setFrameIsSized(true);
            }, 500);
        }
    
        if (props?.type === "frame-option" && frameRatio > 1) 
            setFrameHeight("auto");
    };
    

    useEffect(() => {
        updateSizes(containerRef.current.clientWidth, containerRef.current.clientHeight);                
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nft, props?.onChainReRender, props.frameDetails, containerRef.current]); 
    
    const getClassStyles = () => {
        let ratio = frameRatio??imageRatio;

        let frameimagegapratio = (props?.frameDetails?.metaInfo?.image?.width/props?.frameDetails?.metaInfo?.image?.height)??frameRatio;        
        return (ratio>1?styles['landscape']:ratio===1?styles['square']:styles['portrait'])
        + " " + (frameimagegapratio<imageRatio?styles['fillheight']:styles['fillwidth'])
        + " " + (!frameURL?styles['bareimage']:'');
    }

    const getClassNames = () => {
        let ratio = frameRatio??imageRatio;
        
        let classnames = (ratio>1?'landscape':ratio===1?'square':'portrait');
                        
        return classnames;
    }

    const isNonImage = () => {
        return props?.imageFile?.length > 0 && props.imageFile[0]?.mType!=="image";
    }

    useEffect(() => {
        if (isNonImage())
        {
            if (props?.imageRatioCalculated)  
            {
                props?.imageRatioCalculated(1,props?.asset);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.imageFile])
    return <div ref={containerRef} className={`${(props?.useFrameContainer2 && !(frameRatio<1)) ? styles["nft-frame-container2"] : styles["nft-frame-container"]} ${!frameURL ? styles["no-frame-container"] : ''} ${!frameURL && isNonImage() ? styles["nonimage-max-size"] : ''} ${(!frameIsSized || imagesLoaded.image || (imagesLoaded.frame && frameURL)) && !isNonImage() && 'skelton-loading'} nft-frame-container`}>    
                <div className={styles["skelcontainer"]}><SkeltonCard show={(!frameIsSized || ((imagesLoaded.frame && imagesLoaded.image) && frameURL)) && !isNonImage()} containerClassName={`nftframeskel`} height="100%" inline={true}/></div>
                    <div data-index={`${props["data-index"]}`} onClick={onClickHandler} 
                    style={{height:props?.frameDetailsStyles ? '' : frameHeight, width: props?.useFrameContainer2  ? '100%' : frameWidth, margin:'auto', aspectRatio: props.frameDetails?.metaInfo?.frame?.aspectRatio?.replace(':','/')??`${imageRatio} / 1`}} 
                    className={`${styles["nft-frame-viewer"]} ${getClassStyles()} ${getClassNames()} ${(!frameIsSized || ((imagesLoaded.frame && imagesLoaded.image) && frameURL)) && !isNonImage() ? `${props?.skeltonClass} skelton-loading-nft-frame` : `skelton-loaded ${props?.className}`}`}>                        
                        {(frameURL) &&  <FrameViewer mainClass={`pointer-events-none h-full flex ${props?.imageContainerClass} ${styles["framediv"]}`}
                                            className={`${styles["frame"]} nftListing-image ${isMac ? styles["frame-detail-page"] : ''} nftimage`} 
                                            frameLoaded={frameLoaded} 
                                            frameURL={frameURL} maxHeight={props?.maxHeight}
                                            onChainReRender={props?.onChainReRender} />}
                        {reload && <NftViewer imageIsLoading={imagesLoaded.image && !isNonImage()} nftImageClass={props?.nftImageClass} 
                                        imageOnLoad={imageLoaded} isDetailView={props.isDetailView} 
                                        imgErrorHandler={imgErrorHandler} nft={props.nft} showFileType={props?.showFileType}
                                        nftImage={props?.nftImage} imageFile={props?.imageFile} 
                                        dimensions={props?.frameDetails?.metaInfo?.image??(props.isLandscape?props?.frameDetails?.metaInfo?.landscape?.image:props?.frameDetails?.metaInfo?.portrait?.image)}
                                        onChainReRender={props?.onChainReRender}
                                        playAudio={props?.playAudio}
                                        />}        
                </div>
            </div>
}

export default memo(NftFrame)
