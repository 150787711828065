import React, {  useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import WalletSelectPop from 'App/Components/Ui/WalletSelectPop'
import useAuth from 'App/hooks/use-auth'
import { useStore } from 'App/hooks-store/store'
import { useDispatch, useSelector } from 'react-redux'
import { getLoggingOutStatus, getShowWalletPop ,setShowWalletPop} from 'App/ReduxStore/Slicers/authSlice'

const Collections = () => {
    const [{ currentWallet,collectionWalletData,collectionLoadingStatus }, dispatch] = useStore(false);
    const isLoggedIn = useAuth()[0]
    const reduxDispatch = useDispatch();
    const showAuthPop = useSelector(getShowWalletPop);
    const loggingOutStatus=useSelector(getLoggingOutStatus)

    useEffect(()=>{
        if(!loggingOutStatus) reduxDispatch(setShowWalletPop((!isLoggedIn || (collectionWalletData !== null && !collectionWalletData?.length))&&collectionLoadingStatus !== "in-progress"))
            setTimeout(()=>{
                dispatch('setTopMenuBarOptions', {showMenuIcons: isLoggedIn&&collectionWalletData?.length, showTopMenus: false})
               },1)
           
           
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loggingOutStatus,isLoggedIn,collectionWalletData,collectionLoadingStatus])
    
    useEffect(() => {
        if(currentWallet?.address) {
            reduxDispatch(setShowWalletPop(false))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentWallet?.address])
    useEffect(() => {
        dispatch('setTopMenuBarOptions', {showMenuIcons: true, showTopMenus: false})
        dispatch('setActiveIcons' , {search: ''})
        return () => {
            dispatch('resetAll')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <div className='h-full'>
            {/* <AuthPop show={showAuthPop} onTrialClick={handleTrialClick} onClose={setShowAuthPop} /> */}
            {showAuthPop && <WalletSelectPop show={showAuthPop} />}
            <Outlet />
        </div>
    )
}

export default Collections
