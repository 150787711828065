import storage from "App/Helper/storage";
import { deepCopy, getMetadata } from "App/Helper/utilities";

const { useStore } = require("App/hooks-store/store");

const useCollectionLoader = () => {
    const [store, dispatch] = useStore();
    const { collections, tokens } = store
            
    const sliceCollectionAPI = (assetList, collectionsetfunction="setCollections", loadingstatusfunction="updateCollectionLoadingStatus", type = '') => { 

            const triggerFetch = async () => {
                
            let assetids = [...assetList];
            if (collectionsetfunction !== "setSingleCollection" && collectionsetfunction !== "setCurrentGalleryAssets")
            { 
                let collectionData = collections ? [...collections] : [];
                if(type === 'token')
                    collectionData = tokens ? [...tokens] : [];
                //to eliminate existing assetid
                // eslint-disable-next-line array-callback-return
                const eliminated = [...assetList]?.filter(item => {
                    let temp = collectionData?.find(aData => aData.asset === item.asset)
                    if(!temp)
                        return item;
                })   
                assetids = eliminated;
            }
            // eslint-disable-next-line no-unused-vars
            const newAssetList = assetids?.map(item => { return {asset_name: item?.asset_name, policy_id: item?.policy_id}}) // for new API
            if (newAssetList?.length> 0)
            {
                try {
                    await getImageDetailsChunked({ newAssetList, collectionsetfunction, loadingstatusfunction, assetList });    
                } catch (error) {
                    console.log("Error in getting collection chunks")
                    console.log(error)
                }
            }
            
            dispatch(loadingstatusfunction, 'finished')  
        }
        triggerFetch()
    }

    const getImageDetailsChunked = async ({newAssetList, collectionsetfunction, loadingstatusfunction, assetList = []}) => {                
        const data = {            
            assetids: newAssetList
        }

        if (newAssetList?.length > 0)
        {
            const token = storage().get('token')
            let headers = {}
            if(token)
            headers = {
                Authorization: `Bearer ${token}`,
                isb2c: true
            }
    
            headers['Content-Type'] = 'application/json';
    
            const requestOptions = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data)
            };
            
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/nft-collection/asset/detail?networkType=MAINNET&blockchainTypeId=1`, requestOptions);    
            
            const reader = response.body.getReader();

            dispatch(loadingstatusfunction, 'in-progress')

            let onlycommas = new RegExp(
                '^[,]+$'
            );
            const decoder = new TextDecoder();
            let chunks = [];
            let collectionretreived = [];
            let lastupdate = new Date().getTime();
            while (true) {
                const {done, value} = await reader.read();                                    
                if (value)
                {                                       
                    let str = decoder.decode(value);                    
                    try {                           
                        if (str !== "" && !onlycommas.test(str))
                        {                               
                            chunks.push(str);

                            //test for valid collection
                            try {
                                str = chunks.join('');
                                if (str.startsWith(","))
                                {
                                    str=str.substring(1);
                                }
                                
                                if (!str.startsWith("["))
                                {
                                    str = '[' + str;    
                                }
                                if (!str.endsWith("]"))
                                {
                                    str = str + ']';                        
                                }
                                
                                let col = JSON.parse(str);
                                
                                let colmeta = col.map(x => {
                                    if(assetList?.length) {
                                        x.walletId = assetList?.find(assetData=> x.asset === assetData?.asset)?.walletId;
                                    }
                                    return getMetadata(x, 'isFavouriteNFTCollection')
                                })
                                chunks = [];
                                collectionretreived = collectionretreived.concat(colmeta);
                                //append items into collection
                                //dispatch("setCollections", { loading: false, data: colmeta, count: 0 })                                  
                            } catch (error) {
                                if (!error.message.startsWith("Unterminated string in JSON") 
                                    && !error.message.startsWith("Unexpected token")
                                    && !error.message.startsWith("Expected ',' or '}'"))
                                {
                                    console.error(error);
                                }                                
                                //wasn't valid for parsing, so let the next loop add another chunk and retry
                                //console.log("Not valid for parsing yet...")
                            }
                            

                                                                                    
                        }
                        else
                        {
                            console.log("empty str");
                        }
                    } catch (error) {
                        console.log("ERROR parsing json" + error)
                        console.log(str)
                    } 
                    
                }                
                
                //code to only update the UI every 500ms, as otherwise the max update depth is exceeded
                if (lastupdate < new Date().getTime()-500)
                {
                    lastupdate = new Date().getTime();
                    dispatch(collectionsetfunction, { loading: true, data: collectionretreived, count: 0 }) 
                    dispatch('setOverAllAssets', deepCopy(collectionretreived))
                    collectionretreived = [];
                }
                if (done) {
                    //append items into collection
                    dispatch(collectionsetfunction, { loading: false, data: collectionretreived, count: 0 })
                    dispatch('setOverAllAssets', deepCopy(collectionretreived))
                    break;                
                }                
            }
            dispatch(loadingstatusfunction, 'finished')                            
        }
        else 
        {            
            console.log("ERROR - convert call to getImageDetails to use assetids")
        }
    }
    

    const sliceArtBankAPI = (assetList) => {
        sliceCollectionAPI(assetList, "setArtBank", "updateArtBankLoadingStatus");        
    }

    const sliceSingleCollectionAPI = (assetList) => {
        sliceCollectionAPI(assetList, "setSingleCollection", "updateSingleCollectionLoadingStatus");    
    }

    const sliceTokenAPI = (assetList) => {
        sliceCollectionAPI(assetList, "setTokenCollection", "updateTokenLoadingStatus", 'token');        
    }
    
    return {          
        sliceCollectionAPI,
        sliceArtBankAPI,
        sliceSingleCollectionAPI,        
        sliceTokenAPI
    }
}
export default useCollectionLoader;