import NftFrame from "App/Components/NftFrame";
import { useStore } from "App/hooks-store/store";
import useFrames from "App/hooks/use-frames";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "../../NewFrames.module.scss";
import Tabs from "App/Components/Ui/Tabs";
import close from "Assets/Images/close-circle-icon.svg";
import zoomIcon from "Assets/Images/maximize-icon.svg";
import zoomOutIcon from "Assets/Images/maximize-icon.svg";
import CustomBtn from "App/Components/Ui/CustomBtn";
import useHttp from "App/hooks/use-http";
import SelectBox from "App/Components/Ui/SelectBox";
import { convertJsonToArray, convertPriceToAda } from "App/Helper/utilities";
import Spinner from "App/Components/Ui/Spinner";
import SkeltonCard from "App/Components/Ui/SkeltonCard";
import useAuth from "App/hooks/use-auth";
import adaIcon from "Assets/Icons/ada-icon.png";
import useScreenSize from "App/hooks/use-screen-size";
import FrameDisplay from "../FrameDisplay";
import RoundedButton from "App/Components/Ui/RoundedButton"
const FrameDetails = (props) => {
  const { triggerAPI } = useHttp();
  const dispatch = useStore(false)[1];
  const [{ currentWallet }] = useStore(false);
  const [frameDetails, setFrameDetails] = useState({
    inStock: 1,
    totalEdition: 0,
    price: 0,
  });
  const [qtyList, setQtyList] = useState([]);
  const [frameDetailsLoad, setFrameDetailsLoad] = useState(false);
  const [selectedQty, setSelectedQty] = useState(1);
  const [selectedTab, setSelectedTab] = useState("Frames");
  const [imageLoaded, setImageLoaded] = useState(true);
  const [lastQty, setLastQty] = useState(0);
  const isLoggedIn = useAuth()[0];
  const timeRef = useRef();
  const targetRef = useRef();
  let frameId = props.frame.id;
  const screensize = useScreenSize()?.width;
  const { getFrameFromStore, getFrameAPI } = useFrames();
  const [selectedFrame, setSelectedFrame] = useState(null)
  const [url, setURL] = useState({})
  const nftFrameCnr = useRef();
  
  useEffect(() => {
    getFrames();
    setSelectedQty(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.frame]);

  const [frameMaxHeight, setFrameMaxHeight] = useState(0);
  const tabs = ["Frames"];
  //Frame Api
  const getFrameResult = useCallback((res) => {
    const data = res;
    const pricelist = data?.project?.pricelist;
    let price = 0;
    if (pricelist) {
      const priceData = convertJsonToArray(pricelist);
      price = priceData?.length
        ? priceData[0]?.priceInLovelace
          ? `ADA ${convertPriceToAda(priceData[0]?.priceInLovelace)}`
          : `${priceData[0]?.currency?.toUpperCase()} ${priceData[0]?.price}`
        : 0;
    }
    let temp = {
      id: data.id,
      name: data.name,
      image:
        props.frame.isLandscape ?? data?.isLandscape
          ? data.s3ImageUrlLandscape ?? data.s3ImageUrl
          : data.s3ImageUrl,
      image256:
        props.frame.isLandscape ?? data?.isLandscape
          ? data.s3ImageUrlLandscape256 ?? data.s3ImageUrl256
          : data.s3ImageUrl256,
      image512:
        props.frame.isLandscape ?? data?.isLandscape
          ? data.s3ImageUrlLandscape512 ?? data.s3ImageUrl512
          : data.s3ImageUrl512,
      age: data.age.name,
      type: data.category.name,
      style: data.style.name,
      color: data.color.name,
      features: data.addOn.name,
      complexity: data.complexity.name,
      material: data.material.name,
      metaInfo:
        props.frame.isLandscape ?? data?.isLandscape
          ? data.metaInfo?.landscape
          : data.metaInfo?.portrait,
      price: price,
      totalEdition: data.totalEdition,
      inStock: data.inStock,
    };
    const qtyList = new Array(data?.inStock).fill("").map((x, i) => {
      const val = i + 1;
      return { value: val, name: val, id: val };
    });
    setQtyList(qtyList);
    setFrameDetails({ ...temp });
    setFrameDetailsLoad(false);
  }, []);
  const getFrames = (q) => {
    setFrameDetailsLoad(true);
    getFrameFromStore(frameId, (res) => {
      getFrameResult(res);
    });
  };
  const tabSelectHandler = (e) => {
    setSelectedTab(e);
  };
  //Initialise transaction
  const buyFrameHandler = () => {
    if (currentWallet?.address || isLoggedIn) {
      if (frameDetails?.price) {
        dispatch("showSpinner");
        const data = {
          projectUid: props?.frame?.projectUid,
          tokencount: selectedQty,
          nftUid: props?.frame?.nftUid,
        };
        triggerAPI(
          {
            url: `nft-maker/project/payment`,
            data: data,
            method: "post",
          },
          getResult
        );
      }
    } else {
      props?.setShowWalletPop(true);
    }
  };
  const getResult = (res) => {
    dispatch("hideSpinner");
    const data = res.data;
    setLastQty(frameDetails?.inStock);
    window.open(data?.nmkrPayUrl, "", "width=800,height=600");
  };
  useEffect(() => {
    if (lastQty === frameDetails?.inStock) {
      const checkQty = () => {
        getFrameAPI(frameId, (res) => {
          const data = res?.data;
          if (data?.inStock === lastQty) {
            timeRef.current = setTimeout(() => {
              checkQty();
            }, 5000);
          } else {
            if (lastQty) {
              clearTimeout(timeRef.current);
              const qtyList = new Array(data?.inStock).fill("").map((x, i) => {
                const val = i + 1;
                return { value: val, name: val, id: val };
              });
              setQtyList(qtyList);
              setFrameDetails((prev) => {
                return { ...prev, inStock: data?.inStock };
              });
            }
          }
        });
      };
      checkQty();
    }
    return () => {
      clearTimeout(timeRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastQty]);
  useEffect(() => {
    return () => {
      setFrameDetails((prev) => {
        return { ...prev, inStock: 0 };
      });
    };
  }, []);
  const qtyChange = (e) => {
    setSelectedQty(e?.value);
  };
  useEffect(() => {
    setImageLoaded(true);
  }, [props.frame.imageUrl]);
  const onFrameLoaded = () => {
    setImageLoaded(false);
  };
  const updateHeightCard = () => {
    const root = document.documentElement;
    if (targetRef.current) {
      root?.style.setProperty(
        "--frame-card-height",
        `${targetRef.current.offsetWidth}px`
      );
    }
  };
  useLayoutEffect(() => {
    updateHeightCard();
    window.addEventListener(
      "resize",
      () => {
        setTimeout(() => {
          updateHeightCard();
        }, 1000);
      },
      false
    );
  }, []);
  const frameSelectHandler = e => {
    let frameFilterd = props.frames.find(frame => frame.id === Number(e.currentTarget.dataset["id"]))
    console.log(frameFilterd)
    setSelectedFrame(frameFilterd)
    setTimeout(() => {
        e?.target?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
    }, 500)
}
const getFramesUpdate = e => {
  setFrameDetailsLoad(true);
  getFrameFromStore(Number(e.currentTarget.dataset["id"]), (res) => {
    getFrameResult(res);
  });
};
let userAddedStatus = false;
  return (
    <div
      className={`${styles["details-container"]} ${
        props?.isZoom
          ? styles["frame-detail-popup-panel"]
          : styles["frame-detail-panel"]
      } ${props.frame.dark && styles["dark"]}`}
    >

  
      <div className="aspect-square border border-[#30333A]">
          <NftFrame
            onFrameLoaded={onFrameLoaded}
            setImageLoaded={setImageLoaded}
            skeltonClass={`h-fit`}
            frameDetails={frameDetails}
            isDetailView={props?.isZoom}
            nft={props.nft.img}
            imageFile={props.nft.files}
            // frameDetailsStyles={true}
          />
</div>
       
      {!props?.isZoom && (
        <div
          className={`pt-4  ${styles["framshop-details-section"]} overflow-y-scroll`}
        >
          <div className="flex flex-col  gap-[1rem]">
            <div className="flex w-full justify-between items-center ">
              <span className={`${styles["head-title"]}`}>
                {frameDetails.name}
              </span>
              <div className={`${styles["close-div"]} justify-end  h-[1.5rem]`}  >
                  <div className={`${styles["close-icon"]}`} onClick={props?.detailsCloseHandler}>
                  </div>
                </div>
            </div>
            <div className="flex w-full gap-[4rem]">
              <div className="flex flex-col gap-[.5rem]">
                <div
                  className={`flex gap-[0.625rem] ${styles["description-data"]}`}
                >
                  <span className={`${styles["title"]}`}>Age </span>
                  <span className={`${styles["value"]}`}>
                    {frameDetails.age}
                  </span>
                </div>
                <div
                  className={`flex gap-[0.625rem] ${styles["description-data"]}`}
                >
                  <span className={`${styles["title"]}`}>Style </span>
                  <span className={`${styles["value"]}`}>
                    {frameDetails.style}
                  </span>
                </div>
                <div
                  className={`flex gap-[0.625rem] ${styles["description-data"]}`}
                >
                  <span className={`${styles["title"]}`}>Material </span>
                  <span className={`${styles["value"]}`}>
                    {frameDetails.material}
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-[.5rem]">
                <div
                  className={`flex gap-[0.625rem] ${styles["description-data"]}`}
                >
                  <span className={`${styles["title"]}`}>Color </span>
                  <span className={`${styles["value"]}`}>
                    {frameDetails.color}
                  </span>
                </div>
                <div
                  className={`flex gap-[0.625rem] ${styles["description-data"]}`}
                >
                  <span className={`${styles["title"]}`}>Add-ons </span>
                  <span className={`${styles["value"]}`}>
                    {frameDetails.features}
                  </span>
                </div>
                <div
                  className={`flex gap-[0.625rem] ${styles["description-data"]}`}
                >
                  <span className={`${styles["title"]}`}>Complexity </span>
                  <span className={`${styles["value"]}`}>
                    {frameDetails.complexity}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`${styles["tab-box"]} flex gap-2.5 pt-[1rem] border-b border-[#323A46]`}
            >
              {tabs.map((tab) => (
                <div className={styles["tab-sm"]} key={tab}>
                  <Tabs
                    className={`${styles["frmae-tab-button"]} ${
                      selectedTab === tab ? styles["selectedFrame"] : ""
                    } p-1`}
                    onSelect={tabSelectHandler}
                    selected={selectedTab === tab ? true : false}
                  >
                    {tab}
                  </Tabs>
                </div>
              ))}
            </div>
          </div>
          <div
            className={`${
              selectedTab === tabs[0] && "pb-[0rem]"
            }`}
          >
            <Spinner
              show={frameDetailsLoad}
              className="absolute backdrop-blur-[0.7px] z-10 justify-center items-center w-full h-full"
              size={50}
            />
            <div
              className={`${styles["frame-details"]} h-[60vh] ${
                props?.isZoom ? styles["active"] : ""
              }`}
            >
             {selectedTab === tabs[0] &&  <div className={`grid grid-cols-6 gap-x-3 gap-y-6 sm:pl-0 pl-2.5 overflow-y-auto sm:pb-3 pt-[2rem] max-h-full pr-4`}>
                    {props.frames && props.frames.map((frame, index) =>{
                        const metaInfoFrame = frame?.isLandscape ? frame?.metaInfo?.landscape : frame?.metaInfo?.portrait;
                        const isSelectedFrame = frameDetails.id === frame.id;
                        if (isSelectedFrame) {
                          userAddedStatus = frame.isUserAdded;
                      }

                        return <div data-id={frame.id} onClick={getFramesUpdate} key={frame.id} className={`${styles["card-colr"]} flex flex-col ${frameDetails.id === frame.id ? 'border border-white rounded-[.25rem]' : ''}`}>
                            <FrameDisplay 
                            // onZoomFrame={detailsOpenHandler} 
                            nft={url} 
                            frame={frame} 
                            selectedFrame={selectedFrame} frameId={frame.id} 
                            frameSrc={frame?.isLandscape ? (frame?.imageUrlLand ?? frame?.imageUrl) : frame?.imageUrl} 
                            metaInfoFrame={metaInfoFrame} framesLoading={props.framesLoading} 
                            hidePrice={true}
                            />
                        </div>}
                    )}
                </div>}
            </div>
          </div>
          <div className="flex flex-col h-full justify-center items-center" >  
          <div className="h-[2rem] w-[16rem]" onClick={(event) => {
                                    if(props.isAddingFrame) return;
                    event.stopPropagation();
                    props.onAddorRemoveFrame(frameDetails.id, userAddedStatus)
                }}>
                    <RoundedButton Type={'11'} Name={userAddedStatus ? "Remove" : "Add"}> </RoundedButton>
            </div>
            </div>
        </div>
        
      )}
          
    </div>
  );
};

export default FrameDetails;