import React from 'react'
import styles from "./PriceButton.module.scss"
const PriceButton = (props) => {
    return (
        <div className={`${styles["frame-price"]} ${props.className}`}>
            <p className={styles["frame-price-value-sm"]}>{props.frame.currency}{props.frame.price} Buy</p>
        </div>
    )
}

export default PriceButton
