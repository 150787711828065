import NftFrame from "App/Components/NftFrame"
import { convertJsonToArray, convertPriceToAda, getFrameURL } from "App/Helper/utilities";
import { useEffect, useState } from "react"
import styles from "../../NewFrames.module.scss"
import ADAICON from 'Assets/Icons/ada-icon.png';
import useScreenSize from "App/hooks/use-screen-size";
const FrameDisplay = (props) => {
    const [frameURL, setFrameURL] = useState(null);
    const [framePrice, setFramePrice] = useState(0);
    const [frameMeta, setFrameMeta] = useState(null);
    const screenSize = useScreenSize()?.width;
    useEffect(() => {
        const url = getFrameURL(props.frameSrc);
        setFrameURL(url)
    }, [props.frameSrc])
    useEffect(() => {
        setFrameMeta(props.metaInfoFrame)
    }, [props?.metaInfoFrame])
    useEffect(() => {
        const pricelist = props?.frame?.project?.pricelist;
        if (pricelist) {
            const priceData = convertJsonToArray(pricelist);
            const price = priceData?.length ? (priceData[0]?.priceInLovelace ? `ADA ${convertPriceToAda(priceData[0]?.priceInLovelace)}` : `${priceData[0]?.currency?.toUpperCase()} ${priceData[0]?.price}`) : 0;
            setFramePrice(price);
        }
    }, [props])
    return <div className={`${styles["frame-display"]} ${props.frame.dark && styles["dark"]} flex-1  ${props.frameId === props.selectedFrame?.id ? styles["activeCard"] : ''}`} onClick={props.onZoomFrame}>
        <div onClick={props.onZoomFrame} className={`${styles["frame-nft-img"]} aspect-square`}> 
        <div className="aspect-square flex flex-col justify-center">             
            <NftFrame
                key={props?.inedx}
                skeltonClass={`h-fit flex `}                
                overlayClass={styles["hover-overlay"]}                               
                frameDetails={{metaInfo:frameMeta,image:frameURL}}         
                nft={props.nft.img}
                useFrameContainer2={props.useFrameContainer2}  
            />
        </div>
        </div>
       {!props?.hidePrice && <div className={`${styles["frame-price"]}`} onClick={props.onZoomFrame}>
            <div className={`${styles["frame-title"]}`}>{props.frame?.name}</div>
            <p className={`${styles["frame-price-section"]}`} onClick={props.onZoomFrame}>                
            {props.addButton ? <button className={`w-full h-5 ${props.isAddingFrame && 'cursor-wait'}`} onClick={(event) => {
                                    if(props.isAddingFrame) return;
                    event.stopPropagation();
                    props.onAddorRemoveFrame(props.frameId, props?.isUserAdded)
                }}>{props?.isUserAdded ? 'Remove' : "Add"}</button> : <span>{framePrice} </span>}
            </p>
        </div>}
    </div>
}

export default FrameDisplay