/* eslint-disable react-hooks/exhaustive-deps */
import styles from "../../../../SelectedCollection.module.scss"
import React, { useEffect, useState } from 'react';
import useFrames from "App/hooks/use-frames";
import NftZoomed from "App/Components/NftZoomed";
import useHttp from "App/hooks/use-http";

const NftRow = (props) => {
    const [frameDetails, setFrameDetails] = useState(null)
    const { getFrameFromStore } = useFrames();
    const { triggerAPI } = useHttp();
    const [nftDetails, setNftDetails] = useState([])
    useEffect(() => {
        if (!!props.framesList)
            getFrameId()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.framesList])

    const getFrameId = () => {
        for (let frames of props.framesList) {
            if (frames.asset === props.nft.asset) {
                getFrame(frames.frameId)
            }
        }
    }
    const frameApiResult = (res) => {
        const data = res
        let isLandscape = data?.isLandscape ?? false;
        if (props?.framesList?.length) {
            let userFrame = props?.framesList.filter((x) => x.frameId === data?.id);
            if (userFrame.length) {
                isLandscape = userFrame[0]?.isLandscape ?? false;
            }
        }
        let temp = {
            name: data.name,
            image: isLandscape ? (data.s3ImageUrlLandscape ?? data.s3ImageUrl) : data.s3ImageUrl,
            type: data.category.name,
            style: data.style.name,
            color: data.color.name,
            features: data.addOn.name,
            complexity: data.complexity.name,
            metaInfo: isLandscape ? data.metaInfo?.landscape : data.metaInfo?.portrait
        }
        setFrameDetails(temp)
    }
    const getFrame = (frameId) => {
        getFrameFromStore(frameId, (res) => {
            frameApiResult(res)
        })
    }
    const ImageClickHandler = (e) => {
        setTimeout(() => {
            e?.target?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        }, 500)
        props.onImgClick({ nft: props.nft, frame: frameDetails })

    }

    
    const getNFTResult = (res) => {
        let values = []
        if (res?.data?.onchain_metadata) {
           let newVal= props?.tableHeader?.slice(1)?.map(val=>res?.data?.onchain_metadata[val.name]||"")
            // Object.entries(res?.data?.onchain_metadata)?.length && Object.entries(res?.data?.onchain_metadata)?.forEach(([key, value], i) => {
            //     if (typeof value === "string"&&value !== "N/A"&&!value.includes("ipfs:") && key !== "image"&&!key.includes("--")&& !value.includes("https://") && !value.includes(".com") && key !== "mediaType" && key !== "name" && values?.length < 8) {
            //         values.push(value)
            //     }
            // })
            values=[...newVal]
        }
        setNftDetails(values)

    }
    const getNFTDetails = (asset, networkType) => {
        const data = new URLSearchParams({
            asset: asset,
            networkType: networkType
        })
        triggerAPI({
            url: `user/nft-collection/asset/detail?${data}`, method: 'get'
        }, getNFTResult);
    }

    useEffect(() => {
        if (props?.nft?.asset) {
            getNFTDetails(props?.nft?.asset, props?.nft?.networkType)
        }
    }, [props?.nft,props?.tableHeader])

    return (
        <tr key={props.index}>

            <td className="items-center gap-4 w-6/12">
            <div className="flex">
                    <div className={`${styles["nft-img"]} ${props?.noClick&&"cursor-pointer"}`}>
                        {!!props.nft.nftcdnimage256 && <NftZoomed noClick={props?.noClick} displayNft="list" className="col-art-full-img collection-table-image-nft" data-index={props.index} onClick={ImageClickHandler} nft={props.nft?.nftcdnimage256} frameDetails={frameDetails} />}
                        </div>
                    <div className="list-name-wid-name eclipse-wid flex align-center align-center">
                        {props.nft.name}
                    </div>
                </div>
            </td>
            {nftDetails?.map((val,i) => <td className={`${i === 0 ?  'w-2/6' :'w-1/6'}`} key={i+1}>{val}</td>)}
        </tr>
    )
}

export default NftRow
