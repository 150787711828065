import React, { useEffect, useState } from 'react'

const BlurBGImage = (props) => {
    const [bgImgSrc, setBgImgSrc] = useState(props?.bgImageSrc);
    const [mainImgSrc, setMainImgSrc] = useState(props?.mainImageSrc);

    const handleBgImageError = () => {
        if (props?.altBgImg) {
            setBgImgSrc(props?.altBgImg);
        }
    };

    const handleMainImageError = () => {
        if (props?.altMainImg) {
            setMainImgSrc(props?.altMainImg)
        }
    };

    useEffect(() => {
        setBgImgSrc(props?.bgImageSrc);
        setMainImgSrc(props?.mainImageSrc);
    }, [props?.bgImageSrc, props?.mainImageSrc]);

    return (
        <>
            <img
                alt={props?.bgAlt ?? `bg-pic`}
                src={bgImgSrc}
                className={`object-cover h-full w-full saturate-50 brightness-50 contrast-100 blur-[5px] absolute ${props.bgImageCss}`}
                onError={handleBgImageError}
            />
            <img
                alt={props?.alt ?? `pic`}
                src={mainImgSrc}
                className={`h-full w-full z-[1] object-contain blur-0 ${props.mainImageCss}`}
                onError={handleMainImageError}
            />
        </>
    )
}

export default BlurBGImage