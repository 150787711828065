import { useEffect, useState } from 'react';
import styles from '../../NftFrame.module.scss'
import { IFrame } from 'App/Components/Ui/Iframe';
import { MediaType, getMediaImage } from 'App/Helper/utilities';
import { ReactReader } from 'react-reader'
const NftViewer = (props) => {
    const [src, setSrc] = useState(null)
    const [mType, setMType] = useState('image')    
    const [ref, setRef] = useState();
    const [assetName, setAssetName] = useState('')
    useEffect(() => {
        if (props.nft) {
            setSrc(props.nft);
        }
    }, [props.nft, props?.onChainReRender])
    useEffect(() => {
        const video = ref;
        if (!props?.playAudio && video) {
            video.muted = true;
        }
        if (props?.playAudio && video) {
            video.muted = false;
        }
      }, [props?.playAudio, ref]);
    useEffect(() => {
        if (props.imageFile?.length) {
            let data = props.imageFile[0];
            let alternativeFileType =MediaType(data.mediaType);
            setMType(data?.mType||alternativeFileType)
            setSrc(data?.nftcdnfile)   
            setAssetName(data?.name)      
        }
        else
        {
            setMType("image");
        }
    }, [props.imageFile, props.isDetailView,props.nft])
    return <>
        {
            props?.showFileType?
            <>
                <div className='data-type' 
                    tooltip={assetName}>
                    <div className='media-file-type'>
                        <img src={getMediaImage(mType)} alt=""/>
                    </div>
                </div>
            </>
            :
            <div data-index={props["data-index"]} onClick={props.onClick} className={`${styles["nft-box"]} ${props?.nftImage ?? ''} ${props.imageIsLoading&&mType==="image" ? styles["nft-box-loading"]:''}`} style={props.dimensions && { width: props.dimensions.widthPercent + "%", height: props.dimensions.heightPercent + "%", top: props?.dimensions?.yPercent + "%", left: props?.dimensions?.xPercent + "%" }} >
                {/* {src && <img onLoad={props.imageOnLoad} onError={props.imgErrorHandler} className={`${styles["nft"]} ${props?.nftImageClass}`} alt="imageName" src={src} />} */}
                <>
                {mType === 'image' && src && <img  onLoad={props.imageOnLoad} onError={props.imgErrorHandler} className={`${styles["nft"]} ${props?.nftImageClass} nft-img-sec`} alt="imageName" src={src} />}
                
                {mType === 'mp4' && src && 
                    <video autoPlay muted loop onLoadedMetadata={props.imageOnLoad} ref={newRef => setRef(newRef)}>
                        <source src={src} />
                    </video>
                }     
                {(mType === 'pdf') && src && 
                    <IFrame onLoad={props.imageOnLoad} >
                        {src}
                    </IFrame>
                }
                {(mType === 'epub') && src && 
                    <div className="h-full w-full">
                        <ReactReader 
                            url={src}
                            epubInitOptions={{
                                openAs: 'epub',
                            }}>                        
                        </ReactReader>
                    </div>                    
                }
                {(mType === 'model' && src &&
                    <>                
                        <model-viewer src={src}  ar="" load={props.imageOnLoad} auto-rotate="" autoplay="true" camera-controls="" ar-status="not-presenting" ar-modes="webxr scene-viewer quick-look" environment-image="neutral" className="" style={{height:"100%",width:"100%"}}>                
                        </model-viewer>
                    </>
                )}
                {(mType === 'html') && src && 
                    <IFrame onLoad={props.imageOnLoad} >
                        {src}
                    </IFrame>
                }
                </>
            
                
            </div>
        }
    </>

}

export default NftViewer