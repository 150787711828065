import ControlBody from "../SubComponents/ControlBody";
import ControlCard from "../SubComponents/ControlCard";
import ControlContent from "../SubComponents/ControlContent";
import ControlTitleCard from "../SubComponents/ControlTitleCard";
import { memo } from "react";
import InputSlider from "App/Components/Ui/InputSlider";
import LayoutImageCard from "App/Pages/NftGallery/Component/MyGallery/Components/AddedNfts/Components/GalleryPreview/Components/LayoutImageCard";
const MultiLayoutsControl = ({className = '', multiLayoutList = [], selectedMultiLayout, setSelectedMultiLayout}) => {
    // const { loadingLayout } = useStore(false)[0];
    // const isImageLoading = useMemo(() => {
    //     return loadingLayout?.some(item => item?.loading)
    // }, [loadingLayout])
    //above need to enable if any one of slide loading then needs to disable layout selection
    const handleClick = (e) => {
        const id = e.currentTarget.dataset?.id;
        setSelectedMultiLayout(prev => { return {...prev, layout: multiLayoutList?.find(item => item?.id === parseInt(id))}});
    }
    const onChange = (value) => {
        setSelectedMultiLayout(prev => { return {...prev, size: value}});
    }
    return (
        <ControlCard className={`${className} pop flex flex-col gap-5 layout-list-control w-[50rem]`}>
            <ControlBody className="layout-list-section">
                <div className="flex gap-2 justify-between items-center">
                    <ControlTitleCard>{`Layouts`}</ControlTitleCard>
                    <div className="layout-filter flex gap-2 items-end">
                        <InputSlider value={selectedMultiLayout?.size} min={1} max={1.15} step={0.01} onChange={onChange} />
                    </div>
                </div>
                
                <ControlContent className="layout-content">
                    <div className="grid layout-sec-list listing-grid-control-panel">
                        {multiLayoutList?.map((layout) => (
                            <LayoutImageCard key={layout?.id} design={layout} selected={selectedMultiLayout?.layout} handleLayoutClick={handleClick} />
                        ))}
                    </div>
                </ControlContent>
            </ControlBody>
        </ControlCard>
    )
}
export default memo(MultiLayoutsControl);