import NftRow from "./Components/NftRow"
import CustomTable from "App/Components/Ui/CustomTable";
import './NftTableDisplay.scss';
import { useEffect, useState } from "react";
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";

const NftTableDisplay = props => {
    const tableHeader = [
        {name:'Collection', isSort: true, sortName: 'name'}, 
        // {name:'Category', isSort: true, sortName: 'category'}, 
        {name:'Community', isSort: true, sortName: 'community'}, 
        // {name:'Blockchain', isSort: true, sortName: 'blockchain'}, 
        {name:'Floor', isSort: true, sortName: 'floor'}, 
        {name:'My Assets', isSort: true, sortName: 'assets'}, 
        {name:'My Value', isSort: true, sortName: 'value'}, 
        {name:'Total Vol', isSort: true, sortName: 'volume'}, 
        {name:'Owners', isSort: true, sortName: 'owner'}, 
        {name:'Pcs/Owner', isSort: true, sortName: 'pieces'}, 
        {name:'Mint Date', isSort: true, sortName: 'mintDate'}, 
        {name:'Lock Date', isSort: true, sortName: 'lockDate'}
    ]
    const [arrwFlip, setArrowFlip] = useState({sortName: 'name', flip: false})
    const [nftList, setNftList] = useState([]);
    const [originalList, setOriginalList] = useState([]);
    const [listLoading, setListLoading] = useState(true);
    const [totalList, setTotalList] = useState(10);
    const sortByHandler = (e) => {
        const name = e.currentTarget?.dataset?.sortby;
        setArrowFlip(prev=> { return { flip: !prev?.flip, sortName: name}})
    }
    useEffect(() => {
        setListLoading(true);
        // setTotalList(props?.nfts?.list?.length ?? 10)
        const list = [...props?.nfts?.list]?.map((item) => { 
            item.categoryName = item?.category?.name; 
            item.communityName = item?.community?.name; 
            

            if(item?.metrics?.length) {
                if(item?.metrics[0]?.supply && item?.metrics[0]?.supply){
                    item.piecesPerOwner = (item?.metrics[0]?.supply && item?.metrics[0]?.owners) ?item?.metrics[0]?.supply/ parseFloat(item?.metrics[0]?.owners) : null;
                    item.ownerCount = item?.metrics[0]?.owners;
                }else{
                    item.piecesPerOwner = null;
                }
                item = {...item, ...item?.metrics[0]};
            }
            if (item?.metrics?.length) {
                if (item?.metrics[0]?.floorPrice && item?.quantity) {
                    item.myValue = parseInt(item?.quantity) * parseInt(item?.metrics[0]?.floorPrice)
                } else {
                    item.myValue = null;
                }
                item = { ...item, ...item?.metrics[0] };
            }

            item.ownerCount = item.ownerCount ?? item.ownerCount;
            item.piecesPerOwner =  (item?.assetCount && item.ownerCount) && !item.piecesPerOwner ?  item?.assetCount/item.ownerCount : item.piecesPerOwner ?? null

            return {...item }
        })
        setOriginalList([...list]);
    }, [props?.nfts?.list]) 

    useEffect(() => {
        let list = [], tempArray = [...originalList];
        tempArray = [...tempArray]?.filter(item => item.newName = item?.collectionName ?? item?.name)
        if (props?.listRef?.current)
        props?.listRef?.current.scroll({
            top: 0,
            behavior: 'smooth'
        });
        switch (arrwFlip?.sortName) {
            case 'name':
                if(arrwFlip?.flip) {
                    list = tempArray.sort((a, b) => a["newName"] ? b["newName"] ? a["newName"]?.toLowerCase() < b["newName"]?.toLowerCase() ? 1 : -1 : -1 : 1);
                } else {
                    list = tempArray.sort((a, b) => a["newName"] ? b["newName"] ? a["newName"]?.toLowerCase() > b["newName"]?.toLowerCase() ? 1 : -1 : -1 : 1);
                }
                break;
            case 'category':
                if(arrwFlip?.flip) {
                    list = tempArray.sort((a, b) => a["categoryName"] ? b["categoryName"] ? a["categoryName"]?.toLowerCase() < b["categoryName"]?.toLowerCase() ? 1 : -1 : -1 : 1);
                } else {
                    list = tempArray.sort((a, b) => a["categoryName"] ? b["categoryName"] ? a["categoryName"]?.toLowerCase() > b["categoryName"]?.toLowerCase() ? 1 : -1 : -1 : 1);
                }
                break;
            case 'community':
                if(arrwFlip?.flip) {
                    list = tempArray.sort((a, b) => a["communityName"] ? b["communityName"] ? a["communityName"]?.toLowerCase() < b["communityName"]?.toLowerCase() ? 1 : -1 : -1 : 1);
                } else {
                    list = tempArray.sort((a, b) => a["communityName"] ? b["communityName"] ? a["communityName"]?.toLowerCase() > b["communityName"]?.toLowerCase() ? 1 : -1 : -1 : 1);
                }
                break;
            case 'blockchain':
                if(arrwFlip?.flip) {
                    list = tempArray.sort((a, b) => a["collectionName"] ? b["collectionName"] ? a["collectionName"]?.toLowerCase() < b["collectionName"]?.toLowerCase() ? 1 : -1 : -1 : 1);
                } else {
                    list = tempArray.sort((a, b) => a["collectionName"] ? b["collectionName"] ? a["collectionName"]?.toLowerCase() > b["collectionName"]?.toLowerCase() ? 1 : -1 : -1 : 1);
                }
                break;
            case 'floor':
                if(arrwFlip?.flip) {
                    list = tempArray.sort((a, b) => Number(a["floorPrice"]) ? Number(b["floorPrice"]) ? Number(a["floorPrice"]) < Number(b["floorPrice"]) ? 1 : -1 : -1 : 1);
                } else {
                    list = tempArray.sort((a, b) => Number(a["floorPrice"]) ? Number(b["floorPrice"]) ? Number(a["floorPrice"]) > Number(b["floorPrice"]) ? 1 : -1 : -1 : 1);
                }
                break;
            case 'value':
                if(arrwFlip?.flip) {
                    list = tempArray.sort((a, b) => Number(a["myValue"]) ? Number(b["myValue"]) ? Number(a["myValue"]) < Number(b["myValue"]) ? 1 : -1 : -1 : 1);
                } else {
                    list = tempArray.sort((a, b) => Number(a["myValue"]) ? Number(b["myValue"]) ? Number(a["myValue"]) > Number(b["myValue"]) ? 1 : -1 : -1 : 1);
                }
                break;
            case 'volume':
                if(arrwFlip?.flip) {
                    list = tempArray.sort((a, b) => Number(a["allVolume"]) ? Number(b["allVolume"]) ? Number(a["allVolume"]) < Number(b["allVolume"]) ? 1 : -1 : -1 : 1);
                } else {
                    list = tempArray.sort((a, b) => Number(a["allVolume"]) ? Number(b["allVolume"]) ? Number(a["allVolume"]) > Number(b["allVolume"]) ? 1 : -1 : -1 : 1);
                }
                break;
            case 'assets':
                if(arrwFlip?.flip) {
                    list = tempArray.sort((a, b) => Number(a["quantity"]) ? Number(b["quantity"]) ? Number(a["quantity"]) < Number(b["quantity"]) ? 1 : -1 : -1 : 1);
                } else {
                    list = tempArray.sort((a, b) => Number(a["quantity"]) ? Number(b["quantity"]) ? Number(a["quantity"]) > Number(b["quantity"]) ? 1 : -1 : -1 : 1);
                }
                break;
            case 'mintDate':
                if(arrwFlip?.flip) {
                    list = tempArray.sort((a, b) => {
                        if (!a?.mintDate || !b?.mintDate) {
                            if (!a?.mintDate && !b?.mintDate) {
                                return 0; 
                            } else if (!a?.mintDate) {
                                return 1; 
                            } else {
                                return -1; 
                            }
                        }
                        return new Date(b?.mintDate) - new Date(a?.mintDate);
                    });
                } else {
                    list = tempArray.sort((a, b) => {
                        if (!a?.mintDate || !b?.mintDate) {
                            if (!a?.mintDate && !b?.mintDate) {
                                return 0; 
                            } else if (!a?.mintDate) {
                                return 1; 
                            } else {
                                return -1;
                            }
                        }
                        return new Date(a?.mintDate) - new Date(b?.mintDate);
                    });
                }
                break;
            case 'lockDate':
                if(arrwFlip?.flip) {
                    list = tempArray.sort((a, b) => {
                        if (!a?.lockDate || !b?.lockDate) {
                            if (!a?.lockDate && !b?.lockDate) {
                                return 0; 
                            } else if (!a?.lockDate) {
                                return 1; 
                            } else {
                                return -1; 
                            }
                        }
                        return new Date(b?.lockDate) - new Date(a?.lockDate);
                    });
                } else {
                    list = tempArray.sort((a, b) => {
                        if (!a?.lockDate || !b?.lockDate) {
                            if (!a?.lockDate && !b?.lockDate) {
                                return 0; 
                            } else if (!a?.lockDate) {
                                return 1; 
                            } else {
                                return -1;
                            }
                        }
                        return new Date(a?.lockDate) - new Date(b?.lockDate);
                    });
                }
                break;
            case 'owner':
                if(arrwFlip?.flip) {
                    list = tempArray.sort((a, b) => Number(a["ownerCount"]) ? Number(b["ownerCount"]) ? Number(a["ownerCount"]) < Number(b["ownerCount"]) ? 1 : -1 : -1 : 1);
                } else {
                    list = tempArray.sort((a, b) => Number(a["ownerCount"]) ? Number(b["ownerCount"]) ? Number(a["ownerCount"]) > Number(b["ownerCount"]) ? 1 : -1 : -1 : 1);
                }
                break;
            case 'pieces':
                if(arrwFlip?.flip) {
                    list = tempArray.sort((a, b) => Number(a["piecesPerOwner"]) ? Number(b["piecesPerOwner"]) ? Number(a["piecesPerOwner"]) < Number(b["piecesPerOwner"]) ? 1 : -1 : -1 : 1);
                } else {
                    list = tempArray.sort((a, b) => Number(a["piecesPerOwner"]) ? Number(b["piecesPerOwner"]) ? Number(a["piecesPerOwner"]) > Number(b["piecesPerOwner"]) ? 1 : -1 : -1 : 1);
                }
                break;
        
            default:
                break;
        }
        setNftList(list);
        const timer = setTimeout(()=>setListLoading(false), 1000)
        return () => clearTimeout(timer);
    }, [originalList, arrwFlip])

    return (
        <CustomTable tableHead={tableHeader} mainContainerClass={'collectionTable'} tableHeadClass={'tbl-header'} activeSort={arrwFlip} onClick={sortByHandler} >
            {!listLoading ?
                nftList && nftList?.map((nft, index) =>
                    <NftRow
                        key={nft.asset}
                        noClick={props?.noClick}
                        onCollectionSelect={props.onCollectionSelect}
                        onClick={props?.onImgClick} index={index}
                        framesList={props.framesList} nft={nft} selectedNft={props?.selectedNft}
                    />
                )
                :
                [...Array(totalList).keys()].map((trTag) => <tr key={trTag}>
                    {[...Array(10).keys()].map((loader) =>
                        <td key={loader} className={`${loader === 0 ? 'w-[22%]' : loader === 2 || loader === 6 ? 'w-[7%]' : 'w-[9%]'}`}>
                            {loader === 0 ?
                                <div className="flex gap-2">
                                    <SkeletonLoader className={`w-[3rem] aspect-square`} count={1} />
                                    <div className="w-full flex flex-col gap-1">
                                        <SkeletonLoader className={`w-[90%] mr-[10%] h-4`} count={1} />
                                        <SkeletonLoader className={`w-[30%] h-6 mr-[70%] rounded-full`} count={1} />
                                    </div>
                                </div> : <div className="p-2"> <SkeletonLoader className={`w-full h-full`} count={1} /></div>}

                        </td>)}
                </tr>)
            }
        </CustomTable>   
    )
}

export default NftTableDisplay