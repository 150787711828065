import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import './NftEnlarged.scss';
import { getFrameFilterList } from "App/Helper/aspectRatioCalc";
import useHttp from "App/hooks/use-http"
import { useStore } from "App/hooks-store/store";
import useAuth from "App/hooks/use-auth";
import storage from "App/Helper/storage";
import EnLargeSelectedAsset from "./Components/EnLargeSelectedAsset";
import EnLargeAssetList from "./Components/EnLargeAssetList";
const NftEnlarged = props => {
    const [currentAsset, setCurrentAsset]  = useState(null);
    const [freeFrameList, setFreeFrameList] = useState({ list: [], totalItems: 0 })
    const [purchasedList, setPurchasedList] = useState({ list: [], totalItems: 0 })
    const [allFrameList, setAllFrameList] = useState({ list: [], totalItems: 0 })
    const [filteredFrames, setFilteredFrames] = useState([])
    const [{ currentWallet, collectionLoadingStatus, artBankLoadingStatus, leftMenuCollapse }, dispatch] = useStore(false);
    const isLoggedIn = useAuth()[0]
    const isLoggedInGuest = useAuth()[4]
    const { triggerAPI } = useHttp();
    const [assetListLoading, setAssetListLoading] = useState(false)
    const [frameApplied, setFrameApplied] = useState(null)
    const [showPanel, setShowPanel] = useState(true)
    const [currentAssetLoading, setCurrentAssetLoading] = useState(true)
    const heightRef = useRef();
    
   useEffect(() => {
        if(props?.type === 'collection') {
            if(collectionLoadingStatus === 'idle') {
                setAssetListLoading(true)
            } else {
                setAssetListLoading(false)
            }
        }
        if(props?.type === 'art-bank') {
            if(artBankLoadingStatus === 'idle') {
                setAssetListLoading(true)
            } else {
                setAssetListLoading(false)
            }
        }
   },[collectionLoadingStatus, props?.type, artBankLoadingStatus])
   const imageClick = (e) => {
    const currenAsset = props?.nfts?.find((asset) => asset?.asset === e?.nft?.asset);
    setCurrentAsset(currenAsset);
   }
   useEffect(() => {
    if(!!currentAsset) {
        if (currentAsset?.files?.[0]?.mType=="html")
        {
            getFrameFilterList(null, allFrameList?.list, 1).then((res) => {
                setFilteredFrames(res)
            });
        }
        else
        {
            getFrameFilterList(currentAsset?.nftcdnimage256, allFrameList?.list).then((res) => {
                setFilteredFrames(res)
            });
        }        
    }    
   },[allFrameList, currentAsset])
   useEffect(() => {
    if(!currentAsset && props?.nfts?.length) {
        setCurrentAsset(props?.nfts[0])
        setTimeout(() => {
            setCurrentAssetLoading(false)
        }, 1500);
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[])
   const getFreeFrameList = useCallback(() => {

    let page = 1;
    let temp = [];
    const triggerFetchFreeFrame = () => {
        const data = {
            "page": page,
            "items": 15,
            filter: { categoryId: [2], sortBy:"modernWestern" }
        }
        triggerAPI({
            url: `frame-asset/user/get`, data: data, method: 'post'
        }, (res) => {
            const { list, pageMeta } = res.data;
            let arr_temp = list.map(frame => {
                return {
                    id: frame.id,
                    name: frame.name,
                    imageUrl: frame.s3ImageUrl,
                    imageUrlLand: frame?.s3ImageUrlLandscape,
                    imageUrl256: frame.s3ImageUrl256,
                    imageUrlLand256: frame?.s3ImageUrlLandscape256,
                    imageUrl512: frame.s3ImageUrl512,
                    imageUrlLand512: frame?.s3ImageUrlLandscape512,
                    price: frame.price ? frame.price : "00",
                    inStock: 30,
                    metaInfo: frame.metaInfo,
                    isLandscape: false
                }
            })
            temp = [...temp, ...arr_temp]
            if (temp.length < pageMeta.totalItems) {
                page++;
                triggerFetchFreeFrame();
            } else {
                setFreeFrameList({
                    list: temp,
                    totalItems: pageMeta.totalItems
                })
            }
        });
    }

    triggerFetchFreeFrame();


    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch, triggerAPI])

const getPurchasedList = useCallback(() => {

    let page = 1;
    let temp = [];
    const triggerFetchFreeFrame = () => {
        const data = {
            "page": page,
            "items": 15,
        }
        triggerAPI({
            url: `frame-asset/user/get/purchased-frame?page=${page}&items=${data.items}`, method: 'get'
        }, (res) => {
            const { list, pageMeta } = res.data;
            let arr_temp = list.map(frame => {
                return {
                    id: frame.id,
                    name: frame.name,
                    imageUrl: frame.s3ImageUrl,
                    imageUrlLand: frame?.s3ImageUrlLandscape,
                    imageUrl256: frame.s3ImageUrl256,
                    imageUrlLand256: frame?.s3ImageUrlLandscape256,
                    imageUrl512: frame.s3ImageUrl512,
                    imageUrlLand512: frame?.s3ImageUrlLandscape512,
                    price: frame.price ? frame.price : "00",
                    inStock: 30,
                    metaInfo: frame.metaInfo,
                    isLandscape: false
                }
            })
            temp = [...temp, ...arr_temp]
            if (temp.length < pageMeta.totalItems) {
                page++;
                triggerFetchFreeFrame();
            } else {
                setPurchasedList({
                    list: temp,
                    totalItems: pageMeta.totalItems
                })
            }

        });
    }

    triggerFetchFreeFrame();


    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch, triggerAPI])

useEffect(() => {
    getFreeFrameList();
    const token = storage().get('token')
    if((isLoggedInGuest || isLoggedIn) && token) {
        getPurchasedList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [currentWallet?.address])

useEffect(() => {
    setAllFrameList({ list: [...purchasedList?.list, ...freeFrameList?.list], totalItems: freeFrameList?.totalItems + purchasedList?.totalItems })
}, [purchasedList, freeFrameList])

useEffect(() => {
    if(!!currentAsset) {
        const filterDefaultFrame = props?.framesList?.find((frame) => frame?.asset === currentAsset?.asset);
        if(filterDefaultFrame) {
        let currentFrame = allFrameList?.list.find((frame => frame?.id === filterDefaultFrame?.frameId))
        if(currentFrame)
            currentFrame.isLandscape = filterDefaultFrame?.isLandscape
        previewFrame({...currentFrame});
       } else {
            setFrameApplied(null);
       }
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [currentAsset, allFrameList])

const onFrameSelect = (e) => {
    const frameIndex = e.currentTarget?.dataset?.index;
    if(filteredFrames?.length && frameIndex)
        previewFrame({...filteredFrames[frameIndex]});
    else 
        previewFrame(null);
}

const previewFrame = (currentApplied) => {
    if(currentApplied) {

        currentApplied.image = currentApplied?.isLandscape ? currentApplied?.imageUrlLand : currentApplied?.imageUrl;
        currentApplied.metaInfo = currentApplied?.isLandscape ? currentApplied?.metaInfo?.landscape : currentApplied?.metaInfo?.portrait;
       
    } 
    setFrameApplied(currentApplied);
}
const applyFrameResult = useCallback((res) => {
    dispatch('hideSpinner');
    props.onReload(currentAsset.asset)
}, [dispatch, props, currentAsset])

const applyFrameHandler = useCallback((e) => {
    if(isLoggedIn) { 

        let data = {
            "asset": currentAsset.asset,
            "networkType": currentAsset.networkType,
            "frameId": frameApplied ? frameApplied?.id : null,
            "isLandscape": frameApplied ? frameApplied?.isLandscape : false,
        }
        let url
        let method
        if (!!frameApplied) {
            url = `user/nft-collection/frame-it/add`
            method = 'post'
        }
        else {
            url = `user/nft-collection/frame-it/remove`
            method = 'delete'
            delete data.isLandscape;
        }
        dispatch('showSpinner')
        triggerAPI({
            url: url, data: data, method: method
        }, applyFrameResult,()=>{
            dispatch("hideSpinner");
        });
    } else {            
        props?.setShowAuthPop(true);
    }
}, [dispatch, applyFrameResult, triggerAPI, currentAsset, frameApplied, isLoggedIn, props])

 useEffect(() => {
    dispatch('enableTopIcons', {lgView: true,
        xlgView: true,
        smView: true,
        listView: true,
        dropDown: false,
        folder: false,
        file: false,
        favourite: false,
        sorting: false,
        caption: false,
        frame: false,
        search: false
    })
    dispatch('setActiveIcons', {frame: true});
    return () => {
        dispatch('enableTopIcons', {
            listView: true,
            dropDown: false,
            favourite: false,
            sorting: true,
            caption: true,
            search: false
        })
        dispatch('setActiveIcons', {frame: true});
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])
const handleShow = () => {
    setShowPanel(prev => !prev)
}
const updateHeight = (e) => {
    const root = document.documentElement;
    if(heightRef.current) {
        const height = heightRef.current.offsetHeight + 46;
        root?.style.setProperty(
            "--collection-enlarge-layout-height", `${height}px`
        );
    }
}
useLayoutEffect(() => {
    updateHeight()
    window.addEventListener("resize", () => {
        setTimeout(() => {
            updateHeight()            
        }, 1000);
    }, false);
},[]);
useEffect(() => {
    updateHeight()
},[showPanel, props.framesList, currentAsset]);

useEffect(() => {
    setTimeout(() => {
        updateHeight()            
    }, 1500);
    updateHeight()
},[leftMenuCollapse]);

    return <div className="en-large-container">
        <EnLargeSelectedAsset 
            currentAsset={currentAsset} 
            showPanel={showPanel}
            frameApplied={frameApplied} 
            filteredFrames={filteredFrames}
            currentAssetLoading={currentAssetLoading}
            applyFrameHandler={applyFrameHandler}
            onFrameSelect={onFrameSelect}
            />
        <div ref={heightRef}  className={`frame-asset-card ${!currentAsset && 'justify-center'} ${showPanel && 'active'}`}>
            <EnLargeAssetList 
                imageClick={imageClick} 
                updateHeight={updateHeight} 
                showPanel={showPanel} 
                framesList={props.framesList} 
                assetListLoading={assetListLoading} 
                reload={props?.reload} handleShow={handleShow}
                currentAsset={currentAsset} type={props?.type} 
                nftList={props?.nfts} displayNft={props?.displayNft} 
                skeltonClass="py-4"/>
                
        </div>
    </div>
}

export default NftEnlarged