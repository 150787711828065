import { Fragment, useCallback } from "react";
import { useState, useEffect } from "react";
import "./WalletSelect.scss";
import { useRef } from "react";
import WalletIconWhite from 'Assets/Icons/wallet-icon-white.svg';
import EternalSVG from 'Assets/Images/eternl_wallet.svg';
import FlintWallet from 'Assets/Images/flintWallet.svg';
import NamiWallet from 'Assets/Images/NamiWallet.svg';
import TyphonWallet from 'Assets/Images/TyphonWallet.svg';
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import WalletIconNew from 'Assets/Images/wallet-manage.svg';
import geroWalletIcon from 'Assets/Icons/gero-wallet-icon.svg';
import { useNavigate } from "react-router-dom";
import useAuth from "App/hooks/use-auth";

const WalletSelect = ({ current, availble = [], onSelect, loading = false }) => {
    const popupRef = useRef();
    const [wallets, setWallets] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [isLoggedIn] = useAuth();
    

    const focusHandler = () => {
        setShow(prev => !prev);
    }

    const blurHandler = () => {
        setShow(false);
    }

    const selectWallet = (e) => {
        e.stopPropagation();
        setShow(false);
        const { dataset: { value, id } } = e.currentTarget;
        if (id) {
            window.open(id);
        } else {
            onSelect(value);
        }
    }

    useEffect(() => {
        let defaultWallet = [{
            name: "eternl",
            icon: EternalSVG,
            version: "0.1.0",
            installUrl: "https://chrome.google.com/webstore/detail/eternl/kmhcihpebfmpgmihbkipmjlmmioameka",
        },
        {
            name: "Flint Wallet",
            icon: FlintWallet,
            version: "0.1.0",
            installUrl: "https://chrome.google.com/webstore/detail/flint-wallet/hnhobjmcibchnmglfbldbfabcgaknlkj",
        }, {
            name: "Nami",
            icon: NamiWallet,
            version: "0.1.0",
            installUrl: "https://chrome.google.com/webstore/detail/nami/lpfcbjknijpeeillifnkikgncikgfhdo",
        }, {
            name: "Typhon Wallet",
            icon: TyphonWallet,
            version: "0.1.0",
            installUrl: "https://chrome.google.com/webstore/detail/typhon-wallet/kfdniefadaanbjodldohaedphafoffoh",
        }];

        let val = availble?.filter((x, i) => availble?.findIndex((y, j) => y?.name === x?.name) === i);
        const walletDefault = val?.map(wallet => {
            const existWallet = defaultWallet?.filter(x => x?.name === wallet?.name);
            if (existWallet?.length) {
                defaultWallet = defaultWallet?.filter((x) =>
                    !existWallet?.some(y => y?.name === x?.name)
                );
            }
            if(wallet?.name === "GeroWallet")
                wallet = {...wallet, icon: geroWalletIcon}
            return wallet;
        });
        let walletList = [...defaultWallet, ...walletDefault]?.map(val => {
            return { ...val, name: val?.name?.toLowerCase()?.replaceAll("wallet", "") };
        });
        setWallets(walletList);
        if (current) {
            val?.map(w => w.active = false);
            const { walletName, roundBalance } = current;
            const foundIcon = val?.find(w => w?.name?.toLowerCase() === walletName?.toLowerCase());
            if (foundIcon) {
                foundIcon.active = true;
            }
            setSelectedWallet({ walletName, balance: roundBalance, icon: foundIcon?.icon });
        }
    }, [availble, current]);

    const onMouseBlur = useCallback(() => {
        setShow(false);
    }, []);

    const onMouseFocus = useCallback(() => {
        setShow(true);
    }, []);

    const navToWallet = () => {
        navigate("profile/wallet-authentication");
    }

    return (
        <div className={`wallet-select ${show ? 'open-popup' : 'close-popup'} ${loading ? 'loading' : ''}`}
            onClick={focusHandler}
            onMouseOverCapture={onMouseFocus} onMouseLeave={onMouseBlur} onBlur={blurHandler}
            tabIndex={-1} ref={popupRef}>
            {loading ? <SkeletonLoader height={18} count={1} inline={true} /> :
                <Fragment>
                    {(selectedWallet?.walletName) ?
                        <div className="wallet-select-label flex items-center justify-center">
                            <img src={selectedWallet?.icon} alt={selectedWallet?.name} />
                        </div> :
                        <div className="cursor-pointer flex items-center justify-center h-[3rem] w-[3rem]">
                            <img src={WalletIconWhite} alt="wallet icon" className="h-[2rem] w-[2rem]"/>
                        </div>
                    }

                    <div className="wallet-select-available" style={{ display: wallets.length ? 'block' : 'none' }}>
                        <ul>
                            {isLoggedIn && <div className="pb-[1rem]">
                            <div>
                                <span className="pl-[1rem]">Wallets</span>
                            </div>
                            <li key={''} data-id={''} onClick={navToWallet} data-value={''} className={`flex connect-wallet`}>
                                <div className="flex items-center gap-[.625rem] px-[.5rem]">
                                    <img src={WalletIconNew} alt="wallet icon" />
                                    <span>Manage</span>
                                </div>
                            </li>
                            </div>}
                            {/* <li className={`flex refresh-wallet`}>
                                <div className="flex items-center gap-[.625rem] px-[.5rem]">
                                    <img src={refreshIcon} alt="refresh icon" />
                                    <span>Refresh</span>
                                </div>
                            </li> */}
                            <div className="pl-[1rem] pb-[.4rem] ">
                                <span >Connect</span>
                            </div>  
                            {
                                wallets.map((wallet) => {
                                    return (
                                        <li key={wallet.name} data-id={wallet?.installUrl} onClick={selectWallet} data-value={wallet.name} className={`${wallet.active ? 'activeWallet' : ''}`}>
                                            <div className="wallet-select-available-icon">
                                                <img src={wallet.icon} alt={wallet.name} />
                                            </div>
                                            <div className="wallet-select-available-label">{wallet.name}</div>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </Fragment>
            }
        </div>
    );
};

export default WalletSelect;
