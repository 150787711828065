import { useEffect, useRef } from "react";
import "./HomeV2.scss";
import { useStore } from "App/hooks-store/store"
import useScreenSize from "App/hooks/use-screen-size";
import { HomeCollectorGallery } from "./Components/CollectorGallery";
import { HomeCreatorGallery } from "./Components/CreatorGallery";
import { HomeCreator } from "./Components/Creators";
import { HomeNewCollection } from "./Components/NewCollection";
import { HomeStatitics } from "./Components/Statitics";
import useAuth from "App/hooks/use-auth";
import { HomeAnnouncements } from "./Components/Announcements";
import { HomeEvents } from "./Components/Events";
import HomeCategoriesTileView from "App/Components/Charts/HomeCategoriesTileView";
const HomeV2 = () => {
  const screenSize = useScreenSize()?.width;
  const [{leftMenuCollapse}, dispatch] = useStore(false);
  const targetRef = useRef();
  const isLoggedIn = useAuth()[0]
  const updateHeight = () => {
    const root = document.documentElement;
    if(targetRef.current) {
        root?.style.setProperty(
            "--home-fcollection-height", `${targetRef.current.offsetWidth}px`
        );
    }
  }
  useEffect(() => {
    // Hiding menu icons box
    dispatch('resetAll');
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    setTimeout(() => {
        updateHeight()            
    }, 1000);
    updateHeight()
  },[leftMenuCollapse, screenSize]);
  const handlePreview = (id, type) => {
    let gType = 'meta';
    if (type === '3d') {
    } else if(type === '2d') {
      gType = 'mono';
    }else if(type === '2ds') {
      gType = 'multi';
    }
    callTwodGallery(id, 0, gType);
  }
  const callTwodGallery = (id, type, page) => {
    const data = new URLSearchParams({
      id,
      type,
      page
    })
    window.open(`/preview-gallery?${data}`, '_blank');
  } 
    return (
        <div className="home-layout page-shadow h-full overflow-lg-auto">
            <div className="home-container flex flex-col gap-8">
                <HomeCategoriesTileView/>
                <HomeStatitics />
                {isLoggedIn && <HomeAnnouncements screenSize={screenSize} />}                
                {isLoggedIn && <HomeEvents screenSize={screenSize} />}       
            </div>
        </div>
      )
}

export default HomeV2;