import React from 'react'

const ChartSkelton = () => {
    const chartLoader = (key) =>
        <div key={key} role="status" className="p-4 h-[50dvh] overflow-hidden border rounded-xl shadow animate-pulse md:p-6 border-gray-700">
            <div className="h-2.5 rounded-full bg-gray-700 w-32 mb-2.5"></div>
            {/* <div className="w-48 h-2 mb-10 rounded-full bg-gray-700"></div> */}
            <div className="flex items-baseline mt-4">
                <div className="w-full  rounded-t-lg h-72 bg-gray-700"></div>
                <div className="w-full h-32 ms-6  rounded-t-lg bg-gray-700"></div>
                <div className="w-full rounded-t-lg h-72 ms-6 bg-gray-700"></div>
                <div className="w-full h-64 ms-6 rounded-t-lg bg-gray-700"></div>
                <div className="w-full rounded-t-lg h-80 ms-6 bg-gray-700"></div>
                <div className="w-full  rounded-t-lg h-72 ms-6 bg-gray-700"></div>
                <div className="w-full  rounded-t-lg h-80 ms-6 bg-gray-700"></div>
            </div>
        </div>
    return <>
        <div className="grid grid-cols-5 gap-4 py-[1rem]">
            <span className="border border-[var(--cu-black-50)] bg-[var(--cu-black-50)] rounded-xl h-20 animate-pulse" />
            <span className="border border-[var(--cu-black-50)] bg-[var(--cu-black-50)]  rounded-xl h-20 animate-pulse" />
            <span className="border border-[var(--cu-black-50)] bg-[var(--cu-black-50)]  rounded-xl h-20 animate-pulse" />
            <span className="border border-[var(--cu-black-50)] bg-[var(--cu-black-50)]  rounded-xl h-20 animate-pulse" />
            <span className="border border-[var(--cu-black-50)] bg-[var(--cu-black-50)]  rounded-xl h-20 animate-pulse" />
        </div>
        <div className="h-full grid grid-cols-2 gap-10 ">{[1, 2].map((item) => chartLoader(item))}
        </div>
    </>
}

export default ChartSkelton