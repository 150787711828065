import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.scss';
const RichTextEditor = (props) => {
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link'],
            ['clean'],
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'link', 'image',
    ];

    return (<div className={`${props?.parentClass}`}>
        <ReactQuill
            placeholder={props?.isPlaceholder ?? `Enter`}
            className={`${props?.rQuillClass}`}
            value={props?.value}
            onChange={props?.onChange}
            modules={modules}
            formats={formats}
        />
    </div>)
}

export default RichTextEditor