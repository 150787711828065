import { useCallback, useMemo, useRef, useState } from "react"
import Gallery from "../Gallery"
import SelectedGallery from "../SelectedGallery"
import SelectedPopup from "../../../SelectedPopup"
import { useEffect } from "react"
import AddedNfts from "../AddedNfts"
import PreviewGallery from "../PreviewGallery"
import { markAsTouched, validateFormGroup } from "App/Helper/formValidation"
import useHttp from "App/hooks/use-http"
import GalleryScreenshot from "../GalleryScreenshot"
import { capitalizeFirstLetter, findNumberFromString, getAvailableGallery, removeDuplicateByElement } from "App/Helper/utilities"
import { useStore } from "App/hooks-store/store"
import useCollectionLoader from "App/hooks/use-collectionLoader"
import WarningBox from "App/Components/Ui/WarningBox"
import useAuth from 'App/hooks/use-auth';
import { useNavigate, useParams } from "react-router-dom";
import useScreenSize from "App/hooks/use-screen-size"
import useLayoutData from "App/hooks/use-layout";
import { useDispatch, useSelector } from "react-redux"
import { getCurrentFormValues, setCurrentFormValues, setFormInitiated } from "App/ReduxStore/Slicers/gallerySlice"
import useGallery from "App/hooks/use-gallery"
const GalleryCollection = (props) => {
    const screenSize = useScreenSize()?.width;
    const { communityid, policyid } = useParams();
    const navigate = useNavigate()
    const [value, setValue] = useState({ mode: false })
    const [selectedListView, setSelectedListView] = useState();
    const [click, setClick] = useState({ e: '', click: 0 })
    const [action, setAction] = useState('action')
    const [selectedWall, setSelectedWall] = useState('')
    const [formVaildState, setFormVaildState] = useState({});
    const { triggerAPI } = useHttp();
    const [showPopup, setShowPopup] = useState({ mode: false })
    const [frameNftImage, setFrameNftImage] = useState({})
    const [newNftCollection, setNewNftCollection] = useState([])
    const [displayMode, setDisplayMode] = useState("lg")
    const [{ currentSelectedNfts, currentGallery,  
        collectionRawData, singleCollection, allUserCollection, overAllAssets}, dispatch] = useStore(false);
    const timer = useRef(null);
    const { sliceCollectionAPI } = useCollectionLoader();
    const [showLoadingPopup, setShowLoadingPopup] = useState(false)
    const [isSingle, setIsSingle] = useState(false)
    const [communityCollectionAssetIds, setCommunityCollectionAssetIds] = useState(null)
    const isLoggedIn = useAuth()[0]
    const [ratios, setRatios] = useState([]);
    const dispatchRedux = useDispatch();
    const currentForm = useSelector(getCurrentFormValues)
    const { getAvailableCreatorAssets } = useGallery();

    const galleryid = useMemo(() => {
        return props?.galleryid;
    },[props?.galleryid]);

    const pgaction = useMemo(() => {
        return props?.pgaction;
    },[props?.pgaction]);
    useEffect(() => {
        dispatch("setPage", communityid ? "community-gallery" : "gallery")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communityid])
    const pageType = useMemo(() => {
        return (props?.pageType === 'edit' || props?.pageType === 'view') ? `${props?.pageType}/${props?.galleryid}` : props?.pageType;
    },[props?.pageType, props?.galleryid]);

    useEffect(() => {
        dispatch('setActiveIcons', {
            displayType: 'lg'
        });
        return () => {
            dispatch('resetCurrentGalleryAssets')
            dispatchRedux(setFormInitiated(false));
            dispatch('resetAll')
            dispatch("setPage",communityid?"community-gallery":"gallery")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communityid])
    const apiResult = ({ data, message }) => {
        setShowPopup({ mode: false })
        let id = (communityid ? data?.galleryId : data?.id) ?? currentForm?.id
        dispatch('setCurrentFormValues', {})
        dispatchRedux(setCurrentFormValues({}))
        let viewUrl = !communityid ? `/view/${id}` : '';
        navigate(`${props?.galleryBaseUrl}${viewUrl}`, { replace: true })
        dispatch('showToast', { toast: { toastMode: 'success', message: message } })
    }
    const { getLayoutFromStore } = useLayoutData();
    const [layoutList, setLayoutList] = useState([])    
    const [wallList, setWallList] = useState([])    
    const getWallDetails = (getWallResult) => {        
        triggerAPI({
            url: `background-thumbnail/user/get`, data: { color: props?.color, style: props?.style }, method: 'post'
        }, getWallResult);
    }
    useEffect(() => {
        getLayoutFromStore((res) => {
            setLayoutList(res);
        }) 
        getWallDetails((res) => {
            setWallList(res.data);
        })       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addNftGalleryHandler = (slider) => {
        const apiCall = () => {
            const formData = updateFormData(slider, 'collection');

            const { valid } = validateFormGroup({ errors: formVaildState, formGroup: props?.formGroup, values: currentForm });
            if (valid) {
                const errorHandler = (error) => {
                    dispatch('showToast', { toast: { toastMode: 'error', message: capitalizeFirstLetter(error?.response?.data?.error?.message) } })
                    setShowPopup({ mode: false })
                }
                if (currentForm?.id) {
                    triggerAPI({
                        url: `gallery/asset-update/${currentForm?.id}`, data: formData, method: 'put'
                    }, apiResult, errorHandler);
                }
                else {
                    triggerAPI({
                        url: 'gallery/add', data: formData, method: 'post'
                    }, apiResult, errorHandler);
                }


            } else {
                const newValues = markAsTouched(currentForm);
                dispatch('setCurrentFormValues', newValues)
                dispatchRedux(setCurrentFormValues(newValues))

            }
        }
        apiCall()
    }
    const addCommunityGallery = (slider) => {
        const apiCall = () => {
            const formData = updateFormData(slider, 'community');
            const { valid } = validateFormGroup({ errors: formVaildState, formGroup: props?.formGroup, values: currentForm });
            if (valid) {
                const errorHandler = (error) => {
                    dispatch('showToast', { toast: { toastMode: 'error', message: capitalizeFirstLetter(error?.response?.data?.error?.message) } })
                    setShowPopup({ mode: false })
                }
                if (currentForm?.id) {
                    triggerAPI({
                        url: `gallery/asset-update/${currentForm?.id}`, data: formData, method: 'put'
                    }, apiResult, errorHandler);
                }
                else {
                    triggerAPI({
                        url: 'community/gallery/create', data: formData, method: 'post'
                    }, apiResult, errorHandler);
                }
            } else {
                const newValues = markAsTouched(currentForm);
                dispatch('setCurrentFormValues', newValues)
                dispatchRedux(setCurrentFormValues(newValues))
            }
        }
        apiCall()
    }
    const updateFormData = (slider, type) => {
        const formData = new FormData()
            if (frameNftImage) {
                for (const key in frameNftImage) {
                    if (Object.hasOwnProperty.call(frameNftImage, key)) {
                        const element = frameNftImage[key];
                        const sequence = findNumberFromString(key) + 1;
                        formData.append(`file[${sequence}]`, element.blob)
                    }
                }
            }
            let i = 1;
            for (const key in slider) {
                if (Object.hasOwnProperty.call(slider, key)) {
                    const element = slider[key];
                    formData.append(`slider[${i}]`, element.blob)
                    i++
                }
            }
            let updatedAssets = currentForm?.assets?.map(val=>{
                let assetData = overAllAssets?.find(assetData => assetData?.asset === val?.asset)
                let data = {...val, policy_id: assetData?.policy, asset_name: assetData?.asset_name }
                let asset = data?.data;
                asset && delete data.data
                return {...data}
            })
            formData.append('assets', JSON.stringify(updatedAssets))
            formData.append('layoutId', currentForm.layoutId ?? layoutList?.filter(l => l.count === 1)?.[0]?.id)
            formData.append('description', currentForm.description ?? '')
            formData.append('name', currentForm.name)
            formData.append('twoDWallBackgroundId', currentForm.twoDWallBackgroundId ?? wallList?.[0]?.id)
            formData.append('threeDWallBackgroundId', !!currentForm.threeDWallBackgroundId ? currentForm.threeDWallBackgroundId : 1)
            if(currentForm?.showPrimaryDiscoverable) { 
                formData.append('priority', currentForm.priority ?? 0)
                formData.append('isDiscoverable', currentForm.isDiscoverable??false)
            }
            if(type === 'community') {
                formData.append('communityId', communityid)
                formData.append('rentalPrice', currentForm?.rentalPrice)
                formData.append('rentalSplit', currentForm?.rentalSplit)
            }            
            formData.append("ratios", JSON.stringify(ratios));                        
            return formData;
    }
    const sortOptionsAdd = [
        { id: 0, value: "Name A-Z" },
        { id: 1, value: "Name Z-A" }
    ]
    const [filter, setFilter] = useState({
        sort: sortOptionsAdd[0].value
    })

    const onSelected = useCallback((e) => {
        if (e.source === "community") {
            // setSelectedGallery(e.policy);
            //get assetids for collection, then use those in AssetOnDemand component        
                navigate(`${props?.galleryBaseUrl}/${pageType}/${pgaction}/${e.policy}`)    
            const params = new URLSearchParams({
                collectionId: e.policy
                });        
            triggerAPI(
            {
                url: `community/collection/get-one?${params}`,            
                method: "get",
            }, (res) => {                
                setCommunityCollectionAssetIds(res.data.assets);
            });
        } else {
            // setSelectedGallery(e); //need to remove after updating urls
            if (e !== 'Single') {                        
                let rawData = collectionRawData;
                const filterCollction = rawData?.find(aData => aData.policy_id === e)
                if(filterCollction?.data?.length) {                    
                    dispatch("updateCollectionLoadingStatus", 'in-progress')
                    sliceCollectionAPI(filterCollction?.data);
                }
                navigate(`${props?.galleryBaseUrl}/${pageType}/${pgaction}/${e}`)
            }
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props?.nftCollections, pgaction, pageType]);
    
    useEffect(() => {
        let selectedList = props?.nftCollections?.filter((val) => val.policy === policyid);
        dispatch('setCurrentList', selectedList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.nftCollections, policyid])
    const onClickHandler = useCallback((e) => {
        let includeSelectedNfts = currentSelectedNfts?.some((el) => el.asset === e)
        if (!includeSelectedNfts) {
            let selectedListGallery = [...props?.nftCollections]?.filter((val) => val.asset === e)
            if (selectedListGallery.length === 0)
            {
                selectedListGallery = [...singleCollection]?.filter((val) => val.asset === e)
            }
            dispatch('setCurrentSelectedNfts', [...currentSelectedNfts, ...selectedListGallery])

        }
        else {
            let removeListGallery = currentSelectedNfts.filter((val) => val.asset !== e)
            dispatch('setCurrentSelectedNfts', removeListGallery)

        }
    }, [props?.nftCollections, singleCollection, currentSelectedNfts, dispatch]);
    const onClickSelect = useCallback((e) => {
        let selectedListGallery = [...props?.nftCollections]?.filter((val) => val.asset === e)
        setSelectedListView(selectedListGallery)
    },[props?.nftCollections])

    const onClickGallery = (e) => {
        if (currentSelectedNfts.length)
            navigate(`${props?.galleryBaseUrl}/${pageType}/save`)
        else
            dispatch('showToast', { toast: { toastMode: 'error', message: 'Please Select atleast one Asset' } })
    }


    useEffect(() => {
        if (screenSize >= 551) {

            if (click.click === 1) {
                onClickHandler(click.e)
                setClick({ e: '', click: 0 });

                if (action !== 'close') {
                    onClickSelect(click.e)
                    setValue({ mode: true })
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [click, action]);

    useEffect(() => {
        if (screenSize < 551) {
            if (click.click === 1) {
                onClickHandler(click.e)
                setClick({ e: '', click: 0 });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [click])
    
    const viewToEdit = useCallback(() => {
        props?.setIsCreating(true)
        if (props?.galleryid) {
            if(!communityid) {
                let assets = getAvailableGallery(allUserCollection, currentGallery?.assets)
                let currentArray = {...currentGallery, assets}
                dispatch('setCurrentGallery', currentArray)
                dispatchRedux(setFormInitiated(true));
                navigate(`${props?.galleryBaseUrl}/edit/${props?.galleryid}/save`)
            } else {
                const checkAssets = () => {
                    dispatch('showSpinner')
                    getAvailableCreatorAssets(props?.galleryid, communityid, (res) => {
                        dispatch("hideSpinner");
                        let { data } = res;
                        let assets = getAvailableGallery(data, currentGallery?.assets)
                        let currentArray = {...currentGallery, assets}
                        dispatch('setCurrentGallery', currentArray)
                        dispatchRedux(setFormInitiated(true));
                        navigate(`${props?.galleryBaseUrl}/edit/${props?.galleryid}/save`)
                    })
                    } 
                checkAssets();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props?.galleryid, communityid, allUserCollection, currentGallery, pageType]);
    
    const onAction = (e) => {
        let value = e?.currentTarget?.dataset?.action;
        setAction(value)
        if (value === 'close') {
            let removeListGallery = currentSelectedNfts.filter((val) => val.asset !== e?.currentTarget?.dataset?.id)
            dispatch('setCurrentSelectedNfts', removeListGallery)
        }
    }

    const onFilterChange = (e) => {
        setFilter(e)
    }

    const OnSelectWall = (e) => {
        setSelectedWall(e)
    }

    const addNftHandlerSet = () => {
        try {
            if( isLoggedIn ) {
                const newCurrentFormValues = { ...currentForm }
                const { valid } = validateFormGroup({ errors: formVaildState, formGroup: props?.formGroup, values: newCurrentFormValues });
                if (valid) {
                    props?.setIsCreating(false)
                    setShowPopup({ mode: true })
                } else {
                    const newValues = markAsTouched(newCurrentFormValues);
                    dispatch('setCurrentFormValues', newValues)
                    dispatchRedux(setCurrentFormValues(newValues))
                    setShowPopup({ mode: false })
                }
            } else {
                props?.setShowAuthPop(true);
            }
        } catch (error) {
            console.log('error logged', error);
        }
    }

    const onCloseHandler = () => {
        setShowPopup({ mode: false })
    }

    const screenCapture = (e) => {
        if (e?.file0) {
            if (timer.current) {
                clearTimeout(timer.current)
            }
            timer.current = setTimeout(() => {
                if(communityid) {
                    addCommunityGallery(e)
                } else {
                    addNftGalleryHandler(e)
                }

            }, 1000);
        }
    }
    const screenCaptureNft = (e) => {
        setFrameNftImage(e)
    }
    useEffect(() => {
        if (props?.galleryid !== '') { 
            let temp = {...currentGallery}
            if(communityid)
                temp = {...temp, rentalPrice: temp?.rentalPrice?.toString(), rentalSplit: temp?.rentalSplit?.toString()} //by default if 0, its consider false so updated to string
            dispatch('setCurrentFormValues', temp)
            dispatchRedux(setCurrentFormValues(currentGallery))                        
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.galleryid, currentGallery])

    useEffect(() => {
        if(props?.nftCollections) {
            let removedItems = removeDuplicateByElement([...props?.nftCollections], 'asset');
            setNewNftCollection(removedItems)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.nftCollections])

    const onToGallery = () => {
        navigate(props?.galleryBaseUrl)
        props.setIsCreating(false)
    }
    const onCancel = () => {
        setShowLoadingPopup(false)
    }

    const imageRatioCalculated = useCallback((ratio) => {      
        if (!ratios.includes(ratio))
        {            
            ratios.push(ratio)                      
            setRatios(ratios);
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ratios]);
    return (
        <>
            {(pgaction === 'collection' || pgaction === 'token') &&
                <div className={`w-full gallery-page h-full`} >
                    {((pgaction === 'collection' || pgaction === 'token') && !policyid) &&
                        <Gallery onSelected={onSelected}
                            setCollectionLevelLoading={props?.setCollectionLevelLoading}
                            isEditPage={pageType}
                            galleryBaseUrl={props?.galleryBaseUrl}
                            setIsSingle={setIsSingle}
                            onClickHandler={onClickHandler}
                            nftCollections={newNftCollection}
                            tabSelected={props?.tabSelected}
                            onToGallery={onToGallery}
                            setDisplayMode={setDisplayMode}
                            isToken={props?.isToken}
                            onClickPreview={onClickGallery}
                            currentSelectedTab={props?.currentSelectedTab}
                            updateCollectionFilter={props?.updateCollectionFilter}
                            collectionLevelLoading={props?.collectionLevelLoading}
                            setIsCreating={props.setIsCreating}
                        /> 
                    }                    
                    {((pgaction === 'collection' || pgaction === 'token') && policyid) &&
                        <SelectedGallery value={value}
                            pageType={pageType}
                            galleryBaseUrl={props?.galleryBaseUrl}
                            setIsSingle={setIsSingle}
                            isSingle={isSingle}
                            filter={filter}
                            noDataMessage="pieces"
                            setValue={setValue} click={click}
                            onClickHandler={setClick}
                            selectedListView={selectedListView} sort={sortOptionsAdd}
                            onClickGallery={onClickGallery} onFilterChange={onFilterChange}
                            OnClick={onToGallery}
                            action={onAction} displayMode={displayMode} onClickExit={onToGallery} 
                            onDemandAssetIds={communityCollectionAssetIds} setCommunityCollectionAssetIds={setCommunityCollectionAssetIds}
                            policyId={policyid}
                            hasScroll={true}
                            pageCount={16}
                            parentScrollContainerQuery={communityid ? ".gallery-second-level" : null}
                            />                        
                    }
                    {console.log('!!communityid', communityCollectionAssetIds)}
                    <SelectedPopup action={onAction} onClickPreview={onClickGallery} />
                </div>
            }
            {pgaction === 'save' &&
                <AddedNfts
                    screenCapture={screenCaptureNft} 
                    galleryBaseUrl={props?.galleryBaseUrl}
                    setFormVaildState={setFormVaildState}
                    onClickAdd={addNftHandlerSet}
                    OnSelectWall={OnSelectWall}
                    selectedWall={selectedWall}
                    action={onAction} pageType={pageType}
                    showPrimaryDiscoverable={props?.showPrimaryDiscoverable}
                    imageRatioCalculated={imageRatioCalculated}
                    onToGallery={onToGallery}
                    setRatios={setRatios}
                />
            }
            {(pageType?.includes('view') && !!(galleryid)) && 
                <PreviewGallery 
                    pageType={pageType}
                    fromMainPage={true}
                    type={screenSize <= 550 && "collectors"} 
                    onClickDeleteGallery={props?.onClickDeleteGallery} standalone={true} selectedWall={selectedWall}
                    galleryUpdateId={galleryid}
                    OnClick={viewToEdit} onClickExit={onToGallery} applyFrameButton={false}
                    onClose={onToGallery} backUrl={props?.galleryBaseUrl}
                    className="top-0 pl-[1rem] pr-[1.25rem] pt-0 pb-0"
                />
            }
            {showPopup && <GalleryScreenshot screenCapture={screenCapture} galleryList={currentGallery} show={showPopup} className="scrn"
                onClose={onCloseHandler}
            />}
            {showLoadingPopup && <WarningBox onCancel={onCancel} >
                Please wait while collection loads.....
            </WarningBox>}
        </>
    )
}

export default GalleryCollection
