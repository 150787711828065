import "./MyGallery.scss"
import { Fragment, useCallback, useEffect, useState } from "react";
import { useStore } from "App/hooks-store/store";
import Card from "App/Components/Card";
import useScreenSize from "App/hooks/use-screen-size";
import { getPreviewGalleryLink } from "App/Helper/utilities";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import SubscriptionWarning from "../SubscriptionWarning";
import { useNavigate } from "react-router-dom";
import { setFormInitiated } from "App/ReduxStore/Slicers/gallerySlice";
import { useDispatch } from "react-redux";
import AddPlusIconButton from "App/Components/AddPlusIconButton";

const MyGallery = ({ galleryList = [], setPrimaryHandler=()=>{}, showGallerySkelton = false,
    selectedCollection = '', setGalleryUpdateId, onClick = ()=>{}, wall = [],warningPopup=false,
    isSubscriptionReached=false, setGalleryPreview, handleEdit, onClickDeleteGallery, onCancel }) => {
    const [gallery, setGallery] = useState([]);
    const navigate = useNavigate()
    const dispatch = useStore()[1];
    const [mouseEnter, setMouseEnter] = useState(false);
    const dispatchRedux = useDispatch();
    const onMouseBlur = useCallback(() => {
        setMouseEnter(false)
    }, []);
    const blurHandler = () => {
        setMouseEnter(false)
    }
    const onMouseFocus = useCallback(() => {
        warningPopup && setMouseEnter(true)
    }, [warningPopup]);
    useEffect(() => {
        setGallery(galleryList)
    }, [galleryList])
    
    const onGalleryPreviewHandler = (e, disable) => {
        if(!disable) {
            const galleryId = e?.currentTarget?.dataset?.id;
            let currentArray = gallery?.find(item => item.id === parseInt(galleryId))
            dispatch('setCurrentGallery', currentArray)
            navigate(`/curate/my-galleries/view/${galleryId}`);
            dispatchRedux(setFormInitiated(true));
        }
    }
    
    const handlePreview = (id, previewType, e) => {
        e.stopPropagation();
        getPreviewGalleryLink(id, previewType);
    }

    const screensize = useScreenSize()?.width;
    const discoverableGalleries = gallery.filter((gallery) => gallery.isDiscoverable === true);
    const nonDiscoverableGalleries = gallery.filter((gallery) => gallery.isDiscoverable === false);

    return (
            <>
                <div className={`w-full overflow-flex-auto flex flex-col justify-between  pt-4 my-gallery-banner`}>
                    <div className="pt-0 px-4 sm:pb-4 pb-0 pr-sm  flex justify-between items-center relative" onMouseLeave={onMouseBlur}>
                        <div className="flex flex-col justify-center text-[1.125rem] font-medium"><p className="text-[1.25rem]">Galleries</p></div>
                        <div onMouseOverCapture={onMouseFocus}
                        onBlur={blurHandler}>
                         <AddPlusIconButton className={`flex items-center ml-auto ${warningPopup&&"warning-btn"}`} disabled={showGallerySkelton} onClick={onClick}/>
                           <div className={`warning-toolkit ${mouseEnter&&"active"}`}><SubscriptionWarning content="Galleries" isSubscriptionReached={isSubscriptionReached}/></div>
                        </div>
                    </div>
                    {(gallery && !gallery?.length && !showGallerySkelton) && <div className="visibleSlowly flex items-center no-data justify-center">There are currently no galleries.</div>}
                        
                        {(gallery?.length||showGallerySkelton)&&<div className=" text-left pl-4 mt-[1.5rem] mb-[0.5rem]">
                            <p>Public <span className="text-[#606573]">({ discoverableGalleries?.length})</span></p>
                        </div>} 
                        {gallery && !showGallerySkelton&& <div className={`pt-0 p-4 pr-sm grid gap-2.5 ${screensize > 550 ? 'home-grid' : ''}  gallery-top-sm`}>
                            {
                                discoverableGalleries?.map((info, i) => {
                                    info = { ...info, createdAt: info?.nftDropStartedAt }
                                    return (
                                        <Fragment key={i}>
                                            <div key={i} data-id={info?.id} onClick={(e) => onGalleryPreviewHandler(e, info?.disableGallery)} className='cursor-pointer'>
                                                <Card
                                                    key={i}
                                                    cardProp={info}
                                                    type="Gallery"
                                                    setPrimaryHandler={setPrimaryHandler}
                                                    selectedCollection={selectedCollection}
                                                    preview={!info?.disableGallery}
                                                    showEdit={info?.disableGallery}
                                                    handleEdit={handleEdit}
                                                    handlePreview={handlePreview}
                                                    onClickDeleteGallery={onClickDeleteGallery}
                                                    onCancel={onCancel}
                                                /></div>

                                        </Fragment>)
                                })

                            }
                            
                            {/* {[...Array(3)].map((val, i) => (
                                <div key={i + 1} >
                                    <SkeltonCard noAbsolute={true} show={showGallerySkelton} height="15.4rem" inline={true} containerClassName="leading-none  h-full  " />
                                </div>
                            ))} */}
                    </div>}
                    <div className={`pt-0 p-4 pr-sm `}>
                        <DetailsSkeltonCard
                            show={showGallerySkelton}
                            height={"100%"}
                            count={3}
                            inline={true}
                            details={"1-line"}
                            noAbsolute
                            parentDiv="grid home-grid mt-0 aspect-pt-six  details-gap"
                            containerClassName={`flex`}                   
                        />
                    </div>
                    {(gallery?.length||showGallerySkelton)&&<div className=" text-left pl-4 mb-[0.5rem]">
                            <p>Private <span className="text-[#606573]">({nonDiscoverableGalleries?.length})</span></p>
                        </div>}
                        {gallery &&!showGallerySkelton&&<div className={`pt-0 p-4 pr-sm grid gap-2.5 ${screensize > 550 ? 'home-grid' : ''}  gallery-top-sm`}>
                             
                                {nonDiscoverableGalleries?.map((info, i) => {
                                    info = { ...info, createdAt: info?.nftDropStartedAt }
                                    return (
                                        <Fragment key={i}>
                                            <div key={i} data-id={info?.id} onClick={(e) => onGalleryPreviewHandler(e, info?.disableGallery)} className='cursor-pointer'>
                                                <Card
                                                    key={i}
                                                    cardProp={info}
                                                    type="Gallery"
                                                    setPrimaryHandler={setPrimaryHandler}
                                                    selectedCollection={selectedCollection}
                                                    preview={!info?.disableGallery}
                                                    showEdit={info?.disableGallery}
                                                    handleEdit={handleEdit}
                                                    handlePreview={handlePreview}
                                                    onClickDeleteGallery={onClickDeleteGallery}
                                                    onCancel={onCancel}
                                                /></div>

                                        </Fragment>)
                                })

                            }
                          
                    </div>} 
                    <div className={`pt-0 p-4 pr-sm`}>
                        <DetailsSkeltonCard
                            show={showGallerySkelton}
                            height={"100%"}
                            count={3}
                            inline={true}
                            details={"1-line"}
                            noAbsolute
                            parentDiv="grid home-grid mt-0 aspect-pt-six details-gap"
                            containerClassName={`flex`}                   
                        />
                    </div>
                </div>
            </>
    )
}

export default MyGallery