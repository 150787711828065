import SkeltonCard from "App/Components/Ui/SkeltonCard";
import SelectedNftCard from "../../../SelectedCollection/Components/SelectedNftCard";
import Button from "App/Components/Ui/Button";
import leftarrow from "Assets/Icons/left-arrow-white.svg";
import rightarrow from "Assets/Icons/right-arrow-white.svg";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ExpandIcon from "Assets/Images/ExpandIcon.svg"
import { useStore } from "App/hooks-store/store";
import useScreenSize from "App/hooks/use-screen-size";

const EnLargeAssetList = (props) => {
    const screenSize = useScreenSize()?.width;
    const [assetpageNumber, setAssetpageNumber] = useState(1)
    const [currentList, setCurrentList]  = useState([]);
    const [assetTotalPages, setAssetTotalPages] = useState(1)
    const targetRef = useRef();
    const store = useStore()[0];
    const { leftMenuCollapse } = store;
    const perPageCount = screenSize > 550 ? 9 : 4;
    const RenderArrows = () => {
        return (
            <div className="slider-arrow">
            {assetpageNumber > 1 && 
              <Button
                className="arrow-btn prev cursor-pointer"
                onClick={prevPage}
              >
                <img alt='img' src={leftarrow} />
              </Button>
            }
            {assetpageNumber < assetTotalPages && 
              <Button
                className="arrow-btn next"
                onClick={nextPage}
              >
                <img alt='img' src={rightarrow} />
              </Button>
            }
            </div>
          );
     }
     useEffect(() => {
        const {offset, limit} = getLimitOffset(assetpageNumber);
        if(props?.nftList?.length) {
            let currentList = props?.nftList.slice(offset, limit);
            if(currentList?.length)
                setCurrentList(currentList);
        }
       // eslint-disable-next-line react-hooks/exhaustive-deps
       },[props?.nftList, assetpageNumber])
       
       useEffect(() => {
        let totalItems = props?.nftList?.length ?? 0, totalPages = 1;
            if(totalItems) {
                totalPages = Math.round(totalItems/perPageCount);
            }
            setAssetTotalPages(totalPages);
       // eslint-disable-next-line react-hooks/exhaustive-deps
       },[props?.nftList])
    
     const prevPage = () => {
        setAssetpageNumber(prev => prev - 1)
        setTimeout(() => {
            props?.updateHeight();    
        }, 1);
        
        updateHeightCard()   
     }
     const nextPage = () => {
        setAssetpageNumber(prev => prev + 1)
        setTimeout(() => {
            props?.updateHeight();    
        }, 1);
        updateHeightCard()   
     }
    const getLimitOffset = (pgNum) => {
        let currentPage = pgNum - 1;
        let offset = currentPage * perPageCount;
        let limit = perPageCount * pgNum;
        return {offset, limit};
    }
    const updateHeightCard = () => {
      const root = document.documentElement;
      if (targetRef.current) {
          root?.style.setProperty(
              "--collection-enlarge-card-height", `${targetRef.current.offsetWidth}px`
          );
      }
    }
    useLayoutEffect(() => {
        updateHeightCard()
        window.addEventListener("resize", () => {
            setTimeout(() => {
                updateHeightCard()            
            }, 1000);
        }, false);
    },[]);
    useEffect(() => {
        updateHeightCard()  
    },[props.displayNft]);
    
    useEffect(() => {
        setTimeout(() => {
            updateHeightCard()   
            props?.updateHeight();          
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[leftMenuCollapse, props?.showPanel]);
return (
    <>
        <div className={`expand-icon cursor-pointer ${!props?.showPanel ? 'pb-2' : ''}`}>
            {/* <img src={ExpandIcon} alt="" onClick={props?.handleShow} /> */}
        </div>
        {props?.showPanel && 
            <> 
                {!props?.assetListLoading && <RenderArrows />}
                <div className={`asset-slider-container ${!props?.currentAsset && 'justify-center'}`}>
                    <SkeltonCard show={props?.assetListLoading} height="100%" inline={true} noAbsolute={true}
                            containerClassName={`${props?.skeltonClass} z-10`} />
                    {!props?.assetListLoading &&
                        currentList?.map((nft, index) => <div className={`asset-slide-items`} ref={targetRef} key={`${nft.policy}${index}`}>
                            <SelectedNftCard reload={props?.reload} framesList={props.framesList} filteredList={true} selected={!!(props?.currentAsset?.asset === nft?.asset)} index={index} 
                            onCollectionSelect={props?.imageClick} framed={true} hideName={true}
                                                nft={nft} onImgClick={props?.imageClick} displayNft={props?.displayNft} size={256}/></div>
                        )
                    }
                </div>
            </>
        }            
    </>
    )
}

export default EnLargeAssetList;