import { useCallback, useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Card from "App/Components/Card";
import backgroundImage from '../../../../../Assets/Images/display_background.png'
import useHomeApiHelper from "App/Pages/HomeV2/Components/API/apiHelper";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import AddPlusIconButton from "App/Components/AddPlusIconButton";

const DisplayList = (props) => {        
  const navigate = useNavigate();
  const { getCreatorGallery } = useHomeApiHelper();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const [displayList, setDisplayList] = useState(null);
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
   if(props?.displayList) setDisplayList(seeAll?props?.displayList:props?.displayList?.slice(0,3))
   else setDisplayList(null)
  },[props?.displayList,seeAll])
  const navigateToDisplay = (id)=> {
    navigate(`${id}`)
  }
  const getData = useCallback(() => {
    const req = {
        page: 1,
        items: 8,
        filter: {
            showOnHomePageDisplay: true,
        }
    };
    setLoading(true)
    getCreatorGallery(req, (res) => {
        const temp = res?.list?.map(item => {
            const temp = item?.gallery;
            temp.community = item?.community;
            temp.userIcon = item?.community?.displayPicture;
            return temp;
        })
        setLoading(false)
        setGallery(temp);
    });
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
useEffect(() => {
    getData()
},[getData])

const seeAllHandler = ()=>{
  setSeeAll((pre)=>!pre)
}
  return (
    <div className="flex flex-col gap-[1.5rem] pb-[2rem] ">
     {props?.displayList && <div className="title-section flex justify-between items-center">
          <div className="title text-left text-[1.25rem] flex items-center gap-4">
              <div className="flex items-center"><div className="mb-[2px]">Displays</div><div className="text-[#A0ABBB]">&nbsp;({props?.displayList?.length})</div></div>
              <AddPlusIconButton onClick={props.pairClick} />
          </div>
        {!!(props?.displayList?.length>3)&&<div
            className="cursor-pointer my-[.5rem] text-[.675rem] text-[#A0ABBB]"
            onClick={seeAllHandler}
          >
            {`See ${seeAll?"less":"all"}`}
          </div>}
      </div>}      
     {!!props?.currentUserId && <div
          className={`w-full overflow-flex-auto flex flex-col justify-between display-list`}
        >
          <DetailsSkeltonCard
            show={!props.displayList}
            height={"100%"}
            count={3}
            inline={true}
            details={"img-2-line"}
            noAbsolute
            parentDiv="grid home-grid  aspect-pt-five-six details-gap"
            containerClassName={`flex mt-[1.75rem]`}                
          /> 
            {displayList?.length?<div
              className={`sm:pt-0 pt-4 cursor-pointer grid gap-2.5 display-grid`}
            >
              {displayList?.map((info,i) => (                                              
                <Fragment key={i}>
                    <div key={i} data-id={info?.id} className={`cursor-pointer`} onClick={(e)=> { navigateToDisplay(info.id) }}>
                        <Card
                            key={i}
                            cardProp={{...info.gallery, user:info?.gallery?.user, displayName:info.name}}
                            type="Gallery"
                            preview={true}
                            userDetails={true}
                            hideDetails={true}
                            showProfileIcon={true}
                            showCollectorName={true}
                        />
                        </div>
                </Fragment>
              ))}
            </div>:!!Array.isArray(displayList)&&<div className="no-data">No items to display.</div> }                 
        </div>}
    {!seeAll&& <>
        <div className="w-full h-full relative">
          <img src={backgroundImage} className="w-[100%] h-[21rem] object-cover object-center" onLoad={()=>setImageLoaded(true)} alt=""/>
          {imageLoaded && (
          <a href="https://www.anywall.io/" target="_blank" rel="noreferrer" >
          <button className="display-learnmore">Learn More</button>
          </a>)}       
        </div> 
        <div className="title-section flex justify-between">
          <div className="title text-left text-[1.25rem] flex">
              <div>Rental Galleries</div><div className="text-[#A0ABBB]">&nbsp;({gallery?.length})</div>
          </div>
          </div>
        <div
          className={`w-full overflow-flex-auto flex flex-col justify-between`}
        >
          <DetailsSkeltonCard
                            show={loading}
                            height={"100%"}
                            count={3}
                            inline={true}
                            details={"img-2-line"}
                            noAbsolute
                            parentDiv="grid home-grid  aspect-pt-five-six details-gap"
                            containerClassName={`flex mt-[1.75rem]`}/>
            <div
              className={`sm:pt-0 pt-4 cursor-pointer grid gap-2.5 display-grid`}
            >
              {gallery.map((info,i) => (                                              
                <Fragment key={i}>
                    <div key={i} data-id={info?.id} className={`cursor-pointer`} >
                        <Card
                            key={i}
                            cardProp={{...info, name:info.name}}
                            type="Gallery"
                            // preview={true}
                            showProfileIcon={true}
                            showName={true}
                            displayPreview={true}
                        />
                        </div>
                </Fragment>
              ))}
            </div>                    
        </div> 
        </> }     
    </div>
  );
};

export default DisplayList;