import styles from "./Preferences.module.scss"
import Panel from "App/Components/Ui/Panel"
import { useCallback, useEffect, useState } from "react"
import { useStore } from "App/hooks-store/store"
import useHttp from "App/hooks/use-http"
import TimeSelector from "App/Components/Ui/TimeSelector"
import RoundedButton from "App/Components/Ui/RoundedButton"
const Preferences = () => {
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const [initialTime, setInitialTime] = useState({ hr: '0', min: '0', sec: '0' })
    const [wall, setWall] = useState([])

    const listTime = {
        sec: [...Array(60).keys()],
        min: [...Array(60).keys()],
        hr: new Array(24).fill('').map((x, i) => i),
    }
    const [preferences, setPreferences] = useState({
        slideTimerHours: 0,
        slideTimerMinutes: 0,
        slideTimerSeconds: 0,
        captionId: "",
        themeId: ""
    })

    useEffect(() => {
        getWallData()
        dispatch("setPageTitle", "Preferences")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (!!wall)
            getPreferences()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wall])

    //Get preferences data API
    const getPreferencesResult = useCallback((res) => {
        dispatch('hideSpinner');
        const data = res.data;
        const prefObj = {
            slideTimerHours: data.slideTimerHours ?? "0",
            slideTimerMinutes: data.slideTimerMinutes ?? "0",
            slideTimerSeconds: data.slideTimerSeconds ?? "0"
        }
        setPreferences(prefObj);
        const {
            slideTimerHours, slideTimerMinutes, slideTimerSeconds } = prefObj;
        setInitialTime({
            hr: slideTimerHours.toString(),
            min: slideTimerMinutes.toString(),
            sec: slideTimerSeconds.toString()
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, wall])
    const getPreferences = useCallback(() => {
        dispatch('showSpinner')
        triggerAPI({
            url: `user/display-preference/get`, method: 'get'
        }, getPreferencesResult);
    }, [dispatch, getPreferencesResult, triggerAPI])

    const getWallResult = useCallback((res) => {
        const data = res.data;
        const tempWall = data?.map(item => { item.value = item.id; return item });
        setWall(tempWall);
    }, [])

    const getWallData = useCallback(() => {
        triggerAPI({
            url: `background-wall/2d/user/get`, method: 'get'
        }, getWallResult);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getWallResult, triggerAPI]);


    //Update preferences API
    const updatePreferencesResponse = useCallback((res) => {
        dispatch('hideSpinner');
        getPreferences()
        dispatch('showToast', { toast: { toastMode: 'success', message: "Preferences updated" } })
    }, [dispatch, getPreferences])
    const updatePreferences = useCallback(() => {
        let data = {
            ...preferences,
            themeId: null,
            slideTimerHours: Number(preferences.slideTimerHours),
            slideTimerMinutes: Number(preferences.slideTimerMinutes),
            slideTimerSeconds: Number(preferences.slideTimerSeconds)
        }
        dispatch('showSpinner')
        triggerAPI({
            url: `user/display-preference/update`, data: data, method: 'patch'
        }, updatePreferencesResponse,()=>{
            dispatch("hideSpinner");
        });
    }, [dispatch, updatePreferencesResponse, triggerAPI, preferences])


    //Input change 
    const inputOnChange = useCallback((e) => {
        setPreferences((prev) => {
            return {
                ...prev,
                ...e
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wall])

    const timerOnchange = useCallback((e) => {
        if (e) {
            const { hr: slideTimerHours, min: slideTimerMinutes, sec: slideTimerSeconds } = e;
            const frmValue = {
                slideTimerHours,
                slideTimerMinutes,
                slideTimerSeconds
            }
            inputOnChange(frmValue)
        }
    }, [inputOnChange])
    //Tab selection

    return <div className={`${styles['preferences']}`}>
        <Panel>
            <div className={`${styles["panel-frame"]} flex flex-col gap-2.5 p-5 w-full`}>
                <div className="flex items-center gap-2.5">
                    <div className="py-2.5 text-left">
                        <p className="font-14">Default Display Settings</p>
                    </div>
                    <div className="ml-auto">
                        <RoundedButton Name="Update" Type="9" OnClick={updatePreferences}></RoundedButton>
                    </div>
                </div>

                <div className="flex flex-col items-start">
                    <div className={`${styles["preference-options"]} flex-col`}>
                        <div className={`${styles["option"]}`}>
                            <span className={`${styles["label-color"]}`}>Transition Timer</span>
                        </div>
                        <div>
                            <TimeSelector list={listTime} onSelect={timerOnchange} value={initialTime} />
                            {/* <Validators type="PreferenceTime" value={[preferences.slideTimerHours,preferences.slideTimerMinutes,preferences.slideTimerSeconds]} message="Not a valid slide time" controlName="password" onErrors={collectErrors} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </Panel>
    </div>
}

export default Preferences