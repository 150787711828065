/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import plus from "Assets/Icons/plus.svg"
import '../../CollectionCommunity.scss';
import useScreenSize from "App/hooks/use-screen-size";
import userAvatar from "Assets/Icons/logo-square.svg";
import './ListingCollection.scss';
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import CollectionCard from "App/Components/CollectionCard";
import RemoveWarning from "App/Components/RemoveWarning";
import RightPanel from "App/Pages/Discover/Components/SubComponents/DiscoverCollection/Component/RightPanel";
import { useNavigate } from "react-router-dom";
import AddPlusIconButton from "App/Components/AddPlusIconButton";

const ListingCollection = (props) => {
    const screensize = useScreenSize()?.width;
    const [{ currentUserId },dispatch] = useStore();
    const { triggerAPI } = useHttp();
    const [totalPage, setTotalPage] = useState(0);
    const [totalCount, setTotalCount] = useState({ownershipRequestedCount:0,rejectedCount:0});
    const [collectionList, setCollectionList] = useState([]);
    const [communityFilter, setCommunityFilter] = useState([]);
    const [cardLoader, setCardLoader] = useState(true);
    const [clickedCollectionId, setClickedCollectionId] = useState("");
  const [removeClick,setRemoveClick] = useState(false)
  const [whyCollectionNotDeletedMessage, setWhyCollectionNotDeletedMessage] = useState("");
    const targetRef = useRef(null)
    const navigate = useNavigate()
    const tabs=useMemo(()=>{
       return [{id:1,name:"Pending approval",status:"ownership_requested",count:totalCount?.ownershipRequestedCount},{id:2,name:"Rejected",status:"rejected",count:totalCount?.rejectedCount}]
    },[totalCount])
    const [collectionStatus, setCollectionStatus] = useState(tabs[0]?.status);
    const paginationConfig = useMemo(() =>{
        return {perPage:  100 }
    },[]);
    useEffect(() => {
        if (currentUserId)
            getCollectionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screensize, currentUserId, communityFilter,collectionStatus, props?.fromCommunity, props?.communityId, props?.refetch])

    const getCollectionListResult = useCallback((res) => {
        setCardLoader(false)
        const { data } = res;
        setCollectionList(data?.list || [])
        setTotalPage(data?.pageMeta.totalItems)
        if(props?.type === 'requested') setTotalCount(data?.pageMeta)
    }, [])
    const getCollectionList = useCallback(() => {
        setCardLoader(true)
        let filterData = {}
        if (props?.fromCommunity) {
            filterData = { userId: currentUserId, communityId: [parseInt(props?.communityId)] }
        } else {
            filterData = { userId: currentUserId }
            if (communityFilter?.length) {
                filterData.communityId = communityFilter;
            }
        }
        if(!props?.type) {
            filterData.status =  'active';
        } else if(props?.type === 'requested') {
            filterData.status =  [collectionStatus];
        }
        triggerAPI({
                url: `${props?.type === 'requested' ? 'community/collection/temp/user/get' : 'community/collection/user/get' }`, 
                method: 'post', 
                data: {
                    page: 1,
                    items: paginationConfig?.perPage,
                    filter: filterData,
                    search: ""
                }
        }, getCollectionListResult);
    }, [props?.type, paginationConfig, getCollectionListResult, triggerAPI,collectionStatus, currentUserId, communityFilter, props?.fromCommunity, props?.communityId])

    const onCardDeleteClick = (e,id) => {
        e.stopPropagation()
        setClickedCollectionId(id)
        removeClickHandler()
    }
    const cardClick = (e) => {
        const id = e?.currentTarget?.dataset?.policy;
        props?.onCardClick(e,id)
    }
    const updateHeight = () => {
        const root = document.documentElement;
        if (targetRef.current) {
            if (targetRef.current) {
                root?.style.setProperty(
                    "--collection-card-height", `${targetRef.current.offsetWidth}px`
                );
            }
        }
    }
    useLayoutEffect(() => {
        updateHeight()
        window.addEventListener("resize", () => {
            setTimeout(() => {
                updateHeight()
            }, 1000);
        }, false);
    }, []);
    useEffect(() => {
        updateHeight()
    }, [collectionList])
    const handleCommunityFilter = (e) => {
        const value = e?.currentTarget?.dataset?.id;
        if(props.type ==='requested'){
            let setStatus=tabs?.find(el=>el?.id===Number(value))?.status;
            setCollectionStatus(()=>setStatus)
        }
        else{
        let ids = [];
        if (value === "all") {
            ids =
                communityFilter?.length === props?.communityList?.length
                    ? []
                    : props?.communityList?.map((val) => val?.id);
        } else {
            if (communityFilter?.includes(Number(value))) {
                ids = communityFilter?.filter(
                    (val) => val !== Number(value)
                );
            } else {
                let community = communityFilter
                    ? communityFilter
                    : [];
                ids = [...community, Number(value)];
            }
        }
        setCommunityFilter(ids);
    }
    }

    const removeResult = useCallback(
        (res) => {
          dispatch("hideSpinner");
          getCollectionList()
          props?.reFetch && props?.reFetch()
          dispatch("showToast", {
            toast: { toastMode: "success", message: res?.message },
          });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
      );
    const removeCollection = () =>{
        removeClickHandler()
        dispatch("showSpinner");
        let url = props?.type === 'requested'?"community/collection/temp/remove/":"community/collection/remove/"
        triggerAPI(
          {
            url: `${url}${+clickedCollectionId}`,
            method: "delete",
          },
          removeResult,
          (err) => {
            dispatch("hideSpinner");
            if(err?.response?.data?.error?.message?.toLowerCase()?.includes("sorry") || err?.response?.data?.error?.message?.toLowerCase()?.includes("gallery")){
                setWhyCollectionNotDeletedMessage(err?.response?.data?.error?.message)
            }else{
                setWhyCollectionNotDeletedMessage("")  
                dispatch("showToast", {
                  toast: { toastMode: "error", message: err?.response?.data?.error?.message??err.message },
                });
            }
          }
        );
      }
    const removeClickHandler=()=>{
        setRemoveClick((pre)=>!pre)
      }
      const closeCollectionNotDeletedMessagePopupHandler=()=>{
        setWhyCollectionNotDeletedMessage("")
      }
    const typeHandler=(type)=>{
return type ==='requested'? tabs : props?.communityList?.slice(0, 3)
    }
    const backHandler = () => {
        props?.setSelectedCollection(null);
        navigate(`/community/${props?.communityId}/collection`)
      };
    return (<>
    {props?.selectedCollection ?
     <div className="flex flex-col">
        <RightPanel backHandler={backHandler} setSelectedCollection={props?.setSelectedCollection} selectedCollection={props?.selectedCollection} />
     </div>:
        <div className={`flex flex-col listing-collection collection-container ${props.type === 'requested' && !collectionList.length && !(totalCount?.ownershipRequestedCount+totalCount?.rejectedCount) &&!cardLoader ? 'hidden' : ''} creator-collection-container`}>
            <div className={`${!props?.fromCommunity ? '' : 'justify-end'} gap-[1rem] items-center header community-title-sec flex-row ${props?.fromCommunity&&screensize<=550?"hidden":"flex"}`}>
                {!props?.fromCommunity && <div className="flex gap-6 items-start flex-col sm:flex-row  sm:items-center">
                    <h3 className="collection-header">{props.type ==='requested'?'Collections Requested':'Collections'} {!!(props.type !=='requested')&&<span>{`(${totalPage})`}</span>}</h3>
                    {!!(props?.type ==='requested')&&<div className="community-filter hidden sm:flex gap-3 flex-wrap items-center">
                        {tabs?.map((community) => {
                            return (
                                <span className={`${(communityFilter?.includes(community?.id) || collectionStatus===community?.status ) && 'active'} cursor-pointer`}data-id={community?.id} key={community?.id} onClick={handleCommunityFilter}>{community?.name}{!!(props.type ==='requested')&&<span className={`${(communityFilter?.includes(community?.id) || collectionStatus===community?.status ) && 'active'} count-span`}>{`(${community?.count})`}</span>}</span>
                            )})}
                </div>}
                </div>
                }
                {props.type==='requested' ?  <></> : (props?.communityList?.length || props?.fromCommunity )  ? <div className={` gap-[0.625rem] ${props?.fromCommunity&&screensize<=550?"hidden":"flex"}`}>
                    <AddPlusIconButton onClick={props?.onEditClick} className={"mt-1"}/>
                </div> : <></> } 
            </div>
            {!props?.fromCommunity&&<div className="community-filter flex sm:hidden sm:gap-3 gap-[0.625rem] flex-wrap items-center">
                {props?.communityList?.slice(0, 3)?.map((community) => {
                    return <span className={`${communityFilter?.includes(community?.id) && 'active'} cursor-pointer`}
                        data-id={community?.id} key={community?.id} onClick={handleCommunityFilter}>{community?.name}</span>
                })}
            </div>}
                <DetailsSkeltonCard
                show={cardLoader}
                height={"100%"}
                count={5}
                inline={true}
                details={"img-2-line"}
                noAbsolute
                parentDiv={"sk-three-six"}
                containerClassName={`flex mt-[1.5rem]`}
                />
            <div className={`sm:gap-[0.75rem] mt-[1.5rem] gap-[0.5rem] body ${!cardLoader?"grid":"hidden"}`}>
                {[...collectionList]?.map((list, index) => {
                    return (
                        <CollectionCard key={list?.id}
                        showData={{
                          profileIcon: true,
                          collectionName: true,
                          communityName: !(collectionStatus==="rejected"),
                          count: false,
                          tag: true,
                        }}
                        edit={!(collectionStatus==="rejected")}
                        delete={true}
                        collectionStatus={collectionStatus}
                        onEditClick={props?.onEditClick}
                        onDeleteClick={onCardDeleteClick}
                        mainClass="own-collection"
                        own={true}
                        cardClick={cardClick}
                        row={list}
                        className={""}
                      />
                    )
                })}
            </div>
            {!cardLoader && ![...collectionList]?.length && props?.type!=="requested"&&
            <div className="flex flex-col">         
                    <p className="flex items-center justify-center no-data-text">
                        Verify your existing collections or create new collections 
                        (coming soon) to connect with your community and share with the world.
                    </p>
            </div> } 
            {!!(!cardLoader && ![...collectionList]?.length && totalCount && props?.type==="requested") &&
            <div className="flex flex-col">         
                    <p className="flex items-center justify-center no-data-text">
                       No items to display.
                    </p>
            </div> }
            <div className={`gap-[0.625rem] ${props?.fromCommunity && screensize <= 550 && !cardLoader ? "flex" : "hidden"}`}>
            <AddPlusIconButton onClick={cardClick} className={""}/>
            </div>
        </div>}
        {!!removeClick&&<RemoveWarning onDelete={removeCollection} onCancel={removeClickHandler} approveBtn="Remove">Are you sure you want to remove?</RemoveWarning>}
        {!!whyCollectionNotDeletedMessage&&<RemoveWarning onCancel={closeCollectionNotDeletedMessagePopupHandler} noCancelButton>{whyCollectionNotDeletedMessage}</RemoveWarning>}
        </>
    )
}

export default ListingCollection;