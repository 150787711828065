import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "App/hooks-store/store";
import "./CommunityGallery.scss";
import { Fragment, useCallback, useEffect, useState } from "react";
import useHttp from "App/hooks/use-http";
import Card from "App/Components/Card";
import useCollectionLoader from "App/hooks/use-collectionLoader";
import DeleteGallery from "App/Components/Ui/DeleteGallery";
import { getAvailableGallery, getPreviewGalleryLink } from "App/Helper/utilities";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import useScreenSize from "App/hooks/use-screen-size";
import useGallery from "App/hooks/use-gallery";
import { useDispatch } from "react-redux";
import {  setFormInitiated } from "App/ReduxStore/Slicers/gallerySlice";
import PreviewGallery from "App/Pages/NftGallery/Component/MyGallery/Components/PreviewGallery";
import AddPlusIconButton from "App/Components/AddPlusIconButton";

const paginationConfig = {
  perPage: 100
}
const CommunityGallery = (props) => {
  const screensize = useScreenSize()?.width;
    const [deleteWarning, setDeleteWarning] = useState(false);
    const [dltId, setDltId] = useState(0);
    const { communityid, galleryId } = useParams();
    const { triggerAPI } = useHttp(); 
    const navigate = useNavigate();
    const [gallerList, setGalleryList] = useState([]);
    const [editTriggered, setEditTriggered] = useState({id:null, isTriggered: false});
    const { sliceCollectionAPI } = useCollectionLoader();
    const [{communityCollectionLoadingStatus}, dispatch] = useStore(false);
    const { getAvailableCreatorAssets } = useGallery();
    const [loading, setLoading] = useState(true)
    const dispatchRedux = useDispatch();
  const getGalleryListResult = useCallback((res) => {
    setLoading(false);
    const { data } = res;
    props.onMenuTabs((prev)=>prev.map(el=>{
      if(el.id==="my-galleries"){
          return {...el,count:data?.pageMeta.totalItems}
      }
      return el
  }))
    setGalleryList(data?.list);
    props?.setRefetchGallery(false);  
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

const getGalleryList = useCallback(() => {
    setLoading(true);
    triggerAPI({
        url: `community/gallery/get`, method: 'post', data: {
            page: 1,
            items: paginationConfig.perPage,
            filter: {
              communityId: parseInt(communityid)
            },
            search: ""
          }
    }, getGalleryListResult);
}, [getGalleryListResult, triggerAPI, communityid])

     
  useEffect(() => {
    if(props?.notFull){
      setLoading(!!props?.galleryLoader)
      setGalleryList(props?.galleryList)
    }
    else getGalleryList();      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.notFull,props?.galleryLoader,props?.galleryList])
     
  useEffect(() => {
    dispatch('setCurrentSelectedNfts', [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  
  const previewGallery = (e) => {
    const currentId = parseInt(e.currentTarget?.dataset?.id);
    const currentData = [...gallerList]?.find(item => item?.galleryId === currentId)
    // dispatch('showSpinner')
    triggerAPI({
      url: `gallery/stand-alone/get/${currentId}?isVerified=0&compression=0`, method: 'get'
    }, (res) => {
      // dispatch('hideSpinner')
      const data = res?.data;
      let tempAssets = data?.assets.map(assetData => {
        return {
            details: assetData?.details,
            image: assetData?.details?.nftcdnimage256,
            frameId: assetData?.frameId,
            name: assetData?.details?.name,
            policy: assetData?.policy_id,
            asset_name: assetData?.asset_name,
            isLandscape: assetData?.isLandscape
        };
      });

      if(!!props.onPreviewGalleryDetails){
        props.onPreviewGalleryDetails(data);
        return;
      }
      if(tempAssets?.length) {
        currentData.gallery.assets = tempAssets;
        sliceCollectionAPI(tempAssets, 'setCommunityCollections', 'updateCommunityCollectionLoadingStatus');  
        dispatch('setCurrentCommunityGallery', currentData?.gallery)
        if(props?.notFull)props?.handleCardClick&&props?.handleCardClick(data?.id)
       else navigate(`view/${currentId}`);
      }
    },() => {
      // dispatch("hideSpinner");
    });
    
  }
  const handlePreview = (id, previewType, e) => {
    e.stopPropagation();
    getPreviewGalleryLink(id, previewType);
}
const handleEdit = useCallback((e, gallId) => {
  const id = parseInt(galleryId) || gallId;
  e && e.stopPropagation();
  let currentArray = [...gallerList].find((gallery) => gallery.galleryId === id )?.gallery;
  let assets = currentArray?.assets.filter(x => x);
  currentArray = {...currentArray, assets}
  const checkAssets = () => {
   // dispatch('showSpinner')
    getAvailableCreatorAssets(id, communityid, (res) => {
    //  dispatch("hideSpinner");
    
      let { data } = res;
      let assets = getAvailableGallery(data, currentArray?.assets)
      currentArray = {...currentArray, assets}
      dispatch('setCurrentGallery', currentArray)
      sliceCollectionAPI(currentArray?.assets, 'setCommunityCollections', 'updateCommunityCollectionLoadingStatus');
      setEditTriggered({id: id, isTriggered: true})
      dispatch('setCurrentCommunityGallery', currentArray)
    })
  }  
  checkAssets();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[gallerList, galleryId]);

useEffect(() => {
  if(editTriggered?.isTriggered && communityCollectionLoadingStatus === 'finished') {
    navigate(`/community/${communityid}/my-galleries/edit/${editTriggered?.id}/save`);
    dispatch('setCurrentGalleryUpdateId', { id: editTriggered?.id, preview: false })
    dispatch("resetSingleCollection");
    dispatchRedux(setFormInitiated(true));
    // setTimeout(() => {
      // navigate(`/edit/${editTriggered?.id}/save`);
    // }, 100);
    setEditTriggered({id: null, isTriggered: false})
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [communityCollectionLoadingStatus, editTriggered, communityid])


const deleteResult = useCallback((res) => {
  setDeleteWarning(false)  
  setDltId(0)
    if(res !== -1) {  
      if(props?.notFull){
        props?.callBackApi&&props?.callBackApi();
      }   
    else{
      if(galleryId) navigate(`/community/${communityid}/my-galleries`);
      getGalleryList();
    }
    props?.reFetch&&props?.reFetch()
}
  
// eslint-disable-next-line react-hooks/exhaustive-deps
},[communityid, galleryId])
const onClickDeleteGallery = useCallback((e = '', id) => {
      e && e.stopPropagation();
     setDeleteWarning(true) 
     setDltId(id)       
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
const onToGallery = () => {
  navigate(`/community/${communityid}/my-galleries`)
}
  return (<>{
    !!(galleryId) ?
      (<div className="discover-gallery-preview-section">
        <PreviewGallery
          fromMainPage={true}
          type={screensize <= 550 && "collectors"}
          onClickDeleteGallery={onClickDeleteGallery} 
          standalone={true}
          selectedWall={''}
          galleryUpdateId={galleryId}
          OnClick={handleEdit}
          applyFrameButton={false}
          onClose={onToGallery}
          className="top-0 pl-[1rem] pr-[1rem] pt-0 pb-0"
          communityData={gallerList?.length ? gallerList[0]?.community : ''}
        />
      </div>)
      :  
    <div className="flex flex-col gap-4 pr-sm gallery-container pb-[1rem]">
      { !props?.notFull&& <div className={`justify-end header sm:flex hidden ${props?.noVerifiedCollectionForCommunity&&"h-[2.1625rem]"}`}>
         {!props?.noVerifiedCollectionForCommunity && <AddPlusIconButton onClick={props?.navToCreateGallery} className={""}/>}
        </div>}
        {(!gallerList?.length && !loading) && <div className="flex items-center no-data justify-center">There are currently no galleries.</div>
        }

          <DetailsSkeltonCard
              show={loading}
              height={"100%"}
              count={screensize <= 550 ? 2 : 3}
              inline={true}
              details={"1-line"}
              noAbsolute
              parentDiv="grid home-grid mt-0 aspect-pt-six details-gap"
              containerClassName={`flex`}                                 
          />
            <div className={!loading ? "home-grid grid  gap-[0.5rem] body": ''}>
            {!loading && gallerList?.map((gallery, i) => {
              let info = gallery?.gallery;
                info = { ...info, createdAt: info?.nftDropStartedAt }
                return (
                    <Fragment key={i}>
                        <div key={i} data-id={info?.id} onClick={previewGallery} className='cursor-pointer'>
                            <Card
                                key={i}
                                cardProp={info}
                                type="Gallery"
                                preview={!info?.disableGallery}
                                showEdit={info?.disableGallery}
                                handlePreview={handlePreview}                             
                                editGallery={true}
                                handleEdit={handleEdit}
                                handleDelete={props.deleteButtonClickHandler}
                                onCancel={props.closeDeleteWarnHandler}
                                onClickDeleteGallery={onClickDeleteGallery}
                            /></div>

                    </Fragment>)
            })}
        </div>  
          {!props?.notFull&&<AddPlusIconButton onClick={props?.navToCreateGallery} className={"sm:hidden flex"}/>}
    </div>}
    {deleteWarning && <DeleteGallery show={true} dltId={dltId} deleteResult={deleteResult} setReloadCount={props?.setReloadCount} />}
  </>)
}

export default CommunityGallery;