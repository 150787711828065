import WalletSelect from "App/Components/Ui/WalletSelect";
import storage from "App/Helper/storage";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import useWallet from "App/hooks/use-wallet";
import { useCallback } from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useAuth from "App/hooks/use-auth";

const WalletSelector = () => {
    const [installedWallets, setInstalledWallets] = useState([])
    const [loading, setLoading] = useState();
    const walletConnector = useWallet()
    const [{ currentWallet }, dispatch] = useStore(false);
    const location = useLocation()
    // eslint-disable-next-line no-unused-vars
    const [isLoggedIn, logout, login, guest, isGuest, removeItem] = useAuth();
    const { triggerAPI } = useHttp();
    const selectWallet = useCallback((walletName) => {
        walletConnector.connectWallet(walletName);
    }, [walletConnector])
    const otherWalletResponse = useCallback((res) => {
        const {data} = res;
        dispatch('hideSpinner');
        if(!(typeof data === 'string' && data === 'Duplicate')){
            dispatch("reFetch")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const walletAddHandler = useCallback((walletAddress) => {
        if (walletAddress) {
            // dispatch('showSpinner')
            const data = {
                walletAddress,
            }
            triggerAPI({
                url: `user/connect-wallet/via/manually`, data: data, method: 'post'
            }, otherWalletResponse, (err) => {
                dispatch('hideSpinner')
                if(err?.response?.status === 409) {
                    dispatch('setCurrentWallet', {})
                    removeItem('currentWallet');
                    dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.error?.message } })
                } else {
                    dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.error?.message } })
                    setTimeout(() => {
                        logout();
                    }, 1000);
                }
            });
        }
        else
            dispatch('showToast', { toast: { toastMode: 'error', message: "Invalid valid wallet address" } })
    }, [dispatch, otherWalletResponse, triggerAPI, logout, removeItem])
    useEffect(() => {
        const name = storage().get('currentWallet');
        if (name) {
            selectWallet(name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        const availableWallet = walletConnector.availableWallet();
        setInstalledWallets(availableWallet)
        const { connecting } = walletConnector;
        setLoading(connecting)
    }, [walletConnector])
    return (
        <WalletSelect availble={installedWallets} current={currentWallet} onSelect={selectWallet} loading={loading} />
    )
}
export default WalletSelector;