import TileView from "App/Components/Ui/TileView/indexV2";
import { useCallback, useEffect, useState } from "react";
import { categorySort } from "App/Helper/utilities";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import { useNavigate } from "react-router-dom";

const HomeCategoriesTileView = (subCategoryClick) => {
  const { triggerAPI } = useHttp();
  let navigate = useNavigate();
  const [tileData, setTileData] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryWeightings, setCategoryWeightings] = useState([
    { categorySlug: 'art', weighting: 10},
    { categorySlug: 'avatars', weighting: 3},    
    { categorySlug: 'collectibles', weighting: 4},
    { categorySlug: 'literature', weighting: 4},
    { categorySlug: 'movies', weighting: 2},
    { categorySlug: 'music', weighting: 5},
    { categorySlug: 'photography', weighting: 6},
    { categorySlug: 'sports', weighting: 1}
  ]);
  const [
    {},
    dispatch,
  ] = useStore();
  const getCategoryResponse = useCallback(
    (res) => {
      const cateList = categorySort([...res?.data?.list])
      setCategory(cateList);
    },
    [dispatch]
  );
  const getCategoryList = useCallback(() => {
    triggerAPI(
      {
        url: `category/get`,
        data: {
          page: 1,
          items: 1000,
          filter: {},
          search: "",
          includeCollectionHighlight: true
        },
        method: "post",
      },
      getCategoryResponse
    );
  }, [dispatch, getCategoryResponse, triggerAPI]);

  useEffect(() => {
    if (category.length)
    {
      let data = { 
          name: "Explore", //root name        
      };  

      let children = category?.filter(el=>el?.name?.toLowerCase()!=="other")?.map((category,i)=>{
        console.log(category.name,category.subcategories.sort((a,b)=>parseInt(a.count)<parseInt(b.count)?1:-1))
        let largestSubCategory = category.subcategories.sort((a,b)=>parseInt(a.count)<parseInt(b.count)?1:-1)[0];
        return {        
          name: category?.name,          
          //link: `explore/${val?.slug}`,
          icon: category?.icon,
          count: category?.subcategories.length??0, 
          value: 0,
          image256: largestSubCategory?.nftcdnimage256,
          image512: largestSubCategory?.nftcdnimage512,
          image1024: largestSubCategory?.nftcdnimage1024,          
          children: category?.subcategories?.map(subcategory => {
            return {
              name: subcategory?.name,              
              link: `/explore/${category?.slug}/${subcategory?.slug}`,
              count: 1,
              value: (categoryWeightings.filter(w => w.categorySlug == category.slug)?.[0]?.weighting??1)/category.subcategories.length,
              subcategorycount: subcategory.count,
              image256: subcategory.nftcdnimage256,
              image512: subcategory.nftcdnimage512,
              image1024: subcategory.nftcdnimage1024              
            }
          })
        }      
      });
      data.children = children;
      setTileData(data);
    }
  }, [category]);
    
  useEffect(() => {
    getCategoryList();    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tileclick = useCallback((event) => {
      if (event.data.link) {
        navigate(event.data.link)
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const rootChanged = useCallback((root) => {
    if (root.name == "Cur8")
    {
      return root;
    }    
    else
    {
      for (let i = 0; i < root.children.length; i++) {
        const child = root.children[i];
        child.value = child.subcategorycount;
      }
      return root;
    }
  },[])

  return (
      <TileView data={tileData} tileclick={tileclick} rootChanged={rootChanged} heightPercentage={82} />
  )
}

export default HomeCategoriesTileView