import "./DetailsInfo.scss";
import twitterIcon from "Assets/Icons/social/Twitter.svg";
import discordIcon from "Assets/Icons/social/Discord.svg";
import youtubeIcon from "Assets/Icons/social/YouTube.svg";

const DetailsInfo = (props) => {
  return (
    <div className={`details-info w-full p-4  flex flex-col h-full gap-[1.5265rem] ${props?.className}`}>
      <h4 className="text-left name-text">{props?.data?.name||props?.data?.username}</h4>
      <div className="details-desc-short text-left  w-full flex-col flex">
        <h5>Description</h5>
        <p className="mt-[0.8125rem]">{props?.data?.description}</p>
      </div>
      <div className="profile-social text-left flex-col flex items-center gap-[0.5rem] details-desc-short">
      <h5 className="mb-[0.3125rem] text-left">Links</h5>
        {props?.data?.twitter && (
          <a
            href={props?.data?.twitter}
            rel="noreferrer"
            target="_blank"
            className="social-link-media twitter-x text-left flex gap-[1rem]"
          >
            <img src={twitterIcon} alt="" />
            <span>{props?.data?.twitter?.split("//")[1]}</span>
          </a>
        )}
        {props?.data?.discord && (
          <a
            href={props?.data?.discord}
            rel="noreferrer"
            target="_blank"
            className="social-link-media text-left flex gap-[1rem]"
          >
            <img src={discordIcon} alt="" />
            <span>{props?.data?.discord?.split("//")[1]}</span>
          </a>
        )}
        {props?.data?.youtube && (
          <a
            href={props?.data?.youtube}
            rel="noreferrer"
            target="_blank"
            className="social-link-media text-left items-center flex gap-[1rem]"
          >
            <img src={youtubeIcon} alt="" />
            <span>{props?.data?.youtube?.split("//")[1]}</span>
          </a>
        )}
      </div>
      <div className="details-desc-short text-left  w-full flex-col flex">
        <h5>More Info</h5>
        <p className="mt-[0.8125rem]">{props?.data?.nationality}</p>
        <p className="mt-[0.5rem]">{`Joined ${new Date(props?.data?.createdAt).toLocaleDateString("en-us", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}`}</p>
      </div>
    </div>
  );
};

export default DetailsInfo;
