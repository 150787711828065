/* eslint-disable react-hooks/exhaustive-deps */
import Panel from "App/Components/Ui/Panel";
import { useStore } from "App/hooks-store/store";
import { useCallback, useEffect, useRef, useState } from "react";
import './Details.scss';
import dummyImage from 'Assets/Images/dummypic.svg';
import smDefault from 'Assets/Images/sm-default.svg'
import mdDefault from 'Assets/Images/md-default.svg'
import Validators from 'App/Components/Ui/Forms/Validators'
import Input from 'App/Components/Ui/Input'
import TextArea from 'App/Components/Ui/TextArea';
import RoundedButton from "App/Components/Ui/RoundedButton";
import { getInitial, markAsTouched, validateFormGroup } from "App/Helper/formValidation";
import userAvatarHover from 'Assets/Icons/avatar_upload.svg'
import useHttp from 'App/hooks/use-http'
import {  useNavigate, useParams } from 'react-router-dom';
import { objectToFormdata, objectToNull } from "App/Helper/utilities";
import { handleDragEnter, handleDragLeave, handleDragOver, handleDrop } from "App/Helper/files";
import useScreenSize from "App/hooks/use-screen-size";
const CommunityUpdate = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const formGroup = {
        name: {
            value: undefined,
            isRequired: true
        },
        bannerPictureFile: {
            value: undefined,
            isRequired: true
        },
        thumbnailPictureFile: {
            value: undefined,
            isRequired: true
        },
        displayPictureFile: {
            value: undefined,
            isRequired: true
        },
        description: {
            value: undefined,
            isRequired: true
        },
        about: {
            value: undefined,
            isRequired: true
        },
        instagram: {
            value: undefined,
            isRequired: false
        },
        twitter: {
            value: undefined,
            isRequired: false
        },
        youtube: {
            value: undefined,
            isRequired: false
        },
        website: {
            value: undefined,
            isRequired: false
        },
        discord: {
            value: undefined,
            isRequired: false
        },
        communityCollections: {
            value: undefined,
            isRequired: false
        },
        blockChain: {
            value: undefined,
            isRequired: false
        },
        tokenName: {
            value: undefined,
            isRequired: false
        },
        tokenId: {
            value: undefined,
            isRequired: false
        }
    }

    // const {search: communityid} = useLocation();
    const {communityid} = useParams();
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const [formValues, setFormvalues] = useState(getInitial(formGroup))
    const bannerWrapRef = useRef(null);
    const profileWrapRef = useRef(null);
    const thumbnailWrapRef = useRef(null);
    const [formVaildState, setFormVaildState] = useState({});
    const [id, setId] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [touched, setTouched] = useState(false)
    const [displayPicture, setDisplayPicture] = useState(null)
    const [bannerPicture, setBannerPicture] = useState(null)
    const [thumbnailPicture, setThumbnailPicture] = useState(null)
    const screensize = useScreenSize()?.width;
    const navigate = useNavigate();
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;

    useEffect(() => {
        if (communityid) {
         setId(communityid);
        }
      }, [communityid]);

    useEffect(() => {
        if (id){
           getDetailedCommunity();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    const getDetailedCommunityResult = useCallback((res) => {
        dispatch('hideSpinner');
        const { data } = res;
        const fungibleTokens = data?.fungibleTokens?.length ? data?.fungibleTokens[0] : {}
        setFormvalues({
            name: data?.name,
            description: data?.description,
            about: data?.about,
            website: data?.website,
            twitter: data?.twitter,
            instagram: data?.instagram,
            youtube: data?.youtube,
            discord: data?.discord,
            tokenName: fungibleTokens?.tokenName,
            tokenId: fungibleTokens?.policyId,
            blockChain: fungibleTokens?.blockchainTypeId,
            communityCollections: data?.communityCollections,
            bannerPictureFile:data?.bannerPicture,
            displayPictureFile:data?.displayPicture,
            thumbnailPictureFile:data?.thumbnailPicture
        })
        dispatch('setCurrentCommunityCollectionIds', data?.communityCollections)
        setBannerPicture(data?.bannerPicture);
        setDisplayPicture(data?.displayPicture);
        setThumbnailPicture(data?.thumbnailPicture)
    }, [dispatch])
    const getDetailedCommunity = useCallback(() => {
        dispatch('showSpinner')
        triggerAPI({
            url: `community/get/${id}`, method: 'get'
        }, getDetailedCommunityResult,()=>{
            dispatch("hideSpinner");
        });
    }, [dispatch, getDetailedCommunityResult, triggerAPI, id])

    useEffect(() => {
        if (touched) {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [touched])

    useEffect(() => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        setIsValid(valid)
    }, [formValues, formVaildState, formGroup])

    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])
    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }
    const getCreateResult = useCallback((res) => {
        dispatch('hideSpinner');
        props.onFetch()
        // navigate('/community')
        dispatch('showToast', { toast: { toastMode: 'success', message: res?.message } });
    }, [dispatch, navigate])
    const saveHandler = useCallback(() => {
        if (isValid) {
            dispatch('showSpinner');
            const list = { ...formValues };
            const postUrl = id ? `community/${id}` : 'community/create';
            const method = id ? `put` : 'post';
            list.fungibleTokens = [];
            let tempFungibleTokens = {};
            if (list?.tokenName)
                tempFungibleTokens.tokenName = list?.tokenName;
            if (list?.tokenId)
                tempFungibleTokens.policyId = list?.tokenId;
            if (list?.blockChain)
                tempFungibleTokens.blockchainTypeId = list?.blockChain;
            if(Object.keys(tempFungibleTokens).length)
                list.fungibleTokens.push(tempFungibleTokens);
            if (!list?.bannerPictureFile || typeof list?.bannerPictureFile==="string")
                delete list.bannerPictureFile;
            if (!list?.displayPictureFile||typeof list?.displayPictureFile==="string")
                delete list.displayPictureFile;
                if (!list?.thumbnailPictureFile || typeof list?.thumbnailPictureFile==="string")
                delete list.thumbnailPictureFile;
            if (!list.fungibleTokens?.length)
                delete list.fungibleTokens
            delete list.tokenName;
            delete list.tokenId;
            delete list.blockChain;
            delete list.communityCollections;
            let updatedList = objectToNull(list)
            const formData = objectToFormdata(updatedList);
            triggerAPI({
                url: postUrl, method: method, data: formData
            }, getCreateResult, err => {
                dispatch('hideSpinner');
                dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.error?.message } })
            });
        } else {
            setTouched(true)
        }
    }, [dispatch, formValues, getCreateResult, isValid, triggerAPI, id])

    const imageHandle = (imgFile) => {
        if (imgFile) {     
            const file = imgFile?.bannerPicture || imgFile?.displayPicture ||imgFile?.thumbnailPicture;
            const fileSize = file?.size / 1024 / 1024;
            const extension = file?.type?.split("/").shift();
            const fileExtension = extension.toLowerCase();
            if (
                fileExtension === "image" 
            ) {
              if (fileSize <= 2) {
                const imageUrl = URL.createObjectURL(file);
                if (imgFile?.bannerPicture) {
                  inputOnChange({ bannerPictureFile: file });
                  setBannerPicture(imageUrl);
                }
                if (imgFile?.thumbnailPicture) {
                    inputOnChange({ thumbnailPictureFile: file });
                    setThumbnailPicture(imageUrl);
                  }
                if (imgFile?.displayPicture) {
                  inputOnChange({ displayPictureFile: file });
                  setDisplayPicture(imageUrl);
                }
              } else {
                dispatch("showToast", {
                  toast: {
                    toastMode: "error",
                    message: "The maximum size of an image should be 2 MB",
                  },
                });
              }
            } else {
              dispatch("showToast", {
                toast: {
                  toastMode: "error",
                  message: "Please upload an image",
                },
              });
            }
        }
    }
    // const handleCollections = (e) => {
    //     const id = e?.currentTarget?.dataset?.id;
    //     let ids = [];
    //     if (id === "all") {
    //       ids =
    //         formValues?.communityCollections?.length === targetCollection?.length
    //           ? []
    //           : targetCollection?.map((val) => val?.id);
    //     } else {
    //       if (formValues?.communityCollections?.includes(Number(id))) {
    //         ids = formValues?.communityCollections?.filter(
    //           (val) => val !== Number(id)
    //         );
    //       } else {
    //         let collection = formValues?.communityCollections
    //           ? formValues?.communityCollections
    //           : [];
    //         ids = [...collection, Number(id)];
    //       }
    //     }
    //     inputOnChange({ communityCollections:ids?.length?ids:undefined });
    // };
    return (
        <Panel className={`sm:p-[1.25rem] p-0 gap-3 community-pro community-content-section`}>
            <div className="community-form create-edit-community text-left flex flex-col sm:gap-4 gap-5 p-5">
                <div className="flex flex-col sm:gap-4 gap-[2px]">
                    <div className="w-full flex justify-between gap-6">
                        <div className="w-3/5 flex flex-col gap-4 ">
                            <div className="flex gap-0 sm:gap-4 flex-col sm:flex-row">
                                <div className="flex flex-col sm:gap-4 gap-[0.5rem] w-full">
                                    <div className="flex flex-col gap-[0.375rem]">
                                        <div className="upload-banner flex flex-col gap-2">
                                            <label>Community</label>
                                        </div>
                                        <div>
                                        <div className="sm:grid">
                                            <Input maxlength={35} placeholder="Community" type="text" label="name" validateOnBlur onChange={inputOnChange} controlName="name" value={formValues.name}>
                                                <Validators type="Required" value={formValues.name} message="Required field" controlName="name" onErrors={collectErrors} />
                                            </Input>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-[0.375rem]">
                                        <div className="upload-banner flex flex-col gap-2">
                                            <label>Description</label>
                                        </div>
                                        <div >
                                            <TextArea
                                                placeholder={'Describe your community... (1000 words max)'}
                                                label="description"
                                                validateOnBlur type="text" rows={4}
                                                onChange={inputOnChange} maxWords={1000} showWordCount={true}
                                                controlName="description" value={formValues.description}>
                                                <Validators type="Required" value={formValues.description} message="Required field" controlName="description" onErrors={collectErrors} />
                                            </TextArea>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-[0.375rem]">
                                        <div className="upload-banner flex flex-col gap-2">
                                            <label>About Us</label>
                                        </div>
                                        <div>
                                            <TextArea rows={4} maxWords={1000} showWordCount={true}
                                                placeholder={'Describe you and your team... (1000 words max)'}
                                                label="description" validateOnBlur type="text" onChange={inputOnChange}
                                                controlName="about" value={formValues.about}>
                                                <Validators type="Required" value={formValues.about} message="Required field" controlName="about" onErrors={collectErrors} />
                                            </TextArea>
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:gap-4 gap-[0.5rem]">
                                        <div>
                                            <div className="section-title brd-btm w-full">Social Information (Provide at least one)</div>
                                        </div>
                                        <div className="flex flex-col w-full sm:gap-4 gap-[0.5rem]">
                                            <div className="flex w-full  gap-[0.5rem] sm:gap-[1rem] flex-col sm:grid sm:grid-cols-2">
                                                <div className="flex w-full  flex-col gap-[0.375rem]">
                                                    <div className="upload-banner flex flex-col gap-2">
                                                        <label>Twitter</label>
                                                    </div>
                                                    <div>
                                                        <Input label="Twitter" validateOnBlur placeholder="Twitter URL" type="text" onChange={inputOnChange} controlName="twitter" value={formValues.twitter}>
                                                            <Validators type="url" value={formValues.twitter} message="URL not valid" controlName="twitter" onErrors={collectErrors} />
                                                        </Input>
                                                    </div>
                                                </div>
                                                <div className="flex w-full  flex-col gap-[0.375rem]">
                                                    <div className="upload-banner flex flex-col gap-2">
                                                        <label>Instagram</label>
                                                    </div>
                                                    <div >
                                                        <Input label="Instagram" validateOnBlur placeholder="Instagram URL" type="text" onChange={inputOnChange} controlName="instagram" value={formValues.instagram}>
                                                            <Validators type="url" value={formValues.instagram} message="URL not valid" controlName="instagram" onErrors={collectErrors} />
                                                        </Input>
                                                    </div>
                                                </div>
                                                <div className="flex gap-4 w-full">
                                                    <div className="flex w-full  flex-col gap-[0.375rem]">
                                                        <div className="upload-banner flex flex-col gap-2">
                                                            <label>Discord</label>
                                                        </div>
                                                        <div >
                                                            <Input label="Discord" validateOnBlur placeholder="Discord URL" type="text" onChange={inputOnChange} controlName="discord" value={formValues.discord}>
                                                                <Validators type="url" value={formValues.discord} message="URL not valid" controlName="discord" onErrors={collectErrors} />
                                                            </Input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex gap-4 w-full">
                                                    <div className="flex w-full  flex-col gap-[0.375rem]">
                                                        <div className="upload-banner flex flex-col gap-2">
                                                            <label>Youtube</label>
                                                        </div>
                                                        <div >
                                                            <Input label="Youtube" validateOnBlur placeholder="Youtube URL" type="text" onChange={inputOnChange} controlName="youtube" value={formValues.youtube}>
                                                                <Validators type="url" value={formValues.youtube} message="URL not valid" controlName="youtube" onErrors={collectErrors} />
                                                            </Input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex w-full flex-col gap-[0.375rem]">
                                                    <div className="upload-banner flex flex-col gap-2">
                                                        <label>Website</label>
                                                    </div>
                                                    <div >
                                                        <Input label="website" validateOnBlur placeholder="https://www.website.com" type="text" onChange={inputOnChange} controlName="website" value={formValues.website}>
                                                            <Validators type="url" value={formValues.website} message="URL not valid" controlName="website" onErrors={collectErrors} />
                                                        </Input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="w-2/5 flex sm:gap-[2.5rem] gap-[2px]  flex-col">
                            <div className="flex flex-col sm:gap-4">
                                <div className="flex flex-col gap-[0.375rem]">
                                    <div className="flex flex-col gap-2 sm:mb-0 mb-[0.625rem]">
                                        <label>Profile</label>
                                    </div>
                                    <div className="upload-banner-image profile-img my-0 "
                                    
                                    ref={profileWrapRef}
                                    onDrop={(e) =>
                                    handleDrop(e, profileWrapRef, imageHandle,"displayPicture","pro-pic")
                                    }
                                    id="pro-pic"
                                    onDragOver={handleDragOver}
                                    onDragEnter={(e) => handleDragEnter(e, profileWrapRef,"pro-pic")}
                                    onDragLeave={(e) => handleDragLeave(e, profileWrapRef,"pro-pic")}>
                                        <div className={'relative flex h-full flex-col justify-center'}>
                                            <label
                                                className="cursor-pointer"
                                                htmlFor="upload-button-displayPicture"
                                            >
                                                <img
                                                    alt="No Img"
                                                    src={userAvatarHover}
                                                    className={`upload-icon`}
                                                ></img>
                                                {!displayPicture && (
                                                    <div className="dummy-wrap">
                                                        <img
                                                            className={`image-uploaded`}
                                                            alt="NoImg"
                                                            src={(isPortrait&&screensize<=550)?smDefault:dummyImage}
                                                        ></img>
                                                        {/* <p>
                                                            Maximum file <br />
                                                            size 2MB <br />
                                                            Minimum size <br />
                                                            92px x 92px <br />
                                                            1:1
                                                        </p> */}
                                                    </div>
                                                )}
                                                {displayPicture && (
                                                    <img
                                                        alt="No Img"
                                                        className={`image-uploaded`}
                                                        src={displayPicture}
                                                    ></img>
                                                )}
                                            </label>
                                            <input
                                                type="file"
                                                id="upload-button-displayPicture"
                                                name={'displayPicture'}
                                                className={'hidden'}
                                                accept="image/*"
                                                onChange={(e)=>imageHandle({"displayPicture":e.target.files[0]})}
                                            />
                                        </div>

                                    </div>
                                    <Validators type="Required" value={formValues.displayPictureFile} message="Required field" controlName="displayPictureFile" onErrors={collectErrors} />
                                </div>
                            </div>
                            <div className="basis-[100%] flex gap-[0.375rem] flex-col sm:basis-[30%]">
                                <div className="upload-banner flex flex-col gap-2">
                                    <label> Thumbnail</label>
                                </div>
                                <div className="upload-thumbnail-image !rounded-3xl" ref={thumbnailWrapRef}
                                id="thumbnail"
                                onDrop={(e) =>
                                handleDrop(e, thumbnailWrapRef, imageHandle,"thumbnailPicture","thumbnail")
                                }
                                onDragOver={handleDragOver}
                                onDragEnter={(e) => handleDragEnter(e, thumbnailWrapRef,"thumbnail")}
                                onDragLeave={(e) => handleDragLeave(e, thumbnailWrapRef,"thumbnail")}>
                                    <div className={'relative h-full w-full'}>
                                        <label className="cursor-pointer" htmlFor="upload-button-thumb">
                                            <img
                                                alt="No Img"
                                                src={userAvatarHover}
                                                className={`upload-icon`}
                                            ></img>
                                            {!thumbnailPicture && (
                                                <div className="dummy-wrap long-banner">
                                                    <img
                                                        className={`image-uploaded`}
                                                        alt="NoImg"
                                                        src={(isPortrait&&screensize<=550)?mdDefault:dummyImage}
                                                    ></img>
                                                    <p>
                                                        Recommended Size <br />
                                                        700px x 460px <br />
                                                    </p>
                                                </div>
                                            )}
                                            {thumbnailPicture && (
                                                <img
                                                    alt="No Img"
                                                    className={`image-uploaded-thumbnail_img`}
                                                    src={thumbnailPicture}
                                                ></img>
                                            )}
            
                                        </label>
                                        <input
                                            type="file"
                                            id="upload-button-thumb"
                                            name={'thumbnailPicture'}
                                            className={'hidden'}
                                            accept="image/*"
                                            onChange={(e)=>imageHandle({"thumbnailPicture":e.target.files[0]})}
                                        />
                                        
                                        </div>
                                    </div>
                                    <div className="">
                                            <div className="flex justify-start">
                                                <span className="recomended-size"> Recommended size  700px x 460px</span>
                                            </div>
                                                <Validators type="Required" value={formValues.thumbnailPictureFile} message="Required field" controlName="thumbnailPictureFile" onErrors={collectErrors} />
                                            </div>
                                 </div>
                            <div  className="sm:basis-[70%] flex flex-col gap-[0.375rem] basis-[100%]">
                                <div className="upload-banner flex flex-col gap-2">
                                    <label> Banner</label>
                                </div>
                                <div className="upload-banner-image !rounded-3xl" ref={bannerWrapRef}
                                id="banner"
                                onDrop={(e) =>
                                handleDrop(e, bannerWrapRef, imageHandle,"bannerPicture","banner")
                                }
                                onDragOver={handleDragOver}
                                onDragEnter={(e) => handleDragEnter(e, bannerWrapRef,"banner")}
                                onDragLeave={(e) => handleDragLeave(e, bannerWrapRef,"banner")}>
                                    <div className={'relative h-full w-full'}>
                                        <label className="cursor-pointer" htmlFor="upload-button-banner">
                                            <img
                                                alt="No Img"
                                                src={userAvatarHover}
                                                className={`upload-icon`}
                                            />
                                            {!bannerPicture && (
                                                <div className="dummy-wrap long-banner scale-[0.9]">
                                                    <img
                                                        className={`image-uploaded`}
                                                        alt="NoImg"
                                                        src={(isPortrait&&screensize<=550)?mdDefault:dummyImage}
                                                    />
                                                    <p>
                                                        Recommended size <br />
                                                        1200px x 240px
                                                    </p>
                                                </div>
                                            )}
                                            {bannerPicture && (
                                                <img
                                                    alt="No Img"
                                                    className={`image-uploaded actual-img`}
                                                    src={bannerPicture}
                                                ></img>
                                            )}
                                            
                                        </label>
                                        <input
                                            type="file"
                                            id="upload-button-banner"
                                            name={'bannerPicture'}
                                            className={'hidden'}
                                            accept="image/*"
                                            onChange={(e)=>imageHandle({"bannerPicture":e.target.files[0]})}
                                        />
                                        <div className="justify-start flex mt-2">
                                        <span className="recomended-size"> Recommended size  1200px x 240px</span>
                                        </div>
                                        <Validators type="Required" value={formValues.bannerPictureFile} message="Required field" controlName="bannerPictureFile" onErrors={collectErrors} />
                                     </div>  
                                </div>
                            </div>
                        </div>    
                    </div>
                 </div>  
                <div className="community_btn-container">
                    <RoundedButton className={'cancel-btn'} OnClick={props.onClose} Type="1" Name={'Cancel'}></RoundedButton>
                    <RoundedButton className={`save-btn  ${isValid ? 'active' : ''}`} OnClick={saveHandler} Type="1" Name={screensize > 550 ? 'Save' : 'Save'}></RoundedButton>
                </div> 
            </div>
        </Panel>
        )
    }

    export default CommunityUpdate;