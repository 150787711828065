import  { getShortDate } from "./getDate";
import nftFormat from "./nftFormat";
import image from "Assets/Icons/image.svg";
import audio from "Assets/Icons/music.svg";
import video from "Assets/Icons/video.svg";
import model from "Assets/Icons/model.svg";
import pdf from "Assets/Icons/pdf.svg";
import html from "Assets/Icons/html.svg";
import epub from "Assets/Icons/read-book.svg";

export const getSplice = (array, targetCount) => {
    const totalCount = Math.ceil(array.length / targetCount);
    const temp = [];
    for (let k = 0; k < totalCount; k++) {
        const spl = k * targetCount;
        const list = array.slice(spl, spl + targetCount);
        temp.push(list);
    }
    return temp;
}

export const getSplice5 = (array) => {
    return [array.slice(0, 2), array.slice(2, 3), array.slice(3, 5)]
}

export const getSplice7 = (array) => {
    return [array.slice(0, 3), array.slice(3, 4), array.slice(4, 7)]

}
export const thousandSeparator = (number) => {
    return Number(number)?.toLocaleString('en-US')
}
export const isDataURL = (url) => {
    url.includes('data:')
}
export const getBlobFromUrl = (url, callBack) => {
    try {
        const myRequest = new Request(url);
        if (isDataURL(url)) {
            callBack(url)
        } else {
            fetch(myRequest, { headers: { "Cache-Control": 'no-cache' } })
                .then(response => response.blob())
                .then(blob => {

                    var reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                        var base64data = reader.result;
                        callBack(base64data)
                    }
                });
        }
    } catch (error) {
        console.error('Error getBlobFromUrl', error);
    }    
}
export const geReWriteURL = (url) => {
    return new Promise((resolve) => {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const r = URL.createObjectURL(blob)
                resolve(r)
            })
            .catch(e => {
                resolve('')
            })
    })
}
export const sortObject = (arr, sortVal, originalArr = [], allCollectionData = []) => {
    switch (sortVal) {
        case ("Name A-Z"):
            return arr?.sort((a, b) => a["name"] ? b["name"] ? a["name"]?.toLowerCase() > b["name"]?.toLowerCase() ? 1 : -1 : -1 : 1);

        case ("Name Z-A"):
            return arr?.sort((a, b) => a["name"] ? b["name"] ? a["name"]?.toLowerCase() < b["name"]?.toLowerCase() ? 1 : -1 : -1 : 1);
        case ("Size L-H"):
            return arr?.sort((a, b) => Number(a["assets"]?.length) ? Number(b["assets"]?.length) ? Number(a["assets"]?.length) > Number(b["assets"]?.length) ? 1 : -1 : -1 : 1);
        case ("Size H-L"):
            return arr?.sort((a, b) => Number(a["assets"]?.length) ? Number(b["assets"]?.length) ? Number(a["assets"]?.length) < Number(b["assets"]?.length) ? 1 : -1 : -1 : 1);
        case "Collection A-Z":
            return arr.sort((a, b) =>
                a?.collectionName ?? a?.name
                ? b?.collectionName ?? b?.name
                    ? (a?.collectionName ?? a?.name)?.toLowerCase() > (b?.collectionName ?? b?.name)?.toLowerCase()
                    ? 1
                    : -1
                    : -1
                : 1
            );
        case "Collection Z-A":
            return arr.sort((a, b) =>
                a?.collectionName ?? a?.name
                ? b?.collectionName ?? b?.name
                    ? (a?.collectionName ?? a?.name)?.toLowerCase() < (b?.collectionName ?? b?.name)?.toLowerCase()
                    ? 1
                    : -1
                    : -1
                : 1
            );
        case "Count H-L":
            return arr?.sort((a, b) =>
                Number(a["quantity"])
                ? Number(b["quantity"])
                    ? Number(a["quantity"]) < Number(b["quantity"])
                    ? 1
                    : -1
                    : -1
                : 1
            );
        case "Count L-H":       
            return arr.sort((a, b) =>
                Number(a["quantity"])
                ? Number(b["quantity"])
                    ? Number(a["quantity"]) > Number(b["quantity"])
                    ? 1
                    : -1
                    : -1
                : 1
            );
        case "Floor Price H-L":
            arr = arr.map((pieces) => ({ ...pieces, floorPrice: pieces?.metrics?.length ? pieces?.metrics[0]?.floorPrice ?? 0 : 0 }))
            return arr.sort((a, b) => Number(a["floorPrice"]) ? Number(b["floorPrice"]) ? Number(a["floorPrice"]) < Number(b["floorPrice"]) ? 1 : -1 : -1 : 1);
        case "Release Date L-F":                      
            return arr.sort((a, b) =>
                a["mintDateFull"]
                ? b["mintDateFull"]
                    ? a["mintDateFull"] < b["mintDateFull"]
                    ? 1
                    : -1
                    : -1
                : 1
            );
        case "Release Date F-L":
            return arr.sort((a, b) =>
                a["mintDateFull"]
                ? b["mintDateFull"]
                    ? a["mintDateFull"] > b["mintDateFull"]
                    ? 1
                    : -1
                    : 1
                : -1
            );
        case "Recent Additions":       
            let oldest = allCollectionData?.sort((x,y) => x?.createdAt < y?.createdAt ? 1 : -1)[0]?.createdAt ?? 0;   
            let sorted = arr.sort((a, b) =>
                {              
                let ac = allCollectionData?.filter(c => c?.policy_id === a?.policy).sort((x,y) => x?.createdAt < y?.createdAt ? 1 : -1)[0];
                let bc = allCollectionData?.filter(c => c?.policy_id === b?.policy).sort((x,y) => x?.createdAt < y?.createdAt ? 1 : -1)[0];              
                a.createdAt = ac?.createdAt;
                b.createdAt = bc?.createdAt;                            
                if ((new Date(ac?.createdAt)-new Date(oldest))<60000 && (new Date(bc?.createdAt)-new Date(oldest))<60000)
                {                
                    return a["mintDateFull"]
                            ? b["mintDateFull"]
                            ? a["mintDateFull"] < b["mintDateFull"]
                                ? 1
                                : -1
                            : -1
                            : 1
                }
                else
                {
                    return ac?.createdAt < bc?.createdAt ? 1 : -1                  
                }              
                }            
            );          
            return sorted;    
        default:
            if(originalArr.length){
                return originalArr;
            } else {
                sortVal = sortVal?.toLowerCase()
                return arr.sort((a, b) => a[sortVal] ? b[sortVal] ? a[sortVal]?.toLowerCase() > b[sortVal]?.toLowerCase() ? 1 : -1 : -1 : 1);
            }        
    }

}

//Nft collection assetId list Api

export const getMetadata = (data, favKey) => {
    let image = '';
    if (data.onchain_metadata?.asset?.ipfs) {
        image = `${data.onchain_metadata?.asset?.url}${data.onchain_metadata?.asset?.ipfs}`
    } else if (data.metadata?.logo) {
        image = `data:image/png;base64,${data.metadata?.logo}`;
    }
    let files = [];
    if(data?.onchain_metadata?.files?.length) {
        let thumbimage = nftFormat(data.onchain_metadata?.image || image)
        files = data?.onchain_metadata?.files?.map((item => {
            let src = JSON.parse(JSON.stringify(item?.src))
            let mediaType = JSON.parse(JSON.stringify(item?.mediatype??item?.mediaType))
            if(Array.isArray(src)) {
                src = src.join('');
            }
            if(src?.includes('html')) {
                item.sType = 'html';
            } else if(src?.includes('ipfs')) {
                src = nftFormat(src, '?func=proxy');
                item.sType = 'ipfs';
            } else if(src?.includes('base64')) {
                item.sType = 'base64';
            }

            if(mediaType?.includes('html')) {
                item.mType = 'html';
            } else if(mediaType?.includes('image')) {
                item.mType = 'image';
            } else if(mediaType?.includes('video')) {
                item.mType = 'mp4';
            } else if(mediaType?.includes('pdf')) {
                item.mType = 'pdf';
            } else if(mediaType?.startsWith('model')) {
                item.mType = 'model';
            } else if(mediaType?.includes('epub')) {
                item.mType = 'epub';
            }
            item.newsrc = src;
            item.thumbimage = thumbimage;
            return item
        }))
    } else {
        let src = nftFormat(data.onchain_metadata?.image || image);
        files = [ {
            "src": src,
            "name": data.onchain_metadata?.name || data.metadata?.name,
            "mediaType": "image/png",
            "mType": "image",
            "sType": "ipfs",
            "newsrc": src,
            nftcdnfile: data?.nftcdnimage
        } ]
    }
    return {
        collectionName: data.collectionName??data.onchain_metadata?.collectionName,
        collectionImage: data.collectionImage,
        collectionImage256: data.collectionImage256,
        collectionId: data.collectionId,
        assetCount: data.assetCount,
        ownerCount: data.ownerCount,
        countDate: getShortDate(data.countDate),
        asset: data.asset,
        policy: data.policy_id,
        asset_name: data.asset_name,
        name: data?.onchain_metadata?.name || data?.metadata?.name || data?.onchain_metadata?.title || data?.metadata?.title || data?.onchain_metadata?.["Asset Title"] || data?.metadata?.["Asset Title"],
        image: nftFormat(data.onchain_metadata?.image || image),
        description: data.onchain_metadata?.description || data.metadata?.description,
        type: data.onchain_metadata?.type ?? data.onchain_metadata?.Type,
        files: files,
        quantity: 1,
        isCollectionFavourite: data[favKey],
        isFavourite: data.isFavouriteNFT,
        networkType: data.networkType,
        complexity: data.onchain_metadata?.complexity,
        value: data.onchain_metadata?.value,
        color: data.onchain_metadata?.color,
        circle: data.onchain_metadata?.circle,
        priceSource: data.onchain_metadata?.priceSource,
        mintDate: getShortDate(data.mintDate),
        lockDate: getShortDate(data.lockDate),
        mintDateFull: data.mintDate,
        lockDateFull: data.lockDate,
        walletId: data?.walletId,
        ownersCount: data?.ownersCount,
        items: data?.items,
        category: data?.category??{name: data.categoryName},
        categoryId: data?.categoryId,
        firstSubcategoryId: data?.firstSubcategoryId,
        secondSubcategoryId: data?.secondSubcategoryId,
        onchain_metadata: data.onchain_metadata,
        community: {id: data.communityId, name: data.communityName, displayPicture256: data.communityDisplayPicture256, communitySlug: data.communitySlug },
        aspectRatios: data.aspectRatios,
        formats: data.formats,
        collectionEditionsId: data.collectionEditionsId,
        collectionStorageId: data.collectionStorageId,
        tags: data.tags,
        types: data.types,
        nftcdnimage: data.nftcdnimage,
        nftcdnimage256: data.nftcdnimage256,
        nftcdnimage512: data.nftcdnimage512,
        nftcdnimage1024: data.nftcdnimage1024,
        termsId: data.termsId,
        subCategoryName:data?.subCategoryName,
        showcase: data.showcase,
        collage: data.collage,
        metrics: data?.metrics
    }
}

export const getURL = (url = '', size = 256) => {    
    return url;
}
export const getFrameURL = (url, size) => {
    return url;
    const compressorBaseURL = process.env.REACT_APP_IMAGE_COMPRESSOR;
    if (url?.includes('amazonaws')) {
        return getURL(`${compressorBaseURL}/${url}`, size)
    } else {
        return url;
    }
}
export const getFrameURLFromCDN = (url) => {
    return url;
    const compressorBaseURL = process.env.REACT_APP_IMAGE_COMPRESSOR;
    if (url?.includes('amazonaws')) {
        return `${compressorBaseURL}/${url}`
    } else {
        return url;
    }
}
export const convertPriceToAda = (priceValue) => {
    const price = (priceValue / 1000000);
    return price?thousandCommaSeparator(price?.toFixed(2)):price;
}

export const thousandCommaSeparator =(amount)=>{
   return amount ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0; 
}

export const convertJsonToArray = (data) => {
    try {
        const convertData = JSON.parse(data);
        return typeof convertData === 'object' ? convertData : [];
    } catch (error) {
        return [];
    }
}
export const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        return "Safari";
    }
    return "unknown";
}
export const updateViewPortMeta = () => {
    const v = getMobileOperatingSystem();
    const el = document.querySelector('meta[name=viewport]');
    if (v === 'Android') {
        if (el) {
            el.setAttribute('content', 'width=device-width, initial-scale=1')
        }
    }
    if (v === 'iOS') {
        if (el) {
            el.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1')
        }
    }
}
export const getBLOBUrl = (blob) => {
    try {
        return URL.createObjectURL(blob)
    } catch (error) {
        return blob
    }
}
export const scrollToTop = (x = 0, y = 0) => {
    window.scrollTo(x, y)
}
export const togglePaddingMain = (mode) => {
    const os = getMobileOperatingSystem();
    const isMobile = window.innerWidth <= 768
    const main = document.getElementById('mainContent');
    // if (main && isMobile && os === 'iOS') {
    //     if (mode) {
    //         main.style.paddingTop = 0;
    //     } else {
    //         main.style.paddingTop = '4.9375rem';
    //     }
    // }
}
export const toggleOverflow = (mode) => {
    const isMobile = window.innerWidth <= 768
    const main = document.body
    if (main && isMobile) {
        if (mode) {
            main.style.overflow = 'hidden';
        } else {
            main.style.overflow = 'auto';
        }
    }
}
export const popupMobileScrollAdj = (mode) => {
    togglePaddingMain(mode);
    toggleOverflow(mode)
}

export const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);

}

export const removeDuplicateByElement = (array, key) => {
    const uniqueIds = [];
    // eslint-disable-next-line array-callback-return
    array.filter(ele => {
        const includeArr = uniqueIds.some(x => x[key] === ele[key]);
        if(!includeArr)
            uniqueIds.push(ele);
    })
    return uniqueIds;
}

export const objectToFormdata = (payload = {}) => {
    const formData = new FormData();
    for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
            const element = payload[key];
            if (element && typeof element === 'object' && !(element instanceof Blob)) {
                formData.append(key, JSON.stringify(element))
            }
            else {
                formData.append(key, element)
            }

        }
    }
    return formData
}

export const objectToNull = (payload = {}) => {
    let obj={}
    for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
            const element = payload[key];
        obj[key] = element??null

        }
    }
    return obj
}

export  const arrangeAssetData = (data) => {
    const result = data.reduce(function (r, a) {
        r[a.policy_id] = r[a.policy_id] || [];
        r[a.policy_id].push(a);
        return r;
    }, {});
    var groups = Object.keys(result).map(function (key) {
        return {policy_id: key, data: result[key], assetCount: result[key].length};
    });
    let firstAssetList = [];
    for (const iterator in result) {
        if(result[iterator].length) {
            firstAssetList.push(result[iterator][0]);
        }
    }
    let assetList = [];
    assetList = [...firstAssetList, ...assetList]
    const fetchAssets = [];
    assetList.forEach((item) => {
        const include = fetchAssets.some(x => x.asset === item.asset)
        if (!include) {
            if(!item?.asset) {
                item.asset = item?.asset_name;
            }
            fetchAssets.push(item)
        }
    })
    const assetCount = groups && groups.reduce((accumulator, object) => {
        return accumulator + object.assetCount;
      }, 0);
    return {groups, fetchAssets, assetCount}
}

export const filterCollections = (tempArray, filter) => {
    if(filter?.categoryIds && filter?.categoryIds?.length) {
        tempArray = tempArray?.filter((val) => (filter?.categoryIds?.includes(val?.categoryId) || filter?.categoryIds?.includes(val?.firstSubcategoryId) || filter?.categoryIds?.includes(val?.secondSubcategoryId)))
    }                
    if(filter?.shapes && filter?.shapes?.length) {
        tempArray = tempArray?.filter((val) => 
            val.aspectRatios?.split(",").filter(ar => parseFloat(ar)>1).length > 0 && filter.shapes.includes('landscape')
            || val.aspectRatios?.split(",").filter(ar => parseFloat(ar)<1).length > 0 && filter.shapes.includes('portrait')
            || val.aspectRatios?.split(",").filter(ar => parseFloat(ar)==1).length > 0 && filter.shapes.includes('square')
        );            
    }
    if(filter?.formatIds && filter?.formatIds?.length) {            
        tempArray = tempArray?.filter((val) => 
            val.formats?.split(",").filter(v => 
                filter?.formatIds.filter(f=> 
                    f.toString() == v.toString()
                ).length > 0
            ).length > 0
        );
    }
    if(filter?.storageIds && filter?.storageIds?.length) {                        
        tempArray = tempArray?.filter((val) =>                 
            filter?.storageIds.filter(f=> 
                f == val.collectionStorageId
            ).length > 0                
        );
    }
    if(filter?.editionIds && filter?.editionIds?.length) {                        
        tempArray = tempArray?.filter((val) =>                 
            filter?.editionIds.filter(f=> 
                f == val.collectionEditionsId
            ).length > 0                
        );
    }
    if(filter?.typeIds && filter?.typeIds?.length) {            
        tempArray = tempArray?.filter((val) => 
            val.types?.split(",").filter(v => 
                filter?.typeIds.filter(f=> 
                    f.toString() == v.toString()
                ).length > 0
            ).length > 0
        );
    }
    if(filter?.tagIds && filter?.tagIds?.length) {            
        tempArray = tempArray?.filter((val) => 
            val.tags?.split(",").filter(v => 
                filter?.tagIds.filter(f=> 
                    f.toString() == v.toString()
                ).length > 0
            ).length > 0
        );
    }    
    if(filter?.termsIds && filter?.termsIds?.length) {        
        tempArray = tempArray?.filter((val) =>                 
            filter?.termsIds.filter(f=> 
                f == val.termsId
            ).length > 0                
        );
    }
    return tempArray;
}

export const categorySort = (data) => {
    let updatedList = data
      ?.sort((fs, sec) => fs.name.localeCompare(sec.name))
      ?.map((el) => {
        return {
          ...el,
          subcategories: el?.subcategories?.length
            ? el?.subcategories?.sort((fs, sec) =>
                fs.name.localeCompare(sec.name)
              )
            : el?.subcategories,
        };
      });
  
    return updatedList;
  };

export const getPreviewGalleryLink = (id, previewType) => {
    const type =  0;
    if (previewType === '3d') {
        const data = new URLSearchParams({
            id: id,
            type,
            page: 'meta'
        })
        window.open(`/preview-gallery?${data}`, '_blank');
    } else if(previewType === '2d'){
        const data = new URLSearchParams({
            id: id,
            type,
            page: 'mono'
        })
        window.open(`/preview-gallery?${data}`, '_blank');
    }else if(previewType === '2ds'){
        const data = new URLSearchParams({
            id: id,
            type,
            page: 'multi'
        })
        window.open(`/preview-gallery?${data}`, '_blank');
    }
}
export const deepCopy = (arr) => {
    return JSON.parse(JSON.stringify(arr));
}

export const copyClipHandler = (value) => {
    var textArea = document.createElement("textarea");
    textArea.value = value;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = ".09px";
    textArea.style.opacity = "0";

    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  export const splitWord=(value,num)=>{
    return value?.length < num
        ? value
        : value?.slice(0, num/2) +
        "..." +
        value?.slice(-(num/2))
  }

  export const queryGenerator=(data)=>{
    let queryLink="";
    const queryData=Object.entries(data).filter(([key,val])=>(val&&val?.length)||(val&& typeof val==="number"));
    queryData.forEach(([key,val],i)=>{
        queryLink=`${queryLink}${key}=${typeof val==="object"?`[${val}]`:val}${(i+1!==queryData?.length)?"&":""}`
    })
    return queryLink
  }
export const getAvailableGallery = (collections, assets) => {
    return  assets?.filter(x => collections?.some(y => y.asset === x?.asset));
}
export const findNumberFromString = (str) => {
    let matches = str.match(/\d+/g);
    if(matches)
        matches=parseInt(matches[0])
    return matches;
}

export const sequenceUpdate =(list, from, to) => {
    const listClone = [...list];
    if (from < to) {
      listClone.splice(to + 1, 0, listClone[from]);
      listClone.splice(from, 1);
    } else if (to < from) {
      listClone.splice(to, 0, listClone[from]);
      listClone.splice(from + 1, 1);
    }
    let listCloneUpdate = [...listClone]?.map((el,i)=>{
        return {...el,sequence:i+1}
    })
    return listCloneUpdate;
  }

export const findSelectedSubcategory = (categoryArray) => {
    const selectedCategory = categoryArray?.find(item => item?.isSelected);

    if (selectedCategory) {
        const selectedSubcategory = selectedCategory?.subcategories?.find(sub => sub?.isSelected);
        return selectedSubcategory?.name || '';
    }

    return '';
};

export function appendUserName(value, social) {
    if(!value) return;
    const urls = {
        twitter:`https://twitter.com/`,
        youtube:`https://www.youtube.com/`,
        discord:`https://discord.gg/`,
    }
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    if (!urlRegex.test(value)) {
        // If not a full URL, append the userName at the end
        const fullUrl = `${urls[social]}${value}`;
        return fullUrl;
    }
    // If it's already a full URL, return it as is
    return value;
}

export const MediaType=(media)=>{
    if(media?.includes('html')) {
       return  'html';
    } else if(media?.includes('image')) {
        return 'image';
    } else if(media?.includes('video')) {
        return 'mp4';
    } else if(media?.includes('pdf')) {
        return 'pdf';
    } else if(media?.startsWith('model')) {
        return 'model';
    } else if(media?.includes('audio')) {
        return 'mp3';
    }
}

export const getMediaImage = (type)=>{
    let mediaImg = ""
    switch (type) {
        case 'html':
            mediaImg = html;
            break;
        case 'image':
            mediaImg = image;
            break;
        case 'mp4':
            mediaImg = video;
            break;
        case 'pdf':
            mediaImg = pdf;
            break;
        case 'model':
            mediaImg = model;
            break;
        case 'mp3':
            mediaImg = audio;
            break;
        case 'epub':
            mediaImg = epub;
            break;
        default:
            break;
    }
    return mediaImg
}

export const getCeilValue = (input) => {

    if (!input) return null;

    const number = parseFloat(input);
    if (!isNaN(number)) {
        return Math.ceil(number);
    } else {
        return null;
    }
}

export const getMetadataValue = (sources, keys) => {
    for (let j = 0; j < sources.length; j++) {
        const source = sources[j];                                        
        if (source)
        {
            let props = Object.entries(source).map(([k,v])=>[k.toLowerCase(),v]);
            let value = props.filter(([k,v]) => keys.includes(k))?.[0]?.[1];
            if (!value || value == "")
            {
                value = props.filter(([k,v]) => {
                    let match = false;
                    for (let i = 0; i < keys.length; i++) {
                        const key = keys[i];
                        if (k.indexOf(key) > -1)
                        {
                            match = true;
                            break;
                        }
                    }     
                    return match;                                               
                })?.[0]?.[1];
            }      
            if (value && value != "")
            {
                return value;
            }                                                  
        }                        
    }
}

export const getDurationOfAudio=(duration)=>{
      let  formatDuration = duration?.replace("PT","");
        let minsIndex = formatDuration?.indexOf("M");                            
        let secsIndex = formatDuration?.indexOf("S");
        let secs = 0;
        if (secsIndex > -1) {
            secs = formatDuration?.substring(minsIndex+1, secsIndex - minsIndex+1);
        }                            
        let mins = formatDuration?.substring(0, minsIndex);
        formatDuration = `${mins}:${(parseInt(secs)<10?"0":"") + parseInt(secs)}`;
    return formatDuration
}

export function formatLargeNumber(num) {
    if (!num) return null;

    const number = parseFloat(num);
    if (!isNaN(number)) {
        // Define the threshold for each unit
        const thresholds = [
            { value: 1e12, symbol: 't' }, // trillion
            { value: 1e9, symbol: 'b' },  // billion
            { value: 1e6, symbol: 'm' },  // million
            { value: 1e3, symbol: 'k' }   // thousand
        ];

        // Loop through the thresholds to find the appropriate symbol
        for (let i = 0; i < thresholds.length; i++) {
            if (num >= thresholds[i].value) {
                // If the number is greater than or equal to the threshold, format it accordingly
                return (num / thresholds[i].value).toFixed(1) + thresholds[i].symbol;
            }
        }

        // If the number is smaller than the first threshold, return it as is
        return num.toString();
    } else {
        return null;
    }
}

export const numberWithCommas = (num) => {
    if (!num) return null;

    const number = parseFloat(num);
    if (!isNaN(number)) {
        return number.toLocaleString();
    } else {
        return null;
    }
};