import styles from "./TransactionSummary.module.scss"
import React, { useEffect, useRef, useState, useCallback } from 'react'
import Panel from "App/Components/Ui/Panel"
import Spinner from "App/Components/Ui/Spinner"
import copyIcon from "Assets/Icons/copy_icon.svg"
import shareIcon from "Assets/Icons/share_icon.svg"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useStore } from "App/hooks-store/store"
import useHttp from "App/hooks/use-http"
import PriceButton from "App/Components/Ui/PriceButton/PriceButton"
import Banner from "App/Components/Banner"
import BanerSm from "App/Layouts/Baner-sm"
import NftFrame from "App/Components/NftFrame"
import useFrames from "App/hooks/use-frames"
import backIcon from "Assets/Icons/back-icon.png";

let interval;
const TransactionSummary = () => {
    const [searchParams] = useSearchParams();
    const [qrCode, setQrCode] = useState(null)
    const navigate = useNavigate()
    const [frame, setFrame] = useState({})
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const copyClipRef = useRef(null);
    const assetId = searchParams.get("assetId");
    const {getFrameFromStore} = useFrames();
    useEffect(() => {
        getFrameDetails()
        getQrCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //Frame details Api
    const getFrameDetailsResult = (res) => {
        dispatch('hideSpinner');
        const data = res
        setFrame({
            name: data.name,
            age: data.age.name,
            type: data.category.name,
            style: data.style.name,
            material: data.material.name,
            shape: data.shape.name,
            color: data.color.name,
            features: data.addOn.name,
            complexity: data.complexity.name,
            addOn: data.addOn.name,
            totalEdition:data.totalEdition,
            inStock:data.inStock,
            image: data.s3ImageUrl,
            currency: data.currencyType.symbol,
            price: data.price
        })
    }
    const getFrameDetails = () => {
        dispatch('showSpinner')
        getFrameFromStore(searchParams.get("assetId"),(res) => {
            getFrameDetailsResult(res)
        })
    }

    //Qrcode Api
    const qrCodeApiResult = (res) => {
        setQrCode(res.data)
    }
    const getQrCode = () => {
        const data = {
            id: assetId,
        }
        triggerAPI({
            url: 'frame-asset/buy/via/nowPayment', data, method: 'post'
        }, qrCodeApiResult);

    }

    //Copy to clipboard handler
    const copyClipHandler = () => {
        copyClipRef.current.select();
        document.execCommand('copy');
        dispatch('showToast', { toast: { toastMode: 'success', message: "Copied to clipboard" } })
    }

    const getStatusResult = useCallback((res) => {
        const data = res?.data;
        if (data && data.paymentStatus.toLowerCase() !== 'waiting') {
            clearInterval(interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const getStatus = useCallback(() => {
        triggerAPI({
            url: `frame-asset/user/get/latest/transaction-status/asset/${assetId}`, method: 'get'
        }, getStatusResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, getStatusResult, triggerAPI, assetId])

    useEffect(() => {
        interval = setInterval(() => {
            getStatus()
        }, 5000);

        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const frameShopNavHandler = (params) => {
        navigate("/curate/frame-shop")
    }


    return (
        <div className={styles["transaction-summary"]}>
            <div className={styles["baner"]}>
                <Banner className="flex items-center pr-8 min-h-[3rem]">
                    <div className="pl-4">
                        <div className={styles["back-btn"]}>
                            <img src={backIcon} alt="back-icon" onClick={frameShopNavHandler} className='cursor-pointer' />
                        </div>
                    </div>
                </Banner>
            </div>
            <div className={`${styles["baner-sm"]}`}>
                <BanerSm>
                    <div className={styles["back-btn"]}>
                        <img src={backIcon} alt="back-icon" onClick={frameShopNavHandler}/>
                    </div>
                </BanerSm>
            </div>
            <div className={styles["contents"]}>
                <div className="flex flex-wrap gap-2.5 justify-center">
                    <div className="flex-1">
                        <Panel className="items-center h-full">
                            <div className={styles["frame-details"]}>
                                <p>{frame.title}</p>
                                <div className={styles["frame-box"]}>
                                    <NftFrame frame={frame.image}/>
                                </div>
                                <p>{frame.name}</p>
                                <p>In Stock: {frame.inStock}</p>
                            </div>
                        </Panel>
                    </div>
                    <div className={styles["frame-data-panel"]}>
                        <Panel className="h-full">
                            <div className="w-full text-left p-2.5">
                                <div className={styles["frame-data"]}>
                                    <div className="flex flex-col gap-1">
                                        <div className="flex">
                                            <div className={styles["frame-title"]}>
                                                <p>
                                                    {frame.name}
                                                </p>
                                            </div>
                                            <img className="ml-auto mr-2.5" alt="Img" src={shareIcon} />
                                        </div>
                                        <div className={styles["frame-data-box"]}>
                                            <div className={styles["data-list-box"]}><li className={styles["data-title"]}><span>Age:</span></li><span className={styles["data-value"]}>{frame.age}</span> </div>
                                            <div className={styles["data-list-box"]}><li className={styles["data-title"]}><span>Style:</span></li><span className={styles["data-value"]}>{frame.style}</span> </div>
                                            <div className={styles["data-list-box"]}><li className={styles["data-title"]}><span>Material:</span></li><span className={styles["data-value"]}>{frame.material}</span> </div>
                                            <div className={styles["data-list-box"]}><li className={styles["data-title"]}><span>Shape:</span></li><span className={styles["data-value"]}>{frame.shape}</span> </div>
                                            <div className={styles["data-list-box"]}><li className={styles["data-title"]}><span>Color:</span></li><span className={styles["data-value"]}>{frame.color}</span> </div>
                                            <div className={styles["data-list-box"]}><li className={styles["data-title"]}><span>Complexity:</span></li><span className={styles["data-value"]}>{frame.complexity}</span> </div>
                                            <div className={styles["data-list-box"]}><li className={styles["data-title"]}><span>Add-on:</span></li><span className={styles["data-value"]}>{frame.addOn}</span> </div>
                                            <div className={styles["data-list-box"]}><li className={styles["data-title"]}><span>In Stock:</span></li><span className={styles["data-value"]}>{frame.inStock+"/"+frame.totalEdition}</span> </div>
                                        </div>
                                        <div className={`${styles["price-btn"]} w-[8.375rem]`}>
                                            <PriceButton frame={frame} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </div>
                </div>
                <div className="flex flex-wrap gap-2.5">
                    <div className={styles["instruct-panel"]}>
                        {/* <Panel>
                            <div className={`${styles["data-list-box"]} flex-col p-2.5 gap-2.5 text-[.875rem]`}>
                                <div className={styles["data-title"]}>
                                    Payment Instructions
                                </div>
                                <div className={styles["data-value"]}>
                                    Send the EXACT amount of ADA to the wallet address provided below from a Cardano supported wallet or pay directly from your balance. Do not send ADA from an exchange.
                                </div>
                            </div>
                        </Panel> */}
                        <div className={styles["panel"]}>
                            <Panel className="h-full">
                                <div className={`${styles["data-list-box"]} flex-col p-2.5 gap-2.5 text-[.875rem]`}>
                                    <div className={styles["data-title"]}>
                                        Shipping Policy
                                    </div>
                                    <div className={styles["data-value"]}>
                                        Your Cur8 digital frame has been packed and shipped. Make sure to pay within 20 minutes or else your frame will be lost in the mail.                             </div>
                                </div>
                            </Panel>
                        </div>
                        <div className={styles["panel"]}>
                            <Panel className="h-full">
                                <div>

                                </div>
                                <div className={`${styles["data-list-box"]} flex-col p-2.5 gap-2.5 text-[.875rem]`}>
                                    <div className={styles["data-title"]}>
                                        Return Policy
                                    </div>
                                    <div className={styles["data-value"]}>
                                        Cur8 has a no returns policy. All sales are final.</div>
                                </div>
                            </Panel>
                        </div>
                        {/* <Panel>
                            <div>

                            </div> */}
                        {/* code for status */}
                        {/* <div>

                            </div>
                            <div className={`${styles["data-list-box"]} flex-col p-2.5 gap-2.5 text-[.875rem]`}>
                                <div className={styles["data-title"]}>
                                    Status
                                </div>

                                <div className={`relative w-40 ${paymentStatus && paymentStatus.toLowerCase() === 'waiting' && styles["waiting"]}`}>
                                    <span className={styles["data-value-btn-txt"]}>{paymentStatus}</span>
                                </div>
                                
                            </div>

                            
                        </Panel> */}
                    </div>
                </div>
                    <div className="flex-1 relative">
                        <Panel className="h-full">
                            <Spinner show={!qrCode} className="absolute w-full mt-[50%]" size={20} />

                            {!!qrCode && <div className={styles["qr-box"]}>
                                <div className="flex">
                                    <p>Send This Amount {frame.currency}{frame.price}</p>
                                    <img onClick={copyClipHandler} className="cursor-pointer w-4 ml-auto" alt="Img" src={copyIcon} />
                                </div>
                                <div className={styles["box"]}>
                                    <div className={styles["title"]}>Address</div>
                                    <div className={`${styles["box"]} flex justify-center`}>
                                        <img alt="Img" src={qrCode.qrCode} />
                                    </div>
                                    <div className="flex mt-2.5">
                                        <p className={styles["qr-desc"]}>
                                            {qrCode.transactionAddress}
                                        </p>
                                        {qrCode.transactionAddress && <textarea className="w-[0.1px] opacity-0"
                                            ref={copyClipRef}
                                            readOnly
                                            value={qrCode.transactionAddress}
                                        />}
                                        <img onClick={copyClipHandler} className="cursor-pointer w-4 ml-auto mb-auto" alt="Img" src={copyIcon} />
                                    </div>
                                </div>
                            </div>}
                        </Panel>
                    </div>
            </div>
        </div>
    )
}

export default TransactionSummary
