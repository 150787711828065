import { useEffect, useState } from 'react';
import '../AccordianCard.scss';
import userAvatar from 'Assets/Icons/userAvatars.svg'
import downArrow from 'Assets/Icons/down-arrow.svg';

const SmCard = (props) => {
    const [isOpenCard, setIsOpenCard] = useState(false);

    const handleEdit=()=> {
        props.editHandler(props.data)
    }

    const handleCard = (e) => {
        e.stopPropagation()
        props?.handleCardClick && props?.handleCardClick(e);
        props?.readApi && !isOpenCard && props?.data?.isRead === "0" && props?.readApi(props?.data?.id)
    }
    useEffect(() => {
        let bool = false;
        if (props?.data?.id === props?.selectedCard?.id) {
            bool = true;
        }
        setIsOpenCard(bool);
    }, [props?.data?.id, props?.selectedCard])

    function formatDate(dateString) {
        if (isNaN(new Date(dateString))) {
            return '-';
        }

        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    }
//  const onEditHandler=(e)=>{
    
//     if(props?.page==="creator"){
//         e.stopPropagation()
//         props?.editHandler && props.editHandler(props.data)
//     }
//  }
    return (
        <>
        <div className={`${props?.className} accord-card sm-accord-card`} key={props?.data?.id}>
     <div className='flex flex-col gap-[0.375rem] sm:gap-[0.72rem]'>
             {props.isEvent && <div className='accord-top flex justify-between gap-[0.625rem]'>
                    <div className='flex'>
                        <div className='accord-community-name flex gap-[0.625rem]'>
                             <div className="pro-img"><img src={props?.data?.community?.displayPicture256 || props?.data?.communityPic || userAvatar} alt="" /></div>
                            {props.isEvent && <span>{props?.data?.communityName || props?.data?.community?.name}</span>}
                        </div>
                    </div>

                    {props.isEvent &&<span>{props?.event ? props?.data?.time : formatDate(props?.data?.createdAt)}</span>}
                        </div>}
                        
                     <div className={`${!props.isEvent ? 'flex accord-body cursor-pointer justify-between items-center' : 'flex justify-between items-end accord-body cursor-pointer'}`} data-id={props?.data?.id} onClick={handleCard}>
                        <div className='w-[70%] flex justify-start items-center'>
                            <span className={`text-sm text-left w-[50%]${isOpenCard && "invisible"}`}>{props?.data?.name || props?.data?.headline} 
                            </span>
                            {props?.data?.isFollowing &&  <span className="text-[0.5rem] text-[var(--cu-white-100)] px-2 py-[0.5px] bg-[var(--cu-black-800)] ml-2 rounded-sm">Following</span>}
                        </div>
                         <div className='flex items-center'>
                         {!props.isEvent &&  <span className='text-[.625rem]  '>{props?.event ? props?.data?.time : formatDate(props?.data?.createdAt)}</span>}
                         <img src={downArrow} alt=''
                             className={`${isOpenCard && 'active'} ml-2`} />
                         </div>
                    </div>
                    {isOpenCard &&
                        <div className='flex justify-between'>
                            <div className='accord-desciption flex flex-col text-left'>
                                {props.isEvent && !!props?.data?.community?.bannerPicture && <div className="banner-img">
                                    <img src={props?.data?.community?.bannerPicture} alt="banner" />
                                </div>}
                                {/* <h5 className="text-left  details-text">Details</h5> */}
                                <span>
                                    {props?.data?.details}
                                </span>
                            </div>
                           {/* {props.isEvent &&  <div onClick={handleEdit} className='accord-body '>
                            <span className=' text-[.625rem] '>Edit Button</span>

                            </div>} */}
                        </div>
                     }
                </div>
            </div>
        </>
    )
}
export default SmCard;