import "./CollectionItemGallery.scss";

const CollectionItemGallery = ({
  collectionCount,
  assetsCount,
  galleryCount,
}) => {
  return (
    <div className="details-section flex gap-2">
      <div className="details-item">
        <span className="item-name">Collections</span>
        <span className="item-count">{collectionCount}</span>
      </div>
      <div className="border-right"></div>
      <div className="details-item">
        <span className="item-name">Pieces</span>
        <span className="item-count">{assetsCount}</span>
      </div>
      <div className="border-right"></div>
      <div className="details-item">
        <span className="item-name">Galleries</span>
        <span className="item-count">{galleryCount}</span>
      </div>
    </div>
  );
};
export default CollectionItemGallery;
