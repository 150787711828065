import React, { useLayoutEffect, useRef } from "react"
import styles from "../../../Styles/MyCollections.module.scss"
import favGrey from 'Assets/Icons/fav_grey.svg'
import favLiked from 'Assets/Icons/fav_add.svg'
import { Fragment, useCallback, useEffect, useState } from "react"
import { useStore } from "App/hooks-store/store"
import useHttp from "App/hooks/use-http"
import NftZoomed from "App/Components/NftZoomed"
import useFrames from "App/hooks/use-frames";
import SkeltonCard from "App/Components/Ui/SkeltonCard"
import userAvatar from "Assets/Icons/logo-square.svg";
import { useLocation, useNavigate } from "react-router-dom";
import assetCountIcon from 'Assets/Icons/asset-count-icon.svg'
import useAuth from "App/hooks/use-auth"
const NftCard = props => {
    const [{leftMenuCollapse}, dispatch] = useStore();
    const { triggerAPI } = useHttp();
    const navigate = useNavigate();
    const [spinnerShow, setSpinnerShow] = useState(false);
    const [favHover, setFavHover] = useState(false)
    const [wordLimit, setWordLimit] = useState(35)
    const { getFrameFromStore } = useFrames();
    const [nft, setNft] = useState(null)
    const [isHover, setIsHover] = useState(false)
    const targetRef = useRef();
    const { pathname } = useLocation();
    const isToken = pathname.includes('my-tokens');
    const [isLoggedIn] = useAuth();
    const onMouseBlur = useCallback(() => {
        setFavHover(false);
    }, []);

    const onMouseFocus = useCallback(() => {
        setFavHover(()=>true);
    }, []);
    useEffect(() => {
        if (!!props.framesList)
            getFrameId()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nft, props.framesList])
    const getFrameId = () => {
        let flag = false
        let exist = props?.framesList?.find(frames => frames.asset === props.nft.asset)
        if (exist) {
            flag = true
            getFrame(exist?.frameId)
        }
        if (!flag) {
            setNft(props.nft)
        }

    }
    useEffect(() => {
        if(props.displayNft === 'lg') {
            setWordLimit(35);
        } else if(props.displayNft === 'sm'){
            setWordLimit(25);
        }
    }, [props.displayNft])
    const getFrameResult = useCallback((res) => {
        setNft(props.nft)
        setSpinnerShow(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, props.nft])
    const getFrame = (frameId) => {
        setSpinnerShow(true)
        getFrameFromStore(frameId, (res) => {
            getFrameResult(res)
        })
    }

    const favAddResponse = useCallback((res, e) => {
        switch (props.parent) {
            case "nft-collection":
                if (isToken)
                {                    
                    dispatch("favTokenToggle", { policy: e.target.dataset["policy"], value: true })                 
                }
                else
                {
                    dispatch("favToggle", { policy: e.target.dataset["policy"], value: true })
                }                
                break;
            case "art-bank":
                dispatch("favArtBankToggle", { policy: e.target.dataset["policy"], value: true })
                break;
            default:
                break;
        }
    }, [dispatch, props.parent, isToken])
    const favAddHandler = e => {
        setSpinnerShow(true)
        triggerAPI({
            url: `user/${props.parent}/favourite/add`, data: { policyId: e.target.dataset["policy"] }, method: 'post'
        }, (res) => {
            setSpinnerShow(false)
            favAddResponse(res, e)
        })
    }
    const favRemoveResponse = useCallback((res, e) => {
        switch (props.parent) {
            case "nft-collection":
                if (isToken)
                {                    
                    dispatch("favTokenToggle", { policy: e.target.dataset["policy"], value: false })                        
                }
                else
                {
                    dispatch("favToggle", { policy: e.target.dataset["policy"], value: false })
                }
                break;
            case "art-bank":
                dispatch("favArtBankToggle", { policy: e.target.dataset["policy"], value: false })
                break;
            default:
                break;
        }
    }, [dispatch, props.parent, isToken])
    const favRemoveHandler = e => {
        setSpinnerShow(true)
        triggerAPI({
            url: `user/${props.parent}/favourite/remove`, data: { policyId: e.target.dataset["policy"] }, method: 'delete'
        }, (res) => {
            setSpinnerShow(false)
            favRemoveResponse(res, e)
        })
    }
    const collectionSelectHandler = useCallback(() => {
       if(!props?.noClick) props.onCollectionSelect(props.nft)
    }, [props])

    const blurHandler = () => {
        setFavHover(false);
    }
    const hoverOnHandler = () => {
        setIsHover(true)
    }
    const hoverOffHandler = () => {
        setIsHover(false)
    }
    const openDetailsTabHandler = useCallback((frame) => {
        setTimeout(() => {
            props.onImgClick({ nft: props.nft, frame: frame })
        }, 280)
    }, [props])

    const ImageClickHandler = useCallback((e, frame) => {
        switch (e.detail) {
            case 1:
                openDetailsTabHandler(frame)
                break
            case 2:
                collectionSelectHandler()
                break
            default:
        }
    }, [collectionSelectHandler, openDetailsTabHandler])
    const updateHeight = () => {
        const root = document.documentElement;
        if (targetRef.current) {
          root?.style.setProperty(
            "--collection-card-height",
            `${targetRef.current.offsetWidth}px`
          );
        }
    }
    useLayoutEffect(() => {
        updateHeight()
        window.addEventListener("resize", () => {
            setTimeout(() => {
                updateHeight()            
            }, 1000);
        }, false);
    },[]);
    useEffect(() => {
        updateHeight()   
    },[props.selected]);

    useEffect(() => {
        setTimeout(() => {
            updateHeight()            
        }, 1000);
        updateHeight()
    },[leftMenuCollapse]);
    const navigatocreator = (e) => {
        e.stopPropagation();
        navigate(`/discover/communities/${props?.nft?.community?.communitySlug}/overview`);
    }

    return <div onMouseEnter={hoverOnHandler} onMouseLeave={hoverOffHandler} 
    className={`${styles["nft-display"]} 
        ${props.displayNft === "lg" && styles["nft-display-lg"]} 
        ${props.selected && styles["selected"]} flex-1 relative
        ${(spinnerShow || !nft) && styles["skelton-loading"]} ${props?.nftClass}`}>
        <SkeltonCard show={spinnerShow || !nft} height={`var(--collection-card-height)`} inline={true} containerClassName={`${styles["nft-img"]} flex`} />
        <div ref={targetRef} className={styles["collection-box-img"]} style={{aspectRatio:1}}>
            <div className={styles["tool-box"]}  onMouseOverCapture={onMouseFocus}
            onMouseLeave={onMouseBlur}
            onBlur={blurHandler}>
                {!props.selected && !props?.noFavourite && (<>
                    {isHover && !spinnerShow && nft && <Fragment>
                        {nft && !props.nft?.isCollectionFavourite && 
                        <div className="rounded-full bg-[#191D23] h-[1.5rem] w-[1.5rem] ml-1 mt-1 flex items-center justify-center absolute left-0 top-0">
                        <img className={`${styles["fav"]} `} data-policy={props.nft.policy} onClick={favAddHandler} alt="NoImg" src={favHover?favLiked:favGrey} />
                        </div>
                        }
                    </Fragment>}
                    {nft && props.nft?.isCollectionFavourite && 
                     <div className="rounded-full bg-[#191D23] h-[1.5rem] w-[1.5rem] ml-1 mt-1 flex items-center justify-center absolute left-0 top-0">
                    <img data-policy={props.nft.policy} onClick={favRemoveHandler} className="m" alt="NoImg" src={favLiked} />
                    </div>
                    }
                </>)}

            </div>
            <div className={`${styles["nft-img"]} flex justify-center`}>
                {!!(props.nft.collectionImage256??props.nft.nftcdnimage256) && !!nft &&
                    <NftZoomed
                    showAddDiv={props?.showAddDiv}  addDivClass={props?.addDivClass}
                    className={styles["collection-image"]} 
                    noClick={props?.noClick}
                    nft={props.nft.collectionImage256??props.nft.nftcdnimage256} data-index={props.index} onClick={ImageClickHandler} />
                }
            </div>
        </div>
        <div className={`flex-col px-[.25rem] py-1 gap-[.375rem]`}>
            <div className={`${styles["nft-type"]} flex  `}>
                <div className="flex gap-[.75rem] ">
              {!props?.noClick&&<img onClick={()=>  props?.nft?.community?.id? navigatocreator() : ()=>{}}
                    src={props?.nft?.community?.displayPicture256 || userAvatar} 
                    className={`${styles["nft-profile-img"]} w-[1.875rem] h-[1.875rem] rounded-full grey-border object-cover ${props?.nft?.community?.id && "cursor-pointer"}`}
                    alt='User Avatar'
                />}
                <div className="mt-1">
                <span className={`${styles["nft-name-gallery"]} `}>
                        {props.loading ? "loading..." : (
                            props?.nft?.community?.id ? (
                            <span onClick={navigatocreator} className="cursor-pointer">
                                {props.nft.collectionName ?? 
                                (props.nft.name?.length > wordLimit ? `${props.nft.name.substring(0, wordLimit - 1)}...` : props.nft.name)}
                            </span>
                            ) : (
                            props.nft.collectionName ?? 
                            (props.nft.name?.length > wordLimit ? `${props.nft.name.substring(0, wordLimit - 1)}...` : props.nft.name)
                            )
                        )}
                        </span>
                    {!props?.noClick&&<div className="mt-2 text-[#A0ABBB] text-[.875rem]">
                    {props?.nft?.community?.id ? (
                        <span onClick={navigatocreator} className="cursor-pointer">
                        {props.nft.community.name}
                        </span>
                    ) : (
                        'No community'
                    )}
                    </div>}
                </div>
                </div>
                {!props?.noClick&&<div className={`${styles["nft-price"]} flex  gap-[.5rem]  justify-start p-[.15rem] h-[100%]   ml-auto`}>
                    <img src={assetCountIcon} className="w-[1.5rem] min-w-[1.5rem]" alt=""/>
                    <span className="pt-[.35rem]">{props.nft.quantity}</span>
                </div>}
            </div>
        </div>
    </div>
}

export default NftCard