
import styles from "./NftFrameShop.module.scss"
import { useEffect, useMemo, useState } from "react"
import FrameFilter from "./Components/FrameFilter"
import NewFrames from "./Components/NewFrames"
// import luckyDraw from "Assets/Images/luckyDraw.png"
import useHttp from "App/hooks/use-http"
import { useSearchParams } from "react-router-dom"
import useCollectionLoader from "App/hooks/use-collectionLoader"
import { getMetadata } from "App/Helper/utilities"
import { useStore } from "App/hooks-store/store"
import useScreenSize from "App/hooks/use-screen-size"
import { useDebounce } from "App/hooks/use-debounce"

const NftFrameShop = () => {
    const screenSize = useScreenSize()?.width;
    const { triggerAPI } = useHttp();
    const [frames, setFrames] = useState([])
    const [framesLoading, setFramesLoading] = useState(true)
    const [displayFilter, setDisplayFilter] = useState(false)
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [selectedNft, setSelectedNft] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const debounceValue = useDebounce(searchValue)
    const [dropDownFilter, setDropDownFilter] = useState(false);
    const [searchParams] = useSearchParams();
    const { sliceCollectionAPI } = useCollectionLoader();
    const [displayChange, setDisplayChange] = useState('lg')
    const [frameCountState, setFrameCountState] = useState({
        totalFrames:0,
        allPremiumFrameCount:0,
        userAvailablePremiumFrames:0,
        userAddedPremiumFrames:0,
    })
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [fetchData, setFetchData] = useState(false);
    const [frameAdding, setFrameAdding] = useState(false);
    const [loadMoreActive, setLoadMoreActive] = useState(false)
    const [{activeMenuIcons, allCollectionData, collection}, dispatch] = useStore();
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    const getImageDetails = () => {
        const data = new URLSearchParams({
            asset: searchParams.get("previewNft"),
            networkType: 'MAINNET'
        })
        triggerAPI({
            url: `user/nft-collection/asset/detail?${data}`, method: 'get'
        }, (res) => {
            const { data } = res;
            const result = getMetadata(data, 'isFavouriteNFTCollection')
            setSelectedNft({ ...result, img: result?.image })
        });
    }

    useEffect(() => {
        if (searchParams.get("previewNft")) {
            getImageDetails()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const sort = [
        { id: 0, value: "Name A-Z" },
        { id: 1, value: "Name Z-A" },
        { id: 2, value: "Price H - L" },
        { id: 3, value: "Price L - H" },
    ]
    const [sortBy, setSortBy] = useState({ sortBy: "name", orderBy: "ASC" })
    const [filterCategories, setFilterCategories] = useState({
        image: { name: "Image", list: [], isAsset: true },
        shape: { name: "Shape", list: [] },
        color: { name: "Color", list: [], isColor: true },
        age: { name: "Age", list: [] },
        style: { name: "Style", list: [] },
        material: { name: "Material", list: [] },
        addOn: { name: "Add-ons", list: [] },
        complexity: { name: "Complexity", list: [] }
    })
    const [colors, setColors] = useState({})
    const [selectedColor, setSelectedColor] = useState(null)

    useEffect(() => {
        getColors()
        getStyles()
        getMaterials()
        getAges()
        getShapes()
        getAddons()
        getComplexity()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {        
        if (allCollectionData?.collection?.length??0 < collection?.length??0)
        {
            sliceCollectionAPI(allCollectionData?.collection)
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allCollectionData?.collection, collection])

    //Frames populate Api
    const getFrames = (q, skipLoading,) => {
        if(!skipLoading){
            setFramesLoading(true)
        }
        setLoadMoreActive(true)
        const data = {
            search: searchValue,
            "page": page,
            "items": "40",           
            filter: !!q ? {
                ...q, ...sortBy, "isUserAdded": dropDownFilter, "categoryId": [3]
            } : { ...sortBy, "isUserAdded": dropDownFilter, "categoryId": [3] }
        }
        data.filter.displayStatus = true;
        triggerAPI({
            url: `frame-asset/user/get`, data: data, method: 'post'
        }, getFramesResult);
    }
    const getFramesResult = (res) => {
        const data = res.data;
            setFrameCountState({
                totalFrames: data?.pageMeta?.totalItems,
                allPremiumFrameCount: data?.allPremiumFrameCount,
                userAvailablePremiumFrames: data?.userAvailablePremiumFrames,
                userAddedPremiumFrames: data?.userAddedPremiumFrames,
            })
        let temp = data.list.map(frame => {
            return {
                id: frame.id,
                name: frame.name,
                imageUrl: frame.s3ImageUrl256,
                imageUrlLand: frame.s3ImageUrlLandscape256,
                price: frame.price ? frame.price : "00",
                inStock: frame.inStock,
                project: frame.project,
                metaInfo: frame.metaInfo,
                currency: frame.currencyType.symbol,
                nftUid: frame.nftUid,
                projectUid: frame.projectUid,
                totalEdition: frame?.totalEdition,
                isLandscape: false,
                isUserAdded:frame?.isUserAdded
            }
        })

        setFrames((prev) =>
            page === 1 ? [...temp] : [...prev, ...temp]
        )
        setFramesLoading(false)
        setLoadMoreActive(false)
    }

    //Color filter populate api
    const getColors = () => {
        triggerAPI({
            url: `frame-asset/color/get`, method: 'get'
        }, colorsApiResult);
    }
    const colorsApiResult = (res) => {
        const data = res.data
        let list
        list = data.map(color => { return { name: color.name, id: color.id } })
        list.reverse()
        setColors(list)
    }

    const loadMore = (e) => {
        if (!loadMoreActive) {
          setPage((pre) => pre + 1);
          setFetchData((prev)=>!prev)
        }
      ;
    }


    //Styles filter populate api
    const getStyles = () => {
        triggerAPI({
            url: `frame-asset/style/get`, method: 'get'
        }, stylesApiResult);

    }
    const stylesApiResult = (res) => {
        const data = res.data
        let list
        list = data.map(style => { return { name: style.name, id: style.id } })
        setFilterCategories(prevState => { return { ...prevState, style: { name: "Style", list: list } } })

    }


    //Materials filter populate api
    const materialsApiResult = (res) => {
        const data = res.data
        let list
        list = data.map(material => { return { name: material.name, id: material.id } })
        setFilterCategories(prevState => { return { ...prevState, material: { name: "Material", list: list } } })

    }
    const getMaterials = () => {
        triggerAPI({
            url: `frame-asset/material/get`, method: 'get'
        }, materialsApiResult);
    }


    //Ages filter populate api
    const agesApiResult = (res) => {
        const data = res.data
        let list = []
        list = data.map(age => { return { name: age.name, id: age.id } })
        setFilterCategories(prevState => { return { ...prevState, age: { name: "Age", list: list } } })

    }
    const getAges = () => {
        triggerAPI({
            url: `frame-asset/age/get`, method: 'get'
        }, agesApiResult);
    }


    //Shapes filter populate api
    const shapesApiResult = (res) => {
        const data = res.data
        let list = []
        list = data.map(shape => { return { name: shape.name, id: shape.id } })
        setFilterCategories(prevState => { return { ...prevState, shape: { name: "Shape", list: list } } })

    }
    const getShapes = () => {
        triggerAPI({
            url: `frame-asset/shape/get`, method: 'get'
        }, shapesApiResult);
    }

    //Addons filter populate api
    const addonsApiResult = (res) => {
        const data = res.data
        let list = []
        list = data.map(addOn => { return { name: addOn.name, id: addOn.id } })
        list.sort(function (x, y) { return x.name === "none" ? -1 : y.name === "none" ? 1 : 0; });
        setFilterCategories(prevState => { return { ...prevState, addOn: { name: "Add-ons", list: list } } })

    }
    const getAddons = () => {
        triggerAPI({
            url: `frame-asset/addOn/get`, method: 'get'
        }, addonsApiResult);
    }

    //Complexity filter populate api
    const complexityApiResult = (res) => {
        const data = res.data
        let list = []
        list = data.map(complexity => { return { name: complexity.name, id: complexity.id } })
        setFilterCategories(prevState => { return { ...prevState, complexity: { name: "Complexity", list: list } } })

    }
    const getComplexity = () => {
        triggerAPI({
            url: `frame-asset/complexity/get`, method: 'get'
        }, complexityApiResult);
    }

    //Filter query handler
    const filterHandler = (e, resetColor) => {
        let queries = {}
        for (let items in filterCategories) {
            if (e[filterCategories[items].name]) {
                queries[items] = []
                for (let item of e[filterCategories[items].name]) {
                    for (let listItem of filterCategories[items].list) {
                        if (item === listItem.name)
                            queries[items].push(listItem.id)
                    }
                }
            }
        }
        if (resetColor)
            delete queries.color
        else if (selectedColor)
            queries["color"] = [Number(selectedColor)]
        setQuery(queries)
        setPage(1)
        setIsFilterApplied(true);
        setFetchData((prev)=>!prev)
    }
    //Color filter handler
    const colorSelectHandler = e => {
        if (selectedColor === e.currentTarget.dataset["id"])
            setSelectedColor(null)
        else
            setSelectedColor(e.currentTarget.dataset["id"])
    }
    const resetColorSelectHandler = e => {
        setSelectedColor(null)
    }

    //Filter display toggle
    const filterDisplayHandler = e => {
        setDisplayFilter(prevState => { return !prevState })
    }

    //Nft selection for detailed view
    const nftSelectHandler = e => {
        setSelectedNft(e)
    }

    //Sort
    const sortHandler = (e) => {
        switch (e.value) {
            case "Name A-Z":
                setSortBy({ sortBy: "name", orderBy: "ASC" })
                break
            case "Name Z-A":
                setSortBy({ sortBy: "name", orderBy: "DESC" })
                break
            case "Price H - L":
                setSortBy({ sortBy: "price", orderBy: "DESC" })
                break
            case "Price L - H":
                setSortBy({ sortBy: "price", orderBy: "ASC" })
                break
            default:
                setSortBy({ sortBy: e.value.toLowerCase() + "Id" })
                break
        }
    }

    const dropDownOptions = useMemo(() => {
        return [
            {
                id: 0,
                value: "All Frames",
                count: frameCountState?.allPremiumFrameCount,
                showCount: true
            },
            {
                id: 1,
                value: "My Frames",
                count: `${frameCountState?.userAddedPremiumFrames}/${frameCountState?.userAvailablePremiumFrames}`,
                showCount: true,
            },
        ]
    }, [frameCountState]);
    
    
    useEffect(() => {
        dispatch('resetAll');
     // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    //Menu options
    useEffect(() => {
        dispatch('enableTopIcons', {filterSort: (isPortrait&&screenSize<=550), lgView: !(isPortrait&&screenSize<=550), smView: !(isPortrait&&screenSize<=550), dropDown: true, search: true});
        dispatch('setTopMenuBarOptions', {showMenuIcons: true, showTopMenus: false});
        dispatch('setSortingOptions', sort);
        dispatch('setActiveIcons', {
            sort: sort[0]?.value,
            displayType: 'lg',
            search: '',
            dropDown: true,
        });

        return () => {
            dispatch('resetAll')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPortrait,screenSize])
    //Menu dropdown option
    useEffect(() => {      
        dispatch('setDropDownOptions', dropDownOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPortrait,screenSize,frameCountState])

    const updateButtonText = (frameId) => {
      let updatedFrames = frames?.map((frame)=>{
        if(frameId === frame?.id){
            return {...frame, isUserAdded: !frame?.isUserAdded}
        }
        return frame
      });
      let filteredUpdatedFrames = updatedFrames?.filter((frame)=>frame?.isUserAdded);
      if(dropDownFilter){
        updatedFrames = filteredUpdatedFrames;
        setFrameCountState((prev)=>({...prev, totalFrames:filteredUpdatedFrames?.length}))
      }
      setFrameCountState((prev)=>({...prev, userAddedPremiumFrames:filteredUpdatedFrames?.length}))
      setFrames(updatedFrames);
      setTimeout(()=>setFrameAdding(false), 600)
    }

    // Add and Remove Frame handler
    const handleAddorRemoveFrame = async(frameId, frameAdded) => {
        setFrameAdding(true)
        const payload = {
            frameAssetId: frameId
        }
        // end point changing using frameAdded value
        const endpoint = frameAdded ? `remove-premium-frame` : `add-premium-frame`
        // API call
       await triggerAPI({
            url: `frame-asset/${endpoint}`, data: payload, method: 'post'
        }, (res) => { updateButtonText(frameId); dispatch("showToast", {
            toast: { toastMode: "success", message: frameAdded ? `Frame removed` : `Frame added` },
        }); }, (error) => {
            dispatch("showToast", {
                toast: { toastMode: "error", message: error?.response?.data?.error?.message },
            });
           setFrameAdding(false);
        });

    }

    useEffect(() => {
        setPage(1)
        setDropDownFilter(activeMenuIcons?.dropDown === 'My Frames' ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons?.dropDown])
    useEffect(() => {
        getFrames(query)        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropDownFilter, debounceValue])
    useEffect(() => {   
        getFrames(query, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData])

    useEffect(() => {
        setDisplayChange(activeMenuIcons?.displayType)
    }, [activeMenuIcons?.displayType])
    
    useEffect(() => {
        setShowFilter(activeMenuIcons?.filterSort)
    }, [activeMenuIcons?.filterSort])
    
    useEffect(() => {
        if(activeMenuIcons?.search !== undefined)
            setSearchValue(activeMenuIcons?.search);
    }, [activeMenuIcons?.search])
    
    useEffect(() => {
        const findOne = sort?.find(item => item?.value === activeMenuIcons?.sort)
        if(activeMenuIcons?.sort && findOne)
            sortHandler({value: activeMenuIcons?.sort})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons?.sort])

    return <div className={`${styles["nft-frame-shop"]} frame-shop-box`}>
        <div className={`${styles["frame-content"]}`}>
            {displayFilter && <div className={styles["overlay"]} onClick={filterDisplayHandler}></div>}
            <FrameFilter showFilter={showFilter ? styles['show-filter'] : ''} filterCategories={filterCategories} colors={colors} selectedColor={selectedColor} onColorSelect={colorSelectHandler} onResetColor={resetColorSelectHandler}
                sort={sortBy} noScroll={true} appliedNft={selectedNft} onSelectNft={nftSelectHandler} className={`${styles["filter"]} ${displayFilter && styles.active}`} onApplyFilter={filterHandler}></FrameFilter>

                <NewFrames displayChange={displayChange} nft={selectedNft} frames={frames} framesLoading={framesLoading}
                    isFilterApplied={isFilterApplied} setIsFilterApplied={setIsFilterApplied} setFramesLoading={setFramesLoading} onAddorRemoveFrame={handleAddorRemoveFrame} isAddingFrame={frameAdding}
                     frameCountState={frameCountState}
                     loadMore={loadMore} isMyFramesActive={dropDownFilter}
                ></NewFrames>
        </div>
    </div>
}

export default NftFrameShop