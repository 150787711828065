import sliderPrevIcon from "Assets/Icons/GalleryControls/left-arrow-d.svg";
import sliderNextIcon from "Assets/Icons/GalleryControls/right-arrow-d.svg";
import sliderPlayIcon from "Assets/Icons/GalleryControls/play-d.svg";
import sliderPauseIcon from "Assets/Icons/GalleryControls/pause-d.svg";
import sliderTimerIcon from "Assets/Icons/GalleryControls/timer-d.svg";
import sliderTimerIconActive from "Assets/Icons/GalleryControls/timer-s.svg";
import backGroundIcon from "Assets/Icons/GalleryControls/background-d.svg";
import backGroundIconActive from "Assets/Icons/GalleryControls/background-s.svg";
import frameIcon from "Assets/Icons/GalleryControls/frame-d.svg";
import frameIconActive from "Assets/Icons/GalleryControls/frame-s.svg";
import enlargeIcon from "Assets/Icons/GalleryControls/enlarge-d.svg";
import layoutIcon from "Assets/Icons/GalleryControls/layout-icon.svg";
import layoutIconActive from "Assets/Icons/GalleryControls/layout-icon-s.svg";
import TimeSelection from "../TimeSelection";
import { useRef, useEffect, useCallback } from "react";
import settingIcon from "Assets/Icons/GalleryControls/settings-d.svg";
import settingIconActive from "Assets/Icons/GalleryControls/settings-s.svg";
import displayListIcon from "Assets/Icons/GalleryControls/display-list-d.svg";
import displayListIconActive from "Assets/Icons/GalleryControls/display-list-s.svg";
import './GalleryControlPanel.scss';
import SettingsControl from "./Components/SettingsControl";
import DisplayControl from "./Components/DisplayControl";
import ScreenStyle from "./Components/ScreenStyle";
import MultiLayoutsControl from "./Components/MultiLayoutsControl";
import MultiBgControl from "./Components/MultiBgControl";

const GalleryControlPanel = (props) => {
    const listTime = {
        sec: [...Array(60).keys()],
        min: [...Array(60).keys()],
        hr: new Array(24).fill('').map((x, i) => i),
    }
    const clickRef = useRef(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        document.addEventListener("mousemove", onMouseMoveHandler);
        document.addEventListener("touchstart", onMouseMoveHandler);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
            document.removeEventListener("mousemove", onMouseMoveHandler);
            document.removeEventListener("touchstart", onMouseMoveHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleClickOutside = event => {
        if (clickRef.current && !clickRef.current.contains(event.target)) {
            props?.setToggleControls(false);
        }
    };
    
    const timer = useRef(null);
    const onMouseMoveHandler = useCallback(() => {
        if (!props?.isAppV1) {
            if (timer.current) {
                clearTimeout(timer.current);
                props?.setControlPanelVisibility(true)
            }
            timer.current = setTimeout(() => {
                props?.setControlPanelVisibility(false);
                // props?.setToggleControls(false);
            }, 2000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[timer])

    const handleShowTime = () => {
        props?.setToggleControls(prev => { return {timer: !(prev?.timer)}});
        // props?.closeOtherControls('timer');
    }
    
    const handleShowSetting = useCallback(() => {
        props?.setToggleControls(prev => { return {settings: !(prev?.settings)}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const handleShowDisplayList = useCallback(() => {
        props?.setToggleControls(prev => { return {displayList: !(prev?.displayList)}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const handleShowScreenStyle = useCallback(() => {
        props?.setToggleControls(prev => { return {screenStyle: !(prev?.screenStyle)}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const handleShowLayout = useCallback(() => {
        props?.setToggleControls(prev => { return {layouts: !(prev?.layouts)}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    const handleShowBg = useCallback(() => {
        props?.setToggleControls(prev => { return {backgroundWall: !(prev?.backgroundWall)}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    useEffect(() => {
        if(!!props?.toggleControls && !props?.isMeta)
            props?.toggleSlidePause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.toggleControls, props?.isMeta])
    return (
        <div className={`gallery-control-container relative ${props?.controlPanelVisibility ? 'active-control' : ''} ${props?.zoom ? 'zoom-in' : ''}`} ref={clickRef}>
            {props?.toggleControls?.screenStyle && 
                    <ScreenStyle 
                        setScreenControl={props?.setScreenControl} 
                        screenControls={props?.screenControls} 
                        setToggleControls={props?.setToggleControls}
                    />
            }
            {props?.isMono && <DisplayControl 
                show={props?.toggleControls?.displayList}
                list={props?.galleryList} 
                setFilteredGalleryList={props?.setFilteredGalleryList}
                currentPlayingAsset={props?.currentPlayingAsset}
                slideGoTo={props?.slideGoTo}
            />}
            {props?.toggleControls?.settings && 
                <SettingsControl 
                    showTransition={!props?.isMeta && !props?.isMulti} 
                    showCaption={true} 
                    showEnhanced={props?.isMulti} 
                    settingControls={props?.settingControls} 
                    setSettingControls={props?.setSettingControls} 
                    handleGalleryView={props?.handleGalleryView} galleryType={props?.galleryType}
                />
            }
            {props?.toggleControls?.backgroundWall && 
                <MultiBgControl 
                    multiStyleList={props?.multiStyleList} multiColorList={props?.multiColorList} 
                    bgWallFilter={props?.bgWallFilter} setBgWallFilter={props?.setBgWallFilter} 
                    multiWallList={props?.isMeta ? props?.metWallList : props?.multiWallList} 
                    selectedMultiBg={props?.selectedMultiBg} setSelectedMultiBg={props?.setSelectedMultiBg}
                    isMeta={props?.isMeta}
                />
            }
            {props?.toggleControls?.layouts && 
                <MultiLayoutsControl 
                    selectedMultiLayout={props?.selectedMultiLayout}
                    setSelectedMultiLayout={props?.setSelectedMultiLayout}
                    multiLayoutList={props?.multiLayoutList}
                />
            }
            <div className="gallery-control-panel relative">
                <div className="gallery-control gap-[0.29rem]">
                    {!props?.isMeta && <div className="cursor-pointer" onClick={props?.handlePrevSlide}>
                        <img src={sliderPrevIcon} alt='slide prev icon' />
                    </div>}
                    {props?.slidePlaying ?
                        <div className="cursor-pointer" onClick={props?.toggleSlidePause}>
                            <img src={sliderPauseIcon} alt='pause icon' />
                        </div>
                        :
                        <div className="cursor-pointer" onClick={props?.toggleSlidePlay}>
                            <img src={sliderPlayIcon} alt='play icon' />
                        </div>
                    }

                    <div className="cursor-pointer" onClick={props?.handleNextSlide}>
                        <img src={sliderNextIcon} alt='slide next icon' />
                    </div>
                </div>
                <div className="gallery-control gap-[1.45rem]">
                    <div className="cursor-pointer relative" onClick={handleShowTime}>
                        <img src={props?.toggleControls?.timer ? sliderTimerIconActive : sliderTimerIcon} alt='' />
                        <TimeSelection
                            listTime={listTime}
                            showTitle={true}
                            show={props?.toggleControls?.timer}
                            hideSelectBar={true}
                            timerOnchange={props?.timerOnchange}
                            initialTime={props?.initialTime}
                        />
                    </div>
                    {props?.isMulti &&
                        <div className="cursor-pointer relative" onClick={handleShowLayout}>
                            <img src={props?.toggleControls?.layouts ? layoutIconActive : layoutIcon} alt='' />
                        </div>
                    }
                    {(props?.isMulti || props?.isMeta) &&
                        <div className="cursor-pointer relative" onClick={handleShowBg}>
                            <img src={props?.toggleControls?.backgroundWall ? backGroundIconActive : backGroundIcon} alt='' />                            
                        </div>
                    }
                    {props?.isMono &&
                        <div className="cursor-pointer relative" >
                            <img onClick={handleShowScreenStyle} src={props?.toggleControls?.screenStyle ? frameIconActive : frameIcon} alt='' />
                            
                        </div>
                    }
                    {props?.isMono &&
                        <div className="cursor-pointer relative" onClick={handleShowDisplayList}>
                            <img src={props?.toggleControls?.displayList ? displayListIconActive : displayListIcon} alt='' />
                            
                        </div>
                    }
                    <div className="cursor-pointer relative" onClick={handleShowSetting}>
                        <img src={props?.toggleControls?.settings ? settingIconActive : settingIcon} alt='' />                        
                    </div>
                    {!props?.zoom && (
                        <div className="cursor-pointer" onClick={props?.handleZoomIn}>
                            <img src={enlargeIcon} alt='' />
                        </div>
                    )}                    
                </div>
            </div>
        </div>
    )
}

export default GalleryControlPanel;