import Accordion from 'App/Components/Ui/Accordion'
import AccordionExpander from 'App/Components/Ui/Accordion/AccordionExpander'
import {  useState } from 'react'
import styles from '../../FrameFilter.module.scss'
const FrameColors = props => {
    const [expand, setExpand] = useState(false)
    const toggleAccordian = () => {
        setExpand((prev) => !prev)
    }
    return <div className={`${styles["frame-colors"]}`}>
        <Accordion>
            <div className="expander text-left cursor-pointer">
                <div onClick={toggleAccordian} className={`accordion-arrow ${expand  ? 'accordion-arrow-expanded': 'accordion-arrow-collapsed'}`}>Color</div>
                {
                    <AccordionExpander expand={expand}>
                        <div className={`${styles["color-box"]}`}>
                            {props.colors[0] && props.colors.map(color => <div onClick={props.onColorSelect} key={color.id} data-id={color.id} className={`${styles["color-circle"]} ${Number(props.selectedColor) === color.id ? styles["selected"] : ""} ${(color.name === "multi-color" ? styles["multi"] : "")}`} style={{ backgroundColor: color.name }}></div>)}
                        </div>
                    </AccordionExpander>
                }
            </div>
        </Accordion>
    </div>
}

export default FrameColors