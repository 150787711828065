import { useEffect, useState } from "react";
import useCollectionLoader from "App/hooks/use-collectionLoader"
import { useStore } from "App/hooks-store/store"
import "./AssetsOnDemand.scss";
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";

const AssetGrid = (props) => {
  const [store, dispatch] = useStore();
  const { singleCollection,singleCollectionLoadingStatus } = store
  let [page, setPage] = useState(0);
  const [lastloadedpage, setLastLoadedPage] = useState(-1);
  const [loading, setLoading] = useState(null);  
  const [showNoData, setShowNoData] = useState(false);  
  const pageCount = 99;    
  const { sliceSingleCollectionAPI } = useCollectionLoader();
useEffect(()=>{
  if(singleCollectionLoadingStatus==="finished"){
    setTimeout(()=>{
      setShowNoData(true)
    },2000)
  }else{
    setShowNoData(false)
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[singleCollectionLoadingStatus])
  const getAssetDetails = () => {        
    let pageresults = props?.assetData?.filter(a => a.asset_name !== "").slice((page * pageCount), ((page * pageCount) + pageCount)).map(a => {return { asset: props.policyId + a.asset_name, policy_id: props.policyId,  asset_name: a.asset_name }});            
    sliceSingleCollectionAPI(pageresults);    
    setLoading(false);    
    setLastLoadedPage(page);
  };

  useEffect(() => {
    if (loading==null && lastloadedpage< page && props?.assetData?.length > 0)
    {
      if (page===0 && lastloadedpage === -1)
      {
        dispatch("resetSingleCollection")
      }    
      setLoading(true);
      getAssetDetails();
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.assetData, lastloadedpage, page])     
 
  const onScrollHandler = (e) => {
    if (e.target.scrollTop + e.target.clientHeight > e.target.scrollHeight - 500)
    {      
      if (singleCollection?.length === (page+1)*pageCount)
      {        
        page++;        
        setPage(page);
        setLoading(true);
        getAssetDetails();   
      }
    }
  }
  
  return (
    <div className="max-h-[20rem] min-h-[20rem] overflow-y-auto">
      {singleCollectionLoadingStatus==="finished"&&loading!==null&&showNoData?singleCollection?.length?<div className="image-grid" onScroll={onScrollHandler}>
        {singleCollection?.map((item,i) => (
          <div className="img-wrapper" key={item?.asset}>
            <img src={item?.nftcdnimage256} alt="no" /> 
          </div>         
        ))}
      </div> 
      :
      <div className="no-data flex justify-center create-collection-no-pieces pt-[5rem] visibleSlowly">No pieces to display.</div>:
      <div className="image-grid">{[...Array(27)]?.map((el, index) => (
        <div className={"h-full w-full skeleton-wrapper pieces-loader"} key={index + 1}>
          <SkeletonLoader
            containerClassName={""}
          />
        </div>
      )) }   
    </div>}
    </div>
  );
}
export default AssetGrid;