/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./Main.module.scss";
import Header from "../Header";
import Footer from "../Footer";
import NavBar from "App/Components/Navbar";
import RoutesMain from "../../MainRoutes";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Toaster from "App/Components/Ui/Toaster";
import Spinner from "App/Components/Ui/Spinner";
import { useStore } from "App/hooks-store/store";
import Status from "./Components/Status";
import useAuth from "App/hooks/use-auth";
import homeMenu from "Assets/Icons/TopMenu/home-icon-menu.svg";
import communityMenu from "Assets/Icons/TopMenu/community-icon-menu.svg";
import display from "Assets/Icons/TopMenu/display-icon-menu.svg";
import curateMenu from "Assets/Icons/TopMenu/curate-icon-menu.svg";
import infoMenu from "Assets/Icons/TopMenu/information-icon-menu.svg";
import userInfo from "Assets/Icons/TopMenu/userInfo.svg";
import wallet from "Assets/Icons/TopMenu/mob-wallet.svg";
import discoverMenu from "Assets/Icons/TopMenu/discover-icon-menu.svg";
import { categorySort, scrollToTop } from "App/Helper/utilities";
import { useLocation,useSearchParams } from "react-router-dom";
import infoIcon from "Assets/Images/Info.svg";
import closeIcon from "Assets/Icons/close.svg";
import useScreenSize from "App/hooks/use-screen-size";
import collectionIcon from 'Assets/Icons/my-collection-icon.svg'
import framesIcon from 'Assets/Icons/frames-icon.svg'
import announcementsIcon from 'Assets/Icons/announce-svg.svg'
import postsIcon from 'Assets/Icons/posts-svg.svg'
import eventsSvg from 'Assets/Icons/events-svg.svg'
import collectorsIcon from 'Assets/Icons/collectors-icon.svg'
import allSvg from 'Assets/Icons/menu-all-svg.svg'
import useHttp from "App/hooks/use-http";
import displayIcon from 'Assets/Icons/display-icon.svg'
import Twitter from "Assets/Icons/x.svg";
import Youtube from "Assets/Icons/utube.svg";
import Discord from "Assets/Icons/w-discord.svg";
import Support from 'Assets/Icons/social/Support.svg'
import Read from 'Assets/Icons/read-icon.svg'
import Listen from 'Assets/Icons/listen-icon.svg'
import Community from 'Assets/Images/user-group.svg';
import artist from 'Assets/Icons/Art.svg';
import onchain from "Assets/Icons/onchain-link.svg";
import photographer from "Assets/Icons/Photography.svg";
import music from "Assets/Icons/music.svg";
import screenDisplaySvg from 'Assets/Icons/screen_display.svg'
import { useSelector } from "react-redux";
import { getColelctionSpinner } from "App/ReduxStore/Slicers/spinSlice";
import MusicPlayer from "App/Components/MusicPlayer";
import Draggable from 'react-draggable';
const Main = () => {
  const isLoggedIn = useAuth()[0];
  const { pathname } = useLocation();
  const headerRef = useRef();
  const sideMenuRef = useRef();
  const collectionSpinner = useSelector(getColelctionSpinner)
  const { triggerAPI } = useHttp();
  const [
    {
      spinnerMode,
      toast,
      collectionLoadingStatus,
      artBankLoadingStatus,
      leftMenuCollapse,
    },
    dispatch,
  ] = useStore();
  const screenSize = useScreenSize()?.width;
  const [category, setCategory] = useState([]);
  const getCategoryResponse = useCallback(
    (res) => {
      const cateList = categorySort([...res?.data?.list])
      setCategory(cateList);
    },
    [dispatch]
  );
const getCategoryList = useCallback(() => {
    triggerAPI(
      {
        url: `category/get`,
        data: {
          page: 1,
          items: 1000,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getCategoryResponse
    );
  }, [dispatch, getCategoryResponse, triggerAPI]);

useEffect(()=>{
    if (document.location.pathname != "/displaygallery")
    {
      getCategoryList()
    }    
},[])
  const isCollectionPage =
    pathname?.includes("collections/my-collections") ||
    pathname?.includes("curate/my-galleries") ||
    pathname?.includes("curate/frame-shop") ||
    pathname?.includes("curate/frame-shop") ||
    pathname?.includes("community");
  const [searchParams] = useSearchParams();
  const isAppV1 = searchParams.get("source") === 'appv1';
  const navElements = useMemo(()=>{
    const fSet=[{
      id: 1,
      element: "Home",
      link: "home",
      icon: homeMenu,
    },
    // {
    //   id: 1,
    //   element: "Collections",
    //   link: "collections/my-collections",
    //   icon: collectionIcon,
    // },
    // {
    //   id: 2,
    //   element: "Galleries",
    //   link: "curate/my-galleries",
    //   icon: curateSvg,
    // },
    // {
    //   id: 3,
    //   element: "Announcements",
    //   link: "announcements",
    //   icon: announcementsIcon,
    // },
    // {
    //   id: 4,
    //   element: "Events",
    //   link: "events",
    //   icon: eventsSvg,
    // },
    // {
    //   id: 5,
    //   element: "Home",
    //   link: "home",
    //   icon: homeMenu,
    // },
    // {
    //   id: 6,
    //   element: "Collections",
    //   link: "collections/my-collections",
    //   icon: collectionIcon,
    // },
    {
      id: 2,
      element: "Collection",
      header: "My Cur8",
      icon: collectionIcon,
      walletCheck:true,
      link: "curate/collections/my-collections",
      // subNav: [
      //   {
      //     element: "My Collections",
      //     link: "collections/my-collections",
      //   },
      //   {
      //     element: "My Galleries",
      //     link: "curate/my-galleries",
      //   },
      //   {
      //     element: "Frames",
      //     link: "frame-shop",
      //   },
      // ],
    },
    // {
    //   id: 11,
    //   element: "CollectionV2",
    //   icon: collectionIcon,
    //   link: "collections/collectionsv2",
    //   // subNav: [
    //   //   {
    //   //     element: "My Collections",
    //   //     link: "collections/my-collections",
    //   //   },
    //   //   {
    //   //     element: "My Galleries",
    //   //     link: "curate/my-galleries",
    //   //   },
    //   //   {
    //   //     element: "Frames",
    //   //     link: "frame-shop",
    //   //   },
    //   // ],
    // },
    {
      id: 3,
      element: "Galleries",
      icon: curateMenu,
      walletCheck:true,
      link: "curate/my-galleries",
      // subNav: [
      //   {
      //     element: "Collections",
      //     link: "discover/collections",
      //   },
      //   {
      //     element: "Creators",
      //     link: "discover/creators",
      //   },
      //   {
      //     element: "Collectors",
      //     link: "discover/collectors",
      //   },
      // ],
    },
    {
      id: 4,
      element: "Frames",
      link: "curate/frame-shop",
      icon: framesIcon,
    },
    {
      id: 5,
      element: "Posts",
      link: "curate/posts",
      walletCheck:true,
      // externalLink: true,
      icon: postsIcon,
    },
    {
      id: 6,
      element: "Events",
      link: "curate/events",
      walletCheck:true,
      // externalLink: true,
      icon: eventsSvg,
    },
  
    {
      id: 7,
      element: "Display",
      header: "Play",
      //divider:true,
      icon: displayIcon,
      link: "display",
    },
    {
      id: 8,
      element: "Read",
      link: "read/collections/my-collections",
      icon: Read,
    },
    {
      id: 9,
      element: "Listen",
      link: "listen/collections/my-collections",
      icon: Listen,
    },
    // {
    //   id: 5,
    //   element: "Community",
    //   icon: communityMenu,
    //   link: "",
    //   // section: true,
    //   subNav: [
    //     {
    //       element: "Announcements",
    //       link: "announcements",
    //     },
    //     {
    //       element: "Events",
    //       link: "events",
    //     },
    //   ],
    // },]
  ]
    const categoryNav= category?.filter(el=>el?.name?.toLowerCase()!=="other")?.map((val,i)=>{
      let link={
        id: 1000+i,
        element: val?.name,
        link: `explore/${val?.slug}`,
        icon: val?.icon,
      }
      if(i===0){
        link.header= "Explore"
      }
      return link
    })
    const sSet=[
    //   {
    //   id: fSet?.length+categoryNav?.length+1,
    //   element: "All",
    //   link: "discover/collections",
    //   icon: allSvg,
    //   header: "Explore"
    // },
 
    {
      id: 2002,
      header: "Discover",
      element: "Communities",
      link: "discover/communities",
      icon: Community,
    },
    {
      id: 2003,
      element: "Artists",
      link: "discover/artists",
      icon: artist,
    },
    {
      id: 2004,
      element: "Onchain Artists",
      link: "discover/onchain-artists",
      icon: onchain,
    },
    {
      id: 2005,
      element: "Photographers",
      link: "discover/photographers",
      icon: photographer,
    },
    {
      id: 2006,
      element: "Musicians",
      link: "discover/musicians",
      icon: music,
    },
    {
      id: 2007,
      element: "Collectors",
      link: "discover/collectors",
      icon: collectorsIcon,
    },
    {
      id: 2008,
      header: "Socials",
      element: "Twitter",
      link: "https://twitter.com/Cur8Labs",
      icon: Twitter,
    },
    {
      id: 2009,
      element: "Discord",
      link: "https://discord.com/invite/vHqfQ5vPFd",
      icon: Discord,
    },
    {
      id: 2010,
      element: "Youtube",
      link: "https://www.youtube.com/@cur8galleries",
      icon: Youtube,
    },
    {
      id: 2011,
      element: "Information",
      link: "/information-desk",
      icon: infoIcon,
    },
    // {
    //     id: 5,
    //     element: "Recycler",
    //     link: "https://portal.theartbank.io/recycle",
    //     externalLink: true,
    //     icon: recyleMenu
    // },
]
   return [
    ...fSet,...categoryNav,...sSet
    
  ]},[category])

  const [navExpand, setNavExpand] = useState(false);
  const [mainNavExpand, setMainNavExpand] = useState(false);
  let [hideAlphaMessage, setHideAlphaMessage] = useState(isAppV1);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [mobileNavElements, setMobileNavElements] = useState([])
  
  useEffect(() => {
    if (isPortrait && screenSize <= 550) {
      let navEl = navElements;
      let fIndex = navEl.findIndex((el) => el.element === "Community");
      navEl.splice(fIndex);
      if (isLoggedIn) {
        navEl = [
          ...navEl,
          {
            id: 10,
            header: true,
            element: "Collectors",
            link: "discover/collectors",
            icon: collectorsIcon,
          },
          {
            id: 11,
            element: "User",
            subNav: [
              {
                element: "Profile",
                link: "profile/user/my-galleries",
              },
              {
                element: "Creators",
                link: "community",
              },
            ],
            icon: userInfo,
          },
          {
            id: 12,
            element: "Wallets",
            subNav: [
              {
                element: "Manage",
                link: "profile/wallet-authentication",
              },
              // {
              //   element: "Refresh",
              //   link: "",
              // },
            ],
            icon: wallet,
          },
        ];
      }
      setMobileNavElements(navEl);
    } 
    // else {
    //   let el = navElements;
    //   // let fIndex = el.findIndex((ele) => ele.element === "Collectors");
    //   // el.splice(fIndex + 1, 2);

    //   el = [
    //     ...el,
    //   ];

    //   setNavElements(el);
    // }
  }, [isPortrait, screenSize, isLoggedIn]);
  if (
    document.cookie.split("; ").findIndex((c) => c == "hideAlphaMessage=true") >
    -1
  ) {
    hideAlphaMessage = true;
  }

  useEffect(() => {
    if (isPortrait && screenSize <= 550) {
      document.getElementById("mainContent").style.filter = navExpand
        ? `grayscale(100%)`
        : "none";
    }else{
      document.getElementById("mainContent").style.filter =  "none";
    }
  }, [navExpand,isPortrait,screenSize]);

  const navExpandHandler = () => {
    setTimeout(() => {
      scrollToTop();
    }, 1000);
    setNavExpand((prevState) => {
      return !prevState;
    });
  };
  const mainNavExpandHandler = () => {
    setMainNavExpand((prevState) => {
      return !prevState;
    });
  };
  const mainNavExpandHandleShow = () => {
    setMainNavExpand(false);
  };
  useEffect(() => {
    dispatch("setLeftMenuCollapse", mainNavExpand);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainNavExpand]);
  const checkFilter = useMemo(() => {
    return (
      Number(document.getElementById("header-bar")?.offsetHeight || 0) > 60
    );
  }, [document.getElementById("header-bar")?.offsetHeight]);
  const updateHeight = useCallback(() => {
    const root = document.documentElement;
    if (headerRef.current) {
      root?.style.setProperty(
        "--top-menu-height",
        `${headerRef.current.offsetHeight}px`
      );
    }
    if (sideMenuRef.current) {
      let sideWidth = sideMenuRef.current.offsetWidth;
      root?.style.setProperty("--right-menu-width", `${sideWidth}px`);
    }
  }, [leftMenuCollapse]);
  useLayoutEffect(() => {
    updateHeight();
    window.addEventListener(
      "resize",
      () => {
        setTimeout(() => {
          updateHeight();
        }, 1000);
      },
      false
    );
  }, []);
  useEffect(() => {
    setTimeout(() => {
      updateHeight();
    }, 1500);
  }, [leftMenuCollapse]);

  const hideAlphaMessageHandler = () => {
    setHideAlphaMessage(true);
    document.cookie = "hideAlphaMessage=true";
  };
  useEffect(() => {
    setNavExpand(false);
  }, []);
  return (
    <div className={`flex flex-col h-full`}>
      <Spinner show={!!spinnerMode || !!collectionSpinner} className="spinner" size={150} />
      <Toaster {...toast} />
      <div
        className={`header-wrapper flex items-center relative ${
          navExpand && "grayscale"
        }`}
        ref={headerRef}
      >
        <Header
          onHamburgerClick={navExpandHandler}
          className={styles["menu-bar"]}
        />
      </div>
      <div className={`${styles["content-area-height"]} content-area`}>
        <div className={`${styles["min-content-height"]} flex h-full `}>
          <div
            className={`flex h-full flex-col ${styles["nav-bar"]} ${
              styles["desktop-nav"]
            } ${mainNavExpand ? styles["active"] : ""}`}
            ref={sideMenuRef}
          >
            <NavBar
              classList=" border-y-0 h-full"
              NavTitle="Home"
              mainNavExpand={mainNavExpand}
              onMainNavCollapse={mainNavExpandHandler}
              Elements={navElements}
              isMainNav={true}
              mainNavExpandHandleShow={mainNavExpandHandleShow}
            ></NavBar>
            {/* <div className="footer-wrapper flex flex-1  items-center">
              <Footer mainNavExpand={mainNavExpand} />
            </div> */}
          </div>
          <div
            onClick={navExpandHandler}
            className={`${styles["nav-overlay"]} ${
              navExpand && styles["nav-active"]
            }`}
          ></div>
          {
            <div
              className={`flex h-full flex-col ${styles["nav-bar"]} ${
                styles["mobile-nav"]
              } ${navExpand && styles["nav-active"]} ${
                isLoggedIn ? styles["nav-in"] : styles["nav-out"]
              }`}
            >
              <NavBar
                onNavCollapse={navExpandHandler}
                classList=" border-y-0 h-full"
                NavTitle="Home"
                Elements={mobileNavElements}
              ></NavBar>
            </div>
          }
          <div
            className={`${styles["content"]} ${
              pathname.includes("rewards") || pathname.includes("purchase")
                ? styles["pt-4-3"]
                : ""
            } ${!checkFilter && styles["filter-less"]} ${
              mainNavExpand ? styles["active"] : ""
            }`}
            id="mainContent"
          >
            <RoutesMain />
            {collectionLoadingStatus === "in-progress" && (
              <Status>{"Fetching Collections..."}</Status>
            )}
            <Draggable>
                <div className={`${styles["draggable-music-icon"]} draggable-music-icon`}>
                <MusicPlayer toplevel={true}></MusicPlayer>
                </div>
            </Draggable>
            {collectionLoadingStatus !== "in-progress" &&
              artBankLoadingStatus === "in-progress" && (
                <Status>{"Fetching Art Bank..."}</Status>
              )}
            {/* {!hideAlphaMessage && (
              <div className={`${styles["footer-alpha-warning"]}`}>
                <img
                  className={`${styles["info-icon"]}`}
                  src={infoIcon}
                  alt="info icon"
                />
                <div>
                  Our site is in alpha testing. All collector and creator
                  accounts will be retained, but other data may be lost during
                  the beta migration. Thank you for your patience.
                </div>
                <img
                  src={closeIcon}
                  onClick={hideAlphaMessageHandler}
                  alt=""
                  className={`${styles["close-icon"]} cursor-pointer`}
                />
              </div>
            )}
            )} */}
          </div>
        </div>
      </div>
      
      </div>
  );
};
export default Main;
