import NftFrame from "App/Components/NftFrame";
import { getFrameControl } from "App/ReduxStore/Slicers/galleryPreviewSlice";
import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import GalleryCaption from "../../../GalleryCaption";

const MonoSlider = memo(({item, i, imageRatioCalculated, hideCaptionOnSlideMove, playAudio = false}) => {
    const frameControl = useSelector(getFrameControl)
    const filterValues = (obj, searchString) => {
        const filteredValues = Object.keys(obj)
          .filter(key => key?.toLowerCase().includes(searchString))
          .map(key => obj[key]);
        return filteredValues?.length ? filteredValues[0] : '';
      }
    const userName = useMemo(() => {
        let tempUser = item?.communityName;
        if(item?.details?.onchain_metadata)
            tempUser = filterValues(item?.details?.onchain_metadata, 'artist');
        return tempUser;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onChainFiles = useMemo(() => {
        let tempFiles = [], onchainFiles = item?.details?.onchain_metadata?.files ?? [];
        if(onchainFiles?.length)
            tempFiles = onchainFiles?.filter(item => !item.mediaType.includes("audio"))
        return tempFiles
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item?.details])
    
    
    return (
        <div className="relative" key={i}>
            <div className={`mono-slider-section slider-section`}>
                <NftFrame 
                    skeltonClass='flex h-full w-full' 
                    imageContainerClass={`h-full w-full`} className="nftframe" isDetailView={true} 
                    maxHeight="100%" imageFile={onChainFiles}
                    isLandscape={item.isLandscape} nft={item.details.nftcdnimage} 
                    frameDetails={frameControl ? item?.frame : null} 
                    imageRatioCalculated={imageRatioCalculated} 
                    asset={item?.asset} 
                    playAudio={playAudio}
                />
            </div>
            <GalleryCaption 
                captionCardClass={`mono-caption ${hideCaptionOnSlideMove ? 'opacity-0' : 'opacity-1'}`}
                cardData={{ cardName: item?.collectionName, assetName: item.details?.name, userName: userName}} 
            />
        </div>
    )
})
export default MonoSlider;