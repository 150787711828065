/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import DiscoverTopPanel from "../../../SubComponents/DiscoverTopPanel";
import "./CreatorDetail.scss";
import OverView from "../../../SubComponents/OverView";
import DiscoverGallery from "../../../SubComponents/DiscoverGallery";
import PreviewGallery from "App/Pages/NftGallery/Component/MyGallery/Components/PreviewGallery";
import DiscoverCollection from "../../../SubComponents/DiscoverCollection";
import DiscoverAnnouncements from "../../../SubComponents/DiscoverAnnouncements";
import { useNavigate, useParams } from "react-router-dom";
import useDiscoverApiHelper from "../../../API/apiHelper";
import { getShortDate } from "App/Helper/getDate";
import DiscoverTopPanelMob from "../../../SubComponents/DiscoverTopPanelMob";
import Border from "../../../SubComponents/Border";
import { useStore } from "App/hooks-store/store";
import userAvatar from "Assets/Icons/userAvatars.svg";
import useScreenSize from "App/hooks/use-screen-size";
import DetailsInfo from "../../../SubComponents/Details";
import { EventsCard } from "App/Components/Ui/EventCard";
import useHttp from "App/hooks/use-http";
import AboutTab from "../AboutTab";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";

const paginationConfig = {
  perPage: 8,
};

const CreatorDetail = () => {
  const { communityId, tab, dataid } = useParams();
  const [selectedTab, setSelectedTab] = useState(tab);
  const {
    getUserGalleryAPIByUserCommunity,
    getUserCollectionDataAPI,
    getUserAnnouncementDataAPI,
    getUseEventsDataAPI,
    getStandAloneGallery,
    getCommunityData,
  } = useDiscoverApiHelper();
  const [dataCount, setDataCount] = useState({gallery:0, collection: 0, post: 0, event: 0})
  const [galleryData, setGalleryData] = useState([]);
  const [totalGalleryCount, setTotalGalleryCount] = useState(0);
  const [selectedGallery, setSelectedGallery] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [collectionList, setCollectionList] = useState(null);
  const [announcementList, setAnnouncementList] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [eventsListAll, setEventsListAll] = useState([]);
  const [communityData, setCommunityData] = useState(null);
  const [eventLoader, setEventLoader] = useState(true);
  const [postLoader, setPostLoader] = useState(true);
  const [collectionLoader, setCollectionLoader] = useState(true);
  const [galleryLoader, setGalleryLoader] = useState(true);
  const [totalCollectionCount, setTotalCollectionCount] = useState(null);
  const [collectionDetailsLoader, setCollectionDetailsLoader] = useState(false);
  const [totalAnnouncement, setTotalAnnouncement] = useState(0);
  const [detailsInfo, setDetailsInfo] = useState({});
  const [assetPreviewGallery, setAssetPreviewGallery] = useState({
    mode: false,
    data: [],
  });
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const screenSize = useScreenSize()?.width;
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [{ activeMenuIcons, topActiveMenu }, dispatch] = useStore();
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const { triggerAPI } = useHttp();
  useEffect(() => {
    if (activeMenuIcons?.back) {
      dispatch("setActiveIcons", { back: false });
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.back]);

  useEffect(() => {
    let temp = tab ? tab : (!communityId && !!dataid) ? 'collection' : '';
    setSelectedTab(temp)
  },[tab, communityId, dataid])
  useEffect(() => {
    // if (topActiveMenu !== "") setSelectedTab(topActiveMenu);
    setSelectedGallery(null);
  }, [topActiveMenu]);

    useEffect(() => {
        if((dataid && tab === 'collection')|| (!communityId && !!dataid)) 
          getDetailedCollection(dataid);
         else
          setSelectedCollection(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataid]);

    useEffect(() => {
        if(dataid && tab === 'gallery') {
          handleGalleryCardClick(dataid);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataid]);

  const menuTabs = useMemo(() => { 
      return [
        { id: 0, element: "Overview", url: 'overview' },
        { id: 1, element: "Collections", url: 'collection', count: dataCount?.collection },
        { id: 2, element: "Galleries", url: 'gallery', count: dataCount?.gallery },
        { id: 3, element: "Posts", url: 'post', count: dataCount?.post },
        { id: 4, element: "Events", url: 'event', count: dataCount?.event },
        { id: 5, element: "About", url: 'about'}
      ];
  },[dataCount]);

  useEffect(() => {
    dispatch("setActiveMenuOptions", tab);
    dispatch("setTopMenuBarOptions", {
      showMenuIcons: true,
      showTopMenus: false,
    });
    dispatch("setActiveIcons", { back: false });
    return () => {
      dispatch("resetAll");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    
    dispatch("enableTopIcons", {
      back: true,
      filterSort: false,
      search: false,
      dropDown: false,
      sorting: false,
      favourite: false,
    });
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const hideIcons=()=>{
    setTimeout(()=>{
      dispatch("enableTopIcons", { back: true, lgView: false, smView: false,
        filterSort: false,
        search: false,
        dropDown: false,
        sorting: false,
        favourite: false }); 
    },10)
  }
  const handleTabs = (e) => {
    const id = parseInt(e.currentTarget?.dataset?.id);
    const tabUrl = menuTabs?.find(item => item?.id === id)?.url;
    if(selectedTab !== tabUrl) {
      setSelectedCollection(null);
      setSelectedGallery(null);  
      setPage(1);
      hideIcons()
      dispatch("setActiveMenuOptions", tabUrl);
      navigate(`/discover/communities/${communityId}/${tabUrl}`,{replace:true})
      const root = document.getElementById('top');
      root.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

    }    
  };
  const handleCardClick = useCallback((e) => {
    navigate(`/discover/communities/${communityId}/gallery/${e}`)
  }, []);
  const handleGalleryCardClick = (e) => {
    
    let data = {
      id: e,
    };
;
    getStandAloneGallery(data, (res) => {
      let selected = res?.data;
      setSelectedGallery(selected);
      if (isPortrait&&screenSize<=550) {
        openAssetPreviewGallery(selected);
        window.scrollTo(0, 0);
      }
    }, err => { navigate(`/discover/communities/${communityId}/gallery`) });
  };

  const openAssetPreviewGallery = (e) => {
    setAssetPreviewGallery({ mode: true, data: { id: e?.id, type: 0 } });
  };

    const collectionCardClickOver = (e) => {
        collectionCardClick(e);
    }

  const collectionCardClick = (e) => {
    let id = e.currentTarget?.dataset?.policy;
    navigate(`/discover/communities/${communityId}/collection/${id}`)
  };

  const toGallery = () => {
    setSelectedGallery(null);
    hideIcons()
    navigate(`/discover/communities/${communityId}/gallery`)
  };

  const getGalleryList = (parms) => {
    setGalleryLoader(true)
    const dataParam = {
      page: parms?.page,
      items: parms?.items,
      search: "",
      filter: { communityId: parseInt(communityData?.id) },
    };
    getUserGalleryAPIByUserCommunity(dataParam, (res) => {
      const { list, pageMeta } = res;
      setTotalGalleryCount(pageMeta?.totalItems);
      const temp = list?.map((item) => item?.gallery);
      setGalleryData(temp);
      setGalleryLoader(false)
    });
  };

  const getCollectionList = (parms) => {
    setCollectionLoader(true)
    let dataParam = {
      page: parms?.page,
      items: parms?.items,
      search: "",
      filter: {   },
    };
    if(!parms?.noId) {
      dataParam={...dataParam,filter:{...dataParam?.filter,communityId: [parseInt(communityData?.id)],status: "active"}}
    }
    getUserCollectionDataAPI(dataParam, (res) => {
      const { list, pageMeta } = res;
      setTotalCollectionCount(pageMeta?.totalItems);
      setCollectionList(list);
      setCollectionLoader(false)
    });
  };

  const getAnnouncementList = (parms) => {
    const dataParam = {
      page: parms?.page,
      items: parms?.items,
      search: "",
      filter: { communityId: parseInt(communityData?.id) },
    };
    setPostLoader(true)
    getUserAnnouncementDataAPI(dataParam, (res) => {
      const { list, pageMeta } = res;
      setTotalAnnouncement(pageMeta?.totalItems);
      let tempArray = list?.map((announce) => {
        return {
          ...announce,
          id: announce?.id,
          name: announce?.headline,
          shortDetails: "",
          communityPic: announce?.community?.displayPicture ?? userAvatar,
          communityName: announce?.community?.name,
          details: announce?.details,
          createdAt: getShortDate(announce?.createdAt),
        };
      });
      setAnnouncementList(tempArray);
      setPostLoader(false)
    });
  };

  const getEventstList = (parms) => {
    setEventLoader(true)
    const dataParam = {
      page: parms?.page,
      items: parms?.items,
      search: "",
      filter: { communityId: parseInt(communityData?.id) },
    };
    getUseEventsDataAPI(dataParam, (res) => {
      const { upcomingEvents, pastEvents } = res;
      let allEvents = [...upcomingEvents, ...pastEvents]
      let tempArray = allEvents?.map((event) => {
        return {
          id: event?.id,
          name: event?.headline,
          headline: event?.headline,
          shortDetails: "",
          communityName: event?.community?.name,
          communityPic: event?.community?.displayPicture ?? userAvatar,
          details: event?.details,
          createdAt: getShortDate(event?.createdAt),
          start: event?.start,
          end: event?.end,
          filePath: event?.filePath,
          community : event?.community
        };
      });
      setEventsList(tempArray);
      setEventsListAll({ upcomingEvents, pastEvents });
      setEventLoader(false)
    });
  };

  const getDetailedCollection = useCallback((cId) => {   
    // dispatch('showSpinner')  
    setCollectionDetailsLoader(true)
    if(dataid || cId)  
    {
      const params = new URLSearchParams({
        collectionId: dataid || cId
      });        
      triggerAPI(
        {
          url: `community/collection/get-one?${params}`,            
          method: "get",
        },
        (res) => {    
          // dispatch('hideSpinner')
          setCollectionLoader(false)
          setCollectionDetailsLoader(false)
          if(res?.data) {
            let aspectRatio=res?.data?.shapes?.map(el=>el?.aspectRatio?.toString()).join(",")
            setSelectedCollection({...res.data,aspectRatio}) 
          } else {
            navigate(`/discover/communities/${communityId}/collection`)
          }
        }      
      );
    }
  }, [dispatch, triggerAPI]);  

  useEffect(() => {
    const isOverView = selectedTab === 'overview';
    let parms = { page: page };
    if(page && communityData?.id) {
      if(selectedTab === 'collection' || isOverView) { 
          parms = {...parms, items: isOverView ? (isPortrait && screenSize <= 550 ? 6 : 5) : 1000}
          getCollectionList(parms)    
      } 
      if (selectedTab === 'gallery' || isOverView) {
          parms = {...parms, items: isOverView ? 4 : 1000}
          getGalleryList(parms);
      } 
      if (selectedTab === 'post' || isOverView) {
          parms = {...parms, items: isOverView ? 4 : 1000}
          getAnnouncementList(parms);
      } 
      if (selectedTab === 'event' || isOverView) {
          parms = {...parms, items: isOverView ? 4 : 1000}
          getEventstList(parms);  
      }
    }
  }, [page, selectedTab, communityData?.id, screenSize])

  const handlePreviewGallery = useCallback((e) => {
      navigate(`/discover/communities/${communityId}/gallery/${e}`)
  }, []);


  const onPageChangeHandler = (page) => {
    setPage(page);
  };

  const handleAnnouncementClick = (e) => {
    let id = parseInt(e.currentTarget?.dataset?.id);
    if (id !== selectedAnnouncement?.id) {
      let selected = announcementList?.find((item) => item?.id === id);
      setSelectedAnnouncement(selected);
    } else {
      setSelectedAnnouncement(null);
    }
  };

  const handleEventClick = (e) => {
    let id = parseInt(e.currentTarget?.dataset?.id);
    if (id !== selectedEvent?.id) {
      let selected = eventsList?.find((item) => item?.id === id);
      setSelectedEvent(selected);
    } else {
      setSelectedEvent(null);
    }
  };

  useEffect(() => {
    if (selectedGallery) {
      window.scrollTo(0, 0);
    }
  }, [selectedGallery]);

  const getCommunity = useCallback(() => {
    const param = { id: communityId };
    getCommunityData(param, (res) => {
      const data = res?.data;
      const temp = {
        bannerPicUrl: data?.bannerPicture,
        profilePicUrl: data?.displayPicture,
        name: data?.name,
        id: data?.id,
        createdAt:data?.createdAt,
        twitter: data?.twitter,
        discord: data?.discord,
        youtube: data?.youtube,
        description: data?.description,
        user: data?.user,
        isFollowing: data?.isFollowing,
      };
      const currentDate = new Date();
      const futureEvents = data?.events?.filter(event => new Date(event.end) > currentDate);
      console.log(data)
      setCommunityData(temp);
      setDataCount({gallery: data?.galleryCount, collection: data?.collectionsCount,
                    post: data?.postsCount, event: futureEvents?.length})
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
   if(communityId) getCommunity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId]);
  const onDetailsInfoHandler=(info)=>setDetailsInfo(info)

  useEffect(() => {
    const { pastEvents, upcomingEvents } = eventsListAll
    const filterPastEvents = pastEvents?.length > 8 ? pastEvents?.slice(0, 8) : pastEvents
    setPastEvents(filterPastEvents);
    setUpcomingEvents(upcomingEvents);
  }, [eventsListAll]);
  return (
    <div id="top"
      className={`h-full flex discover-creator-details flex-col ${
        !selectedGallery && "overflow-auto"
      }`}
    >
      {!detailsInfo?.id ? (
        <>
          {!!communityId && (
            <>
              {!communityData ? (
                <div className="sm:flex hidden relative mb-[5.625rem]">
                  <DetailsSkeltonCard
                    show={true}
                    height={"16.3125em"}
                    count={1}
                    inline={true}
                    details={"banner-profile-img"}
                    noAbsolute
                    parentDiv={"banner-profile-skeleton"}
                    containerClassName={`flex mt-0 banner-profile-skeleton-banner`}
                  />
                </div>
              ) : (
                <>
                  <DiscoverTopPanel
                    self={true}
                    fromOther={true}
                    dontCallApi={true}
                    className="sm:flex hidden"
                    profileData={communityData}
                    setSelectedTab={handleTabs}
                    learnMoreLink={5}
                    isImageTag={true}
                    loaderClass="mb-[5.625rem]"
                  />
                  <DiscoverTopPanelMob
                    dontCallApi={true}
                    self={true}
                    profileData={communityData}
                    className={" p-[1rem] sm:hidden"}
                    onDetailsInfoHandler={onDetailsInfoHandler}
                  />
                </>
              )}
              <div className="tab-container mobileTab">
                <div className="tab-list flex gap-[1.42857rem]">
                  {menuTabs?.map((menu) => {
                    return (
                      <div
                        onClick={handleTabs}
                        data-id={menu?.id}
                        key={menu?.id}
                        className={`tab-data ${
                          menu?.url === selectedTab ? "active":"not-active"
                        }`}
                      >
                        {menu?.element}
                        {/* tab id 0, 5 count column not required as of now */}
                        {![0, 5]?.includes(menu?.id) && (
                          <span className="gallery-count">
                            {` (${menu?.count})`}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <Border
                className={"right-panel-b-border ml-[1rem] mr-[1rem] w-auto"}
              />
            </>
          )}
          {selectedTab === "overview" && (
            <div
              className={`details-container h-full ${
                !(isPortrait && screenSize <= 550) && "page-shadow"
              }`}
            >
              <OverView
                handleTabs={handleTabs}
                galleryList={galleryData}
                totalGalleryCount={dataCount?.gallery}
                handlePreviewGallery={handlePreviewGallery}
                perPage={4}
                collectionList={collectionList}
                totalCollectionCount={dataCount?.collection}
                collectionCardClick={collectionCardClickOver}
                announcementList={announcementList}
                totalAnnouncement={totalAnnouncement}
                selectedAnnouncement={selectedAnnouncement}
                handleAnnouncementClick={handleAnnouncementClick}
                handleEventClick={handleEventClick}
                eventsList={upcomingEvents}
                selectedEvent={selectedEvent}
                page="creators"
                eventLoader={eventLoader}
                postLoader={postLoader}
                collectionLoader={collectionLoader}
                galleryLoader={galleryLoader}
              />
            </div>
          )}
          {selectedTab === "collection" && (
            <div
              className={`${
                collectionLoader?"details-container": selectedCollection ? "px-4 pb-4" : "details-container"
              } h-full ${
                !(isPortrait && screenSize <= 550) && !!communityId
                  ? "page-shadow"
                  : "no-banner"
              }`}
            >
              <div className={`flex flex-col gap-[0.72rem] sm:pb-4 pb-[3rem]`}>
                <div className={`head-title sm:flex hidden`}></div>
                {!(dataid && tab === 'collection'&&!selectedCollection)?(collectionLoader&&!collectionDetailsLoader) ? (
                  <DetailsSkeltonCard
                    show={true}
                    height={"100%"}
                    count={5}
                    inline={true}
                    details={"img-2-line"}
                    noAbsolute
                    containerClassName={`flex`}
                  />
                ) : (
                  <DiscoverCollection
                    collectionList={collectionList}
                    collectionCardClick={collectionCardClick}
                    selectedCollection={selectedCollection}
                    totalItems={totalCollectionCount || 0}
                    perPage={paginationConfig?.perPage}
                    hideCloseOptn={!communityId}
                    pageId={communityId}
                    setSelectedCollection={setSelectedCollection}
                    hidePagination={true}
                  />
                ):<></>}
              </div>
            </div>
          )}
          {selectedTab === "gallery" && (
            <div
              className={`${
                dataid&&selectedGallery?galleryLoader?"p-2": "" : "p-4"
              } flex flex-col gap-[0.72rem] px-4 pb-4`}
            >
              <div className={`head-title sm:flex hidden`}>
                {dataid && selectedGallery && (
                  <span className="link" onClick={toGallery}>
                    Back
                  </span>
                )}
              </div>
              {!!dataid &&selectedGallery? (
                <div className="discover-gallery-preview-section">
                  <PreviewGallery
                    galleryUpdateId={dataid}
                    standalone={true}
                    selectedWall={""}
                    enableEdit={false}
                    viewFromOther={true}
                    page="discover"
                    galleryType={0}
                    fromDiscover={true}
                    onClose={toGallery}
                    selectedGallery={selectedGallery}
                    applyFrameButton={false}
                    communityData={communityData}
                    className={"top-0"}
                  />
                </div>
              ) : galleryLoader ? (
                <DetailsSkeltonCard
                  show={true}
                  height={"100%"}
                  count={screenSize <= 550 ? 2 : 3}
                  inline={true}
                  details={"1-line"}
                  noAbsolute
                  parentDiv="grid home-grid mt-0 aspect-pt-six details-gap"
                  containerClassName={`flex`}
                />
              ) : (
                <div className="overview-content">
                  <DiscoverGallery
                    galleryList={galleryData}
                    handleCardClick={handleCardClick}
                    page={page}
                    type="collectors"
                    previewGallery={assetPreviewGallery}
                    openAssetPreviewGallery={openAssetPreviewGallery}
                    totalItems={totalGalleryCount}
                    perPage={paginationConfig?.perPage}
                    onPageChangeHandler={onPageChangeHandler}
                    setSelectedGallery={setSelectedGallery}
                    hidePagination={true}
                    className={"home-grid"}
                    showSlider={false}
                    communityData={communityData}
                  />
                </div>
              )}
            </div>
          )}
          {selectedTab === "post" && (
            <>
              {postLoader ? (
                <div className={`posts-skeleton-wrap px-4 pb-4`}>
                  <DetailsSkeltonCard
                    show={true}
                    height={"100%"}
                    count={8}
                    inline={true}
                    noAbsolute
                    details="posts"
                    containerClassName={` flex pt-[1rem]`}
                  />
                </div>
              ) : (
                <div
                  className={`details-container h-full ${
                    !(isPortrait && screenSize <= 550) && "page-shadow"
                  }`}
                >
                  <DiscoverAnnouncements
                    selectedAnnouncement={selectedAnnouncement}
                    handleAnnouncementClick={handleAnnouncementClick}
                    announcementList={announcementList}
                  />
                </div>
              )}
            </>
          )}
          {selectedTab === "event" && (
            <div className="">
              <div
                className={`details-container h-full ${
                  !(isPortrait && screenSize <= 550) && "page-shadow"
                }`}
              >
                <div className=" text-left pl-1">
                  <p>
                    Upcoming Events{" "}
                    <span className="text-[#606573]">
                      ({upcomingEvents?.length})
                    </span>
                  </p>
                </div>
                {eventLoader ? (
                  <div className="event-skeleton-wrap">
                    <DetailsSkeltonCard
                      show={true}
                      height={"100%"}
                      count={screenSize > 1620 ? 4 : 3}
                      inline={true}
                      noAbsolute
                      containerClassName={` flex pt-[1rem]`}
                    />
                  </div>
                ) : upcomingEvents?.length ? (
                  <div className="event-grid py-4   gap-2">
                    {upcomingEvents?.map((event, index) => {
                      return (
                        <EventsCard
                          data={event}
                          fromCommunity={true}
                          key={index}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="h-[17rem] flex flex-col justify-center">
                    <p className="text-[#606573]">No upcoming events</p>
                  </div>
                )}
                <div className=" text-left pl-1 pt-2">
                  <p>
                    Past Events{" "}
                    <span className="text-[#606573]">
                      ({pastEvents?.length})
                    </span>
                  </p>
                </div>
                {eventLoader ? (
                  <div className="event-skeleton-wrap">
                    <DetailsSkeltonCard
                      show={true}
                      height={"100%"}
                      count={screenSize > 1620 ? 4 : 3}
                      inline={true}
                      noAbsolute
                      containerClassName={` flex pt-[1rem]`}
                    />
                  </div>
                ) : pastEvents?.length ? (
                  <div className="event-grid py-4  gap-2">
                    {pastEvents?.map((event, index) => {
                      return (
                        <EventsCard
                          data={event}
                          fromCommunity={true}
                          key={index}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="h-[17rem] flex flex-col justify-center">
                    <p className="text-[#606573]">No past events</p>
                  </div>
                )}
              </div>
            </div>
          )}
          {selectedTab === "about" && (
            <AboutTab communityData={communityData} />
          )}
        </>
      ) : (
        <DetailsInfo data={detailsInfo} />
      )}
    </div>
  );
};

export default CreatorDetail;