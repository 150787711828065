import { useEffect, useRef } from "react";

const AccordionExpander = ({ children, expand, scroll=true }) => {
    const expandOuter = useRef();
    const expandInner = useRef();
    useEffect(() => {
        const height = expandInner.current.clientHeight;
        expandOuter.current.style.maxHeight = expand ? `${height}px` : 0;
        if(expand && scroll){
            // const el = expandOuter.current;
            // setTimeout(() => {
            //     el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"}); 
            // }, 200);
        }
    }, [children,expand,scroll])
    return (
        <div className={`expander-content transition-all overflow-hidden ${
            expand ? "expanded" : "collapsed"
          }`} ref={expandOuter}>
            <div className="break-all acco-child pb-[.45rem] pl-[3.65rem]" ref={expandInner}>
                {children}
            </div>
        </div>
    )
}
export default AccordionExpander;