/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import WalletSelectPop from "App/Components/Ui/WalletSelectPop";
import useAuth from "App/hooks/use-auth";
import useScreenSize from "App/hooks/use-screen-size";
import { EventsCard } from "App/Components/Ui/EventCard";
import './Event.scss'
import { useDispatch, useSelector } from "react-redux";
import { getShowWalletPop, setShowWalletPop,getLoggingOutStatus } from "App/ReduxStore/Slicers/authSlice";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
const Events = () => {
  const screenWidth = useScreenSize()?.width;
  const [loader, setLoader] = useState(true);
  const { triggerAPI } = useHttp();
  const [{activeMenuIcons, currentWallet,collectionWalletData,collectionLoadingStatus }, dispatch] = useStore();
  const isLoggedIn = useAuth()[0];
  const [pastEvents, setPastEvents] = useState([]);
  const [renderForFirstTime, setRenderForFirstTime] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [eventCategories, setEventCategories] = useState([])
  const reduxDispatch = useDispatch();
  const showAuthPop = useSelector(getShowWalletPop);
  const loggingOutStatus=useSelector(getLoggingOutStatus)
  useEffect(()=>{
    if(!loggingOutStatus) reduxDispatch(setShowWalletPop((!isLoggedIn || (collectionWalletData !== null && !collectionWalletData?.length))&&collectionLoadingStatus !== "in-progress"))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loggingOutStatus,isLoggedIn,collectionWalletData,collectionLoadingStatus])
  const eventListApiResult = useCallback(
    (res) => {
      const { data } = res;
      setUpcomingEvents(data?.upcomingEvents);
      const filterPastEvents = data?.pastEvents?.length && data?.pastEvents?.length > 8 ? data?.pastEvents?.slice(0, 8) : data?.pastEvents
      setPastEvents(filterPastEvents)
      setLoader(()=>false)
    },
    [dispatch]
  );

  const getEventList = useCallback(
    (pageNo) => {
      setLoader(()=>true)

      if (renderForFirstTime) {
        triggerAPI(
          {
            url: "user/events/get",
            data: {
              page: 1,
              items: 10,
              filter: {},
              search: "",
            },
            method: "post",
          },
          (res) => {
            triggerAPI(
              {
                url: "user/events/get",
                data: {
                  page: 1,
                  items: res?.data?.pageMeta?.totalItems || 100,
                  filter: {
                    categoryIds: activeMenuIcons?.CategoryActiveIds ?? []
                  },
                  search: "",
                },
                method: "post",
              },
              eventListApiResult
            );
            setTotalItems(res?.data?.pageMeta?.totalItems);
            setRenderForFirstTime(false)
          }
        );
      } else {
        triggerAPI(
          {
            url: "user/events/get",
            data: {
              page: 1,
              items: totalItems || 100,
              filter: {
                categoryIds: activeMenuIcons?.CategoryActiveIds ?? []
              },
              search: "",
            },
            method: "post",
          },
          eventListApiResult
        );
      }
    },
    [triggerAPI, eventListApiResult, dispatch]
  );

  const getEventCategories = useCallback(() => {
    triggerAPI(
      {
        url: `community/event/categories/get`,
        method: "get",
      },
      (res)=>setEventCategories(res?.data)
    );
  }, [triggerAPI]);

  useEffect(() => {
    if (isLoggedIn) getEventList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWallet?.address, activeMenuIcons?.CategoryActiveIds]);

  useEffect(() => {
    if (isLoggedIn) getEventCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch("enableTopIcons", {
      filterCategoryDropdown: true,
    });
    dispatch("setTopMenuBarOptions", {
      showMenuIcons: isLoggedIn && collectionWalletData?.length,
      showTopMenus: false,
    });
    dispatch("setActiveIcons", {
      displayType: "all",
    });
    dispatch("setCategoryDropdown", eventCategories);
    return ()=> dispatch("resetAll");
  }, [isLoggedIn, collectionWalletData, eventCategories]);

  return (
    <div className="overflow-auto h-[100%] ">
      {/* <AuthPop
        show={showAuthPop}
        onTrialClick={handleTrialClick}
        onClose={setShowAuthPop}
      /> */}
      {showAuthPop && (
        <WalletSelectPop
          show={showAuthPop}
          
        />
      )}
      {/* <div className="list-announcement pb-[1rem] pt-[1rem] pl-[1rem]  pr-[1rem] sm:pr-[1.8rem] events-listing overflow-auto community-announcements flex flex-col h-full">
        <EventsCalendar
          view={isPortrait&&screeWidth<=550 ? "month" : "week"}
          events={eventList}
        />
      </div> */}
      <div className={`${((!isLoggedIn||(collectionWalletData !== null && !collectionWalletData?.length))&&collectionLoadingStatus !== "in-progress")&&"hidden"}`}>
       <div className=" text-left pl-9 pt-4">
          <p className="text-[1.25rem]">Upcoming Events <span className="text-[#606573]">({ upcomingEvents?.length})</span></p>
        </div>
        <div className="event-skeleton-wrap sm:pl-8  px-4">
        <DetailsSkeltonCard
        show={loader}
        height={"100%"}
        count={screenWidth>1620?4:3}
        inline={true}
        noAbsolute
        containerClassName={` flex pt-[1rem]`}
       />
       </div>
      {!loader? upcomingEvents?.length ? <div className="event-grid sm:py-4 sm:px-[1rem] py-2 px-4 gap-2">
        {upcomingEvents?.map((event, index)=>{
          return (
          <EventsCard
          data={event}
          fromCommunity={true} 
          key={index}
                // selectedCard={props?.selectedEvent} 
                // community={matchedCommunity}
          />
            )}
            )}
          </div>
          :
          <div className="h-[17rem] flex flex-col justify-center">
          <p className="text-[#606573]">No upcoming events</p>
          </div>:<></>
          }
      <div className=" text-left pl-9 pt-2">
          <p className="text-[1.25rem]">Past Events <span className="text-[#606573]">({ pastEvents?.length})</span></p>
        </div>
        <div className="event-skeleton-wrap sm:pl-8 px-4">
        <DetailsSkeltonCard
        show={loader}
        height={"100%"}
       count={screenWidth>1620?4:3}
        inline={true}
        noAbsolute
        containerClassName={` flex pt-[1rem]`}
       />
       </div>
      {!loader ? pastEvents?.length ? <div className="event-grid sm:py-4 sm:px-[1rem] py-2 px-4 gap-2 ">
        {pastEvents?.map((event, index)=>{
          return (
          <EventsCard
          data={event}
          fromCommunity={true} 
          key={index}
                // selectedCard={props?.selectedEvent} 
                // community={matchedCommunity}
          />

        )}
        )
        }
      </div>
       :
       <div className="h-[17rem] flex flex-col justify-center">
       <p className="text-[#606573]">No past events</p>
       </div>:<></>}
       </div>
    </div>
  );
};

export default Events;
