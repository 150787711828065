import React from 'react';
import './modal-outer.scss';

const ModalOuter = (props) => {
  return (
    <div onClick={props.onClose} className={`modal-outer-container ${props.parentClass}`}>
      <div onClick={(event)=>{
        event.stopPropagation();
        // props?.onClick(event);
        }} className={`modal-content ${props.contentClass}`}>{props.children}</div>  
    </div>
  )
}

export default ModalOuter;