const { initStore } = require("./store")
const configureStore = () => {
    const actions = {
        setLeftMenuCollapse: (current, data) => {
            return {
                leftMenuCollapse: data
            }
        },
        setMenuLoading: (current, data) => {
            return {
                menuLoading: data
            }
        },
        setTopMenuBarOptions: (current, data) => {
            return {
                showMenuIcons: data?.showMenuIcons,
                showTopMenus: data?.showTopMenus,
            }
        },
        setMenuOptions: (current, data) => {
            return {
                topMenuList: data,
            }
        },
        setCategoryOptions: (current, data) => {
            return {
                subCategoryOptions: data,
            }
        },
        setInfoDeskIcons: (current, data) => {
            return {
                infoDeskIcons: data,
            }
        },
        setActiveMenuOptions: (current, data) => {
            return {
                topActiveMenu: data,
            }
        },
        setActiveMenuOptionsData: (current, data) => {
            return {
                topActiveMenuData: data,
            }
        },
        setSortingOptions: (current, data) => {
            return {
                topSortingOptions: data
            }
        },
        setTileWeightingOptions: (current, data) => {
            return {
                topTileWeightingOptions: data
            }
        },
        setDropDownOptions: (current, data) => {
            return {
                topdropDownOptions: data
            }
        },
        setUserGroup: (current, data) => {
            return {
                userGroupOptions: data
            }
        },
        setCategoryDropdown: (current, data) => {
            return {
                categoryListOptions: data
            }
        },
        setActiveIcons: (current, object) => {            
            return {
                activeMenuIcons: {...current?.activeMenuIcons, ...object}
            }
        },
        setFilterContent: (current, object) => {
            return {
                filterContent: {...current?.filterContent, ...object}
            }
        },
        setEnableScrollMenu: (current,data) => {
            return {
                enableScrollMenu: data
            }
        },
        setFilterData: (current,data) => {
            return {
                filterData: data
            }
        },
        setCommunityGalleryCollections: (current,data) => {
            return {
                communityGalleryCollections: data
            }
        },
        enableTopIcons: (current, object) => {
            return {
                topMenuIcons: {...current?.topMenuIcons, ...object}
            }
        },
        setPage: (current, data) => {
            return {
                pageName: data,
            }
        },
        resetAll: (current, object) => {
           return {
            topMenuIcons: {  },
            pageName:"",
            activeMenuIcons: {},
            communityGalleryCollections:[],
            topdropDownOptions: [],
            topSortingOptions: [],
            topTileWeightingOptions: [],
            topMenuList: [],
            topActiveMenu: '',
            topActiveMenuData:{},
            showMenuIcons: false,
            showTopMenus: false,
            infoDeskIcons: false,
            menuLoading: false,
            enableScrollMenu:false,
            filterData: {},
            filterContent:{
                category:true,
                collectionStorage:true
            }
           }
        }
    }
    initStore(actions, {
        leftMenuCollapse: false,
        menuLoading: false,
        showMenuIcons: false,
        showTopMenus: false,
        infoDeskIcons: false,
        pageName:"",
        enableScrollMenu:false,
        topActiveMenuData:{},
        topSortingOptions: [],
        topTileWeightingOptions: [],
        topMenuList: [],
        subCategoryOptions : [],
        topActiveMenu: '',
        activeMenuIcons: {},
        topdropDownOptions: [],
        topMenuIcons: {
            lgView: false,
            xlgView: false,
            smView: false,
            listView: false,
            dropDown: false,
            userGroup: false,
            folder: false,
            file: false,
            favourite: false,
            filter: false,
            sorting: false,
            search: false,
            refresh: false,
            caption: false,
            frame: false,
            edit: false,
            delete: false,
            sortingDisable: false,
            searchDisable: false,
            favDisable: false,
            filterSort:false,
            filterCategoryDropdown: false,
        },
        filterContent:{
            category:true,
            collectionStorage:true
        },

        filterData: {}
    });
}
export default configureStore;