import styles from './AboutTab.module.scss'
import twitterIcon from 'Assets/Icons/social/Twitter.svg';
import discordIcon from 'Assets/Icons/social/Discord.svg';
import youtubeIcon from 'Assets/Icons/social/YouTube.svg'
import { appendUserName } from 'App/Helper/utilities';
const AboutTab = (props) => {
    const formatDate = (inputDate) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const dateObject = new Date(inputDate);
        const formattedDate = dateObject.toLocaleDateString('en-US', options);
        return formattedDate;
      };
  return (
    <div className={`flex flex-col w-full p-4 ${props?.className}`}>
           <div className="flex flex-col gap-[1rem] pb-[1.75rem] border-b border-[#191D23]">
            <div className="desc-title">
                Description
            </div>
            <div className={`${styles['description-about']}`}>
                {props?.communityData?.description}
            </div>
          </div>
          <div className="flex flex-col gap-[1rem] pt-[1.75rem] pb-[1.75rem] border-b border-[#191D23]">
            <div className="desc-title">
                Links
            </div>
            <div className='flex flex-col gap-[1rem] items-left pl-[2.5625rem]'>
                    {props?.communityData?.twitter &&
                     <a href={appendUserName(props?.communityData?.twitter, 'twitter')} target='_blank' rel='noreferrer' className='flex' >
                        <div className='flex gap-7'>
                            <img src={twitterIcon} alt='twitter' className='w-[1rem] ml-1'/>
                            <div className={`${styles['description-about']}`}>{props?.communityData?.twitter}</div>
                        </div>
                    </a>}
                        {props?.communityData?.discord && 
                     <a href={appendUserName(props?.communityData?.discord, 'discord')} rel='noreferrer' target='_blank' className='flex '>
                        <div className='flex gap-6'>
                        <img src={discordIcon} alt='discord' className='w-[1.5rem]'/>
                        <div className={`${styles['description-about']}`}>{props?.communityData?.discord}</div>
                        </div>
                    </a> 
                    }
                    {props?.communityData?.youtube && 
                    <a href={appendUserName(props?.communityData?.youtube, 'youtube')} rel='noreferrer' target='_blank' className='flex ' >
                         <div className='flex gap-6'>
                        <img src={youtubeIcon} alt='' className='w-[1.5rem]'/>
                        <div className={`${styles['description-about']}`}>{props?.communityData?.youtube}</div>
                        </div>
                    </a> 
                    }
                    </div>
          </div>
          <div className="flex flex-col gap-[1rem] pt-[1.75rem] pb-[1.75rem] ">
            <div className="desc-title">
                More Info
            </div>
            <div className="desc-title flex gap-4 pl-4">
                <div className='pl-4'>Joined</div>
                <div className={`${styles['description-about']}`}>
                {formatDate(props?.communityData?.createdAt)}
            </div>
            </div>
           
          </div>
        </div>
  )
}

export default AboutTab