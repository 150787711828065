import React from 'react';
import './custom-tooltip.scss';
import { Tooltip } from 'react-tooltip';

const CustomTooltip = (props) => {

    if(props?.reactTooltip) return <Tooltip id={props?.tooltipId}/>

    return (
        <div className={`tooltip_element ${props.parentClass}`}>
            {props.children}
            <span dangerouslySetInnerHTML={{ __html: props.tooltipTitle }} className={`tooltip ${props.titleClass ? props.titleClass : 'tooltip-position'}`} />
        </div>
    )
}

export default CustomTooltip