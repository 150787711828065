import React from 'react'
import styles from './Status.module.scss'
const Status = (props) => {
    return (
        <div className={styles["status"]}>
            {props.children}
        </div>
    )
}

export default Status
