import styles from "../../SelectedCollection.module.scss"
import NftFrame from "App/Components/NftFrame"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useStore } from "App/hooks-store/store"
import useFrames from "App/hooks/use-frames"
import { getURL } from "App/Helper/utilities"
import SkeltonCard from "App/Components/Ui/SkeltonCard"
import Tilt from "react-parallax-tilt";
import "./SelectedNftCard.scss"
import readBookIcon from "Assets/Icons/read-book.svg";
import infoIconSquareIcon from "Assets/Icons/infoIconSquareWhite.svg";
const SelectedNftCard = props => {
    const [src, setSrc] = useState(null)
    const store = useStore()[0];
    const { getFrameFromStore } = useFrames();
    const [spinnerShow, setSpinnerShow] = useState(false)
    const [wordLimit, setWordLimit] = useState(45)
    const [frameDetails, setFrameDetails] = useState(null)
    const [nft, setNft] = useState(null)
    const [isFav, setIsFav] = useState(false)
    const [hasBook, setHasBook] = useState(false)
    const [isBookIO, setIsBookIO] = useState(false)
    const targetRef = useRef();
    const { collections, leftMenuCollapse } = store
    useEffect(() => {
        setSpinnerShow(false)
        collections?.forEach(element => {
            if (element.asset === props.nft.asset) {
                if (isFav !== element.isFavourite)
                    setIsFav(element.isFavourite)
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collections])
    useEffect(() => {
        if (!!props.framesList)
        {
            getFrameId()
        }
        if (props.read)
        {
            for (let i = 0; i < props?.nft?.files.length; i++) {
                const file = props?.nft?.files[i];
                if (file.mType == 'pdf' || file.mType == 'epub')
                {
                    setHasBook(true);
                    break;
                }
            }   
            if (props?.nft?.collectionName?.toLowerCase().indexOf("book.io") > -1 
                || props?.nft.onchain_metadata?.website?.toLowerCase().indexOf("book.io") > -1
                || props?.nft.onchain_metadata?.website?.toLowerCase().indexOf("booktoken.io") > -1)         
            {
                setIsBookIO(true);
            }
        }            
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nft,props.read])
    useEffect(() => {
        if (props.reload?.asset === props.nft.asset) {
            getFrameId()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.framesList])
    const ImageClickHandler = (e, readBook) => {
        props.onImgClick({ nft: props?.nft, frame: frameDetails, readBook: readBook })
        setTimeout(() => {
            e?.target?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        }, 500)
    }
    const getFrameId = () => {
        let flag = false
        for (let frames of props.framesList) {
            if (frames.asset === props.nft.asset) {
                flag = true
                setSpinnerShow(true)
                getFrame(frames.frameId)
            }
        }
        if (!flag) {
            setSpinnerShow(false)
            setNft(props.nft)
            setFrameDetails(null)
        }
    }

    const getFrameResult = useCallback((res) => {
        const data = res;
        let isLandscape = data?.isLandscape ?? false;
        if(props?.framesList?.length) {
            let userFrame = props?.framesList.find((x) => x.frameId === data?.id && props.nft?.asset === x.asset);
            if(userFrame) {
                isLandscape = userFrame?.isLandscape ??  false;
            }
        }
        let temp = {
            id: data.id,
            name: data.name,
            image: 
                props.size==256 ? 
                    isLandscape ? (data.s3ImageUrlLandscape256 ?? data.s3ImageUrl256) : data.s3ImageUrl256
                : props.size==512 ? 
                    isLandscape ? (data.s3ImageUrlLandscape512 ?? data.s3ImageUrl512) : data.s3ImageUrl512
                : 
                    isLandscape ? (data.s3ImageUrlLandscape ?? data.s3ImageUrl) : data.s3ImageUrl
            ,
            type: data.category.name,
            style: data.style.name,
            color: data.color.name,
            features: data.addOn.name,
            complexity: data.complexity.name,
            metaInfo: isLandscape ? data.metaInfo?.landscape : data.metaInfo?.portrait,
            isLandscape: isLandscape
        }
        setNft(props.nft)
        setFrameDetails(temp)
        setSpinnerShow(false)
    }, [props])
    const getFrame = (frameId) => {
        setSpinnerShow(true)
        getFrameFromStore(frameId, (res) => {
            getFrameResult(res)
        })
    }
    useEffect(() => {
        if (props?.nft?.nftcdnimage512||props?.nft?.nftcdnimage256) {            
            setSrc(props.size===256?props?.nft?.nftcdnimage256:props?.nft?.nftcdnimage512);
        }
    }, [props?.nft,props.size])
    useEffect(() => {
        if(props.displayNft === 'lg') {
            setWordLimit(45);
        } else if(props.displayNft === 'sm'){
            setWordLimit(30);
        }
    }, [props.displayNft])
    const updateHeight = () => {
        const root = document.documentElement;
        if (targetRef.current) {
            root?.style.setProperty(
                "--collection-card-height", `${targetRef.current.offsetWidth}px`
            );
        }
    }
    useLayoutEffect(() => {
        updateHeight()
        window.addEventListener("resize", () => {
            setTimeout(() => {
                updateHeight()            
            }, 1000);
        }, false);
    },[]);
    useEffect(() => {
        updateHeight()
    },[props.selected, props.displayNft]);
    
    useEffect(() => {
        setTimeout(() => {
            updateHeight()            
        }, 1000);
        updateHeight()
    },[leftMenuCollapse]);

    return < div className={`${styles["nft-display"]} ${props.displayNft === "xlg" && 'enlarge-card'}  ${props.displayNft === "lg" && styles["nft-display-lg"]} ${props.selected && `${styles["selected"]} ${styles["selected-sec-lvl"]}`} relative`}>
        <SkeltonCard show={spinnerShow || !nft} height="100%" inline={true}
            containerClassName={`${props.displayNft === "lg" ? styles["selected-skelton-card-lg"] : styles["selected-skelton-card-sm"]} flex`} />
        <Tilt glareEnable={false} glareMaxOpacity={0} tiltEnable={!props?.read} tiltMaxAngleX={10} 
                        tiltMaxAngleY={10} perspective={1500} 
                        glareColor={"rgba(0,0,0,1)"}
                        >
        {props.read && <>
            <div className={`content-overlay ${!hasBook ? 'nobook' : ''}`} onClick={(e)=> hasBook && ImageClickHandler(e, true)}>
                <div className={`content-details`} >
                    <div className="center-icon">
                        {isBookIO && <div className="message">                        
{`This is an encrypted Book.io eBook, currently they have no support for 3rd party readers

Please contact Book.io for more information`}
                        </div>}
                        {!hasBook && !isBookIO && <div className="message">                        
{`Sorry, this NFT doesn't contain any supported books (PDFs or ePubs)`}
                        </div>}
                        {hasBook && <img className="readbookicon" src={readBookIcon} alt="Read" />}
                    </div>  
                    <div className="top-right-icon">
                        <img className="infoicon" src={infoIconSquareIcon} alt="Info" onClick={(e)=> { e.stopPropagation(); ImageClickHandler(e, false) }}/>
                    </div>                    
                </div>
            </div>
        </>}                            
        <div className={`${styles["nft-img"]} ${props.displayNft !== "xlg" && styles["list-nft-img"]} ${props.displayNft === "xlg" && `enlarge-nft-img ${styles["enlarge-nft-img"]}`}`} ref={targetRef} onClick={ImageClickHandler}>            
            <NftFrame
                skeltonClass={`${props.displayNft === "lg" ? styles["selected-skelton-card-lg"] : styles["selected-skelton-card-sm"]} h-full flex ${props.displayNft === "xlg" && 'enlargeskelton'}`}
                displayNft={props.displayNft}
                overlayClass={styles["hover-overlay"]}
                data-index={props.index} onClick={ImageClickHandler} 
                className={`selected-nft-cards-collection inner-nft ${styles["collection-image"]}`}
                frameDetails={props.framed?frameDetails:null}                
                nft={src}         
            />
        </div>
        </Tilt>
        {!props?.hideName &&
            <div className={`${styles["nft-name-box"]} ${props.caption ? styles["bg-blue"] : ""} flex flex-col`}>
                <div className="flex">
                    <div className={`${styles["nft-name"]} nft-names ml-2`}>
                        {props.caption && <span>{(
                        props.nft.name?.length > wordLimit ? `${props.nft.name.substring(0,(wordLimit - 1))}...` : props.nft.name)}</span>}
                    </div>
                </div>
            </div>
        }
    </div >
}

export default SelectedNftCard