
import './DiscoverAnnouncements.scss';
import AnnouncementsList from 'App/Pages/CommunityCollection/Community/Components/Announcements/Components/AnnouncementLists';

const DiscoverAnnouncements = (props) => {
    return (
        <div className='discover-announcement-container gap-[0.72rem] flex flex-col pb-[1rem]'>
            <AnnouncementsList
            data={props?.announcementList}
            normalAccordion={true}
            page="creators"
            />
        </div>
    )
}
export default DiscoverAnnouncements;