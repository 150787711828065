import Skeleton from "react-loading-skeleton";

const SkeletonLoader = (props) => {
  const color = {
    baseColor: "var(--cu-black-500)",
    highlightColor: "var(--cu-black-110)",
  };
  const defaultConfig = {
    ...color,
    ...props,
  };
  return (
    <>
      {props?.details==="posts"?<div className="flex items-center py-[0.625rem] details-wrapper px-[0.5rem] gap-2 rounded-[.312rem]  border border-[#191D23]">
          <Skeleton
            circle
            width={50}
            height={50}
            inline={props?.inline}
            {...color}
          />
          <div className="text-details">
            <Skeleton
              count={2}
              inline={props?.inline}
              height={"0.5rem"}
              {...color}
            />
          </div>
        </div>:
          props?.details==="profile-img-with-1-lines"?(
            <div className="flex items-center details-wrapper gap-4">
            <Skeleton
              circle
              width={24}
              height={24}
              inline={props?.inline}
              {...color}
            />
            <div className="text-details pt-1 flex flex-col gap-[6px]">
              <Skeleton
                count={1}
                inline={props?.inline}
                height={"0.5rem"}
                {...color}
              />
            </div>
          </div>
          ):
        props?.details==="profile-img-with-2-lines"?(
          <div className="flex items-center details-wrapper gap-4">
          <Skeleton
            circle
            width={24}
            height={24}
            inline={props?.inline}
            {...color}
          />
          <div className="text-details pt-1 flex flex-col gap-[6px]">
            <Skeleton
              count={1}
              inline={props?.inline}
              height={"0.5rem"}
              {...color}
            />
            <Skeleton
              count={1}
              inline={props?.inline}
              height={"0.5rem"}
              {...color}
            />
          </div>
        </div>
        ):<>
        <Skeleton {...defaultConfig} />
      {props?.details === "img-2-line" ? (
        <div className="flex items-center pt-2 details-wrapper gap-2">
          <Skeleton
            circle
            width={50}
            height={50}
            inline={props?.inline}
            {...color}
          />
          <div className="text-details">
            <Skeleton
              count={2}
              inline={props?.inline}
              height={"0.5rem"}
              {...color}
            />
          </div>
        </div>
      ) : 
        props?.details === "1-line" ? (
          <div className="text-details">
            <Skeleton
              count={1}
              inline={props?.inline}
              height={"0.5rem"}
              {...color}
            />
          </div>
        )
        : props?.details ==="banner-profile-img"&&(
          <div className="banner-profile-img">
            <Skeleton
            circle
            width={105}
            height={105}
            inline={props?.inline}
            {...color}
          />
          </div>
        )
      }</>
      }
    </>
  );
};
export default SkeletonLoader;
