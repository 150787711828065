import InputSlider from "App/Components/Ui/InputSlider";
import ControlBody from "../SubComponents/ControlBody";
import ControlCard from "../SubComponents/ControlCard";
import ControlContent from "../SubComponents/ControlContent";
import ControlTitleCard from "../SubComponents/ControlTitleCard";
import InputColor from "App/Components/Ui/InputColor";
import { useCallback, useEffect, useMemo, useState } from "react";

const ScreenStyle = ({className = '', setScreenControl, screenControls}) => {
    const [imageThickness, setImageThickness] = useState(0);
    const styleTypes = useMemo(() => {
        return [
            { id: 1, name: 'Zoomed', value: 'ful-scr' },
            { id: 2, name: 'Framed', value: 'frm' },
            { id: 3, name: 'Customized', value: 'custom' }
        ]
    }, [])
    const frameTypes = useMemo(() => {
        return [
            { id: 1, name: 'On', value: 1 },
            { id: 2, name: 'Off', value: 0 }
        ]
    }, []);
    const handleStyleType = (e) => {
        let type = e?.currentTarget?.dataset?.value;
        setScreenControl(prev => { return {...prev, styleType: type}})
    }
    const onImageSizeChange = useCallback((value) => {
        setScreenControl(prev => { return {...prev, imageLayout: {...prev?.imageLayout, size: value}}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onImageBgChange = useCallback((value) => {
        setScreenControl(prev => { return {...prev, imageLayout: {...prev?.imageLayout, bgColor: value}}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onImageThickChange = useCallback((value) => {
        setImageThickness(value)
        // setScreenControl(prev => { return {...prev, imageBorder: {...prev?.imageBorder, thickness: value}}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onImageBg1Change = useCallback((value) => {
        setScreenControl(prev => { return {...prev, imageBorder: {...prev?.imageBorder, bgColor_1: value}}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onImageBg2Change = useCallback((value) => {
        setScreenControl(prev => { return {...prev, imageBorder: {...prev?.imageBorder, bgColor_2: value}}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleFrameType = (e) => {
        let type = parseInt(e?.currentTarget?.dataset?.value);
        setScreenControl(prev => { return {...prev, frameType: !!type}})
    }
    useEffect(() => {
        let timer = setTimeout(() => {
            setScreenControl(prev => { return {...prev, imageBorder: {...prev?.imageBorder, thickness: Number(imageThickness)}}})
        }, 600);
        return () => clearTimeout(timer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [imageThickness]);
    return (
        <ControlCard className={`${className} pop screen-style-control w-[25rem] ${screenControls?.styleType === 'custom' && 'style-pop'}`}>
            <div className={`custom-styles flex gap-8 flex-col ${screenControls?.styleType === 'custom' ? 'opacity-1' : 'hidden'}`}>
                <ControlBody className="bottom-border pb-8">
                    <ControlTitleCard>{`Layouts`}</ControlTitleCard>
                    <ControlContent className="flex layout-content">
                        <div className="layout-items">
                            <span>Image Size</span>
                            <div className="flex flex-1">
                                <InputSlider step={0.5} onChange={onImageSizeChange} value={screenControls?.imageLayout?.size} max={5}/>
                            </div>
                        </div>
                        <div className="layout-items">
                            <span>Background Color</span>
                            <InputColor value={screenControls?.imageLayout?.bgColor} onChange={onImageBgChange} />
                        </div>
                    </ControlContent>
                </ControlBody>
                <ControlBody className="bottom-border pb-8">
                    <ControlTitleCard>{`Border`}</ControlTitleCard>
                    <ControlContent className="flex layout-content">
                        <div className="layout-items">
                            <span>Thickness</span>
                            <div className="flex flex-1">
                                <InputSlider step={0.5} onChange={onImageThickChange} value={imageThickness ?? 0} max={5} />
                            </div>
                        </div>
                        <div className="layout-items">
                            <span>Colors</span>
                            <div className="flex gap-4">
                                <InputColor value={screenControls?.imageBorder?.bgColor_1} onChange={onImageBg1Change} />
                                <InputColor value={screenControls?.imageBorder?.bgColor_2} onChange={onImageBg2Change} />
                            </div>
                        </div>
                    </ControlContent>
                </ControlBody>
                <ControlBody className="bottom-border pb-8">
                    <ControlTitleCard>{`Frame`}</ControlTitleCard>
                    <ControlContent className="layout-content">
                        {frameTypes?.map(type => {
                            return  <span data-value={type?.value} className={`cursor-pointer ${type?.value === Number(screenControls?.frameType) && `text-active`}`} 
                                        onClick={handleFrameType} key={type?.id}>
                                        {type?.name}
                                    </span>
                        })}
                    </ControlContent>
                </ControlBody>
            </div>       
            <ControlBody className="">
                <ControlTitleCard>{`Preset Layouts`}</ControlTitleCard>
                <ControlContent className="layout-content">
                    {styleTypes?.map(type => {
                        return  <span data-value={type?.value} className={`${type?.value === screenControls?.styleType && `text-active`} cursor-pointer`} 
                                    onClick={handleStyleType} key={type?.id}>
                                    {type?.name}
                                </span>
                    })}
                </ControlContent>
            </ControlBody>     
        </ControlCard>
    )
}
export default ScreenStyle;