import { useCallback, useEffect, useState } from "react";
import useHomeApiHelper from "../API/apiHelper";
import { useNavigate } from "react-router-dom";
import SkeltonCard from "App/Components/Ui/SkeltonCard";
import AnnouncementsList from "App/Pages/CommunityCollection/Community/Components/Announcements/Components/AnnouncementLists";
export const HomeAnnouncements = ({ handlePreview = () => {}, screenSize = '' }) => {
  const { getAnnouncement } = useHomeApiHelper();
  const [announcementList, setAnnouncementList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const getData = useCallback(() => {
    const req = {
      page: 1,
      items: screenSize > 550 ? 10 : 5,
      filter: {
        showOnHomePageDisplay: true,
      },
    };
    setLoading(true);
    getAnnouncement(req, (res) => {
      setLoading(false);
      const updatedData = res?.data?.list?.map((val) => {
        return {
          ...val,
          createdAt: new Date(val?.createdAt).toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
        };
      });
      setAnnouncementList(updatedData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize]);
  useEffect(() => {
    getData();
  }, [getData, screenSize]);
  const navigateToAll = () => {
    navigate(`/curate/posts`);
    window.scrollTo(0, 0);
  };
  return (
    <div className="flex flex-col sm:gap-[0.437rem] gap-[1.25rem]">
      <div className="title-section flex justify-between">
        <div className="title">Browse Posts</div>
        {!!announcementList?.length&&<div className="link" onClick={navigateToAll}>
          See all
        </div>}
      </div>
      <div className="announcement-container flex flex-col gap-3 relative">
        {loading && (
          <div className="min-h-[7rem]">
            <SkeltonCard
              show={loading}
              height="3rem"
              count={2}
              inline={true}
              containerClassName={` flex flex-col gap-2`}
            />
          </div>
        )}
        {!loading &&
          // announcementList?.map((item) => {
          //   return (
          //     <div className="announcement-card">
          //       {screenSize > 550 ?
          //         <div className="flex time-title-wrapper gap-2">
          //           <span className="date w-16 text-left">{item?.createdAt}</span>
          //           <div className="pro-img">
          //             <img src={item?.community?.displayPicture} alt="icon" />
          //           </div>
          //           <span className="datetitles-text">
          //             <FormatField
          //               type="sub"
          //               maxWidth={30}
          //               value={item?.community?.name}
          //             />
          //           </span>
          //         </div> : 
          //         <div className="flex gap-4 w-full justify-between">
          //           <div className="time-title-wrapper flex  gap-4">
          //             <div className="pro-img">
          //               <img src={item?.community?.displayPicture} alt="icon" />
          //             </div>
          //             <span className="datetitles-text">
          //               <FormatField
          //                 type="sub"
          //                 maxWidth={15}
          //                 value={item?.community?.name}
          //               />
          //             </span>
          //           </div>
          //           <div>
          //             <span className="date w-16 text-left">{item?.createdAt}</span>
          //           </div>
          //           {/* <span className="date w-16 text-left">{item?.createdAt}</span>
          //           <div className="pro-img">
          //             <img src={item?.community?.displayPicture} alt="icon" />
          //           </div>
          //           <span className="datetitles-text">
          //             <FormatField
          //               type="sub"
          //               maxWidth={30}
          //               value={item?.community?.name}
          //             />
          //           </span> */}
          //         </div>
          //       }
          //       <p className="brief-desc">
          //         <FormatField
          //           type="sub"
          //           maxWidth={screenSize > 550 ? 70 : 70}
          //           value={item?.details ?? ""}
          //         />
          //       </p>
          //     </div>
          //   );
          // })
          <AnnouncementsList
          data={announcementList}
          normalAccordion={true}
          page="main"
        />
          }
      </div>
    </div>
  );
};
